import React from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import uuid from 'uuid';
import { formatBytes, dataURItoBlob } from '../../../../../util/UtilFunctions';
import { validateLocalVideoFileRendability } from '../../../../../util/FileTypeSupportCheck';
import { getVideoSnapshotFromStart, getPercent } from './FileUploadingModaListItemUtilFunctions';
import { pdfToPng } from '../../../setPictureModal/SetPictureModalUtilFunctions';
import { createNewVideoElement } from '../../FileUploadingModalUtilFunctions';
import { ReactComponent as CrossIcon } from '../../../../../assets/nitro/cross.svg';
import './fileUploadingModalListItem.scss';
import ListItemThumbnail from './listItemThubmnail';

class FileUploadingModalListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoWrapperId: '',
      videoPlayerError: undefined,
    };
    this.cancelled = false;
    this.thumbnailImageNode = React.createRef();
  }

  componentDidMount = async () => {
    const isVideo = this.props.file.type.split('/')[0] === 'video';
    const isRendable = validateLocalVideoFileRendability(this.props.file);
    const isPdf = this.props.file.type.split('/')[1] === 'pdf';
    if (isVideo && isRendable) {
      this.props.updateThumbnailGeneratingProcessOnGoing(true);
      this.initializePlayer();
    } else if (isPdf) {
      try {
        const pdfImg = await pdfToPng(this.props.file);
        this.props.updateFilesListItemDetails({ name: this.props.file.name, thumbnail: pdfImg });
      } catch (pdfToPngError) {
        this.props.updateFilesListItemDetails({ name: this.props.file.name });
      }
    }
  };

  componentWillUnmount = () => {
    this.cancelled = true;
  };

  handleDeleteButtonClick = () => {
    this.props.removeItemFromFilesList(this.props.file);
    this.props.removeFromVideoDetailsList(this.props.file);
  };

  initializePlayer = () => {
    const rndNum = uuid();
    const whiteSpaceRemovedName = this.props.file.name.replace(/\s/g, '');
    const videoWrapperId = `video-wrapper-${whiteSpaceRemovedName}-${rndNum}`;
    this.setState({ videoWrapperId }, () => {
      const videoPlayerWrapperElement = document.getElementById(videoWrapperId);
      const videoDomElement = createNewVideoElement(this.props.file, videoWrapperId);
      videoPlayerWrapperElement.appendChild(videoDomElement);
      const playerObj = videojs(videoDomElement);
      playerObj.on('error', this.handleVideoPlayerError);

      setTimeout(() => {
        playerObj.ready(() => {
          if (!this.state.videoPlayerError) {
            playerObj.play();
            playerObj.pause();
            playerObj.currentTime(1);
          }
        });
      }, 200);
      setTimeout(() => this.updateVideoThumbnailDetails(videoWrapperId), 2000);
    });
  };

  handleVideoPlayerError = (err) => {
    this.setState({ videoPlayerError: err });
    const uploadableVideoDetails = {
      name: this.props.file.name,
      thumbnailBlob: undefined,
    };
    this.props.addToVideoDetailsList(uploadableVideoDetails);
  };

  updateVideoThumbnailDetails = async (videoWrapperId) => {
    if (!this.cancelled) {
      if (!this.state.videoPlayerError) {
        const snapShotData = await getVideoSnapshotFromStart(videoWrapperId);
        this.props.updateThumbnailGeneratingProcessOnGoing(false);
        if (!this.cancelled) {
          const blobData = dataURItoBlob(snapShotData);
          const uploadableVideoDetails = {
            name: this.props.file.name,
            thumbnailBlob: blobData,
          };
          this.props.addToVideoDetailsList(uploadableVideoDetails);
        }
      }
    }
  };

  render() {
    const percent = getPercent(this.props.isFinished, this.props.progressPercent);
    return (
      <li className={`fileUploadingModalListItem ${this.props.didFail ? 'error' : ''}`}>
        <ListItemThumbnail
          file={this.props.file}
          snapshotBlob={this.props.snapshotBlob}
          didFail={this.props.didFail}
          videoWrapperId={this.state.videoWrapperId}
          videoPlayerError={this.state.videoPlayerError}
        />
        <div className="wrapper">
          <div className="progress" style={{ width: `${percent}%` }} />
          <div className="filename">
            <span className="name">{this.props.file.name}</span>
            <span className="ext" />
          </div>
          <div className="end">
            <h5>{this.props.isUploading ? `${percent}%` : formatBytes(this.props.file.size)}</h5>
            {!this.props.isFinished && !this.props.isUploading && (
              <button onClick={() => this.handleDeleteButtonClick()} type="button">
                <CrossIcon />
              </button>
            )}
          </div>
        </div>
      </li>
    );
  }
}

export default FileUploadingModalListItem;

FileUploadingModalListItem.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  removeItemFromFilesList: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  addToVideoDetailsList: PropTypes.func.isRequired,
  removeFromVideoDetailsList: PropTypes.func.isRequired,
  snapshotBlob: PropTypes.object,
  isFinished: PropTypes.bool.isRequired,
  didFail: PropTypes.bool.isRequired,
  updateFilesListItemDetails: PropTypes.func.isRequired,
  progressPercent: PropTypes.number,
  updateThumbnailGeneratingProcessOnGoing: PropTypes.func.isRequired,
};

FileUploadingModalListItem.defaultProps = {
  snapshotBlob: undefined,
  progressPercent: undefined,
};
