/* eslint-disable */
import { Amplify } from 'aws-amplify';
import { config } from '../config/Config';

export const configureAmplify = () =>
  Amplify.configure({
    Auth: {
      mandatorySignIn: false,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    API: {
      endpoints: [
        {
          name: config.apiGateway.NAME,
          endpoint: config.apiGateway.ENDPOINT,
          region: config.apiGateway.REGION,
          id: config.apiGateway.ID,
        },
      ],
    },
    Storage: {
      region: config.cognito.REGION,
      bucket: config.s3.BUCKET,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
  });
