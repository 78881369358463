import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as UploadIcon } from '../../../assets/nitro/upload.svg';
import './dropzone.scss';

// TODO: add fileformat check and handle invalid fileformat styles (onInvalid)
const Dropzone = ({ handleChange, accept, useCase }) => {
  // This can be used to handle multiple events to give different styling in the future.
  // For now there is only active or non-avtive. Hover is handled in styles.
  const [active, setActive] = useState(false);

  return (
    <div className={`dropzone ${active ? 'active' : ''}`}>
      <div className="fileSelectWrapper">
        <div>
          <UploadIcon />
          <h5>{useCase === 'file' ? 'Drag or select a file' : 'Drag or select a folder'}</h5>
        </div>
        {useCase === 'folder' && (
          <input
            type="file"
            onChange={handleChange}
            multiple
            id="dropzone"
            webkitdirectory=""
            directory=""
            onBlur={() => setActive(false)}
            onFocus={() => setActive(true)}
            onInput={() => setActive(false)}
            onInvalid={() => setActive(undefined)}
            onDragEnter={() => setActive(true)}
            onDragLeave={() => setActive(false)}
            accept={accept}
          />
        )}
        {useCase === 'file' && (
          <input
            type="file"
            onChange={handleChange}
            multiple
            id="dropzone"
            onBlur={() => setActive(false)}
            onFocus={() => setActive(true)}
            onInput={() => setActive(false)}
            onInvalid={() => setActive(undefined)}
            onDragEnter={() => setActive(true)}
            onDragLeave={() => setActive(false)}
            accept={accept}
          />
        )}
      </div>
    </div>
  );
};
export default Dropzone;

Dropzone.propTypes = {
  // handleClick: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  // multiple: PropTypes.bool,
  // status: PropTypes.bool,
  accept: PropTypes.string,
  useCase: PropTypes.oneOf(['folder', 'file']).isRequired,
};

Dropzone.defaultProps = {
  accept: undefined,
};
