// eslint-disable-next-line
import store from '../configureStore';
import { hasExpired } from './subscriptionDetails';

export const formatDate = (dateStr) => {
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(5, 7);
  const day = dateStr.substring(8, 10);

  return `${day}.${month}.${year}`;
};

export const formatMilliSeconds = (milliSeconds) => {
  if (milliSeconds > 1000) {
    const seconds = milliSeconds / 1000;
    if (seconds > 60) {
      const minutes = Math.floor(seconds / 60);
      if (minutes > 60) {
        const hours = Math.floor(minutes / 60);
        const remainderMinutes = Math.floor(minutes - hours * 60);
        const remainderSeconds = Math.floor(seconds - (hours * 60 * 60 + remainderMinutes * 60));
        return `${hours}h${remainderMinutes}m${remainderSeconds}s`;
      } else {
        const remainderSeconds = Math.floor(seconds - minutes * 60);
        return `${minutes}m${remainderSeconds}s`;
      }
    } else {
      return `${Math.round((milliSeconds / 1000) * 10) / 10}s`;
    }
  } else {
    return `${Math.round((milliSeconds / 1000) * 10) / 10}s`;
  }
};

export const formatBytes = (bytes) => {
  let fileSize = bytes;
  if (!fileSize) {
    fileSize = '0';
  }

  const kb = fileSize / 1000;
  const mb = fileSize / 1000000;
  const gb = fileSize / 1000000000;
  const tb = fileSize / 1000000000000;

  if (tb >= 1) {
    return `${Math.round(tb * 10) / 10} tb`;
  } else if (gb >= 1) {
    return `${Math.round(gb * 10) / 10} gb`;
  } else if (mb >= 1) {
    return `${Math.round(mb * 10) / 10} mb`;
  } else if (kb >= 1) {
    return `${Math.round(kb * 10) / 10} kb`;
  } else {
    return `${fileSize} b`;
  }
};

export const getExtFromFileName = (fileName) => fileName.substring(fileName.lastIndexOf('.') + 1);

export const getNameFromFileName = (fileName) => fileName.substring(0, fileName.lastIndexOf('.'));

export function getRelativeTime(current, previous) {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    if (Math.round(elapsed / 1000) === 1) {
      return `${Math.round(elapsed / 1000)} second ago`;
    }
    return `${Math.round(elapsed / 1000)} seconds ago`;
  } else if (elapsed < msPerHour) {
    if (Math.round(elapsed / msPerMinute) === 1) {
      return `${Math.round(elapsed / msPerMinute)} minute ago`;
    }
    return `${Math.round(elapsed / msPerMinute)} minutes ago`;
  } else if (elapsed < msPerDay) {
    if (Math.round(elapsed / msPerHour) === 1) {
      return `${Math.round(elapsed / msPerHour)} hour ago`;
    }
    return `${Math.round(elapsed / msPerHour)} hours ago`;
  } else if (elapsed < msPerMonth) {
    if (Math.round(elapsed / msPerDay) === 1) {
      return `${Math.round(elapsed / msPerDay)} day ago`;
    }
    return `${Math.round(elapsed / msPerDay)} days ago`;
  } else if (elapsed < msPerYear) {
    if (Math.round(elapsed / msPerMonth) === 1) {
      return `${Math.round(elapsed / msPerMonth)} month ago`;
    }
    return `${Math.round(elapsed / msPerMonth)} months ago`;
  } else {
    if (Math.round(elapsed / msPerYear) === 1) {
      return `${Math.round(elapsed / msPerYear)} year ago`;
    }
    return `${Math.round(elapsed / msPerYear)} years ago`;
  }
}

const getName = (item) => {
  return item.title?.S || item.title?.N || item.title || item.name.S || item.name.N || item.name;
};

export function sortContentList(contentList, sortByCriteriaIndex, sortByDirectionIsAscending) {
  switch (sortByCriteriaIndex) {
    case 0:
      return contentList.sort((a, b) => {
        if (a.created < b.created) {
          return sortByDirectionIsAscending ? 1 : -1;
        } else if (a.created > b.created) {
          return sortByDirectionIsAscending ? -1 : 1;
        } else {
          return 0;
        }
      });
    case 1:
      return contentList.sort((a, b) => {
        const compareAItem = getName(a).toLowerCase();
        const compareBItem = getName(b).toLowerCase();
        if (compareAItem < compareBItem) {
          return sortByDirectionIsAscending ? -1 : 1;
        } else if (compareAItem > compareBItem) {
          return sortByDirectionIsAscending ? 1 : -1;
        } else {
          return 0;
        }
      });
    case 2:
      return contentList.sort((a, b) => {
        // if both are resources, sort them by itemtype
        if (a.itemtype === 'resource' && b.itemtype === 'resource') {
          if (a.type < b.type) {
            return sortByDirectionIsAscending ? -1 : 1;
          } else if (a.type > b.type) {
            return sortByDirectionIsAscending ? 1 : -1;
          } else {
            return 0;
          }
        } else {
          // keep resources and others apart
          if (a.itemtype === 'resource') {
            return sortByDirectionIsAscending ? 1 : -1;
          } else if (b.itemtype === 'resource') {
            return sortByDirectionIsAscending ? -1 : 1;
          } else {
            // if we get here neither of the items is resource so we sort them by itemtype
            if (a.itemtype < b.itemtype) {
              return sortByDirectionIsAscending ? -1 : 1;
            } else if (a.itemtype > b.itemtype) {
              return sortByDirectionIsAscending ? 1 : -1;
            } else {
              return 0;
            }
          }
        }
      });
    default:
      return [];
  }
}

export function sortProjectItemsList(items, sortByCriteriaIndex, sortByDirectionIsAscending) {
  const contentList = [...items];
  switch (sortByCriteriaIndex) {
    case 0:
      return contentList.sort((a, b) => {
        if (a.created < b.created) {
          return sortByDirectionIsAscending ? 1 : -1;
        } else if (a.created > b.created) {
          return sortByDirectionIsAscending ? -1 : 1;
        } else {
          return 0;
        }
      });
    case 1:
      return contentList.sort((a, b) => {
        if (a.name < b.name) {
          return sortByDirectionIsAscending ? -1 : 1;
        } else if (a.name > b.name) {
          return sortByDirectionIsAscending ? 1 : -1;
        } else {
          return 0;
        }
      });
    case 2:
      return contentList.sort((a, b) => {
        // if both are resources, sort them by itemtype
        if (a.itemtype === 'resource' && b.itemtype === 'resource') {
          if (a.type < b.type) {
            return sortByDirectionIsAscending ? -1 : 1;
          } else if (a.type > b.type) {
            return sortByDirectionIsAscending ? 1 : -1;
          } else {
            return 0;
          }
        } else {
          // keep resources and others apart
          if (a.itemtype === 'resource') {
            return sortByDirectionIsAscending ? 1 : -1;
          } else if (b.itemtype === 'resource') {
            return sortByDirectionIsAscending ? -1 : 1;
          } else {
            // if we get here neither of the items is resource so we sort them by itemtype
            if (a.itemtype < b.itemtype) {
              return sortByDirectionIsAscending ? -1 : 1;
            } else if (a.itemtype > b.itemtype) {
              return sortByDirectionIsAscending ? 1 : -1;
            } else {
              return 0;
            }
          }
        }
      });
    default:
      return [];
  }
}

export const getRelativeCoordinates = (event, element) => {
  const position = {
    x: event.pageX,
    y: event.pageY,
  };

  const offset = {
    left: element.offsetLeft,
    top: element.offsetTop,
  };

  let reference = element.offsetParent;

  while (reference != null) {
    offset.left += reference.offsetLeft;
    offset.top += reference.offsetTop;
    reference = reference.offsetParent;
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
  };
};

export const downloadFile = (fileName, fileUrl) => {
  // eslint-disable-next-line
  const newPromise = new Promise((resolve, _) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = fileUrl;
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
    resolve('done');
  });
  return newPromise;
};

export const addToFavourites = (item) => {
  const favourites = window.localStorage.getItem('favourites');
  if (favourites) {
    const parsedFavourites = JSON.parse(favourites);
    const newFavourites = [...parsedFavourites, item];
    const stringifiedNewFavourites = JSON.stringify(newFavourites);
    window.localStorage.setItem('favourites', stringifiedNewFavourites);
  } else {
    const stringifiedItem = JSON.stringify([item]);
    window.localStorage.setItem('favourites', stringifiedItem);
  }
};

export const removeFromFavourites = (item) => {
  const favourites = window.localStorage.getItem('favourites');
  const parsedFavourites = JSON.parse(favourites);
  const filteredList = parsedFavourites.filter((favourite) => favourite.id !== item.id);
  const stringifiedNewFavourites = JSON.stringify(filteredList);
  window.localStorage.setItem('favourites', stringifiedNewFavourites);
};

export const createNewVideoElement = (video) => {
  const videoDomElement = document.createElement('video');
  videoDomElement.setAttribute('id', `project-main-view-player-${video.id.S || video.id}`);
  videoDomElement.setAttribute('class', 'video-js');
  videoDomElement.setAttribute('controls', 'true');
  videoDomElement.setAttribute('preload', 'auto');
  videoDomElement.setAttribute('data-setup', '{}');
  videoDomElement.setAttribute('width', '880px');
  videoDomElement.setAttribute('crossorigin', 'anonymous');
  const videoDomElementDisplayValue = `${video.id !== 1 ? 'inherit' : 'none'}`;
  videoDomElement.setAttribute('display', videoDomElementDisplayValue);
  videoDomElement.addEventListener('click', this.handleVideoClickEvent);
  videoDomElement.addEventListener('playing', this.videoDomElementPlayingListeningFunction);

  const videoDomSourceElement = document.createElement('source');
  videoDomSourceElement.setAttribute('src', video.signedUrl);
  // const videoExt = getExtFromFileName(video.key.S);
  // videoDomSourceElement.setAttribute('type', `video/${videoExt}`);
  videoDomSourceElement.setAttribute(
    'type',
    video.mimeType === 'video/quicktime' ? 'video/mp4' : video.mimeType,
  );
  videoDomElement.appendChild(videoDomSourceElement);

  return videoDomElement;
};

export const getValueFromUserAttributes = (userAttributes, valueName) => {
  if (userAttributes) {
    const compAttributeObj = userAttributes.find((attributeObj) => attributeObj.Name === valueName);
    if (compAttributeObj) {
      return compAttributeObj.Value;
    }
    return undefined;
  } else {
    return undefined;
  }
};

export const dataURItoBlob = (dataURI) => {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i += 1) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
};

const checkIfAdminInParentGroup = (currentGroupObj, groupsList, currentUserInfo) => {
  const parentGroupObj = groupsList.find((group) => group.id.S === currentGroupObj.parent.S);
  if (parentGroupObj) {
    const userGroupParentObj = currentUserInfo.groups.find(
      (group) => group.id.S === parentGroupObj.id.S,
    );
    if (userGroupParentObj) {
      if (userGroupParentObj.role.S === 'admin') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const currentUserIsAdminOfRootGroup = (currentUserInfo, groupsList) => {
  const {
    workspace: { currentUserGroup },
  } = store.getState();
  if (currentUserInfo && currentUserInfo.groups && groupsList?.length) {
    const currentUserInfoGroupObj = currentUserInfo.groups.find(
      (group) => group.id.S === currentUserGroup,
    );
    const currentGroupObj = groupsList.find((group) => group.id.S === currentUserGroup);
    let showStorageSection = false;
    if (currentUserInfoGroupObj && currentGroupObj) {
      if (currentUserInfoGroupObj.role.S === 'admin' && currentGroupObj.parent.S === 'root') {
        showStorageSection = true;
      } else {
        if (currentGroupObj.parent.S !== 'root') {
          showStorageSection = checkIfAdminInParentGroup(
            currentGroupObj,
            groupsList,
            currentUserInfo,
          );
        } else {
          showStorageSection = false;
        }
      }
    }
    return showStorageSection;
  } else {
    return false;
  }
};

export const validateEmailFormat = (email) => {
  // eslint-disable-next-line
  const validateEmailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validateEmailRegEx.test(String(email).toLowerCase());
};

export const imageExists = (imageSrc) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = (data) => resolve(data);
    img.onerror = (err) => reject(err);
    img.src = imageSrc;
  });

export const getLowerCaseFileExtensionInFile = (file) => {
  const fileExt = getExtFromFileName(file.name);
  const fileNameBase = getNameFromFileName(file.name);
  const lowerCasedFileExt = fileExt.toLowerCase();
  const newCompleteName = `${fileNameBase}.${lowerCasedFileExt}`;
  const capitalizedFileExtensionHandledFile = new File([file], newCompleteName, {
    type: file.type,
  });
  return capitalizedFileExtensionHandledFile;
};

const getFullName = (user) => {
  const firstName = user.cognitoAttributes.UserAttributes.find(
    (attribute) => attribute.Name === 'given_name',
  ).Value;
  const lastName = user.cognitoAttributes.UserAttributes.find(
    (attribute) => attribute.Name === 'family_name',
  ).Value;
  return `${firstName} ${lastName}`;
};

export const sortParticipantsListByFullName = (list) => {
  if (list) {
    const sortedList = list.sort((userA, userB) => {
      const userAName = getFullName(userA);
      const userBName = getFullName(userB);
      if (userAName < userBName) {
        return -1;
      }
      if (userAName > userBName) {
        return 1;
      }
      return 0;
    });
    return sortedList;
  } else {
    return undefined;
  }
};

export const sortGroupsListByName = (list) =>
  list.sort((groupA, groupB) => {
    if (groupA.title.S < groupB.title.S) {
      return -1;
    }
    if (groupA.title.S > groupB.title.S) {
      return 1;
    }
    return 0;
  });

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getCurrentWorkspace = (groupsList) => {
  const {
    project: { currentUserGroup },
  } = store.getState();
  return groupsList.find((group) => group.id.S === currentUserGroup);
};

export const currentWorkspaceHasExpired = (groupsList) => {
  const currentWorkspace = getCurrentWorkspace(groupsList);
  return hasExpired(currentWorkspace);
};

export const userIsCurrentRootGroupCreator = (groupsList, currentUserInfo, currentUserGroup) => {
  if (groupsList) {
    const currentGroup = groupsList.find((group) => group.id.S === currentUserGroup);
    if (currentGroup) {
      const currentGroupIsRoot = currentGroup.parent.S === 'root';
      const userIsAdmin = currentUserInfo?.role?.S === 'admin';
      if (currentUserInfo?.creator) {
        const userIsCreator = currentUserInfo.creator.N === '1';
        return currentGroupIsRoot && userIsAdmin && userIsCreator;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 *
 * @param {Array} groupList List of user's groups
 * @param {string} currentGroupId id of the selected workspace
 * @returns {Object | undefined} returns selectedGroup's info if it is root
 *  else returns it's parent (which is root). If group is not connected to root, returns undefined
 */
export const getClosestRootGroup = (groupList, currentGroupId) => {
  const elem = groupList.find((group) => group.id.S === currentGroupId);
  // if element itself is root, return it else return its parent
  if (elem.parent.S === 'root') {
    return elem;
  } else {
    return groupList.find((group) => group.id.S === elem.parent.S);
  }
};

export const userIsRootGroupCreator = (currentUserInfo, groupsList) => {
  const rootGroups = groupsList.filter((group) => group.parent.S === 'root');
  const currentUserCreatorGroup = rootGroups.find(
    (group) => group.creator.S === currentUserInfo.user.S,
  );
  return currentUserCreatorGroup !== undefined;
};

export const getCurrentUserCreatorGroup = (currentUserInfo, groupsList) => {
  if (groupsList && currentUserInfo) {
    const rootGroups = groupsList.filter((group) => (group.parent.S || group.parent) === 'root');
    return rootGroups.find(
      (group) =>
        (group.creator.S || group.creator) === (currentUserInfo?.user?.S || currentUserInfo.uid),
    );
  } else {
    return undefined;
  }
};
