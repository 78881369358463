import {
  getNameFromFileName,
  getExtFromFileName,
  getLowerCaseFileExtensionInFile,
} from '../../../util/UtilFunctions';

const getAlreadyExistingFilesInParentFolderList = (chosenFilesList, content) => {
  const alreadyExistingFilesInParentFolderList = [];
  chosenFilesList.forEach((chosenFile) => {
    const nonResourceFilteredContentList = content.filter((item) => item.itemtype === 'resource');
    const existsInCurrentParent =
      nonResourceFilteredContentList.find((item) => item.name === chosenFile.name) !== undefined;
    if (existsInCurrentParent) {
      alreadyExistingFilesInParentFolderList.push(chosenFile);
    }
  });
  return alreadyExistingFilesInParentFolderList;
};

const getExistingFilesModifiedChosenFilesList = (
  chosenFilesList,
  alreadyExistingFilesInParentFolderList,
) => {
  const existingFilesModifiedChosenFilesList = chosenFilesList.map((chosenFile) => {
    const fileIsInExistingFilesList = alreadyExistingFilesInParentFolderList.find(
      (existingFile) => chosenFile.name === existingFile.name,
    );
    if (fileIsInExistingFilesList) {
      const actualFileName = getNameFromFileName(chosenFile.name);
      const ext = getExtFromFileName(chosenFile.name);
      const newActualFileName = `${actualFileName}_COPY`;
      const newCompleteName = `${newActualFileName}.${ext}`;
      const newFile = new File([chosenFile], newCompleteName, {
        type: chosenFile.type,
      });
      return newFile;
    }
    return chosenFile;
  });
  return existingFilesModifiedChosenFilesList;
};

export const processSelectedFiles = (
  files,
  existingFilesList,
  finishedUploadsList,
  content,
  addNotification,
) => {
  const itemisedFiles = [...files].map((file) => {
    const itemisedFile = { item: file };
    return itemisedFile;
  });
  const capitalizedFileExtensionsHandledChosenFilesList = itemisedFiles.map((file) => {
    const fileItem = { item: getLowerCaseFileExtensionInFile(file.item) };
    return fileItem;
  });
  const finishedUploadsFilterList = capitalizedFileExtensionsHandledChosenFilesList.filter(
    (file) =>
      finishedUploadsList.find((finishedItem) => finishedItem.name === file.item.name) ===
      undefined,
  );
  if (finishedUploadsFilterList.length < files.length) {
    addNotification({
      id: Date.now(),
      type: 'info',
      message: 'Some files are already uploaded',
    });
  }

  const alreadyExistingFilesInParentFolderList = getAlreadyExistingFilesInParentFolderList(
    finishedUploadsFilterList,
    content,
  );
  if (alreadyExistingFilesInParentFolderList.length > 0) {
    const existingFilesModifiedChosenFilesList = getExistingFilesModifiedChosenFilesList(
      finishedUploadsFilterList,
      alreadyExistingFilesInParentFolderList,
    );
    return [...existingFilesList, ...existingFilesModifiedChosenFilesList];
  } else {
    return [...existingFilesList, ...finishedUploadsFilterList];
  }
};

export const checkIfNoFilesToUpload = (filesList, uploadingItemsList, finishedUploadsList) => {
  const filesUploadabilityBooleanList = [];
  filesList.forEach((item) => {
    const itemIsUploading =
      uploadingItemsList.find((uploadingItem) => uploadingItem.name === item.name) !== undefined;
    const itemIsFinished =
      finishedUploadsList.find((finishedItem) => finishedItem.name === item.name) !== undefined;
    filesUploadabilityBooleanList.push(!(itemIsUploading || itemIsFinished));
  });
  const noFilesToUpload = filesUploadabilityBooleanList.find((val) => val === true) === undefined;
  return noFilesToUpload;
};

export const createNewVideoElement = (video, videoWrapperId) => {
  const videoDomElement = document.createElement('video');
  videoDomElement.setAttribute('id', videoWrapperId);
  videoDomElement.setAttribute('class', 'video-js');
  videoDomElement.setAttribute('controls', 'false');
  videoDomElement.setAttribute('preload', 'auto');
  videoDomElement.setAttribute('data-setup', '{}');
  videoDomElement.setAttribute('width', '100px');
  videoDomElement.setAttribute('crossorigin', 'anonymous');
  videoDomElement.setAttribute('display', 'inherit');

  const videoDomSourceElement = document.createElement('source');
  const videoSrc = URL.createObjectURL(video);
  videoDomSourceElement.setAttribute('src', videoSrc);
  videoDomSourceElement.setAttribute(
    'type',
    video.type === 'video/quicktime' ? 'video/mp4' : video.type,
  );

  videoDomElement.appendChild(videoDomSourceElement);

  return videoDomElement;
};

export const getMaxDepth = (inputFilesList) => {
  let depth = 0;
  [...inputFilesList].forEach((file) => {
    const fileDepth = file.webkitRelativePath.split('/').length;
    if (depth < fileDepth) {
      depth = fileDepth;
    }
  });
  return depth;
};

const getFolderTotalSize = (inputFilesList) => {
  let totalSize = 0;
  [...inputFilesList].forEach((file) => {
    totalSize = totalSize + file.size;
    return undefined;
  });
  return totalSize;
};

export const processFolderFiles = (inputFilesList, filesList) => {
  const name = [...inputFilesList][0].webkitRelativePath.split('/')[0];
  const mappedFolderContentList = [...inputFilesList].map((file) => ({
    item: file,
  }));
  const folder = {
    type: 'folder',
    name,
    size: getFolderTotalSize(inputFilesList),
    content: mappedFolderContentList,
    id: undefined,
  };
  const updatedList = [...filesList, { item: folder }];
  return updatedList;
};

export const getUpdatedFilesList = (filesList, listItemDetails) =>
  filesList.map((file) => {
    if (file.item.name === listItemDetails.name) {
      const updateFilesItem = { ...file, details: listItemDetails.thumbnail };
      return updateFilesItem;
    }
    return file;
  });
