import { cutItemTitle } from '../../../util/FitTextIntoWidth';
import { capitalize } from '../../../util/UtilFunctions';

const getCurrentFolderBreadCrumbItem = (currentFolder) => {
  if (currentFolder.breadcrumb) {
    if (currentFolder.breadcrumb.length >= 0) {
      return {
        title: currentFolder.title,
        id: currentFolder.id,
        itemtype: currentFolder.itemtype,
      };
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getModifiedBreadCrumbsArray = (currentFolder, workspaceName) => {
  const currentFolderBreadCrumbItem = getCurrentFolderBreadCrumbItem(currentFolder);
  const rootBreadCrumbItem = {
    title: workspaceName,
    id: undefined,
    itemtype: undefined,
  };
  if (currentFolder === 'root') {
    return [rootBreadCrumbItem];
  } else if (currentFolder.breadcrumb.length === 0) {
    return [rootBreadCrumbItem, currentFolderBreadCrumbItem];
  } else if (currentFolder.breadcrumb.length > 0) {
    return [rootBreadCrumbItem, ...currentFolder.breadcrumb, currentFolderBreadCrumbItem];
  } else {
    return undefined;
  }
};

export const getItemClassName = (item, currentFolder, workspaceName) => {
  if (!item.id && currentFolder === workspaceName) {
    return 'unclickable';
  } else if (currentFolder.id === item.id) {
    return 'unclickable';
  } else {
    return 'clickable';
  }
};

export const getItemTitle = (item) => {
  if (item === 'root') {
    return 'Home';
  } else {
    const cutTitle = cutItemTitle(item, 120);
    return capitalize(cutTitle);
  }
};
