import { Storage as AWSStorage } from 'aws-amplify';
import uuidv4 from 'uuid';
import store from '../configureStore';
import { getExtFromFileName } from '../util/UtilFunctions';

export const putToStorageDefault = (file, fileName) => {
  const rndUuid = uuidv4();
  const {
    workspace: { currentUserGroup },
    user: { identityId },
  } = store.getState();
  const fileExt = file.name ? getExtFromFileName(file.name) : getExtFromFileName(fileName);
  const newFileName = `${currentUserGroup}/${rndUuid}.${fileExt}`;
  const putToStorageConfigObj = {
    level: 'private',
    contentType: file.type,
    identityId,
  };
  return AWSStorage.put(newFileName, file, putToStorageConfigObj);
};

export const putToStorageProfileImage = (file, fileName) => {
  const rndUuid = uuidv4();
  const {
    user: { identityId },
  } = store.getState();
  const fileExt = file.name ? getExtFromFileName(file.name) : getExtFromFileName(fileName);
  const newFileName = `${rndUuid}.${fileExt}`;
  const putToStorageConfigObj = {
    level: 'private',
    contentType: file.type,
    identityId: identityId,
  };
  return AWSStorage.put(newFileName, file, putToStorageConfigObj);
};

export const putToStorageResource = (file, progressCallback) => {
  const {
    workspace: { currentUserGroup },
    user: { identityId },
  } = store.getState();
  const rndUuid = uuidv4();
  const fileExt = getExtFromFileName(file.name);
  const chosenFileType = file.type.split('/')[0];
  const updatedFileName = `${currentUserGroup}/${chosenFileType}-${rndUuid}.${fileExt}`;
  const putToStorageConfigObj = {
    level: 'private',
    contentType: file.type,
    identityId,
    progressCallback,
  };
  return AWSStorage.put(updatedFileName, file, putToStorageConfigObj);
};

export const putToStorageVideoSnapShot = (blobData) => {
  const {
    workspace: { currentUserGroup },
    user: { identityId },
  } = store.getState();
  const rndUuid = uuidv4();
  const thumbnailNewFileName = `${currentUserGroup}/${rndUuid}.jpeg`;
  const putToStorageConfigObj = {
    level: 'private',
    contentType: blobData.type,
    identityId: identityId,
  };
  return AWSStorage.put(thumbnailNewFileName, blobData, putToStorageConfigObj);
};

export const putToStorageTaskSnapShot = (blobData) => {
  const rndUuid = uuidv4();
  const {
    workspace: { currentUserGroup },
    user: { identityId },
  } = store.getState();
  const newFileName = `${currentUserGroup}/task-${rndUuid}.jpeg`;
  const putToStorageConfigObj = {
    level: 'private',
    contentType: blobData.type,
    identityId,
  };
  return AWSStorage.put(newFileName, blobData, putToStorageConfigObj);
};
