import React from 'react';
import PropTypes from 'prop-types';
import ChangePasswordSection from './changePasswordSection/ChangePasswordSection';
import ProfileModals from './profileModals/ProfileModals';
import GeneralInfoSection from './generalInfoSection/GeneralInfoSection';
import { currentUserInfoShape } from '../../../../apiServices/userApiTypes';
import { groupShape } from '../../../AppPropTypes';
import CompanyInfoSection from './companyInfoSection/CompanyInfoSection';
import { getCurrentUserCreatorGroup } from '../../../../util/UtilFunctions';
import { workspaceDetailsShape } from '../../../../apiServices/group/GroupApiTypes';

class AccountSettingsProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: undefined,
    };
    this.cancelled = false;
  }

  componentWillUnmount() {
    this.cancelled = true;
  }

  handleCloseModalClick = () => {
    this.setState({ modalOpen: undefined });
  };

  updateModalOpen = (modalType) => {
    this.setState({ modalOpen: modalType });
  };

  render = () => {
    const currentUserCreatorGroup = getCurrentUserCreatorGroup(
      this.props.currentUserInfo,
      this.props.groupsList,
    );
    return (
      <>
        <GeneralInfoSection
          currentUserInfo={this.props.currentUserInfo}
          updateCurrentUserInfo={this.props.updateCurrentUserInfo}
          updateModalOpen={this.updateModalOpen}
          updateIsQuerying={this.props.updateIsQuerying}
          groupsList={this.props.groupsList}
          getWorkspacePeopleList={this.props.getWorkspacePeopleList}
        />
        {currentUserCreatorGroup && (
          <CompanyInfoSection
            currentUserInfo={this.props.currentUserInfo}
            updateCurrentUserInfo={this.props.updateCurrentUserInfo}
            rootWorkspaceDetails={this.props.rootWorkspaceDetails}
            groupsList={this.props.groupsList}
            currentUserGroup={this.props.currentUserGroup}
            currentUserCreatorGroup={currentUserCreatorGroup}
            updateRootWorkspaceDetails={this.props.updateRootWorkspaceDetails}
          />
        )}
        <ChangePasswordSection updateIsQuerying={this.props.updateIsQuerying} />
        {this.state.modalOpen && (
          <ProfileModals
            modalOpen={this.state.modalOpen}
            currentUserInfo={this.props.currentUserInfo}
            handleCloseModalClick={this.handleCloseModalClick}
            updateCurrentUserInfo={this.props.updateCurrentUserInfo}
            updateIsQuerying={this.props.updateIsQuerying}
            getWorkspacePeopleList={this.props.getWorkspacePeopleList}
          />
        )}
      </>
    );
  };
}

export default AccountSettingsProfile;

AccountSettingsProfile.propTypes = {
  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  updateCurrentUserInfo: PropTypes.func.isRequired,
  updateRootWorkspaceDetails: PropTypes.func.isRequired,
  rootWorkspaceDetails: PropTypes.shape(workspaceDetailsShape),
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
  getWorkspacePeopleList: PropTypes.func.isRequired,
  currentUserGroup: PropTypes.string.isRequired,
};

AccountSettingsProfile.defaultProps = {
  rootWorkspaceDetails: undefined,
};
