export const getShouldRenderAddFileButton = (location, currentUserInfo) => {
  const pathnameSplitArr = location.pathname.split('/');
  const isAtRoot = pathnameSplitArr[pathnameSplitArr.length - 1] === 'root';
  if (currentUserInfo && currentUserInfo.role) {
    return !isAtRoot && currentUserInfo.role.S !== 'viewer';
  } else {
    return false;
  }
};

export const getShouldRenderAddItemButton = (location, currentUserInfo) => {
  const pathnameSplitArr = location.pathname.split('/');
  const isAtRoot = pathnameSplitArr[pathnameSplitArr.length - 1] === 'root';
  if (currentUserInfo && currentUserInfo.role) {
    return (isAtRoot || pathnameSplitArr[2] !== 'project') && currentUserInfo.role.S !== 'viewer';
  } else {
    return false;
  }
};
