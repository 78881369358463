import React from 'react';
import PropTypes from 'prop-types';
import classes from './AccountSettings.module.css';
import AccountSettingsNavigationBar from './accountSettingsNavigationBar/AccountSettingsNavigationBar';
import AccountSettingsProfile from './accountSettingsProfile/AccountSettingsProfile';
import AccountSettingsWorkspace from './accountSettingsWorkspace/AccountSettingsWorkspace';
import { AccountSettingsBilling } from './accountSettingsBilling/AccountSettingsBilling';
import { AccountSettingsSupport } from './accountSettingsSupport/AccountSettingsSupport';
import {
  getValueFromUserAttributes,
  userIsCurrentRootGroupCreator,
} from '../../../util/UtilFunctions';
import { matchShape, locationShape, historyShape } from '../../../routerPropTypes';
import { currentUserInfoShape } from '../../../apiServices/userApiTypes';
import { groupShape } from '../../AppPropTypes';
import ErrorBoundary from '../../errorBoundary/ErrorBoundary';
import { workspaceDetailsShape } from '../../../apiServices/group/GroupApiTypes';

class AccountSettings extends React.Component {
  componentDidMount = () => {
    if (this.props.currentUserInfo) {
      const currentItem = this.getCurrentItem(this.props.currentUserInfo);
      this.props.updateCurrentItem(currentItem);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.currentUserInfo && this.props.currentUserInfo) {
      const currentItem = this.getCurrentItem(this.props.currentUserInfo);
      this.props.updateCurrentItem(currentItem);
    }
  };

  getCurrentItem = (currentUserInfo) => {
    const firstName = getValueFromUserAttributes(
      currentUserInfo.cognitoAttributes.UserAttributes,
      'given_name',
    );
    const lastName = getValueFromUserAttributes(
      currentUserInfo.cognitoAttributes.UserAttributes,
      'family_name',
    );
    return {
      id: {
        S: currentUserInfo.cognitoAttributes.UserAttributes.Username,
      },
      title: {
        S: `${firstName} ${lastName}`,
      },
      itemtype: {
        S: 'account',
      },
    };
  };

  getUrlLastItem = (match) => {
    const urlLastSlashIndex = match.url.lastIndexOf('/');
    return match.url.substring(urlLastSlashIndex + 1);
  };

  render() {
    const urlLastItem = this.getUrlLastItem(this.props.match);
    const isCurrentRootGroupCreator = userIsCurrentRootGroupCreator(
      this.props.groupsList,
      this.props.currentUserInfo,
      this.props.currentUserGroup,
    );
    return (
      <ErrorBoundary>
        <div className={classes.wrapper}>
          {this.props.currentUserInfo && (
            <>
              <AccountSettingsNavigationBar
                match={this.props.match}
                userIsCurrentRootGroupCreator={isCurrentRootGroupCreator}
                groupsList={this.props.groupsList}
              />
              {urlLastItem === 'profile' && (
                <AccountSettingsProfile
                  currentUserInfo={this.props.currentUserInfo}
                  updateIsQuerying={this.props.updateIsQuerying}
                  updateCurrentUserInfo={this.props.updateCurrentUserInfo}
                  refreshList={this.props.initiateSession}
                  updateRootWorkspaceDetails={this.props.updateRootWorkspaceDetails}
                  rootWorkspaceDetails={this.props.rootWorkspaceDetails}
                  groupsList={this.props.groupsList}
                  getWorkspacePeopleList={this.props.getWorkspacePeopleList}
                  currentUserGroup={this.props.currentUserGroup}
                />
              )}
              {urlLastItem === 'workspace' &&
                this.props.groupsList &&
                this.props.groupsList.length > 0 && (
                  <AccountSettingsWorkspace
                    groupsList={this.props.groupsList}
                    currentUserGroup={this.props.currentUserGroup}
                    currentUserInfo={this.props.currentUserInfo}
                    getWorkspacePeopleList={this.props.getWorkspacePeopleList}
                    updateIsQuerying={this.props.updateIsQuerying}
                    initiateSession={this.props.initiateSession}
                    refreshList={this.props.updateGroupsList}
                    updateCurrentUserInfo={this.props.updateCurrentUserInfo}
                    history={this.props.history}
                  />
              )}
              {urlLastItem === 'billing' && isCurrentRootGroupCreator && (
                <AccountSettingsBilling />
              )}
              {urlLastItem === 'support' && <AccountSettingsSupport />}
            </>
          )}
        </div>
      </ErrorBoundary>
    );
  }
}

export default AccountSettings;

AccountSettings.propTypes = {
  // router props
  match: PropTypes.shape(matchShape).isRequired,
  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
  currentUserGroup: PropTypes.string.isRequired,
  updateCurrentItem: PropTypes.func.isRequired,
  initiateSession: PropTypes.func.isRequired,
  updateGroupsList: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  getWorkspacePeopleList: PropTypes.func.isRequired,
  updateCurrentUserInfo: PropTypes.func.isRequired,
  updateRootWorkspaceDetails: PropTypes.func.isRequired,
  rootWorkspaceDetails: PropTypes.shape(workspaceDetailsShape),
  location: PropTypes.shape(locationShape).isRequired,
  history: PropTypes.shape(historyShape).isRequired,
};

AccountSettings.defaultProps = {
  rootWorkspaceDetails: undefined,
};
