import React from 'react';
import classes from './ListActionables.module.scss';
import SelectAllIcon from '../../icons/SelectAllIcon';
import ShareIcon from '../../icons/ShareIcon';
import DeleteIcon from '../../icons/DeleteIcon';
import DownloadIcon from '../../icons/DownloadIcon';
import CopyIcon from '../../icons/CopyIcon';
import MoveIcon from '../../icons/MoveIcon';
import CheckedIcon from '../../icons/CheckedIcon';
import {
  getResourceDetails,
  updateResourceProjectPreviewStatus,
} from '../../../apiServices/resourceApi';
import { getExtFromFileName } from '../../../util/UtilFunctions';
import { validateFileTypeRendability } from '../../../util/FileTypeSupportCheck';
import NotificationContext from '../../../context/notification';

class ListActionables extends React.Component {
  handleDeleteSelectedClick = () => {
    this.props.updateModalOpen('delete');
  };

  handleSelectAllClick = () => {
    this.props.updateSelectedItems(this.props.content);
  };

  handleDeselectAllClick = () => {
    this.props.updateSelectedItems([]);
  };

  getResourceDetailsPromiseList = (selectedItems) => {
    const resourceDetailsPromiseList = [];
    selectedItems.forEach((item) => {
      const newPromise = new Promise((resolve, reject) => {
        getResourceDetails(item.id)
          .then((response) => {
            const modifiedItem = {
              url: response.response.Item.signedUrl,
              id: response.response.Item.id,
              name: response.response.Item.name,
            };
            resolve(modifiedItem);
          })
          .catch((error) => reject(error));
      });
      resourceDetailsPromiseList.push(newPromise);
    });
    return resourceDetailsPromiseList;
  };

  getSelectedFilesDetails = async (selectedItems) => {
    const resourceDetailsPromiseList = this.getResourceDetailsPromiseList(selectedItems);
    try {
      const resourceDetailsResponse = await Promise.all(resourceDetailsPromiseList);
      return resourceDetailsResponse;
    } catch (getResourceDetailsError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not get resource details',
      });
      return undefined;
    }
  };

  handleShareSelectedClick = async () => {
    const selectionContainsNonResourceItems =
      this.props.selectedItems.find((item) => item.itemtype !== 'resource') !== undefined;
    if (!selectionContainsNonResourceItems) {
      this.props.updateIsQuerying(true);
      const selectedFilesDetails = await this.getSelectedFilesDetails(this.props.selectedItems);
      this.props.updateIsQuerying(false);
      const modalParams = [
        {
          selectedFiles: selectedFilesDetails,
        },
      ];
      this.props.updateModalOpen('shareFile', modalParams);
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Selection includes unshareable items',
      });
    }
  };

  downloadAll = (files) => {
    async function downloadNext(i) {
      if (files[i]) {
        if (files[i].overLimit) {
          if (i >= files.length) {
            return;
          }
          let a = document.createElement('a');
          a.href = files[i].download;
          a.target = '_parent';
          // Use a.download if available, it prevents plugins from opening.
          if ('download' in a) {
            a.download = files[i].filename;
          }
          // Add a to the doc for click to work.
          (document.body || document.documentElement).appendChild(a);
          if (a.click) {
            a.click(); // The click method is supported by most browsers.
          }
          // Delete the temporary link.
          a.parentNode.removeChild(a);
          // Download the next file with a small timeout. The timeout is necessary
          // for IE, which will otherwise only download the first file.
          setTimeout(() => {
            downloadNext(i + 1);
          }, 500);
        } else {
          try {
            const fetchResponse = await fetch(files[i].download);
            const blob = await fetchResponse.blob();

            let a = document.createElement('a');

            a.href = URL.createObjectURL(blob);
            a.setAttribute('download', files[i].fileName);
            a.click();
            setTimeout(() => {
              downloadNext(i + 1);
            }, 500);
          } catch (fetchError) {
            setTimeout(() => {
              downloadNext(i + 1);
            }, 500);
          }
        }
      }
    }
    // Initiate the first download.
    downloadNext(0);
  };

  handleDownloadSelectedClick = () => {
    const fileList = this.props.selectedItems.map((item) => {
      const isPdf = item.mimeType === 'application/pdf';
      const isAbove20Mb = item.size >= 21000000;
      return {
        pdfOverLimit: isPdf && isAbove20Mb,
        download: item.signedUrl,
        fileName: item.name,
      };
    });
    this.downloadAll(fileList);
    const notificationPopupMessage =
      this.props.selectedItems.length > 1 ? 'Downloaded files' : 'Downloaded file';
    this.context.add({
      id: Date.now(),
      type: 'success',
      message: notificationPopupMessage,
    });
  };

  handleMoveSelectedClick = () => {
    this.props.updateModalOpen('move');
  };

  handleCopySelectedClick = () => {
    const containsOnlyResources =
      this.props.selectedItems.find((item) => item.itemtype !== 'resource') === undefined;
    if (containsOnlyResources) {
      this.props.updateModalOpen('copy');
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Selection includes uncopiable items',
      });
    }
  };

  getPreviewPromisesList = () => {
    const previewPromisesList = [];
    this.props.selectedItems.forEach(async (item) => {
      if (!item.preview) {
        const ext = getExtFromFileName(item.key);
        const isRendableInResourceView = validateFileTypeRendability(item.type, ext);
        if (isRendableInResourceView) {
          const newPromise = new Promise((resolve, reject) => {
            this.props.updateIsQuerying(true);
            updateResourceProjectPreviewStatus(item.id, item.name, 1)
              .then((response) => {
                this.props.updateIsQuerying(false);
                this.props.refreshList(false, undefined, true);
                resolve(response);
              })
              .catch((updateResourceError) => {
                this.props.updateIsQuerying(false);
                reject(updateResourceError);
              });
          });
          previewPromisesList.push(newPromise);
        }
      }
    });
    return previewPromisesList;
  };

  handlePreviewSelectedClick = () => {
    Promise.all(this.getPreviewPromisesList())
      .then(() => {
        !!this.props.setAllPreview && this.props.setAllPreview();
        this.context.add({
          id: Date.now(),
          type: 'success',
          message: 'Updated selected files work mode status',
        });
      })
      .catch((err) => {
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not update selected files work mode status',
        });
      });
  };

  getActiveButtonsList = () => {
    const containsOnlyResources =
      this.props.selectedItems.find((item) => item.itemtype !== 'resource' || item.itemtype?.S) ===
      undefined;

    const deselectAllButtonProps = {
      icon: <SelectAllIcon height="20px" fill="white" />,
      text: 'Deselect',
      onClickHandler: this.handleDeselectAllClick,
      styles: classes.deselectAllButton,
      title: 'deselect files',
    };
    const shareSelectedButtonProps = {
      icon: <ShareIcon height="20px" fill="white" />,
      text: 'Share',
      onClickHandler: this.handleShareSelectedClick,
      styles: classes.shareSelectedButton,
      title: 'share selected files',
    };
    const deleteSelectedButtonProps = {
      icon: <DeleteIcon height="20px" fill="white" />,
      text: 'Delete',
      onClickHandler: this.handleDeleteSelectedClick,
      styles: classes.deleteSelectedButton,
      title: 'delete selected files',
    };
    const downloadSelectedButtonProps = {
      icon: <DownloadIcon height="20px" fill="white" />,
      text: 'Download',
      onClickHandler: this.handleDownloadSelectedClick,
      styles: classes.downloadSelectedButton,
      title: 'download selected files',
    };
    const moveSelectedButtonProps = {
      icon: <MoveIcon height="20px" fill="white" />,
      text: 'Move',
      onClickHandler: this.handleMoveSelectedClick,
      styles: classes.moveSelectedButton,
      title: 'move selected files',
    };
    const copySelectedButtonProps = {
      icon: <CopyIcon height="20px" fill="white" />,
      text: 'Copy',
      onClickHandler: this.handleCopySelectedClick,
      styles: classes.copySelectedButton,
      title: 'copy selected files',
    };
    const previewSelectedButtonProps = {
      icon: <CheckedIcon height="20px" fill="white" />,
      text: 'Preview',
      onClickHandler: this.handlePreviewSelectedClick,
      styles: classes.previewSelectedButton,
      title: 'preview selected files',
    };

    if (!this.props.currentUserIsAViewer) {
      if (containsOnlyResources) {
        if (this.props.useCase === 'folder') {
          return [
            deselectAllButtonProps,
            shareSelectedButtonProps,
            deleteSelectedButtonProps,
            downloadSelectedButtonProps,
            moveSelectedButtonProps,
            copySelectedButtonProps,
          ];
        } else if (this.props.useCase === 'project') {
          return [
            deselectAllButtonProps,
            shareSelectedButtonProps,
            deleteSelectedButtonProps,
            downloadSelectedButtonProps,
            moveSelectedButtonProps,
            copySelectedButtonProps,
            previewSelectedButtonProps,
          ];
        } else if (this.props.useCase === 'link') {
          return [deselectAllButtonProps, downloadSelectedButtonProps];
        }
      } else {
        return [deselectAllButtonProps, moveSelectedButtonProps];
      }
    } else {
      if (containsOnlyResources) {
        return [deselectAllButtonProps, downloadSelectedButtonProps, shareSelectedButtonProps];
      } else {
        return [];
      }
    }
    return [];
  };

  getPassiveButtonsList = () => {
    const selectAllButtonProps = {
      icon: <SelectAllIcon height="20px" fill="white" />,
      text: 'Select all',
      onClickHandler: this.handleSelectAllClick,
      styles: classes.selectAllButton,
      title: 'select files',
    };
    return [selectAllButtonProps];
  };

  getActiveButtonListItems = () => {
    const activeButtonsList = this.getActiveButtonsList();
    return activeButtonsList.map((button) => (
      <li className={classes.listItem} key={button.text}>
        <button
          className={button.styles}
          onClick={button.onClickHandler}
          type="button"
          title={button.title}
        >
          {button.icon}
          <h5 className={classes.text}>{button.text}</h5>
        </button>
      </li>
    ));
  };

  getPassiveButtonListItems = () => {
    const passiveButtonsList = this.getPassiveButtonsList(
      this.props.content,
      this.props.currentUserIsAViewer,
    );
    return passiveButtonsList.map((button) => (
      <li className={classes.listItem} key={button.text}>
        <button
          className={button.styles}
          onClick={button.onClickHandler}
          type="button"
          title={button.title}
        >
          {button.icon}
          <h5 className={classes.text}>{button.text}</h5>
        </button>
      </li>
    ));
  };

  static contextType = NotificationContext;

  render() {
    const containsOnlyResources =
      this.props.content.find((item) => item.itemtype !== 'resource') === undefined;
    return (
      <>
        {!(!containsOnlyResources && this.props.currentUserIsAViewer) && (
          <div className={classes.wrapper}>
            {this.props.selectedItems.length !== 0 && (
              <>
                <h5 className={classes.filesSelectedText}>
                  {`${this.props.selectedItems.length} files selected`}
                </h5>
                <ul className={classes.list}>{this.getActiveButtonListItems()}</ul>
              </>
            )}
            {this.props.selectedItems.length === 0 && (
              <>
                <h5 className={classes.filesSelectedText}>
                  {`${this.props.selectedItems.length} files selected`}
                </h5>
                <ul className={classes.list}>{this.getPassiveButtonListItems()}</ul>
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

export default ListActionables;

// ListActionables.propTypes = {
//   // App.jsx props
//   updateIsQuerying: PropTypes.func.isRequired,

//   // FolderView.jsx props
//   refreshList: PropTypes.func.isRequired,
//   content: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
//   useCase: useCaseType.isRequired,

//   // ItemList.jsx props
//   selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
//   updateSelectedItems: PropTypes.func.isRequired,
//   updateModalOpen: PropTypes.func.isRequired,
//   currentUserIsAViewer: PropTypes.bool.isRequired,
// };
