import React from 'react';
import PropTypes from 'prop-types';

const MoveIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={props.height}>
    <path fill={props.fill} d="M480 256l-96-96v64h-96v-96h64l-96-96-96 96h64v96h-96v-64l-96 96 96 96v-64h96v96h-64l96 96 96-96h-64v-96h96v64z" />
  </svg>
);
export default MoveIcon;

MoveIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

MoveIcon.defaultProps = {
  height: '0',
};
