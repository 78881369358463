import React from 'react';
import PropTypes from 'prop-types';
import TaskMessagesList from '../taskMessagesList/TaskMessagesList';
import { taskMessagesItemShape } from '../../../../ProjectMainViewPropTypes';
import { workspaceParticipantShape } from '../../../../../../../AppPropTypes';
import ExperimentalButton from '../../../../../../../../components/buttons/experimentalButton/experimentalButton';
import './contentSection.scss';
import { connect } from 'react-redux';

class ContentSection extends React.Component {
  state = {
    taskMessageInputFieldValue: '',
  };

  handleTaskMessageInputFieldValueChange = (event) => {
    this.setState({ taskMessageInputFieldValue: event.target.value });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    this.props.handleTaskMessageSubmit(this.state.taskMessageInputFieldValue);
    this.setState({ taskMessageInputFieldValue: '' });
  };

  render = () => (
    <div className="contentSection">
      <TaskMessagesList messages={this.props.messages} />
      {this.props.userRole !== 'guest' && (
        <form onSubmit={this.handleFormSubmit}>
          <div className="textAreaWrapper">
            <textarea
              type="text"
              placeholder="Add new comment"
              value={this.state.taskMessageInputFieldValue}
              onChange={this.handleTaskMessageInputFieldValueChange}
              autoFocus
            />
          </div>

          <div className="submitSectionWrapper">
            <ExperimentalButton
              loading={this.props.commentUploadingProcess.status === 'loading'}
              onClick={this.handleSubmit}
              htmlType="submit"
            >
              Send
            </ExperimentalButton>
          </div>
        </form>
      )}
    </div>
  );
}

const mapStateToProps = ({ project }) => ({ userRole: project.userRole });
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContentSection);

ContentSection.propTypes = {
  // app.jsx props
  users: PropTypes.arrayOf(PropTypes.shape(workspaceParticipantShape)).isRequired,

  // projecMainViewWorkSection.jsx prop
  messages: PropTypes.arrayOf(PropTypes.shape(taskMessagesItemShape)).isRequired,
  // TaskMessage.jsx props
  handleTaskMessageSubmit: PropTypes.func.isRequired,
  commentUploadingProcess: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
};
