const dataURItoBlob = (dataURI) => {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i += 1) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
};

const takeVideoSnapshotAtCurrentTime = (playerElementId) => {
  const videojsElement = document.getElementById(playerElementId);
  const videoDomElement = videojsElement.getElementsByTagName('video')[0];

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = videoDomElement.videoWidth;
  canvas.height = videoDomElement.videoHeight;

  context.drawImage(videoDomElement, 0, 0, canvas.width, canvas.height);
  const dataURL = canvas.toDataURL();
  return dataURL;
};

export const getVideoSnapshot = (playerId) => {
  const snapShotData = takeVideoSnapshotAtCurrentTime(playerId);
  const blobData = dataURItoBlob(snapShotData);
  return blobData;
};
