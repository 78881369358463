import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import classes from './ItemListItem.module.scss';
import { cutItemTitle } from '../../../util/FitTextIntoWidth';
import ProjectIcon from '../../icons/ProjectIcon';
import FolderIcon from '../../icons/AllfilesIcon';
import ItemListItemContentAmountsDisplay from '../itemListItemContentAmountsDisplay/ItemListItemContentAmountsDisplay';
import ItemActionables from '../itemActionables/ItemActionables';
import { ReactComponent as StarIcon } from '../../../assets/starIconFull.svg';
import {
  folderListNonResourceItemShape,
  folderListItemShape,
} from '../../../app/views/folderView/FolderViewPropTypes';
import { useCaseType } from '../ItemListPropTypes';
import {
  getListItem,
  getWrapperStyles,
  getIsFavourite,
  getListClass,
} from './ItemListItemUtilityFunctions';

class ItemListItem extends React.Component {
  constructor(props) {
    super(props);
    this.listItemNode = React.createRef();
    this.state = {
      isHoveringOver: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    if (!this.listItemNode.current.contains(event.target)) {
      if (this.props.item.id === this.props.dropdownOpenedItemId) {
        this.props.updateDropdownOpenedItemId();
      } else if (this.props.item.id === this.props.renamingItemId) {
        this.props.updateRenamingItemId();
      }
    }
  };

  handleMouseEnter = () => {
    this.setState({ isHoveringOver: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHoveringOver: false });
  };

  renderPreviewImages = (previewImages, itemType) => {
    if (previewImages?.length === 1) {
      return (
        <div
          className={
            itemType === 'project'
              ? classes.projectPreviewImagesWrapper
              : classes.folderPreviewImageWrapper
          }
        >
          <img src={previewImages[0].S} alt="previewImage" />
        </div>
      );
    } else {
      const previewImageListItems = previewImages.map((image, index) => (
        <li className={getListItem(previewImages.length, index)} key={image?.S}>
          <img src={image?.S} alt="thumbnail" />
        </li>
      ));
      return <ul className={getListClass(previewImages.length)}>{previewImageListItems}</ul>;
    }
  };

  getItemDisplay = () => {
    const previewImages = this.props.item.previewImages?.filter((i) => !!i);
    return (
      <Link
        key={this.props.item.id}
        to={`/in/${this.props.item.itemtype}/${this.props.item.id}`}
        className={!!previewImages?.length ? classes.link : classes.emptyProjectLink}
        onClick={this.handleLinkClick}
      >
        {!previewImages?.length ? (
          <>
            <div className={classes.emptyProjectSticker}>
              {this.props.item.itemtype === 'project' && <ProjectIcon height="80px" fill="white" />}
              {this.props.item.itemtype === 'folder' && <FolderIcon height="80px" fill="white" />}
              <p className={classes.projectStickerText}>
                {this.props.item.itemtype === 'project' ? 'Project' : 'Folder'}
              </p>
            </div>
            {this.props.item.resources !== undefined && (
              <EmptyProjectContentAmountsDisplayWrapper>
                <ItemListItemContentAmountsDisplay item={this.props.item} />
              </EmptyProjectContentAmountsDisplayWrapper>
            )}
          </>
        ) : (
          <>
            <div className={classes.gridFiller} />
            {this.props.item.resources !== undefined && (
              <ItemListItemContentAmountsDisplay item={this.props.item} />
            )}
            {this.props.item.itemtype === 'project' && (
              <div className={classes.projectSticker}>
                <ProjectIcon height="40px" fill="white" />
                <p className={classes.projectStickerText}>PROJECT</p>
              </div>
            )}
            {!!previewImages?.length &&
              this.renderPreviewImages(previewImages, this.props.item.itemType)}
          </>
        )}
      </Link>
    );
  };

  handleLinkClick = (event) => {
    if (this.props.dropdownOpenedItemId === this.props.item.id) {
      event.preventDefault();
    }
  };

  render() {
    return (
      <li
        className={getWrapperStyles(
          this.props.item.previewImages !== undefined,
          this.props.isSelected,
        )}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={this.listItemNode}
        title={this.props.item.title}
      >
        <div className={classes.contentWrapper}>
          {this.getItemDisplay()}
          <div className={classes.titleRow}>
            <h4>
              {cutItemTitle(
                this.props.item.title ? this.props.item.title : this.props.item.title,
                200,
              )}
            </h4>
            <span className={classes.starIcon}>
              {getIsFavourite(this.props.item.id) && <StarIcon title="favourite" />}
            </span>
          </div>
          <ItemActionables
            dropdownOpen={this.props.dropdownOpenedItemId === this.props.item.id}
            isHoveringOver={this.state.isHoveringOver}
            isFavourite={getIsFavourite(this.props.item.id)}
            itemHasBeenSelected={this.props.selectedItems.length > 0}
            isSelected={this.props.isSelected}
            item={this.props.item}
            addToSelectedItems={this.props.addToSelectedItems}
            removeFromSelectedItems={this.props.removeFromSelectedItems}
            updateDropdownOpenedItemId={this.props.updateDropdownOpenedItemId}
            currentUserGroup={this.props.currentUserGroup}
            updateModalOpen={this.props.updateModalOpen}
            updateIsQuerying={this.props.updateIsQuerying}
            currentUserIsAViewer={this.props.currentUserIsAViewer}
            useCase={this.props.useCase}
          />
        </div>
      </li>
    );
  }
}

const EmptyProjectContentAmountsDisplayWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export default ItemListItem;

ItemListItem.propTypes = {
  // app.jsx props
  currentUserGroup: PropTypes.string.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,

  // FolderView.jsx props
  currentUserIsAViewer: PropTypes.bool.isRequired,

  // itemList.jsx props
  item: PropTypes.shape(folderListNonResourceItemShape).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  addToSelectedItems: PropTypes.func.isRequired,
  removeFromSelectedItems: PropTypes.func.isRequired,
  updateModalOpen: PropTypes.func.isRequired,
  updateDropdownOpenedItemId: PropTypes.func.isRequired,
  dropdownOpenedItemId: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  renamingItemId: PropTypes.string,
  updateRenamingItemId: PropTypes.func.isRequired,
  useCase: useCaseType.isRequired,
};

ItemListItem.defaultProps = {
  dropdownOpenedItemId: undefined,
  renamingItemId: undefined,
};
