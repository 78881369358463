import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Subnavigation from '../../../../../../components/navigationBar/Subnavigation';
import { ReactComponent as Arrow } from '../../../../../../assets/arrow.svg';
import CheckedIcon from '../../../../../../components/icons/CheckedIcon';
import './showTypeSection.scss';

const ShowTypeSection = ({ handleShowTypesChange, showTypes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [title, setTitle] = useState('All');
  useEffect(() => {
    if (selectAll) {
      setTitle('All');
    } else if (showTypes.some((i) => !i.filterStatus)) {
      // get active element labels and set them as title
      setTitle(
        showTypes
          .filter((elem) => !elem.filterStatus)
          .map((elem) => (elem.label === 'doc' ? 'documents' : `${elem.label}s`))
          .join(', '),
      );
    } else {
      // nothing is selected
      setTitle('None');
    }
  }, [showTypes, selectAll]);
  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleTypeClick = (index) => {
    // if all is selected, deselect all but the one user clicked
    let arr;
    if (selectAll) {
      arr = showTypes.map((elem) => ({ ...elem, filterStatus: true }));
      setSelectAll(false);
    } else {
      arr = [...showTypes];
    }
    // toggle filter status
    arr[index].filterStatus = !arr[index].filterStatus;
    handleShowTypesChange(arr);
  };

  const toggleSelectAll = () => {
    let arr = [];
    if (!selectAll) {
      arr = showTypes.map((elem) => ({ ...elem, filterStatus: false }));
      setSelectAll(true);
    } else {
      arr = showTypes.map((elem) => ({ ...elem, filterStatus: true }));
      setSelectAll(false);
    }
    handleShowTypesChange(arr);
  };

  return (
    <div className="showTypeSection">
      <h5>Show file types:</h5>
      <button onClick={handleButtonClick} type="button" className={isOpen ? 'open' : ''}>
        <h4>{title}</h4>
        <Arrow />
      </button>
      <Subnavigation close={() => setIsOpen(false)} isOpen={isOpen}>
        {showTypes.map((type, index) => {
          const isActive = !type.filterStatus && !selectAll;
          return (
            <div
              className={`type ${isActive ? 'active' : ''}`}
              onClick={() => handleTypeClick(index)}
              key={type.label}
            >
              <span className="checkedIcon">
                <CheckedIcon height="0.7rem" fill="#000" />
              </span>
              <span>{type.label === 'doc' ? 'documents' : `${type.label}s`}</span>
            </div>
          );
        })}
        <div className="divider" />
        <div className={`type selectAll ${selectAll ? 'active' : ''}`} onClick={toggleSelectAll}>
          <span className="checkedIcon">
            <CheckedIcon height="0.7rem" fill="#000" />
          </span>
          <span>All</span>
        </div>
      </Subnavigation>
    </div>
  );
};

export default ShowTypeSection;

ShowTypeSection.propTypes = {
  // Folder.jsx props
  showTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      filterStatus: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  handleShowTypesChange: PropTypes.func.isRequired,
};
