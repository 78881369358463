import PropTypes from 'prop-types';

export const currentSessionShape = {
  idToken: PropTypes.shape({
    jwtToken: PropTypes.string.isRequired,
  }).isRequired,
};

export const currentCredentialsShape = {
  identityId: PropTypes.string.isRequired,
};

export const initShape = {
  headers: PropTypes.shape({
    Authorization: PropTypes.string.isRequired,
  }),
};

const userAttributeShape = {
  Name: PropTypes.string.isRequired,
  Value: PropTypes.string.isRequired,
};

export const cognitoAttributesShape = {
  Enabled: PropTypes.bool.isRequired,
  UserAttributes: PropTypes.arrayOf(PropTypes.shape(userAttributeShape)).isRequired,
  UserCreateDate: PropTypes.string.isRequired,
  UserLastModifiedDate: PropTypes.string,
  UserStatus: PropTypes.string,
  Username: PropTypes.string.isRequired,
};

export const userInfoGroupsListItemShape = {
  active: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  role: PropTypes.shape({
    S: PropTypes.oneOf(['admin', 'pro', 'user', 'viewer']).isRequired,
  }).isRequired,
  type: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
};

export const groupShape = {
  created: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  itemtype: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  parent: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  level: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }),
  creator: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
};

export const workspaceParticipantShape = {
  active: PropTypes.bool.isRequired,
  cognitoAttributes: PropTypes.shape(cognitoAttributesShape).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    S: PropTypes.string,
  }),
  role: PropTypes.oneOf(['admin', 'pro', 'user', 'viewer', 'guest']).isRequired,

  type: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
};

export const localFileShape = {
  lastModified: PropTypes.number.isRequired,
  lastModifiedDate: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export const statusOptions = ['initial', 'loading', 'success', 'error'];
