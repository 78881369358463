export const getParentId = (pathname) => {
  const pathnameArray = pathname.split('/');
  const pathNameLastItem = pathnameArray[pathnameArray.length - 1];
  const parentId = pathNameLastItem === 'welcome' ? 'root' : pathNameLastItem;
  return parentId;
};

// this.props.location.pathname
export const getIsInWelcomeScreen = (pathname) => {
  const pathnameArray = pathname.split('/');
  const pathNameLastItem = pathnameArray[pathnameArray.length - 1];
  return pathNameLastItem === 'welcome';
};

// this.props.currentUserInfo.role.S
// this.props.welcomeScreenSelection
export const getMode = (role, welcomeScreenSelection) => {
  const currentUserIsAdminOrPro = role === 'admin' || role === 'pro';
  if (currentUserIsAdminOrPro) {
    return welcomeScreenSelection;
  } else {
    return 'folder';
  }
};

export const getHeadline = (role, welcomeScreenSelection) =>
  getMode(role, welcomeScreenSelection) !== undefined
    ? `Create new ${getMode(role, welcomeScreenSelection)}`
    : 'Create new';

// this.state.addTitleFieldValue
export const validateTitleField = (titleFieldValue) => {
  if (titleFieldValue.trim().length >= 3) {
    if (titleFieldValue.length < 50) {
      return false;
    } else {
      return "Title can't be over 50 characters";
    }
  } else {
    return 'Title must be at least 3 characters';
  }
};
