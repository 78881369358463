import PropTypes from 'prop-types';
import { statusOptions } from '../../../app/AppPropTypes';

export const imageRemovingProcessShape = {
  status: PropTypes.oneOf(statusOptions).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
};

export const useCaseOptions = ['profile', 'workspace', 'coverImage', 'createNew'];

export const pictureUploadingProcessShape = {
  status: PropTypes.string.isRequired,
  data: PropTypes.shape({
    picture: PropTypes.object,
    croppedImage: PropTypes.object,
  }),
};
