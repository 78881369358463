import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { addMessageToTask, editVideoTask } from '../../../../../../../apiServices/taskApi';
import { taskShape } from '../../../ProjectMainViewPropTypes';
import { workspaceParticipantShape } from '../../../../../../AppPropTypes';
import ContentSection from './contentSection/ContentSection';
import CrossIcon from '../../../../../../../components/icons/CrossIcon';
import { getXYPercent } from '../../projectMainViewImage/ProjectMainViewImageUtilityFunctions';
import './taskMessage.scss';
import NotificationContext from '../../../../../../../context/notification';
import Dot from '../dot/Dot';
import { ConditionalPortal } from './ConditionalPortal';
import { formatMilliSeconds } from '../../../../../../../util/UtilFunctions';
import { lineTypes } from '../../projectMainViewVideoPlayer/arrowsOverlay/utils';
import NumberInput from '../../../../../../../components/inputs/numberInput';
import ExperimentalButton from '../../../../../../../components/buttons/experimentalButton/experimentalButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMessageToTask as addMessageToContext,
  updateTaskDuration,
} from '../../../../../../features/project/projectSlice';
const TaskMessage = ({
  task,
  updateSelectedTask,
  videoFullscreen,
  destination,
  setPlayerCurrentTime,
}) => {
  const [duration, setDuration] = useState(() => parseFloat(task.duration) || 1);
  const [commentUploadingProcess, setCommentUploadingProcess] = useState({ status: 'initial' });
  const [updateLoading, setUpdateLoading] = useState(false);
  const wrapperNode = useRef(null);
  const { add } = React.useContext(NotificationContext);
  const { project, user } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    function handleClickOutside(e) {
      if (wrapperNode.current && !wrapperNode.current.contains(e.target)) {
        updateSelectedTask();
      }
    }
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [updateSelectedTask]);

  const handleCloseButtonClick = () => {
    updateSelectedTask();
  };

  const modifyDuration = (amount) => {
    const startTime = Math.round((task.timestamp / 1000) * 10) / 10;
    setPlayerCurrentTime(startTime + amount);
    setDuration(amount);
  };

  const handleTaskMessageSubmit = async (taskMessageInputFieldValue) => {
    if (taskMessageInputFieldValue !== '') {
      setCommentUploadingProcess({ status: 'loading' });
      try {
        await addMessageToTask(task.id, taskMessageInputFieldValue).then((response) => {
          const newMessageData = {
            content: taskMessageInputFieldValue,
            created: new Date().toString(),
            sender: user.uid,
            userData: project.users.find((i) => i.uid === user.uid),
          };
          dispatch(
            addMessageToContext({
              task,
              message: newMessageData,
            }),
          );
          updateSelectedTask({ ...task, messages: [...task.messages, newMessageData] });
        });

        setCommentUploadingProcess({ status: 'success' });
      } catch (addMessageToTaskError) {
        setCommentUploadingProcess({ status: 'error', error: addMessageToTaskError });
        add({
          id: Date.now(),
          type: 'error',
          message: 'Could not send message',
        });
      }
    } else {
      add({
        id: Date.now(),
        type: 'info',
        message: 'Form is not complete',
      });
    }
  };

  const calculatePosition = (x, y) => {
    let offsetTop;
    let offsetLeft;
    if (y > 66) {
      // align a bit higher
      offsetTop = 18;
    } else if (y < 33) {
      offsetTop = 5;
    } else {
      offsetTop = 10;
    }

    if (x > 50) {
      // align on left side
      offsetLeft = 27;
    } else {
      // align on right side
      offsetLeft = -2;
    }
    return { top: `calc(${y}% - ${offsetTop}rem)`, left: `calc(${x}% - ${offsetLeft}rem)` };
  };

  const getTopPosition = (givenTask) => {
    if (givenTask.coord_y > 50) {
      if (givenTask.messages.length > 1) {
        return `calc(${givenTask.coord_y}% - 450px)`;
      } else {
        return `calc(${givenTask.coord_y}% - 300px)`;
      }
    } else {
      return `calc(${givenTask.coord_y}% - 25px)`;
    }
  };

  const getTaskMessageStyles = () => {
    switch (task.type) {
      case 'audio': {
        return {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          margin: 'auto',
          height: 'fit-content',
        };
      }
      case 'image': {
        const relativeCoord = getXYPercent(
          task.coord_x,
          task.coord_y,
          'project-mainview-fullscreen-img',
        );
        if (relativeCoord) {
          return calculatePosition(relativeCoord.x, relativeCoord.y);
        } else {
          return {
            top: 0,
            left: 0,
          };
        }
      }
      case 'video': {
        const changeDate = new Date('2021-01-13T09:59:55.671Z');
        const creationDate = new Date(task.created);
        if (creationDate > changeDate) {
          return {
            top: getTopPosition(task),
            left:
              task.coord_x > 50
                ? `calc(${task.coord_x}% - 450px)`
                : `calc(${task.coord_x}% + 25px)`,
          };
        } else {
          return {
            top: parseInt(task.coord_y, 10) - 100,
            left: parseInt(task.coord_x, 10) + 40,
            margin: 'auto',
          };
        }
      }
      default:
        return undefined;
    }
  };

  const updateTask = async () => {
    setUpdateLoading(true);

    await editVideoTask({
      id: task.id,
      duration,
      status: task.status,
      tasktype: task.taskType,
    }).then((i) => {
      dispatch(updateTaskDuration({ ...task, duration }));
    });

    setUpdateLoading(false);
  };

  return (
    <ConditionalPortal
      condition={task.type === 'video' && videoFullscreen}
      destination={destination}
    >
      <>
        {task.type === 'video' && (
          <Dot
            index={task.index}
            coord_x={parseInt(task.coord_x, 10)}
            coord_y={parseInt(task.coord_y, 10)}
            type="video"
            closeNewTask={handleCloseButtonClick}
            opacity={1}
            getXYPercent={getXYPercent}
            newTask={false}
            created={task.created}
            appearance={task.taskType}
          />
        )}
        <div className={`taskMessage ${task.type}`}>
          <div style={getTaskMessageStyles()} className="modal" ref={wrapperNode}>
            {task?.index && (
              <h3>
                {' '}
                Task {task.index}{' '}
                <span>
                  {task.type !== 'image' ? ` (${formatMilliSeconds(task.timestamp)})` : ''}
                </span>
              </h3>
            )}
            {task.type === 'video' && Object.values(lineTypes).includes(task.taskType) && (
              <span className="durationForm">
                <NumberInput
                  value={duration}
                  min={0.5}
                  max={100}
                  step={0.5}
                  onChange={(value) => modifyDuration(value)}
                  label="Duration:"
                  suffix="s"
                />
                {duration !== parseFloat(task.duration) && (
                  <ExperimentalButton type="denim" loading={updateLoading} onClick={updateTask}>
                    Update
                  </ExperimentalButton>
                )}
              </span>
            )}
            <div className="modalScrollWrapper">
              <ContentSection
                handleCloseButtonClick={handleCloseButtonClick}
                handleTaskMessageSubmit={handleTaskMessageSubmit}
                commentUploadingProcess={commentUploadingProcess}
                messages={task.messages}
              />
            </div>
            <div className="closeIcon" onClick={handleCloseButtonClick} title="close">
              <CrossIcon height="15px" fill="#a3a3a3" />
            </div>
          </div>
        </div>
      </>
    </ConditionalPortal>
  );
};

export default TaskMessage;

TaskMessage.propTypes = {
  // app props
  users: PropTypes.arrayOf(PropTypes.shape(workspaceParticipantShape)).isRequired,

  // projectMainView.jsx props
  updateTasks: PropTypes.func.isRequired,
  updateSelectedTask: PropTypes.func.isRequired,

  // projecMainViewWorkSection.jsx prop
  task: PropTypes.shape(taskShape).isRequired,
  videoFullscreen: PropTypes.bool.isRequired,
  destination: PropTypes.any.isRequired,

  setPlayerCurrentTime: PropTypes.func.isRequired,
};
