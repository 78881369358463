import React from 'react';
import PropTypes from 'prop-types';
import classes from './FileUploadingModalList.module.css';
import FileUploadingModalListItem from './fileUploadingModalListItem/FileUploadingModalListItem';
import { filesListShape, videoDetailsListShape } from '../FileUploadingModalPropTypes';
import { getIsFinished, getSnapshotBlob } from './FileUploadingModalListUtilityFunctions';

const FileUploadingModalList = (props) => {
  const listItems = props.filesList.map((file) => {
    const snapshotBlob = getSnapshotBlob(file, props.videoDetailsList);
    const isUploading =
      props.uploadingItemsList.find((item) => item.name === file.item.name) !== undefined;
    const isFinished = getIsFinished(file, props.finishedUploadsList);
    const didFail =
      props.failedUploadsList.find((item) => item.name === file.item.name) !== undefined;

    return (
      <FileUploadingModalListItem
        key={file.item.name}
        isFinished={isFinished}
        didFail={didFail}
        file={file.item}
        removeItemFromFilesList={props.removeItemFromFilesList}
        isUploading={isUploading}
        addToVideoDetailsList={props.addToVideoDetailsList}
        removeFromVideoDetailsList={props.removeFromVideoDetailsList}
        snapshotBlob={snapshotBlob}
        updateFilesListItemDetails={props.updateFilesListItemDetails}
        progressPercent={props.progress[file.item.name]}
        updateThumbnailGeneratingProcessOnGoing={props.updateThumbnailGeneratingProcessOnGoing}
      />
    );
  });
  return <ul className={classes.list}>{listItems}</ul>;
};

export default FileUploadingModalList;

FileUploadingModalList.propTypes = {
  finishedUploadsList: filesListShape.isRequired,
  filesList: filesListShape.isRequired,
  uploadingItemsList: filesListShape.isRequired,
  failedUploadsList: filesListShape.isRequired,
  removeItemFromFilesList: PropTypes.func.isRequired,
  addToVideoDetailsList: PropTypes.func.isRequired,
  removeFromVideoDetailsList: PropTypes.func.isRequired,
  videoDetailsList: videoDetailsListShape.isRequired,
  updateFilesListItemDetails: PropTypes.func.isRequired,
  progress: PropTypes.object,
  updateThumbnailGeneratingProcessOnGoing: PropTypes.func.isRequired,
};

FileUploadingModalList.defaultProps = {
  progress: undefined,
};
