import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import classes from './AccountSettingsWorkspaceListItem.module.scss';
import { ReactComponent as WorkspaceIcon } from '../../../../../../assets/workspace.svg';
import AccountSettingsWorkspaceListItemActionables from './itemActionables/AccountSettingsWorkspaceListItemActionables';
import { groupShape } from '../../../../../AppPropTypes';
import { currentUserInfoShape } from '../../../../../../apiServices/userApiTypes';
import { cutItemTitle } from '../../../../../../util/FitTextIntoWidth';

const AccountSettingsWorkspaceListItem = (props) => {
  const [imageError, setImageError] = useState(false);
  const listItemNode = useRef(null);
  const history = useHistory();

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, []);

  useEffect(() => {
    setImageError(false);
  }, [props.group]);

  const handleClickOutside = (event) => {
    if (props.dropdownOpenedItemId === props.group.id.S) {
      if (!listItemNode.current.contains(event.target)) {
        props.updateDropdownOpenedItemId();
      }
    }
  };

  const selectGroup = () => {
    if (props.group.id.S !== props.currentUserGroup) {
      window.localStorage.setItem('currentUserGroup', props.group.id.S);
      props.initiateSession();
    }
  };

  const navigateHome = () => {
    selectGroup();
    // sami alasveto
    history.push('/in/folder/root');
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const getImageClass = (isActive, parentIsRoot) => {
    if (!isActive && parentIsRoot) {
      return classes.passiveImage;
    } else {
      return classes.image;
    }
  };

  const getWrapperClass = (isActive) => {
    if (isActive) {
      return classes.wrapperActive;
    } else if (props.group.parent.S === props.currentUserGroup) {
      return classes.wrapperSubGroupActive;
    } else {
      return isActive ? classes.wrapperActive : classes.wrapper;
    }
  };

  const isActive = props.group.id.S === props.currentUserGroup;
  const parentIsRoot = props.group.parent.S === 'root';
  const currentGroupUserInfoObj = props.currentUserInfo.groups.find(
    (group) => group.id.S === props.group.id.S,
  );

  return (
    <li className={getWrapperClass(isActive, parentIsRoot)} ref={listItemNode}>
      <AccountSettingsWorkspaceListItemActionables
        isHoveringOver
        dropdownOpenedItemId={props.dropdownOpenedItemId}
        updateDropdownOpenedItemId={props.updateDropdownOpenedItemId}
        updateModalOpen={props.updateModalOpen}
        updateModalParams={props.updateModalParams}
        refreshList={props.refreshList}
        updateIsQuerying={props.updateIsQuerying}
        group={props.group}
        currentUserInfo={props.currentUserInfo}
        currentGroupUserInfoObj={currentGroupUserInfoObj}
        selectGroup={selectGroup}
      />
      <div className={classes.clickArea} onClick={navigateHome}>
        <div className={classes.workspaceIconWrapper}>
          {!imageError && props.group.image && (
            <img
              src={props.group.image.S}
              className={getImageClass(isActive, parentIsRoot)}
              alt="group"
              onError={handleImageError}
            />
          )}
          {(!props.group.image || imageError) && (
            <div className={classes.groupIcon}>
              <WorkspaceIcon />
            </div>
          )}
        </div>
        <h4 className={classes.title}>{cutItemTitle(props.group.title.S, 150)}</h4>
      </div>
    </li>
  );
};

export default AccountSettingsWorkspaceListItem;

AccountSettingsWorkspaceListItem.propTypes = {
  // app.jsx props
  currentUserGroup: PropTypes.string.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  refreshList: PropTypes.func.isRequired,
  initiateSession: PropTypes.func.isRequired,

  // AccountSettingsWorkspace.jsx props
  updateModalOpen: PropTypes.func.isRequired,
  updateModalParams: PropTypes.func.isRequired,

  // accountSettingsWorkspaceList.jsx props
  dropdownOpenedItemId: PropTypes.string,
  updateDropdownOpenedItemId: PropTypes.func.isRequired,
  group: PropTypes.shape(groupShape).isRequired,
};

AccountSettingsWorkspaceListItem.defaultProps = {
  dropdownOpenedItemId: undefined,
};
