import React, { useEffect } from 'react';
import videojs from 'video.js';
import PropTypes from 'prop-types';
import { resourceShape } from '../../ResourceViewPropTypes';

const VideoPlayer = (props) => {
  const createNewMediaElement = () => {
    const mediaDomElement = document.createElement('video');
    mediaDomElement.setAttribute('id', `video-wrapper-${props.content.id.S}-resource-view`);
    mediaDomElement.setAttribute('class', 'video-js');
    mediaDomElement.setAttribute('controls', 'true');
    mediaDomElement.setAttribute('data-setup', '{}');
    mediaDomElement.setAttribute('crossorigin', 'anonymous');

    const mediaDomSourceElement = document.createElement('source');
    mediaDomSourceElement.setAttribute('src', props.content.signedUrl.S);
    mediaDomSourceElement.setAttribute('type', props.content.mimeType.S === 'video/quicktime' ? 'video/mp4' : props.content.mimeType.S);

    mediaDomElement.appendChild(mediaDomSourceElement);

    return mediaDomElement;
  };

  useEffect(() => {
    const videoJsVideoPlayerOptions = {
      autoplay: false,
      controls: true,
      width: '100%',
      playbackRates: [0.25, 0.5, 1, 1.5, 2],
    };

    const videoPlayerWrapperElement = document.getElementById('video-wrapper-resource');
    const mediaDomElement = createNewMediaElement();
    videoPlayerWrapperElement.appendChild(mediaDomElement);
    const playerObj = videojs(mediaDomElement, videoJsVideoPlayerOptions);
    playerObj.ready(() => {
      props.ready();
    });
    return () => {
      videojs(`video-wrapper-${props.content.id.S}-resource-view`).dispose();
    };
  }, [props.content]);

  return <div />;
};

export default VideoPlayer;

VideoPlayer.propTypes = {
  content: PropTypes.shape(resourceShape).isRequired,
  ready: PropTypes.func.isRequired,
};
