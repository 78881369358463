import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcon } from '../../../assets/nitro/cross.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/nitro/checked.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons8-info.svg';
import './NotificationListItem.scss';

const icons = {
  success: SuccessIcon,
  error: ErrorIcon,
  info: ErrorIcon,
};

export const NotificationListItem = (props) => {
  const [render, setRender] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setRender(false);
    }, 6000);
  }, []);

  const onAnimationEnd = () => {
    if (!render) props.remove(props.id);
  };
  const Icon = icons[props.type];
  return (
    <div
      key={props.id}
      className={`notification ${props.type}`}
      onClick={() => {
        setRender(false);
      }}
      style={{ animation: `${render ? 'fadeIn' : 'fadeOut'} 0.5s forwards` }}
      onAnimationEnd={onAnimationEnd}
    >
      <div className="mainContent">{props.message}</div>
      <Icon />
    </div>
  );
};

NotificationListItem.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
