import React from 'react';
import classes from './UserHasNoGroups.module.css';
import { supportEmail } from '../../constantValues/ConstantValues';

const UserHasNoGroups = () => {
  const emailLinkElement = <a href={`mailto:${supportEmail}`}>{supportEmail}</a>;
  return (
    <div className={classes.wrapper}>
      <h4 className={classes.headline}>
        You are not participating in any workspaces at the moment
      </h4>
      <p className={classes.text}>
        Ask to get invited to a workspace or contact support at {emailLinkElement}
      </p>
    </div>
  );
};

export default UserHasNoGroups;
