import React from 'react';
import ImageSection from './imageSection/ImageSection';
import Buttons from './buttons/Buttons';
import { updateUserImage, removeUserImage } from '../../../apiServices/userApi';
import {
  putToStorageProfileImage,
  putToStorageDefault,
} from '../../../apiServices/storageApiService';
import { updateWorkspaceImage, removeWorkspaceImage } from '../../../apiServices/groupApi';
import { updateItemCover, removeCoverImage } from '../../../apiServices/projectApi';
import { getHeadline, pdfToPng } from './SetPictureModalUtilFunctions';
import { getLowerCaseFileExtensionInFile } from '../../../util/UtilFunctions';
import './SetPictureModal.scss';
import NotificationContext from '../../../context/notification';

class SetPictureModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureUploadingProcess: { status: 'initial' },
      imageRemovingProcess: { status: 'initial' },
      croppedImageUrl: undefined,
    };
  }

  handlePictureChange = async (event) => {
    const file = getLowerCaseFileExtensionInFile(event.target.files[0]);
    this.setState({ pictureUploadingProcess: { status: 'initial' } }, () => {
      const mimeTypeSplitArr = file.type.split('/');
      if (mimeTypeSplitArr[1] === 'gif') {
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: ".gif files can't be set as cover images",
        });
      } else if (mimeTypeSplitArr[0] === 'image') {
        this.handleImageSelection(file);
      } else if (mimeTypeSplitArr[1] === 'pdf') {
        this.handlePdfSelection(file);
      } else {
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: 'Given file is not an image or pdf',
        });
      }
    });
  };

  handleImageSelection = (file) => {
    this.setState({
      pictureUploadingProcess: {
        status: 'pictureSelected',
        data: { picture: file, croppedImage: undefined },
      },
    });
  };

  handlePdfSelection = async (file) => {
    this.setState(
      {
        pictureUploadingProcess: {
          status: 'pdfSelected',
          data: {
            pdfFile: file,
            picture: undefined,
          },
        },
      },
      async () => {
        try {
          const newFile = await pdfToPng(file);
          this.setState({
            pictureUploadingProcess: {
              status: 'pdfSelected',
              data: {
                picture: newFile,
              },
            },
            croppedImageUrl: URL.createObjectURL(file),
          });
        } catch (pdfCreationError) {
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not produce pdf thumbnail',
          });
        }
      },
    );
  };

  updateCroppedImage = (croppedImage) => {
    const { pictureUploadingProcess } = this.state;
    this.setState({
      pictureUploadingProcess: {
        status: 'pictureCropped',
        data: { picture: pictureUploadingProcess.data.picture, croppedImage },
      },
    });
  };

  handleUpdateWorkspaceImage = async (event) => {
    event.preventDefault();
    let storageResponse;
    if (
      this.state.pictureUploadingProcess.status === 'pictureSelected' ||
      this.state.pictureUploadingProcess.status === 'pictureCropped'
    ) {
      storageResponse = await this.getStorageResponse(this.props.useCase, false);
    } else if (this.state.pictureUploadingProcess.status === 'pdfSelected') {
      storageResponse = await this.getStorageResponse(this.props.useCase, true);
    }
    try {
      const itemId = this.props.item.id;
      const i = await updateWorkspaceImage(
        itemId,
        storageResponse.key,
        this.state.pictureUploadingProcess.data.picture.name,
      );

      this.setState({ pictureUploadingProcess: { status: 'success' } });
      this.props.updateGroupsList(itemId, 0);
      this.context.add({
        id: Date.now(),
        type: 'success',
        message: 'Updated workspace icon',
      });
      this.props.closeModal();
    } catch (updateUserImageError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: `Could not update workspace icon due to an ${updateUserImageError.message}`,
      });
      this.setState({
        pictureUploadingProcess: { status: 'error' },
      });
      this.props.updateIsQuerying(false);
      this.props.closeModal();
    }
  };

  getImageFileStorageResponse = async (pictureUploadingProcess, useCase) => {
    const croppedImageUrl = URL.createObjectURL(pictureUploadingProcess.data.croppedImage);
    this.setState({
      pictureUploadingProcess: {
        status: 'uploading',
        data: {
          picture: pictureUploadingProcess.data.picture,
          croppedImage: pictureUploadingProcess.data.croppedImage,
        },
      },
      croppedImageUrl,
    });
    let { name } = this.state.pictureUploadingProcess.data.picture;
    if (!name) {
      name = 'default.png';
    }
    if (useCase === 'profile') {
      this.props.updateIsQuerying(true);
      const storageResponse = await putToStorageProfileImage(
        this.state.pictureUploadingProcess.data.croppedImage,
        name,
      );
      this.props.updateIsQuerying(false);
      return storageResponse;
    } else {
      this.props.updateIsQuerying(true);
      const storageResponse = await putToStorageDefault(
        this.state.pictureUploadingProcess.data.croppedImage,
        name,
      );
      this.props.updateIsQuerying(false);
      return storageResponse;
    }
  };

  getPdfStorageResponse = async (pictureUploadingProcess, useCase) => {
    const croppedImageUrl = URL.createObjectURL(pictureUploadingProcess.data.picture);
    this.setState({
      pictureUploadingProcess: {
        status: 'uploading',
        data: {
          picture: pictureUploadingProcess.data.picture,
          croppedImage: pictureUploadingProcess.data.croppedImage,
        },
      },
      croppedImageUrl,
    });
    if (useCase === 'profile') {
      this.props.updateIsQuerying(true);
      const storageResponse = await putToStorageProfileImage(
        this.state.pictureUploadingProcess.data.picture,
        this.state.pictureUploadingProcess.data.picture.name,
      );
      this.props.updateIsQuerying(false);
      return storageResponse;
    } else {
      this.props.updateIsQuerying(true);
      const storageResponse = await putToStorageDefault(
        this.state.pictureUploadingProcess.data.picture,
        this.state.pictureUploadingProcess.data.picture.name,
      );
      this.props.updateIsQuerying(false);
      return storageResponse;
    }
  };

  getStorageResponse = async (useCase, isPdf) => {
    try {
      const { pictureUploadingProcess } = this.state;
      if (!isPdf) {
        return this.getImageFileStorageResponse(pictureUploadingProcess, useCase);
      } else {
        return this.getPdfStorageResponse(pictureUploadingProcess, useCase);
      }
    } catch (putToStorageError) {
      this.setState({
        pictureUploadingProcess: { status: 'error' },
      });
      this.props.updateIsQuerying(false);
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: putToStorageError.message,
      });
      return undefined;
    }
  };

  handleUploadFile = async (event) => {
    event.preventDefault();
    let storageResponse;
    if (
      this.state.pictureUploadingProcess.status === 'pictureSelected' ||
      this.state.pictureUploadingProcess.status === 'pictureCropped'
    ) {
      storageResponse = await this.getStorageResponse(this.props.useCase, false);
    } else if (this.state.pictureUploadingProcess.status === 'pdfSelected') {
      storageResponse = await this.getStorageResponse(this.props.useCase, true);
    }
    try {
      this.props.updateIsQuerying(true);
      await updateUserImage(
        this.props.currentUserInfo.cognitoAttributes.Username,
        storageResponse.key,
        this.state.pictureUploadingProcess.data.picture.name,
      );
      this.props.updateIsQuerying(false);
      this.setState({ pictureUploadingProcess: { status: 'success' } });
      this.context.add({
        id: Date.now(),
        type: 'success',
        message: 'Updated profile picture',
      });
      this.props.updateCurrentUserInfo(true, 0);
      if (this.props.useCase !== 'coverImage') {
        this.props.getWorkspacePeopleList();
      }
      this.props.closeModal();
    } catch (updateUserImageError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: updateUserImageError.message,
      });
      this.setState({
        pictureUploadingProcess: { status: 'error' },
      });
      this.props.updateIsQuerying(false);
    }
  };

  handleUpdateCoverImage = async (event) => {
    event.preventDefault();
    let storageResponse;
    if (
      this.state.pictureUploadingProcess.status === 'pictureSelected' ||
      this.state.pictureUploadingProcess.status === 'pictureCropped'
    ) {
      storageResponse = await this.getStorageResponse(this.props.useCase, false);
    } else if (this.state.pictureUploadingProcess.status === 'pdfSelected') {
      storageResponse = await this.getStorageResponse(this.props.useCase, true);
    }
    try {
      const itemId = this.props.item.id || this.props.item.id;
      this.props.updateIsQuerying(true);
      await updateItemCover(itemId, storageResponse);
      this.props.updateIsQuerying(false);
      this.setState({ pictureUploadingProcess: { status: 'success' } });
      this.context.add({
        id: Date.now(),
        type: 'success',
        message: 'Updated cover icon',
      });
      this.props.refreshList({ type: 'item', id: itemId }, 0, true);
      this.props.closeModal();
    } catch (updateUserImageError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: updateUserImageError.message,
      });
      this.setState({
        pictureUploadingProcess: { status: 'error' },
      });
      this.props.updateIsQuerying(false);
    }
  };

  getOnClick = (useCase) => {
    switch (useCase) {
      case 'profile':
        return this.handleUploadFile;
      case 'workspace':
        return this.handleUpdateWorkspaceImage;
      case 'coverImage':
        return this.handleUpdateCoverImage;
      default: {
        const defaultFunc = () => {};
        return defaultFunc;
      }
    }
  };

  removeImage = async () => {
    switch (this.props.useCase) {
      case 'profile': {
        try {
          this.setState({ imageRemovingProcess: { status: 'loading' } });
          await removeUserImage(this.props.currentUserInfo.cognitoAttributes.Username);
          this.setState({ imageRemovingProcess: { status: 'success' } });
          this.props.updateCurrentUserInfo();
          this.context.add({
            id: Date.now(),
            type: 'success',
            message: 'Removed profile picture',
          });
        } catch (removeCoverError) {
          this.setState({ imageRemovingProcess: { status: 'error', error: removeCoverError } });
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not remove profile picture',
          });
        }
        break;
      }
      case 'workspace': {
        try {
          this.setState({ imageRemovingProcess: { status: 'loading' } });
          await removeWorkspaceImage(this.props.item.id || this.props.item.id);
          this.setState({ imageRemovingProcess: { status: 'success' } });
          this.context.add({
            id: Date.now(),
            type: 'success',
            message: 'Removed workspace picture',
          });
          this.props.updateGroupsList();
          this.props.closeModal();
        } catch (removeCoverError) {
          this.setState({ imageRemovingProcess: { status: 'error', error: removeCoverError } });
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not remove workspace picture',
          });
        }
        break;
      }
      case 'coverImage': {
        try {
          this.setState({ imageRemovingProcess: { status: 'loading' } });
          await removeCoverImage(this.props.item.id || this.props.item.id);
          this.setState({ imageRemovingProcess: { status: 'success' } });
          this.context.add({
            id: Date.now(),
            type: 'success',
            message: 'Removed cover icon',
          });
          this.props.refreshList();
        } catch (removeCoverError) {
          this.setState({ imageRemovingProcess: { status: 'error', error: removeCoverError } });
          this.context.add({
            id: Date.now(),
            type: 'success',
            message: 'Could not remove cover icon',
          });
        }
        break;
      }
      default:
        break;
    }
  };

  static contextType = NotificationContext;

  render() {
    return (
      <div className="setPictureModal">
        <h4>{getHeadline(this.props.useCase)}</h4>
        <div className={`contentWrapper ${''}`}>
          <ImageSection
            item={this.props.item}
            pictureUploadingProcess={this.state.pictureUploadingProcess}
            currentUserInfo={this.props.currentUserInfo}
            updateCroppedImage={this.updateCroppedImage}
            croppedImageUrl={this.state.croppedImageUrl}
            useCase={this.props.useCase}
            handlePictureChange={this.handlePictureChange}
          />
          {(this.props.hasImage || this.state.pictureUploadingProcess.status !== 'initial') && (
            <Buttons
              imageRemovingProcess={this.state.imageRemovingProcess}
              pictureUploadingProcess={this.state.pictureUploadingProcess}
              getOnClick={this.getOnClick}
              handlePictureChange={this.handlePictureChange}
              removeImage={this.removeImage}
              useCase={this.props.useCase}
              hasImage={this.props.hasImage}
            />
          )}
        </div>
      </div>
    );
  }
}

export default SetPictureModal;

// SetPictureModal.propTypes = {
//   // app.jsx props
//   currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
//   updateIsQuerying: PropTypes.func.isRequired,
//   updateCurrentUserInfo: PropTypes.func,
//   updateGroupsList: PropTypes.func,
//   item: PropTypes.oneOfType([PropTypes.shape(groupShape), PropTypes.shape(folderListItemShape)]),
//   closeModal: PropTypes.func.isRequired,
//   useCase: PropTypes.oneOf(useCaseOptions).isRequired,
//   refreshList: PropTypes.func,
//   hasImage: PropTypes.bool.isRequired,
//   getWorkspacePeopleList: PropTypes.func,
// };

// SetPictureModal.defaultProps = {
//   updateCurrentUserInfo: undefined,
//   updateGroupsList: undefined,
//   refreshList: undefined,
//   item: undefined,
//   getWorkspacePeopleList: undefined,
// };
