const getPreviewItem = (projectId) => {
  const storedPreviewItemOnShowIdInProjectsList = JSON.parse(
    window.localStorage.getItem('previewItemOnShowInProjectsList'),
  );
  if (storedPreviewItemOnShowIdInProjectsList) {
    // eslint-disable-next-line
    const storedPreviewItemOnShowIdForThisProject = storedPreviewItemOnShowIdInProjectsList.find(
      (item) => item.project === projectId,
    );
    return storedPreviewItemOnShowIdForThisProject;
  }
  return undefined;
};

export const getPreviewItemIdFromLocalStorage = (projectId) => {
  const previewItem = getPreviewItem(projectId);
  if (previewItem) {
    return previewItem.previewItemOnShowId;
  }
  return undefined;
};

export const setPreviewItemToLocalStorage = (previewItem, projectId) => {
  const newItemLocalStorageItem = {
    project: projectId,
    previewItemOnShowId: previewItem,
  };
  const storedPreviewItemOnShowIdInProjectsList = JSON.parse(
    window.localStorage.getItem('previewItemOnShowInProjectsList'),
  );
  if (storedPreviewItemOnShowIdInProjectsList) {
    const previewItemOnTheProjectAlreadyExists = storedPreviewItemOnShowIdInProjectsList.some(
      (item) => item.project === projectId,
    );
    if (previewItemOnTheProjectAlreadyExists) {
      const filteredList = storedPreviewItemOnShowIdInProjectsList.filter(
        (item) => item.project !== projectId,
      );
      const newList = [...filteredList, newItemLocalStorageItem];
      window.localStorage.setItem('previewItemOnShowInProjectsList', JSON.stringify(newList));
    } else {
      const newList = [...storedPreviewItemOnShowIdInProjectsList, newItemLocalStorageItem];
      window.localStorage.setItem('previewItemOnShowInProjectsList', JSON.stringify(newList));
    }
  } else {
    const newList = [newItemLocalStorageItem];
    window.localStorage.setItem('previewItemOnShowInProjectsList', JSON.stringify(newList));
  }
};

export const getTasksAmountByResourceList = (getTasksProcess) => {
  let tasksAmountByResourceList = [];
  if (getTasksProcess.status === 'success') {
    getTasksProcess.data.forEach((resource) => {
      const tasksAmountObj = {
        resource: resource.item.id.S,
        amount: resource.item.tasks.length,
      };
      tasksAmountByResourceList.push(tasksAmountObj);
    });
  }
  return tasksAmountByResourceList;
};

const getNewTask = (task, resource, taskOwnerObj, taskOwnerImageUrl) => {
  if (task.type.S === 'image') {
    return {
      ...task,
      imgThumbnail: {
        S: resource.item.thumbnailUrl ? resource.item.thumbnailUrl.S : undefined,
      },
      owner: {
        attributes: taskOwnerObj,
        image: taskOwnerImageUrl,
      },
      resourceId: resource.item.id.S,
    };
  } else {
    return {
      ...task,
      owner: {
        attributes: taskOwnerObj,
        image: taskOwnerImageUrl,
      },
      resourceId: resource.item.id.S,
    };
  }
};

const getTaskOwnerImageUrl = (taskOwnerObj) => {
  if (taskOwnerObj.image) {
    return taskOwnerObj.image.S;
  } else {
    return undefined;
  }
};

export const getTasksList = (tasksResponseList, workspacePeopleList) => {
  const tasksList = [];
  if (workspacePeopleList) {
    tasksResponseList.forEach((resource) => {
      resource.item.tasks.forEach(async (task) => {
        const taskOwnerObj = workspacePeopleList.find((peopleListItem) => {
          if (task.owner.uid === peopleListItem.uid) {
            return peopleListItem;
          }
          return undefined;
        });
        if (taskOwnerObj) {
          const taskOwnerImageUrl = getTaskOwnerImageUrl(taskOwnerObj);
          const newTask = getNewTask(task, resource, taskOwnerObj, taskOwnerImageUrl);
          tasksList.push(newTask);
        } else {
          tasksList.push(task);
        }
      });
    });
  }
  return tasksList;
};
