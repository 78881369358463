import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './login.scss';
import { signIn, completeNewPassword } from '../../../apiServices/authApi';
import { historyShape } from '../../../routerPropTypes';
import ErrorBoundary from '../../errorBoundary/ErrorBoundary';
import NotificationContext from '../../../context/notification';
import ExperimentalButton from '../../../components/buttons/experimentalButton/experimentalButton';
import TextInput from '../../../components/inputs/textInput';
import { ReactComponent as DividerShape } from '../../../assets/nitro/divider-shape.svg';

class Login extends React.Component {
  static contextType = NotificationContext;

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loginFormProcess: { status: 'inactive' },
      newPasswordProcess: { status: 'inactive' },
      requiresNewPassword: false,
      newPassword: '',
      confirmNewPassword: '',
      signInResponseUser: undefined,
    };
  }

  validateForm = () => this.state.email.length > 0 && this.state.password.length > 0;

  handleEmailFieldChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handlePasswordFieldChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handlenewPasswordFieldChange = (event) => {
    this.setState({ newPassword: event.target.value });
  };

  handleConfirmNewPasswordFieldChange = (event) => {
    this.setState({ confirmNewPassword: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loginFormProcess: { status: 'loading' } });
    try {
      const signInResponse = await signIn(this.state.email.toLowerCase(), this.state.password);
      if (signInResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setState({
          requiresNewPassword: true,
          signInResponseUser: signInResponse,
          loginFormProcess: { status: 'success' },
        });
      } else {
        this.setState({ loginFormProcess: { status: 'success' } });
        this.props.initiateSession();
        this.props.userHasAuthenticated(true);
        this.props.updateLoginProcessOngoing(true);
      }
    } catch (signInError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not log in',
      });
      this.setState({ loginFormProcess: { status: 'error', data: signInError } });
    }
  };

  handleNewPasswordSubmit = async (event) => {
    event.preventDefault();
    if (this.state.newPassword === this.state.confirmNewPassword) {
      this.setState({ newPasswordProcess: { status: 'loading' } });
      try {
        await completeNewPassword(this.state.signInResponseUser, this.state.newPassword);
        this.setState({ newPasswordProcess: { status: 'success' } });
        this.props.initiateSession();
        this.props.userHasAuthenticated(true);
        this.props.history.push('/in/folder/root');
      } catch (completeNewPasswordError) {
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not perform action',
        });
        this.setState({ newPasswordProcess: { status: 'error', data: completeNewPasswordError } });
      }
    }
  };

  getLoginButtonStatus = () => {
    if (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.loginFormProcess.status === 'inactive'
    ) {
      return 'active';
    } else {
      return this.state.loginFormProcess.status;
    }
  };

  getNewPasswordButton = () => {
    if (this.state.newPassword.length > 0 && this.state.newPasswordProcess.status === 'inactive') {
      return 'active';
    } else {
      return this.state.newPasswordProcess.status;
    }
  };

  render() {
    const loginButtonStatus = this.getLoginButtonStatus();
    const newPasswordButton = this.getNewPasswordButton();
    return (
      <ErrorBoundary>
        <div className="login">
          <div className="whiteBackground" />
          <div className="dividerShape">
            <DividerShape />
          </div>
          <div className="greenBackground" />
          <div className="card">
            {!this.state.requiresNewPassword && (
              <>
                <div className="header">
                  <h4>Login</h4>
                </div>
                <div className="contentWrapper">
                  <form onSubmit={this.handleSubmit}>
                    <div className="inputsWrapper">
                      <div className="inputWrapper">
                        <TextInput
                          type="text"
                          value={this.state.email}
                          onChange={this.handleEmailFieldChange}
                          placeholder=" "
                          id="email"
                          autoFocus
                        />
                        <label htmlFor="email">Email</label>
                      </div>
                      <div className="inputWrapper">
                        <TextInput
                          type="password"
                          value={this.state.password}
                          onChange={this.handlePasswordFieldChange}
                          placeholder=" "
                          id="password"
                        />
                        <label htmlFor="password">Password</label>
                      </div>
                    </div>
                    <div className="buttonWrapper">
                      <ExperimentalButton
                        isDisabled={loginButtonStatus === 'inactive'}
                        loading={this.state.loginFormProcess.status === 'loading'}
                        htmlType="submit"
                      >
                        {loginButtonStatus === 'error' ? 'Try again' : 'Login'}
                      </ExperimentalButton>
                    </div>
                  </form>
                  <Link to="/forgotPassword" className="link">
                    Forgot your password..?
                  </Link>
                </div>
              </>
            )}
            {this.state.requiresNewPassword && (
              <>
                <div className="header">
                  <h5>Renew password</h5>
                </div>
                <div className="contentWrapper">
                  <form onSubmit={this.handleNewPasswordSubmit}>
                    <div className="inputsWrapper">
                      <div className="inputWrapper">
                        <TextInput
                          type="password"
                          value={this.state.newPassword}
                          onChange={this.handlenewPasswordFieldChange}
                          placeholder=" "
                          id="password1"
                        />
                        <label htmlFor="password1">Password</label>
                      </div>
                      <div className="inputWrapper">
                        <TextInput
                          type="password"
                          value={this.state.confirmNewPassword}
                          onChange={this.handleConfirmNewPasswordFieldChange}
                          placeholder=" "
                          id="password2"
                        />
                        <label htmlFor="password2">Confirm password</label>
                      </div>
                    </div>
                    <div className="buttonWrapper">
                      <ExperimentalButton
                        isDisabled={newPasswordButton === 'inactive'}
                        loading={this.state.newPasswordProcess.status === 'loading'}
                        htmlType="submit"
                      >
                        {newPasswordButton === 'error' ? 'Try again' : 'Renew'}
                      </ExperimentalButton>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default Login;

Login.propTypes = {
  // router prop types
  history: PropTypes.shape(historyShape).isRequired,

  // app.jsx prop types
  initiateSession: PropTypes.func.isRequired,
  userHasAuthenticated: PropTypes.func.isRequired,
  updateLoginProcessOngoing: PropTypes.func.isRequired,
};
