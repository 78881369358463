import React from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import DocumentsIcon from '../../../icons/DocumentsIcon';
import { ReactComponent as SoundWave } from '../../../../assets/soundwave.svg';
import VideoIcon from '../../../icons/VideosIcon';
import { folderListResourceItemShape } from '../../../../app/views/folderView/FolderViewPropTypes';
import { ThumbnailLink } from './thumbnailLink/ThumbnailLink';
import ImagesIcon from '../../../icons/ImagesIcon';
import { useCaseType } from '../../ItemListPropTypes';
import { displayInFullscreen } from '../../../../util/DisplayInFullScreen';
import './filePreviewSection.scss';
import {
  getIsRendableInResourceView,
  getThumbnailSrc,
  handlePlayerSetup,
} from './FilePreviewSectionUtlityFunctions';

class FilePreviewSection extends React.Component {
  state = {
    thumbnailImageError: false,
    inFullScreen: false,
    isPlaying: false,
  };

  componentDidMount = () => {
    if (this.props.item.type === 'video' || this.props.item.type === 'audio') {
      handlePlayerSetup(this.props.item, this.props.useCase, this.onAudioEnded);
    }
  };

  onAudioEnded = () => {
    this.setState({ isPlaying: false });
  };

  onAudioElementClick = () => {
    const audioElement = videojs(`item-list-audio-player-${this.props.item.id}`);
    if (this.state.isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
    this.setState((prev) => ({ isPlaying: !prev.isPlaying }));
  };

  handleThumbnailImageError = () => {
    this.setState({ thumbnailImageError: true });
  };

  handleImgDoubleClick = (id) => {
    const elementId = `project-fileview-fullscreen-img/${id}`;
    this.setState({ inFullScreen: true }, () => displayInFullscreen(elementId));
  };

  getImageContent = (iconHeight) => {
    const thumbnailSrc = getThumbnailSrc(this.props.item);

    return (
      <div className="thumbnail">
        <ThumbnailLink
          useCase={this.props.useCase}
          itemId={this.props.item.id}
          parentId={this.props.item.parent}
          updateResourceId={this.props.updateResourceId}
          sortByCriteriaIndex={this.props.sortByCriteriaIndex}
          sortByDirectionIsAscending={this.props.sortByDirectionIsAscending}
        >
          {!this.state.thumbnailImageError && (
            <>
              {!this.state.inFullScreen && (
                <>
                  {thumbnailSrc && (
                    <img
                      src={thumbnailSrc}
                      alt="thumbnail"
                      onError={this.handleThumbnailImageError}
                      onDoubleClick={() => this.handleImgDoubleClick(this.props.item.id)}
                    />
                  )}
                  {!thumbnailSrc && <ImagesIcon height={iconHeight} fill="lightgray" />}
                </>
              )}
              {this.state.inFullScreen && (
                <img
                  src={this.props.item.signedUrl}
                  alt="placeholder"
                  id={`project-fileview-fullscreen-img/${this.props.item.id}`}
                />
              )}
            </>
          )}
          {this.state.thumbnailImageError && <ImagesIcon height={iconHeight} fill="lightgray" />}
        </ThumbnailLink>
      </div>
    );
  };

  getItemContent = () => {
    const iconHeight = this.props.useCase === 'project' ? '60px' : '40px';
    const isRendableInResourceView = getIsRendableInResourceView(this.props.item);
    switch (this.props.item.type) {
      case 'video': {
        return (
          <div className={`thumbnail video ${isRendableInResourceView ? 'playable' : ''}`}>
            {isRendableInResourceView && <div id={`video-wrapper-${this.props.item.id}`} />}
            {!isRendableInResourceView && <VideoIcon height={iconHeight} fill="lightgray" />}
          </div>
        );
      }
      case 'audio': {
        return (
          <div
            className={`thumbnail audio ${this.state.isPlaying ? 'animate' : ''}`}
            onClick={() => (isRendableInResourceView ? this.onAudioElementClick() : '')}
          >
            <SoundWave />
            <div className="audio-wrapper-item-list-file">
              <div id={`audio-wrapper-${this.props.item.id}`} />
            </div>
          </div>
        );
      }
      case 'image': {
        return this.getImageContent(iconHeight);
      }
      case 'doc': {
        const isPdf = this.props.item.mimeType === 'application/pdf';
        if (!isPdf) {
          return (
            <div className="thumbnail">
              <DocumentsIcon height={iconHeight} fill="lightgray" />
            </div>
          );
        } else {
          return this.getImageContent(iconHeight);
        }
      }
      default:
        return <div />;
    }
  };

  render = () => (
    <div className={`filePreviewSection ${this.props.useCase === 'project' ? 'large' : ''}`}>
      {this.props.item.type && this.getItemContent()}
    </div>
  );
}

export default FilePreviewSection;

FilePreviewSection.propTypes = {
  // ItemList.jsx props
  item: PropTypes.shape(folderListResourceItemShape).isRequired,

  // Project.jsx props

  updateResourceId: PropTypes.func,
  useCase: useCaseType.isRequired,
  sortByCriteriaIndex: PropTypes.number,
  sortByDirectionIsAscending: PropTypes.bool,
};

FilePreviewSection.defaultProps = {
  projectFileListView: undefined,
  updateResourceId: undefined,
  sortByCriteriaIndex: undefined,
  sortByDirectionIsAscending: undefined,
};
