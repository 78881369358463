import React from 'react';
import PropTypes from 'prop-types';
import classes from './ItemListLoading.module.css';

const ItemListLoading = (props) => {
  let listItems = [];
  for (let i = 0; i < props.amount; i++) {
    const loadingItem = (
      <li className={classes.listItemwrapper} key={i}>
        <div className={classes.thumbnailMock} />
        <div className={classes.titleMock} />
        <div className={classes.infoMock} />
      </li>
    );
    listItems.push(loadingItem);
  }

  return (
    <div className={classes.listWrapper}>
      <ul className={classes.itemsList}>{listItems}</ul>
    </div>
  );
};

export default ItemListLoading;

ItemListLoading.propTypes = {
  amount: PropTypes.number.isRequired,
};
