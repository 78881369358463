import PropTypes from 'prop-types';
import { workspaceParticipantShape, statusOptions } from '../../AppPropTypes';
import { folderListItemShape } from '../folderView/FolderViewPropTypes';

export const usersInProjectListProcessShape = {
  status: PropTypes.oneOf(statusOptions),
  data: PropTypes.arrayOf(PropTypes.shape(workspaceParticipantShape)),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export const modalParamsItemShape = {
  projectId: PropTypes.string.isRequired,
  usersInProjectList: usersInProjectListProcessShape.data,
};

export const breadcrumbItemShape = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  itemtype: PropTypes.string.isRequired,
};

export const projectShape = {
  breadcrumb: PropTypes.arrayOf(PropTypes.shape(breadcrumbItemShape)).isRequired,
  created: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  endTime: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  itemtype: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  owner: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  parent: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  path: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  previews: PropTypes.arrayOf(PropTypes.any).isRequired,
  resources: PropTypes.shape({
    audio: PropTypes.number.isRequired,
    doc: PropTypes.number.isRequired,
    image: PropTypes.number.isRequired,
    video: PropTypes.number.isRequired,
  }),
  searchField: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  startTime: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
};

export const getProjectProcessShape = {
  status: PropTypes.oneOf(statusOptions).isRequired,
  data: PropTypes.shape(projectShape),
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
};

export const getProjectFileListProcessShape = {
  status: PropTypes.oneOf(statusOptions).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(folderListItemShape).isRequired),
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
};
