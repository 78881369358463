// gets full payload of amplify getCurrentAuthenticatedUser and retuns values for global state
const transformAuthUser = (amplifyAuthUser) => {
  if (amplifyAuthUser) {
    const {
      attributes: { sub },
      signInUserSession: {
        accessToken: { jwtToken },
      },
    } = amplifyAuthUser;

    return {
      uid: sub,
      jwtToken,
    };
  } else {
    return null;
  }
};

// Transform userDetails from /myself api call
const transformUserDetails = (myself) => {
  if (myself) {
    const { image, firstname, lastname, email } = myself;

    return {
      image: image?.S,
      firstname: firstname?.S,
      lastname: lastname?.S,
      email: email?.S,
    };
  } else {
    return null;
  }
};

export { transformAuthUser, transformUserDetails };
