import React from 'react';
import PropTypes from 'prop-types';
import '../login/login.scss';
import { historyShape, locationShape } from '../../../routerPropTypes';
import ConfirmationForm from './confirmationForm/ConfirmationForm';
import ErrorBoundary from '../../errorBoundary/ErrorBoundary';
import { ReactComponent as DividerShape } from '../../../assets/nitro/divider-shape.svg';

class ConfirmSignup extends React.Component {
  hello = () => {};

  render = () => (
    <ErrorBoundary>
      <div className="signup">
        <div className="whiteBackground" />
        <div className="dividerShape">
          <DividerShape />
        </div>
        <div className="greenBackground" />
        <div className="card">
          <div className="header">
            <h5>Confirm sign up</h5>
          </div>
          <ConfirmationForm
            location={this.props.location}
            history={this.props.history}
            userHasAuthenticated={this.props.userHasAuthenticated}
            initiateSession={this.props.initiateSession}
            updateIsQuerying={this.props.updateIsQuerying}
            updateLoginProcessOngoing={this.props.updateLoginProcessOngoing}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}
export default ConfirmSignup;

ConfirmSignup.propTypes = {
  // router props
  location: PropTypes.shape(locationShape).isRequired,
  history: PropTypes.shape(historyShape).isRequired,

  // App.jsx props
  userHasAuthenticated: PropTypes.func.isRequired,
  initiateSession: PropTypes.func.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  updateLoginProcessOngoing: PropTypes.func.isRequired,
};
