import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MiniatureFolderSystem from '../../miniatureFolderSystem/MiniatureFolderSystem';
import SelectedItemsList from '../../selectedItemsList/SelectedItemsList';
import { copyResource } from '../../../apiServices/resourceApi';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import { matchShape } from '../../../routerPropTypes';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';
import { serializeFuncs } from '../../../util/Sequentialize';
import { FinishedUploadsCounter } from '../fileUploadingModal/finishedUploadsCounter/FinishedUploadsCounter';
import NotificationContext from '../../../context/notification';
import { borderRadiusDefault } from '../../../styles/variables';

class CopyItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDestination: 'root',
      copyingProcess: { status: 'inactive' },
      filesAmount: undefined,
    };
  }

  componentDidMount = () => {
    this.setState({ filesAmount: this.props.selectedItems.length });
  };

  updateSelectedDestination = (destination) => {
    if (destination) {
      this.setState({
        selectedDestination: destination,
        copyingProcess: { status: 'active' },
      });
    } else {
      this.setState({
        selectedDestination: undefined,
        copyingProcess: { status: 'inactive' },
      });
    }
  };

  handleSubmitButtonClick = async () => {
    if (this.props.match.params.id !== this.state.selectedDestination.id) {
      if (this.props.selectedItems.length === 1) {
        await this.copyItem(this.props.selectedItems[0]);
      } else {
        const funcs = [];
        this.props.selectedItems.forEach(async (selectedItem) => {
          const newPromise = () =>
            new Promise((resolve, reject) => {
              this.copyItem(selectedItem)
                .then(() => resolve())
                .catch((err) => reject(err));
            });
          funcs.push(newPromise);
        });
        await serializeFuncs(funcs);
      }
      this.props.refreshList(false, undefined, true);
      this.props.closeModal();
      const notificationPopupMessage =
        this.props.selectedItems.length > 1 ? 'items copied' : 'item copied';
      this.context.add({
        id: Date.now(),
        type: 'success',
        message: notificationPopupMessage,
      });
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: "Can't move files to current folder",
      });
    }
  };

  copyItem = async (file) => {
    try {
      this.setState({ copyingProcess: { status: 'loading' } });
      this.props.updateIsQuerying(true);
      await copyResource(file.id, this.state.selectedDestination.id);
      this.props.updateIsQuerying(false);
      this.setState({ copyingProcess: { status: 'inactive' } });
      this.props.removeFromSelectedItems([file]);
    } catch (copyResourceError) {
      this.setState({ copyingProcess: { status: 'error' } });
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not copy resource',
      });
      this.props.updateIsQuerying(false);
    }
  };

  static contextType = NotificationContext;

  render = () => (
    <Wrapper>
      <Headline>Copy items</Headline>
      <ContentWrapper>
        <SelectedItemsList
          selectedItems={this.props.selectedItems}
          removeFromSelectedItems={this.props.removeFromSelectedItems}
        />
        <MiniatureFolderSystem
          updateSelectedDestination={this.updateSelectedDestination}
          selectedItemsContainsNonFileItem={false}
          workspaceName={this.props.workspaceName}
          pathname={this.props.pathname}
          updateIsQuerying={this.props.updateIsQuerying}
          useCase="copy"
        />

        <ExperimentalButton
          onClick={this.handleSubmitButtonClick}
          title="Copy"
          isDisabled={this.state.selectedDestination === 'root'}
          loading={this.state.copyingProcess.status === 'loading'}
          type="primary"
        >
          {this.state.copyingProcess.status === 'error' ? 'Try again' : 'Copy'}
        </ExperimentalButton>
        {this.state.filesAmount > 1 && (
          <FinishedUploadsCounter
            filesListLength={this.state.filesAmount}
            fileUploadingProcessStatus={this.state.copyingProcess.status}
            finishedUploadsListLength={this.state.filesAmount - this.props.selectedItems.length}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: ${borderRadiusDefault};
  height: 100%;
  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin-bottom: 1rem;
  }
`;

const Headline = styled.h4`
  text-align: center;
  font-size: 1.3em;
  margin: 0 0 1rem 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default CopyItemModal;

CopyItemModal.propTypes = {
  // router props
  match: PropTypes.shape(matchShape).isRequired,
  pathname: PropTypes.string.isRequired,

  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,

  // Folder.jsx or ProjectView.jsx props
  refreshList: PropTypes.func.isRequired,

  // itemList.jsx props
  selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  removeFromSelectedItems: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,

  // itemListModals.jsx props
  workspaceName: PropTypes.string.isRequired,
};
