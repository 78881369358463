import React from 'react';
import PropTypes from 'prop-types';
import classes from './SearchBarDropdownList.module.css';
import SearchBarDropdownListItem from './searchBarDropdownListItem/SearchBarDropdownListItem';
import { historyShape } from '../../../../routerPropTypes';
import { folderListItemShape } from '../../../../app/views/folderView/FolderViewPropTypes';

const SearchBarDropdownList = (props) => {
  let rendableListItems;
  if (!props.searchBarInitialState && props.searchItemsList.length === 0) {
    rendableListItems = (
      <SearchBarDropdownListItem
        text={`0 results for ${props.searchTerm}`}
        searching={props.searching}
        failed
        selected={false}
        history={props.history}
        closeSubnav={props.closeSubnav}
      />
    );
  } else if (props.searchTerm.length < 3 && props.searchTerm.length > 0) {
    rendableListItems = (
      <SearchBarDropdownListItem
        text="Pass at least 3 characters"
        searching={props.searching}
        failed
        selected={false}
        history={props.history}
        closeSubnav={props.closeSubnav}
      />
    );
  } else if (!props.searchBarInitialState && props.searching) {
    rendableListItems = ['', '', '', '', ''].map((item, index) => (
      <div className={classes.exceptionItemWrapper} key={index}>
        <h4 className={classes.text}>...</h4>
      </div>
    ));
  } else if (!props.searchBarInitialState && !props.searching) {
    rendableListItems = props.searchItemsList.map((item) => {
      let selected = false;
      if (props.selectedItem) {
        if (props.selectedItem.id) {
          selected = item.id.S === props.selectedItem.id.S;
        }
      }
      return (
        <SearchBarDropdownListItem
          key={item.id.S}
          item={item}
          searching={props.searching}
          resetSearchBarState={props.resetSearchBarState}
          closeSubnav={props.closeSubnav}
          failed={false}
          selected={selected}
          history={props.history}
        />
      );
    });
  }
  return <>{rendableListItems}</>;
};

export default SearchBarDropdownList;

SearchBarDropdownList.propTypes = {
  // router props
  history: PropTypes.shape(historyShape).isRequired,

  // searchbar.jsx props
  searchItemsList: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  searchTerm: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  selectedItem: PropTypes.shape(folderListItemShape),

  searchBarInitialState: PropTypes.bool.isRequired,
  resetSearchBarState: PropTypes.func.isRequired,
  closeSubnav: PropTypes.func.isRequired,
};

SearchBarDropdownList.defaultProps = {
  selectedItem: undefined,
};
