import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as StarIcon } from '../../../../assets/starIconFull.svg';
import { ReactComponent as ShareIcon } from '../../../../assets/nitro/share.svg';
import DownloadIcon from '../../../icons/DownloadIcon';
import { formatDate, downloadFile, formatBytes } from '../../../../util/UtilFunctions';
import {
  updateResourceProjectPreviewStatus,
  getResourceDetails,
} from '../../../../apiServices/resourceApi';
import { cutItemTitle } from '../../../../util/FitTextIntoWidth';
import ToggleSwitch from '../../../buttons/toggleSwitch/toggleSwitch';
import './itemInfoSection.scss';
import { useDispatch } from 'react-redux';
import { updateItemPreview } from '../../../../app/features/project/projectSlice';

const ItemInfoSection = (props) => {
  const dispatch = useDispatch();
  const [isPreview, setIsPreview] = useState(!!props.item.preview);

  useEffect(() => {
    setIsPreview(!!props.item.preview);
  }, [props.item.preview]);

  const handleShareButtonClick = async () => {
    if (props.item.signedUrl) {
      const modalParams = [
        {
          selectedFiles: [
            {
              url: props.item.signedUrl,
              id: props.item.id,
              name: props.item.title,
            },
          ],
        },
      ];
      props.updateModalOpen('shareFile', modalParams);
    } else {
      try {
        props.updateIsQuerying(true);
        const getResourceDetailsResponse = await getResourceDetails(props.item.id);
        props.updateIsQuerying(false);
        const modalParams = [
          {
            selectedFiles: [
              {
                url: getResourceDetailsResponse.response.Item.signedUrl,
                id: getResourceDetailsResponse.response.Item.id,
                name: getResourceDetailsResponse.response.Item.title,
              },
            ],
          },
        ];
        props.updateModalOpen('shareFile', modalParams);
      } catch (getResoureDetailsError) {
        context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not get resource details',
        });
        props.updateIsQuerying(false);
      }
    }
  };

  const handlePreviewCheckboxClick = async () => {
    setIsPreview(!props.item.preview);
    const i = await updateResourceProjectPreviewStatus(
      props.item.id,
      props.item.title,
      !props.item.preview,
    );

    dispatch(updateItemPreview(props.item));
  };

  const titleCutOff = props.item.itemtype === 'project' ? 432 : 325;
  return (
    <div className="itemInfoSection" title={props.item.title}>
      {props.useCase !== 'link' && (
        <Link
          key={props.item.id}
          to={`/in/${props.item.parentType}/${props.item.parent}/resource/${props.item.id}/${props.projectFileListView}?sortBy=${props.sortByCriteriaIndex}&ascending=${props.sortByDirectionIsAscending}`}
          className="link"
        >
          <h5 className="title">{cutItemTitle(props.item.title, titleCutOff)}</h5>
        </Link>
      )}
      {props.useCase === 'link' && (
        <div onClick={() => props.updateResourceId(props.item.id)} className="link">
          <h5 className="title">{cutItemTitle(props.item.title, titleCutOff)}</h5>
        </div>
      )}
      <div className="detailsRow">
        <h5>{props.item.created ? formatDate(props.item.created) : 'unknown'}</h5>
        <h5>{props.item.size ? formatBytes(props.item.size) : 'unknown'}</h5>
        {props.isFavourite && <StarIcon />}
        <div className="bottomWrapper">
          {!props.currentUserIsAViewer && (
            <ToggleSwitch
              name={`toggle-switch-${props.item.id}`}
              onChange={handlePreviewCheckboxClick}
              checked={isPreview}
              disabled={!props.item.isRendable}
            />
          )}
          {props.useCase !== 'link' && (
            <div
              className="actionablesIconItem"
              onClick={handleShareButtonClick}
              title="share file"
            >
              <ShareIcon />
            </div>
          )}
          {props.useCase === 'link' && (
            <div
              className="actionablesIconItem"
              onClick={() => downloadFile(props.item.title, props.item.signedUrl)}
              title="download file"
            >
              <DownloadIcon height={20} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemInfoSection;
