import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { addGroupWithImage, addGroup, addGroupWithHeader } from '../../../apiServices/groupApi';
import { putToStorageDefault } from '../../../apiServices/storageApiService';
import { currentUserInfoShape } from '../../../apiServices/userApiTypes';
import NotificationContext from '../../../context/notification';
import TextInput from '../../inputs/textInput';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import ImageSection from '../setPictureModal/imageSection/ImageSection';
import {
  getLowerCaseFileExtensionInFile,
  getValueFromUserAttributes,
} from '../../../util/UtilFunctions';
import { pdfToPng } from '../setPictureModal/SetPictureModalUtilFunctions';
import { borderRadiusDefault, colorGreyDark, colorRedSecondary } from '../../../styles/variables';

class CreateWorkspaceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workspaceFieldValue: '',
      workspaceCreatingProcess: { status: 'initial' },
      pictureUploadingProcess: { status: 'initial' },
      croppedImageUrl: undefined,
    };
  }

  handleButtonPress = (event) => {
    if (event.keyCode === 27) {
      this.props.closeModal();
    }
  };

  handleClickOutside = (event) => {
    if (!this.modalWrapperNode.current.contains(event.target)) {
      this.props.closeModal();
    }
  };

  handleCloseButtonClick = () => {
    this.props.closeModal();
  };

  handleWorkspaceFieldValueChange = (event) => {
    this.setState({ workspaceFieldValue: event.target.value });
  };

  addWorkspaceWithImage = async () => {
    try {
      this.props.updateIsQuerying(true);
      const storageResponse = await putToStorageDefault(
        this.state.pictureUploadingProcess.data.picture,
      );
      this.props.updateIsQuerying(false);
      try {
        this.props.updateIsQuerying(true);
        const addGroupWithImageResponse = await addGroupWithImage(
          this.state.workspaceFieldValue,
          this.props.currentUserInfo.cognitoAttributes.Username,
          storageResponse.key,
        );
        this.props.updateIsQuerying(false);
        this.setState({ workspaceCreatingProcess: { status: 'success' } });
        this.props.updateGroupsList(addGroupWithImageResponse.response, 0);
        this.props.closeModal();
        this.context.add({
          id: Date.now(),
          type: 'success',
          message: `Created workspace ${this.state.workspaceFieldValue}`,
        });
      } catch (addGroupError) {
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not create workspace',
        });
        this.setState({ workspaceCreatingProcess: { status: 'error', data: addGroupError } });
        this.props.updateIsQuerying(false);
        this.props.closeModal();
      }
    } catch (putToStorageError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not create workspace',
      });
      this.props.updateIsQuerying(false);
      this.props.closeModal();
    }
  };

  addWorkspace = async () => {
    try {
      this.props.updateIsQuerying(true);
      if (this.props.useCase === 'root') {
        const firstname = getValueFromUserAttributes(
          this.props.currentUserInfo.cognitoAttributes.UserAttributes,
          'given_name',
        );
        const lastname = getValueFromUserAttributes(
          this.props.currentUserInfo.cognitoAttributes.UserAttributes,
          'family_name',
        );
        const email = getValueFromUserAttributes(
          this.props.currentUserInfo.cognitoAttributes.UserAttributes,
          'email',
        );
        const addGroupResponse = await addGroupWithHeader(
          this.state.workspaceFieldValue,
          this.props.currentUserInfo.cognitoAttributes.Username,
          undefined,
          firstname,
          lastname,
          email,
        );
        if (addGroupResponse.response.search('ERROR') !== -1) {
          this.setState({
            workspaceCreatingProcess: {
              status: 'error',
              error: new Error({ message: addGroupResponse.response }),
            },
          });
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: `Could not create workspace ${this.state.workspaceFieldValue}`,
          });
        } else {
          this.setState({
            workspaceCreatingProcess: { status: 'success', data: addGroupResponse },
          });
          this.context.add({
            id: Date.now(),
            type: 'success',
            message: `Created workspace ${this.state.workspaceFieldValue}`,
          });
        }
      } else {
        const addGroupResponse = await addGroup(
          this.state.workspaceFieldValue,
          this.props.currentUserInfo.cognitoAttributes.Username,
        );
        if (addGroupResponse.response.search('ERROR') !== -1) {
          this.setState({
            workspaceCreatingProcess: {
              status: 'error',
              error: new Error({ message: addGroupResponse.response }),
            },
          });
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: `Could not create workspace ${this.state.workspaceFieldValue}`,
          });
        } else {
          this.setState({
            workspaceCreatingProcess: { status: 'success', data: addGroupResponse },
          });
          this.context.add({
            id: Date.now(),
            type: 'success',
            message: `Created workspace ${this.state.workspaceFieldValue}`,
          });
        }
      }
      this.props.updateIsQuerying(false);
      this.props.updateGroupsList();
      this.props.updateCurrentUserInfo();
      this.props.closeModal();
    } catch (addGroupError) {
      this.setState({ workspaceCreatingProcess: { status: 'error', data: addGroupError } });
      this.props.updateIsQuerying(false);
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not create workspace',
      });
    }
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();
    if (this.state.workspaceFieldValue.length > 0) {
      if (this.state.workspaceFieldValue.length < 50) {
        this.setState({ workspaceCreatingProcess: { status: 'loading' } });
        if (
          this.state.pictureUploadingProcess.status === 'pictureCropped' ||
          this.state.pictureUploadingProcess.status === 'pdfSelected'
        ) {
          this.addWorkspaceWithImage();
        } else {
          this.addWorkspace();
        }
      } else {
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: "Name can't be over 50 characters",
        });
      }
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Form is not complete',
      });
    }
  };

  handlePictureChange = async (event) => {
    const file = getLowerCaseFileExtensionInFile(event.target.files[0]);
    const mimeTypeSplitArr = file.type.split('/');
    if (mimeTypeSplitArr[1] === 'gif') {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: ".gif files can't be set as cover images",
      });
    } else if (mimeTypeSplitArr[0] === 'image') {
      this.handleImageSelection(file);
    } else if (mimeTypeSplitArr[1] === 'pdf') {
      this.handlePdfSelection(file);
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Given file is not an image or pdf',
      });
    }
  };

  updateCroppedImage = (croppedImage) => {
    const { pictureUploadingProcess } = this.state;
    this.setState({
      pictureUploadingProcess: {
        status: 'pictureCropped',
        data: { picture: pictureUploadingProcess.data.picture, croppedImage },
      },
    });
  };

  handleImageSelection = (file) => {
    this.setState({
      pictureUploadingProcess: {
        status: 'pictureSelected',
        data: { picture: file, croppedImage: undefined },
      },
    });
  };

  handlePdfSelection = async (file) => {
    this.setState(
      {
        pictureUploadingProcess: {
          status: 'pdfSelected',
          data: {
            pdfFile: file,
            picture: undefined,
          },
        },
      },
      async () => {
        try {
          const newFile = await pdfToPng(file);
          this.setState({
            pictureUploadingProcess: {
              status: 'pdfSelected',
              data: {
                picture: newFile,
              },
            },
            croppedImageUrl: URL.createObjectURL(file),
          });
        } catch (pdfCreationError) {
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not produce pdf thumbnail',
          });
        }
      },
    );
  };

  static contextType = NotificationContext;

  render() {
    return (
      <Wrapper>
        {this.props.packageType === 'root' && <Title>Create a new yemma</Title>}
        {this.props.useCase !== 'root' && <Title>Create new workspace</Title>}
        <ContentWrapper>
          <form onSubmit={this.handleFormSubmit}>
            <InputWrapper>
              <TextInput
                placeholder={this.props.useCase === 'root' ? 'company name' : 'workspace name'}
                value={this.state.workspaceFieldValue}
                onChange={this.handleWorkspaceFieldValueChange}
                autoFocus
              />
            </InputWrapper>
            {this.props.useCase === 'root' && <p>Yemma is free to use for 14 days</p>}
            {this.props.useCase !== 'root' && (
              <div className="uploadPictureWrapper">
                <ImageSection
                  useCase="createNew"
                  pictureUploadingProcess={this.state.pictureUploadingProcess}
                  currentUserInfo={this.props.currentUserInfo}
                  updateCroppedImage={this.updateCroppedImage}
                  croppedImageUrl={this.state.croppedImageUrl}
                  handlePictureChange={this.handlePictureChange}
                />
              </div>
            )}
            <ExperimentalButton
              htmlType="submit"
              loading={this.state.workspaceCreatingProcess.status === 'loading'}
            >
              {this.state.workspaceCreatingProcess.status === 'error' ? 'Try again' : 'Create'}
            </ExperimentalButton>
          </form>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default CreateWorkspaceModal;

CreateWorkspaceModal.propTypes = {
  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  updateGroupsList: PropTypes.func.isRequired,
  updateCurrentUserInfo: PropTypes.func.isRequired,

  // ItemList.jsx props
  closeModal: PropTypes.func.isRequired,

  packageType: PropTypes.string.isRequired,
  useCase: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  position: relative;
  border-radius: ${borderRadiusDefault};
  width: 100%;
  height: 100%;

  button.button.primary {
    padding: 0.5rem 0;
    width: 7rem;
    margin: 2rem auto;
  }
`;

const Title = styled.h4`
  text-align: center;
  font-size: 1.3em;
  margin: 0 0 1rem 0;
  span {
    color: ${colorGreyDark};
  }
`;

const InputWrapper = styled.div`
  margin: 2rem 0;
  position: relative;
  span {
    position: absolute;
    right: -2.6rem;
    top: 5px;
    font-size: 0.9rem;
    color: ${colorGreyDark};
  }
  p {
    color: ${colorRedSecondary};
    font-size: 0.8rem;
    margin: 0.5rem 0;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
`;
