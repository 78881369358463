import PropTypes from 'prop-types';
import { breadcrumbItemShape } from '../projectView/ProjectViewPropTypes';

export const resourceShape = {
  breadcrumb: PropTypes.arrayOf(PropTypes.shape(breadcrumbItemShape)),
  created: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  itemtype: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  key: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  mimeType: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  owner: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  parent: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  parentType: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  preview: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  searchField: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  signedUrl: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  size: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  thumbnailUrl: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
};
