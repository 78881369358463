export const getButtonIsDisabled = (selectedItems, selectedDestination) => {
  const selectedItemsContainsFile = selectedItems.some((item) => item.itemtype.S === 'resource');
  if (selectedItemsContainsFile) {
    if (selectedDestination === 'root') {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
