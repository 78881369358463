export const basicSubscription = {
  price: 79,
  maxStorageSpace: 500000000000,
  dataTransfer: {
    limit: 250000000000,
    exceed: {
      amount: 0.2,
      perSize: 1000000000,
    },
  },
  adminsAmount: 2,
};

export const premiumSubscription = {
  price: 189,
  maxStorageSpace: 1000000000000,
  dataTransfer: {
    limit: 800000000000,
    exceed: {
      amount: 0.2,
      perSize: 1000000000,
    },
  },
  adminsAmount: 5,
};

export const supportEmail = 'support@theyemma.com';
