import React, { useMemo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../../app/video-js-global.css';
import { useCaseType } from '../ItemListPropTypes';
import ItemActionables from '../itemActionables/ItemActionables';
import ItemInfoSection from './itemInfoSection/ItemInfoSection';
import './audioPlayerOverWrites.scss';
import FilePreviewSection from './filePreviewSection/FilePreviewSection';
import {
  folderListResourceItemShape,
  folderListItemShape,
} from '../../../app/views/folderView/FolderViewPropTypes';
import './itemListItemFile.scss';
import { transformResource } from '../../../app/features/project/utils';

const ItemListItemFile = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const ref = useRef(null);

  // Since project-data has been refactored but others aren't
  const item = useMemo(() => {
    if (props.item.id?.S) {
      return transformResource(props.item);
    }
    return props.item;
  }, [props.item]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, []);

  const handleClickOutside = (event) => {
    if (!ref?.current?.contains(event.target)) {
      if (item.id === props.dropdownOpenedItemId) {
        props.updateDropdownOpenedItemId();
      }
      if (item.id === props.renamingItemId) {
        props.updateRenamingItemId();
      }
    }
  };

  const getIsFavourite = () => {
    const favourites = JSON.parse(window.localStorage.getItem('favourites'));
    if (favourites) {
      return favourites.find((favItem) => favItem.id === item.id) !== undefined;
    }
    return false;
  };

  return (
    <li
      className={`itemListItemFile ${props.useCase === 'project' ? 'project' : 'folder'}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      ref={ref}
    >
      <div className={`contentWrapper ${props.isSelected ? 'selected' : ''}`}>
        <ItemActionables
          dropdownOpen={props.dropdownOpenedItemId === item.id}
          isHoveringOver={isHovering}
          isFavourite={getIsFavourite()}
          itemHasBeenSelected={props.selectedItems.length > 0}
          isSelected={props.isSelected}
          item={item}
          addToSelectedItems={props.addToSelectedItems}
          removeFromSelectedItems={props.removeFromSelectedItems}
          updateDropdownOpenedItemId={props.updateDropdownOpenedItemId}
          updateModalOpen={props.updateModalOpen}
          updateIsQuerying={props.updateIsQuerying}
          currentUserIsAViewer={props.currentUserIsAViewer}
          useCase={props.useCase}
        />
        <FilePreviewSection
          item={item}
          updateResourceId={props.updateResourceId}
          useCase={props.useCase}
          sortByCriteriaIndex={props.sortByCriteriaIndex}
          sortByDirectionIsAscending={props.sortByDirectionIsAscending}
        />
        <ItemInfoSection
          isFavourite={getIsFavourite()}
          item={item}
          refreshList={props.refreshList}
          preview={item.preview}
          updateModalOpen={props.updateModalOpen}
          updateIsQuerying={props.updateIsQuerying}
          projectFileListView={props.projectFileListView}
          currentUserIsAViewer={props.currentUserIsAViewer}
          useCase={props.useCase}
          updateResourceId={props.updateResourceId}
          sortByCriteriaIndex={props.sortByCriteriaIndex}
          sortByDirectionIsAscending={props.sortByDirectionIsAscending}
        />
      </div>
    </li>
  );
};

export default ItemListItemFile;

ItemListItemFile.propTypes = {
  // App.jsx props
  updateIsQuerying: PropTypes.func.isRequired,

  // FolderView.jsx props
  refreshList: PropTypes.func.isRequired,
  sortByCriteriaIndex: PropTypes.number,
  sortByDirectionIsAscending: PropTypes.bool,

  // ProjectView.jsx props
  projectFileListView: PropTypes.string,

  useCase: useCaseType.isRequired,
  updateResourceId: PropTypes.func,

  // ItemList.jsx props
  selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  item: PropTypes.shape(folderListResourceItemShape).isRequired,
  addToSelectedItems: PropTypes.func,
  removeFromSelectedItems: PropTypes.func,
  updateModalOpen: PropTypes.func.isRequired,
  updateDropdownOpenedItemId: PropTypes.func,
  dropdownOpenedItemId: PropTypes.string,
  isSelected: PropTypes.bool,
  renamingItemId: PropTypes.string,
  updateRenamingItemId: PropTypes.func,

  // ProjectViewItemList.jsx or FOlderViewItemList.jsx props
  currentUserIsAViewer: PropTypes.bool,
};

ItemListItemFile.defaultProps = {
  dropdownOpenedItemId: undefined,
  projectFileListView: undefined,
  addToSelectedItems: undefined,
  removeFromSelectedItems: undefined,
  updateDropdownOpenedItemId: undefined,
  isSelected: undefined,
  currentUserIsAViewer: undefined,
  updateResourceId: undefined,
  renamingItemId: undefined,
  updateRenamingItemId: undefined,
  sortByCriteriaIndex: undefined,
  sortByDirectionIsAscending: undefined,
};
