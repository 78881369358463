import React from 'react';
import PropTypes from 'prop-types';
import { cutItemTitle } from '../../../../../util/FitTextIntoWidth';
import { SubnavigationItem } from '../../../Subnavigation';
import './searchBarDropdownListItem.scss';
import { historyShape } from '../../../../../routerPropTypes';
import { folderListItemShape } from '../../../../../app/views/folderView/FolderViewPropTypes';
import { ItemTypeIcon } from '../../../../itemTypeIcon/ItemTypeIcon';

class SearchBarDropdownListItem extends React.Component {
  navigate = (url) => {
    this.props.history.push(url);
    this.props.resetSearchBarState();
    this.props.closeSubnav();
  };

  getLinkHref = (item) => {
    if (item.itemtype.S === 'resource') {
      return `/in/${item.parentType.S}/${item.parent.S}/resource/${item.id.S}/allfiles`;
    } else {
      return `/in/${item.itemtype.S}/${item.id.S}`;
    }
  };

  render = () => {
    let rendableItem;
    if (this.props.failed || this.props.searching) {
      rendableItem = (
        <SubnavigationItem className="exceptionItemWrapper">
          <h4>{this.props.text}</h4>
        </SubnavigationItem>
      );
    } else {
      const title = cutItemTitle(
        this.props.item.title ? this.props.item.title.S : this.props.item.name.S,
        185,
      );

      rendableItem = (
        <SubnavigationItem
          onClick={() => this.navigate(this.getLinkHref(this.props.item))}
          className={`resultItem ${this.props.selected ? 'active' : ''}`}
        >
          {this.props.selected && <div className="activityIndicator" />}
          <div className="icon">
            <ItemTypeIcon
              itemType={this.props.item.itemtype.S}
              fileGenre={this.props.item.type && this.props.item.type.S}
              size={0}
              color={undefined}
            />
          </div>
          <h4>{title}</h4>
        </SubnavigationItem>
      );
    }
    return rendableItem;
  };
}

export default SearchBarDropdownListItem;

SearchBarDropdownListItem.propTypes = {
  item: PropTypes.shape(folderListItemShape),
  text: PropTypes.string,
  resetSearchBarState: PropTypes.func,
  failed: PropTypes.bool.isRequired,
  searching: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  history: PropTypes.shape(historyShape).isRequired,
  closeSubnav: PropTypes.func.isRequired,
};

SearchBarDropdownListItem.defaultProps = {
  item: undefined,
  text: undefined,
  resetSearchBarState: undefined,
};
