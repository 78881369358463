import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './WorkspaceParticipiantsModalPeopleList.scss';
import WorkspaceParticipiantsModalPeopleListItem from './workspaceParticipiantsModalPeopleListItem/WorkspaceParticipiantsModalPeopleListItem';
import { groupShape, workspaceParticipantShape } from '../../../../app/AppPropTypes';
import { getCurrentWorkspacePeopleListProcessShape } from '../WorkspaceParticipantsModalPropTypes';
import { sortParticipantsListByFullName } from '../../../../util/UtilFunctions';
import Loader from '../../../loader/loader';
import ExperimentalButton from '../../../buttons/experimentalButton/experimentalButton';
import * as stylesVariables from '../../../../styles/variables';

const WorkspaceParticipiantsModalPeopleList = (props) => {
  const [showInfo, setShowInfo] = React.useState();

  const updateShowInfo = (showInfoProps) => {
    if (!showInfo) {
      setShowInfo(showInfoProps);
    } else {
      setShowInfo();
    }
  };

  let view;
  switch (props.getCurrentWorkspacePeopleListProcess.status) {
    case 'loading': {
      view = (
        <div className="spinnerWrapper">
          <Loader />
        </div>
      );
      break;
    }
    case 'success': {
      const processData = props.getCurrentWorkspacePeopleListProcess.data;
      const sortedList = sortParticipantsListByFullName(processData);
      if (sortedList && sortedList.length > 0) {
        const listItems = sortedList.map((person) => {
          const currentUserWorkspacePeopleListItem = sortedList.find(
            (workspacePeopleListItem) =>
              workspacePeopleListItem.user.S === props.currentUserInfoInThisWorkspace.user.S,
          );
          const verificationIsPending =
            person.cognitoAttributes.UserStatus === 'FORCE_CHANGE_PASSWORD';
          const firstName = person.cognitoAttributes.UserAttributes.find(
            (attribute) => attribute.Name === 'given_name',
          ).Value;
          const lastName = person.cognitoAttributes.UserAttributes.find(
            (attribute) => attribute.Name === 'family_name',
          ).Value;
          const email = person.cognitoAttributes.UserAttributes.find(
            (attribute) => attribute.Name === 'email',
          ).Value;
          const role = person.role.S;
          const profileImageUrl = person.image ? person.image.S : undefined;
          return (
            <WorkspaceParticipiantsModalPeopleListItem
              person={person}
              key={person.cognitoAttributes.Username}
              uid={person.cognitoAttributes.Username}
              firstName={firstName}
              lastName={lastName}
              email={email}
              role={role}
              verificationIsPending={verificationIsPending}
              profileImageUrl={profileImageUrl}
              currentUserInfoInThisWorkspace={props.currentUserInfoInThisWorkspace}
              currentUserWorkspacePeopleListItem={currentUserWorkspacePeopleListItem}
              chosenGroup={props.chosenGroup}
              updateIsQuerying={props.updateIsQuerying}
              getPeopleList={props.getPeopleList}
              itemIsCurrentUser={props.currentUserInfoInThisWorkspace.user.S === person.user.S}
              updateShowInfo={updateShowInfo}
            />
          );
        });
        const successCaseView = (
          <ListWrapper>
            <ul className="list">{listItems}</ul>{' '}
            {showInfo && (
              <Box
                maxHeight={showInfo.maxHeight}
                stylesVariables={stylesVariables}
                left={showInfo.left}
                top={showInfo.top}
              >
                <BoxContentWrapper>
                  {showInfo.children}
                  <Button onClick={() => setShowInfo()} title="close info">
                    X
                  </Button>
                </BoxContentWrapper>
              </Box>
            )}
          </ListWrapper>
        );
        view = successCaseView;
      } else {
        const errorCaseView = (
          <div className="errorWrapper">
            <h5 className="errorText">Something went wrong</h5>
            <ExperimentalButton type="primary" onClick={props.getPeopleList} title="Try again">
              Try again
            </ExperimentalButton>
          </div>
        );
        view = errorCaseView;
      }
      break;
    }
    case 'error': {
      const errorCaseView = (
        <div className="errorWrapper">
          <h5 className="errorText">Something went wrong</h5>
          <ExperimentalButton type="primary" onClick={props.getPeopleList} title="Try again">
            Try again
          </ExperimentalButton>
        </div>
      );
      view = errorCaseView;
      break;
    }
    default:
      view = <div />;
      break;
  }
  return <div className="workspaceParticipiantsModalPeopleList">{view}</div>;
};

const BoxContentWrapper = styled.div`
  position: relative;
`;

const ListWrapper = styled.div`
  position: relative;
`;


const Box = styled.div`
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 7.5rem);
  background-color: ${(props) => props.stylesVariables.colorGreySecondary};
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 15rem;
  padding-bottom: 1rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  max-height: ${(props) => props.maxHeight};
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.stylesVariables.colorGreySecondary};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.stylesVariables.colorGreyPrimary};
    border-radius: 5px;
    height: 50px;
  }
  h4 {
    margin: 0.5rem;
    text-transform: capitalize;
    font-size: 0.95rem;
  }
  ul {
    padding: 0 0 0 1.5rem;
    li {
      text-align: left;
      font-size: 0.85rem;
      line-height: 1rem;
      padding-bottom: 0.5rem;
    }
  }
  .secondaryText {
    font-size: 0.85rem;
    text-align: left;
    color: darken(${stylesVariables.colorDenimSecondary}, 15);
    margin: 0;
  }
`;

const Button = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
`;

export default WorkspaceParticipiantsModalPeopleList;

WorkspaceParticipiantsModalPeopleList.propTypes = {
  chosenGroup: PropTypes.shape(groupShape).isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfoInThisWorkspace: PropTypes.shape(workspaceParticipantShape).isRequired,
  getCurrentWorkspacePeopleListProcess: PropTypes.shape(getCurrentWorkspacePeopleListProcessShape)
    .isRequired,
  getPeopleList: PropTypes.func.isRequired,
};
