import { createSlice } from '@reduxjs/toolkit';
import {
  getCurrentRootGroup,
  transformInvitedUsers,
  transformWorkspaces,
  transformWorspaceUsers,
} from './utils';

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: {
    workspaceUsers: [],
    invitedUsers: [],
    currentUserGroup: null,
    allWorkspaces: [],
    createdGroup: null,
    packages: null,
    selectedPackage: null,
    rootGroup: null,
  },
  reducers: {
    updateWorkspaceUsers: (state, action) => {
      const users = transformWorspaceUsers(action.payload?.Items || []);
      //console.log('workspace', payload);
      const invitedUsers = transformInvitedUsers(action.payload?.invitedUsers || []);
      // console.log('users', users);
      state.workspaceUsers = users;
      state.invitedUsers = invitedUsers;
    },
    updateCurrentUserGroup: (state, action) => {
      state.currentUserGroup = action.payload;
      state.rootGroup = getCurrentRootGroup(state.allWorkspaces, action.payload);
    },
    setCreatedGroup: (state, action) => {
      state.createdGroup = action.payload;
    },
    setAllWorkspaces: (state, action) => {
      //  console.log('all fucking workspaces', action.payload);
      const workspaces = transformWorkspaces(action.payload?.items || []);

      state.allWorkspaces = workspaces;

      // Set myRootGroup
      if (action.payload.uid) {
        const rootGroups = workspaces.filter((i) => i.parent === 'root');
        state.createdGroup = rootGroups.find((i) => i.creator === action.payload.uid);
        state.packages = action.payload.packages;
        state.selectedPackage = action.payload.selectedPackage;
      }
      //
      const localStorageCurrentUserGroup = window.localStorage.getItem('currentUserGroup');
      let currentUserGroup = null;
      if (
        localStorageCurrentUserGroup &&
        workspaces.some((i) => i.id === localStorageCurrentUserGroup)
      ) {
        // console.log('currentUserGroup', localStorageCurrentUserGroup);
        currentUserGroup = localStorageCurrentUserGroup;
      } else {
        currentUserGroup = workspaces[0]?.id || undefined;
      }
      state.currentUserGroup = currentUserGroup;
      state.rootGroup = getCurrentRootGroup(workspaces, currentUserGroup);
    },
  },
});

// example of acync reducer (thunk)
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

export const { updateWorkspaceUsers, updateCurrentUserGroup, setAllWorkspaces, setCreatedGroup } =
  workspaceSlice.actions;

export default workspaceSlice.reducer;
