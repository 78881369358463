export const getAmount = (fileType) => {
  if (fileType.videos) {
    return fileType.videos;
  } else if (fileType.sounds) {
    return fileType.sounds;
  } else if (fileType.images) {
    return fileType.images;
  } else if (fileType.documents) {
    return fileType.documents;
  } else if (fileType.folders) {
    return fileType.folders;
  } else if (fileType.projects) {
    return fileType.projects;
  } else {
    return undefined;
  }
};

export const getFileTypeStringFromFileTypeObj = (fileType) => {
  const key = Object.keys(fileType)[0];
  switch (key) {
    case 'videos':
      return 'video';
    case 'sounds':
      return 'audio';
    case 'images':
      return 'image';
    case 'documents':
      return 'doc';
    case 'folders':
      return 'folder';
    case 'projects':
      return 'project';
    default:
      return '';
  }
};

export const getItemTypeFromFileTypeObj = (fileType) => {
  const key = Object.keys(fileType)[0];
  switch (key) {
    case 'videos':
      return 'resource';
    case 'sounds':
      return 'resource';
    case 'images':
      return 'resource';
    case 'documents':
      return 'resource';
    case 'folders':
      return 'folder';
    case 'projects':
      return 'project';
    default:
      return '';
  }
};
