export const getCurrentUserRoleInThisWorkspace = (currentUserInfoInThisWorkspaceProcess) => {
  if (
    currentUserInfoInThisWorkspaceProcess.status === 'success' &&
    currentUserInfoInThisWorkspaceProcess.data.role
  ) {
    return currentUserInfoInThisWorkspaceProcess.data.role.S;
  } else {
    return undefined;
  }
};
