import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classes from './AccountSettingsNavigationBar.module.scss';
import { groupShape } from '../../../AppPropTypes';
import { colorDenimPrimary } from '../../../../styles/variables';

const AccountSettingsNavigationBar = (props) => (
  <Wrapper>
    <Link>
      <li>
        <NavLink
          to="/in/account/profile"
          activeClassName={classes.linkActive}
          className={classes.link}
          exact
        >
          <LinkText>Profile</LinkText>
          <div className={classes.underline} />
        </NavLink>
      </li>
      {props.groupsList && props.groupsList.length > 0 && (
        <li>
          <NavLink
            to="/in/account/workspace"
            activeClassName={classes.linkActive}
            className={classes.link}
          >
            <LinkText>Workspaces</LinkText>
            <div className={classes.underline} />
          </NavLink>
        </li>
      )}
      {props.userIsCurrentRootGroupCreator && (
        <li>
          <NavLink
            to="/in/account/billing"
            activeClassName={classes.linkActive}
            className={classes.link}
          >
            <LinkText>Billing</LinkText>
            <div className={classes.underline} />
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          to="/in/account/support"
          activeClassName={classes.linkActive}
          className={classes.link}
        >
          <LinkText>Support</LinkText>
          <div className={classes.underline} />
        </NavLink>
      </li>
    </Link>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid lightgray;
`;

const Link = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  list-style-type: none;
`;

const LinkText = styled.h5`
  margin: 1rem 0;
  transition: all 0.2s linear;
  &:hover h5 {
    color: ${colorDenimPrimary};
  }
`;

export default AccountSettingsNavigationBar;

AccountSettingsNavigationBar.propTypes = {
  // AccountSettings.jsx props
  userIsCurrentRootGroupCreator: PropTypes.bool.isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
};
