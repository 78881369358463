import React from 'react';
import PropTypes from 'prop-types';

const DocumentsIcon = (props) => {
  const { height, fill } = props;
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 122.2 163"
      enableBackground="new 0 0 122.2 163"
      xmlSpace="preserve"
      height={height}
    >
      <g id="Layer_2" />
      <g id="Layer_1">
        <path
          fill={fill}
          d="M122.2,33c0-1-0.4-1.9-1.1-2.7L91.9,1.1c0,0,0,0,0,0C91.2,0.4,90.2,0,89.2,0H8C3.6,0,0,3.6,0,8V155c0,4.4,3.6,8,8,8h106.2
          c4.4,0,8-3.6,8-8L122.2,33C122.2,33,122.2,33,122.2,33z M88,9.2l25.6,25.6H88V9.2z M27.3,105.9c-2.1,0-3.8-1.7-3.8-3.8
          c0-2.1,1.7-3.8,3.8-3.8h67.5c2.1,0,3.8,1.7,3.8,3.8c0,2.1-1.7,3.8-3.8,3.8H27.3z M64.9,125.1c0,2.1-1.7,3.8-3.8,3.8H27.3
          c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8h33.8C63.2,121.3,64.9,123,64.9,125.1z M94.9,82.9H27.3c-2.1,0-3.8-1.7-3.8-3.8
          c0-2.1,1.7-3.8,3.8-3.8h67.5c2.1,0,3.8,1.7,3.8,3.8C98.7,81.2,97,82.9,94.9,82.9z M94.9,59.8H27.3c-2.1,0-3.8-1.7-3.8-3.8
          c0-2.1,1.7-3.8,3.8-3.8h67.5c2.1,0,3.8,1.7,3.8,3.8C98.7,58.1,97,59.8,94.9,59.8z"
        />
      </g>
    </svg>
  );
};

export default DocumentsIcon;

DocumentsIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

DocumentsIcon.defaultProps = {
  height: '0',
};
