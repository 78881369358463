import React from 'react';
import PropTypes from 'prop-types';
import classes from './MiniatureFolderSystemFolderListItem.module.scss';
import FolderIcon from '../../../icons/AllfilesIcon';
import ProjectIcon from '../../../icons/ProjectIcon';
import { folderListItemShape } from '../../../../app/views/folderView/FolderViewPropTypes';
import { ReactComponent as ArrowIcon } from '../../../../assets/nitro/arrow-down.svg';

class MiniatureFolderSystemFolderListItem extends React.Component {
  handleItemClick = () => {
    if (this.props.containsProjectsOrFolders) {
      this.props.updateFolderById(this.props.item.id);
    } else {
      this.props.updateSelectedItem(this.props.item);
    }
  };

  getWrapperStyles = (isUnavailable, selected) => {
    if (isUnavailable) {
      return classes.unavailableItemWrapper;
    } else {
      if (selected) {
        return classes.selectedItemWrapper;
      } else {
        return classes.availableItemWrapper;
      }
    }
  };

  render = () => {
    const wrapperStyles = this.getWrapperStyles(this.props.isUnavailable, this.props.selected);
    const titleStyles = this.props.isUnavailable
      ? classes.unavailableTitle
      : classes.availableTitle;
    const icon =
      this.props.item.itemtype === 'project' ? (
        <ProjectIcon height="20px" />
      ) : (
        <FolderIcon height="20px" />
      );
    return (
      <div className={wrapperStyles} onClick={this.handleItemClick}>
        <div className={classes.iconWrapper}>{icon}</div>
        <h4 className={titleStyles}>{this.props.item.title?.S || this.props.item.title}</h4>
        {this.props.containsProjectsOrFolders && <ArrowIcon className={classes.arrowIcon} />}
      </div>
    );
  };
}

export default MiniatureFolderSystemFolderListItem;

MiniatureFolderSystemFolderListItem.propTypes = {
  // MiniatureFolderSystem.jsx props
  updateSelectedItem: PropTypes.func.isRequired,
  updateFolderById: PropTypes.func.isRequired,

  // MiniatureFolderSystemFolderList.jsx props
  item: PropTypes.shape(folderListItemShape).isRequired,
  selected: PropTypes.bool.isRequired,
  isUnavailable: PropTypes.bool.isRequired,
  containsProjectsOrFolders: PropTypes.bool.isRequired,
};
