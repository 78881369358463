import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import Login from '../app/views/login/Login';
import Signup from '../app/views/signup/Signup';
import ConfirmSignup from '../app/views/confirmSignup/ConfirmSignup';
import AccountSettings from '../app/views/accountSettings/AccountSettings';
import UnAuthenticatedHome from '../app/views/unAuthenticatedHome/UnAuthenticatedHome';
import FolderView from '../app/views/folderView/FolderView';
import ProjectView from '../app/views/projectView/ProjectView';
import ResourceView from '../app/views/resourceView/ResourceView';
import ForgotPassword from '../app/views/login/forgotPassword/ForgotPassword';
import WelcomeScreen from '../app/views/welcomeScreen/WelcomeScreen';
import OutsideLink from '../app/views/outsideLinkView/OutsideLinkView';
import NoMatchView from '../app/views/noMatchView/NoMatchView';
import { FreeTrialEndedView } from '../app/views/freeTrialEndedView/FreeTrialEndedView';
import GuestProject from '../app/views/projectView/GuestProject';

const Routes = ({ childProps }) => (
  <Switch>
    <UnauthenticatedRoute path="/" exact component={UnAuthenticatedHome} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute
      path="/forgotPassword"
      exact
      component={ForgotPassword}
      props={childProps}
    />
    <UnauthenticatedRoute path="/guest/:id" exact component={GuestProject} props={childProps} />
    <UnauthenticatedRoute path="/link/:id" exact component={OutsideLink} props={childProps} />
    <UnauthenticatedRoute
      path="/signup/confirm"
      exact
      component={ConfirmSignup}
      props={childProps}
    />
    <UnauthenticatedRoute path="/signup/:id" exact component={Signup} props={childProps} />
    <AuthenticatedRoute
      path="/in/account/:id"
      exact
      component={AccountSettings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/in/account/profile"
      exact
      component={AccountSettings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/in/account/workspace"
      exact
      component={AccountSettings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/in/account/billing"
      exact
      component={AccountSettings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/in/account/support"
      exact
      component={AccountSettings}
      props={childProps}
    />
    <AuthenticatedRoute path="/in/welcome" component={WelcomeScreen} props={childProps} />
    <AuthenticatedRoute
      path="/in/freeTrialEnded"
      component={FreeTrialEndedView}
      props={childProps}
    />
    <AuthenticatedRoute path="/in/link/:id" component={OutsideLink} props={childProps} />
    <AuthenticatedRoute path="/in/folder/:id" exact component={FolderView} props={childProps} />
    <AuthenticatedRoute
      path="/in/folder/:parentId/resource/:id/:viewType"
      component={ResourceView}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/in/project/:parentId/resource/:id/:viewType"
      component={ResourceView}
      props={childProps}
    />
    <AuthenticatedRoute path="/in/project/:id" exact component={ProjectView} props={childProps} />

    <AuthenticatedRoute
      path="/in/project/:id/:viewType"
      component={ProjectView}
      props={childProps}
    />

    {/* <AuthenticatedRoute
      path="/in/project/:id/video"
      exact
      component={ProjectView}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/in/project/:id/audio"
      exact
      component={ProjectView}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/in/project/:id/image"
      exact
      component={ProjectView}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/in/project/:id/doc"
      exact
      component={ProjectView}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/in/project/:id/allfiles"
      exact
      component={ProjectView}
      props={childProps}
    /> */}
    <AuthenticatedRoute path="*" component={NoMatchView} props={childProps} />
  </Switch>
);

export default Routes;

Routes.propTypes = {
  childProps: PropTypes.object.isRequired,
};
