import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getRootWorkspaceDetails } from '../../../../../../apiServices/groupApi';
import { formatBytes } from '../../../../../../util/UtilFunctions';
import { getSubscriptionStaticInfo } from '../../../../../../util/subscriptionDetails';
import { workspaceDetailsShape } from '../../../../../../apiServices/group/GroupApiTypes';

class DetailsSection extends React.Component {
  state = {
    getRootWorkspaceDetailsProcess: { status: 'initial' },
  };

  componentDidMount = () => {
    if (!this.props.rootWorkspaceDetails) {
      this.updateWorkspaceSize();
    }
  };

  updateWorkspaceSize = async () => {
    this.setState({ getRootWorkspaceDetailsProcess: { status: 'loading' } });
    try {
      const rootWorkspaceDetails = await getRootWorkspaceDetails();
      this.props.updateRootWorkspaceDetails(rootWorkspaceDetails.response);
      this.setState({
        getRootWorkspaceDetailsProcess: { status: 'success', data: rootWorkspaceDetails.response },
      });
    } catch (getRootWorkspaceDetailsError) {
      this.setState({
        getRootWorkspaceDetailsProcess: { status: 'error', error: getRootWorkspaceDetailsError },
      });
    }
  };

  getSize = (rootWorkspaceDetails, getRootWorkspaceDetailsProcess) => {
    if (rootWorkspaceDetails && rootWorkspaceDetails.usedBytes) {
      if (rootWorkspaceDetails) {
        return rootWorkspaceDetails.usedBytes.N;
      } else {
        if (getRootWorkspaceDetailsProcess.status === 'success') {
          return getRootWorkspaceDetailsProcess.data.usedBytes.N;
        } else {
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  };

  getTransferSizeThisMonth = (rootWorkspaceDetails, getRootWorkspaceDetailsProcess) => {
    if (rootWorkspaceDetails && rootWorkspaceDetails.sentBytes) {
      if (rootWorkspaceDetails) {
        return rootWorkspaceDetails.sentBytes.thisMonth.N;
      } else {
        if (getRootWorkspaceDetailsProcess.status === 'success') {
          return getRootWorkspaceDetailsProcess.data.sentBytes.thisMonth.N;
        } else {
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  };

  getTransferSizeLastMonth = (rootWorkspaceDetails, getRootWorkspaceDetailsProcess) => {
    if (rootWorkspaceDetails && rootWorkspaceDetails.sentBytes) {
      if (rootWorkspaceDetails) {
        return rootWorkspaceDetails.sentBytes.lastMonth.N;
      } else {
        if (getRootWorkspaceDetailsProcess.status === 'success') {
          return getRootWorkspaceDetailsProcess.data.sentBytes.lastMonth.N;
        } else {
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  };

  render = () => {
    const size = this.getSize(
      this.props.rootWorkspaceDetails,
      this.state.getRootWorkspaceDetailsProcess,
    );
    const transferSizeThisMonth = this.getTransferSizeThisMonth(
      this.props.rootWorkspaceDetails,
      this.state.getRootWorkspaceDetailsProcess,
    );
    const transferSizeLastMonth = this.getTransferSizeLastMonth(
      this.props.rootWorkspaceDetails,
      this.state.getRootWorkspaceDetailsProcess,
    );
    const subscriptionConstants = getSubscriptionStaticInfo(this.props.packageType);
    return (
      <>
        <Wrapper>
          <Label>Data transfer this month</Label>
          <ValueWrapper>
            <SecondaryText>
              {`${formatBytes(transferSizeThisMonth)} / ${
                subscriptionConstants
                  ? formatBytes(subscriptionConstants.dataTransfer.limit)
                  : undefined
              }`}
            </SecondaryText>
          </ValueWrapper>
        </Wrapper>
        <Wrapper>
          <Label>Data transfer last month</Label>
          <ValueWrapper>
            <SecondaryText>
              {`${formatBytes(transferSizeLastMonth)} / ${
                subscriptionConstants
                  ? formatBytes(subscriptionConstants.dataTransfer.limit)
                  : undefined
              }`}
            </SecondaryText>
          </ValueWrapper>
        </Wrapper>
        <Wrapper>
          <Label>Storage space</Label>
          <ValueWrapper>
            <SecondaryText>
              {`${formatBytes(size)} / ${
                subscriptionConstants
                  ? formatBytes(subscriptionConstants.maxStorageSpace)
                  : undefined
              }`}
            </SecondaryText>
          </ValueWrapper>
        </Wrapper>
      </>
    );
  };
}

const Wrapper = styled.div`
  height: 75px;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 var(--space-2);
`;

const Label = styled.h5`
  margin: auto 0;
  font-weight: normal;
  font-size: 1rem;
`;

const ValueWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SecondaryText = styled.h5`
  font-weight: normal;
  color: #808080;
  font-size: 1rem;
`;

export default DetailsSection;

DetailsSection.propTypes = {
  // app.jsx props
  rootWorkspaceDetails: PropTypes.shape(workspaceDetailsShape),
  updateRootWorkspaceDetails: PropTypes.func.isRequired,
  packageType: PropTypes.oneOf(['Standard', 'Premium', 'Custom', 'Free Trial', 'Expired'])
    .isRequired,
};

DetailsSection.defaultProps = {
  rootWorkspaceDetails: undefined,
};
