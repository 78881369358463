const dev = {
  s3: {
    BUCKET: 'yemma-uploads-eu-central-1-dev',
  },
  apiGateway: {
    NAME: 'yemma-api-dev',
    ENDPOINT: 'https://api.dev.theyemma.com',
    ID: '2a2r14d4pk',
    REGION: 'eu-central-1',
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_WgepPR2go',
    APP_CLIENT_ID: '19cmmncausi7kk6aa4v9t5h7vf',
    IDENTITY_POOL_ID: 'eu-central-1:45ee2d2e-0e13-4644-8888-2afb9f1b6db8',
  },
};

const prod = {
  s3: {
    BUCKET: 'yemma-uploads-eu-central-1-prod',
  },
  apiGateway: {
    NAME: 'yemma-api-prod',
    ENDPOINT: 'https://api.prod.theyemma.com',
    ID: '4f2ofqyi0a',
    REGION: 'eu-central-1',
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_uqqhvqIvE',
    APP_CLIENT_ID: '42bous72gt14s9b3c6uce1md03',
    IDENTITY_POOL_ID: 'eu-central-1:d5e69f91-80d0-47ec-9358-5b538909c7d1',
  },
};

export const config = process.env.REACT_APP_ENVIRONMENT === 'development' ? dev : prod;
