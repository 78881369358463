import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const UnauthenticatedRoute = ({ component: Component, props: childProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!childProps.isAuthenticated || rest.path === '/guest/:id') {
          return <Component {...props} {...childProps} />;
        }
        return <Redirect to="/in/folder/root" />;
      }}
    />
  );
};

export default UnauthenticatedRoute;

UnauthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  props: PropTypes.object.isRequired,
};
