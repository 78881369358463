import React from 'react';
import PropTypes from 'prop-types';
import './MiniatureFolderSystemBreadCrumbs.scss';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';
import {
  getModifiedBreadCrumbsArray,
  getItemClassName,
  getItemTitle,
} from './MiniatureFolderSystemBreadCrumbsUtlityFunctions';

class MiniatureFolderSystemBreadCrumbs extends React.Component {
  handleItemClick = (item) => {
    // do not allow update if at root folder and clicked on the root folder breadcrumb item
    if (!(!item.id && this.props.currentFolder === this.props.workspaceName)) {
      if (item.id) {
        this.props.updateFolderById(item.id);
      } else {
        this.props.updateRootFolder();
      }
    }
  };

  render() {
    const modifiedBreadCrumbsArray = getModifiedBreadCrumbsArray(
      this.props.currentFolder,
      this.props.workspaceName,
    );
    const listItems = modifiedBreadCrumbsArray.map((item, index) => {
      const isLastItem = index === modifiedBreadCrumbsArray.length - 1;
      return (
        <React.Fragment key={item.title}>
          <h5
            onClick={() => this.handleItemClick(item)}
            className={getItemClassName(item, this.props.currentFolder, this.props.workspaceName)}
            title={item.title}
          >
            {getItemTitle(item.title)}
          </h5>
          {!isLastItem && (
            <span className="circle">
              <Circle />
            </span>
          )}
        </React.Fragment>
      );
    });
    return <ul className="miniatureFolderSystemBreadCrumbs">{listItems}</ul>;
  }
}

const Circle = () => (
  <svg height="20" width="20">
    <circle cx="10" cy="10" r="3" />
  </svg>
);

export default MiniatureFolderSystemBreadCrumbs;

MiniatureFolderSystemBreadCrumbs.propTypes = {
  // ItemListModals.jsx props
  workspaceName: PropTypes.string.isRequired,

  // MiniatureFolderSystem.jsx props
  currentFolder: PropTypes.oneOfType([PropTypes.shape(folderListItemShape), PropTypes.string])
    .isRequired,
  updateRootFolder: PropTypes.func.isRequired,
  updateFolderById: PropTypes.func.isRequired,
};
