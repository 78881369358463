import React, { useContext, useState, useEffect } from 'react';
import ProjectChatMessages from './projectChatMessages/ProjectChatMessages';
import ProjectChatTasks from './projectChatTasks/ProjectChatTasks';
import MessagesIcon from '../../../../../components/icons/PaperPlaneIcon';
import TasksIcon from '../../../../../components/icons/TasksIcon';
import { sendMessage } from '../../../../../apiServices/messageApi';
import ExperimentalButton from '../../../../../components/buttons/experimentalButton/experimentalButton';
import NotificationContext from '../../../../../context/notification';
import './projectChat.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage } from '../../../../features/project/projectSlice';

const ProjectChat = (props) => {
  const contextType = useContext(NotificationContext);
  const [inputValue, setInputValue] = useState('');
  const [isSending, setIsSending] = useState(false);
  const listRef = React.useRef(null);
  const { project, user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { selectedItem, tasks, messages, projectId, userRole } = project;

  useEffect(() => {
    scrollToBottom();
  }, [props.messagesTabOpen, messages, tasks]);
  // const resourceTasks = useMemo(() => {
  //   if (!selectedItem || !tasks) return undefined;
  //   return tasks.filter((i) => i.resource === selectedItem.id);
  // }, [tasks, selectedItem]);

  // componentDidMount = async () => {
  //   const fetcherInterval = setInterval(chatFetcher, 15000);
  //   setState({ fetcherInterval });
  //   await updateMessages(false, false);
  // };

  // componentWillUnmount = () => {
  //   clearInterval(state.fetcherInterval);
  // };

  // chatFetcher = async () => {
  //   if (props.messagesTabOpen) {
  //     const fetcher = true;
  //     await updateMessages(fetcher);
  //   } else {
  //     props.getTasks(true);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (inputValue !== '') {
      setIsSending(true);
      try {
        await sendMessage(projectId, inputValue).then((i) => {
          const newMessage = i.response.Item;
          dispatch(
            addMessage({
              created: newMessage?.created.S || new Date().toString(),
              content: newMessage?.msgbody?.S,
              sender: user.uid || newMessage.sender?.S,
              id: newMessage.msgid.S,
              userData: project.users.find((i) => i.uid === (user.uid || newMessage.sender?.S)),
            }),
          );
        });
      } catch (sendMessageError) {
        contextType.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not send message',
        });
        props.updateIsQuerying(false);
      }

      setIsSending(false);
      setInputValue('');
    }
  };

  const scrollToBottom = () => {
    if (listRef?.current) {
      const scrollHeight = listRef.current.scrollHeight;
      listRef.current.scrollTop = scrollHeight;
    }
  };

  return (
    <div className="projectChat">
      <div className="header">
        <div
          className={`headerTab ${props.messagesTabOpen ? 'active' : ''}`}
          onClick={() => props.updateMessagesTabOpen(true)}
        >
          <MessagesIcon height="20px" />
          <h4>Messages</h4>
        </div>
        <div
          className={`headerTab ${!props.messagesTabOpen ? 'active' : ''}`}
          onClick={() => props.updateMessagesTabOpen(false)}
        >
          <TasksIcon height="15px" />
          <h4>Tasks</h4>
        </div>
      </div>
      <div className="contentWrapper" ref={listRef}>
        {messages && (
          <ProjectChatMessages
            //getProjectMessagesProcess={state.getProjectMessagesProcess}
            //updateMessages={updateMessages}
            //currentUserInfo={props.currentUserInfo}
            updateModalOpen={props.updateModalOpen}
            messages={messages}
            isVisible={props.messagesTabOpen}
            projectId={projectId}
            uid={user.uid}
            userRole={userRole}
          />
        )}
        {tasks && (
          <ProjectChatTasks
            tasks={tasks}
            updateSelectedTask={props.updateSelectedTask}
            users={props.users}
            previewItemOnShow={props.previewItemOnShow}
            updatePreviewItemOnShow={props.updatePreviewItemOnShow}
            previewItemsList={props.previewItemsList}
            //currentUserInfo={props.currentUserInfo}
            getTasks={props.getTasks}
            updateModalOpen={props.updateModalOpen}
            isVisible={!props.messagesTabOpen}
            projectId={projectId}
            uid={user.uid}
            userRole={userRole}
          />
        )}
      </div>
      {props.messagesTabOpen && (
        <form onSubmit={handleSubmit}>
          <div className="textAreaWrapper">
            <textarea
              type="text"
              placeholder="Write a message..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>

          <div className="submitSectionWrapper">
            <ExperimentalButton loading={isSending} onClick={handleSubmit} htmlType="submit">
              Post
            </ExperimentalButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default ProjectChat;
