import React, { useMemo, useState } from 'react';
import classes from './ProjectFilesView.module.css';
import ItemList from '../../../../components/itemList/ItemList';
import ItemListLoading from '../../../../components/itemListLoading/ItemListLoading';
import FolderViewHeader from '../../folderView/folder/folderViewHeader/FolderViewHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getFilesForProject } from '../../../../apiServices/projectApi';
import { setItems, setItemsPreview } from '../../../features/project/projectSlice';

const ProjectFilesView = (props) => {
  const [sortByCriteriaIndex, setSortByCriteriaIndex] = useState(0);
  const [sortByDirectionIsAscending, setSortByDirectionIsAscending] = useState(true);
  const { items, projectId, userRole } = useSelector((state) => state.project);
  const dispatch = useDispatch();
  // filter items and get viewType from url
  const { filteredItems, viewType } = useMemo(() => {
    const newViewType = props.match.params?.viewType;
    const newItems =
      newViewType === 'allfiles' ? items : items?.filter((i) => i.type === newViewType);
    return {
      filteredItems: newItems,
      viewType: newViewType,
    };
  }, [props.match, items]);

  const handleArrowClick = () => {
    setSortByDirectionIsAscending(!sortByDirectionIsAscending);
  };

  // For now fetch all items again since uploading new file does not return the added resource
  const updateAllProjectItems = () => {
    // thumbnail takes some time apperently
    setTimeout(
      getFilesForProject(projectId).then((res) => {
        // console.log('Update all items');
        dispatch(setItems(res.response.Items));
      }),
      1000,
    );
  };

  const handleSortByCriteriaClicked = () => {
    let newIndex;
    if (sortByCriteriaIndex === 2) {
      newIndex = 0;
    } else {
      // eslint-disable-next-line
      newIndex = sortByCriteriaIndex + 1;
    }
    setSortByCriteriaIndex(newIndex);
  };

  const setAllPreview = () => {
    dispatch(setItemsPreview());
  };

  // let content = <div />;
  // switch (props.getProjectFileListProcess.status) {
  //   case 'initial': {
  //     content = <ItemListLoading amount={10} />;
  //     break;
  //   }
  //   case 'loading': {
  //     if (!props.getProjectFileListProcess.siteLoaded) {
  //       content = <ItemListLoading amount={15} />;
  //     } else {
  //       const isError = props.getProjectFileListProcess.prevData instanceof Error;
  //       if (!isError && props.getProjectFileListProcess.prevData) {
  //         const filteredList =
  //           props.fileListView !== 'allfiles'
  //             ? props.getProjectFileListProcess.prevData.filter(
  //                 (file) => file.type.S === props.fileListView,
  //               )
  //             : props.getProjectFileListProcess.prevData;
  //         content = (
  //           <ItemList
  //             content={filteredList}
  //             sortByCriteriaIndex={sortByCriteriaIndex}
  //             sortByDirectionIsAscending={sortByDirectionIsAscending}
  //             viewAsLargeGrid
  //             inProjectInitialState={false}
  //             useCase="project"
  //             match={props.match}
  //             currentUserGroup={props.currentUserGroup}
  //             refreshList={props.getProjectFileList}
  //             history={props.history}
  //             currentItem={props.project}
  //             updateIsQuerying={props.updateIsQuerying}
  //             currentUserInfo={props.currentUserInfo}
  //             location={props.location}
  //           />
  //         );
  //       } else {
  //         content = <ItemListLoading amount={15} />;
  //       }
  //     }
  //     break;
  //   }
  //   case 'success': {
  //     const filteredList =
  //       props.fileListView !== 'allfiles'
  //         ? props.getProjectFileListProcess.data.filter(
  //             (file) => file.type.S === props.fileListView,
  //           )
  //         : props.getProjectFileListProcess.data;

  //     content = (
  //       <ItemList
  //         content={filteredList}
  //         viewAsLargeGrid
  //         useCase="project"
  //         inProjectInitialState={false}
  //         match={props.match}
  //         currentUserGroup={props.currentUserGroup}
  //         refreshList={props.getProjectFileList}
  //         history={props.history}
  //         currentItem={props.project}
  //         updateIsQuerying={props.updateIsQuerying}
  //         currentUserInfo={props.currentUserInfo}
  //         projectFileListView={props.fileListView}
  //         location={props.location}
  //         sortByCriteriaIndex={sortByCriteriaIndex}
  //         sortByDirectionIsAscending={sortByDirectionIsAscending}
  //       />
  //     );
  //     break;
  //   }
  //   case 'error': {
  //     content = (
  //       <div className={classes.errorWrapper}>
  //         <h3>Could not get project files</h3>
  //         <ExperimentalButton type="primary" title="try again" onClick={props.getProjectFileList}>
  //           Try again
  //         </ExperimentalButton>
  //       </div>
  //     );
  //     break;
  //   }
  //   default:
  //     content = <div />;
  // }

  return (
    <div className={classes.wrapper}>
      <FolderViewHeader
        sortByCriteriaIndex={sortByCriteriaIndex}
        sortByDirectionIsAscending={sortByDirectionIsAscending}
        handleArrowClick={handleArrowClick}
        handleSortByCriteriaClicked={handleSortByCriteriaClicked}
        location={props.location}
        disabled={false}
      />
      {!filteredItems ? (
        <ItemListLoading amount={15} />
      ) : (
        <ItemList
          content={filteredItems}
          useCase="project"
          inProjectInitialState={false}
          match={props.match}
          currentUserGroup={props.currentUserGroup}
          refreshList={(res) => updateAllProjectItems(res)}
          history={props.history}
          currentItem={projectId}
          updateIsQuerying={() => {}}
          currentUserInfo={props.currentUserInfo}
          location={props.location}
          sortByCriteriaIndex={sortByCriteriaIndex}
          sortByDirectionIsAscending={sortByDirectionIsAscending}
          userRole={userRole}
          projectFileListView={props.fileListView}
          setAllPreview={setAllPreview}
        />
      )}
    </div>
  );
};

export default ProjectFilesView;
