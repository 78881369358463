import React from 'react';
import PropTypes from 'prop-types';
import ProfileBall from '../../../profileBall/ProfileBall';
import ImageCropper from './imageCropper/ImageCropper';
import { getValueFromUserAttributes } from '../../../../util/UtilFunctions';
import { currentUserInfoShape } from '../../../../apiServices/userApiTypes';
import { groupShape } from '../../../../app/AppPropTypes';
import { folderListItemShape } from '../../../../app/views/folderView/FolderViewPropTypes';
import { useCaseOptions, pictureUploadingProcessShape } from '../SetPictureModalPropTypes';
import Dropzone from '../../../buttons/dropzone/dropzone';
import './ImageSection.scss';

class ImageSection extends React.Component {
  state = {
    imageDimensions: undefined,
    imageUrlForCropper: undefined,
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.pictureUploadingProcess.status === 'pictureSelected' &&
      prevProps.pictureUploadingProcess.status === 'initial'
    ) {
      const image = new Image();
      image.style.display = 'none';
      const imageSrc =
        this.props.pictureUploadingProcess.data.picture instanceof File
          ? URL.createObjectURL(this.props.pictureUploadingProcess.data.picture)
          : this.props.pictureUploadingProcess.data.picture;
      image.src = imageSrc;
      image.onload = this.handleDimensionTestImageLoaded;
      document.body.appendChild(image);
    }
  };

  handleDimensionTestImageLoaded = async (image) => {
    this.setState({
      imageDimensions: {
        height: image.target.naturalHeight,
        width: image.target.naturalWidth,
      },
    });
    if (image.target.naturalHeight > 320 && image.target.naturalWidth > 320) {
      const resizedImage = await this.getResizedImage(image.target);
      const imageUrlForCropper = URL.createObjectURL(resizedImage);
      this.setState({
        imageUrlForCropper,
      });
    } else {
      this.setState({
        imageUrlForCropper: image.target.src,
      });
    }
  };

  getRestrictedImageDimensions = (imageDimensions) => {
    if (imageDimensions) {
      const widthToHeightRatio = imageDimensions.width / imageDimensions.height;
      const heightToWidthRatio = imageDimensions.height / imageDimensions.width;
      if (imageDimensions.height > 320 && imageDimensions.width < 320) {
        return {
          height: 320,
          width: 320 * widthToHeightRatio,
        };
      } else if (imageDimensions.height < 320 && imageDimensions.width > 320) {
        return {
          height: 320 * heightToWidthRatio,
          width: 320,
        };
      } else if (imageDimensions.height < 320 && imageDimensions.width < 320) {
        return {
          height: imageDimensions.height,
          width: imageDimensions.width,
        };
      } else if (imageDimensions.height > 320 && imageDimensions.width > 320) {
        return {
          height: 320 * heightToWidthRatio > 320 ? 320 : 320 * heightToWidthRatio,
          width: 320 * widthToHeightRatio > 320 ? 320 : 320 * widthToHeightRatio,
        };
      } else {
        return undefined;
      }
    }
    return undefined;
  };

  getResizedImage = (image) => {
    const canvas = document.createElement('canvas');
    const lowerResImageDimensions = this.getRestrictedImageDimensions({
      height: image.naturalHeight,
      width: image.naturalWidth,
    });
    canvas.width = lowerResImageDimensions.width;
    canvas.height = lowerResImageDimensions.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(image, 0, 0, lowerResImageDimensions.width, lowerResImageDimensions.height);

    // eslint-disable-next-line
    return new Promise((resolve, _) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/jpeg',
        1,
      );
    });
  };

  handleDropzoneChange = (event) => {
    this.props.handlePictureChange(event);
  };

  render = () => {
    let image;
    switch (this.props.useCase) {
      case 'profile': {
        if (this.props.currentUserInfo) {
          const firstName = getValueFromUserAttributes(
            this.props.currentUserInfo.cognitoAttributes.UserAttributes,
            'given_name',
          );
          const lastName = getValueFromUserAttributes(
            this.props.currentUserInfo.cognitoAttributes.UserAttributes,
            'family_name',
          );
          image = this.props.currentUserInfo?.image?.S ? (
            <ProfileBall
              imageUrl={this.props.currentUserInfo.image?.S}
              firstName={firstName}
              lastName={lastName}
            />
          ) : (
            <Dropzone
              accept="image/png, image/jpg, image/jpeg, application/pdf"
              handleChange={this.handleDropzoneChange}
              useCase="file"
            />
          );
        }
        break;
      }
      case 'workspace': {
        image = this.props.item.image ? (
          <img src={this.props.item.image.S} alt="workspace icon" className="cover" />
        ) : (
          <Dropzone
            accept="image/png, image/jpg, image/jpeg, application/pdf"
            handleChange={this.handleDropzoneChange}
            useCase="file"
          />
        );
        break;
      }
      case 'coverImage': {
        image =
          this.props.item.previewImages?.length === 1 && this.props.item.image ? (
            <img src={this.props.item.previewImages[0].S} alt="cover" className="cover" />
          ) : (
            <Dropzone
              accept="image/png, image/jpg, image/jpeg, application/pdf"
              handleChange={this.handleDropzoneChange}
              useCase="file"
            />
          );
        break;
      }
      case 'createNew': {
        image = (
          <Dropzone
            accept="image/png, image/jpg, image/jpeg, application/pdf"
            handleChange={this.handleDropzoneChange}
            useCase="file"
          />
        );
        break;
      }
      default:
        break;
    }
    return (
      <div
        className={`setPictureModal_imageSection ${
          this.props.pictureUploadingProcess.status === 'initial' ? 'initial' : ''
        }`}
      >
        <div className="avatarWrapper">
          {this.props.pictureUploadingProcess.status === 'initial' && image}
          {(this.props.pictureUploadingProcess.status === 'pictureSelected' ||
            this.props.pictureUploadingProcess.status === 'pictureCropped') && (
            <>
              {this.state.imageUrlForCropper && (
                <ImageCropper
                  imageSrc={this.state.imageUrlForCropper}
                  updateCroppedImage={this.props.updateCroppedImage}
                  restrictedImageDimensions={this.getRestrictedImageDimensions(
                    this.state.imageDimensions,
                  )}
                  circularCrop={this.props.useCase === 'profile'}
                />
              )}
            </>
          )}
          {(this.props.pictureUploadingProcess.status === 'uploading' ||
            this.props.pictureUploadingProcess.status === 'success' ||
            this.props.pictureUploadingProcess.status === 'error') && (
            <>
              {this.props.useCase === 'profile' && (
                <img
                  src={this.props.croppedImageUrl}
                  alt="final cropped form"
                  style={{ borderRadius: '50%', maxWidth: '10rem', maxHeight: '10rem' }}
                />
              )}
              {this.props.useCase === 'workspace' ||
                (this.props.useCase === 'coverImage' && (
                  <img
                    src={this.props.croppedImageUrl}
                    alt="final cropped form"
                    style={{
                      maxWidth: '10rem',
                      maxHeight: '10rem',
                      borderRadius: '4px',
                    }}
                  />
                ))}
            </>
          )}
          {this.props.pictureUploadingProcess.status === 'pdfSelected' && (
            <>
              {this.props.pictureUploadingProcess.data.picture && (
                <img
                  src={URL.createObjectURL(this.props.pictureUploadingProcess.data.picture)}
                  alt="pdf transformed"
                  style={{ maxWidth: '17rem', maxHeight: '17rem' }}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };
}

export default ImageSection;

ImageSection.propTypes = {
  item: PropTypes.oneOfType([PropTypes.shape(groupShape), PropTypes.shape(folderListItemShape)]),
  useCase: PropTypes.oneOf(useCaseOptions).isRequired,
  pictureUploadingProcess: PropTypes.shape(pictureUploadingProcessShape).isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  updateCroppedImage: PropTypes.func.isRequired,
  croppedImageUrl: PropTypes.string,
  handlePictureChange: PropTypes.func.isRequired,
};

ImageSection.defaultProps = {
  item: undefined,
  croppedImageUrl: undefined,
};
