import { getMessages, getWorkspacesMessagesAmount } from '../../apiServices/messageApi';

const getMessagesForCurrentUserGroup = async () =>
  getMessages()
    .then((response) => response.response.Items)
    .catch(() => {
      console.warn('Failed to fetch messages');
      return false;
    });

const getAllMessages = async () =>
  getWorkspacesMessagesAmount()
    .then((response) => response.response.Items)
    .catch(() => {
      console.warn('Failed to fetch messages');
      return false;
    });

export { getMessagesForCurrentUserGroup, getAllMessages };
