import React from 'react';
import './AccountSettingsWorkspaceAdministration.scss';
import { AccountSettingsWorkspaceAdministrationList } from './accountSettingsWorkspaceAdministrationList/AccountSettingsWorkspaceAdministrationList';
import { groupShape } from '../../../../AppPropTypes';
import { currentUserInfoShape } from '../../../../../apiServices/userApiTypes';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow.svg';
import { historyShape } from '../../../../../routerPropTypes';

export const AccountSettingsWorkspaceAdministration = (props) => {
  const [listHeight, setListHeight] = React.useState('unset');
  const listRef = React.useRef(null);

  React.useEffect(() => {
    setListHeight(listRef?.current?.clientHeight);
  }, [listRef]);

  const getHeight = () => {
    if (!listRef) {
      return 'unset';
    }
    return props.sectionsOpen.some((item) => item === props.sectionIndex)
      ? `${listHeight}px`
      : '0px';
  };

  const isOpen = props.sectionsOpen.some((item) => item === props.sectionIndex);
  return (
    <div className={`account-settings-workspace-administration-wrapper ${isOpen ? 'open' : ''}`}>
      <div className="header" onClick={() => props.updateSectionsOpen(props.sectionIndex)}>
        <h4>{props.title}</h4>
        <ArrowIcon />
      </div>
      <div
        className="account-settings-workspace-administration-list-wrapper"
        style={{ height: getHeight() }}
        ref={listRef}
      >
        {isOpen && (
          <AccountSettingsWorkspaceAdministrationList
            rootGroupsList={props.rootGroupsList}
            title={`workspace control (${props.rootGroupsList.length})`}
            currentUserGroup={props.currentUserGroup}
            updateIsQuerying={props.updateIsQuerying}
            currentUserInfo={props.currentUserInfo}
            refreshList={props.refreshList}
            initiateSession={props.initiateSession}
            updateModalParams={props.updateModalParams}
            updateModalOpen={props.updateModalOpen}
            updateSectionsOpen={props.updateSectionsOpen}
            history={props.history}
          />
        )}
      </div>
    </div>
  );
};

// AccountSettingsWorkspaceAdministration.propTypes = {
//   rootGroupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
//   currentUserGroup: PropTypes.string.isRequired,
//   updateIsQuerying: PropTypes.func.isRequired,
//   refreshList: PropTypes.func.isRequired,
//   currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
//   initiateSession: PropTypes.func.isRequired,
//   updateModalParams: PropTypes.func.isRequired,
//   updateModalOpen: PropTypes.func.isRequired,
//   updateSectionsOpen: PropTypes.func.isRequired,
//   sectionsOpen: PropTypes.bool.isRequired,
//   sectionIndex: PropTypes.number.isRequired,
//   title: PropTypes.string.isRequired,
//   history: PropTypes.shape(historyShape).isRequired,
// };
