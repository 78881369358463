import React from 'react';
import { connect } from 'react-redux';
import classes from './ProjectView.module.css';
import ProjectFilesView from './projectFilesView/ProjectFilesView';
import ProjectMainView from './projectMainView/ProjectMainView';
import ProjectFooter from './projectFooter/ProjectFooter';
import { usersByProject } from '../../../apiServices/userApi';
import { getMessagesByProject } from '../../../apiServices/messageApi';
import ProjectParticipiantsModal from '../../../components/modals/projectParticipiantsModal/ProjectParticipiantsModal';
import { getProjectById, getFilesForProject } from '../../../apiServices/projectApi';
import {
  getPreviewItemIdFromLocalStorage,
  setPreviewItemToLocalStorage,
} from './projectMainView/ProjectMainViewUtilityFunctions';
import ItemNotFound from '../../../components/itemNotFound/ItemNotFound';
import ItemListLoading from '../../../components/itemListLoading/ItemListLoading';
import MainViewLoading from './mainViewLoading/MainViewLoading';
import {
  updateProjectUsers,
  updateInvitedUsers,
  updateProjectId,
  updateProjectError,
  updateProjectLoading,
  updateProjectModal,
  updateUserRole,
  setItems,
  setTasks,
  setMessages,
  setSelectedItem,
  setProjectMetadata,
} from '../../features/project/projectSlice';
import ErrorBoundary from '../../errorBoundary/ErrorBoundary';
import NotificationContext from '../../../context/notification';
import { getProjectTasks } from '../../../apiServices/taskApi';

// tasks-all (get all tasks) (filter the ones with no active preview)
// get-resource-by-parent (yhteenveto file typeistä, kaikki resurssit, (resurssilla preview-kenttä, ei tarvetta hakea previewejä erikseen))
// messages (stays the same)

class ProjectView extends React.Component {
  static contextType = NotificationContext;

  cancelled = false;

  constructor() {
    super();
    this.state = {
      getProjectProcess: { status: 'initial' },
      getProjectFileListProcess: { status: 'initial' },
      previewItemOnShow: undefined,
      currentPreviewItemIsLoaded: false,
      previewItemsList: undefined,
    };
  }

  componentDidMount = async () => {
    //this.initateProjectView(false);
    this.props.updateProjectId(this.props.match.params.id);

    this.fetchProjectData();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.cancelled = true;
      this.props.updateProjectUsers(null);
      this.props.setItems(null);
      this.props.updateInvitedUsers([]);
      this.props.setMessages(null);
      this.props.setTasks(null);
      this.props.updateUserRole(null);
      this.props.setSelectedItem(null);
      this.props.updateProjectId(null);
      this.fetchProjectData();
    }
  };

  // componentDidUpdate = async (prevProps) => {
  //   // workspaceInvitedUsers might not be ready if user access project directly
  //   // This logic will be moved to the to off the app after cleanup
  //   if (
  //     this.props.match.params.id !== prevProps.match.params.id ||
  //     this.props.workspaceInvitedUsers !== prevProps.workspaceInvitedUsers
  //   ) {
  //     this.initateProjectView(false);
  //   }
  // };

  componentWillUnmount() {
    this.cancelled = true;
    this.props.updateProjectUsers(null);
    this.props.setItems(null);
    this.props.updateInvitedUsers([]);
    this.props.setMessages(null);
    this.props.setTasks(null);
    this.props.updateUserRole(null);
    this.props.setSelectedItem(null);
    this.props.updateProjectId(null);
  }

  // initateProjectView = (siteLoaded) => {
  //   if (this.state.getProjectProcess.status !== 'initial') {
  //     this.setState({ getProjectProcess: { status: 'initial' } });
  //   }
  //   if (this.props.init && this.props.currentUserGroup) {
  //     this.getProject(siteLoaded);
  //   }
  // };

  fetchProjectData = async () => {
    getProjectById(this.props.match.params.id)
      .then((res) => {
        this.props.setProjectMetadata(res.response.Item);
        this.props.updateCurrentItem(this.props.details);
      })
      .catch((err) => console.error(err));
    getFilesForProject(this.props.match.params.id)
      .then((res) => {
        this.props.setItems(res.response.Items);
      })
      .catch((err) => console.error(err));
    await usersByProject(this.props.match.params.id)
      .then((res) => {
        this.props.updateProjectUsers(res.response.Items);
        this.props.updateInvitedUsers(res.response?.invitedUsers);
        const userRole = this.props.users.find((i) => i.uid === this.props.user.uid)?.role;
        this.props.updateUserRole(userRole);
      })
      .catch((err) => console.error(err));
    getProjectTasks(this.props.match.params.id)
      .then((res) => {
        this.props.setTasks(res.response.Items);
      })
      .catch((err) => console.error(err));
    getMessagesByProject(this.props.match.params.id)
      .then((res) => {
        this.props.setMessages(res.response.Items);
      })
      .catch((err) => console.error(err));
  };

  // updateProjectFiles = () => {
  //   getFilesForProject(this.props.match.params.id).then((res) => {
  //     this.props.setItems(res.response.Items);
  //   });
  // };

  // getProject = async (siteLoaded) => {
  //   try {
  //     const { data } = this.state.getProjectProcess;
  //     this.setState({ getProjectProcess: { status: 'loading', siteLoaded, prevData: data } });
  //     this.props.updateIsQuerying(true);
  //     const getProjectByIdResponse = await getProjectById(this.props.match.params.id);
  //     console.log('getprojectbyid', getProjectByIdResponse);
  //     this.props.updateIsQuerying(false);
  //     this.props.updateCurrentItem(getProjectByIdResponse.response.Item);
  //     if (!this.cancelled) {
  //       this.setState(
  //         {
  //           getProjectProcess: { status: 'success', data: getProjectByIdResponse.response.Item },
  //         },
  //         () => this.getProjectFileList(false, undefined, siteLoaded),
  //       );
  //     }
  //   } catch (getProjectByIdResponseError) {
  //     await this.props.updateCurrentSession();
  //     this.props.updateIsQuerying(false);
  //     this.setState({ getProjectProcess: { status: 'error', data: getProjectByIdResponseError } });
  //   }
  // };

  // getProjectFileList = async (performUntilThumbnailsAreShowing, counter, siteLoaded) => {
  //   const checkingInterval = 2000;
  //   if (!performUntilThumbnailsAreShowing) {
  //     const { data } = this.state.getProjectFileListProcess;
  //     this.setState({
  //       getProjectFileListProcess: { status: 'loading', prevData: data, siteLoaded },
  //     });
  //   }
  //   try {
  //     this.props.updateIsQuerying(true);
  //     const getProjectFilesResponse = await getFilesForProject(
  //       this.state.getProjectProcess.data.id.S,
  //     );
  //     console.log('getFilesForProject', getProjectFilesResponse);
  //     this.props.updateIsQuerying(false);
  //     const projectFilesList = getProjectFilesResponse.response.Items;
  //     if (performUntilThumbnailsAreShowing) {
  //       const projectFilesImageList = projectFilesList.filter((resource) => {
  //         if (resource.type) {
  //           if (resource.type.S === 'image') {
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         } else {
  //           return false;
  //         }
  //       });
  //       const allImagesHaveThumbnails =
  //         projectFilesImageList.find((image) => !image.thumbnailUrl) === undefined;
  //       if (!allImagesHaveThumbnails) {
  //         if (counter === 0) {
  //           const previewItemsList = this.getPreviewItemsList(projectFilesList);
  //           this.setState({
  //             getProjectFileListProcess: {
  //               status: 'success',
  //               data: projectFilesList,
  //             },
  //             previewItemsList,
  //           });
  //         }
  //         if (counter < 5) {
  //           const runningCounter = counter + 1;
  //           setTimeout(() => {
  //             this.getProjectFileList(true, runningCounter, true);
  //           }, checkingInterval);
  //         } else {
  //           if (!this.cancelled) {
  //             const previewItemsList = this.getPreviewItemsList(projectFilesList);
  //             this.setState({
  //               getProjectFileListProcess: {
  //                 status: 'success',
  //                 data: projectFilesList,
  //               },
  //               previewItemsList,
  //             });
  //           }
  //         }
  //       } else {
  //         if (!this.cancelled) {
  //           const previewItemsList = this.getPreviewItemsList(projectFilesList);
  //           this.setState({
  //             getProjectFileListProcess: {
  //               status: 'success',
  //               data: projectFilesList,
  //             },
  //             previewItemsList,
  //           });
  //         }
  //       }
  //     } else {
  //       const previewItemsList = this.getPreviewItemsList(projectFilesList);
  //       if (previewItemsList) {
  //         if (previewItemsList.length > 0) {
  //           const previewItemId = getPreviewItemIdFromLocalStorage(this.props.match.params.id);
  //           const previewItemOnShowBookmarked = previewItemsList.find(
  //             (previewItem) => previewItem.id.S === previewItemId,
  //           );
  //           let previewItemOnShow = previewItemOnShowBookmarked;
  //           if (!previewItemOnShowBookmarked) {
  //             // eslint-disable-next-line
  //             previewItemOnShow = previewItemsList[0];
  //           }
  //           this.updatePreviewItemOnShow(previewItemOnShow);
  //         }
  //       }
  //       if (!this.cancelled) {
  //         this.setState({
  //           getProjectFileListProcess: { status: 'success', data: projectFilesList },
  //           previewItemsList,
  //         });
  //       }
  //     }
  //   } catch (getFilesForProjectError) {
  //     this.props.updateIsQuerying(false);
  //     this.setState({
  //       getProjectFileListProcess: { status: 'error', data: getFilesForProjectError },
  //     });
  //   }
  // };

  // updatePreviewItemOnShow = (previewItem) => {
  //   setPreviewItemToLocalStorage(previewItem, this.props.match.params.id);
  //   if (!(previewItem.type.S !== 'doc' || previewItem.type.S !== 'audio')) {
  //     this.setState({ previewItemOnShow: previewItem });
  //   } else {
  //     this.setState({
  //       previewItemOnShow: previewItem,
  //       currentPreviewItemIsLoaded: true,
  //     });
  //   }
  // };

  // updateCurrentPreviewItemIsLoaded = (isLoaded) => {
  //   this.setState({ currentPreviewItemIsLoaded: isLoaded });
  // };

  // getPreviewItemsList = (fileList) => {
  //   if (!fileList) {
  //     const copiedItemsList = [...this.state.getProjectFileListProcess.data];
  //     const previewItemsList = copiedItemsList.filter((item) => {
  //       if (parseInt(item.preview.N, 10) === 1) {
  //         return item;
  //       }
  //       return null;
  //     });
  //     return previewItemsList;
  //   } else {
  //     const previewItemsList = fileList.filter((item) => {
  //       if (parseInt(item.preview.N, 10) === 1) {
  //         return item;
  //       }
  //       return null;
  //     });
  //     return previewItemsList;
  //   }
  // };

  toggleModalOpen = () => {
    if (this.props.error) {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Could not find project users list',
      });
    } else {
      this.props.updateProjectModal(!this.props.modalOpen);
    }
  };

  getView = () => {
    const fileListView = this.props.match.params?.viewType;
    let mainView;
    if (!!fileListView) {
      mainView = (
        <ProjectFilesView
          previewItemsList={this.state.previewItemsList}
          project={this.state.getProjectProcess.data}
          match={this.props.match}
          currentUserGroup={this.props.currentUserGroup}
          history={this.props.history}
          updateIsQuerying={this.props.updateIsQuerying}
          currentUserInfo={this.props.currentUserInfo}
          location={this.props.location}
          user={{ ...this.props.user, userRole: this.props.userRole }}
          fileListView={fileListView}
        />
      );
    } else if (false) {
      // TODO: handle error state
      return <ItemNotFound history={this.props.history} />;
    } else if (!this.props.users?.length || !this.props.items || !this.props.user?.uid) {
      return <MainViewLoading previewListItemsAmount={13} />;
    } else {
      mainView = (
        <ProjectMainView
          getProjectFileListProcess={() => {}}
          getProjectProcess={() => {}}
          getProjectFileList={() => {}}
          project={this.state.getProjectProcess.data}
          projectId={this.props.projectId}
          currentItem={this.state.getProjectProcess.data}
          refreshList={this.getProjectFileList}
          updatePreviewItemOnShow={this.updatePreviewItemOnShow}
          previewItemOnShow={this.state.previewItemOnShow}
          currentPreviewItemIsLoaded={this.state.currentPreviewItemIsLoaded}
          updateCurrentPreviewItemIsLoaded={this.updateCurrentPreviewItemIsLoaded}
          match={this.props.match}
          previewItemsList={this.state.previewItemsList}
          users={this.props.users}
          currentUserGroup={this.props.currentUserGroup}
          history={this.props.history}
          updateIsQuerying={() => {}}
          currentUserInfo={this.props.currentUserInfo}
        />
      );
    }

    return (
      <div className={classes.wrapper}>
        {this.props.currentUserInfo && mainView}
        {this.state.getProjectFileListProcess.status !== 'error' &&
          this.state.getProjectProcess.status !== 'error' && (
            <ProjectFooter
              baseUrl={`/in/project/${this.props.match.params.id}`}
              active={this.props.match.params.viewType}
            />
          )}
        {this.props.modalOpen && (
          <ProjectParticipiantsModal
            closeModal={this.toggleModalOpen}
            projectId={this.props.match.params.id}
            projectUsers={this.props.users}
            invitedUsers={this.props.invitedUsers}
            workspaceUsers={this.props.workspaceUsers}
            currentUserInfo={this.props.currentUserInfo}
            updateIsQuerying={this.props.updateIsQuerying}
            getWorkspacePeopleList={this.props.getWorkspacePeopleList}
            userRole={this.props.userRole}
          />
        )}
      </div>
    );

    //switch (this.state.getProjectProcess.status) {
    //   case 'initial':
    //     if (isInFilesView) {
    //       return <ItemListLoading amount={10} />;
    //     } else {
    //       return <MainViewLoading previewListItemsAmount={13} />;
    //     }
    //   case 'loading':
    //     if (isInFilesView) {
    //       return <ItemListLoading amount={10} />;
    //     } else {
    //       return <MainViewLoading previewListItemsAmount={13} />;
    //     }
    //   case 'success': {
    //     let mainView;
    //     if (isInFilesView) {
    //       mainView = (
    //         <ProjectFilesView
    //           getProjectFileListProcess={this.state.getProjectFileListProcess}
    //           getProjectFileList={this.updateProjectFiles}
    //           previewItemsList={this.state.previewItemsList}
    //           project={this.state.getProjectProcess.data}
    //           match={this.props.match}
    //           currentUserGroup={this.props.currentUserGroup}
    //           history={this.props.history}
    //           updateIsQuerying={this.props.updateIsQuerying}
    //           currentUserInfo={this.props.currentUserInfo}
    //           location={this.props.location}
    //         />
    //       );
    //     } else {
    //       mainView = (
    //         <ProjectMainView
    //           getProjectFileListProcess={this.state.getProjectFileListProcess}
    //           getProjectProcess={this.state.getProjectProcess}
    //           getProjectFileList={this.updateProjectFiles}
    //           project={this.state.getProjectProcess.data}
    //           currentItem={this.state.getProjectProcess.data}
    //           refreshList={this.getProjectFileList}
    //           updatePreviewItemOnShow={this.updatePreviewItemOnShow}
    //           previewItemOnShow={this.state.previewItemOnShow}
    //           currentPreviewItemIsLoaded={this.state.currentPreviewItemIsLoaded}
    //           updateCurrentPreviewItemIsLoaded={this.updateCurrentPreviewItemIsLoaded}
    //           match={this.props.match}
    //           previewItemsList={this.state.previewItemsList}
    //           users={this.props.users}
    //           currentUserGroup={this.props.currentUserGroup}
    //           history={this.props.history}
    //           updateIsQuerying={this.props.updateIsQuerying}
    //           currentUserInfo={this.props.currentUserInfo}
    //         />
    //       );
    //     }
    //   }
    //   case 'error':
    //     return <ItemNotFound history={this.props.history} />;
    //   default:
    //     return <div />;
    // }
  };

  render = () => <ErrorBoundary>{this.getView()}</ErrorBoundary>;
}

// make redux currentProjectData.users accessable for component
const mapStateToProps = ({ project, workspace, user }) => {
  const {
    users,
    userRole,
    modalOpen,
    invitedUsers,
    selectedItem,
    error,
    loading,
    projectId,
    items,
    details,
  } = project;

  return {
    projectId,
    users,
    details,
    user,
    userRole,
    items,
    invitedUsers,
    selectedItem,
    workspaceInvitedUsers: workspace.invitedUsers,
    workspaceUsers: workspace.workspaceUsers,
    currentUserGroup: workspace.currentUserGroup,
    modalOpen,
    loading,
    error,
  };
};

const mapDispatchToProps = {
  updateProjectUsers,
  updateInvitedUsers,
  updateProjectId,
  updateProjectError,
  updateProjectLoading,
  updateProjectModal,
  updateUserRole,
  setItems,
  setTasks,
  setMessages,
  setSelectedItem,
  setProjectMetadata,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);
