import React from 'react';
import PropTypes from 'prop-types';

const FullScreenIcon = (props) => {
  const { height, fill } = props;
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={height}
      height={height}
      viewBox="0 0 533.333 533.333"
      enableBackground="new 0 0 533.333 533.333"
      xmlSpace="preserve"
    >
      <path
        fill={fill}
        d="M533.333,0v216.667L450,133.333l-100,100l-50-50l100-100L316.667,0H533.333z M233.333,350l-100,100l83.333,83.333H0
      V316.667L83.333,400l100-100L233.333,350z"
      />
    </svg>
  );
};

export default FullScreenIcon;

FullScreenIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

FullScreenIcon.defaultProps = {
  height: '0',
};
