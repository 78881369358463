import PropTypes from 'prop-types';
import { cognitoAttributesShape, userInfoGroupsListItemShape } from '../app/AppPropTypes';

export const currentUserInfoShape = {
  active: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  cognitoAttributes: PropTypes.shape(cognitoAttributesShape).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape(userInfoGroupsListItemShape)).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    S: PropTypes.string,
  }),
  role: PropTypes.shape({
    S: PropTypes.oneOf(['admin', 'pro', 'user', 'viewer']).isRequired,
  }).isRequired,
  type: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
};
