import { API } from 'aws-amplify';
import { config } from '../config/Config';
import store from '../configureStore';

export const sendOrderDetails = (
  companyId,
  address,
  country,
  city,
  postalCode,
  standardPackageChosen,
) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const updateWorkspaceImageInit = {
    headers: header,
    body: `vat=${companyId}&street=${address}&country=${country}&city=${city}&zip=${postalCode}&package=${
      standardPackageChosen ? '7900' : '18900'
    }`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/group/update?gid=${currentUserGroup}`,
    updateWorkspaceImageInit,
  );
};

export const getPortalUrl = (projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const getPortalUrlInit = {
    headers: header,
  };

  return API.get(
    config.apiGateway.NAME,
    `/stripe/get-portal-url?gid=${currentUserGroup}`,
    //`/project/${projectId}?gid=${currentUserGroup}`,
    getPortalUrlInit,
  );
};
