import classes from './ItemListItem.module.scss';

export const getListClass = (previewImagesAmount) => {
  if (previewImagesAmount >= 3) {
    return classes.folderPreviewThreeImagesList;
  } else if (previewImagesAmount === 2) {
    return classes.folderPreviewTwoImagesList;
  } else {
    return undefined;
  }
};

export const getListItem = (previewImagesAmount, imageIndex) => {
  if (previewImagesAmount >= 3) {
    if (imageIndex === 0) {
      return classes.folderPreviewThreeImagesListItemWrapperFirst;
    } else if (imageIndex === 1) {
      return classes.folderPreviewThreeImagesListItemWrapperSecond;
    } else if (imageIndex === 2) {
      return classes.folderPreviewThreeImagesListItemWrapperThird;
    } else {
      return undefined;
    }
  } else if (previewImagesAmount === 2) {
    if (imageIndex === 0) {
      return classes.folderPreviewTwoImagesListItemWrapperFirst;
    } else if (imageIndex === 1) {
      return classes.folderPreviewTwoImagesListItemWrapperSecond;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getWrapperStyles = (hasPreviewImages, isSelected) => {
  if (hasPreviewImages) {
    if (isSelected) {
      return classes.selectedWrapper;
    } else {
      return classes.wrapper;
    }
  } else {
    return classes.emptyProjectWrapper;
  }
};

export const getIsFavourite = (itemId) => {
  const favourites = JSON.parse(window.localStorage.getItem('favourites'));
  if (favourites) {
    return favourites.find((favItem) => favItem.id.S === itemId) !== undefined;
  } else {
    return false;
  }
};
