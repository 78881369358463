import React from 'react';
import PropTypes from 'prop-types';

const AllfilesIcon = (props) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 49"
    enableBackground="new 0 0 60 49"
    xmlSpace="preserve"
    height={props.height}
  >
    <g>
      <path
        fill={props.fill}
        d="M14,18c-0.3,0-0.5,0.2-0.5,0.4L2,47v1c0,0.7,0,1,0.6,1h44.8c1.2,0,2.2-0.8,2.5-1.8L60,19c0,0,0-0.6,0-1H14z"
      />
      <path
        fill={props.fill}
        d="M12.7,16H53h1V9.7C54,8.2,52.8,7,51.3,7H26.5l-5-7H2.7C1.2,0,0,1.2,0,2.7v41.8l10.3-26.7C10.6,16.8,11.6,16,12.7,16z"
      />
    </g>
  </svg>
);

export default AllfilesIcon;

AllfilesIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

AllfilesIcon.defaultProps = {
  height: '0',
};
