import videojs from 'video.js';
import { getExtFromFileName } from '../../../../util/UtilFunctions';
import { validateFileTypeRendability } from '../../../../util/FileTypeSupportCheck';

const createNewMediaElement = (item, useCase) => {
  const mediaDomElement = document.createElement('video');
  const preferredWidth = useCase === 'project' ? '250px' : '200px';
  mediaDomElement.setAttribute('id', `item-list-video-player-${item.id}`);
  mediaDomElement.setAttribute('class', 'video-js vjs-default-skin');
  mediaDomElement.setAttribute('controls', 'false');
  mediaDomElement.setAttribute('data-setup', '{}');
  mediaDomElement.setAttribute('width', preferredWidth);
  mediaDomElement.setAttribute('crossorigin', 'anonymous');

  const mediaDomSourceElement = document.createElement('source');
  mediaDomSourceElement.setAttribute('src', item.signedUrl);
  mediaDomSourceElement.setAttribute(
    'type',
    item.mimeType === 'video/quicktime' ? 'video/mp4' : item.mimeType,
  );

  mediaDomElement.appendChild(mediaDomSourceElement);

  return mediaDomElement;
};

const createNewAudioElement = (
  item,
  useCase,
  videoDomElementPlayingListeningFunction,
  videoDomElementIsPaused,
) => {
  const mediaDomElement = document.createElement('audio');
  const preferredWidth = useCase === 'project' ? '250px' : '200px';
  mediaDomElement.setAttribute('id', `item-list-audio-player-${item.id}`);
  mediaDomElement.setAttribute('class', 'video-js');
  mediaDomElement.setAttribute('controls', 'true');
  mediaDomElement.setAttribute('data-setup', '{}');
  mediaDomElement.setAttribute('width', preferredWidth);
  mediaDomElement.setAttribute('crossorigin', 'anonymous');
  mediaDomElement.addEventListener('playing', videoDomElementPlayingListeningFunction);
  mediaDomElement.addEventListener('pause', videoDomElementIsPaused);
  const mediaDomSourceElement = document.createElement('source');
  mediaDomSourceElement.setAttribute('src', item.signedUrl);
  mediaDomSourceElement.setAttribute('type', item.mimeType);

  mediaDomElement.appendChild(mediaDomSourceElement);

  return mediaDomElement;
};

const handleVideoPlayerSetup = (item, useCase) => {
  const videoPlayerWrapperElement = document.getElementById(`video-wrapper-${item.id}`);
  const mediaDomElement = createNewMediaElement(item, useCase);
  videoPlayerWrapperElement.appendChild(mediaDomElement);
  videojs(mediaDomElement, {
    controlBar: false,
  });
  // shoud this be mute if audio-elements are possible?
  // playerObj.muted(true);
};

const handleAudioPlayerSetup = (item, useCase, onEnd) => {
  const audioPlayerWrapperElement = document.getElementById(`audio-wrapper-${item.id}`);
  const mediaDomElement = createNewAudioElement(item, useCase);
  audioPlayerWrapperElement.appendChild(mediaDomElement);
  const videoJsAudioPlayerOptions = {
    autoplay: false,
    controls: false,
    sources: [
      {
        src: item.signedUrl,
        type: item.mimeType,
      },
    ],
    controlBar: {
      fullscreenToggle: false,

      liveDisplay: false,
      pictureInPictureToggle: false,
      remainingTimeDisplay: false,
      volumePanel: false,
    },
  };
  videojs(mediaDomElement, videoJsAudioPlayerOptions).on('ended', onEnd);
};

export const handlePlayerSetup = (item, useCase, onAudioEnded) => {
  const isRendable = validateFileTypeRendability(item.type, getExtFromFileName(item.key));
  if (isRendable) {
    if (item.type === 'video') {
      handleVideoPlayerSetup(item, useCase);
    } else if (item.type === 'audio') {
      handleAudioPlayerSetup(item, useCase, onAudioEnded);
    }
  }
};

export const getThumbnailSrc = (item) => {
  if (item.previewImages && item.previewImages[0]) {
    return item.previewImages[0].S;
  } else if (item.thumbnailUrl) {
    return item.thumbnailUrl;
  } else if (item.snapshotUrl) {
    return item.snapshotUrl;
  } else {
    return undefined;
  }
};

const getActualMimeType = (item) => {
  if (item.mimeType.search(';') !== -1) {
    return item.mimeType.split(';')[0];
  } else {
    return item.mimeType;
  }
};

export const getIsRendableInResourceView = (item) => {
  const ext = getExtFromFileName(item.key);
  const actualMimeType = getActualMimeType(item);
  const mimeTypeFirstPart = actualMimeType.split('/')[0];
  return validateFileTypeRendability(mimeTypeFirstPart, ext);
};
