/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Folder from './folder/Folder';
import { getFolderById, getFolders } from '../../../apiServices/folderApi';
import { matchShape, locationShape, historyShape } from '../../../routerPropTypes';
import { groupShape } from '../../AppPropTypes';
import { currentUserInfoShape } from '../../../apiServices/userApiTypes';
import UserHasNoGroups from '../../../components/userHasNoGroups/UserHasNoGroups';
import ErrorBoundary from '../../errorBoundary/ErrorBoundary';
import { useSelector } from 'react-redux';
import { transformFolder, transformFolderContent } from '../../features/workspace/utils';

const FolderView = (props) => {
  const [getFolderProcess, setGetFolderProcess] = useState({ status: 'initial' });
  const [currentItem, setCurrentItem] = useState(undefined);
  const { workspace } = useSelector((state) => state);

  useEffect(() => {
    if (props.groupsList && props.groupsList.length > 0) {
      if (props.match.params.id === 'root') {
        initateMainView();
      } else {
        initateFolderView();
      }
    }
  }, [props.match.params.id, workspace.currentUserGroup]);

  const initateMainView = async () => {
    try {
      props.updateIsQuerying(true);
      const { data } = getFolderProcess;
      setGetFolderProcess({ status: 'loading', prevData: data });
      const getFoldersResponse = await getFolders();

      props.updateIsQuerying(false);
      if (
        getFoldersResponse.response.Items.length === 0 &&
        props.currentUserInfo &&
        props.currentUserInfo.role.S !== 'viewer'
      ) {
        props.history.push('/in/welcome');
      }

      const folderContent = transformFolderContent(getFoldersResponse.response.Items);
      const folderItem = getFoldersResponse.response.Item
        ? transformFolder(getFoldersResponse.response.Item)
        : undefined;

      setGetFolderProcess({ status: 'success', data: folderContent });
      setCurrentItem(folderItem);
      props.updateCurrentItem(folderItem);
    } catch (getFolderByIdError) {
      await props.updateCurrentSession();
      setGetFolderProcess({ status: 'error', data: getFolderByIdError });

      props.updateIsQuerying(false);
    }
  };

  const refreshList = () => {
    if (props.match.params.id === 'root') {
      initateMainView();
    } else {
      initateFolderView();
    }
  };

  const initateFolderView = async () => {
    const { data } = getFolderProcess;
    setGetFolderProcess({ status: 'loading', prevData: data });

    try {
      props.updateIsQuerying(true);
      const getFolderByIdResponse = await getFolderById(props.match.params.id);

      props.updateIsQuerying(false);
      const folderContent = transformFolderContent(getFolderByIdResponse.response.Items);
      const folderItem = transformFolder(getFolderByIdResponse.response.Item);

      setGetFolderProcess({ status: 'success', data: folderContent });
      setCurrentItem(folderItem);
      props.updateCurrentItem(folderItem);
    } catch (getFolderByIdError) {
      await props.updateCurrentSession();
      setGetFolderProcess({ status: 'error', data: getFolderByIdError });

      props.updateIsQuerying(false);
    }
  };

  const listEmpty = getFolderProcess.data && getFolderProcess.data.length === 0;

  return (
    <ErrorBoundary>
      {props.groupsList && props.groupsList.length > 0 && (
        <Folder
          queryProcessObj={getFolderProcess}
          refreshList={refreshList}
          initateMainView={initateMainView}
          currentItem={currentItem ? currentItem : { id: 'root' }}
          location={props.location}
          match={props.match}
          updateIsQuerying={props.updateIsQuerying}
          currentUserGroup={props.currentUserGroup}
          history={props.history}
          currentUserInfo={props.currentUserInfo}
        />
      )}
      <div style={{ width: '15rem', textAlign: 'center', margin: 'auto' }}>
        {listEmpty && props.currentUserInfo && props.currentUserInfo.role.S === 'viewer' && (
          <h5 style={{ fontSize: '1.5rem' }}>No viewable content</h5>
        )}
      </div>
      {props.groupsList && props.groupsList.length === 0 && <UserHasNoGroups />}
    </ErrorBoundary>
  );
};

export default FolderView;

FolderView.propTypes = {
  // router prop types
  history: PropTypes.shape(historyShape).isRequired,
  match: PropTypes.shape(matchShape).isRequired,
  location: PropTypes.shape(locationShape).isRequired,

  // app prop types
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserGroup: PropTypes.string.isRequired,
  updateCurrentItem: PropTypes.func.isRequired,
  updateCurrentSession: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape),
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
};

FolderView.defaultProps = {
  currentUserInfo: undefined,
};
