import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ProfileBall from '../../../../profileBall/ProfileBall';
import { updateUserRole, removeUserFromWorkspace } from '../../../../../apiServices/userApi';
import { groupShape, workspaceParticipantShape } from '../../../../../app/AppPropTypes';
import NotificationContext from '../../../../../context/notification';
import RoleSelector from '../../roleSelector';
import ExperimentalButton from '../../../../buttons/experimentalButton/experimentalButton';
import { ReactComponent as CrossIcon } from '../../../../../assets/nitro/cross.svg';
import './WorkspaceParticipiantsModalPeopleListItem.scss';
import {
  getIsRoleEditable,
  getIsUserRemovable,
} from './WorkspaceParticipantsModalPeopleListItemUtilityFunctions';

const WorkspaceParticipiantsModalPeopleListItem = ({
  currentUserInfoInThisWorkspace,
  currentUserWorkspacePeopleListItem,
  itemIsCurrentUser,
  profileImageUrl,
  email,
  updateIsQuerying,
  getPeopleList,
  uid,
  chosenGroup,
  role,
  firstName,
  lastName,
  person,
  updateShowInfo,
}) => {
  const [availableRoles, setAvailableRoles] = useState([]);
  // const [updateUserRoleProcess, setUpdateUserRoleProcess] = useState({ status: 'inactive' });
  const [removeUserStatus, setRemoveUserStatus] = useState('initial');

  const notificationContext = useContext(NotificationContext);

  useEffect(() => {
    if (currentUserInfoInThisWorkspace.role.S === 'admin') {
      setAvailableRoles(['viewer', 'user', 'pro']);
    } else if (currentUserInfoInThisWorkspace.role.S === 'pro') {
      setAvailableRoles(['viewer', 'user']);
    } else if (currentUserInfoInThisWorkspace.role.S === 'user') {
      setAvailableRoles(['viewer']);
    }
  }, [currentUserInfoInThisWorkspace]);

  const removeUser = async () => {
    if (
      role !== 'admin' ||
      (role === 'admin' && window.confirm(`Remove admin '${firstName} ${lastName}'?`))
    ) {
      setRemoveUserStatus('loading');
      try {
        updateIsQuerying(false);
        await removeUserFromWorkspace(uid, chosenGroup.id.S);
        getPeopleList();
        setRemoveUserStatus('initial');
        updateIsQuerying(false);
        notificationContext.add({
          id: Date.now(),
          type: 'success',
          message: `Removed user ${firstName} ${lastName}`,
        });
      } catch (removeUserFromWorkspaceError) {
        notificationContext.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not remove user',
        });
        setRemoveUserStatus('initial');
        updateIsQuerying(false);
      }
    }
  };

  const changeUserRole = async (selectedRole) => {
    // setUpdateUserRoleProcess({ status: 'loading' });
    updateIsQuerying(true);
    try {
      await updateUserRole(uid, selectedRole, chosenGroup.id.S);
      updateIsQuerying(false);
      getPeopleList();
      // setUpdateUserRoleProcess({ status: 'success' });

      notificationContext.add({
        id: Date.now(),
        type: 'success',
        message: 'Updated user role',
      });
    } catch (updateUserRoleError) {
      updateIsQuerying(false);
      // setUpdateUserRoleProcess({ status: 'error', data: updateUserRoleError });
      notificationContext.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not change user role',
      });
    }
  };

  return (
    <li className="workspaceParticipiantsModalPeopleListItem">
      <div className="avatarWrapper" title={`${firstName} ${lastName}`}>
        <ProfileBall
          imageUrl={profileImageUrl}
          hasBorder={itemIsCurrentUser}
          firstName={firstName}
          lastName={lastName}
        />
      </div>
      <div className="userInfoWrapper">
        <div className="userTitleField">
          <h5 className="userInfoName">
            {`${firstName} ${lastName} `}
            {person.creator?.N === '1' && <span>(Owner)</span>}
          </h5>
        </div>
        <h5 className="userInfoEmail">{`${email}`}</h5>
      </div>
      <RoleSelector
        availableValues={availableRoles}
        value={role}
        setValue={changeUserRole}
        isEditable={getIsRoleEditable(
          chosenGroup,
          currentUserWorkspacePeopleListItem,
          currentUserInfoInThisWorkspace,
          role,
          person,
        )}
        updateShowInfo={updateShowInfo}
      />
      {getIsUserRemovable(currentUserInfoInThisWorkspace, itemIsCurrentUser, person, role) && (
        <ExperimentalButton
          type="grey"
          isDisabled={removeUserStatus === 'loading'}
          onClick={removeUser}
          title={`Remove ${firstName} ${lastName}`}
        >
          <CrossIcon height="10px" fill="lightgray" />
        </ExperimentalButton>
      )}
    </li>
  );
};

export default WorkspaceParticipiantsModalPeopleListItem;

WorkspaceParticipiantsModalPeopleListItem.propTypes = {
  uid: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  profileImageUrl: PropTypes.string,
  currentUserInfoInThisWorkspace: PropTypes.shape(workspaceParticipantShape).isRequired,
  chosenGroup: PropTypes.shape(groupShape).isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  getPeopleList: PropTypes.func.isRequired,
  itemIsCurrentUser: PropTypes.bool.isRequired,
  person: PropTypes.shape(workspaceParticipantShape).isRequired,
  currentUserWorkspacePeopleListItem: PropTypes.shape(workspaceParticipantShape).isRequired,
  updateShowInfo: PropTypes.func.isRequired,
};

WorkspaceParticipiantsModalPeopleListItem.defaultProps = {
  profileImageUrl: undefined,
};
