import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import AccountSettingsWorkspaceListItem from './accountSettingsWorkspaceListItem/AccountSettingsWorkspaceListItem';
import { groupShape } from '../../../../AppPropTypes';
import { currentUserInfoShape } from '../../../../../apiServices/userApiTypes';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow.svg';
import './AccountSettingsWorkspaceList.scss';

const AccountSettingsWorkspaceList = (props) => {
  const listRef = useRef(null);

  const rootGroupsListItems = props.groupsList.map((rootGroup) => (
    <AccountSettingsWorkspaceListItem
      key={rootGroup.id.S}
      group={rootGroup}
      currentUserGroup={props.currentUserGroup}
      updateModalOpen={props.updateModalOpen}
      updateModalParams={props.updateModalParams}
      updateIsQuerying={props.updateIsQuerying}
      currentUserInfo={props.currentUserInfo}
      refreshList={props.refreshList}
      dropdownOpenedItemId={props.dropdownOpenedItemId}
      updateDropdownOpenedItemId={props.updateDropdownOpenedItemId}
      initiateSession={props.initiateSession}
    />
  ));

  const getClassNameStr = () => {
    const sectionIsOpen = props.sectionsOpen.some((item) => item === props.sectionIndex);
    if (props.rootWorkspaceHasExpired) {
      return 'accountSettingsWorkspaceList disabled';
    } else if (sectionIsOpen) {
      return 'accountSettingsWorkspaceList open';
    } else {
      return 'accountSettingsWorkspaceList';
    }
  };

  return (
    <div className={getClassNameStr()}>
      <div className="header" onClick={() => props.updateSectionsOpen(props.sectionIndex)}>
        <h4>{props.title}</h4>
        <ArrowIcon />
      </div>
      {!props.rootWorkspaceHasExpired && (
        <ul className="list" ref={listRef}>
          {rootGroupsListItems}
        </ul>
      )}
    </div>
  );
};

export default AccountSettingsWorkspaceList;

AccountSettingsWorkspaceList.propTypes = {
  // app.jsx props
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
  currentUserGroup: PropTypes.string.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  refreshList: PropTypes.func.isRequired,
  initiateSession: PropTypes.func.isRequired,

  // AccountSettingsWorkspace.jsx props
  updateModalParams: PropTypes.func.isRequired,
  updateModalOpen: PropTypes.func.isRequired,
  updateSectionsOpen: PropTypes.func.isRequired,

  title: PropTypes.string.isRequired,
  dropdownOpenedItemId: PropTypes.string,
  updateDropdownOpenedItemId: PropTypes.func.isRequired,
  sectionsOpen: PropTypes.arrayOf(PropTypes.number).isRequired,
  sectionIndex: PropTypes.number.isRequired,
  rootWorkspaceHasExpired: PropTypes.bool.isRequired,
};

AccountSettingsWorkspaceList.defaultProps = {
  dropdownOpenedItemId: undefined,
};
