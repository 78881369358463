import React, { useMemo } from 'react';
import classes from './ProjectMainViewWorkSection.module.scss';
import PreviewItemsList from '../projectPreviewItemsList/ProjectPreviewItemsList';
import ProjectMainViewVideoPlayer from './projectMainViewVideoPlayer/ProjectMainViewVideoPlayer';
import ProjectMainViewImage from './projectMainViewImage/ProjectMainViewImage';
import ProjectMainViewAddFiles from './projectMainViewComponents/projectMainViewAddFiles/ProjectMainViewAddFiles';
import ProjectMainViewAudioPlayer from './projectMainViewAudioPlayer/ProjectMainViewAudioPlayer';
import ProjectMainViewPdf from './projectMainViewPdf/ProjectMainViewPdf';

import './projectMainViewWorkSection.scss';
import { useSelector } from 'react-redux';

const ProjectMainViewWorkSection = ({
  selectedTask,
  messagesTabOpen,
  updateSelectedTask,
  updateModalOpen,
  match,
  ...props
}) => {
  const { project } = useSelector((state) => state);
  const { selectedItem, tasks, userRole } = project;
  const isViewer = userRole === 'viewer' || userRole === 'guest';
  // TODO: can be refactored, we also do this on ProjectChat.jsx
  const selectedItemTasks = useMemo(() => {
    if (!selectedItem || !tasks) return [];
    return tasks.filter((i) => i.resource === selectedItem.id);
  }, [tasks, selectedItem]);
  // <div className={classes.mainContentWrapper}>
  //   <div className={classes.mainContentPreviewItemWrapper}>
  //     <div />
  //   </div>
  //   <PreviewListLoading amount={13} />
  // </div>

  const lastIndex = useMemo(() => {
    if (selectedItemTasks) {
      return Math.max(...selectedItemTasks?.map((i) => i.index), 0);
    }
    return 0;
  }, [selectedItemTasks]);

  return (
    <div className="projectMainViewWorkSection">
      {!!selectedItem && (
        <>
          {' '}
          <div className="mainContent">
            <div className={`wrapper ${!!selectedItem ? selectedItem.type : ''}`}>
              {selectedItem?.type === 'video' && (
                <ProjectMainViewVideoPlayer
                  tasks={selectedItemTasks}
                  match={props.match}
                  users={project.users}
                  messagesTabOpen={messagesTabOpen}
                  selectedTask={selectedTask}
                  updateSelectedTask={updateSelectedTask}
                  highestIndex={lastIndex + 1}
                  resource={selectedItem}
                  userRole={userRole}
                />
              )}
              {selectedItem?.type === 'audio' && (
                <ProjectMainViewAudioPlayer
                  tasks={selectedItemTasks}
                  match={props.match}
                  users={project.users}
                  messagesTabOpen={messagesTabOpen}
                  selectedTask={selectedTask}
                  updateSelectedTask={updateSelectedTask}
                  resource={selectedItem}
                  highestIndex={lastIndex + 1}
                  userRole={userRole}
                />
              )}
              {selectedItem?.type === 'image' && (
                <ProjectMainViewImage
                  match={props.match}
                  updateTasks={props.updateTasks}
                  selectedTask={selectedTask}
                  updateSelectedTask={updateSelectedTask}
                  users={props.users}
                  messagesTabOpen={messagesTabOpen}
                  resource={selectedItem}
                  tasks={selectedItemTasks}
                  highestIndex={lastIndex + 1}
                  userRole={userRole}
                />
              )}
              {selectedItem?.type === 'doc' && (
                <ProjectMainViewPdf match={props.match} resource={selectedItem} />
              )}
            </div>
          </div>
          <PreviewItemsList updateSelectedTask={updateSelectedTask} />
        </>
      )}

      {!selectedItem && (
        <>
          {!isViewer && <ProjectMainViewAddFiles updateModalOpen={updateModalOpen} />}
          {isViewer && <h5 style={{ textAlign: 'center' }}>No content</h5>}
        </>
      )}
    </div>
  );

  // <div className={classes.errorWrapper}>
  //   <h3>{`Could not get project files due to ${props.getProjectFileListProcess.data.message}`}</h3>
  //   <ExperimentalButton type="primary" onClick={props.getProjectFileList} title="try again">
  //     Try again
  //   </ExperimentalButton>
  // </div>
};

export default ProjectMainViewWorkSection;
