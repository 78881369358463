import React from 'react';
import PropTypes from 'prop-types';
import { sendEmails, sendEmailsWithMessage } from '../../../../apiServices/emailApi';
import { getEmailSenderInfo } from '../ShareFileModalUtilFunctions';
import { validateEmailFormat } from '../../../../util/UtilFunctions';
import { currentUserInfoShape } from '../../../../apiServices/userApiTypes';
import { ReactComponent as CrossIcon } from '../../../../assets/nitro/cross.svg';
import { outsideResourceIdsProcessShape } from '../ShareFileModalPropTypes';
import ExperimentalButton from '../../../buttons/experimentalButton/experimentalButton';
import './sendEmailSection.scss';
import ShareFileModalInputField from '../shareFileModalInputField/ShareFileModalInputField';
import NotificationContext from '../../../../context/notification';

class SendEmailSection extends React.Component {
  state = {
    emailAddressFieldValue: '',
    messageFieldValue: '',
    emailSendingProcess: { status: 'inactive' },
    confirmedEmails: [],
    showError: undefined,
  };

  handleEmailAddressFieldValueChange = (event) => {
    if (event.target.value.search(',') !== -1) {
      this.addEmailInputToConfirmedEmails();
    } else {
      this.setState({
        emailAddressFieldValue: event.target.value.toLowerCase(),
        showError: undefined,
      });
    }
  };

  handleEmailLinkSubmit = async () => {
    if (this.state.emailAddressFieldValue === '' && this.state.confirmedEmails.length > 0) {
      if (this.props.outsideResourceIdsProcess.status === 'success') {
        try {
          this.setState({ emailSendingProcess: { status: 'loading' } });
          const emailSenderInfo = getEmailSenderInfo(this.props.currentUserInfo);
          let sendEmailResponse;
          if (this.state.messageFieldValue.length > 0) {
            this.props.updateIsQuerying(true);
            sendEmailResponse = await sendEmailsWithMessage(
              this.state.confirmedEmails,
              `${emailSenderInfo.firstName} ${emailSenderInfo.lastName}`,
              this.props.outsideResourceIdsProcess.data,
              this.state.messageFieldValue,
            );
            this.props.updateIsQuerying(false);
          } else {
            this.props.updateIsQuerying(true);
            sendEmailResponse = await sendEmails(
              this.state.confirmedEmails,
              `${emailSenderInfo.firstName} ${emailSenderInfo.lastName}`,
              this.props.outsideResourceIdsProcess.data,
            );
            this.props.updateIsQuerying(false);
          }
          if (sendEmailResponse.response.search('error') !== -1) {
            this.context.add({
              id: Date.now(),
              type: 'error',
              message: 'Email sending failed',
            });
            this.setState({ emailSendingProcess: { status: 'inactive' } });
          } else {
            this.context.add({
              id: Date.now(),
              type: 'success',
              message: 'Email sent',
            });
            this.props.closeModal();
            this.setState({ emailSendingProcess: { status: 'inactive' } });
          }
        } catch (sendEmailError) {
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not send email',
          });
          this.props.updateIsQuerying(false);
        }
      } else {
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: 'No link to send',
        });
        this.setState({ emailAddressFieldValue: '' });
      }
    } else {
      const { emailAddressFieldValue } = this.state;
      if (validateEmailFormat(emailAddressFieldValue.trim())) {
        const { confirmedEmails } = this.state;
        const emails = [...confirmedEmails, emailAddressFieldValue];
        this.setState({
          emailAddressFieldValue: '',
          confirmedEmails: emails,
        });
      } else {
        this.setState({ emailAddressFieldValue: '', showError: undefined });
      }
    }
  };

  emailDoesNotExistInConfirmedList = (emailAddressFieldValue, confirmedEmails) =>
    confirmedEmails.find((email) => email === emailAddressFieldValue) === undefined;

  addEmailInputToConfirmedEmails = () => {
    if (this.state.emailAddressFieldValue !== '') {
      if (validateEmailFormat(this.state.emailAddressFieldValue.trim())) {
        if (
          this.emailDoesNotExistInConfirmedList(
            this.state.emailAddressFieldValue,
            this.state.confirmedEmails,
          )
        ) {
          const { confirmedEmails } = this.state;
          const emails = [...confirmedEmails, this.state.emailAddressFieldValue];
          this.setState({
            emailAddressFieldValue: '',
            confirmedEmails: emails,
          });
        } else {
          this.setState({ showError: 'Email already received' });
        }
      } else {
        this.setState({ showError: 'Not a valid email address' });
      }
    } else {
      this.setState({ showError: 'No email address given' });
    }
  };

  handleMessageFieldValueChange = (event) => {
    if (this.state.emailAddressFieldValue.length > 0) {
      this.addEmailInputToConfirmedEmails();
    }
    this.setState({ messageFieldValue: event.target.value });
  };

  removeEmail = (email) => {
    const { confirmedEmails } = this.state;
    const filteredList = confirmedEmails.filter((emailItem) => emailItem !== email);
    this.setState({ confirmedEmails: filteredList });
  };

  static contextType = NotificationContext;

  render = () => {
    const emailListItems = this.state.confirmedEmails.map((email) => (
      <li key={email}>
        <h5>{email}</h5>
        <button
          className="removeButton"
          onClick={() => this.removeEmail(email)}
          type="button"
          title="remove"
        >
          <CrossIcon />
        </button>
      </li>
    ));

    return (
      <div className="sendEmailSection">
        <form onSubmit={(event) => event.preventDefault()}>
          <ul>{emailListItems}</ul>
          <ShareFileModalInputField
            useCase="email"
            handleEmailAddressFieldValueChange={this.handleEmailAddressFieldValueChange}
            fieldValue={this.state.emailAddressFieldValue}
            addEmailInputToConfirmedEmails={this.addEmailInputToConfirmedEmails}
          />
          <h6 className="errorText">
            {this.state.showError !== undefined && this.state.showError}
          </h6>
          <textarea
            type="text"
            value={this.state.messageFieldValue}
            onChange={this.handleMessageFieldValueChange}
            placeholder="Message"
          />
          <ExperimentalButton
            onClick={this.handleEmailLinkSubmit}
            loading={this.state.emailSendingProcess.status === 'loading'}
            isDisabled={this.state.confirmedEmails.length < 1}
            type="primary"
          >
            {this.state.emailSendingProcess.status === 'error' ? 'Try again' : 'Send email'}
          </ExperimentalButton>
        </form>
      </div>
    );
  };
}

export default SendEmailSection;

SendEmailSection.propTypes = {
  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,

  // shareFileModal.jsx props
  outsideResourceIdsProcess: PropTypes.shape(outsideResourceIdsProcessShape).isRequired,
};
