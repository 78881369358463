import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';
import Loader from '../../loader/loader';

const ExperimentalButton = ({
  type = 'primary',
  isDisabled = false,
  htmlType = 'button',
  loading = false,
  onClick,
  animated = true,
  title,
  children,
}) => (
  <button
    className={`button ${type} ${isDisabled ? 'disabled' : ''} ${loading ? 'loading' : ''}`}
    type={htmlType}
    onClick={onClick}
    disabled={isDisabled || loading}
    title={loading ? 'loading' : title}
  >
    {animated && loading ? <Loader /> : children}
  </button>
);

export default ExperimentalButton;

ExperimentalButton.propTypes = {
  onClick: PropTypes.func, // function to run on button's native click-event
  title: PropTypes.string, // html title-attribute
  animated: PropTypes.bool, // whether to display loader-animation on loading, defaults to true
  htmlType: PropTypes.string, // html-type, defaults to 'button'
  // boolean to determine loading-state. Will give button 'loading'-class to set some styling,
  // will disable button-events, if animated-prop is true will display loader-animation
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired, // content of the button
  // boolean to determine disabled-state.
  // Will give button 'disabled'-class to set some styling, will disable button-events
  isDisabled: PropTypes.bool,
  // type to get some default styling for commonly used buttons.
  // At the moment there is: 'primary', 'secondary' and 'green'. Defaults to 'primary'.
  type: PropTypes.string,
};

ExperimentalButton.defaultProps = {
  type: 'primary',
  isDisabled: false,
  htmlType: 'button',
  loading: false,
  animated: true,
  title: '',
  onClick: undefined,
};
