import React, { useEffect } from 'react';
import styles from './ProjectChatTasks.module.scss';
import Message from '../../projectMainViewWorkSection/projectMainViewComponents/message/Message';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem } from '../../../../../features/project/projectSlice';

const ProjectChatTasks = ({
  tasks,
  updateSelectedTask,
  uid,
  userRole,
  projectId,
  updateModalOpen,
  isVisible,
  previewItemsList,
  previewItemOnShow,
  updatePreviewItemOnShow,
}) => {
  const { items, selectedItem } = useSelector((state) => state.project);
  const dispatch = useDispatch();

  return (
    <ul className={`${styles.container} ${isVisible ? '' : styles.hidden}`}>
      {tasks.map((task) => {
        return task.messages.map((message, index) => {
          const isFirst = index === 0;
          const imageUrl = isFirst
            ? items.find((i) => i.id === task.resource)?.thumbnailUrl
            : message.userData?.img;

          return (
            <li key={message.created}>
              <Message
                firstName={message.userData?.given_name}
                lastName={message.userData?.family_name}
                created={message.created}
                content={message.content}
                image={{
                  task: isFirst,
                  imageUrl,
                }}
                isAReply={!isFirst}
                task={task}
                previewItemOnShow={selectedItem}
                updatePreviewItemOnShow={(i) => dispatch(setSelectedItem(i))}
                taskResource={items.find((i) => i.id === task.resource)}
                updateSelectedTask={updateSelectedTask}
                isRemovable={isFirst && (userRole === 'admin' || uid === task.owner) && !!task.id}
                projectId={projectId}
                // refreshList={getTasks}
                updateModalOpen={updateModalOpen}
              />
            </li>
          );
        });
      })}
    </ul>
  );
};

export default ProjectChatTasks;
