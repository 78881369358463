import React, { useState, useContext } from 'react';
import classes from './ProjectParticipiantsModalPeopleList.module.css';
import ProjectParticipiantsModalPeopleListItem from './projectParticipiantsModalPeopleListItem/ProjectParticipiantsModalPeopleListItem';
import { updateUserAccess } from '../../../../apiServices/userApi';
import NotificationContext from '../../../../context/notification';
import { removeUser } from '../../../../app/features/project/projectSlice';
import { useDispatch } from 'react-redux';

const ProjectParticipiantsModalPeopleList = ({ users, projectId, ...props }) => {
  const [deletingUserId, setDeletingUserId] = useState(undefined);
  const { add } = useContext(NotificationContext);
  const dispatch = useDispatch();

  const removeUserFromProject = async (person) => {
    setDeletingUserId(person.uid);
    try {
      const res = await updateUserAccess(person.uid, projectId, 0);

      dispatch(removeUser(person.uid));

      setDeletingUserId(undefined);
      add({
        id: Date.now(),
        message: `${person.given_name} ${person.family_name} removed from project.`,
        type: 'success',
      });
    } catch (updateUserAccessError) {
      add({
        id: Date.now(),
        message: 'Could not remove user from project',
        type: 'error',
      });
      setDeletingUserId(undefined);
    }
  };

  const sortedList = [...users].sort((i, j) => i.given_name < j.given_name);
  const listItems = sortedList.map((person) => {
    const deleting = deletingUserId === person.uid;
    return (
      <ProjectParticipiantsModalPeopleListItem
        key={person.uid}
        person={person}
        removeUserFromProject={removeUserFromProject}
        deleting={deleting}
        currentUserIsAdminOrPro={props.currentUserIsAdminOrPro}
      />
    );
  });

  return <ul className={classes.list}>{listItems}</ul>;
};

export default ProjectParticipiantsModalPeopleList;
