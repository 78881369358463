import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from '../../../assets/icons8-info.svg';
import * as stylesVariables from '../../../styles/variables';

export const Infobox = (props) => (
  <Wrapper>
    <Button
      onClick={() =>
        props.updateShowInfo({
          children: props.children,
          maxHeight: props.maxHeight,
        })
      }
      type="button"
      stylesVariables={stylesVariables}
    >
      <InfoIcon />
    </Button>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  overflow: visible;
`;

const Button = styled.button`
  border: none;
  background-color: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  svg {
    width: 1.2rem;
    fill: ${(props) => props.stylesVariables.colorDenimPrimary};
  }
`;

