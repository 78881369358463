import { basicSubscription, premiumSubscription } from '../constantValues/ConstantValues';
// eslint-disable-next-line
import { getCurrentUserCreatorGroup } from './UtilFunctions';

export const hasExpired = (workspace) => {
  if (workspace) {
    if (workspace.expired) {
      return workspace.expired.S === 'true';
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getCurrentWorkspaceActivePackage = (groupsList, currentUserGroup) => {
  const currentWorkspace = groupsList.find((group) => group.id.S === currentUserGroup);
  if (currentWorkspace.monthlyCharge) {
    if (currentWorkspace.monthlyCharge.N) {
      return currentWorkspace.monthlyCharge.N;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getWorkspaceActivePackage = (workspace) => {
  if (workspace) {
    if (workspace.monthlyCharge) {
      if (workspace.monthlyCharge.N) {
        return workspace.monthlyCharge.N;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getPackageType = (currentUserInfo, groupsList) => {
  const currentUserCreatorGroup = getCurrentUserCreatorGroup(currentUserInfo, groupsList);
  const activePackage = getWorkspaceActivePackage(currentUserCreatorGroup);
  const userHasNoCreatedGroups =
    groupsList.find((group) => group.creator.S === currentUserInfo.user.S) === undefined;
  if (activePackage && parseInt(activePackage, 10) !== 0) {
    if (parseInt(activePackage, 10) === 7900) {
      return 'Standard';
    } else if (parseInt(activePackage, 10) === 18900) {
      return 'Premium';
    } else {
      return 'Custom';
    }
  } else if (userHasNoCreatedGroups) {
    return 'Invited';
  } else {
    if (hasExpired(currentUserCreatorGroup)) {
      return 'Expired';
    } else {
      return 'Free trial';
    }
  }
};

export const getPackageTypeStr = (packageType) => {
  switch (packageType) {
    case 'Standard':
      return `Standard (${basicSubscription.price})`;
    case 'Premium':
      return `Premium (${premiumSubscription.price})`;
    case 'Custom':
      return 'Custom';
    case 'Expired':
      return 'Expired';
    case 'Free trial':
      return 'Free trial';
    default:
      return undefined;
  }
};

export const getSubscriptionStaticInfo = (packageType) => {
  if (packageType === 'Standard' || packageType === 'Free trial') {
    return basicSubscription;
  } else if (packageType === 'Premium') {
    return premiumSubscription;
  } else {
    return undefined;
  }
};
