import React, { useState } from 'react';
import { ReactComponent as Soundwave } from '../../../../../../../../../assets/soundwave.svg';
import './messageImageSectionTaskItem.scss';
import { formatMilliSeconds } from '../../../../../../../../../util/UtilFunctions';
import { lineTypes } from '../../../../projectMainViewVideoPlayer/arrowsOverlay/utils';
import { ReactComponent as ImageIcon } from '../../../../../../../../../assets/nitro/images.svg';

const MessageImageSectionTaskItem = ({ image, task, handleImageClick }) => {
  const [imageError, setImageError] = useState(false);
  const renderImage = () => {
    if (imageError) {
      return <h4>No thumbnail</h4>;
    } else {
      return <img src={image?.imageUrl} alt="task preview" onError={() => setImageError(true)} />;
    }
  };
  const classNames = Object.values(lineTypes).includes(task.taskType) ? 'lineTask' : 'defaultTask';

  return (
    <div
      className={`messageImageSectionTaskItem ${classNames} ${task.type}`}
      onClick={handleImageClick}
    >
      <div className="imageTimestampOverlay">
        {task?.type !== 'image' ? <h5>{formatMilliSeconds(task.timestamp)}</h5> : <ImageIcon />}
      </div>
      {task?.type === 'audio' ? (
        <div className="soundWaveIcon">
          <Soundwave />
        </div>
      ) : (
        renderImage()
      )}
    </div>
  );
};

export default MessageImageSectionTaskItem;
