import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './canvas.scss';
import { dimensionsShape } from '../../../ProjectMainViewPropTypes';

const Canvas = ({ dimensions, afterDraw }) => {
  const { width, height, offsetLeft, offsetTop } = dimensions;

  const canvasRef = useRef(null);
  const [isPainting, setIsPainting] = useState(false);
  const [mousePosition, setMousePosition] = useState(undefined);
  const [coords, setCoords] = useState([]);

  const startPaint = useCallback(
    (event) => {
      const coordinates = getCoordinates(event);
      if (coordinates) {
        setMousePosition(coordinates);
        setIsPainting(true);
      }
    },
    [dimensions],
  );

  useEffect(() => {
    setMousePosition(undefined);
    setIsPainting(false);
  }, [dimensions]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    canvas.addEventListener('mousedown', startPaint);
    return () => {
      canvas.removeEventListener('mousedown', startPaint);
    };
  }, [startPaint]);

  const paint = useCallback(
    (event) => {
      if (isPainting) {
        const newMousePosition = getCoordinates(event);
        if (mousePosition && newMousePosition) {
          drawLine(mousePosition, newMousePosition);
          setMousePosition(newMousePosition);
        }
      }
    },
    [isPainting, mousePosition],
  );

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    canvas.addEventListener('mousemove', paint);
    return () => {
      canvas.removeEventListener('mousemove', paint);
    };
  }, [paint]);

  const exitPaint = useCallback(() => {
    setIsPainting(false);
    setMousePosition(undefined);

    afterDraw(coords);
    setCoords([]);
    clearCanvas();
  }, [coords]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    canvas.addEventListener('mouseup', exitPaint);
    canvas.addEventListener('mouseleave', exitPaint);
    return () => {
      canvas.removeEventListener('mouseup', exitPaint);
      canvas.removeEventListener('mouseleave', exitPaint);
    };
  }, [exitPaint]);

  // clear all active drawings from canvas
  const clearCanvas = () => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    if (context) {
      context.clearRect(0, 0, width, height);
    }
  };

  const getCoordinates = (event) => {
    if (!canvasRef.current) {
      return;
    }
    const newPosition = [event.pageX - offsetLeft, event.pageY - offsetTop];
    setCoords([...coords, newPosition]);
    return newPosition;
  };

  const drawLine = (originalMousePosition, newMousePosition) => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    if (context) {
      context.strokeStyle = '#e32d4b';
      context.lineJoin = 'round';
      context.lineWidth = 4;

      context.beginPath();
      context.moveTo(originalMousePosition[0], originalMousePosition[1]);
      context.lineTo(newMousePosition[0], newMousePosition[1]);
      context.closePath();

      context.stroke();
    }
  };

  return <canvas className="canvas" ref={canvasRef} height={height} width={width} />;
};

Canvas.propTypes = {
  dimensions: PropTypes.shape(dimensionsShape).isRequired,
  afterDraw: PropTypes.func.isRequired,
};

export default Canvas;
