import React from 'react';
import PropTypes from 'prop-types';

const SelectAllIcon = (props) => {
  const { height, fill } = props;
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 147.9 147.9"
      enableBackground="new 0 0 147.9 147.9"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={fill}
        d="M92.8,129.9c-6.4,2.6-13.4,4-20.8,4c-15,0-28.5-5.9-38.6-15.5l4.6-2.7c3.2-1.9,3.2-5-0.1-6.8L15.5,96.2
          c-3.3-1.8-6-0.3-6.1,3.5l-0.5,26.4c-0.1,3.8,2.5,5.3,5.7,3.4l6.4-3.8c12.8,13.6,30.9,22.1,51,22.1c7.3,0,14.3-1.2,21-3.3
          C107,140.9,100,126.9,92.8,129.9z M16.4,70.3c3.1-22.7,19.7-41,41.5-46.7V9.2c-27.4,5.6-49,27.2-54.6,54.6
          C-1.6,81.3,15.9,77.8,16.4,70.3z M64.1,35.7L87,22c3.3-2,3.3-5.2,0-7.2L64,0.9c-3.3-2-6-0.5-6,3.4l0.1,28
          C58.1,36.2,60.8,37.7,64.1,35.7z M143.1,103l-11.9-7.1l7.3,4.2c2.3-7,3.7-14.5,3.7-22.2c0-21.2-9.5-40.3-24.4-53.1
          c-7.1-6.4-17.6,0.6-10.9,9.2c13,10.3,21.3,26.1,21.3,44c0,5.2-0.8,10.2-2.1,15l-6.2-3.6c-3.2-1.9-5.8-0.3-5.7,3.5l0.4,26.3
          c0.1,3.8,2.7,5.4,6,3.6l22.6-12.9C146.3,107.9,146.3,104.9,143.1,103z"
      />
    </svg>
  );
};

export default SelectAllIcon;

SelectAllIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

SelectAllIcon.defaultProps = {
  height: '0',
};
