import PropTypes from 'prop-types';

export const workspaceDetailsShape = {
  usedBytes: PropTypes.shape({ N: PropTypes.number.isRequired }),
  sentBytes: PropTypes.shape({
    lastMonth: PropTypes.shape({
      N: PropTypes.number.isRequired,
    }).isRequired,
    thisMonth: PropTypes.shape({
      N: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
