import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { addNewUser } from '../../../../apiServices/userApi';
import { getValueFromUserAttributes, validateEmailFormat } from '../../../../util/UtilFunctions';
import { groupShape } from '../../../../app/AppPropTypes';
import ExperimentalButton from '../../../buttons/experimentalButton/experimentalButton';
import { Checkbox } from '../../../inputs/checkbox/Checkbox';
import './WorkspaceParticipiantsModalAddNewPeopleSection.scss';
import NotificationContext from '../../../../context/notification';
import RoleSelector from '../roleSelector';
import * as stylesVariables from '../../../../styles/variables';

class WorkspaceParticipiantsModalAddNewPeopleSection extends React.Component {
  state = {
    showInfo: undefined,
    emailFieldValue: '',
    activeRadio: this.props.chosenGroup.parent.S === 'root' ? 'admin' : undefined,
    personAddingProcess: { status: 'initial' },
  };

  updateShowInfo = (showInfoProps) => {
    if (!this.state.showInfo) {
      this.setState({ showInfo: showInfoProps });
    } else {
      this.setState({ showInfo: undefined });
    }
  };

  handleEmailFieldValueChange = (event) => {
    this.setState({ emailFieldValue: event.target.value.toLowerCase() });
  };

  handleRadioChange = (type) => {
    this.setState({ activeRadio: type });
  };

  capitalize = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return undefined;
  };

  getEmailSenderInfo = (userInfo) => {
    const senderFirstNameUnCapitalized = getValueFromUserAttributes(
      userInfo.cognitoAttributes.UserAttributes,
      'given_name',
    );
    const firstName = this.capitalize(senderFirstNameUnCapitalized);
    const senderLastNameUnCapitalized = getValueFromUserAttributes(
      userInfo.cognitoAttributes.UserAttributes,
      'family_name',
    );
    const lastName = this.capitalize(senderLastNameUnCapitalized);
    const email = getValueFromUserAttributes(userInfo.cognitoAttributes.UserAttributes, 'email');
    return {
      firstName,
      lastName,
      email,
    };
  };

  validateFormEmpty = () =>
    this.state.emailFieldValue !== '' && this.state.activeRadio !== undefined;

  validateFormTooLong = () => this.state.emailFieldValue.length < 50;

  handleFormSubmit = async (event) => {
    event.preventDefault();
    if (this.validateFormEmpty()) {
      if (this.validateFormTooLong()) {
        if (validateEmailFormat(this.state.emailFieldValue)) {
          this.setState({
            personAddingProcess: { status: 'loading' },
          });
          try {
            this.props.updateIsQuerying(true);
            const addNewUserResponse = await addNewUser(
              this.state.emailFieldValue.toLowerCase(),
              this.state.activeRadio,
              this.props.currentWorkspace,
            );
            this.props.updateIsQuerying(false);
            if (addNewUserResponse) {
              if (addNewUserResponse.response.search('ERROR') === -1) {
                this.props.getWorkspacePeopleList();
                this.context.add({
                  id: Date.now(),
                  type: 'success',
                  message: `Invited ${this.state.activeRadio} '${this.state.emailFieldValue}' participant`,
                });
                this.restoreDefaultState();
              } else {
                this.setState({ personAddingProcess: { status: 'error' } });
                if (addNewUserResponse.response.search('Too many admins') !== -1) {
                  this.context.add({
                    id: Date.now(),
                    type: 'info',
                    message: 'Could not invite user, too many admins already',
                  });
                } else {
                  this.context.add({
                    id: Date.now(),
                    type: 'info',
                    message: `Could not invite user '${this.state.emailFieldValue}'`,
                  });
                }
              }
            }
          } catch (addUserError) {
            this.restoreDefaultState();
            this.props.updateIsQuerying(false);
            this.context.add({
              id: Date.now(),
              type: 'info',
              message: 'Could not invite user',
            });
          }
        } else {
          this.context.add({
            id: Date.now(),
            type: 'info',
            message: 'Given text is not an email address',
          });
          this.setState({ emailFieldValue: '' });
        }
      } else {
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: "Form entries can't be over 50 characters",
        });
      }
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Form not complete',
      });
    }
  };

  handleActiveRadioChange = (activeRadio) => {
    this.setState({ activeRadio });
  };

  restoreDefaultState = () => {
    this.setState({
      emailFieldValue: '',
      activeRadio: '',
      personAddingProcess: { status: 'initial' },
    });
  };

  getRoleCheckboxItem = (role, activeRadio) => (
    <li key={role} className="radioButtonWrapper">
      <Checkbox
        checked={activeRadio === role}
        handleRadioChange={this.handleRadioChange}
        type={role}
        updateShowInfo={() => {}}
      />
      <div className="radioButtonRoleWrapper">
        <RoleSelector value={role} updateShowInfo={this.updateShowInfo} />
      </div>
    </li>
  );

  getRoleCheckboxList = (availableRoles, activeRadio) =>
    availableRoles.map((role) => this.getRoleCheckboxItem(role, activeRadio));

  getAvailableRoles = (chosenGroup) => {
    if (chosenGroup.parent.S === 'root') {
      return ['admin'];
    } else if (this.props.currentUserInfoInThisWorkspace.role.S === 'admin') {
      return ['pro', 'user', 'viewer'];
    } else if (this.props.currentUserInfoInThisWorkspace.role.S === 'pro') {
      return ['user', 'viewer'];
    } else if (this.props.currentUserInfoInThisWorkspace.role.S === 'user') {
      return ['viewer'];
    } else {
      return [];
    }
  };

  static contextType = NotificationContext;

  render() {
    const availableRoles = this.getAvailableRoles(this.props.chosenGroup);
    const roleCheckboxListItems = this.getRoleCheckboxList(availableRoles, this.state.activeRadio);
    return (
      <div className="workspace-participants-modal-add-new-people-section-content-wrapper">
        <div className="form">
          <div className="formInfoFieldsWrapper">
            <input
              type="text"
              placeholder="email"
              value={this.state.emailFieldValue}
              onChange={this.handleEmailFieldValueChange}
              className="inputField"
            />
            <ListWrapper>
              <ul className="list">{roleCheckboxListItems}</ul>
              {' '}
              {this.state.showInfo && (
                <Box
                  maxHeight={this.state.showInfo.maxHeight}
                  stylesVariables={stylesVariables}
                  left={this.state.showInfo.left}
                  top={this.state.showInfo.top}
                >
                  <BoxContentWrapper>
                    {this.state.showInfo.children}
                    <Button onClick={this.updateShowInfo} title="close info">
                      X
                    </Button>
                  </BoxContentWrapper>
                </Box>
              )}
            </ListWrapper>
          </div>
          <div className="buttonWrapper">
            <ExperimentalButton
              loading={this.state.personAddingProcess.status === 'loading'}
              onClick={this.handleFormSubmit}
            >
              Add
            </ExperimentalButton>
          </div>
        </div>
      </div>
    );
  }
}

const BoxContentWrapper = styled.div`
  position: relative;
`;

const ListWrapper = styled.div`
  position: relative;
`;

const Box = styled.div`
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 7.5rem);
  background-color: ${(props) => props.stylesVariables.colorGreySecondary};
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 15rem;
  padding-bottom: 1rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  max-height: ${(props) => props.maxHeight};
  z-index: 300;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.stylesVariables.colorGreySecondary};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.stylesVariables.colorGreyPrimary};
    border-radius: 5px;
    height: 50px;
  }
  h4 {
    margin: 0.5rem;
    text-transform: capitalize;
    font-size: 0.95rem;
  }
  ul {
    padding: 0 0 0 1.5rem;
    li {
      text-align: left;
      font-size: 0.85rem;
      line-height: 1rem;
      padding-bottom: 0.5rem;
    }
  }
  .secondaryText {
    font-size: 0.85rem;
    text-align: left;
    color: darken(${stylesVariables.colorDenimSecondary}, 15);
    margin: 0;
  }
`;

const Button = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
`;

export default WorkspaceParticipiantsModalAddNewPeopleSection;

WorkspaceParticipiantsModalAddNewPeopleSection.propTypes = {
  chosenGroup: PropTypes.shape(groupShape).isRequired,
  currentWorkspace: PropTypes.string.isRequired,
  getWorkspacePeopleList: PropTypes.func.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfoInThisWorkspace: PropTypes.object.isRequired,
};
