import { API } from 'aws-amplify';
import store from '../configureStore';
import { config } from '../config/Config';

export const sendEmails = (emailList, senderName, link) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  let sendTo;
  if (emailList.length > 0) {
    sendTo = emailList.join(',');
  } else {
    sendTo = emailList;
  }
  const sendEmailInit = {
    headers: header,
    body: `to=${sendTo}&name=${senderName}&link=${link}`,
  };
  return API.post(config.apiGateway.NAME, `/email/send?gid=${currentUserGroup}`, sendEmailInit);
};

export const sendEmailsWithMessage = (emailList, senderName, link, message) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  let sendTo;
  if (emailList.length > 0) {
    sendTo = emailList.join(',');
  } else {
    sendTo = emailList;
  }
  const sendEmailInit = {
    headers: header,
    body: `to=${sendTo}&name=${senderName}&link=${link}&message=${message}`,
  };
  return API.post(config.apiGateway.NAME, `/email/send?gid=${currentUserGroup}`, sendEmailInit);
};
