import React from 'react';
import { SubnavigationItem } from '../Subnavigation';
import { getRelativeTime } from '../../../util/UtilFunctions';
import { processMessagesListData } from './MessagesSubnavItemsUtilityFunctions';

class MessagesSubnavItems extends React.Component {
  navigate = (url) => {
    this.props.history.push(url);
    this.props.updateSubnav();
  };

  getListItems = (messagesList) => {
    const processedMessagesListData = processMessagesListData(messagesList);
    return processedMessagesListData.map(
      ({
        projectId,
        title,
        latestMessage,
        unreadMessagesCount,
        msgCount,
        unreadTaskMsgsCount,
        taskMsgCount,
      }) => {
        const relativeTime = getRelativeTime(new Date(), new Date(latestMessage));
        return (
          <SubnavigationItem
            key={projectId}
            onClick={() => this.navigate(`/in/project/${projectId}`)}
            className="messageItem"
          >
            <>
              <div>
                <h4>{title}</h4>
                <span>{relativeTime}</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={`messagesCount ${unreadMessagesCount ? 'unread' : ''}`}>
                  <h5 style={{ margin: 0 }}>
                    {`M (${unreadMessagesCount > 0 ? unreadMessagesCount : msgCount})`}
                  </h5>
                </div>
                <div className={`messagesCount ${unreadTaskMsgsCount > 0 ? 'unread' : ''}`}>
                  <h5 style={{ margin: 0 }}>
                    {`T (${unreadTaskMsgsCount > 0 ? unreadTaskMsgsCount : taskMsgCount})`}
                  </h5>
                </div>
              </div>
            </>
          </SubnavigationItem>
        );
      },
    );
  };

  render() {
    if (this.props.fetcherFailed) {
      return (
        <SubnavigationItem className="nonClickable">
          <h4>Could not fetch messages</h4>
        </SubnavigationItem>
      );
    } else if (this.props.messagesList.length === 0) {
      return (
        <SubnavigationItem className="nonClickable">
          <h4>No messages</h4>
        </SubnavigationItem>
      );
    } else {
      return this.getListItems(this.props.messagesList);
    }
  }
}

export default MessagesSubnavItems;
