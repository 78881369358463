import React from 'react';
import PropTypes from 'prop-types';
import { outsideResourceIdsProcessShape } from '../ShareFileModalPropTypes';
import Loader from '../../../loader/loader';
import './ShareFileModalInputField.scss';
import NotificationContext from '../../../../context/notification';
import {
  getButtonDisabled,
  getIsLoading,
  getLinkStr,
} from './ShareFileModalInputFieldUtilityFunctions';

class ShareFileModalInputField extends React.Component {
  copyToClipboard = (event) => {
    this.textArea.select();
    document.execCommand('copy');
    event.target.focus();
    this.context.add({
      id: Date.now(),
      type: 'success',
      message: 'Link copied',
    });
  };

  emailAddressFieldKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.props.addEmailInputToConfirmedEmails();
    }
  };

  static contextType = NotificationContext;

  render() {
    const isLoading = getIsLoading(this.props.outsideResourceIdsProcess);
    return (
      <div className="ShareFileModalInputField">
        {!isLoading && (
          <div className="form">
            <input
              onKeyDown={this.props.useCase === 'link' ? () => {} : this.emailAddressFieldKeyDown}
              placeholder={this.props.useCase === 'link' ? undefined : 'Email'}
              type={this.props.useCase === 'link' ? 'text' : 'email'}
              name={this.props.useCase}
              ref={(textarea) => (this.textArea = textarea)}
              value={
                this.props.useCase === 'link'
                  ? getLinkStr(this.props.outsideResourceIdsProcess)
                  : this.props.fieldValue
              }
              rows={1}
              onChange={(event) =>
                this.props.useCase === 'link'
                  ? () => {}
                  : this.props.handleEmailAddressFieldValueChange(event)
              }
            />
            <button
              type="button"
              onClick={
                this.props.useCase === 'link'
                  ? (event) => this.copyToClipboard(event)
                  : this.props.addEmailInputToConfirmedEmails
              }
              disabled={getButtonDisabled()}
            >
              {this.props.useCase === 'link' ? 'Copy link' : 'Add'}
            </button>
          </div>
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default ShareFileModalInputField;

ShareFileModalInputField.propTypes = {
  // shareFileModal.jsx props
  outsideResourceIdsProcess: PropTypes.shape(outsideResourceIdsProcessShape),
  useCase: PropTypes.oneOf(['email', 'link']).isRequired,
  handleEmailAddressFieldValueChange: PropTypes.func,
  fieldValue: PropTypes.string,
  addEmailInputToConfirmedEmails: PropTypes.func,
};

ShareFileModalInputField.defaultProps = {
  handleEmailAddressFieldValueChange: undefined,
  outsideResourceIdsProcess: undefined,
  fieldValue: '',
  addEmailInputToConfirmedEmails: undefined,
  handleEmailInputBlur: undefined,
};
