import React from 'react';
import PropTypes from 'prop-types';
import classes from './WorkspaceParticipiantsModal.module.scss';
import WorkspaceParticipiantsModalPeopleList from './workspaceParticipiantsModalPeopleList/WorkspaceParticipiantsModalPeopleList';
import WorkspaceParticipiantsModalAddNewPeopleSection from './workspaceParticipiantsModalAddNewPeopleSection/WorkspaceParticipiantsModalAddNewPeopleSection';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow.svg';
import WorkspaceParticipiantsModalAddExistingPeopleSection from './workspaceParticipiantsModalAddExistingPeopleSection/WorkspaceParticipiantsModalAddExistingPeopleSection';
import { getCurrentUserInfoInWorkspace, getAllUsers } from '../../../apiServices/userApi';
import { groupShape } from '../../../app/AppPropTypes';
import NotificationContext from '../../../context/notification';
import { hasExpired } from '../../../util/subscriptionDetails';
import { getCurrentUserRoleInThisWorkspace } from './WorkspaceParticipantsModalUtilityFunctions';

class WorkspaceParticipiantsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      peopleSectionOpen: true,
      addNewPeopleSectionOpen: false,
      addExistingPeopleSectionOpen: false,
      currentUserInfoInThisWorkspaceProcess: { status: 'loading' },
      getCurrentWorkspacePeopleListProcess: { status: 'initial' },
    };
  }

  componentDidMount = () => {
    this.getCurrentUserInfoInThisWorkspace();
    this.getPeopleList();
  };

  getPeopleList = async () => {
    this.setState({ getCurrentWorkspacePeopleListProcess: { status: 'loading' } });
    try {
      const chosenGroup = this.props.groupsList.find(
        (group) => group.id.S === this.props.workspace,
      );
      const getAllUsersResponse = await getAllUsers(chosenGroup);
      this.setState({
        getCurrentWorkspacePeopleListProcess: {
          status: 'success',
          data: getAllUsersResponse.response.Items,
        },
      });
    } catch (getAllUsersError) {
      this.setState({
        getCurrentWorkspacePeopleListProcess: { status: 'error', data: getAllUsersError },
      });
    }
  };

  handlePeopleHeaderClick = () => {
    const { peopleSectionOpen } = this.state;
    if (!peopleSectionOpen) {
      this.getPeopleList();
    }
    this.setState({
      peopleSectionOpen: !peopleSectionOpen,
      addNewPeopleSectionOpen: false,
      addExistingPeopleSectionOpen: false,
    });
  };

  handleAddPeopleHeaderClick = () => {
    const chosenGroup = this.props.groupsList.find((group) => group.id.S === this.props.workspace);
    const workspaceHasExpired = hasExpired(chosenGroup);
    if (workspaceHasExpired) {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'This workspace has expired, please consider upgrading the package.',
      });
    } else {
      const { addNewPeopleSectionOpen } = this.state;
      this.setState({
        addNewPeopleSectionOpen: !addNewPeopleSectionOpen,
        peopleSectionOpen: false,
        addExistingPeopleSectionOpen: false,
      });
    }
  };

  handleAddExistingHeaderClick = () => {
    const chosenGroup = this.props.groupsList.find((group) => group.id.S === this.props.workspace);
    const workspaceHasExpired = hasExpired(chosenGroup);
    if (workspaceHasExpired) {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'This workspace has expired, please consider upgrading the package.',
      });
    } else {
      const { addExistingPeopleSectionOpen } = this.state;
      this.setState({
        addExistingPeopleSectionOpen: !addExistingPeopleSectionOpen,
        peopleSectionOpen: false,
        addNewPeopleSectionOpen: false,
      });
    }
  };

  getCurrentUserInfoInThisWorkspace = async () => {
    try {
      this.props.updateIsQuerying(true);
      const getCurrentUserInfoInWorkspaceResponse = await getCurrentUserInfoInWorkspace(
        this.props.workspace,
      );
      this.props.updateIsQuerying(false);
      this.setState({
        currentUserInfoInThisWorkspaceProcess: {
          status: 'success',
          data: getCurrentUserInfoInWorkspaceResponse.response.Item,
        },
      });
    } catch (getCurrentUserInfoInWorkspaceError) {
      this.setState({
        currentUserInfoInThisWorkspaceProcess: {
          status: 'error',
          data: getCurrentUserInfoInWorkspaceError,
        },
      });
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: getCurrentUserInfoInWorkspaceError.message,
      });
      this.props.updateIsQuerying(false);
    }
  };

  getAddHeadersClassNames = (workspaceHasExpired, sectionOpen) => {
    if (workspaceHasExpired) {
      return classes.headerDisabled;
    } else if (sectionOpen) {
      return classes.headerActive;
    } else {
      return classes.header;
    }
  };

  static contextType = NotificationContext;

  render() {
    const chosenGroup = this.props.groupsList.find((group) => group.id.S === this.props.workspace);
    const workspaceHasExpired = hasExpired(chosenGroup);
    const peopleSectionHeaderClass = this.state.peopleSectionOpen
      ? classes.headerActive
      : classes.header;

    const addNewPeopleSectionHeaderClass = this.getAddHeadersClassNames(
      workspaceHasExpired,
      this.state.addNewPeopleSectionOpen,
    );
    const addExistingPeopleSectionHeaderClass = this.getAddHeadersClassNames(
      workspaceHasExpired,
      this.state.addExistingPeopleSectionOpen,
    );
    const currentUserGroupInfo = this.props.groupsList.find(
      (group) => group.id.S === this.props.currentUserGroup,
    );

    const currentUserRoleInThisWorkspace = getCurrentUserRoleInThisWorkspace(
      this.state.currentUserInfoInThisWorkspaceProcess,
    );

    return (
      <div className={classes.modalWrapper}>
        <h4 className={classes.mainHeader}>Workspace participants</h4>
        <div className={classes.contentWrapper}>
          <div className={classes.peopleSection}>
            <div className={peopleSectionHeaderClass} onClick={this.handlePeopleHeaderClick}>
              <h5>PARTICIPANTS</h5>
              <ArrowIcon />
            </div>
            {this.state.peopleSectionOpen &&
              this.state.currentUserInfoInThisWorkspaceProcess.status === 'success' && (
                <div className={classes.peopleListWrapper}>
                  <WorkspaceParticipiantsModalPeopleList
                    chosenGroup={chosenGroup}
                    updateIsQuerying={this.props.updateIsQuerying}
                    currentUserInfoInThisWorkspace={
                      this.state.currentUserInfoInThisWorkspaceProcess.data
                    }
                    getCurrentWorkspacePeopleListProcess={
                      this.state.getCurrentWorkspacePeopleListProcess
                    }
                    getPeopleList={this.getPeopleList}
                  />
                </div>
            )}
          </div>
          {(currentUserRoleInThisWorkspace === 'admin' ||
            currentUserRoleInThisWorkspace === 'pro') && (
            <>
              <div className={classes.addPeopleSection}>
                <div
                  className={addExistingPeopleSectionHeaderClass}
                  onClick={this.handleAddExistingHeaderClick}
                >
                  <h5>ADD FROM OTHER WORKSPACES</h5>
                  <ArrowIcon />
                </div>
                {this.state.addExistingPeopleSectionOpen &&
                  this.state.currentUserInfoInThisWorkspaceProcess.status === 'success' && (
                    <div className={classes.peopleListWrapper}>
                      <WorkspaceParticipiantsModalAddExistingPeopleSection
                        groupsList={this.props.groupsList}
                        currentWorkspace={this.props.workspace}
                        updateIsQuerying={this.props.updateIsQuerying}
                        currentUserInfoInThisWorkspace={
                          this.state.currentUserInfoInThisWorkspaceProcess.data
                        }
                        chosenGroup={chosenGroup}
                      />
                    </div>
                )}
              </div>
              <div className={classes.addPeopleSection}>
                <div
                  className={addNewPeopleSectionHeaderClass}
                  onClick={this.handleAddPeopleHeaderClick}
                >
                  <h5>ADD NEW PEOPLE</h5>
                  <ArrowIcon />
                </div>
                {this.state.addNewPeopleSectionOpen &&
                  this.state.currentUserInfoInThisWorkspaceProcess.status === 'success' && (
                    <WorkspaceParticipiantsModalAddNewPeopleSection
                      currentWorkspace={this.props.workspace}
                      chosenGroup={chosenGroup}
                      getWorkspacePeopleList={this.props.getWorkspacePeopleList}
                      currentUserGroupInfo={currentUserGroupInfo}
                      updateIsQuerying={this.props.updateIsQuerying}
                      currentUserInfoInThisWorkspace={
                        this.state.currentUserInfoInThisWorkspaceProcess.data
                      }
                    />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default WorkspaceParticipiantsModal;

WorkspaceParticipiantsModal.propTypes = {
  workspace: PropTypes.object.isRequired,
  currentUserGroup: PropTypes.string.isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
  getWorkspacePeopleList: PropTypes.func.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
};
