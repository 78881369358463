import React from 'react';
import PropTypes from 'prop-types';
import MessageImageSection from './messageImageSection/MessageImageSection';
import MessageMainSection from './messageMainSection/MessageMainSection';

// prop types
import { taskShape } from '../../../ProjectMainViewPropTypes';
import { folderListItemShape } from '../../../../../folderView/FolderViewPropTypes';
import { imageShape } from '../../../projectChat/projectChatMessages/ProjectChatMessagesPropTypes';
import './message.scss';

class Message extends React.Component {
  handleImageClick = () => {
    if (this.props.previewItemOnShow && this.props.taskResource) {
      if (this.props.previewItemOnShow.id !== this.props.taskResource.id) {
        this.props.updatePreviewItemOnShow(this.props.taskResource);
      }
      this.props.updateSelectedTask(this.props.task);
      window.scrollTo(0, 0);
    }
  };

  render() {
    return (
      <div className={`message ${this.props.isAReply ? 'reply' : ''}`}>
        <MessageImageSection
          handleImageClick={this.handleImageClick}
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          image={this.props.image}
          isAReply={this.props.isAReply}
          taskResource={this.props.taskResource}
          task={this.props.task}
        />
        <MessageMainSection
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          created={this.props.created}
          content={this.props.content}
          image={this.props.image}
          messageId={this.props.messageId}
          projectId={this.props.projectId}
          isRemovable={this.props.isRemovable}
          task={this.props.task}
          refreshList={this.props.refreshList}
          updateModalOpen={this.props.updateModalOpen}
        />
      </div>
    );
  }
}

export default Message;

Message.propTypes = {
  // ProjectChatMessages.jsx or ProjectChatTasks.jsx props
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  created: PropTypes.string.isRequired,
  content: PropTypes.string,
  image: PropTypes.shape(imageShape),
  isAReply: PropTypes.bool.isRequired,
  updatePreviewItemOnShow: PropTypes.func,
  updateSelectedTask: PropTypes.func,
  previewItemOnShow: PropTypes.shape(folderListItemShape),
  taskResource: PropTypes.shape(folderListItemShape),
  task: PropTypes.shape(taskShape),
  messageId: PropTypes.string,
  projectId: PropTypes.string,
  isRemovable: PropTypes.bool.isRequired,
  refreshList: PropTypes.func,
  updateModalOpen: PropTypes.func.isRequired,
};

Message.defaultProps = {
  firstName: undefined,
  lastName: undefined,
  content: undefined,
  image: undefined,
  updatePreviewItemOnShow: undefined,
  updateSelectedTask: undefined,
  previewItemOnShow: undefined,
  taskResource: undefined,
  task: undefined,
  messageId: undefined,
  projectId: undefined,
  refreshList: undefined,
};
