import React from 'react';

import videojs from 'video.js';
import '../../../../../video-js-global.css';
import '../../videojs.markers.css';
import 'videojs-markers';
import TaskMessage from '../projectMainViewComponents/taskMessage/TaskMessage';
import NewTask from '../projectMainViewComponents/newTask/NewTask';
import './videojsOverWrites.scss';
import './projectMainViewVideoPlayer.scss';
import NotificationContext from '../../../../../../context/notification';
// prop types

import './arrowsOverlay/arrowsOverlay.scss';
import './arrowsOverlay/arrowsOverlay';
import ClickArea from './clickArea';
import { lineTypes } from './arrowsOverlay/utils';

class ProjectMainViewVideoPlayer extends React.Component {
  constructor() {
    super();
    this.state = {
      newTaskData: undefined,
      videoHasStarted: false,
      markerClicked: undefined,
      newLineType: lineTypes.freeLine,
    };
  }

  componentDidMount() {
    if (!this.props.messagesTabOpen) {
      this.initializePlayerWithTasks();
    } else {
      this.initializePlayerWithoutTasks();
    }

    this.setState({
      dimensions: {
        width: this.videoWrapperRef.offsetWidth,
        height: this.videoWrapperRef.offsetHeight,
        offsetLeft: this.videoWrapperRef.offsetLeft,
        offsetTop: this.videoWrapperRef.offsetTop,
      },
    });
  }

  // adapt the audio player for different updates caused by the user
  componentDidUpdate(prevProps) {
    if (this.props.messagesTabOpen !== prevProps.messagesTabOpen) {
      this.handleChatTabsChange();
    } else if (this.props.selectedTask && this.props.selectedTask !== prevProps.selectedTask) {
      this.handleTaskSelectionChange(prevProps);
    } else if (this.props.resource.id !== prevProps.resource.id) {
      this.handlePreviewItemOnShowChange(prevProps);
    } else if (
      this.props.tasks &&
      prevProps.tasks &&
      this.props.tasks.length !== prevProps.tasks.length &&
      !this.props.messagesTabOpen
    ) {
      this.handleNewTaskAppearance();
    } else if (this.props.selectedTask && this.props.tasks !== prevProps.tasks) {
      console.log(this.props.tasks, prevProps.tasks, 'tasks changed');
      this.removePlayerEventListeners();
      videojs(`project-main-view-player-${this.props.resource.id}`).dispose();
      window.removeEventListener('resize', this.udpateDimensions);
      this.initializePlayerWithSelectedTask();
    }
  }

  componentWillUnmount() {
    this.removePlayerEventListeners();
    videojs(`project-main-view-player-${this.props.resource.id}`).dispose();
    window.removeEventListener('resize', this.udpateDimensions);
  }

  udpateDimensions = () => {
    this.setState({
      dimensions: {
        width: this.videoWrapperRef.offsetWidth,
        height: this.videoWrapperRef.offsetHeight,
        offsetLeft: this.videoWrapperRef.offsetLeft,
        offsetTop: this.videoWrapperRef.offsetTop,
      },
    });
  };

  handleNewTaskAppearance = () => {
    const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);

    if (playerObj.markers.add) {
      // remove possible previous markers and overlays
      playerObj.markers.removeAll();
      playerObj.overlay({ overlays: [] });
      // update dimensions just to be sure
      this.setState(
        {
          dimensions: {
            width: this.videoWrapperRef.offsetWidth,
            height: this.videoWrapperRef.offsetHeight,
            offsetLeft: this.videoWrapperRef.offsetLeft,
            offsetTop: this.videoWrapperRef.offsetTop,
          },
        },
        () => this.addMarkersToPlayer(playerObj),
      );
    }
  };

  addMarkersToPlayer = (playerObj) => {
    const arrows = [];
    const markersList = this.props.tasks.map((task) => {
      if (Object.values(lineTypes).includes(task.taskType)) {
        arrows.push({
          start: task.timestamp / 1000,
          end: Number.parseFloat(task.timestamp / 1000) + Number.parseFloat(task.duration),
          coords: task.coords,
          type: task.taskType,
        });
      }

      const marker = {
        task,
        time: task.timestamp / 1000,
        text: task.messages[0]?.content || task.message,
        class: `marker ${task.taskType || ''}-marker`,
      };
      return marker;
    });

    playerObj.overlay({ overlays: arrows, dimensions: this.state.dimensions });

    playerObj.markers.add(markersList);
  };

  handlePreviewItemOnShowChange = (prevProps) => {
    this.removePlayerEventListeners();
    const previousPlayerObj = videojs(`project-main-view-player-${prevProps.resource.id}`);
    previousPlayerObj.dispose();
    if (!this.props.messagesTabOpen) {
      this.initializePlayerWithTasks();
    } else {
      this.initializePlayerWithoutTasks();
    }
  };

  handleChatTabsChange = () => {
    const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);

    // remove markers
    playerObj.markers.removeAll();

    if (this.props.messagesTabOpen) {
      // remove overlays
      playerObj.overlay({ overlays: [] });
      playerObj.pause();
    } else {
      playerObj.pause();
      // update dimensions just to be sure
      this.setState(
        {
          dimensions: {
            width: this.videoWrapperRef.offsetWidth,
            height: this.videoWrapperRef.offsetHeight,
            offsetLeft: this.videoWrapperRef.offsetLeft,
            offsetTop: this.videoWrapperRef.offsetTop,
          },
        },
        () => this.addMarkersToPlayer(playerObj),
      );
    }
  };

  handleTaskSelectionChange = (prevProps) => {
    if (this.props.selectedTask.resource !== prevProps.resource.id) {
      this.removePlayerEventListeners();
      const previousPlayerObj = videojs(`project-main-view-player-${prevProps.resource.id}`);
      previousPlayerObj.dispose();
      this.initializePlayerWithSelectedTask();
    } else {
      const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);
      if (this.state.videoHasStarted) {
        if (!this.state.markerClicked) {
          playerObj.currentTime(this.props.selectedTask.timestamp / 1000);
        } else {
          // if user previously clicked on a marker and now clicked on a
          // different task in the "chat"
          if (this.state.markerClicked.task.id !== this.props.selectedTask.id) {
            playerObj.currentTime(this.props.selectedTask.timestamp / 1000);
          }
        }
      } else {
        const time = this.props.selectedTask.timestamp / 1000;
        this.updateVideoPositionToTime(playerObj, time);
      }
    }
  };

  initializePlayerWithSelectedTask = () => {
    const { selectedTask } = this.props;
    const videoPlayerWrapperElement = document.getElementById('video-wrapper');
    const videoDomElement = this.createNewVideoElement(this.props.resource);
    videoPlayerWrapperElement.appendChild(videoDomElement);
    const playerObj = this.createVideoJs(videoDomElement);
    console.log(this.props);
    playerObj.ready(() => {
      this.initVideoPlugins(playerObj);
      setTimeout(() => {
        const time = selectedTask.timestamp / 1000;
        this.updateVideoPositionToTime(playerObj, time);
      }, 300);
    });
  };

  initializePlayerWithoutSelectedTask = () => {
    const videoPlayerWrapperElement = document.getElementById('video-wrapper');
    const videoDomElement = this.createNewVideoElement(this.props.resource);
    videoPlayerWrapperElement.appendChild(videoDomElement);
    const playerObj = this.createVideoJs(videoDomElement);
    playerObj.ready(() => {
      this.initVideoPlugins(playerObj);
      playerObj.currentTime(0);
    });
  };

  initializePlayerWithTasks = () => {
    if (this.props.selectedTask) {
      if (this.props.selectedTask.resource === this.props.resource.id) {
        this.initializePlayerWithSelectedTask();
      } else {
        this.initializePlayerWithoutSelectedTask();
      }
    } else {
      this.initializePlayerWithoutSelectedTask();
    }
  };

  initializePlayerWithoutTasks = () => {
    const videoPlayerWrapperElement = document.getElementById('video-wrapper');
    const videoDomElement = this.createNewVideoElement(this.props.resource);
    videoPlayerWrapperElement.appendChild(videoDomElement);
    const playerObj = this.createVideoJs(videoDomElement);
    playerObj.ready(() => {
      this.initVideoPlugins(playerObj);
    });
  };

  initVideoPlugins = (playerObj) => {
    const markersObj = {
      markers: [],
      markerStyle: {},
      onMarkerClick: (marker) => {
        playerObj.pause();
        this.setState({ markerClicked: marker }, () => {
          this.props.updateSelectedTask(marker.task);
        });
      },
    };
    try {
      // playerObj.overlay({ overlays: arrows, dimensions: this.state.dimensions });
      playerObj.markers(markersObj);

      !this.props.messagesTabOpen && setTimeout(() => this.addMarkersToPlayer(playerObj), 500);
    } catch (markersError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not perform action',
      });
    }
  };

  updateVideoPositionToTime = (playerObj, time) => {
    playerObj.play();
    playerObj.currentTime(time);
    playerObj.pause();
  };

  modifyPlayerCurrentTime = (time) => {
    const player = videojs(`project-main-view-player-${this.props.resource.id}`);
    if (player) {
      const newTime = player.currentTime() + time;
      player.currentTime(newTime);
    }
  };

  setPlayerCurrentTime = (time) => {
    const player = videojs(`project-main-view-player-${this.props.resource.id}`);
    if (player) {
      player.currentTime(time);
    }
  };

  createVideoJs = (elem) =>
    videojs(elem, {
      playbackRates: [0.25, 0.5, 1, 1.5, 2],
      controls: true,
      muted: false,
    });

  createNewVideoElement = (video) => {
    const videoDomElement = document.createElement('video');
    videoDomElement.setAttribute('id', `project-main-view-player-${video.id}`);
    videoDomElement.setAttribute('class', 'video-js');
    videoDomElement.setAttribute('controls', 'true');
    videoDomElement.setAttribute('preload', 'auto');
    videoDomElement.setAttribute('data-setup', '{}');
    videoDomElement.setAttribute('crossorigin', 'anonymous');
    const videoDomElementDisplayValue = `${video.id !== 1 ? 'inherit' : 'none'}`;
    videoDomElement.setAttribute('display', videoDomElementDisplayValue);
    videoDomElement.addEventListener('playing', this.videoDomElementPlayingListeningFunction);

    const videoDomSourceElement = document.createElement('source');
    videoDomSourceElement.setAttribute('src', video.signedUrl);
    videoDomSourceElement.setAttribute(
      'type',
      video.mimeType === 'video/quicktime' ? 'video/mp4' : video.mimeType,
    );

    videoDomElement.appendChild(videoDomSourceElement);

    return videoDomElement;
  };

  videoDomElementPlayingListeningFunction = () => {
    this.setState({
      videoHasStarted: true,
      markerClicked: undefined,
    });
    if (this.props.selectedTask) {
      const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);
      const currentTimeInDeciSeconds = playerObj.currentTime() * 10;
      const selectedTaskTimeInDeciSeconds = this.props.selectedTask.timestamp / 100;
      if (Math.round(currentTimeInDeciSeconds) !== Math.round(selectedTaskTimeInDeciSeconds)) {
        this.props.updateSelectedTask();
      }
    }
  };

  videoDomElementSeekedListeningFunction = () => {
    if (!this.props.messagesTabOpen) {
      if (this.props.selectedTask) {
        // during seek operation, only delete selected task and markerClicked if
        // progressbar is not near a marker
        const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);
        const currentTimeInDeciSeconds = playerObj.currentTime() * 10;
        const selectedTaskTimeInDeciSeconds = this.props.selectedTask.timestamp / 100;
        if (Math.round(currentTimeInDeciSeconds) !== Math.round(selectedTaskTimeInDeciSeconds)) {
          this.props.updateSelectedTask();
          this.setState({ markerClicked: undefined });
        }
      }
    }
  };

  removePlayerEventListeners = () => {
    const videojsElement = document.getElementById(
      `project-main-view-player-${this.props.resource.id}`,
    );
    if (videojsElement) {
      const videoDomElement = videojsElement.getElementsByTagName('video')[0];
      if (videoDomElement) {
        videoDomElement.removeEventListener(
          'playing',
          this.videoDomElementPlayingListeningFunction,
        );
        videoDomElement.addEventListener('seeked', this.videoDomElementSeekedListeningFunction);
      }
    }
  };

  updateNewTaskData = (newTaskData) => {
    if (!newTaskData) {
      this.setState({ newTaskData: undefined, newLineType: lineTypes.freeLine });
    } else {
      this.setState({ newTaskData });
    }
  };

  // see readme
  taskMessageRestriction = (selectedTask, isInFullscreen) => {
    const changeDate = new Date('2021-01-13T09:59:55.671Z');
    if (selectedTask) {
      const creationDate = new Date(this.props.selectedTask.created);
      return isInFullscreen && creationDate < changeDate;
    } else {
      return false;
    }
  };

  static contextType = NotificationContext;

  render() {
    let isInFullScreen = false;
    let playerRoot;
    let playerObj;
    try {
      playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);
      isInFullScreen = playerObj.isFullscreen();
      playerRoot = playerObj.el();
    } catch (playerDidNotExistError) {
      console.log(playerDidNotExistError, 'playerDidNotExistError');
    }

    return (
      <div
        className="projectMainViewVideoPlayer"
        id="video-wrapper"
        ref={(el) => (this.videoWrapperRef = el)}
      >
        {playerObj &&
          this.state.dimensions?.offsetLeft &&
          !this.props.messagesTabOpen &&
          this.props.userRole !== 'guest' && (
            <ClickArea
              id={this.props.resource.id}
              updateNewTaskData={this.updateNewTaskData}
              dimensions={this.state.dimensions}
              isNewTaskActive={!!this.state.newTaskData}
              lineType={this.state.newLineType}
            />
          )}
        {this.props.selectedTask &&
          !this.taskMessageRestriction(this.props.selectedTask, isInFullScreen) && (
            <TaskMessage
              task={this.props.selectedTask}
              updateSelectedTask={this.props.updateSelectedTask}
              videoFullscreen={isInFullScreen}
              destination={playerRoot}
              setPlayerCurrentTime={this.setPlayerCurrentTime}
            />
          )}
        {this.state.newTaskData && (
          <NewTask
            newTaskIndex={this.props.highestIndex}
            useCase="video"
            clickEvent={this.state.newTaskData}
            updateClickEvent={this.updateNewTaskData}
            match={this.props.match}
            videoFullscreen={isInFullScreen}
            destination={playerRoot}
            toggleLineType={(value) => this.setState({ newLineType: value })}
            modifyPlayerCurrentTime={this.modifyPlayerCurrentTime}
            resource={this.props.resource}
          />
        )}
      </div>
    );
  }
}

export default ProjectMainViewVideoPlayer;
