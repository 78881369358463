import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { removeUserFromWorkspace } from '../../../apiServices/userApi';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import NotificationContext from '../../../context/notification';
import { groupShape } from '../../../app/AppPropTypes';
import { currentUserInfoShape } from '../../../apiServices/userApiTypes';
import { Wrapper, ContentWrapper } from '../confirmModal/ConfirmModal';

const ExitWorkspaceModal = ({
  workspace,
  closeModal,
  currentUserInfo,
  updateIsQuerying,
  refreshList,
}) => {
  const [loading, setLoading] = useState(false);
  const { add } = React.useContext(NotificationContext);
  const exitFromGroup = async () => {
    setLoading(true);
    try {
      updateIsQuerying(true);
      await removeUserFromWorkspace(currentUserInfo.user.S, workspace.id.S);
      updateIsQuerying(false);
      add({
        id: Date.now(),
        type: 'success',
        message: `exited from group ${workspace.title.S}`,
      });
      refreshList();
    } catch (removeUserError) {
      updateIsQuerying(false);
      add({
        id: Date.now(),
        type: 'error',
        message: `Could not exit from the group '${workspace.title.S}'`,
      });
    }
    setLoading(false);
    closeModal();
  };
  return (
    <Wrapper>
      <h4>{`Leave from workspace ${workspace.title.S}?`}</h4>
      <ContentWrapper>
        <ExperimentalButton
          onClick={exitFromGroup}
          title="Exit from workspace"
          type="red"
          loading={loading}
        >
          Confirm
        </ExperimentalButton>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ExitWorkspaceModal;

ExitWorkspaceModal.propTypes = {
  workspace: PropTypes.shape(groupShape).isRequired,
  closeModal: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
};
