import React from 'react';
import PropTypes from 'prop-types';

const ShareIcon = (props) => {
  const { height, fill } = props;
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 473.932 473.932"
      enableBackground="new 0 0 473.932 473.932"
      xmlSpace="preserve"
      height={height}
    >
      <g>
        <path
          fill={fill}
          d="M385.513,301.214c-27.438,0-51.64,13.072-67.452,33.09l-146.66-75.002
            c1.92-7.161,3.3-14.56,3.3-22.347c0-8.477-1.639-16.458-3.926-24.224l146.013-74.656c15.725,20.924,40.553,34.6,68.746,34.6
            c47.758,0,86.391-38.633,86.391-86.348C471.926,38.655,433.292,0,385.535,0c-47.65,0-86.326,38.655-86.326,86.326
            c0,7.809,1.381,15.229,3.322,22.412L155.892,183.74c-15.833-20.039-40.079-33.154-67.56-33.154
            c-47.715,0-86.326,38.676-86.326,86.369s38.612,86.348,86.326,86.348c28.236,0,53.043-13.719,68.832-34.664l145.948,74.656
            c-2.287,7.744-3.947,15.79-3.947,24.289c0,47.693,38.676,86.348,86.326,86.348c47.758,0,86.391-38.655,86.391-86.348
            C471.904,339.848,433.271,301.214,385.513,301.214z"
        />
      </g>
    </svg>
  );
};

export default ShareIcon;

ShareIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

ShareIcon.defaultProps = {
  height: '0',
};
