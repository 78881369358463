import React from 'react';
import PropTypes from 'prop-types';
import './FreeTrialEndedView.scss';
import ExperimentalButton from '../../../components/buttons/experimentalButton/experimentalButton';
import { historyShape } from '../../../routerPropTypes';

export const FreeTrialEndedView = (props) => (
  <div className="freeTrialEndedView">
    <h4>Your free trial period has ended</h4>
    <p>Purchase Premium</p>
    <ExperimentalButton onClick={() => props.history.push('/in/account/billing')}>
      Billing
    </ExperimentalButton>
  </div>
);

export default FreeTrialEndedView;

FreeTrialEndedView.propTypes = {
  history: PropTypes.shape(historyShape).isRequired,
};
