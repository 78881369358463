import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const getClassName = (finishedUploadsListLength, fileUploadingProcessStatus, filesListLength) => {
  const isInitial = finishedUploadsListLength === 0 && fileUploadingProcessStatus !== 'loading';
  if (isInitial) {
    return 'initial';
  }
  const uploadHasBegun = fileUploadingProcessStatus === 'loading';
  if (uploadHasBegun) {
    return 'uploadingFromInitial';
  }
  const allUploadsFinished = filesListLength !== 0 && filesListLength === finishedUploadsListLength;
  if (allUploadsFinished) {
    return 'finished';
  }

  return undefined;
};

export const FinishedUploadsCounter = (props) => (
  <Wrapper>
    <div
      className={`finishedUploadsCounterTextsWrapper ${getClassName(
        props.finishedUploadsListLength,
        props.fileUploadingProcessStatus,
        props.filesListLength,
      )}`}
    >
      <h3>{`${props.finishedUploadsListLength} / ${props.filesListLength}`}</h3>
      {props.failedUploadsListLength > 0 && (
        <h3 style={{ color: 'red', margin: '0 0 0 0.5rem' }}>
          {`(${props.failedUploadsListLength})`}
        </h3>
      )}
    </div>
  </Wrapper>
);

const changeColorBlackToOrange = keyframes`
  from {
    color: black;
  }
  to {
    color: orange;
  }
`;

const changeColorOrangeToGreen = keyframes`
  from {
    color: orange;
  }
  to {
    color: green;
  }
`;

const changeColorGreenToOrange = keyframes`
  from {
    color: orange;
  }
  to {
    color: green;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;

  .textsWrapper,
  .finished,
  .initial,
  .uploadingFromInitial,
  .uploadingFromFinished {
    display: flex;
    h3 {
      margin: 0;
      font-size: 1em;
    }
  }

  .finished {
    animation-name: ${changeColorOrangeToGreen};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .uploadingFromInitial {
    animation-name: ${changeColorBlackToOrange};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .uploadingFromFinished {
    animation-name: ${changeColorGreenToOrange};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
`;

FinishedUploadsCounter.propTypes = {
  filesListLength: PropTypes.number.isRequired,
  fileUploadingProcessStatus: PropTypes.string.isRequired,
  finishedUploadsListLength: PropTypes.number.isRequired,
  failedUploadsListLength: PropTypes.number.isRequired,
};
