export const getRelativeCoordFromNaturalCoord = (x, y) => {
  const wrapperElement = document.getElementById('imageWrapper');
  const imageElement = document.getElementById('project-mainview-fullscreen-img');

  if (imageElement) {
    const widthRatio = imageElement.naturalWidth / wrapperElement.offsetWidth;
    const heightRatio = imageElement.naturalHeight / wrapperElement.offsetHeight;

    const clickOnResizedImageXCoordPosition = Math.round(x / widthRatio);
    const clickOnResizedImageYCoordPosition = Math.round(y / heightRatio);

    const coord = {
      x: clickOnResizedImageXCoordPosition,
      y: clickOnResizedImageYCoordPosition,
    };
    return coord;
  } else {
    return undefined;
  }
};

/**
 * Takes x and y coordinates as pixels, gets images natural size from DOM -.-
 * and calculates percentages
 * @param {Integer} x - x-coordinate in pixels
 * @param {Integer} y - y-coordinate in pixels
 * @returns {Object} - returns object with x and y keys containing numbers
 */
export const getXYPercent = (x, y, wrapperId) => {
  const imageElement = document.getElementById(wrapperId);
  if (imageElement) {
    const percentX = (x / imageElement.naturalWidth) * 100;
    const percentY = (y / imageElement.naturalHeight) * 100;

    const coord = {
      x: percentX,
      y: percentY,
    };
    return coord;
  } else {
    return undefined;
  }
};

export const getVideoXYPercent = (x, y, wrapperId) => {
  const element = document.getElementById(wrapperId);
  if (element) {
    const percentX = (x / element.offsetWidth) * 100;
    const percentY = (y / element.offsetHeight) * 100;

    const coord = {
      x: percentX,
      y: percentY,
    };
    return coord;
  } else {
    return undefined;
  }
};
