import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import PropTypes from 'prop-types';
import './ImageCropper.scss';

class ImageCropper extends React.Component {
  constructor(props) {
    super(props);
    const lowestDimension = this.getLowestDimension(
      this.props.restrictedImageDimensions.width,
      this.props.restrictedImageDimensions.height,
    );
    let crop;
    if (lowestDimension === 'width') {
      if (this.props.restrictedImageDimensions.width < 150) {
        crop = {
          width: this.props.restrictedImageDimensions.width,
          height: this.props.restrictedImageDimensions.width,
          unit: 'px',
          x: 0,
          y: 0,
          aspect: 1,
        };
      } else {
        crop = {
          width: 100,
          height: 100,
          unit: 'px',
          x: 0,
          y: 0,
          aspect: 1,
        };
      }
    } else {
      if (this.props.restrictedImageDimensions.height < 150) {
        crop = {
          width: this.props.restrictedImageDimensions.height,
          height: this.props.restrictedImageDimensions.height,
          unit: 'px',
          x: 0,
          y: 0,
          aspect: 1,
        };
      } else {
        crop = {
          width: 100,
          height: 100,
          unit: 'px',
          x: 0,
          y: 0,
          aspect: 1,
        };
      }
    }
    this.state = {
      crop,
    };
  }

  getLowestDimension = (width, height) => {
    if (width < height) {
      return 'width';
    } else if (width === height) {
      return 'even';
    } else {
      return 'height';
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    const dataURL = canvas.toDataURL();
    const blobData = this.dataURItoBlob(dataURL);
    return blobData;
  }

  dataURItoBlob = (dataURI) => {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i += 1) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  };

  makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.props.updateCroppedImage(croppedImageUrl);
    }
  }

  render() {
    const { crop } = this.state;

    return (
      <div className="imageCropper">
        {this.props.imageSrc && (
          <ReactCrop
            circularCrop={this.props.circularCrop}
            src={this.props.imageSrc}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            keepSelection
          />
        )}
      </div>
    );
  }
}

export default ImageCropper;

ImageCropper.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  updateCroppedImage: PropTypes.func.isRequired,
  restrictedImageDimensions: PropTypes.object.isRequired,
  circularCrop: PropTypes.bool.isRequired,
};
