import React from 'react';
import PropTypes from 'prop-types';
import classes from './ItemNotFound.module.css';
import { historyShape } from '../../routerPropTypes';
import ExperimentalButton from '../buttons/experimentalButton/experimentalButton';

class ItemNotFound extends React.Component {
  handleButtonClick = () => {
    this.props.history.push('/in/folder/root');
  };

  render = () => (
    <div className={classes.wrapper}>
      <h2>Could not find item</h2>
      <ExperimentalButton type="primary" title="return home" onClick={this.handleButtonClick}>
        Return home
      </ExperimentalButton>
    </div>
  );
}

export default ItemNotFound;

ItemNotFound.propTypes = {
  history: PropTypes.shape(historyShape).isRequired,
};
