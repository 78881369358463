import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';
import { ItemTypeIcon } from '../../itemTypeIcon/ItemTypeIcon';
import {
  getFileTypeStringFromFileTypeObj,
  getAmount,
  getItemTypeFromFileTypeObj,
} from './ItemListItemContentAmountsDisplayUtilityFunctions';

const ItemListItemContentAmountsDisplay = (props) => {
  const itemFileTypeAmountsList = [
    { videos: props.item.resources.video },
    { sounds: props.item.resources.audio },
    { images: props.item.resources.image },
    { documents: props.item.resources.doc },
    { folders: props.item.resources.folder },
    { projects: props.item.resources.project },
  ];

  const listItems = itemFileTypeAmountsList.map((fileType, index) => {
    const fileGenre = getFileTypeStringFromFileTypeObj(fileType);
    const itemType = getItemTypeFromFileTypeObj(fileType);
    const amount = getAmount(fileType);
    if (amount > 0) {
      return (
        <ContentInfoItem key={`${index}+${amount}`}>
          <ContentInfoItemIconWrapper>
            <ItemTypeIcon itemType={itemType} fileGenre={fileGenre} size={12} color="white" />
          </ContentInfoItemIconWrapper>
          <ContentInfoItemAmount>{amount}</ContentInfoItemAmount>
        </ContentInfoItem>
      );
    } else {
      return null;
    }
  });
  const nullItemsRemovedListItems = listItems.filter((listItem) => listItem);
  return <ContentInfoWrapper>{nullItemsRemovedListItems}</ContentInfoWrapper>;
};

const ContentInfoWrapper = styled.div`
  padding: 0 var(--space-2);
  display: grid;
  grid-template-areas:
    '. . .'
    '. . .';
  align-items: end;
  margin: auto 0 0 0;
  z-index: 3;
  position: relative;
  width: 100%;
  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 120%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
`;

const ContentInfoItem = styled.div`
  display: flex;
  margin: 0 10px;
  z-index: 2;
`;

const ContentInfoItemAmount = styled.p`
  margin: 0 0 0 0.2rem;
  font-weight: 900;
  color: white;
`;

const ContentInfoItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
`;

export default ItemListItemContentAmountsDisplay;

ItemListItemContentAmountsDisplay.propTypes = {
  // ItemList.jsx props
  item: PropTypes.shape(folderListItemShape).isRequired,
};
