export const getAvailableDropdownItems = (isFavourite, item, currentUserIsAViewer) => {
  const favouriteStr = isFavourite ? 'Remove from favourites' : 'Set as favourite';
  if (!currentUserIsAViewer) {
    if (item.itemtype.S === 'resource' || item.itemtype === 'resource') {
      const isVideo = item.mimeType?.S
        ? item.mimeType.S.split('/')[0] === 'video'
        : item.mimeType.split('/')[0] === 'video';
      if (isVideo) {
        return [
          'Rename',
          'Download',
          favouriteStr,
          'Move',
          'Copy',
          'Delete',
          'Share',
          'Convert to mp4',
        ];
      } else {
        return ['Rename', 'Download', favouriteStr, 'Move', 'Copy', 'Delete', 'Share'];
      }
    } else {
      return ['Rename', 'Move', 'Delete', 'Cover image'];
    }
  } else {
    if (item.itemtype.S === 'resource' || item.itemtype === 'resource') {
      return ['Download', favouriteStr, 'Share'];
    } else {
      return ['Download', favouriteStr];
    }
  }
};
