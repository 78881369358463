import React from 'react';
import PropTypes from 'prop-types';

const ProjectIcon = (props) => {
  const { height, fill } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 487.3 527.4"
      enableBackground="new 0 0 487.3 527.4"
      xmlSpace="preserve"
      height={height}
    >
      <g>
        <path
          fill={fill}
          d="M210.9,165.6c1.5-1.1,2.5-2.8,2.8-4.7c0.3-1.9-0.2-3.8-1.3-5.3l-10.5-14.3c-0.1-0.2-0.3-0.4-0.5-0.6
          c-9.6-10.7-15-24.5-15-39c0-15.6,6.1-30.3,17.1-41.2c10.8-10.8,25.2-16.7,40.5-16.7l0.3,0c19,0.1,36.7,9.5,47.3,25.3
          c1.1,1.6,2.7,2.6,4.5,3c1.9,0.4,3.8,0,5.3-1.1c3.2-2.2,4.1-6.6,1.9-9.9l0,0c-5.5-8.1-12.5-14.9-20.5-20c0.7,0.2,1.5,0.4,2.2,0.4
          c2.5,0,4.9-1.4,6.2-3.6l8.3-14.3c1-1.6,1.2-3.6,0.7-5.4s-1.7-3.4-3.3-4.3c-1.6-0.9-3.6-1.2-5.4-0.7c-1.8,0.5-3.4,1.7-4.3,3.3
          L279,30.8c-1,1.6-1.2,3.6-0.7,5.4c0.3,1.2,1,2.3,1.8,3.2c-9.8-5.7-20.8-9.1-32.4-9.7c2-1.3,3.4-3.5,3.4-6V7.1
          c0-3.9-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1v16.5c0,2.5,1.3,4.8,3.4,6c-11.3,0.6-22.1,3.8-31.8,9.4c1.7-2.2,2-5.3,0.5-7.9l-8.3-14.3
          c-1-1.6-2.5-2.8-4.3-3.3c-1.8-0.5-3.8-0.2-5.4,0.7c-1.6,1-2.8,2.5-3.3,4.3c-0.5,1.8-0.2,3.8,0.7,5.4l8.3,14.3
          c1.3,2.2,3.6,3.6,6.2,3.6c0.5,0,0.9-0.1,1.4-0.2c-3.8,2.6-7.4,5.5-10.7,8.8c-13.7,13.7-21.3,31.9-21.3,51.3
          c0,17.9,6.5,35,18.4,48.3l10.3,14c1.3,1.8,3.5,2.9,5.7,2.9C208.2,167,209.6,166.5,210.9,165.6z"
        />
        <path
          fill={fill}
          d="M311.1,76L311.1,76c-1.4-3.7-5.5-5.5-9.2-4.1c-3.7,1.4-5.5,5.5-4.1,9.2c2.5,6.6,3.8,13.6,3.8,20.8
          c0,14.5-5.3,28.4-15,39.1c-0.2,0.2-0.3,0.4-0.5,0.6l-11.1,15c-0.2,0.2-0.3,0.5-0.5,0.7c-5.2,9.1-9,18.1-11.5,24.7h-12.4
          c0.3-1,0.5-2.1,0.5-3.3v-26.3c0-5.2-3.1-9.3-7.1-9.3s-7.1,4.1-7.1,9.3v26.3c0,1.2,0.2,2.3,0.5,3.3h-12.4c-1.5-4-3.1-7.9-4.8-11.6
          c-0.8-1.7-2.2-3-4-3.7c-1.8-0.7-3.7-0.6-5.4,0.2c-1.7,0.8-3,2.2-3.7,4c-0.7,1.8-0.6,3.7,0.2,5.4c1.1,2.3,2.1,4.7,3.1,7.2
          c-1.7,1.3-2.8,3.3-2.8,5.6v7.6c-3.1,0.8-5.3,3.6-5.3,6.9v14.5c0,3.3,2.3,6.1,5.3,6.9v7.6c0,3.8,3,6.9,6.7,7.1l1.9,8.9
          c0.7,3.2,3.9,3.4,11,3.7c4.4,0.2,10.4,0.3,16.8,0.3c0.1,0,0.1,0,0.2,0c6.4,0,12.4-0.1,16.8-0.3c7.1-0.3,10.4-0.5,11.1-3.7l1.9-8.9
          c3.7-0.2,6.7-3.3,6.7-7.1v-7.6c3-0.8,5.3-3.6,5.3-6.9v-14.5c0-3.3-2.3-6.1-5.3-6.9V189c0-2.3-1.1-4.3-2.8-5.6
          c2.7-6.7,5.7-13,9-18.8l10.6-14.4c11.9-13.3,18.5-30.4,18.5-48.3C315.8,92.9,314.2,84.2,311.1,76z"
        />
        <path
          fill={fill}
          d="M154.8,62.7l14.3,8.3c1.1,0.6,2.3,1,3.6,1c2.5,0,4.9-1.4,6.2-3.6c1-1.6,1.2-3.6,0.7-5.4
          c-0.5-1.8-1.7-3.4-3.3-4.3l-14.3-8.3c-1.6-1-3.6-1.2-5.4-0.7c-1.8,0.5-3.4,1.7-4.3,3.3c-1,1.6-1.2,3.6-0.7,5.4
          C152,60.2,153.2,61.7,154.8,62.7z"
        />
        <path
          fill={fill}
          d="M333.2,149.2l-14.3-8.3c-1.6-1-3.6-1.2-5.4-0.7c-1.8,0.5-3.4,1.7-4.3,3.3c-1,1.6-1.2,3.6-0.7,5.4
          c0.5,1.8,1.7,3.4,3.3,4.3l14.3,8.3c1.1,0.6,2.3,1,3.6,1c2.5,0,4.9-1.4,6.2-3.6c1-1.6,1.2-3.6,0.7-5.4S334.8,150.2,333.2,149.2z"
        />
        <path
          fill={fill}
          d="M168.8,105.9c0-3.9-3.2-7.1-7.1-7.1h-16.5c-3.9,0-7.1,3.2-7.1,7.1s3.2,7.1,7.1,7.1h16.5
          C165.6,113,168.8,109.8,168.8,105.9z"
        />
        <path
          fill={fill}
          d="M342.9,98.7h-16.5c-3.9,0-7.1,3.2-7.1,7.1s3.2,7.1,7.1,7.1h16.5c3.9,0,7.1-3.2,7.1-7.1
          S346.8,98.7,342.9,98.7z"
        />
        <path
          fill={fill}
          d="M178.9,143.4c-2-3.4-6.3-4.6-9.7-2.6l-14.3,8.3c-3.4,2-4.6,6.3-2.6,9.7c1.3,2.2,3.6,3.6,6.2,3.6
          c1.2,0,2.5-0.3,3.6-1l14.3-8.3c1.6-1,2.8-2.5,3.3-4.3C180.1,146.9,179.8,145,178.9,143.4L178.9,143.4z"
        />
        <path
          fill={fill}
          d="M315.3,71.7c1.2,0,2.5-0.3,3.6-1l14.3-8.3c1.6-1,2.8-2.5,3.3-4.3c0.5-1.8,0.2-3.8-0.7-5.4
          c-1-1.6-2.5-2.8-4.3-3.3c-1.8-0.5-3.8-0.2-5.4,0.7l-14.3,8.3c-3.4,2-4.6,6.3-2.6,9.7C310.4,70.3,312.8,71.7,315.3,71.7z"
        />
      </g>
      <path
        fill={fill}
        d="M160,442.5h-16v-18.8c23.9-13.9,40-39.7,40-69.2v-48c0,0,0,0,0,0s0,0,0,0c0-19.3-13.8-35.5-32-39.2v-24.8
        c0-4.4-3.6-8-8-8H96c-39.7,0-72,32.3-72,72v24.2c0,0,0,0.1,0,0.1v24.2c0,0.1,0,0.3,0,0.4c0.3,29.2,16.3,54.7,40,68.4v18.8H48
        c-26.5,0-48,21.5-48,48v5.2h0v11.7c0,11,9.3,20.1,20.3,20.1H188c11,0,20-9,20-20v-8.9c0,0,0,0,0,0v-8C208,464,186.5,442.5,160,442.5
        z M40,354.5v-19.6l17.3-11.7c8.1,2.3,28.1,7.2,50.7,7.2c21.2,0,36.1-4.4,43.1-7l16.9,11.4v19.6c0,35.3-28.7,64-64,64
        C68.7,418.5,40,389.8,40,354.5z M120,449.5l-12.4-6.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1-0.1-0.3-0.1-0.4-0.1
        c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4-0.1-0.6-0.1c0,0-0.1,0-0.1,0
        c-1.4-0.1-2.8,0.2-4.1,0.8L88,449.5l-8-4v-14.7c7.6,2.4,15.6,3.7,24,3.7c8.4,0,16.4-1.3,24-3.7v14.7L120,449.5z"
      />
      <path
        fill={fill}
        d="M439.3,442.5h-16v-18.6l0.4-0.2c23.9-13.9,40-39.7,40-69.2c0,0,0,0,0,0s0,0,0,0v-25.9c0-0.2,0-0.5,0-0.7
        v-9.4c0-19.1-6.4-36.7-17.1-50.8c-0.3-0.5-0.7-1.1-1.2-1.5c-15.4-19.3-39.1-31.7-65.7-31.7c-46.3,0-84,37.7-84,84c0,0.1,0,0.2,0,0.3
        c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.4,0.1,0.6c0,0,0,0.1,0,0.1c0.1,0.2,0.1,0.4,0.2,0.6
        c0,0,0,0,0,0.1c0.1,0.2,0.2,0.5,0.3,0.7c0,0,0,0,0,0c0.4,0.9,1,1.8,1.8,2.5c0,0,0,0,0,0c0.2,0.2,0.4,0.3,0.6,0.5c0,0,0,0,0,0
        c0.2,0.1,0.4,0.3,0.6,0.4c0,0,0,0,0,0c0.2,0.1,0.4,0.3,0.7,0.4c0,0,0,0,0,0c0.2,0.1,0.5,0.2,0.7,0.3c0,0,0,0,0,0
        c0.2,0.1,0.5,0.2,0.8,0.2c0,0,0,0,0,0c0.3,0.1,0.5,0.1,0.8,0.2c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0
        c0.3,0,0.5,0.1,0.8,0.1v27.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,29.4,15.9,55.1,39.6,69v19h-16c-26.5,0-48,21.5-48,48v5.2h0
        v11.7c0,11,9.3,20.1,20.3,20.1h167.7c11,0,20-9,20-20v-8.9c0,0,0,0,0,0v-8C487.3,464,465.8,442.5,439.3,442.5z M319.7,354.5
        c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v-25.7c36.9,3.1,60.4-4.6,78.3-10.4c7.4-2.4,13.3-4.3,18.6-5c0.1,0,0.1,0,0.2,0
        c10.7-1.4,18.8,2.5,31,17.9v23.5c0,0,0,0,0,0s0,0,0,0c0,24.7-14.1,46.2-34.6,56.8c-0.9,0.3-1.7,0.7-2.4,1.2c-8.2,3.8-17.3,6-26.9,6
        C348.4,418.5,319.7,389.8,319.7,354.5z M399.3,449.5l-12.4-6.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.1
        c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.2,0
        c-0.2,0-0.4-0.1-0.6-0.1c0,0-0.1,0-0.1,0c-1.4-0.1-2.8,0.2-4.1,0.8l-12.4,6.2l-8-4v-14.9l0.4,0.2c7.6,2.4,15.6,3.7,24,3.7
        c8.2,0,16.2-1.3,23.6-3.6v14.6L399.3,449.5z"
      />
    </svg>
  );
};

export default ProjectIcon;

ProjectIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

ProjectIcon.defaultProps = {
  height: '0',
};
