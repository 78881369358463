import {configureStore} from '@reduxjs/toolkit';
import projectReducer from './app/features/project/projectSlice';
import userReducer from './app/features/user/userSlice';
import workspaceReducer from './app/features/workspace/workspaceSlice';
import {createBrowserHistory} from 'history';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {actionToPlainObject} from './actionToPlainObject';

export const history = createBrowserHistory({basename: '/app'});

export default configureStore({
  reducer: {
    project: projectReducer,
    user: userReducer,
    workspace: workspaceReducer,
    router: connectRouter(history)
  },
  middleware: [actionToPlainObject, routerMiddleware(history)]
});
