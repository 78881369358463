import React from 'react';
import PropTypes from 'prop-types';
import classes from './ItemList.module.css';
import ListActionables from './listActionables/ListActionables';
import ItemListModals from './itemListModals/ItemListModals';
import FolderViewItemList from './folderViewItemList/FolderViewItemList';
import ProjectViewItemList from './projectViewItemList/ProjectViewItemList';
import LinkViewItemList from './linkViewItemList/LinkViewItemList';
import { matchShape, historyShape, locationShape } from '../../routerPropTypes';
import { currentUserInfoShape } from '../../apiServices/userApiTypes';
import { useCaseType } from './ItemListPropTypes';
import { folderListItemShape } from '../../app/views/folderView/FolderViewPropTypes';
import { ItemListButtons } from './itemListButtons/ItemListButtons';
import { transformItems } from '../../app/features/project/utils';

class ItemList extends React.Component {
  state = {
    dropdownOpenedItemId: undefined,
    renamingItemId: undefined,
    modalOpen: undefined,
    modalParams: undefined,
    selectedItems: [],
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.updateSelectedItems([]);
    } else if (
      this.state.selectedItems.length > 0 &&
      this.props.content.length !== prevProps.content.length
    ) {
      this.removeFilteredContentFromSelectedItems(this.state.selectedItems, this.props.content);
    }
  };

  removeFilteredContentFromSelectedItems = (selectedItems, content) => {
    const deletableList = [];
    selectedItems.forEach((item) => {
      const existsInContent = content.some((contentItem) => contentItem.id === item.id);
      if (!existsInContent) {
        deletableList.push(item);
      }
    });
    if (deletableList.length > 0) {
      this.removeFromSelectedItems(deletableList);
    }
  };

  updateModalOpen = (type, modalParams) => {
    if (modalParams) {
      this.setState({ modalParams });
    }
    if (type) {
      this.setState({ modalOpen: type });
    } else {
      this.setState({ modalOpen: undefined });
    }
  };

  updateSelectedItems = (newSelectedItems) => {
    this.setState({ selectedItems: newSelectedItems });
  };

  addToSelectedItems = (itemToBeAdded) => {
    const { selectedItems } = this.state;
    const newList = [...selectedItems, itemToBeAdded];
    this.setState({ selectedItems: newList });
  };

  removeFromSelectedItems = (itemToBeRemovedArr) => {
    const { selectedItems } = this.state;
    const newList = selectedItems.filter(
      (item) => !itemToBeRemovedArr.some((toBeRemovedItem) => toBeRemovedItem.id === item.id),
    );
    this.setState({ selectedItems: newList });
  };

  updateDropdownOpenedItemId = (id) => {
    this.setState({ dropdownOpenedItemId: id });
  };

  updateRenamingItemId = (id) => {
    this.setState({ renamingItemId: id });
  };

  isViewer = () => {
    // folderviews still use old schema
    if (!!this.props.userRole)
      return this.props.userRole === 'viewer' || this.props.userRole === 'guest';
    return this.props.currentUserInfo ? this.props.currentUserInfo.role.S === 'viewer' : false;
  };

  isAtRoot = () => {
    const pathnameSplitArr = this.props.location.pathname.split('/');
    return pathnameSplitArr[pathnameSplitArr.length - 1] === 'root';
  };

  render() {
    const currentUserIsAViewer = this.isViewer();
    const isRoot = this.isAtRoot();
    // console.log('selecteditems', this.state.selectedItems);
    // Project sections is refactored and folder views are not, so transform to new format
    const transformedContent = !!this.props.content[0]?.id?.S
      ? transformItems(this.props.content)
      : this.props.content;

    return (
      <>
        <div className={classes.wrapper}>
          {transformedContent.length > 0 && !(currentUserIsAViewer && isRoot) && (
            <ListActionables
              selectedItems={this.state.selectedItems}
              updateSelectedItems={this.updateSelectedItems}
              addToSelectedItems={this.addToSelectedItems}
              removeFromSelectedItems={this.removeFromSelectedItems}
              updateModalOpen={this.updateModalOpen}
              refreshList={this.props.refreshList}
              content={transformedContent}
              updateIsQuerying={this.props.updateIsQuerying}
              currentUserIsAViewer={currentUserIsAViewer}
              useCase={this.props.useCase}
              setAllPreview={this.props.setAllPreview}
            />
          )}
          {this.props.currentUserInfo && (
            <>
              {this.props.useCase === 'folder' && (
                <FolderViewItemList
                  selectedItems={this.state.selectedItems}
                  addToSelectedItems={this.addToSelectedItems}
                  removeFromSelectedItems={this.removeFromSelectedItems}
                  updateModalOpen={this.updateModalOpen}
                  updateDropdownOpenedItemId={this.updateDropdownOpenedItemId}
                  dropdownOpenedItemId={this.state.dropdownOpenedItemId}
                  updateRenamingItemId={this.updateRenamingItemId}
                  renamingItemId={this.state.renamingItemId}
                  updateIsQuerying={this.props.updateIsQuerying}
                  refreshList={this.props.refreshList}
                  content={transformedContent}
                  match={this.props.match}
                  currentUserInfo={this.props.currentUserInfo}
                  currentUserGroup={this.props.currentUserGroup}
                  sortByCriteriaIndex={this.props.sortByCriteriaIndex}
                  sortByDirectionIsAscending={this.props.sortByDirectionIsAscending}
                  useCase={this.props.useCase}
                />
              )}
              {this.props.useCase === 'project' && (
                <ProjectViewItemList
                  updateIsQuerying={this.props.updateIsQuerying}
                  refreshList={this.props.refreshList}
                  content={transformedContent}
                  match={this.props.match}
                  currentUserInfo={this.props.currentUserInfo}
                  selectedItems={this.state.selectedItems}
                  addToSelectedItems={this.addToSelectedItems}
                  removeFromSelectedItems={this.removeFromSelectedItems}
                  updateModalOpen={this.updateModalOpen}
                  updateDropdownOpenedItemId={this.updateDropdownOpenedItemId}
                  dropdownOpenedItemId={this.state.dropdownOpenedItemId}
                  updateRenamingItemId={this.updateRenamingItemId}
                  renamingItemId={this.state.renamingItemId}
                  useCase={this.props.useCase}
                  sortByCriteriaIndex={this.props.sortByCriteriaIndex}
                  sortByDirectionIsAscending={this.props.sortByDirectionIsAscending}
                  userRole={this.props.userRole}
                  projectFileListView={this.props.projectFileListView}
                />
              )}
            </>
          )}
          {this.props.useCase === 'link' && (
            <LinkViewItemList
              content={this.props.content}
              match={this.props.match}
              updateModalOpen={this.updateModalOpen}
              refreshList={this.props.refreshList}
              viewAsLargeGrid={false}
              updateIsQuerying={this.props.updateIsQuerying}
              useCase={this.props.useCase}
              updateResourceId={this.props.updateResourceId}
              selectedItems={this.state.selectedItems}
              addToSelectedItems={this.addToSelectedItems}
              removeFromSelectedItems={this.removeFromSelectedItems}
            />
          )}
        </div>
        <ItemListButtons
          location={this.props.location}
          currentUserInfo={this.props.currentUserInfo}
          updateModalOpen={this.updateModalOpen}
          useCase={this.props.useCase}
        />
        <ItemListModals
          history={this.props.history}
          match={this.props.match}
          location={this.props.location}
          updateIsQuerying={this.props.updateIsQuerying}
          currentUserInfo={this.props.currentUserInfo}
          currentItem={this.props.currentItem}
          refreshList={this.props.refreshList}
          content={transformedContent}
          modalOpen={this.state.modalOpen}
          modalParams={this.state.modalParams}
          inProjectInitialState={this.props.inProjectInitialState}
          useCase={this.props.useCase}
          updateModalOpen={this.updateModalOpen}
          removeFromSelectedItems={this.removeFromSelectedItems}
          selectedItems={this.state.selectedItems}
        />
      </>
    );
  }
}

export default ItemList;

ItemList.propTypes = {
  // router props
  match: PropTypes.shape(matchShape).isRequired,
  history: PropTypes.shape(historyShape).isRequired,
  location: PropTypes.shape(locationShape).isRequired,

  // App.jsx props
  currentUserGroup: PropTypes.string.isRequired,
  currentItem: PropTypes.object.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  updateIsQuerying: PropTypes.func.isRequired,

  // FolderView.jsx props
  content: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  refreshList: PropTypes.func.isRequired,

  // Folder.jsx or ProjectView.jsx props
  sortByCriteriaIndex: PropTypes.number,
  sortByDirectionIsAscending: PropTypes.bool,
  inProjectInitialState: PropTypes.bool.isRequired,

  projectFileListView: PropTypes.string,
  useCase: useCaseType.isRequired,
  updateResourceId: PropTypes.func,
};

ItemList.defaultProps = {
  projectFileListView: undefined,
  sortByCriteriaIndex: undefined,
  sortByDirectionIsAscending: undefined,
  updateResourceId: undefined,
};
