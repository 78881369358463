import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CheckedIcon from '../../icons/CheckedIcon';

export const CheckBox = (props) => (
  <Button
    isSelected={props.isSelected}
    disabled={props.disabled}
    onClick={props.handleSelectClick}
    type="button"
    title={props.disabled ? '' : 'select item'}
    data-testid="item-actionables-checkbox-button"
  >
    {props.isSelected && <CheckedIcon height="15px" fill="white" />}
  </Button>
);

const Button = styled.button`
  position: absolute;
  z-index: 10;
  top: -4px;
  left: -4px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: ${(props) => (props.isSelected ? '#29baf8' : 'white')};
  border: ${(props) => (props.isSelected ? '1px solid transparent' : '1px solid lightgray')};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  -webkit-appearance: ${(props) => (props.isSelected ? 'none' : 'unset')};
`;

CheckBox.propTypes = {
  handleSelectClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CheckBox.defaultProps = {
  disabled: false,
};
