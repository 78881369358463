import React from 'react';
import PropTypes from 'prop-types';
import searchItems from '../../../apiServices/searchApi';
import SearchIcon from '../../icons/SearchIcon';
import SearchBarDropdownList from './searchBarDropdownList/SearchBarDropdownList';
import Subnavigation from '../Subnavigation';
import './searchBar.scss';
import { historyShape } from '../../../routerPropTypes';
import NotificationContext from '../../../context/notification';

const initialState = {
  searchBarValue: '',
  searchItemsList: [],
  searchBarInitialState: true,
  searching: false,
  selectedItem: undefined,
};

class SearchBar extends React.Component {
  state = initialState;

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  handleKeyPress = (event) => {
    switch (event.keyCode) {
      // arrow up
      case 38:
        if (this.state.selectedItem) {
          const selectedItemIndex = this.state.searchItemsList.findIndex(
            (searchItem) => searchItem.id.S === this.state.selectedItem.id.S,
          );
          if (selectedItemIndex - 1 >= 0) {
            const { searchItemsList } = this.state;
            this.setState({ selectedItem: searchItemsList[selectedItemIndex - 1] });
          } else {
            const { searchItemsList } = this.state;
            this.setState({ selectedItem: searchItemsList[searchItemsList.length - 1] });
          }
        } else {
          const { searchItemsList } = this.state;
          this.setState({ selectedItem: searchItemsList[searchItemsList.length - 1] });
        }
        break;
      // arrow down
      case 40:
        if (this.state.selectedItem) {
          const selectedItemIndex = this.state.searchItemsList.findIndex(
            (searchItem) => searchItem.id.S === this.state.selectedItem.id.S,
          );
          if (selectedItemIndex + 1 < this.state.searchItemsList.length) {
            const { searchItemsList } = this.state;
            this.setState({ selectedItem: searchItemsList[selectedItemIndex + 1] });
          } else {
            const { searchItemsList } = this.state;
            this.setState({ selectedItem: searchItemsList[0] });
          }
        } else {
          const { searchItemsList } = this.state;
          this.setState({ selectedItem: searchItemsList[0] });
        }
        break;
      // enter
      case 13:
        if (this.props.isSubnavOpen && this.state.selectedItem) {
          const isResource = this.state.selectedItem.itemtype.S === 'resource';
          if (!isResource) {
            this.props.history.push(
              `/in/${this.state.selectedItem.itemtype.S}/${this.state.selectedItem.id.S}`,
            );
            this.setState(initialState);
            this.props.closeSubnav();
          } else {
            this.props.history.push(
              `/in/${this.state.selectedItem.parentType.S}/${this.state.selectedItem.parent.S}/${this.state.selectedItem.itemtype.S}/${this.state.selectedItem.id.S}/allfiles`,
            );
            this.setState(initialState);
            this.props.closeSubnav();
          }
        }
        break;
      // escape key
      case 27:
        if (this.props.closeSubnav) {
          this.setState(initialState);
          this.props.closeSubnav();
        }
        break;
      default:
        break;
    }
  };

  updateSearchBarValue = (searchBarValue) => {
    this.setState({ searchBarValue });
  };

  resetSearchBarState = () => {
    console.log('reset');
    this.setState({
      searchBarValue: '',
      searchItemsList: [],
      searchBarInitialState: true,
      searching: false,
    });
  };

  handleSearchBarChange = async (event) => {
    this.updateSearchBarValue(event.target.value);
    if (event.target.value.length > 0) {
      this.props.openSubnav();
      if (event.target.value.length > 2) {
        try {
          this.setState({ searching: true });
          this.props.updateIsQuerying(true);
          const searchItemsResponse = await searchItems(event.target.value);
          this.props.updateIsQuerying(false);
          this.setState({
            searchItemsList: searchItemsResponse.response.Items,
            searching: false,
            searchBarInitialState: false,
          });
        } catch (searchItemsError) {
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: searchItemsError.message,
          });
          this.setState({ searching: false });
          this.props.updateIsQuerying(false);
        }
      } else {
        this.setState({
          searchItemsList: [],
          searchBarInitialState: true,
        });
      }
    } else {
      this.props.closeSubnav();
      this.setState({
        searchBarInitialState: true,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  static contextType = NotificationContext;

  render() {
    return (
      <div className="searchWrapper">
        <form onSubmit={this.handleSubmit} className="searchForm">
          <SearchIcon height="20px" fill="gray" />
          <input
            type="search"
            className="searchWrapperSearchBar"
            placeholder="Search"
            onChange={this.handleSearchBarChange}
            value={this.state.searchBarValue}
          />
        </form>
        <Subnavigation isOpen={this.props.isSubnavOpen} close={this.props.closeSubnav}>
          <SearchBarDropdownList
            searchItemsList={this.state.searchItemsList}
            searching={this.state.searching}
            searchBarInitialState={this.state.searchBarInitialState}
            searchTerm={this.state.searchBarValue}
            resetSearchBarState={this.resetSearchBarState}
            selectedItem={this.state.selectedItem}
            closeSubnav={this.props.closeSubnav}
            isSubnavOpen={this.props.isSubnavOpen}
            history={this.props.history}
          />
        </Subnavigation>
      </div>
    );
  }
}

export default SearchBar;

SearchBar.propTypes = {
  // router props
  history: PropTypes.shape(historyShape).isRequired,

  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,

  openSubnav: PropTypes.func.isRequired,
  closeSubnav: PropTypes.func.isRequired,
  isSubnavOpen: PropTypes.bool.isRequired,
};
