import React from 'react';
import PropTypes from 'prop-types';
import Resource from './resource/Resource';
import { matchShape, locationShape, historyShape } from '../../../routerPropTypes';
import { initShape } from '../../AppPropTypes';
import { getExtFromFileName, downloadFile, sortContentList } from '../../../util/UtilFunctions';
import { validateFileTypeRendability } from '../../../util/FileTypeSupportCheck';
import ErrorBoundary from '../../errorBoundary/ErrorBoundary';
import { getFilesForProjectByType } from '../../../apiServices/projectApi';
import { getFolderById } from '../../../apiServices/folderApi';
import { ReactComponent as ArrowIcon } from '../../../assets/nitro/arrow-down.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/nitro/download.svg';
import { ReactComponent as FullscreenIcon } from '../../../assets/nitro/fullscreen.svg';
import Loader from '../../../components/loader/loader';
import './resourceView.scss';
import { getResourceDetails } from '../../../apiServices/resourceApi';
import NotificationContext from '../../../context/notification';

class ResourceView extends React.Component {
  constructor() {
    super();
    this.state = {
      items: [],
      activeIndex: undefined,
      fullscreen: false,
    };

    this.keyupHandler = this.keyupHandler.bind(this);
    this.cancelled = false;
  }

  componentDidMount = () => {
    this.initateResourceView();
    window.addEventListener('keyup', this.keyupHandler);
  };

  componentDidUpdate = (prevProps) => {
    // only update when parent (folder or project) changes
    if (prevProps.match.params.viewType !== this.props.match.params.viewType) {
      this.initateResourceView();
    }
  };

  componentWillUnmount() {
    this.cancelled = true;
    window.removeEventListener('keyup', this.keyupHandler);
  }

  initateResourceView = () => {
    if (this.props.init && this.props.currentUserGroup) {
      // id is currentItem id, parentId is id of the folder or project user is viewing
      const { id, parentId, viewType } = this.props.match.params;
      // get parentType from url
      const parentType = this.props.location.pathname.split('/')[2];

      if (parentType === 'project') {
        // get files from current project
        getFilesForProjectByType(parentId, viewType || 'allfiles').then(({ response }) => {
          let breadcrumb = response.Item?.breadcrumb;
          breadcrumb.push({
            id: response.Item.id.S,
            title: response.Item.title?.S,
            itemtype: 'project',
          });

          const items = this.parseItems(response.Items).map((i) => ({
            ...i,
            breadcrumb,
          }));
          // find current item index from created files-list
          const activeIndex = items.findIndex((i) => (i.id.S || i.id) === id);
          this.props.updateCurrentItem(items[activeIndex]);
          this.setState({
            items,
            activeIndex,
          });
        });
      } else {
        const sortByCriteriaIndex = this.getSortByIndexFromQueryParams(this.props.location);
        const sortByAscending = this.getAscendingFromQueryParams(this.props.location);
        // get files from current folder
        getFolderById(parentId).then(({ response }) => {
          let breadcrumb = response.Item.breadcrumb;
          breadcrumb.push({
            id: response.Item.id.S,
            title: response.Item.title?.S,
            itemtype: 'folder',
          });

          const items = this.parseItems(response.Items);
          const sortedItems = sortContentList(
            items,
            // eslint-disable-next-line
            sortByCriteriaIndex ? sortByCriteriaIndex : 0,
            // eslint-disable-next-line
            sortByAscending ? sortByAscending : true,
          ).map((i) => ({
            ...i,
            breadcrumb,
          }));

          // find current item index from created files-list
          const activeIndex = sortedItems.findIndex((i) => (i.id.S || i.id) === id);
          this.props.updateCurrentItem(sortedItems[activeIndex]);
          this.setState({
            items: sortedItems,
            activeIndex,
          });
        });
      }
    }
  };

  handleDownloadFileClick = async () => {
    const content = this.state.items[this.state.activeIndex];
    try {
      this.props.updateIsQuerying(true);
      const getResourceDetailsResponse = await getResourceDetails(content.id.S || content.id);
      await downloadFile(
        content.name.S || content.name,
        getResourceDetailsResponse.response.Item.signedUrl.S,
      );
      this.context.add({
        id: Date.now(),
        type: 'success',
        message: 'Downloading file',
      });
      this.props.updateIsQuerying(false);
    } catch (getResoureDetailsError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not get resource details',
      });
      this.props.updateIsQuerying(false);
    }
  };

  navigateToNextFile = () => {
    let newIndex;
    if (this.state.activeIndex === this.state.items.length - 1) {
      newIndex = 0;
    } else {
      newIndex = this.state.activeIndex + 1;
    }
    // change url
    this.changePathToNewId(this.state.items[newIndex].id.S || this.state.items[newIndex].id);
    // set new index to state
    this.props.updateCurrentItem(this.state.items[newIndex]);
    this.setState({ activeIndex: newIndex });
  };

  navigateToPreviousFile = () => {
    let newIndex;
    if (this.state.activeIndex === 0) {
      newIndex = this.state.items.length - 1;
    } else {
      newIndex = this.state.activeIndex - 1;
    }
    // change url
    this.changePathToNewId(this.state.items[newIndex].id.S || this.state.items[newIndex].id);
    // set new index to state

    this.props.updateCurrentItem(this.state.items[newIndex]);
    this.setState({ activeIndex: newIndex });
  };

  changePathToNewId = (id) => {
    const path = this.props.location.pathname.split('/');
    // replace resourceId part of the url with new id
    path[5] = id;
    const url = path.join('/');
    this.props.history.push(url);
  };

  // filter out other than resources and return new array
  parseItems = (items) => items.filter((item) => (item.itemtype.S || item.itemtype) === 'resource');

  getSortByIndexFromQueryParams = (location) => {
    if (location.search !== '') {
      if (location.search.charAt('?') !== -1) {
        const queryParam = location.search.split('?')[1];
        const items = queryParam.split('&');
        const sortByItem = items.find((item) => item.search('sortBy') !== -1);
        const sortByIndex = sortByItem.split('=')[1];
        return parseInt(sortByIndex, 10);
      }
      return undefined;
    } else {
      return undefined;
    }
  };

  getAscendingFromQueryParams = (location) => {
    if (location.search !== '') {
      if (location.search.charAt('?') !== -1) {
        const queryParam = location.search.split('?')[1];
        const items = queryParam.split('&');
        const ascendingItem = items.find((item) => item.search('ascending') !== -1);
        const ascendingValue = ascendingItem.split('=')[1] === 'true';
        return ascendingValue;
      }
      return undefined;
    } else {
      return undefined;
    }
  };

  keyupHandler({ key }) {
    if (key === 'ArrowLeft') this.navigateToPreviousFile();
    if (key === 'ArrowRight') this.navigateToNextFile();
  }

  static contextType = NotificationContext;

  render() {
    const content = this.state.items[this.state.activeIndex];
    const type = content?.type.S || content?.type;

    const fileExt = getExtFromFileName(content?.name.S || content?.name || '');
    const isRendable = validateFileTypeRendability(type, fileExt);
    return (
      <ErrorBoundary>
        <div className={`resourceView ${type === undefined ? type : ''}`}>
          <>
            {/* eslint-disable */}
            {!isNaN(this.state.activeIndex) ? (
              <Resource
                content={content}
                fullscreen={this.state.fullscreen}
                closeFullscreen={() => this.setState({ fullscreen: false })}
                fileExt={fileExt}
                isRendable={isRendable}
                openFullscreen={() => this.setState({ fullscreen: true })}
              />
            ) : (
              <Loader />
            )}
            <div className="toolbar">
              <div className="download">
                <button type="button" onClick={this.handleDownloadFileClick}>
                  <DownloadIcon />
                </button>
              </div>
              <div className="mainControls">
                <button type="button" className="arrow" onClick={this.navigateToPreviousFile}>
                  <ArrowIcon />
                </button>
                <span className="count">
                  {this.state.items.length > 0 &&
                    `${this.state.activeIndex + 1}/${this.state.items.length}`}
                </span>
                <button type="button" className="arrow" onClick={this.navigateToNextFile}>
                  <ArrowIcon />
                </button>
              </div>
              {type !== 'video' && type !== 'audio' && isRendable && (
                <div className="fullscreen">
                  <button type="button" onClick={() => this.setState({ fullscreen: true })}>
                    <FullscreenIcon />
                  </button>
                </div>
              )}
              {content && <h5 className="filename">{content.name.S || content.name}</h5>}
            </div>
          </>
        </div>
      </ErrorBoundary>
    );
  }
}

export default ResourceView;

ResourceView.propTypes = {
  // router props
  match: PropTypes.shape(matchShape).isRequired,
  location: PropTypes.shape(locationShape).isRequired,
  history: PropTypes.shape(historyShape).isRequired,

  // app jsx
  init: PropTypes.shape(initShape).isRequired,
  currentUserGroup: PropTypes.string.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
};
