import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ItemTypeIcon } from '../itemTypeIcon/ItemTypeIcon';

export const FileIconBall = (props) => {
  let color;
  switch (props.fileGenre) {
    case 'video':
      color = '#29baf8';
      break;
    case 'audio':
      color = ' #00e1a1';
      break;
    case 'image':
      color = '#ebc300';
      break;
    case 'doc':
      color = '#e35000';
      break;
    default:
      color = 'gray';
      break;
  }
  return (
    <IconWrapper color={color}>
      <ItemTypeIcon
        itemType={props.itemType}
        fileGenre={props.fileGenre}
        size={props.size}
        color={props.color}
      />
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  position: absolute;
  background-color: none;
  top: 3px;
  left: 3px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: ${(props) => props.color};
`;

FileIconBall.propTypes = {
  itemType: PropTypes.oneOf(['folder', 'project', 'resource']).isRequired,
  fileGenre: PropTypes.oneOf(['image', 'video', 'audio', 'doc']),
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

FileIconBall.defaultProps = {
  fileGenre: '',
};
