import { API } from 'aws-amplify';
import store from '../configureStore';
import { config } from '../config/Config';

export const addLinks = (resourcesIdList) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const init = {
    headers: header,
    body:
      resourcesIdList.length > 1 ? `id=${resourcesIdList.join(',')}` : `id=${resourcesIdList[0]}`,
  };
  return API.post(config.apiGateway.NAME, `/link/add?gid=${currentUserGroup}`, init);
};

export const getLink = (tempId) => API.get(config.apiGateway.NAME, `/link/get/${tempId}`);
