import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import NoAccessView from '../app/views/noAccessView/NoAccessView';
import { useSelector, useDispatch } from 'react-redux';
import PackagesView from '../app/views/subscription/packagesView';
import Spinner from '../components/common/spinner';
import {
  getAllUsers,
  getCurrentUserInfo,
  getUsersGroups,
  getWorkspaceUsers,
} from '../apiServices/userApi';
import { setAllWorkspaces, updateWorkspaceUsers } from '../app/features/workspace/workspaceSlice';
import { setDetails, setOldUserDetails } from '../app/features/user/userSlice';

const AuthenticatedRoute = ({ component: Component, props: childProps, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const { createdGroup, allWorkspaces, currentUserGroup, rootGroup } = useSelector(
    (state) => state.workspace,
  );
  const { uid } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const routeIsOutsideLink = childProps.location.pathname.search('/in/link') !== -1;
  const shouldRenderComponent = childProps.isAuthenticated;

  useEffect(() => {
    const fetchAppData = async () => {
      setLoading(true);
      const myselfRes = await getCurrentUserInfo(currentUserGroup);
      const groups = await getUsersGroups();

      // Workspaces
      const myself = myselfRes.response.Item;
      // packages to choose from
      const packages = myselfRes.response.Packages;
      // For active subscription this retuns a package
      const selectedPackage = myselfRes.response.Package;

      dispatch(setAllWorkspaces({ items: myself.groups, uid, packages, selectedPackage }));
      dispatch(setDetails(myself));
      dispatch(setOldUserDetails(myself));

      //All users
      const allUsersRes = await getWorkspaceUsers();

      dispatch(updateWorkspaceUsers(allUsersRes.response));
      setLoading(false);
    };
    if (uid) {
      fetchAppData();
    }
  }, [uid, currentUserGroup]);

  if (childProps.location.pathname.includes('/in/link') && !childProps.isAuthenticated && !uid) {
    return <Redirect to={childProps.location.pathname.replace('/in', '')} />;
  }

  if (loading) {
    return (
      <div
        style={{
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          left: 0,
          top: 0,
        }}
      >
        <Spinner />
      </div>
    );
  }

  let activeGroupIsRoot = false;
  if (allWorkspaces) {
    const activeWorkspace = allWorkspaces.find((group) => currentUserGroup === group.id);
    activeGroupIsRoot = false;
    if (activeWorkspace) {
      activeGroupIsRoot = activeWorkspace.parent === 'root';
    }
  }

  let View = null;
  if (shouldRenderComponent) {
    const isInAccountSettings = childProps.location.pathname.search('/in/account') !== -1;
    if (rootGroup?.status === '3' || rootGroup?.status === '4') {
      return <PackagesView />;
    }
    if (!activeGroupIsRoot) {
      View = Component;
    } else if (activeGroupIsRoot && isInAccountSettings) {
      View = Component;
    } else if (activeGroupIsRoot) {
      return <Redirect to={`/in/account/workspace`} />;
    } else {
      View = NoAccessView;
    }
  } else {
    return (
      <Redirect
        to={`/login?redirect=${childProps.location.pathname}${childProps.location.search}`}
      />
    );
  }
  return <Route {...rest} render={(props) => <View {...props} {...childProps} />} />;
};

export default AuthenticatedRoute;

AuthenticatedRoute.propTypes = {
  component: PropTypes.any,
  props: PropTypes.object.isRequired,
};

AuthenticatedRoute.defaultProps = {
  component: undefined,
};
