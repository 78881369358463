import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MiniatureFolderSystem from '../../miniatureFolderSystem/MiniatureFolderSystem';
import SelectedItemsList from '../../selectedItemsList/SelectedItemsList';
import { updateResourceParent } from '../../../apiServices/resourceApi';
import { serializeFuncs } from '../../../util/Sequentialize';
import { matchShape, historyShape } from '../../../routerPropTypes';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import { FinishedUploadsCounter } from '../fileUploadingModal/finishedUploadsCounter/FinishedUploadsCounter';
import NotificationContext from '../../../context/notification';
import { getButtonIsDisabled } from './MoveItemModalUtilityFunctions';
import { borderRadiusDefault } from '../../../styles/variables';

class MoveItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDestination: 'root',
      fileMoveProcess: { status: 'inactive' },
      filesAmount: undefined,
      filesUploading: 'initial',
    };
  }

  componentDidMount = () => {
    this.setState({ filesAmount: this.props.selectedItems.length });
  };

  updateSelectedDestination = (destination) => {
    if (destination) {
      this.setState({
        selectedDestination: destination,
        fileMoveProcess: { status: 'active' },
      });
    } else {
      this.setState({
        selectedDestination: undefined,
        fileMoveProcess: { status: 'inactive' },
      });
    }
  };

  moveSingleEntity = async (entity) => {
    this.setState({ filesUploading: 'loading' });
    if (entity.itemtype === 'resource') {
      await this.moveFile(entity);
    } else {
      await this.moveItem(entity);
    }
    this.setState({ filesUploading: 'success' });
  };

  moveMultipleEntities = async (list) => {
    this.setState({ filesUploading: 'loading' });
    const funcs = [];
    list.forEach(async (selectedItem) => {
      const newPromise = () =>
        new Promise((resolve, reject) => {
          if (selectedItem.itemtype === 'resource') {
            this.moveFile(selectedItem)
              .then(() => resolve())
              .catch((err) => reject(err));
          } else {
            this.moveItem(selectedItem)
              .then(() => resolve())
              .catch((err) => reject(err));
          }
        });
      funcs.push(newPromise);
    });
    await serializeFuncs(funcs);
    this.setState({ filesUploading: 'success' });
  };

  handleSubmitButtonClick = async () => {
    if (this.state.selectedDestination !== 'root') {
      if (this.props.match.params.id !== this.state.selectedDestination.id) {
        if (this.props.selectedItems.length === 1) {
          await this.moveSingleEntity(this.props.selectedItems[0]);
        } else {
          await this.moveMultipleEntities(this.props.selectedItems);
        }
      } else {
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: "Can't move files to current folder",
        });
      }
    } else {
      this.props.selectedItems.forEach(async (selectedItem) => {
        await this.moveItem(selectedItem);
      });
      this.props.history.push('/in/folder/root');
    }
    this.props.refreshList(false, undefined, true);
    this.props.closeModal();
    const notificationPopupStr = this.props.selectedItems.length > 1 ? 'items moved' : 'item moved';
    this.context.add({
      id: Date.now(),
      type: 'success',
      message: notificationPopupStr,
    });
  };

  moveItem = async (item) => {
    try {
      this.setState({ fileMoveProcess: { status: 'loading' } });
      this.props.updateIsQuerying(true);
      if (this.state.selectedDestination === 'root') {
        await updateResourceParent(item.id, item.title, 'root');
      } else {
        await updateResourceParent(item.id, item.title, this.state.selectedDestination.id);
      }
      this.props.updateIsQuerying(false);
      this.setState({ fileMoveProcess: { status: 'inactive' } });
      this.props.removeFromSelectedItems([item]);
    } catch (updateResourceError) {
      this.setState({ fileMoveProcess: { status: 'error' } });
      this.props.updateIsQuerying(false);
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not update item',
      });
    }
  };

  moveFile = async (file) => {
    try {
      this.setState({ fileMoveProcess: { status: 'loading' } });
      this.props.updateIsQuerying(true);
      await updateResourceParent(file.id, file.name, this.state.selectedDestination.id);
      this.props.updateIsQuerying(false);
      this.setState({ fileMoveProcess: { status: 'inactive' } });
      this.props.removeFromSelectedItems([file]);
    } catch (updateResourceError) {
      this.setState({ fileMoveProcess: { status: 'error' } });
      this.props.updateIsQuerying(false);
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not update resource',
      });
    }
  };

  static contextType = NotificationContext;

  render = () => {
    const selectedItemsContainsNonFileItem = this.props.selectedItems.some(
      (item) => item.itemtype !== 'resource',
    );
    return (
      <Wrapper>
        <Headline>Move items</Headline>
        <ContentWrapper>
          <SelectedItemsList
            selectedItems={this.props.selectedItems}
            removeFromSelectedItems={this.props.removeFromSelectedItems}
          />
          {!this.state.selectedItemsExpanded && (
            <MiniatureFolderSystem
              updateSelectedDestination={this.updateSelectedDestination}
              selectedItemsContainsNonFileItem={selectedItemsContainsNonFileItem}
              workspaceName={this.props.workspaceName}
              updateIsQuerying={this.props.updateIsQuerying}
              selectedItems={this.props.selectedItems}
              useCase="move"
              pathname={this.props.pathname}
            />
          )}
          <ExperimentalButton
            onClick={this.handleSubmitButtonClick}
            title="Move"
            isDisabled={getButtonIsDisabled(
              this.props.selectedItems,
              this.state.selectedDestination,
            )}
            loading={this.state.fileMoveProcess.status === 'loading'}
            type="primary"
          >
            {this.state.fileMoveProcess.status === 'error' ? 'Try again' : 'Move'}
          </ExperimentalButton>
          {this.state.filesAmount > 1 && (
            <FinishedUploadsCounter
              filesListLength={this.state.filesAmount}
              fileUploadingProcessStatus={this.state.filesUploading}
              finishedUploadsListLength={this.state.filesAmount - this.props.selectedItems.length}
              failedUploadsListLength={0}
            />
          )}
        </ContentWrapper>
      </Wrapper>
    );
  };
}

export default MoveItemModal;

const Wrapper = styled.div`
  position: relative;
  border-radius: ${borderRadiusDefault};
  width: 100%;
  height: 100%;

  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin-bottom: 1rem;
  }
`;

const Headline = styled.h4`
  text-align: center;
  font-size: 1.3em;
  margin: 0 0 1rem 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

MoveItemModal.propTypes = {
  // router props
  match: PropTypes.shape(matchShape).isRequired,
  history: PropTypes.shape(historyShape).isRequired,

  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,

  // ItemList.jsx
  selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  closeModal: PropTypes.func.isRequired,
  workspaceName: PropTypes.string.isRequired,
  removeFromSelectedItems: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
};
