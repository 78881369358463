import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './subnavigation.scss';

const Subnavigation = ({ close, isOpen, children }) => {
  const ref = useRef(null);

  useEffect(() => {
    // define eventlistener function
    function handleOutsideClick(e) {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        close();
      }
    }
    document.addEventListener('click', handleOutsideClick);
    // cleanup after unmount
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [ref, isOpen, close]);

  return (
    <div className={`subnavigation ${isOpen ? '' : 'hidden'}`} ref={ref}>
      {children}
    </div>
  );
};

/**
 * Wrapper-component for Subnavigation childs
 */
const SubnavigationItem = ({ onClick, className, disabled, children }) => (
  // eslint-disable-next-line
  <div
    disabled
    className={`subnavigationItem ${className ? className : ''} ${disabled ? 'disabled' : ''}`}
    onClick={() => !disabled && onClick()}
  >
    {children}
  </div>
);

export { SubnavigationItem };

export default Subnavigation;

Subnavigation.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

SubnavigationItem.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

SubnavigationItem.defaultProps = {
  className: undefined,
  onClick: () => {},
};
