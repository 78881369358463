import React from 'react';
import PropTypes from 'prop-types';
import './StorageSpaceLimitModal.scss';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';

const StorageSpaceLimitModal = (props) => {
  const handleClick = () => {
    window.localStorage.setItem('storage-space-limit-notification-accepted', 'true');
    props.closeModal();
  };
  return (
    <div className="StorageSpaceLimitModal">
      <h4>Storage limit exceeded</h4>
      <div className="contentWrapper">
        <ExperimentalButton onClick={handleClick} title="delete files" type="primary">
          ok
        </ExperimentalButton>
      </div>
    </div>
  );
};

export default StorageSpaceLimitModal;

StorageSpaceLimitModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
