import React from 'react';
import styled from 'styled-components';
import { colorGreenPrimary } from '../../styles/variables';
import triangleDown from '../../assets/triangleDown.png';

export const SpeechBubble = () => (
  <Wrapper>
    <Bubble>
      <Text>
        Yemma is free to use for 14 days. Subscribe to Yemma inside the service, read more about
        subscriptions at www.theyemma.com.
      </Text>
    </Bubble>
    <Image src={triangleDown} alt="triangle down" />
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
`;

const Bubble = styled.div`
  min-height: 100px;
  width: 400px;
  background-color: ${colorGreenPrimary};
  border-radius: 5px;
  padding: 10px 20px;
`;

const Text = styled.p`
  color: white;
  font-weight: 600;
`;

const Image = styled.img`
  height: 40px;
  position: absolute;
  bottom: -35px;
  right: 20px;
`;
