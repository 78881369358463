import React from 'react';
import videojs from 'video.js';
import { addTask, addAudioTask, addVideoTask } from '../../../../../../../apiServices/taskApi';
import CrossIcon from '../../../../../../../components/icons/CrossIcon';
import { putToStorageTaskSnapShot } from '../../../../../../../apiServices/storageApiService';
import { getVideoSnapshot } from './NewTaskUtilityFunctions';
import {
  getVideoXYPercent,
  getXYPercent,
} from '../../projectMainViewImage/ProjectMainViewImageUtilityFunctions';
import NotificationContext from '../../../../../../../context/notification';
import './newTask.scss';
import Dot from '../dot/Dot';

import { ConditionalPortal } from '../taskMessage/ConditionalPortal';
import { addTask as addTaskToContext } from '../../../../../../features/project/projectSlice';
import TaskForm from './taskForm';
import VideoTaskForm from './videoTaskForm';
import { lineTypes } from '../../projectMainViewVideoPlayer/arrowsOverlay/utils';
import { connect } from 'react-redux';

class NewTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
    this.wrapperNode = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutside, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside, false);
  };

  handleCloseButtonClick = () => {
    if (this.props.toggleLineType) this.props.toggleLineType(lineTypes.freeLine);
    this.props.updateClickEvent();
  };

  handleClickOutside = (event) => {
    if (this.wrapperNode) {
      if (this.wrapperNode.current) {
        if (!this.wrapperNode.current.contains(event.target)) {
          if (this.props.useCase !== 'audio') {
            this.props.updateClickEvent();
          }
        }
      }
    }
  };

  handleSubmit = async (message, duration, taskType) => {
    this.setState({ isSubmitting: true });
    if (this.props.useCase !== 'video') {
      try {
        if (this.props.useCase === 'image') {
          let coords = this.props.clickEvent.coords || null;
          if (taskType === lineTypes.straightLine || taskType === lineTypes.straightArrow) {
            coords = [coords[0], coords[coords.length - 1]];
          }
          const newTaskData = {
            parent: this.props.resource.parent,
            type: this.props.resource.type,
            index: this.props.newTaskIndex,
            resource: this.props.resource.id,
            message,
            coord_x: this.props.clickEvent.coord.left,
            coord_y: this.props.clickEvent.coord.top,
            taskType: taskType || 'default',
            coords,
          };

          await addTask(newTaskData).then((i) => {
            this.props.addTaskToContext(i.response.Item);
          });
        } else if (this.props.useCase === 'audio') {
          await addAudioTask(
            this.props.resource.parent,
            this.props.resource.type,
            this.props.newTaskIndex,
            this.props.resource.id,
            message,
            this.props.clickEvent.time,
          ).then((res) => {
            this.props.addTaskToContext(res.response.Item);
          });
        }
        this.props.updateClickEvent();

        //this.props.updateTasks();
      } catch (addTaskError) {
        console.log(addTaskError);

        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not add task',
        });
        this.props.updateClickEvent();
      }
    } else if (this.props.useCase === 'video') {
      try {
        const videoPlayerId = `project-main-view-player-${this.props.resource.id}`;
        const snapShot = getVideoSnapshot(videoPlayerId);
        const storageResponse = await putToStorageTaskSnapShot(snapShot);

        try {
          const playerObj = videojs(videoPlayerId);
          const relativeCoord = getVideoXYPercent(
            this.props.clickEvent.coord.left,
            this.props.clickEvent.coord.top,
            playerObj.el().id,
          );
          let coords = this.props.clickEvent.coords || null;
          if (taskType === lineTypes.straightLine || taskType === lineTypes.straightArrow) {
            coords = [coords[0], coords[coords.length - 1]];
          }
          const videoTaskObj = {
            parent: this.props.resource.parent,
            type: this.props.resource.type,
            index: this.props.newTaskIndex,
            resource: this.props.resource.id,
            message,
            coordX: relativeCoord.x,
            coordY: relativeCoord.y,
            timestamp: this.props.clickEvent.time,
            snapshotThumbnailStorage: storageResponse.key,
            taskType: taskType || 'default',
            duration,
            coords,
          };
          await addVideoTask(videoTaskObj).then((res) => {
            this.props.addTaskToContext(res.response.Item);

            this.props.updateClickEvent();
          });
          //this.props.updateClickEvent();
          // this.setState({
          //   isSubmitting: false,
          // });
          //this.props.updateTasks();
        } catch (addTaskError) {
          this.props.updateClickEvent();

          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not add task',
          });
        }
      } catch (putToStorageError) {
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not upload file',
        });
      }
    }
  };

  calculatePosition = (x, y) => {
    let offsetTop;
    let offsetLeft;
    if (y > 66) {
      // align a bit higher
      offsetTop = 10;
    } else if (y < 33) {
      offsetTop = 2;
    } else {
      offsetTop = 5;
    }

    if (x > 50) {
      // align on left side
      offsetLeft = 27;
    } else {
      // align on right side
      offsetLeft = -2;
    }
    return { top: `calc(${y}% - ${offsetTop}rem)`, left: `calc(${x}% - ${offsetLeft}rem)` };
  };

  getTaskMessageStyles = () => {
    switch (this.props.useCase) {
      case 'audio': {
        return {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          margin: 'auto',
        };
      }
      case 'image': {
        const relativeCoord = getXYPercent(
          this.props.clickEvent.coord.left,
          this.props.clickEvent.coord.top,
          'project-mainview-fullscreen-img',
        );

        if (relativeCoord) {
          return this.calculatePosition(relativeCoord.x, relativeCoord.y);
        } else {
          return {
            top: 0,
            left: 0,
          };
        }
      }
      case 'video': {
        const videoPlayerId = `project-main-view-player-${this.props.resource.id}`;
        const playerObj = videojs(videoPlayerId);
        const relativeCoord = getVideoXYPercent(
          this.props.clickEvent.coord.left,
          this.props.clickEvent.coord.top,
          playerObj.el().id,
        );
        return {
          top:
            relativeCoord.y > 50
              ? `calc(${relativeCoord.y}% - 250px)`
              : `calc(${relativeCoord.y}% - 25px)`,
          left:
            relativeCoord.x > 50
              ? `calc(${relativeCoord.x}% - 450px)`
              : `calc(${relativeCoord.x}% + 25px)`,
        };
      }
      default:
        return undefined;
    }
  };

  static contextType = NotificationContext;

  render = () => (
    <ConditionalPortal condition={this.props.videoFullscreen} destination={this.props.destination}>
      <div className="newTask" ref={this.wrapperNode}>
        {(this.props.useCase === 'image' || this.props.useCase === 'video') && (
          <Dot
            index={this.props.newTaskIndex.toString()}
            coord_x={this.props.clickEvent.coord.left}
            coord_y={this.props.clickEvent.coord.top}
            type={this.props.useCase}
            closeNewTask={this.handleCloseButtonClick}
            appearance={this.props.clickEvent?.taskType}
            newTask
          />
        )}
        <div
          className={`modal ${this.props.useCase ? 'videoTaskModal' : ''}`}
          style={this.getTaskMessageStyles()}
        >
          {this.props.useCase !== 'video' && this.props.useCase !== 'image' ? (
            <TaskForm
              handleSubmit={this.handleSubmit}
              isSubmitting={this.state.isSubmitting}
              time={this.props.clickEvent.time}
              useCase={this.props.useCase}
            />
          ) : (
            <VideoTaskForm
              handleSubmit={this.handleSubmit}
              isSubmitting={this.state.isSubmitting}
              time={this.props.clickEvent.time}
              useCase={this.props.useCase}
              type={this.props.clickEvent.taskType}
              toggleLineType={this.props.toggleLineType}
              modifyPlayerCurrentTime={this.props.modifyPlayerCurrentTime}
            />
          )}
          <div className="closeIcon" onClick={this.handleCloseButtonClick} title="close">
            <CrossIcon height="15px" fill="#a3a3a3" />
          </div>
        </div>
      </div>
    </ConditionalPortal>
  );
}

const mapStateToProps = ({ user, project }) => {
  return { currentUser: project.users.find((i) => i.uid === user.uid) };
};

const mapDispatchToProps = {
  addTaskToContext,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTask);
