export const getButtonDisabled = () => {
  if (document.queryCommandSupported) {
    return !document.queryCommandSupported('copy');
  } else {
    return true;
  }
};

export const getIsLoading = (outsideResourceIdsProcess) => {
  if (!outsideResourceIdsProcess) {
    return false;
  } else {
    if (outsideResourceIdsProcess.status === 'loading') {
      return true;
    } else {
      return false;
    }
  }
};

export const getLinkStr = (outsideResourceIdsProcess) => {
  if (outsideResourceIdsProcess.status === 'success') {
    return outsideResourceIdsProcess.data;
  } else {
    return undefined;
  }
};
