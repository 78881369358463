import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { ReactComponent as AllFilesIcon } from '../../../../assets/nitro/allfiles.svg';
import { ReactComponent as Soundwave } from '../../../../assets/soundwave.svg';
import { ReactComponent as DocumentsIcon } from '../../../../assets/nitro/documents.svg';
import { ReactComponent as ImagesIcon } from '../../../../assets/nitro/images.svg';
import { ReactComponent as VideosIcon } from '../../../../assets/nitro/videos.svg';

import './projectFooter.scss';
import { useSelector } from 'react-redux';

const ProjectFooter = ({ active, baseUrl }) => {
  const [fileAmounts, setFileAmounts] = useState({});
  const { items } = useSelector((state) => state.project);
  useEffect(() => {
    if (items) {
      const obj = {};
      obj.all = items.length;
      items.forEach((i) => {
        const type = i.type;
        if (Object.prototype.hasOwnProperty.call(obj, type)) {
          obj[type] = obj[type] + 1;
        } else {
          obj[type] = 1;
        }
      });
      setFileAmounts(obj);
    }
  }, [items]);

  return (
    <div className="projectFooter">
      <ul className="links">
        <li className={`video ${active === 'video' ? 'active' : ''}`}>
          <Link to={`${baseUrl}/video`}>
            <VideosIcon />
            <span className="label">Videos</span>
            <span className="amount">({fileAmounts.video ? fileAmounts.video : 0})</span>
          </Link>
        </li>
        <li className={`audio ${active === 'audio' ? 'active' : ''}`}>
          <Link to={`${baseUrl}/audio`}>
            <Soundwave />
            <span className="label">Audios</span>
            <span className="amount">({fileAmounts.audio ? fileAmounts.audio : 0})</span>
          </Link>
        </li>
        <li className={`image ${active === 'image' ? 'active' : ''}`}>
          <Link to={`${baseUrl}/image`}>
            <ImagesIcon />
            <span className="label">Images</span>
            <span className="amount">({fileAmounts.image ? fileAmounts.image : 0})</span>
          </Link>
        </li>
        <li className={`doc ${active === 'doc' ? 'active' : ''}`}>
          <Link to={`${baseUrl}/doc`}>
            <DocumentsIcon />
            <span className="label">Documents</span>
            <span className="amount">({fileAmounts.doc ? fileAmounts.doc : 0})</span>
          </Link>
        </li>
        <li className={`all ${active === 'allfiles' ? 'active' : ''}`}>
          <Link to={`${baseUrl}/allfiles`}>
            <AllFilesIcon />
            <span className="label">All files</span>
            <span className="amount">({fileAmounts.all ? fileAmounts.all : 0})</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ProjectFooter;
