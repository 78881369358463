import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDown } from '../../assets/nitro/arrow-down.svg';
import './select.scss';

const Select = ({ label, selected, onSelect, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="select" onClick={() => setIsVisible(!isVisible)}>
      <label>{label}</label>
      <div>
        <div className="value">
          {children.find((option) => option.props.value === selected).props.children}
          <ArrowDown className="arrow-down" />
        </div>
        <div className={`dropdown ${isVisible ? 'visible' : ''}`}>
          {children.map((option) => (
            <Option
              key={option.value}
              onClick={() => onSelect(option.props.value)}
              {...option.props}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const Option = ({ children, onClick, value }) => (
  <div key={value} className="option" onClick={onClick}>
    {children}
  </div>
);

export { Select, Option };

Select.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
};

Select.defaultProps = {
  label: '',
};

Option.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
