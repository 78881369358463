import PropTypes from 'prop-types';

export const modalOpenType = PropTypes.oneOf([
  'coverImage',
  'shareFile',
  'newItem',
  'fileUpload',
  'move',
  'copy',
  'rename',
  'delete',
]);

export const useCaseType = PropTypes.oneOf(['link', 'folder', 'project']);
