import React from 'react';
import PropTypes from 'prop-types';

const UploadFolderIcon = (props) => (
  <svg
    version="1.1"
    id="icons"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    height={props.height}
    viewBox="0 0 63.4 55"
    enableBackground="new 0 0 63.4 55"
    xmlSpace="preserve"
  >
    <path
      fill="#E8EFEC"
      d="M9.4,18h34C41.7,7.6,32.7,0,21.8,0C9.8,0,0,9.8,0,21.8C0,29.1,3.9,36,9.4,40V18z"
    />
    <polyline
      fill="none"
      stroke="#43D29E"
      strokeWidth="2"
      strokeMiterlimit="10"
      points="47.4,37 55.4,29 55.4,28 17.6,28 13.4,41"
    />
    <path
      fill="#E4F7EE"
      stroke="#43D29E"
      strokeWidth="2"
      strokeMiterlimit="10"
      d="M30.4,18v-2l-4-4h-21v40c0,1.1,0.9,2,2,2h32.7
	c0.9,0,1.8-0.6,2.4-1.3L58.4,38v-3h-18l-4,3H23.6L10.8,50H9.4V18H30.4z"
    />
    <polyline
      fill="none"
      stroke="#43D29E"
      strokeWidth="2"
      strokeMiterlimit="10"
      points="25.4,18 52.4,18 52.4,19 46.4,28 "
    />
    <g>
      <rect x="50.4" y="8" fill="#43D29E" width="2" height="5" />
      <rect
        x="53.8"
        y="10.6"
        transform="matrix(0.5 -0.866 0.866 0.5 18.0465 54.4131)"
        fill="#43D29E"
        width="4.8"
        height="2"
      />
      <rect
        x="57.3"
        y="14.1"
        transform="matrix(0.866 -0.5 0.5 0.866 0.4301 31.8806)"
        fill="#43D29E"
        width="4.8"
        height="2"
      />
      <rect x="58.4" y="19" fill="#43D29E" width="5" height="2" />
      <rect
        x="45.4"
        y="9.2"
        transform="matrix(0.866 -0.5 0.5 0.866 0.4302 24.7613)"
        fill="#43D29E"
        width="2"
        height="4.8"
      />
    </g>
  </svg>
);

export default UploadFolderIcon;

UploadFolderIcon.propTypes = {
  height: PropTypes.string.isRequired,
};
