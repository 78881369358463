import React, { useEffect, useState } from 'react';
import { formatBytes } from '../../../util/UtilFunctions';
import { getPackageType, getSubscriptionStaticInfo } from '../../../util/subscriptionDetails';
import { getRootWorkspaceDetails } from '../../../apiServices/groupApi';
import './storageSection.scss';

import { API } from 'aws-amplify';

const StorageSection = (props) => {
  const [status, setStatus] = useState('initial');
  const [data, setData] = useState(null);
  const [usage, setUsage] = useState(null);

  useEffect(() => {
    const requestPromise = getRootWorkspaceDetails().then((res) => {
      if (res?.response?.storage) {
        const data = res?.response;
        setUsage({
          used: data?.usedBytes?.N,
          sent: {
            thisMonth: data?.sentBytes?.thisMonth?.N,
            lastMonth: data?.sentBytes?.lastMonth?.N,
          },
          storage: data?.storage * 1000000000,
          dataTransfer: data?.data_transfer * 1000000000,
        });
      }
    });

    return () => {
      API.cancel(requestPromise, 'getUsage cancelled');
    };
  }, [props.rootGroup.id]);

  const getDataTransferLimitExceeded = (bytes) => {
    const packageType = getPackageType(props.currentUserInfo, props.groupsList);
    if (packageType === 'Standard' || packageType === 'Premium') {
      const subscriptionInfo = getSubscriptionStaticInfo(packageType);
      const dataTransferLimitExceeded = bytes > subscriptionInfo.dataTransfer;
      return dataTransferLimitExceeded;
    } else {
      return false;
    }
  };

  const getStorageLimitExceeded = (bytes) => {
    const packageType = getPackageType(props.currentUserInfo, props.groupsList);
    if (packageType === 'Standard' || packageType === 'Premium') {
      const subscriptionInfo = getSubscriptionStaticInfo(packageType);
      const storagelimitExceeded = bytes > subscriptionInfo.maxStorageSpace;
      return storagelimitExceeded;
    } else {
      return false;
    }
  };

  const handleWarningNotificationsDisplay = (response) => {
    const dataTransferLimitExceeded = getDataTransferLimitExceeded(response.sentBytes.thisMonth.N);
    const storageLimitExceeded = getStorageLimitExceeded(response.usedBytes.N);
    if (storageLimitExceeded) {
      const notificationAccepted = window.localStorage.getItem(
        'storage-space-limit-notification-accepted',
      );
      if (notificationAccepted !== 'true') {
        props.updateModalOpen('storageLimit');
      }
    }
    if (dataTransferLimitExceeded) {
      const notificationAccepted = window.localStorage.getItem(
        'data-transfer-limit-notification-accepted',
      );
      if (notificationAccepted !== 'true') {
        props.updateModalOpen('dataTransfer');
      }
    }
  };

  const executeLogic = async () => {
    setStatus('loading');
    try {
      const { response } = await getRootWorkspaceDetails();
      handleWarningNotificationsDisplay(response);
      setData(response);
      setStatus('success');
      props.updateRootWorkspaceDetails(response);
    } catch (error) {
      console.error(error);
      setStatus('error');
    }
  };

  // useEffect(() => {
  //   if (!props.rootWorkspaceDetails) {
  //     executeLogic();
  //   }
  // }, [props.rootWorkspaceDetails, props.updateRootWorkspaceDetails]);

  const getStorageSize = (details) => {
    if (details && details.usedBytes) {
      return formatBytes(details.usedBytes.N);
    } else {
      return undefined;
    }
  };

  const getTransferSize = (details) => {
    if (details && details.sentBytes && details.sentBytes.thisMonth) {
      return formatBytes(details.sentBytes.thisMonth.N);
    } else {
      return undefined;
    }
  };

  const getBarStyle = (bytes, limit) => {
    const percentage = Math.round((bytes / limit) * 100);

    if (percentage < 70) {
      return {
        width: `${percentage}%`,
        backgroundColor: 'green',
      };
    } else if (percentage > 70 && percentage < 100) {
      return {
        width: `${percentage}%`,
        backgroundColor: 'orange',
      };
    } else {
      return {
        width: `${percentage <= 100 ? percentage : 100}%`,
        backgroundColor: 'salmon',
      };
    }
  };

  if (!usage?.storage) return null;

  const dataTransferBarStyle = getBarStyle(usage.sent.thisMonth, usage.dataTransfer || 0);
  const storageBarStyle = getBarStyle(usage.used, usage.storage);

  return (
    <>
      <div className="workspace-details-wrapper">
        <h5 className="title">Storage space</h5>

        <div className="spaceMeter">
          <div className="bar" style={storageBarStyle} />
        </div>
        <div className="info">
          <span>{`${formatBytes(usage.used)} of ${formatBytes(usage.storage)} used`}</span>
        </div>
      </div>
      <div className="workspace-details-wrapper">
        <h5 className="title">Data transfer</h5>
        <div className="spaceMeter">
          <div className="bar" style={dataTransferBarStyle} />
        </div>
        <div className="info">
          <span>
            {`${formatBytes(usage?.sent?.thisMonth)} of ${formatBytes(usage.dataTransfer)} used`}
          </span>
        </div>
      </div>
    </>
  );
};

export default StorageSection;

// StorageSection.propTypes = {
//   rootWorkspaceDetails: PropTypes.shape(workspaceDetailsShape),
//   updateRootWorkspaceDetails: PropTypes.func.isRequired,
//   currentUserCreatorGroup: PropTypes.shape(groupShape).isRequired,
//   updateModalOpen: PropTypes.func.isRequired,
//   currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
//   groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
// };

// StorageSection.defaultProps = {
//   rootWorkspaceDetails: undefined,
// };
