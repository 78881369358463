import React from 'react';
import PropTypes from 'prop-types';
import classes from './ProjectMainViewAddFiles.module.scss';
import { ReactComponent as UploadIcon } from '../../../../../../../assets/nitro/upload.svg';

const ProjectMainViewAddFiles = (props) => (
  <div className={classes.uploadWrapper}>
    <div className={classes.contentWrapper} onClick={() => props.updateModalOpen('fileUpload')}>
      <UploadIcon />
      <h4 className={classes.uploadWrapperText}>Add files</h4>
    </div>
  </div>
);

export default ProjectMainViewAddFiles;

ProjectMainViewAddFiles.propTypes = {
  updateModalOpen: PropTypes.func.isRequired,
};
