import React from 'react';
import PropTypes from 'prop-types';

const ImagesIcon = (props) => {
  const { height, fill } = props;
  return (
    <svg
      version="1.1"
      id="Icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48 37"
      enableBackground="new 0 0 48 37"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={fill}
        d="M48,0v37H0V0H48z M35,4.8c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2 C39.2,6.7,37.3,4.8,35,4.8z M4,33h40v-4l-11.5-8l-6,4l-14-9L4,22V33z"
      />
    </svg>
  );
};

export default ImagesIcon;

ImagesIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

ImagesIcon.defaultProps = {
  height: '0',
};
