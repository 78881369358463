import React from 'react';
import classes from './ProjectPreviewItem.module.scss';
import { ReactComponent as SoundwaveIcon } from '../../../../../assets/soundwave.svg';
import { ReactComponent as VideoIcon } from '../../../../../assets/nitro/videos.svg';
import { ReactComponent as ImageIcon } from '../../../../../assets/nitro/images.svg';
import { FileIconBall } from '../../../../../components/fileIconBall/FileIconBall';

const ProjectPreviewItem = ({ isActive, thumbnailUrl, type, name, onClick, itemtype }) => {
  //const isPdf = this.props.item.mimeType === 'application/pdf';
  return (
    <li
      className={`${classes.wrapper} ${isActive ? classes.active : ''}`}
      onClick={onClick}
      title={name}
    >
      <FileIconBall itemType={itemtype} fileGenre={type} size={12} color="white" />
      {(type === 'image' || type === 'doc') &&
        (thumbnailUrl ? (
          <img src={thumbnailUrl} className={classes.thumbnailImg} alt="preview item" />
        ) : (
          <div className={classes.fallbackThumbnail}>
            <ImageIcon />
          </div>
        ))}
      {type === 'video' &&
        (thumbnailUrl ? (
          <img src={thumbnailUrl} className={classes.thumbnailImg} alt="preview item" />
        ) : (
          <div className={classes.fallbackThumbnail}>
            <VideoIcon />
          </div>
        ))}
      {type === 'audio' && (
        <div className={classes.audioThumbnail}>
          <SoundwaveIcon />
        </div>
      )}
    </li>
  );
};

export default ProjectPreviewItem;
