import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { cutResourceFileName, shouldNotRerender } from './BreadcrumbsUtilFunctions';
import { cutItemTitle } from '../../../util/FitTextIntoWidth';
import { locationShape } from '../../../routerPropTypes';
import './breadcrumbs.scss';

// use memo to prevent unnecessary rerenders
const Breadcrumbs = React.memo((props) => (
  <div className="breadCrumbs">
    <div className="contentWrapper">
      <HomeElement isRootDir={!props.currentItem} />
      {props.currentItem && (
        <>
          <FolderPathList currentItem={props.currentItem} />
          <ExtraElements currentItem={props.currentItem} location={props.location} />
        </>
      )}
    </div>
  </div>
));

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  // router props
  location: PropTypes.shape(locationShape).isRequired,

  // app.jsx props
  currentItem: PropTypes.object,
};

Breadcrumbs.defaultProps = {
  currentItem: undefined,
};

const getFileTypeStr = (fileType) => {
  switch (fileType) {
    case 'video':
      return 'Videos';
    case 'audio':
      return 'Audios';
    case 'image':
      return 'Images';
    case 'doc':
      return 'Documents';
    case 'allfiles':
      return 'All files';
    default:
      return '';
  }
};

const getPassiveElement = (currentItem, location) => {
  const urlItems = location.pathname.split('/');
  if (currentItem) {
    const itemType = currentItem.itemtype?.S || currentItem.itemtype;
    const urlLastItem = urlItems[urlItems.length - 1];

    if (itemType === 'project') {
      if (
        urlLastItem === 'video' ||
        urlLastItem === 'audio' ||
        urlLastItem === 'image' ||
        urlLastItem === 'doc' ||
        urlLastItem === 'allfiles'
      ) {
        return getFileTypeStr(urlLastItem);
      }
    } else if (itemType === 'account') {
      if (urlLastItem === 'profile' || urlLastItem === 'workspace') {
        return urlLastItem;
      }
    }
  }
  return undefined;
};

const getViewTypeElem = (location) => {
  const urlItems = location.pathname.split('/');

  const urlLastItem = urlItems[urlItems.length - 1];

  if (
    urlLastItem === 'video' ||
    urlLastItem === 'audio' ||
    urlLastItem === 'image' ||
    urlLastItem === 'doc' ||
    urlLastItem === 'allfiles'
  ) {
    return urlLastItem;
  }

  return undefined;
};

const getActiveElement = (currentItem) => {
  let breadCrumbActiveElement;

  const itemType = currentItem?.itemtype?.S || currentItem?.itemtype;
  if (currentItem) {
    if (itemType === 'resource') {
      breadCrumbActiveElement = currentItem?.name?.S || currentItem.name;
    } else {
      breadCrumbActiveElement =
        currentItem?.title?.S || currentItem?.title?.N || currentItem?.title;
    }
    if (itemType === 'project') {
      breadCrumbActiveElement = cutItemTitle(breadCrumbActiveElement, 180);
    } else if (itemType === 'folder') {
      breadCrumbActiveElement = cutItemTitle(breadCrumbActiveElement, 180);
    } else if (itemType === 'account') {
      breadCrumbActiveElement = cutItemTitle(breadCrumbActiveElement, 180);
    } else if (itemType === 'resource') {
      breadCrumbActiveElement = cutResourceFileName(breadCrumbActiveElement, 180);
    }
  }
  return breadCrumbActiveElement;
};

const getResourceViewType = (location) => {
  const viewTypeParam = location.pathname.split('&')[1];

  if (viewTypeParam) {
    // eslint-disable-next-line
    return viewTypeParam.split('=')[1];
  }
  return undefined;
};

const HomeElement = ({ isRootDir }) => (
  <>
    <Link to="/in/folder/root" className={`link ${isRootDir ? 'rootDir' : ''}`}>
      <h4>Home</h4>
    </Link>
    {!isRootDir && (
      <span className="circle">
        <Circle />
      </span>
    )}
  </>
);

HomeElement.propTypes = {
  isRootDir: PropTypes.bool.isRequired,
};

const renderFolders = (breadcrumb) => {
  let arr = [...breadcrumb];
  // hide elements from middle in long folder-lists
  if (breadcrumb.length > 2) {
    arr = [breadcrumb[0], {}, breadcrumb[breadcrumb.length - 1]];
  }
  return arr.map(({ itemtype, id, title }) => {
    if (!id) {
      return (
        <Fragment key={id}>
          <span className="link placeholder">
            <h4>...</h4>
          </span>
          <span className="circle">
            <Circle />
          </span>
        </Fragment>
      );
    }
    return (
      <Fragment key={id}>
        <Link to={`/in/${itemtype}/${id}`} className="link folder">
          <h4>{cutItemTitle(title, 180)}</h4>
        </Link>
        <span className="circle">
          <Circle />
        </span>
      </Fragment>
    );
  });
};

/* folder path list received from endpoint, for example folder1/folder2/.. */
const FolderPathList = (props) => (
  <>
    {props.currentItem.breadcrumb && (
      <>{props.currentItem.breadcrumb.length > 0 && renderFolders(props.currentItem.breadcrumb)}</>
    )}
  </>
);

FolderPathList.propTypes = {
  currentItem: PropTypes.object.isRequired,
};

const ExtraElements = (props) => {
  const breadCrumbActiveElement = getActiveElement(props.currentItem);
  const breadCrumbPassiveElement = getPassiveElement(props.currentItem, props.location);
  const urlItems = props.location.pathname.split('/');
  const urlLastItem = urlItems[urlItems.length - 1];
  const itemtype = props.currentItem.itemtype.S || props.currentItem.itemtype;
  const type = props.currentItem.type?.S || props.currentItem.type;
  const id = props.currentItem.id.S || props.currentItem.id;
  const breadcrumbs = props.currentItem.breadcrumb;
  const viewType = getViewTypeElem(props.location);
  return (
    <>
      {itemtype === 'resource' && (
        <>
          {breadcrumbs?.[breadcrumbs.length - 1].itemtype === 'project' && (
            <>
              <Link
                to={`/in/project/${breadcrumbs?.[breadcrumbs.length - 1].id}/${
                  viewType ? viewType : type
                }`}
                className="link project"
              >
                <h4>{viewType ? getFileTypeStr(viewType) : type}</h4>
              </Link>
              {/* eslint-disable-next-line */}
              <span className="circle">
                <Circle />
              </span>
            </>
          )}
        </>
      )}
      {itemtype !== 'resource' && itemtype !== 'account' && (
        <Link to={`/in/${itemtype}/${id}`} className="link resource">
          <h4>{breadCrumbActiveElement}</h4>
        </Link>
      )}

      {
        // I think this one is passive since it doesn't have a link-tag
        (itemtype === 'resource' || itemtype === 'account') && (
          <span className="link passive">
            <h4>{breadCrumbActiveElement}</h4>
          </span>
        )
      }
      {breadCrumbPassiveElement && (
        <>
          <span className="circle">
            <Circle />
          </span>
          <Link to={`/in/${itemtype}/${id}/${urlLastItem}`} className="link resource">
            <h4>{breadCrumbPassiveElement}</h4>
          </Link>
        </>
      )}
    </>
  );
};

ExtraElements.propTypes = {
  // router props
  location: PropTypes.shape(locationShape).isRequired,

  // app.jsx props
  // currentItem is a hotfix, refer to ./README.md
  currentItem: PropTypes.object.isRequired,
};

const Circle = () => (
  <svg height="20" width="20">
    <circle cx="10" cy="10" r="3" />
  </svg>
);
