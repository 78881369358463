import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { updateWorkspaceName } from '../../../apiServices/groupApi';
import { renameItem } from '../../../apiServices/projectApi';
import { updateResourceName } from '../../../apiServices/resourceApi';
import { updateUserName } from '../../../apiServices/userApi';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import TextInput from '../../inputs/textInput';
import { getExtFromFileName } from '../../../util/UtilFunctions';
import { groupShape } from '../../../app/AppPropTypes';
import NotificationContext from '../../../context/notification';
import { borderRadiusDefault, colorGreyDark, colorRedSecondary } from '../../../styles/variables';

/**
 * Modal content that handles changing some value with one text-input
 * used in ItemListModals.jsx, WorkspaceModals.jsx, ProfileModals.jsx
 */
const RenameModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const { add } = React.useContext(NotificationContext);

  const itemType = props.item?.itemtype.S || props.item?.itemtype;

  const rename = async () => {
    if (value.length > 0) {
      setError('');
      try {
        setLoading(true);
        props.updateIsQuerying(true);
        if (props.workspace) {
          await updateWorkspaceName(props.workspace.id.S || props.workspace.id, value);
        } else if (itemType === 'resource') {
          await updateResourceName(
            props.item.id.S || props.item.id,
            `${value}.${getExtFromFileName(props.item.name)}`,
          );
        } else if (itemType === 'folder' || itemType === 'project') {
          await renameItem(props.item.id, value);
        } else if (itemType === 'lastname') {
          const firstname = props.item.cognitoAttributes.UserAttributes[0].Value;
          await updateUserName(firstname, value);
          props.updateCurrentUserInfo();
        } else if (itemType === 'firstname') {
          const lastname = props.item.cognitoAttributes.UserAttributes[1].Value;
          await updateUserName(value, lastname);
          props.updateCurrentUserInfo();
        }
        // refresh list if function is provided
        if (props.refreshList) {
          props.refreshList();
        }
        props.updateIsQuerying(false);
        setLoading(false);
        add({
          id: Date.now(),
          type: 'success',
          message: 'Item title updated',
        });
        props.closeModal();
      } catch (renameError) {
        props.updateIsQuerying(false);
        setLoading(false);
        add({
          id: Date.now(),
          type: 'error',
          message: 'Item title could not be updated',
        });
      }
    } else {
      setError('Name cannot be empty');
    }
  };

  return (
    <Wrapper>
      <h4>{props.title}</h4>
      <ContentWrapper>
        <InputWrapper>
          <TextInput
            placeholder={props.workspace ? 'New name' : 'New name'}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoFocus
          />
          {props.item?.itemtype === 'resource' && (
            <span>{getExtFromFileName(props.item.name)}</span>
          )}
          <p>{error}</p>
        </InputWrapper>
        <ExperimentalButton onClick={rename} loading={loading}>
          Rename
        </ExperimentalButton>
      </ContentWrapper>
    </Wrapper>
  );
};

export default RenameModal;

const Wrapper = styled.div`
  position: relative;
  border-radius: ${borderRadiusDefault};
  width: 100%;
  height: 100%;

  h4 {
    text-align: center;
    font-size: 1.3em;
    margin: 0 0 1rem 0;
    span {
      color: ${colorGreyDark};
    }
  }

  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin: 2rem auto;
  }
`;

const InputWrapper = styled.div`
  margin: 2rem 0;
  position: relative;
  span {
    position: absolute;
    right: -2.6rem;
    top: 5px;
    font-size: 0.9rem;
    color: ${colorGreyDark};
  }
  p {
    color: ${colorRedSecondary};
    font-size: 0.8rem;
    margin: 0.5rem 0;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

RenameModal.propTypes = {
  updateIsQuerying: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  workspace: PropTypes.shape(groupShape),
  refreshList: PropTypes.func,
  updateCurrentUserInfo: PropTypes.func,
};

RenameModal.defaultProps = {
  refreshList: undefined,
  updateCurrentUserInfo: undefined,
  workspace: undefined,
};
