import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateProjectModal } from '../../app/features/project/projectSlice';
import ProfileBall from '../profileBall/ProfileBall';
import './usersList.scss';
import { ReactComponent as CrossIcon } from '../../assets/nitro/cross.svg';
import NotificationContext from '../../context/notification';
import { notifyProjectParticipants } from '../../apiServices/messageApi';
import { ConfirmModal } from '../modals/confirmModal/ConfirmModal';
import { ReactComponent as Bell } from '../../assets/bell3.svg';
import { colorGreenPrimary, colorGreenPrimaryDark } from '../../styles/variables';

const getProfilePictureList = (usersList) => {
  return usersList.map((person) => (
    <ProfileBall
      key={`${person.given_name} ${person.family_name}`}
      imageUrl={person.img}
      firstName={person.given_name}
      lastName={person.family_name}
      title={name}
    />
  ));
};

// this is the main component
const UsersList = () => {
  const [surplus, setSurplus] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { add } = useContext(NotificationContext);
  const { users, invitedUsers, error, loading, modalOpen, projectId } = useSelector(
    ({ project }) => project,
  );
  const dispatch = useDispatch();
  // count surplus if there is more than 5 users
  useEffect(() => {
    if (users?.length > 5) {
      setSurplus(users?.length - 5);
    }
  }, [users]);

  useEffect(() => {
    if (error) {
      add({
        type: 'error',
        message: 'Error while fetching project participants',
      });
    }
  }, [error]);

  const toggleModal = () => {
    if (!error) {
      dispatch(updateProjectModal(!modalOpen));
    }
  };

  const renderLoadingView = () => (
    <div className="contributorsWrapper" disabled type="button">
      <ul className="peopleBallList inactive">
        <div className="addBall inactive" title="Add more users">
          <CrossIcon />
        </div>
        {['g!', 'in', 'ad', 'lo'].map((i) => (
          <ProfileBall key={i} firstName={`${i[0]}`} lastName={`${i[1]}`} />
        ))}
      </ul>
    </div>
  );

  const handleNotificationBellClick = async () => {
    setConfirmModalOpen(true);
  };

  const renderUsersView = () => (
    <div className="contributorsWrapper" onClick={toggleModal}>
      <ul className="peopleBallList">
        <div className="addBall" title="Add more users">
          <CrossIcon />
        </div>
        {surplus && <div className="surplus">+{surplus}</div>}
        {getProfilePictureList(surplus ? users.slice(0, 5) : users)}
      </ul>
    </div>
  );

  return (
    <div className="usersList">
      {(loading || !users) && renderLoadingView()}
      {!!users?.length && !loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {renderUsersView()}
          <ButtonWrapper>
            <Button onClick={handleNotificationBellClick} title="send reminder" type="button">
              <Bell />
            </Button>
          </ButtonWrapper>
        </div>
      )}
      {confirmModalOpen && (
        <div className="confirmModalBackground">
          <div className="modalContent">
            <div className="header">
              <button onClick={() => setConfirmModalOpen(false)} title="close modal" type="button">
                <CrossIcon />
              </button>
            </div>
            <ConfirmModal
              updateIsQuerying={() => {}}
              closeModal={() => setConfirmModalOpen(false)}
              executeAction={() => notifyProjectParticipants(projectId)}
              headerText="Send reminder to project participants?"
              successText="Reminder sent to project participants"
              errorText="Could not send reminder to project participants"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersList;

UsersList.propTypes = {};

UsersList.defaultProps = {};

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 30px;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  svg {
    width: 40px;
    height: 40px;
    circle {
      transition: all 0.2s linear;
      fill: ${colorGreenPrimary};
    }
    transition: all 0.2s linear;
    &:hover {
      circle {
        fill: ${colorGreenPrimaryDark};
      }
    }
  }
`;
