import React from 'react';
import PropTypes from 'prop-types';
import './ProfileModals.scss';
import SetPictureModal from '../../../../../components/modals/setPictureModal/SetPictureModal';
import { currentUserInfoShape } from '../../../../../apiServices/userApiTypes';
import { ReactComponent as CrossIcon } from '../../../../../assets/nitro/cross.svg';
import RenameModal from '../../../../../components/modals/renameModal/renameModal';
import UpdateEmailModal from '../../../../../components/modals/updateEmailModal/UpdateEmailModal';

const ProfileModals = (props) => (
  <div
    className={`profileModals ${props.modalOpen ? '' : 'closed'} ${
      props.modalOpen ? props.modalOpen : ''
    }`}
  >
    <div className="modalContent">
      <div className="header">
        <button onClick={() => props.handleCloseModalClick()} title="close modal" type="button">
          <CrossIcon />
        </button>
      </div>
      {props.modalOpen === 'uploadProfile' && (
        <SetPictureModal
          currentUserInfo={props.currentUserInfo}
          closeModal={props.handleCloseModalClick}
          useCase="profile"
          updateCurrentUserInfo={props.updateCurrentUserInfo}
          updateIsQuerying={props.updateIsQuerying}
          hasImage={
            props.currentUserInfo.image !== undefined &&
            props.currentUserInfo.image.S !== 'no-image'
          }
          getWorkspacePeopleList={props.getWorkspacePeopleList}
        />
      )}
      {props.modalOpen === 'updateEmail' && (
        <UpdateEmailModal
          closeModal={props.handleCloseModalClick}
          updateIsQuerying={props.updateIsQuerying}
          updateCurrentUserInfo={props.updateCurrentUserInfo}
        />
      )}
      {props.modalOpen === 'updateFirstname' && (
        <RenameModal
          closeModal={props.handleCloseModalClick}
          updateIsQuerying={props.updateIsQuerying}
          updateCurrentUserInfo={props.updateCurrentUserInfo}
          item={{ ...props.currentUserInfo, itemtype: { S: 'firstname' } }}
          title="Update first name"
          placeholder="New first name"
          getWorkspacePeopleList={props.getWorkspacePeopleList}
        />
      )}
      {props.modalOpen === 'updateLastname' && (
        <RenameModal
          closeModal={props.handleCloseModalClick}
          updateIsQuerying={props.updateIsQuerying}
          updateCurrentUserInfo={props.updateCurrentUserInfo}
          item={{ ...props.currentUserInfo, itemtype: { S: 'lastname' } }}
          title="Update surname"
          placeholder="New surname"
          getWorkspacePeopleList={props.getWorkspacePeopleList}
        />
      )}
    </div>
  </div>
);

export default ProfileModals;

ProfileModals.propTypes = {
  // app.jsx props
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  updateCurrentUserInfo: PropTypes.func.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  getWorkspacePeopleList: PropTypes.func.isRequired,

  // AccountSettingsProfile.jsx props
  modalOpen: PropTypes.string.isRequired,
  handleCloseModalClick: PropTypes.func.isRequired,
};
