import React from 'react';
import Message from '../../message/Message';
import './taskMessageList.scss';

const TaskMessagesList = ({ messages }) => {
  return (
    <ul className="taskMessageList">
      {messages?.map((message, index) => {
        return (
          <Message
            key={message.created}
            firstName={message.userData?.given_name}
            lastName={message.userData?.family_name}
            created={message.created}
            content={message.content}
            image={{ task: false, imageUrl: message.userData?.img }}
            isAReply={index !== 0}
            isRemovable={false}
          />
        );
      })}
    </ul>
  );
};

export default TaskMessagesList;
