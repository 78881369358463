import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGuestUser, setGuestUsername } from '../../../apiServices/projectApi';
import ExperimentalButton from '../../../components/buttons/experimentalButton/experimentalButton';
import TextInput from '../../../components/inputs/textInput';
import Loader from '../../../components/loader/loader';
import {
  setItems,
  setMessages,
  setProjectMetadata,
  setTasks,
  updateProjectUsers,
  updateUserRole,
  addUser,
} from '../../features/project/projectSlice';
import { setGuestUser } from '../../features/user/userSlice';
import './guestProject.scss';
import MainViewLoading from './mainViewLoading/MainViewLoading';
import ProjectMainView from './projectMainView/ProjectMainView';

const GuestProject = (props) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [initFirstname, setInitFirstname] = useState('');
  const [initLastname, setInitLastname] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { details, title } = useSelector((state) => state.project);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    // If user is logged in, log them out since they are at guest page
    if (props.isAuthenticated) props.handleLogout(true);
  }, [props.isAuthenticated]);
  useEffect(() => {
    getGuestUser(props.match.params.id)
      .then((res) => {
        const [userR, usersR, projectR, messagesR, tasksR, resourcesR] = res;

        if (userR.status === 'rejected') {
          throw new userR.reason();
        }

        dispatch(updateUserRole('guest'));

        if (userR.status === 'fulfilled') {
          //console.log('guestuser', userR.value.response.Item);
          //const {email, id, given_name, family_name} = user.value.response.Item;
          dispatch(setGuestUser(userR.value.response.Item));
        }
        if (usersR.status === 'fulfilled') {
          //console.log('users', usersR.value.response.Items);
          dispatch(updateProjectUsers(usersR.value.response.Items));
        }
        if (projectR.status === 'fulfilled') {
          dispatch(
            setProjectMetadata({
              ...projectR.value.response.Item,
              guestHash: props.match.params.id,
            }),
          );
        }
        if (messagesR.status === 'fulfilled') {
          dispatch(setMessages(messagesR.value.response.Items));
        }
        if (tasksR.status === 'fulfilled') {
          dispatch(setTasks(tasksR.value.response.Items));
        }
        if (resourcesR.status === 'fulfilled') {
          dispatch(setItems(resourcesR.value.response.Items));
        }

        props.updateCurrentItem(details);

        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError('Link is invalid');
        setLoading(false);
      });
  }, []);

  const submitUserDetails = async () => {
    if (initFirstname.length && initLastname.length && !submitting) {
      setSubmitting(true);
      const res = await setGuestUsername(props.match.params.id, initFirstname, initLastname);

      if (res.response?.Item) {
        const newUser = res.response.Item;
        dispatch(
          addUser({
            given_name: newUser.firstname,
            family_name: newUser.lastname,
            uid: user.uid,
            email: newUser.email,
            role: 'guest',
          }),
        );
        dispatch(setGuestUser(res.response.Item));
      }
      setSubmitting(false);
    }
  };

  if (loading)
    return (
      <div className="loading">
        <MainViewLoading previewListItemsAmount={13} />
      </div>
    );
  if (error) {
    return (
      <div>
        <h1>{error}</h1>
      </div>
    );
  }
  // User visits page first time, needs to pass names
  if (!user.given_name) {
    return (
      <div className="registerGuest">
        <div className="modal">
          <h2>Please, add you name below and you´re ready to join the space {title}</h2>
          <div className="inputWrapper">
            <TextInput
              type="text"
              value={initFirstname}
              onChange={(e) => setInitFirstname(e.target.value)}
              placeholder=" "
              id="firstname"
              autoFocus
            />
            <label htmlFor="firstname">Firstname</label>
          </div>
          <div className="inputWrapper">
            <TextInput
              type="text"
              value={initLastname}
              onChange={(e) => setInitLastname(e.target.value)}
              placeholder=" "
              id="lastname"
            />
            <label htmlFor="lastname">Lastname</label>
          </div>
          <ExperimentalButton loading={submitting} onClick={submitUserDetails}>
            Submit
          </ExperimentalButton>
        </div>
      </div>
    );
  }

  return (
    <div className="guestProject">
      <h1>{title}</h1>
      <ProjectMainView
        getProjectFileListProcess={() => {}}
        getProjectProcess={() => {}}
        getProjectFileList={() => {}}
        match={props.match}
        currentUserGroup={details.group}
        history={props.history}
        updateIsQuerying={() => {}}
      />
    </div>
  );
};

export default GuestProject;

// https://dev.theyemma.com/guest/<käyttäjän-hash>
// Public group API endpointit:
// GET project
// https://api.dev.theyemma.com/guest/project/<käyttäjän-hash>
// GET messages
// https://api.dev.theyemma.com/guest/message/<käyttäjän-hash>
// GET tasks
// https://api.dev.theyemma.com/guest/task/<käyttäjän-hash>
// GET user
// https://api.dev.theyemma.com/guest/user/<käyttäjän-hash>
// Toi user endpoint antaa käyttäjän emailin ja nicknamen. Jos nickname puuttuu, niin frontin pitäisi aukaista modaali, johon voi syöttää nimen ja lähettää se sitten parametrinä samaan endpointtin: ?nickname=jamesbond
// Bäkkäri tallentaa sen käyttäjälle. /guest/user/{hash}-endpointiin kun laittaa parametrit:
//firstname=joku&lastname=joku
