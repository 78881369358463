import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './searchBar/SearchBar';
import Messages from './messages/Messages';
import Workspaces from './workspaces/Workspaces';
import UserDetails from './userDetails/UserDetails';
import './navigationBar.scss';
import { getActiveWorkspace, getIsRootWorkspace } from '../../app/AppUtilityFunctions';
import { getAllMessages, getMessagesForCurrentUserGroup } from './navigationBarUtils';
import { useSelector } from 'react-redux';

const NavigationBar = (props) => {
  const [subnavContent, setSubnavContent] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [currentGroupMessages, setCurrentGroupMessages] = useState([]);
  const { userRole } = useSelector((state) => state.project);
  const { createdGroup } = useSelector((state) => state.workspace);

  // new function
  // UserDetails and workspaces already use
  const updateSubnav = (value) => {
    if (subnavContent === value) {
      setSubnavContent(false);
    } else {
      setSubnavContent(value);
    }
  };

  function getNotifications() {
    setTimeout(() => {
      props.isAuthenticated &&
        Promise.all([getAllMessages(), getMessagesForCurrentUserGroup()]).then(([i, j]) => {
          setAllMessages(i);
          setCurrentGroupMessages(j);
        });
    }, 2000);
  }

  useEffect(() => {
    getNotifications();
  }, [props.currentUserGroup, props.pathname]);

  useEffect(() => {
    const interval = setInterval(getNotifications, 15000);
    return () => clearInterval(interval);
  }, []);

  const shouldRender = useMemo(
    () => props.isAuthenticated && props.currentUserInfo,
    [props.isAuthenticated, props.currentUserInfo],
  );

  const isRootWorkspace = useMemo(() => {
    const activeWorkspace = getActiveWorkspace(props.groupsList, props.currentUserGroup);
    return getIsRootWorkspace(activeWorkspace);
  }, [props.groupsList, props.currentUserGroup]);

  const hasWorkspaces = props.groupsList && props.groupsList.length > 0;
  return (
    <div className="navigationBar">
      {props.isAuthenticated && (
        <>
          {isRootWorkspace && <span className="iconWrapper" />}
          {!isRootWorkspace && (
            <Link to={props.isAuthenticated ? '/in/folder/root' : '/'} className="iconWrapper" />
          )}
        </>
      )}
      {!props.isAuthenticated && (
        <a
          href="https://www.theyemma.com/"
          title="https://www.theyemma.com/"
          className="iconWrapper"
        >
          <div />
        </a>
      )}
      {shouldRender && (
        <>
          {hasWorkspaces && !isRootWorkspace && (
            <>
              <SearchBar
                updateIsQuerying={props.updateIsQuerying}
                openSubnav={() => setSubnavContent('search')}
                closeSubnav={() => setSubnavContent(false)}
                isSubnavOpen={subnavContent === 'search'}
                history={props.history}
              />
              <Messages
                pathname={props.pathname}
                history={props.history}
                updateSubnav={() => updateSubnav('messages')}
                isSubnavOpen={subnavContent === 'messages'}
                messages={currentGroupMessages}
              />
            </>
          )}
          {hasWorkspaces && (
            <Workspaces
              currentUserGroup={props.currentUserGroup}
              groupsList={props.groupsList}
              initiateSession={props.initiateSession}
              history={props.history}
              updateSubnav={() => updateSubnav('workspaces')}
              isSubnavOpen={subnavContent === 'workspaces'}
              pathname={props.pathname}
              notifications={allMessages || []}
            />
          )}
          {props.currentUserInfo.cognitoAttributes && (
            <UserDetails
              handleLogout={props.handleLogout}
              currentUserInfo={props.currentUserInfo}
              updateCurrentUserInfo={props.updateCurrentUserInfo}
              updateRootWorkspaceDetails={props.updateRootWorkspaceDetails}
              rootWorkspaceDetails={props.rootWorkspaceDetails}
              groupsList={props.groupsList}
              history={props.history}
              updateSubnav={() => updateSubnav('userDetails')}
              isSubnavOpen={subnavContent === 'userDetails'}
              currentUserGroup={props.currentUserGroup}
              updateModalOpen={props.updateModalOpen}
            />
          )}
        </>
      )}
      {!props.isAuthenticated && userRole !== 'guest' && (
        <div className="register">
          <Link to="/signup" className="link">
            Sign up
          </Link>
          <Link to="/login" className="link">
            Login
          </Link>
        </div>
      )}
    </div>
  );
};

export default NavigationBar;

// NavigationBar.propTypes = {
//   isAuthenticated: PropTypes.bool.isRequired,
//   handleLogout: PropTypes.func.isRequired,
//   groupsList: PropTypes.arrayOf(PropTypes.object),
//   currentUserGroup: PropTypes.string,
//   currentUserInfo: PropTypes.shape(currentUserInfoShape),
//   initiateSession: PropTypes.func.isRequired,
//   updateIsQuerying: PropTypes.func.isRequired,
//   updateCurrentUserInfo: PropTypes.func.isRequired,
//   history: PropTypes.shape(historyShape).isRequired,
//   pathname: PropTypes.string.isRequired,
//   updateRootWorkspaceDetails: PropTypes.func.isRequired,
//   rootWorkspaceDetails: PropTypes.shape(workspaceDetailsShape),
//   updateModalOpen: PropTypes.func.isRequired,
// };

// NavigationBar.defaultProps = {
//   currentUserInfo: undefined,
//   groupsList: undefined,
//   currentUserGroup: undefined,
//   rootWorkspaceDetails: undefined,
// };
