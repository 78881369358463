import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '../../../../inputs/checkbox/Checkbox';
import './RadioButtonsItem.scss';

export const RadioButtonsItem = (props) => (
  <li className="checkbox-radio-button-wrapper">
    <Checkbox
      checked={props.activeRadio === props.type}
      type={props.type}
      handleRadioChange={props.handleRadioChange}
    />
    <h5 className="checkbox-label-text">{props.type}</h5>
  </li>
);

RadioButtonsItem.propTypes = {
  activeRadio: PropTypes.string.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['project', 'folder']).isRequired,
};
