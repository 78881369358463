/* eslint-disable */

import React, { Component } from 'react';

// create context to share data between components
const NotificationContext = React.createContext(null);

// create AlertProvider
class NotificationProvider extends Component {
  constructor() {
    super();
    this.state = {
      notifications: [],
      add: (notification) => {
        const notifications = [...this.state.notifications];
        notifications.push(notification);
        this.setState({ notifications });
      },
      remove: (id) => {
        const notifications = this.state.notifications.filter(
          (notification) => notification.id !== id,
        );
        this.setState({ notifications });
      },
    };
  }
  render() {
    const { children } = this.props;
    return (
      <NotificationContext.Provider value={this.state}>{children}</NotificationContext.Provider>
    );
  }
}

export default NotificationContext;

export { NotificationProvider };
