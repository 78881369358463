import React from 'react';
import { Redirect } from 'react-router-dom';
import classes from './UnAuthenticatedHome.module.css';

const UnauthenticatedHome = () => (
  <div className={classes.wrapper}>
    <h1>Home</h1>
    <Redirect to="/login" />
  </div>
);
export default UnauthenticatedHome;
