export const getAvailableRoles = (currentUserRoleInThisWorkspace, parent) => {
  if (parent === 'root') {
    return ['admin'];
  } else if (currentUserRoleInThisWorkspace === 'admin') {
    return ['viewer', 'user', 'pro'];
  } else if (currentUserRoleInThisWorkspace === 'pro') {
    return ['viewer', 'user'];
  } else {
    return ['viewer'];
  }
};
