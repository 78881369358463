import React from 'react';
import ProjectIcon from '../../../components/icons/ProjectIcon';
import UploadFolderIcon from '../../../components/icons/UploadFolderIcon';
import NewItemModal from '../../../components/modals/newItemModal/NewItemModal';
import ErrorBoundary from '../../errorBoundary/ErrorBoundary';
import { ReactComponent as CrossIcon } from '../../../assets/nitro/cross.svg';
import './WelcomeScreen.scss';

class WelcomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: undefined,
    };
    this.modalContent = React.createRef(null);
  }

  updateModalOpen = (item) => {
    this.setState({ modalOpen: item });
  };

  handleClick = (event) => {
    if (this.modalContent.current) {
      if (!this.modalContent.current.contains(event.target)) {
        this.updateModalOpen();
      }
    }
  };

  getWorkspaceListCurrentUser = (workspacePeopleList, currentUserInfo) => {
    if (workspacePeopleList) {
      return workspacePeopleList.find((item) => {
        if (currentUserInfo && item.user.S === currentUserInfo.user.S) {
          return true;
        } else {
          return undefined;
        }
      });
    } else {
      return undefined;
    }
  };

  render = () => {
    const workspaceListCurrentUser = this.getWorkspaceListCurrentUser(
      this.props.workspacePeopleList,
      this.props.currentUserInfo,
    );
    const currentUserIsProOrAdmin =
      workspaceListCurrentUser &&
      (workspaceListCurrentUser.role.S === 'admin' || workspaceListCurrentUser.role.S === 'pro');
    return (
      <ErrorBoundary>
        <div className="welcome-screen-wrapper">
          <div className="itemWrapper" onClick={() => this.updateModalOpen('newFolder')}>
            <h4 className="headline">Create your first folder</h4>
            <p className="text">Create a folder to store all your digital stuff.</p>
            <div className="iconWrapper">
              <UploadFolderIcon height="100px" />
            </div>
          </div>
          {currentUserIsProOrAdmin && (
            <div className="itemWrapper" onClick={() => this.updateModalOpen('newProject')}>
              <h4 className="headline">Create your first project</h4>
              <p className="text">Create a project and start working together with people.</p>
              <div className="iconWrapper">
                <ProjectIcon height="100px" fill="#43D29E" />
              </div>
            </div>
          )}
        </div>
        {(this.state.modalOpen === 'newFolder' || this.state.modalOpen === 'newProject') && (
          <div
            className={`welcomeScreenModal ${this.state.modalOpen ? '' : 'closed'}`}
            onClick={this.handleClick}
          >
            <div className="modalContent" ref={this.modalContent}>
              <div className="header">
                <button onClick={this.updateModalOpen} title="close modal" type="button">
                  <CrossIcon />
                </button>
              </div>
              <NewItemModal
                closeModal={this.updateModalOpen}
                currentUserGroup={this.props.currentUserGroup}
                history={this.props.history}
                updateIsQuerying={this.props.updateIsQuerying}
                location={this.props.location}
                currentUserInfo={this.props.currentUserInfo}
                welcomeScreenSelection={
                  this.state.modalOpen === 'newProject' ? 'project' : 'folder'
                }
                refreshList={() => {}}
              />
            </div>
          </div>
        )}
      </ErrorBoundary>
    );
  };
}

export default WelcomeScreen;

// WelcomeScreen.propTypes = {
//   // router props
//   history: PropTypes.shape(historyShape).isRequired,
//   location: PropTypes.shape(locationShape).isRequired,

//   // App.jsx props
//   currentUserGroup: PropTypes.string.isRequired,
//   updateIsQuerying: PropTypes.func.isRequired,
//   currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
//   workspacePeopleList: PropTypes.arrayOf(PropTypes.shape(workspaceParticipantShape)).isRequired,
// };
