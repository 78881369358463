import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { groupShape } from '../../../../AppPropTypes';
import { currentUserInfoShape } from '../../../../../apiServices/userApiTypes';
import DetailsSection from './detailsSection/DetailsSection';
import { currentUserIsAdminOfRootGroup } from '../../../../../util/UtilFunctions';
import { getPackageType } from '../../../../../util/subscriptionDetails';
import { workspaceDetailsShape } from '../../../../../apiServices/group/GroupApiTypes';
import {
  Wrapper,
  ContentWrapper,
  Section,
  Currentvalue,
  Header,
} from '../generalInfoSection/GeneralInfoSection';

const CompanyInfoSection = ({
  currentUserInfo,
  groupsList,
  updateRootWorkspaceDetails,
  rootWorkspaceDetails,
  currentUserCreatorGroup,
}) => {
  // whether to show storageSection
  // eslint-disable-next-line
  const [showStorageSection, setShowStorageSection] = useState(() => (
    currentUserIsAdminOfRootGroup(currentUserInfo, groupsList)));

  const packageType = getPackageType(currentUserInfo, groupsList);
  return (
    <Wrapper>
      <Header>
        <h4>Company information</h4>
      </Header>
      <ContentWrapper>
        <Section>
          <h5>Owner company</h5>
          <Currentvalue>{currentUserCreatorGroup.title.S}</Currentvalue>
        </Section>
        <Section>
          <h5>Package type</h5>
          <Currentvalue>{packageType}</Currentvalue>
        </Section>
        {showStorageSection && (
          <DetailsSection
            updateRootWorkspaceDetails={updateRootWorkspaceDetails}
            rootWorkspaceDetails={rootWorkspaceDetails}
            groupsList={groupsList}
            currentUserCreatorGroup={currentUserCreatorGroup}
            packageType={packageType}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default CompanyInfoSection;

CompanyInfoSection.propTypes = {
  // app.jsx props
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  updateRootWorkspaceDetails: PropTypes.func.isRequired,
  rootWorkspaceDetails: PropTypes.shape(workspaceDetailsShape),
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
  currentUserCreatorGroup: PropTypes.shape(groupShape).isRequired,
};

CompanyInfoSection.defaultProps = {
  rootWorkspaceDetails: undefined,
};
