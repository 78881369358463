import React from 'react';
import PropTypes from 'prop-types';
import classes from './ConfirmationForm.module.css';
import {
  confirmSignUp,
  signIn,
  getCurrentSession,
  resendVerificationCode,
} from '../../../../apiServices/authApi';
import { addGroupWithHeader } from '../../../../apiServices/groupApi';
import NotificationContext from '../../../../context/notification';
import ExperimentalButton from '../../../../components/buttons/experimentalButton/experimentalButton';
import TextInput from '../../../../components/inputs/textInput';
import { historyShape } from '../../../../routerPropTypes';
import { addInvitedUser } from '../../../../apiServices/userApi';

class ConfirmationForm extends React.Component {
  static contextType = NotificationContext;

  constructor() {
    super();
    this.state = {
      confirmationCode: '',
      confirmFormProcess: { status: 'inactive' },
      resendVerificationCodeProcess: { status: 'active' },
    };
  }

  componentDidMount = () => {
    const infoFormObj = this.getFormInfoObj();
    if (infoFormObj === undefined) {
      this.props.history.push('/app/signup');
    }
  };

  handleConfirmationCodeFieldValueChange = (event) => {
    this.setState({ confirmationCode: event.target.value });
  };

  getFormInfoObj = () => {
    const confirmFormProcessObj = window.localStorage.getItem('confirmFormProcess');
    if (confirmFormProcessObj) {
      const parsedObj = JSON.parse(confirmFormProcessObj);
      if (parsedObj.status === 'initial' || parsedObj.status === 'error') {
        return parsedObj;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    this.setState({ confirmFormProcess: { status: 'loading' } });
    const infoObj = this.getFormInfoObj();
    this.confirmRegistration(infoObj.email, this.state.confirmationCode)
      .then(() => {
        this.logUserIn(infoObj.email, infoObj.password).then(() => {
          this.getCurrentSessionObj().then((getCurrentSessionObjResponse) => {
            if (infoObj.invitationId !== '') {
              const header = { Authorization: getCurrentSessionObjResponse.idToken.jwtToken };
              addInvitedUser(
                infoObj.invitationId,
                infoObj.firstName,
                infoObj.lastName,
                header,
              ).then(() => {
                this.setState({ confirmFormProcess: { status: 'success' } });
                window.localStorage.removeItem('confirmFormProcess');
                this.props.userHasAuthenticated(true);
                this.props.initiateSession();
                this.props.updateLoginProcessOngoing(true);
              });
            } else {
              this.addWorkspace(
                getCurrentSessionObjResponse,
                infoObj.companyName,
                infoObj.userSub,
                infoObj.firstName,
                infoObj.lastName,
                infoObj.email,
              ).then(() => {
                this.setState({ confirmFormProcess: { status: 'success' } });
                window.localStorage.removeItem('confirmFormProcess');
                this.props.userHasAuthenticated(true);
                this.props.initiateSession();
                this.props.updateLoginProcessOngoing(true);
              });
            }
          });
        });
      })
      .catch(() => {
        this.setState({ confirmationCode: '' });
      });
  };

  confirmRegistration = async (email, confirmationCode) =>
    new Promise((resolve, reject) => {
      this.props.updateIsQuerying(true);
      confirmSignUp(email, confirmationCode)
        .then((confirmSignUpResponse) => {
          this.props.updateIsQuerying(false);
          this.setState({ confirmFormProcess: { status: 'success' } });
          resolve(confirmSignUpResponse);
        })
        .catch((confirmationError) => {
          this.setState({ confirmFormProcess: { status: 'error', data: confirmationError } });
          this.props.updateIsQuerying(false);
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not confirm registration',
          });
          reject(confirmationError);
        });
    });

  logUserIn = async (email, password) =>
    new Promise((resolve, reject) => {
      this.props.updateIsQuerying(true);
      signIn(email, password)
        .then((signInResponse) => {
          this.props.updateIsQuerying(false);
          resolve(signInResponse);
        })
        .catch((signInError) => {
          this.props.updateIsQuerying(false);
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not log in user',
          });
          reject(signInError);
        });
    });

  addWorkspace = async (currentSession, companyName, userSub, firstName, lastName, email) =>
    new Promise((resolve, reject) => {
      const header = { Authorization: currentSession.idToken.jwtToken };
      this.props.updateIsQuerying(true);
      addGroupWithHeader(companyName, userSub, header, firstName, lastName, email)
        .then((addGroupResponse) => {
          this.props.updateIsQuerying(false);
          resolve(addGroupResponse);
        })
        .catch((addGroupWithHeaderError) => {
          this.props.updateIsQuerying(false);
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not register new group',
          });
          reject(addGroupWithHeaderError);
        });
    });

  getCurrentSessionObj = async () =>
    new Promise((resolve, reject) => {
      this.props.updateIsQuerying(true);
      getCurrentSession()
        .then((currentSession) => {
          this.props.updateIsQuerying(false);
          resolve(currentSession);
        })
        .catch((currentSessionError) => {
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not fetch session details',
          });
          reject(currentSessionError);
        });
    });

  validateConfirmationForm = () => {
    const { confirmationCode } = this.state;
    return confirmationCode.length > 0;
  };

  handleResendCode = async () => {
    try {
      const confirmFormProcessObj = window.localStorage.getItem('confirmFormProcess');
      const confirmFormProcessObjParsed = JSON.parse(confirmFormProcessObj);
      this.props.updateIsQuerying(true);
      this.setState({ resendVerificationCodeProcess: { status: 'loading' } });
      const resendVerificationCodeResponse = await resendVerificationCode(
        confirmFormProcessObjParsed.email,
      );
      this.setState({
        resendVerificationCodeProcess: { status: 'success', data: resendVerificationCodeResponse },
      });
      this.context.add({
        id: Date.now(),
        type: 'success',
        message: `Code sent to ${confirmFormProcessObjParsed.email}`,
      });
      this.props.updateIsQuerying(false);
    } catch (resendVerificationCodeError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not send verification code',
      });
      this.setState({
        resendVerificationCodeProcess: { status: 'error', data: resendVerificationCodeError },
      });
    }
  };

  getConfirmationButtonStyle = (confirmFormProcess, confirmationCode) => {
    if (
      (confirmationCode.length > 0 && confirmFormProcess.status === 'inactive') ||
      confirmFormProcess.status === 'success'
    ) {
      return 'active';
    } else {
      return confirmFormProcess.status;
    }
  };

  render = () => {
    const infoObj = this.getFormInfoObj();
    const confirmationButtonStyle = this.getConfirmationButtonStyle(
      this.state.confirmFormProcess,
      this.state.confirmationCode,
    );
    return (
      <div className="contentWrapper">
        <p>Email: {infoObj ? infoObj.email : ''}</p>
        <form onSubmit={this.handleConfirmationSubmit}>
          <div className="inputsWrapper">
            <div className="inputWrapper">
              <TextInput
                type="text"
                value={this.state.confirmationCode}
                onChange={this.handleConfirmationCodeFieldValueChange}
                placeholder=" "
                id="code"
                autoFocus
              />
              <label htmlFor="code">Confirmation code</label>
            </div>
          </div>
          <div className={classes.buttonsWrapper}>
            <ExperimentalButton
              loading={this.state.resendVerificationCodeProcess.status === 'loading'}
              isDisabled={this.state.resendVerificationCodeProcess.status === 'inactive'}
              type="secondary"
              onClick={this.handleResendCode}
            >
              {this.state.resendVerificationCodeProcess.status === 'error'
                ? 'Try again'
                : 'Resend code'}
            </ExperimentalButton>
            <ExperimentalButton
              loading={this.state.confirmFormProcess.status === 'loading'}
              isDisabled={confirmationButtonStyle === 'inactive'}
              htmlType="submit"
            >
              {confirmationButtonStyle === 'error' ? 'Try again' : 'Confirm'}
            </ExperimentalButton>
          </div>
        </form>
      </div>
    );
  };
}

export default ConfirmationForm;

ConfirmationForm.propTypes = {
  // router
  history: PropTypes.shape(historyShape).isRequired,

  // App.jsx props
  userHasAuthenticated: PropTypes.func.isRequired,
  initiateSession: PropTypes.func.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  updateLoginProcessOngoing: PropTypes.func.isRequired,
};
