import { useState, useRef, useEffect, useMemo } from 'react';

import './ProjectParticipiantsModal.scss';
import { ReactComponent as CrossIcon } from '../../../assets/nitro/cross.svg';
import ProjectParticipiantsModalPeopleList from './projectParticipiantsModalPeopleList/ProjectParticipiantsModalPeopleList';
import WorkspaceUsersSection from './workspaceUsersSection/workspaceUsersSection';

import { getWorkspaceParticipantsNotYetParticipatingInProjectList } from './ProjectParticipiantsModalUtilityFunctions';
import ProjectParticipiantsModalAddNewPeopleSection from './projectParticipiantsModalAddNewPeopleSection/ProjectParticipiantsModalAddNewPeopleSection';
import { useDispatch, useSelector } from 'react-redux';
import RoleTablet from '../../common/roleTablet/roleTablet';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import { removeInvitedUser } from '../../../apiServices/userApi';
import { removeInvitedUser as removeInvitedUserFromContext } from '../../../app/features/project/projectSlice';

const ProjectParticipiantsModal = (props) => {
  const [peopleSectionOpen, setPeopleSectionOpen] = useState(true);
  const [addPeopleSectionOpen, setAddPeopleSectionOpen] = useState(true);
  const { project, user, workspace } = useSelector((state) => state);
  const dispatch = useDispatch();
  const modalWrapperNode = useRef(null);

  // eslint-disable-next-line
  const workspaceParticipantsNotYetParticipatingInProjectList = useMemo(
    () =>
      getWorkspaceParticipantsNotYetParticipatingInProjectList(
        workspace.workspaceUsers,
        project.users,
      ),
    [project.users, workspace.workspaceUsers],
  );

  const currentUserIsAdminOrPro = props.userRole === 'admin' || props.userRole === 'pro';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalWrapperNode?.current?.contains(event.target)) {
        props.closeModal();
      }
    };
    const handleButtonPress = (event) => {
      if (event.keyCode === 27) {
        props.closeModal();
      }
    };
    document.addEventListener('keydown', handleButtonPress, false);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handleButtonPress, false);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [modalWrapperNode]);

  const revokeInvitation = async (id) => {
    const i = await removeInvitedUser(id);
    // TODO: error handling?
    dispatch(removeInvitedUserFromContext(id));
  };

  return (
    <div className="projectParticipiantsModal">
      <div className="modalContent" ref={modalWrapperNode}>
        <div className="header">
          <button onClick={props.closeModal} title="close modal" type="button">
            <CrossIcon />
          </button>
        </div>
        <div className="wrapper">
          <h4>Project participants</h4>
          <section>
            <div className="subtitle">
              <h5>PEOPLE</h5>
            </div>
            <div className="peopleList">
              <ProjectParticipiantsModalPeopleList
                users={project.users}
                projectId={project.projectId}
                currentUserIsAdminOrPro={currentUserIsAdminOrPro}
              />
            </div>
          </section>
          {project.invitedUsers?.length > 0 && (
            <section className="pendingPeople">
              <div className="subtitle">
                <h5>PENDING INVITATIONS</h5>
              </div>
              <ul>
                {project.invitedUsers.map((person) => (
                  <li key={person.uid} className="person">
                    <div>
                      {' '}
                      <span className="email">{person.email}</span>
                      <RoleTablet role={person.role} />
                      {person.status === 'expired' && <span className="expired">Expired</span>}
                    </div>
                    {currentUserIsAdminOrPro && !!person.uid && (
                      <>
                        <ExperimentalButton
                          type="grey"
                          //isDisabled={deleting}
                          onClick={() => revokeInvitation(person.uid)}
                          title="Revoke invitation"
                        >
                          <CrossIcon height="10px" fill="lightgray" />
                        </ExperimentalButton>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </section>
          )}
          {currentUserIsAdminOrPro &&
            workspaceParticipantsNotYetParticipatingInProjectList?.length > 0 && (
              <section className="addPeople">
                <div className="subtitle">
                  <h5>ADD EXISTING PEOPLE</h5>
                </div>
                <div className="addPeopleList">
                  <WorkspaceUsersSection
                    projectId={props.projectId}
                    users={workspaceParticipantsNotYetParticipatingInProjectList}
                  />
                </div>
              </section>
            )}
          {currentUserIsAdminOrPro && (
            <section className="addPeople">
              <div className="subtitle">
                <h5>ADD NEW PEOPLE</h5>
              </div>
              <div className="addPeopleList">
                <ProjectParticipiantsModalAddNewPeopleSection
                  currentUserInfo={props.currentUserInfo}
                  projectId={props.projectId}
                  currenUserGroup={workspace.currentUserGroup}
                />
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectParticipiantsModal;
