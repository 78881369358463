import React from 'react';
import { ReactComponent as FullScreenIcon } from '../../../../../../assets/nitro/fullscreen.svg';
import { ReactComponent as Cross } from '../../../../../../assets/nitro/cross.svg';
import './projectMainViewPdf.scss';

class ProjectMainViewPdf extends React.Component {
  state = {
    imageErrored: false,
    fullScreen: false,
  };

  render() {
    const isPdf = this.props.resource.mimeType === 'application/pdf';
    return (
      <>
        {isPdf && (
          <div
            id="imageWrapper"
            className={`projectMainViewPdf ${this.state.fullScreen ? 'fullScreen' : ''}`}
          >
            {!this.state.imageErrored &&
              this.props.resource?.thumbnailUrl &&
              !this.state.fullScreen && (
                <img
                  src={this.props.resource.thumbnailUrl}
                  alt="preview"
                  id="project-mainview-fullscreen-img"
                  onClick={this.handleImageWrapperClick}
                  onLoad={this.handleImageLoaded}
                  onError={this.handleImageErrored}
                  allowFullScreen
                  className={`${this.props.currentPreviewItemIsLoaded ? '' : 'loading'}`}
                />
              )}
            {this.props.resource?.signedUrl &&
              this.state.fullScreen &&
              this.props.resource.size <= 20000000 && (
                <iframe
                  src={`${this.props.resource.signedUrl}`}
                  title={this.props.resource.name}
                  type="application/pdf"
                />
              )}
            {this.state.fullScreen && isPdf && this.props.resource.size > 20000000 && (
              <h5 style={{ color: 'white', fontSize: '20px' }}>
                Can&apos;t view pdf files larger than 20mb, download file to view
              </h5>
            )}
            <div
              className="fullScreenIcon"
              onClick={() => this.setState({ fullScreen: !this.state.fullScreen })}
            >
              {!this.state.fullScreen ? <FullScreenIcon /> : <Cross />}
            </div>
          </div>
        )}
        {!isPdf && (
          <div>
            <iframe
              src={`${this.props.resource.signedUrl}#toolbar=0`}
              title={this.props.resource.name}
            />
          </div>
        )}
      </>
    );
  }
}

export default ProjectMainViewPdf;
