import PropTypes from 'prop-types';
import { breadcrumbItemShape } from '../ProjectViewPropTypes';
import { statusOptions } from '../../../AppPropTypes';

export const taskMessagesItemShape = {
  M: PropTypes.shape({
    created: PropTypes.shape({
      S: PropTypes.string.isRequired,
    }).isRequired,
    msg: PropTypes.shape({
      S: PropTypes.string.isRequired,
    }).isRequired,
    sender: PropTypes.shape({
      S: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export const taskShape = {
  coord_x: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  coord_y: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  created: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  itemtype: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  message: PropTypes.shape({
    S: PropTypes.string,
  }),
  messages: PropTypes.shape({
    L: PropTypes.arrayOf(PropTypes.shape(taskMessagesItemShape)),
  }).isRequired,
  owner: PropTypes.object.isRequired,
  parent: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  resource: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  resourceId: PropTypes.string,
  status: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
};

export const taskResourceShape = {
  breadCrumb: PropTypes.arrayOf(PropTypes.shape(breadcrumbItemShape)),
  created: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  itemtype: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  key: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  mimeType: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  owner: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  parent: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  parentType: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  preview: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  searchField: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  signedUrl: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  size: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape(taskShape)),
  thumbnailUrl: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
};

export const getTasksProcessShape = {
  status: PropTypes.oneOf(statusOptions).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape(taskResourceShape),
    }),
  ),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export const dimensionsShape = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  offseTop: PropTypes.number.isRequired,
  offsetLeft: PropTypes.number.isRequired,
};
