import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import UsersList from './UsersList';
import './subheader.scss';
import { locationShape } from '../../routerPropTypes';

const Subheader = ({ currentItem, location }) => {
  const subHeaderClassName = currentItem?.itemtype === 'project' ? 'subheaderProject' : 'subheader';
  return (
    <div className={subHeaderClassName}>
      <div className="wrapper">
        <Breadcrumbs currentItem={currentItem} location={location} />
        <UsersList />
      </div>
    </div>
  );
};
export default Subheader;

Subheader.propTypes = {
  // router prop types
  currentItem: PropTypes.object,
  location: PropTypes.shape(locationShape).isRequired,
};

Subheader.defaultProps = {
  currentItem: undefined,
};
