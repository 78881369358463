import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RoleTablet from '../../common/roleTablet/roleTablet';
import { roleDefinitions } from '../../../role-definitions';
import './roleSelector.scss';
import Loader from '../../loader/loader';
import { Infobox } from '../../common/infobox/Infobox';

/**
 * Component for displaying roleTablet with given value,
 * possibility to browse through available values,
 * info tooltips for values and button to submit selected value
 *
 * @param {string || undefined} value initial value
 * @param {function || undefined} setValue submit function. Takes selected value as parameter
 * @param {array || undefined} availableValues array of values to select from
 * @param {bool} isEditable whether user can browse availableValues.
 * If false initial value will be rendered.
 * @param {string} status handles loading, success and initial states if necessary
 * @param {string} useCase addUser and changeUserRole have different needs -
 * like submitButton's text and whether to display submit button from the start
 */
const RoleSelector = ({
  value,
  setValue,
  availableValues,
  isEditable,
  status,
  useCase,
  updateShowInfo,
}) => {
  const [role, setRole] = useState(undefined);
  const [hasChanged, setHasChanged] = useState(() => useCase === 'addUser');

  useEffect(() => {
    if (role !== value) {
      setRole(value);
    }
  }, [value]);

  const setNextValue = () => {
    let index = availableValues.indexOf(role);
    index = index === availableValues.length - 1 ? 0 : index + 1;
    setHasChanged(true);
    setRole(availableValues[index]);
  };

  // if user has been successfully added, hide submit-button
  let roleIsEditable = isEditable;
  if (status === 'success') {
    roleIsEditable = false;
  }
  return (
    <div className="roleSelector">
      <button
        onClick={roleIsEditable && status === 'initial' ? setNextValue : () => {}}
        className={`toggleRoleButton ${roleIsEditable ? 'editable' : ''}`}
        title="Click to change role"
        type="button"
      >
        {role && <RoleTablet role={role} />}
      </button>

      {role && (
        <Infobox maxHeight="11rem" updateShowInfo={updateShowInfo}>
          <h4>{role}</h4>
          <ul>
            {roleDefinitions[role].map((i) => (
              <li key={i}>{i}</li>
            ))}
          </ul>
          {roleIsEditable && <p className="secondaryText">Change role by clicking it.</p>}
        </Infobox>
      )}
      <div className="cta">
        {roleIsEditable &&
          hasChanged &&
          (status === 'loading' ? (
            <Loader />
          ) : (
            <button
              onClick={() => setValue(role)}
              title={useCase === 'addUser' ? 'Add user to workspace' : "Change user's role"}
              className="submit"
              type="button"
            >
              {useCase === 'addUser' ? 'Add user' : 'Change Role'}
            </button>
          ))}
      </div>
    </div>
  );
};

export default RoleSelector;

RoleSelector.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  availableValues: PropTypes.array,
  isEditable: PropTypes.bool,
  status: PropTypes.string,
  useCase: PropTypes.string,
  updateShowInfo: PropTypes.func.isRequired,
};

RoleSelector.defaultProps = {
  value: undefined,
  setValue: undefined,
  availableValues: undefined,
  status: 'initial',
  useCase: 'changeRole',
  isEditable: true,
};
