import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatMilliSeconds } from '../../../../../../../util/UtilFunctions';
import ExperimentalButton from '../../../../../../../components/buttons/experimentalButton/experimentalButton';

const TaskForm = ({
  handleSubmit, time, isSubmitting, useCase,
}) => {
  const [message, setMessage] = useState('');

  const onSubmit = () => {
    handleSubmit(message, null, 'default');
  };

  return (
    <>
      <h3>
        Assign a task
        <span>
          {useCase !== 'image'
            ? ` (${formatMilliSeconds(time)})`
            : ''}
        </span>
      </h3>
      <div className="modalScrollWrapper">
        <div className="contentSection">
          <form>
            <textarea
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="taskPopUpTextarea"
              placeholder="Write something"
              autoFocus
            />
            <div className="submitSectionWrapper">
              <ExperimentalButton loading={isSubmitting} htmlType="button" onClick={onSubmit}>
                Submit
              </ExperimentalButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default TaskForm;

TaskForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  time: PropTypes.number,
  isSubmitting: PropTypes.bool.isRequired,
  useCase: PropTypes.string.isRequired,
};

TaskForm.defaultProps = {
  time: null,
};
