import React from 'react';
import PropTypes from 'prop-types';
import './roleTablet.scss';

const RoleTablet = ({ role }) => <span className={`roleTablet ${role}`}>{role}</span>;

export default RoleTablet;

RoleTablet.propTypes = {
  role: PropTypes.oneOf(['pro', 'viewer', 'user', 'admin']).isRequired,
};
