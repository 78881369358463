export const getTextDimensions = (text) => {
  const tag = document.createElement('div');
  tag.style.position = 'absolute';
  tag.style.margin = 0;
  tag.style.height = '40px';
  tag.style.overflow = 'hidden';
  tag.style.wordBreak = 'break-all';
  tag.style.left = '-99in';
  tag.style.font = 'Nunito';
  tag.innerHTML = text;

  document.body.appendChild(tag);

  const result = {
    width: tag.clientWidth,
    height: tag.clientHeight,
  };

  document.body.removeChild(tag);

  return result;
};

export const testCharacterAmountUntilItFitsToWidth = (text, preferredWidth) => {
  for (let i = text.length; i >= 0; i--) {
    const testText = text.substring(0, i);
    const testTextDimensions = getTextDimensions(testText);
    if (testTextDimensions.width <= preferredWidth) {
      return i;
    }
  }
  return undefined;
};

export const cutItemTitle = (name, width) => {
  const title = name?.S || name?.N || name;
  const textDimensions = getTextDimensions(title);
  if (textDimensions.width > width) {
    const characterAmount = testCharacterAmountUntilItFitsToWidth(title, width - 38);
    const cutFileNameFirstSection = title.substring(0, characterAmount);
    const fileNameLastLetters = title.substring(title.length - 4, title.length);
    return `${cutFileNameFirstSection}...${fileNameLastLetters}`;
  }
  return title;
};
