import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import '../../../video-js-global.css';
import { resourceShape } from '../ResourceViewPropTypes';
import { ReactComponent as CrossIcon } from '../../../../assets/nitro/cross.svg';
import { ReactComponent as ObsIcon } from '../../../../assets/obs.svg';
import VideoPlayer from './videoPlayer/VideoPlayer';
import AudioPlayer from './audioPlayer/AudioPlayer';
import './videojsOverWrites.scss';
import FullScreenIcon from '../../../../components/icons/FullscreenIcon';

class Resource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageError: false,
      loading: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      (prevProps.content.id.S || prevProps.content.id) !==
      (this.props.content.id.S || this.props.content.id)
    ) {
      if (this.props.isRendable) {
        this.setState({ loading: true });
      } else {
        this.setState({ loading: false });
      }
    }
  };

  handleImageError = () => {
    this.setState({ imageError: true, loading: false });
  };

  handlePdfIframeLoad = (event) => {
    //console.log(event, 'event');
  };

  getImageSection = (fileExt) => (
    <>
      {!this.state.imageError && (
        <div id="imageWrapper" className="imageWrapper">
          <img
            src={this.props.content.signedUrl.S || this.props.content.signedUrl}
            alt="file"
            onError={this.handleImageError}
            onLoad={() => this.setState({ loading: false })}
          />
          {this.props.fullscreen && (
            <div className="closeIconWrapper" onClick={this.props.closeFullscreen}>
              <CrossIcon className="crossIcon" />
            </div>
          )}
        </div>
      )}
      {this.state.imageError && this.getNoViewSection(fileExt)}
    </>
  );

  getPdfThumbnailUrl = () => {
    if (this.props.content.previewImages && this.props.content.previewImages.length > 0) {
      return this.props.content.previewImages[0].S || this.props.content.previewImages[0];
    } else if (this.props.content.snapshotUrl) {
      return this.props.content.snapshotUrl.S || this.props.content.snapshotUrl;
    } else {
      return undefined;
    }
  };

  getDocumentSection = (fileExt) => {
    const isPdf = fileExt === 'pdf';
    if (isPdf) {
      return (
        <>
          {!this.state.imageError && this.props.content && !this.props.fullscreen && (
            <>
              {this.getPdfThumbnailUrl() !== undefined && (
                <div>
                  <div className="imageWrapper">
                    <img
                      src={this.getPdfThumbnailUrl()}
                      alt="preview"
                      onError={this.handleimageError}
                      onLoad={() => this.setState({ loading: false })}
                    />
                  </div>
                  {this.props.useCase === 'link' && (
                    <div className="imageWrapper">
                      <Button
                        className="button"
                        type="button"
                        onClick={this.props.openFullscreen}
                        title="display in fullscreen"
                      >
                        <FullScreenIcon height="25px" fill="black" />
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {this.getPdfThumbnailUrl() === undefined && (
                <PdfWrapper>
                  {(this.props.content.size.N || this.props.content.size) < 20000000 && (
                    <div className="imageWrapper">
                      <iframe
                        src={`${
                          this.props.content.signedUrl.S || this.props.content.signedUrl
                        }#toolbar=0`}
                        title={this.props.content.name.S || this.props.content.name}
                      />
                    </div>
                  )}
                  {(this.props.content.size.N || this.props.content.size) > 20000000 && (
                    <img src="undefined" alt="undefined" />
                  )}
                  {this.props.useCase === 'link' && (
                    <div className="imageWrapper">
                      <Button
                        className="button"
                        type="button"
                        onClick={this.props.openFullscreen}
                        title="display in fullscreen"
                      >
                        <FullScreenIcon height="25px" fill="black" />
                      </Button>
                    </div>
                  )}
                </PdfWrapper>
              )}
            </>
          )}
          {this.props.content?.signedUrl &&
            this.props.fullscreen &&
            !(
              this.props.fileExt === 'pdf' &&
              (this.props.content.size.N || this.props.content.size) > 20000000
            ) && (
              <iframe
                src={`${this.props.content.signedUrl.S || this.props.content.signedUrl}#toolbar=0`}
                title={this.props.content.name.S || this.props.content.name}
              />
            )}
          {this.props.content?.signedUrl &&
            this.props.fullscreen &&
            this.props.fileExt === 'pdf' &&
            (this.props.content.size.N || this.props.content.size) > 20000000 && (
              <h5 style={{ color: 'white', fontSize: '20px' }}>
                Can&apos;t view pdf files larger than 20mb, download file to view
              </h5>
            )}
          {this.props.fullscreen && (
            <div className="closeIconWrapper" onClick={this.props.closeFullscreen}>
              <CrossIcon className="crossIcon" />
            </div>
          )}
        </>
      );
    } else {
      return (
        <div>
          <iframe
            src={`${this.props.content.signedUrl.S || this.props.content.signedUrl}#toolbar=0`}
            title={this.props.content.name.S || this.props.content.name}
          />
        </div>
      );
    }
  };

  getNoViewSection = (fileExt) => (
    <div className="notRendable">
      <ObsIcon />
      <h5>{`.${fileExt} files can't be previewed.`}</h5>
    </div>
  );

  renderFiletype = (fileExt) => {
    switch (this.props.content.type.S || this.props.content.type) {
      case 'video':
        return (
          <VideoPlayer
            content={this.props.content}
            ready={() => this.setState({ loading: false })}
          />
        );
      case 'audio':
        return (
          <AudioPlayer
            content={this.props.content}
            ready={() => this.setState({ loading: false })}
          />
        );
      case 'image':
        return this.getImageSection();
      case 'doc':
        return this.getDocumentSection(fileExt);
      default:
        return this.getNoViewSection(fileExt);
    }
  };

  render() {
    return (
      <div
        className={`resource ${this.props.fullscreen ? 'fullscreen' : ''} ${
          this.state.loading ? 'loading' : ''
        }`}
      >
        <div
          id="video-wrapper-resource"
          className={
            (this.props.content.type.S || this.props.content.type) === 'video' &&
            this.props.isRendable
              ? ''
              : 'hidden'
          }
        />
        <div
          id="audio-wrapper-resource"
          className={
            (this.props.content.type.S || this.props.content.type) === 'audio' &&
            this.props.isRendable
              ? ''
              : 'hidden'
          }
        />
        {!this.props.isRendable
          ? this.getNoViewSection(this.props.fileExt)
          : this.renderFiletype(this.props.fileExt)}
      </div>
    );
  }
}

const PdfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Resource;

Resource.propTypes = {
  // ResourceView.jsx props
  content: PropTypes.shape(resourceShape).isRequired,
  fullscreen: PropTypes.bool.isRequired,

  closeFullscreen: PropTypes.func.isRequired,
  fileExt: PropTypes.string.isRequired,
  isRendable: PropTypes.bool.isRequired,
  openFullscreen: PropTypes.func,
  useCase: PropTypes.string,
};

Resource.defaultProps = {
  openFullscreen: undefined,
  useCase: undefined,
};

const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
`;
