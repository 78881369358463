import { transformResource } from '../project/utils';

const transformWorkspaces = (items) => {
  return items.map((w) => ({
    id: w.id.S,
    creator: w.details.creator?.S,
    title: w.details.title.S,
    status: w.details.status?.N,
    parent: w.details.parent?.S,
    level: w.details.level?.N,
    image: w.details?.image?.S,
    created: w.details.created.S,
    itemType: w.details.itemtype.S,
    expired: !!w.expired?.S,
    role: w.role.S,
  }));
};

const transformWorspaceUsers = (items) => {
  return items.map((u) => {
    const { active, cognitoAttributes, role, user } = u;
    const userAttributes = getUserAttributes(cognitoAttributes?.UserAttributes);
    return {
      uid: user?.S,
      active: active?.N,
      role: role?.S,
      ...userAttributes,
    };
  });
};

const transformInvitedUsers = (items) => {
  return items.map((u) => {
    const { email, created, expires, id, invitedFrom, inviter, project, role, group, status } = u;
    return {
      email: email.S,
      created: created.S,
      expires: expires.S,
      id: id.S,
      invitedFrom: invitedFrom.S,
      inviter: inviter.S,
      project: project?.S,
      role: role.S,
      status: status.S,
      group: group?.S,
    };
  });
};

const transformUsage = (usage) => {
  return {
    sentBytes: {
      lastMonth: usage?.sentBytes?.lastMonth.N,
      thisMonth: usage?.sentBytes?.thisMonth.N,
    },
    usedBytes: usage?.usedBytes.N,
  };
};

// Gets CognitoAttributes' userAttributes object and returns usable object (this structure is fucked up)
const getUserAttributes = (user) => {
  if (!user) return {};
  const userAttributes = {};
  for (const attr of user) {
    userAttributes[attr.Name] = attr.Value;
  }
  return userAttributes;
};

const transformFolder = (item) => ({
  id: item.id.S,
  created: item.created?.S,
  endTime: item.endTime?.S,
  group: item.group?.S,
  image: item.image?.S,
  index: item.index?.N,
  itemtype: item.itemtype?.S,
  owner: item.owner?.S,
  parent: item.parent?.S,
  parentType: item.parentType?.S,
  path: item.path?.S,
  previewImages: item.previewImages,
  resources: item.resources,
  searchField: item.searchField?.S,
  startTime: item.startTime?.S,
  status: item.status?.N,
  title: item.title?.S || item.name?.S,
  breadcrumb: item.breadcrumb,
});

const transformFolderContent = (items) => {
  return items.map((i) => {
    switch (i.itemtype.S) {
      case 'project': {
        return transformProject(i);
      }
      case 'folder': {
        return transformFolder(i);
      }
      case 'resource': {
        return transformResource(i);
      }
    }
  });
};

const transformProject = (item) => ({
  id: item.id.S,
  projectId: item.id.S,
  created: item.created?.S,
  endTime: item.endTime?.S,
  group: item.group?.S,
  image: item.image?.S,
  index: item.index?.N,
  itemtype: item.itemtype?.S,
  owner: item.owner?.S,
  parent: item.parent?.S,
  parentType: item.parentType?.S,
  path: item.path?.S,
  previewImages: item.previewImages,
  resources: item.resources,
  searchField: item.searchField?.S,
  startTime: item.startTime?.S,
  status: item.status?.N,
  title: item.title?.S || item.name?.S,
  breadcrumb: item.breadcrumb,
});

// returns data of the group root of selected group
const getCurrentRootGroup = (allWorkspaces, currentUserGroup) => {
  const current = allWorkspaces.find((i) => i.id === currentUserGroup);
  if (current?.parent === 'root') {
    return current;
  }
  return allWorkspaces.find((i) => i.id === current?.parent);
};

export {
  transformFolderContent,
  transformFolder,
  transformWorkspaces,
  transformWorspaceUsers,
  transformUsage,
  transformInvitedUsers,
  getUserAttributes,
  transformProject,
  getCurrentRootGroup,
};
