import {
  getTextDimensions,
  testCharacterAmountUntilItFitsToWidth,
} from '../../../util/FitTextIntoWidth';
import { getExtFromFileName } from '../../../util/UtilFunctions';

export const cutResourceFileName = (name, width) => {
  const textDimensions = getTextDimensions(name);
  if (textDimensions.width > width) {
    const characterAmount = testCharacterAmountUntilItFitsToWidth(name, width - 38);
    const cutFileNameFirstSection = name.substring(0, characterAmount);
    const fileNameLastLetters = name.substring(name.lastIndexOf('.') - 3, name.lastIndexOf('.'));
    const extension = getExtFromFileName(name);
    return `${cutFileNameFirstSection}...${fileNameLastLetters}.${extension}`;
  }
  return name;
};

export const getUrlProjectLastItem = (location, currentItem) => {
  const urlItems = location.pathname.split('/');
  if (currentItem) {
    const urlLastItem = urlItems[urlItems.length - 1];
    if (currentItem.itemtype.S === 'project') {
      if (
        urlLastItem === 'video' ||
        urlLastItem === 'audio' ||
        urlLastItem === 'image' ||
        urlLastItem === 'doc' ||
        urlLastItem === 'allfiles'
      ) {
        return urlLastItem;
      }
    } else if (currentItem.itemtype.S === 'account') {
      if (urlLastItem === 'profile' || urlLastItem === 'workspace') {
        return urlLastItem;
      }
    }
  }
  return undefined;
};

const didChangeBetweenProjectMainAndFilesView = (prevProps, nextProps) => {
  const prevLastItem = getUrlProjectLastItem(prevProps.location, prevProps.currentItem);
  const currentLastItem = getUrlProjectLastItem(nextProps.location, nextProps.currentItem);
  if (prevLastItem === undefined && currentLastItem !== undefined) {
    return true;
  } else if (prevLastItem !== undefined && currentLastItem === undefined) {
    return true;
  } else {
    return false;
  }
};

const urlLastItemDidChange = (prevProps, nextProps) => {
  const prevLastItem = getUrlProjectLastItem(prevProps.location, prevProps.currentItem);
  const currentLastItem = getUrlProjectLastItem(nextProps.location, nextProps.currentItem);
  if (prevLastItem !== undefined && currentLastItem !== undefined) {
    if (prevLastItem !== currentLastItem) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// export const shouldNotRerender = (prevProps, nextProps) => {
//   return prevProps.location?.pathname === nextProps.location?.pathname || prevProps.currentItem;

//   // if (prevProps.location?.pathname === nextProps.location?.pathname) {
//   //   if (
//   //     didChangeBetweenProjectMainAndFilesView(prevProps, nextProps) ||
//   //     urlLastItemDidChange(prevProps, nextProps)
//   //   ) {
//   //     return false;
//   //   } else {
//   //     return true;
//   //   }
//   // } else {
//   //   return false;
//   // }
// };
