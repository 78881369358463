import React from 'react';
import PropTypes from 'prop-types';
import classes from './ItemActionables.module.css';
import { removeFromFavourites, addToFavourites, downloadFile } from '../../../util/UtilFunctions';
import { getResourceDetails } from '../../../apiServices/resourceApi';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';
import { useCaseType } from '../ItemListPropTypes';
import './ItemActionables.scss';
import NotificationContext from '../../../context/notification';
import { CheckBox } from '../../buttons/checkBox/CheckBox';
import { getAvailableDropdownItems } from './ItemActionablesUtilityFunctions';

class ItemActionables extends React.Component {
  handleDropdownListItemClick = (listItem) => {
    let modalParams = [{ item: this.props.item }];
    this.props.updateDropdownOpenedItemId();
    switch (listItem) {
      case 'Rename':
        modalParams = [
          {
            title: `Rename ${this.props.item.itemtype} ${
              this.props.item.name || this.props.item?.title
            }`,
            item: this.props.item,
          },
        ];
        this.props.updateModalOpen('rename', modalParams);
        break;
      case 'Cover image':
        this.props.updateModalOpen('coverImage', modalParams);
        break;
      case 'Copy':
        this.props.updateModalOpen('copy');
        this.props.addToSelectedItems(this.props.item);
        break;
      case 'Delete':
        this.props.updateModalOpen('delete');
        this.props.addToSelectedItems(this.props.item);
        break;
      case 'Download':
        this.handleFileDownload();
        break;
      case 'Move':
        this.props.updateModalOpen('move');
        this.props.addToSelectedItems(this.props.item);
        break;
      case 'Set as favourite':
        addToFavourites(this.props.item);
        this.context.add({
          id: Date.now(),
          type: 'success',
          message: 'Item set as favourite',
        });
        break;
      case 'Remove from favourites':
        removeFromFavourites(this.props.item);
        this.context.add({
          id: Date.now(),
          type: 'success',
          message: 'Removed item from favourites',
        });
        break;
      case 'Share':
        this.shareItem();
        break;
      case 'Convert to mp4':
        this.props.updateModalOpen('convert', modalParams);
        break;
      default:
        break;
    }
  };

  handleFileDownload = async () => {
    if (this.props.item.signedUrl) {
      try {
        const isPdf = this.props.item.mimeType === 'application/pdf';
        if (isPdf) {
          await downloadFile(this.props.item.name, this.props.item.signedUrl);
        } else {
          await downloadFile(this.props.item.name, this.props.item.signedUrl);
        }
        this.context.add({
          id: Date.now(),
          type: 'success',
          message: 'Downloading file',
        });
      } catch (downloadFileError) {
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not download file',
        });
      }
    } else {
      try {
        this.props.updateIsQuerying(true);
        const getResourceDetailsResponse = await getResourceDetails(this.props.item.id);
        this.props.updateIsQuerying(false);
        await downloadFile(
          this.props.item.name,
          getResourceDetailsResponse.response.Item.signedUrl,
        );
        this.context.add({
          id: Date.now(),
          type: 'success',
          message: 'Downloading file',
        });
      } catch (getResoureDetailsError) {
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not get resource details',
        });
        this.props.updateIsQuerying(false);
      }
    }
  };

  shareItem = async () => {
    if (this.props.item.signedUrl) {
      const modalParams = [
        {
          selectedFiles: [
            {
              url: this.props.item.signedUrl,
              id: this.props.item.id,
              name: this.props.item.name,
            },
          ],
        },
      ];
      this.props.updateModalOpen('shareFile', modalParams);
    } else {
      try {
        this.props.updateIsQuerying(true);
        const getResourceDetailsResponse = await getResourceDetails(this.props.item.id);
        this.props.updateIsQuerying(false);
        const modalParams = [
          {
            selectedFiles: [
              {
                url: getResourceDetailsResponse.response.Item.signedUrl,
                id: getResourceDetailsResponse.response.Item.id,
                name: getResourceDetailsResponse.response.Item.name,
              },
            ],
          },
        ];
        this.props.updateModalOpen('shareFile', modalParams);
      } catch (getResoureDetailsError) {
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not get resource details',
        });
        this.props.updateIsQuerying(false);
      }
    }
  };

  handleSelectClick = () => {
    if (!this.props.isSelected) {
      this.props.addToSelectedItems(this.props.item);
      if (this.props.dropdownOpen) {
        this.props.updateDropdownOpenedItemId();
      }
    } else {
      this.props.removeFromSelectedItems([this.props.item]);
    }
  };

  handleDropdownTriggerClick = () => {
    if (!this.props.itemHasBeenSelected) {
      if (this.props.dropdownOpen) {
        this.props.updateDropdownOpenedItemId();
      } else {
        this.props.updateDropdownOpenedItemId(this.props.item.id);
      }
    }
  };

  getDropdownListItems = () => {
    const availableDropdownItems = getAvailableDropdownItems(
      this.props.isFavourite,
      this.props.item,
      this.props.currentUserIsAViewer,
    );
    return availableDropdownItems.map((listItem) => (
      <li
        key={listItem}
        className={classes.actionablesDropdownListItem}
        onClick={() => this.handleDropdownListItemClick(listItem)}
      >
        <h5 className={classes.actionablesDropdownListItemLabel}>{listItem}</h5>
      </li>
    ));
  };

  static contextType = NotificationContext;

  render() {
    const hoveringOrSelectedOrDropdownOpen =
      this.props.isHoveringOver || this.props.isSelected || this.props.dropdownOpen;
    const isNotOutOfHoverAndDropdownOpen = !(!this.props.isHoveringOver && this.props.dropdownOpen);
    const actionablesDropdownTriggerClass = this.props.dropdownOpen
      ? classes.actionablesDropdownTriggerActive
      : classes.actionablesDropdownTrigger;

    const actionablesDropdownTriggerDotsStyles = {
      margin: 'var(--space-minus-3) 0 0 0',
      fontSize: '1.7em',
      fontWeight: 900,
      color: this.props.dropdownOpen ? '#00ce5c' : 'black',
    };
    const dropdownListItems = this.getDropdownListItems();
    return (
      <div
        className={
          hoveringOrSelectedOrDropdownOpen
            ? 'item-actionables-wrapper-hovering'
            : 'item-actionables-wrapper'
        }
      >
        {isNotOutOfHoverAndDropdownOpen && (
          <CheckBox handleSelectClick={this.handleSelectClick} isSelected={this.props.isSelected} />
        )}
        {!this.props.itemHasBeenSelected && this.props.useCase !== 'link' && (
          <>
            <button
              className={actionablesDropdownTriggerClass}
              onClick={this.handleDropdownTriggerClick}
              type="button"
              title="options"
              data-testid="item-actionables-dropdown-trigger-button"
            >
              <h6 style={actionablesDropdownTriggerDotsStyles}>...</h6>
            </button>
            {this.props.dropdownOpen && (
              <ul className={classes.actionablesDropdownList} onBlur={this.handleDropdownBlur}>
                {dropdownListItems}
              </ul>
            )}
          </>
        )}
      </div>
    );
  }
}

export default ItemActionables;

ItemActionables.propTypes = {
  // App.jsx props
  updateIsQuerying: PropTypes.func.isRequired,

  // FolderView.jsx props
  useCase: useCaseType.isRequired,

  // ItemList.jsx props
  item: PropTypes.shape(folderListItemShape).isRequired,
  addToSelectedItems: PropTypes.func.isRequired,
  removeFromSelectedItems: PropTypes.func.isRequired,
  updateModalOpen: PropTypes.func.isRequired,
  updateDropdownOpenedItemId: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,

  // ProjectViewItemList.jsx or FOlderViewItemList.jsx props
  currentUserIsAViewer: PropTypes.bool.isRequired,

  // ItemListItemFile.jsx props
  dropdownOpen: PropTypes.bool.isRequired,
  itemHasBeenSelected: PropTypes.bool.isRequired,
  isHoveringOver: PropTypes.bool.isRequired,
  isFavourite: PropTypes.bool.isRequired,
};
