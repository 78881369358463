import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import styles from './accountSettingsBilling.module.scss';
import { useSelector } from 'react-redux';
import { getPortalUrl } from '../../../../apiServices/paymentApi';

// Tätä uudistettu, en halua koskea muihin accountSettings juttuihin vielä ellei ole pakko

export const AccountSettingsBilling = (props) => {
  const { selectedPackage } = useSelector((state) => state.workspace);
  const [portalUrl, setPortalUrl] = useState(null);

  useEffect(() => {
    getPortalUrl().then((res) => {
      try {
        new URL(res.response);
        setPortalUrl(res.response);
      } catch (_) {
        return false;
      }
    });
  }, []);

  if (!selectedPackage) return <div>Loading</div>;
  return (
    <div className={styles.container}>
      <h1>Billing</h1>
      <div className={styles.content}>
        <p>
          <span>Plan</span>
          <span>{selectedPackage.name}</span>
        </p>
        <p>
          <span>admins</span>
          <span>{selectedPackage.admins}</span>
        </p>
        <p>
          <span>price</span>
          <span>{selectedPackage.price} €/month</span>
        </p>
        <p>
          <span>storage</span>
          <span>{selectedPackage.storage} GB</span>
        </p>
        <p>
          <span>data transfer</span>
          <span>{selectedPackage.data_transfer}</span>
        </p>
        <p>
          <span>status</span>
          <span>{selectedPackage.subscriptionStatus}</span>
        </p>
        {!!selectedPackage.nextPayment && (
          <p>
            <span>next payment</span>
            <span>{format(new Date(selectedPackage.nextPayment), 'dd.MM.yyyy hh:mm')}</span>
          </p>
        )}
        <p>{portalUrl && <a href={portalUrl}>Manage subscription</a>}</p>
      </div>
    </div>
  );
};
