import React, { useContext } from 'react';
import NotificationContext from '../../context/notification';
import { NotificationListItem } from './notificationListItem/NotificationListItem';
import './Notifications.scss';

export const Notifications = () => {
  const { notifications, remove } = useContext(NotificationContext);
  return (
    <div className="notifications">
      {notifications.map((notification) => (
        <NotificationListItem remove={remove} key={notification.id} {...notification} />
      ))}
    </div>
  );
};
