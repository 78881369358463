import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem } from '../../../../features/project/projectSlice';
import { setPreviewItemToLocalStorage } from '../ProjectMainViewUtilityFunctions';
import ProjectPreviewItem from './ProjectPreviewItem';
import PreviewListLoading from '../../mainViewLoading/previewListLoading/PreviewListLoading';
import classes from './ProjectPreviewItemsList.module.scss';

const ProjectPreviewItemsList = ({ updateSelectedTask }) => {
  const [showAll, setShowAll] = useState(false);
  const { project } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { selectedItem, items, projectId } = project;

  const { previewItems, count } = useMemo(() => {
    const list = items?.filter((i) => i.preview);
    return { previewItems: showAll ? list : list?.slice(0, 12), count: list?.length };
  }, [items, showAll]);

  const onItemClick = (item) => {
    if (selectedItem?.id !== item?.id) {
      dispatch(setSelectedItem(item));
      setPreviewItemToLocalStorage(item.id, projectId);
    }
  };

  return !!previewItems ? (
    <ul className={classes.list}>
      {previewItems?.map((item) => (
        <ProjectPreviewItem
          key={item.id}
          name={item.title}
          onClick={() => onItemClick(item)}
          isActive={item.id === selectedItem?.id}
          thumbnailUrl={item.thumbnailUrl}
          type={item.type}
          itemtype={item.itemtype}
        />
      ))}
      {count > 13 && !showAll && (
        <div className={classes.showMore} onClick={() => setShowAll(true)} type="button">
          <span> Show more </span>
        </div>
      )}
    </ul>
  ) : (
    <PreviewListLoading amount={6} />
  );
};

export default ProjectPreviewItemsList;
