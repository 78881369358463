import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SetPictureModal from '../../../../../components/modals/setPictureModal/SetPictureModal';
import CreateWorkspaceModal from '../../../../../components/modals/createWorkspaceModal/CreateWorkspaceModal';
import WorkspaceParticipiantsModal from '../../../../../components/modals/workspaceParticipiantsModal/WorkspaceParticipiantsModal';
import ExitWorkspaceModal from '../../../../../components/modals/exitWorkspaceModal/ExitWorkspaceModal';
import { groupShape } from '../../../../AppPropTypes';
import { currentUserInfoShape } from '../../../../../apiServices/userApiTypes';
import { accountSettingsModalParamsItemShape } from '../AccountSettingsWorkspacePropTypes';
import RenameModal from '../../../../../components/modals/renameModal/renameModal';
import { ReactComponent as CrossIcon } from '../../../../../assets/nitro/cross.svg';
import './WorkspaceModals.scss';
import DeleteItemModal from '../../../../../components/modals/deleteItemModal/DeleteItemModal';

const WorkspaceModals = (props) => {
  const modalContent = useRef(null);

  const handleClick = (e) => {
    if (modalContent.current) {
      if (!modalContent.current.contains(e.target)) {
        props.updateModalOpen();
      }
    }
  };

  return (
    <div
      className={`workspaceModals ${props.modalOpen ? '' : 'closed'} ${
        props.modalOpen ? props.modalOpen : ''
      }`}
      onClick={handleClick}
    >
      <div className="modalContent" ref={modalContent}>
        <div className="header">
          <button onClick={() => props.updateModalOpen()} title="close modal" type="button">
            <CrossIcon />
          </button>
        </div>
        {props.modalOpen === 'uploadProfile' && (
          <SetPictureModal
            closeModal={props.updateModalOpen}
            updateIsQuerying={props.updateIsQuerying}
            updateGroupsList={props.refreshList}
            currentUserInfo={props.currentUserInfo}
            useCase={props.modalParams[0].useCase}
            item={props.modalParams[0].workspace}
            hasImage={
              props.modalParams[0].workspace.image &&
              props.modalParams[0].workspace.image.S !== 'no-image'
            }
            getWorkspacePeopleList={props.getWorkspacePeopleList}
          />
        )}
        {props.modalOpen === 'createWorkspace' && (
          <CreateWorkspaceModal
            currentUserInfo={props.currentUserInfo}
            closeModal={props.updateModalOpen}
            updateIsQuerying={props.updateIsQuerying}
            updateGroupsList={props.refreshList}
            updateCurrentUserInfo={props.updateCurrentUserInfo}
            packageType={props.packageType}
            useCase={props.modalParams[0].useCase}
          />
        )}
        {props.modalOpen === 'workspacePeopleList' && (
          <WorkspaceParticipiantsModal
            currentUserGroup={props.currentUserGroup}
            getWorkspacePeopleList={props.getWorkspacePeopleList}
            workspace={props.modalParams[0].workspace}
            currentUserInfo={props.currentUserInfo}
            groupsList={props.groupsList}
            updateIsQuerying={props.updateIsQuerying}
          />
        )}
        {props.modalOpen === 'exitWorkspace' && (
          <ExitWorkspaceModal
            workspace={props.modalParams[0].workspace}
            closeModal={props.updateModalOpen}
            currentUserGroup={props.currentUserInfo}
            currentUserInfo={props.currentUserInfo}
            updateIsQuerying={props.updateIsQuerying}
            refreshList={props.refreshList}
          />
        )}
        {props.modalOpen === 'renameWorkspace' && (
          <RenameModal
            workspace={props.modalParams[0].workspace}
            title={props.modalParams[0].title}
            closeModal={props.updateModalOpen}
            currentUserGroup={props.currentUserInfo}
            currentUserInfo={props.currentUserInfo}
            updateIsQuerying={props.updateIsQuerying}
            refreshList={props.refreshList}
            placeholder="New name"
          />
        )}
        {props.modalOpen === 'deleteWorkspace' && (
          <DeleteItemModal
            selectedItems={[props.modalParams[0].workspace]}
            closeModal={props.updateModalOpen}
            updateIsQuerying={props.updateIsQuerying}
            refreshList={props.refreshList}
            userIsAdmin={props.roleInCurrentWorkspace}
          />
        )}
      </div>
    </div>
  );
};

export default WorkspaceModals;

WorkspaceModals.propTypes = {
  // app.jsx
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  refreshList: PropTypes.func.isRequired,
  currentUserGroup: PropTypes.string.isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
  getWorkspacePeopleList: PropTypes.func.isRequired,
  updateCurrentUserInfo: PropTypes.func.isRequired,

  // AccountSettingsWorkpsace.jsx
  updateModalOpen: PropTypes.func.isRequired,
  modalOpen: PropTypes.string.isRequired,
  modalParams: PropTypes.arrayOf(PropTypes.shape(accountSettingsModalParamsItemShape)),
  roleInCurrentWorkspace: PropTypes.bool.isRequired,
  packageType: PropTypes.string.isRequired,
};

WorkspaceModals.defaultProps = {
  modalParams: undefined,
};
