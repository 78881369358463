import PropTypes from 'prop-types';

export const folderListNonResourceItemShape = {
  // common
  created: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  itemtype: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  owner: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  parent: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,

  searchField: PropTypes.shape({
    S: PropTypes.string,
  }),
  status: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,

  // specific
  index: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  startTime: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  endTime: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  previewImages: PropTypes.arrayOf(
    PropTypes.shape({
      S: PropTypes.string.isRequired,
    }).isRequired,
  ),
  path: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  resources: PropTypes.shape({
    audio: PropTypes.number.isRequired,
    doc: PropTypes.number.isRequired,
    folder: PropTypes.number.isRequired,
    image: PropTypes.number.isRequired,
    project: PropTypes.number.isRequired,
    video: PropTypes.number.isRequired,
  }).isRequired,
};

export const folderListResourceItemShape = {
  // common
  created: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  itemtype: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  owner: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  parent: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  previewImages: PropTypes.arrayOf(
    PropTypes.shape({
      S: PropTypes.string.isRequired,
    }).isRequired,
  ),
  searchField: PropTypes.shape({
    S: PropTypes.string,
  }),
  status: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,

  // specific
  key: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  mimeType: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  parentType: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  preview: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  signedUrl: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  size: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
};

export const folderListItemShape = {
  // common
  created: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  itemtype: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  owner: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  parent: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }).isRequired,
  previewImages: PropTypes.arrayOf(
    PropTypes.shape({
      S: PropTypes.string.isRequired,
    }).isRequired,
  ),
  searchField: PropTypes.shape({
    S: PropTypes.string,
  }),
  status: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }).isRequired,

  // resource specific
  key: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  mimeType: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  name: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  parentType: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  preview: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }),
  signedUrl: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  size: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }),
  type: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),

  // folder / project specific
  index: PropTypes.shape({
    N: PropTypes.string.isRequired,
  }),
  title: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  startTime: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  endTime: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  path: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  resources: PropTypes.shape({
    audio: PropTypes.number.isRequired,
    doc: PropTypes.number.isRequired,
    folder: PropTypes.number.isRequired,
    image: PropTypes.number.isRequired,
    project: PropTypes.number.isRequired,
    video: PropTypes.number.isRequired,
  }),

  // project specific
  thumbnailUrl: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
};
