import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ItemListItem from '../itemListItem/ItemListItem';
import ItemListItemFile from '../itemListItemFile/ItemListItemFile';
import { sortContentList } from '../../../util/UtilFunctions';
import { currentUserInfoShape } from '../../../apiServices/userApiTypes';
import { matchShape } from '../../../routerPropTypes';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';
import { useCaseType } from '../ItemListPropTypes';
import { breakpointLarge } from '../../../styles/variables';

const FolderViewItemList = (props) => {
  const sortedList = sortContentList(
    props.content,
    props.sortByCriteriaIndex,
    props.sortByDirectionIsAscending,
  );
  const rendableList = sortedList.map((item) => {
    const isSelected =
      props.selectedItems.find((selectedItem) => selectedItem.id === item.id) !== undefined;

    return (
      <React.Fragment key={item.id}>
        {item.itemtype !== 'resource' && (
          <ItemListItem
            item={item}
            match={props.match}
            currentUserGroup={props.currentUserGroup}
            refreshList={props.refreshList}
            selectedItems={props.selectedItems}
            addToSelectedItems={props.addToSelectedItems}
            removeFromSelectedItems={props.removeFromSelectedItems}
            viewAsLargeGrid={false}
            updateModalOpen={props.updateModalOpen}
            updateDropdownOpenedItemId={props.updateDropdownOpenedItemId}
            dropdownOpenedItemId={props.dropdownOpenedItemId}
            updateRenamingItemId={props.updateRenamingItemId}
            renamingItemId={props.renamingItemId}
            isSelected={isSelected}
            updateIsQuerying={props.updateIsQuerying}
            currentUserIsAViewer={props.currentUserInfo.role === 'viewer'}
            useCase={props.useCase}
          />
        )}
        {item.itemtype === 'resource' && (
          <ItemListItemFile
            item={item}
            selectedItems={props.selectedItems}
            match={props.match}
            addToSelectedItems={props.addToSelectedItems}
            removeFromSelectedItems={props.removeFromSelectedItems}
            updateModalOpen={props.updateModalOpen}
            updateDropdownOpenedItemId={props.updateDropdownOpenedItemId}
            dropdownOpenedItemId={props.dropdownOpenedItemId}
            updateRenamingItemId={props.updateRenamingItemId}
            renamingItemId={props.renamingItemId}
            isSelected={isSelected}
            refreshList={props.refreshList}
            viewAsLargeGrid={false}
            updateIsQuerying={props.updateIsQuerying}
            currentUserIsAViewer={props.currentUserInfo.role === 'viewer'}
            useCase={props.useCase}
            sortByCriteriaIndex={props.sortByCriteriaIndex}
            sortByDirectionIsAscending={props.sortByDirectionIsAscending}
          />
        )}
      </React.Fragment>
    );
  });
  return <ItemList>{rendableList}</ItemList>;
};

const ItemList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;

  @media screen and (max-width: ${breakpointLarge}) {
    justify-content: center;
  }
`;

export default FolderViewItemList;

FolderViewItemList.propTypes = {
  // Router props
  match: PropTypes.shape(matchShape).isRequired,

  // App.jsx props
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  currentUserGroup: PropTypes.string.isRequired,

  // FolderView.jsx props
  refreshList: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  useCase: useCaseType.isRequired,

  // ItemList.jsx props
  selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  addToSelectedItems: PropTypes.func.isRequired,
  removeFromSelectedItems: PropTypes.func.isRequired,
  dropdownOpenedItemId: PropTypes.string,
  updateDropdownOpenedItemId: PropTypes.func.isRequired,
  sortByCriteriaIndex: PropTypes.number.isRequired,
  sortByDirectionIsAscending: PropTypes.bool.isRequired,
  updateModalOpen: PropTypes.func.isRequired,
  renamingItemId: PropTypes.string,
  updateRenamingItemId: PropTypes.func.isRequired,
};

FolderViewItemList.defaultProps = {
  dropdownOpenedItemId: undefined,
  renamingItemId: undefined,
};
