import { API } from 'aws-amplify';
import store from '../configureStore';
import { config } from '../config/Config';

export const addGroupWithHeader = (
  companyName,
  creatorUid,
  signupHeader,
  firstName,
  lastName,
  email,
) => {
  const espacedName = encodeURIComponent(companyName);
  if (signupHeader) {
    const addGroupInit = {
      headers: signupHeader,
      body: `groupname=${espacedName}&uid=${creatorUid}&firstname=${firstName}&lastname=${lastName}&email=${email}`,
    };
    return API.post(config.apiGateway.NAME, '/group/register', addGroupInit);
  } else {
    const {
      project: { header },
    } = store.getState();
    const addGroupInit = {
      headers: header,
      body: `groupname=${espacedName}&uid=${creatorUid}&firstname=${firstName}&lastname=${lastName}&email=${email}`,
    };
    return API.post(config.apiGateway.NAME, '/group/register', addGroupInit);
  }
};

export const addGroup = (companyName, creatorUid) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();

  const espacedName = encodeURIComponent(companyName);
  const addGroupInit = {
    headers: header,
    body: `groupname=${espacedName}&uid=${creatorUid}&parent=${currentUserGroup}`,
  };
  return API.post(config.apiGateway.NAME, `/group/add?gid=${currentUserGroup}`, addGroupInit);
};

export const addGroupWithImage = (companyName, creatorUid, storageKey) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
    user: { identityId },
  } = store.getState();

  const imageS3Path = `private/${identityId}/${storageKey}`;
  const espacedName = encodeURIComponent(companyName);
  const addGroupInit = {
    headers: header,
    body: `groupname=${espacedName}&uid=${creatorUid}&image=${imageS3Path}&parent=${currentUserGroup}`,
  };

  return API.post(config.apiGateway.NAME, `/group/add?gid=${currentUserGroup}`, addGroupInit);
};

export const updateWorkspaceImage = (groupId, storageResponseKey) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
    user: { identityId },
  } = store.getState();
  const resourceS3Path = `private/${identityId}/${storageResponseKey}`;
  const updateWorkspaceImageInit = {
    headers: header,
    body: `image=${resourceS3Path}`,
  };
  const gid = groupId.S || groupId;
  return API.post(config.apiGateway.NAME, `/group/update?gid=${gid}`, updateWorkspaceImageInit);
};

export const removeWorkspaceImage = (groupId) => {
  const {
    project: { header },
  } = store.getState();
  const updateWorkspaceImageInit = {
    headers: header,
    body: 'image=no-image',
  };
  const gid = groupId.S || groupId;
  return API.post(config.apiGateway.NAME, `/group/update?gid=${gid}`, updateWorkspaceImageInit);
};

export const updateWorkspaceName = (groupId, name) => {
  const {
    project: { header },
  } = store.getState();

  const espacedName = encodeURIComponent(name);

  const updateWorkspaceNameInit = {
    headers: header,
    body: `groupname=${espacedName}`,
  };
  const gid = groupId.S || groupId;
  return API.post(config.apiGateway.NAME, `/group/update?gid=${gid}`, updateWorkspaceNameInit);
};

export const getRootWorkspaceDetails = () => {
  const {
    project: { header },
    workspace: { rootGroup },
  } = store.getState();
  const getRootWorkspaceSizeInit = {
    headers: header,
  };
  return API.get(
    config.apiGateway.NAME,
    `/group/usage?gid=${rootGroup?.id}`,
    getRootWorkspaceSizeInit,
  );
};

export const subscripePlan = (gid, plan) => {
  const {
    project: { header },
  } = store.getState();
  const getRootWorkspaceSizeInit = {
    headers: header,
  };
  return API.get(
    config.apiGateway.NAME,
    `/stripe/get-session?gid=${gid}&plan=${plan}`,
    getRootWorkspaceSizeInit,
  );
};

export const deleteWorkspace = (groupId) => {
  const {
    project: { header },
  } = store.getState();
  // I do not have the time to refactor these workspace stuff also 🙄
  const id = typeof groupId === 'string' ? groupId : groupId.S;
  const updateWorkspaceImageInit = {
    headers: header,
    body: `id=${id}`,
  };

  return API.post(config.apiGateway.NAME, `/group/delete?id=${id}`, updateWorkspaceImageInit);
};
