import React from 'react';
import PropTypes from 'prop-types';

const CheckedIcon = (props) => {
  const { height, fill } = props;
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      enableBackground="new 0 0 26 26"
      height={height}
      style={{ pointerEvents: 'none' }}
      fill={fill}
      data-testid="checked-icon"
    >
      <path
        d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z"
      />
    </svg>
  );
};

export default CheckedIcon;

CheckedIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

CheckedIcon.defaultProps = {
  height: '0',
};
