import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { groupShape } from '../../../../AppPropTypes';
import { currentUserInfoShape } from '../../../../../apiServices/userApiTypes';
import { getValueFromUserAttributes } from '../../../../../util/UtilFunctions';
import ProfileBall from '../../../../../components/profileBall/ProfileBall';
import ExperimentalButton from '../../../../../components/buttons/experimentalButton/experimentalButton';
import {
  colorDenimPrimary,
  colorGreyDark,
  colorGreyPrimary,
} from '../../../../../styles/variables';

const GeneralInfoSection = ({
  currentUserInfo,
  groupsList,
  updateModalOpen,
  updateCurrentUserInfo,
}) => {
  // whether to show storageSection
  // eslint-disable-next-line
  // const [showStorageSection, setShowStorageSection] = useState(() => (
  //   currentUserIsAdminOfRootGroup(currentUserInfo, groupsList)));
  // get userdata
  const [userData, setUserData] = useState({});

  // update userdata when currentUserInfo prop changes
  useEffect(() => {
    const firstname = getValueFromUserAttributes(
      currentUserInfo.cognitoAttributes.UserAttributes,
      'given_name',
    );
    const lastname = getValueFromUserAttributes(
      currentUserInfo.cognitoAttributes.UserAttributes,
      'family_name',
    );
    const email = getValueFromUserAttributes(
      currentUserInfo.cognitoAttributes.UserAttributes,
      'email',
    );
    setUserData({ firstname, lastname, email });
  }, [currentUserInfo]);

  const handleEditClick = (type) => {
    updateModalOpen(type);
  };
  return (
    <Wrapper>
      <Header>
        <h4>General</h4>
      </Header>
      <ContentWrapper>
        <Section>
          <h5>Profile picture</h5>
          <Currentvalue>
            <ProfileBall
              size={40}
              imageUrl={currentUserInfo.image?.S}
              refreshPicture={updateCurrentUserInfo}
              firstName={userData.firstname}
              lastName={userData.lastname}
            />
          </Currentvalue>
          <ExperimentalButton
            onClick={() => handleEditClick('uploadProfile')}
            type="primary"
            title="Upload new profile picture"
          >
            Update
          </ExperimentalButton>
        </Section>
        <Section>
          <h5>First name</h5>
          <Currentvalue>{userData.firstname}</Currentvalue>
          <ExperimentalButton
            onClick={() => handleEditClick('updateFirstname')}
            type="primary"
            title="Update firstname"
          >
            Edit
          </ExperimentalButton>
        </Section>
        <Section>
          <h5>Surname</h5>
          <Currentvalue>{userData.lastname}</Currentvalue>
          <ExperimentalButton
            onClick={() => handleEditClick('updateLastname')}
            type="primary"
            title="Update lastname"
          >
            Edit
          </ExperimentalButton>
        </Section>
        <Section>
          <h5>Email</h5>
          <Currentvalue>{userData.email}</Currentvalue>
          <ExperimentalButton
            onClick={() => handleEditClick('updateEmail')}
            type="primary"
            title="update email"
          >
            Edit
          </ExperimentalButton>
        </Section>
      </ContentWrapper>
    </Wrapper>
  );
};

export default GeneralInfoSection;

export const Wrapper = styled.div`
  margin: 1rem 0;
`;

export const ContentWrapper = styled.div`
  max-width: 700px;
`;

export const Section = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colorGreyPrimary};
  margin-left: 0.25rem;
  &:last-child {
    border-bottom: none;
  }
  h5 {
    font-size: 1rem;
    font-weight: normal;
  }

  button.button {
    padding: 0;
    width: 5rem;
    margin-bottom: 1rem;
    min-height: 1rem;
    height: 2rem;
    margin-bottom: unset;
  }
`;

export const Currentvalue = styled.div`
  margin-left: auto;
  margin-right: 1rem;
  color: ${colorGreyDark};
`;

export const Header = styled.div`
  max-width: 700px;
  height: 35px;
  background-color: ${colorDenimPrimary};
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 0 0 var(--space-2);
  border-radius: 7px;
  margin-bottom: 1rem;
`;

GeneralInfoSection.propTypes = {
  // app.jsx props
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  updateCurrentUserInfo: PropTypes.func.isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,

  // AccountSettingsProfile.jsx props
  updateModalOpen: PropTypes.func.isRequired,
};
