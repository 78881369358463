export const determineCombinedProcessesStatus = (firstStatus, secondStatus) => {
  let combinedProcessesStatus;
  if (firstStatus === 'initial' || secondStatus === 'initial') {
    combinedProcessesStatus = 'initial';
  } else if (firstStatus === 'loading' || secondStatus === 'loading') {
    combinedProcessesStatus = 'loading';
  }
  if (firstStatus === 'success' && secondStatus === 'success') {
    combinedProcessesStatus = 'success';
  } else if (firstStatus === 'error' || secondStatus === 'error') {
    combinedProcessesStatus = 'error';
  }
  return combinedProcessesStatus;
};
