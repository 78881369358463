import React, { useState, useEffect } from 'react';
import videojs from 'video.js';
import PropTypes from 'prop-types';
import Canvas from './arrowsOverlay/canvas';
import { dimensionsShape } from '../../ProjectMainViewPropTypes';
import { lineTypes, getArrowHeadPoints, getLinePoints } from './arrowsOverlay/utils';

const ClickArea = ({ id, updateNewTaskData, dimensions, isNewTaskActive, lineType }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [savedCoords, setSavedCoords] = useState([]);

  useEffect(() => {
    if (!isNewTaskActive) {
      setSavedCoords([]);
    }
  }, [isNewTaskActive]);

  const handleVideoClickEvent = (event) => {
    event.stopPropagation();
    if (!isDragging) {
      const playerObj = videojs(`project-main-view-player-${id}`);
      playerObj.pause();

      if (playerObj && !playerObj.isFullscreen()) {
        const videoElementOffsetLeft = dimensions.offsetLeft;
        const videoElementOffsetTop = dimensions.offsetTop;
        const newTaskData = {
          coord: {
            left: event.pageX - videoElementOffsetLeft,
            top: event.pageY - videoElementOffsetTop,
          },
          time: playerObj.currentTime() * 1000,
        };
        createNewTask(newTaskData);
      }
    }
  };

  const createNewTask = (newTaskData) => {
    updateNewTaskData(newTaskData);
  };

  const afterDraw = (coords) => {
    if (coords.length > 0) {
      const playerObj = videojs(`project-main-view-player-${id}`);
      playerObj.pause();

      const startX = coords[0][0];

      const startY = coords[0][1];

      let newTaskData;
      // check minimum delta x or y to create drawed task

      if (coords.length > 10) {
        // remove some points from the middle for smoother line
        const filteredCoords = coords.filter(
          (d, i) => i === 0 || i === savedCoords.length - 1 || i % 6 === 0,
        );

        setSavedCoords(filteredCoords);

        newTaskData = {
          coord: {
            left: startX,
            top: startY,
          },
          time: playerObj.currentTime() * 1000,
          coords: filteredCoords,
          duration: 3,
          taskType: lineTypes.freeLine,
        };
      } else {
        newTaskData = {
          coord: {
            left: startX,
            top: startY,
          },
          time: playerObj.currentTime() * 1000,
        };
      }

      updateNewTaskData(newTaskData);
    }
  };

  return (
    <div
      className="clickArea"
      onClick={handleVideoClickEvent}
      onMouseDown={() => setIsDragging(false)}
      onMouseMove={() => setIsDragging(true)}
    >
      {isNewTaskActive && (
        <svg
          style={{
            color: '#fff',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          preserveAspectRatio="none"
          width="100%"
          height="100%"
        >
          <path
            d={`${getLinePoints(savedCoords, lineType)}`}
            fill="none"
            stroke="#e32d4b"
            strokeWidth="4"
          />

          <polygon
            points={getArrowHeadPoints(savedCoords, lineType)}
            stroke="#e32d4b"
            strokeWidth="4"
            fill="#e32d4b"
          />
        </svg>
      )}
      <Canvas dimensions={dimensions} afterDraw={afterDraw} />
    </div>
  );
};

export default ClickArea;

ClickArea.propTypes = {
  id: PropTypes.string.isRequired,
  updateNewTaskData: PropTypes.func.isRequired,
  dimensions: PropTypes.shape(dimensionsShape).isRequired,
  isNewTaskActive: PropTypes.bool.isRequired,
  lineType: PropTypes.oneOf(Object.values(lineTypes)).isRequired,
};
