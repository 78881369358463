import PropTypes from 'prop-types';

export const matchShape = {
  url: PropTypes.string,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export const historyShape = {
  push: PropTypes.func.isRequired,
};

export const locationShape = {
  pathname: PropTypes.string.isRequired,
};
