import React from 'react';
import PropTypes from 'prop-types';
import './LoadingDots.css';

const LoadingDots = (props) => {
  const styles = {
    fontSize: `${props.fontSize}em`,
    width: `${props.width}px`,
    margin: `-${props.width / 5}px 0 0 0`,
    color: props.color ? props.color : 'inherit',
  };

  return (
    <div className="loadingWrapper">
      <div className="loading" style={styles} />
    </div>
  );
};

export default LoadingDots;

LoadingDots.propTypes = {
  fontSize: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
