import React from 'react';
import PropTypes from 'prop-types';
import { forgotPassword, forgotPasswordSubmit } from '../../../../apiServices/authApi';
import { historyShape } from '../../../../routerPropTypes';
import NotificationContext from '../../../../context/notification';
import ExperimentalButton from '../../../../components/buttons/experimentalButton/experimentalButton';
import TextInput from '../../../../components/inputs/textInput';
import '../login.scss';

class ForgotPassword extends React.Component {
  static contextType = NotificationContext;

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailFormProcess: { status: 'inactive' },
      verifyFormProcess: { status: 'inactive' },
      code: '',
      newPassword: '',
      confirmNewPassword: '',
      emailWasSubmitted: false,
    };
  }

  handleEmailFieldChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleCodeFieldChange = (event) => {
    this.setState({ code: event.target.value });
  };

  handleNewPasswordFieldChange = (event) => {
    this.setState({ newPassword: event.target.value });
  };

  handleConfirmNewPasswordFieldChange = (event) => {
    this.setState({ confirmNewPassword: event.target.value });
  };

  handleEmailFormSubmit = async (event) => {
    event.preventDefault();
    if (this.state.email !== '') {
      const { email } = this.state;
      try {
        this.setState({ emailFormProcess: { status: 'loading' } });
        const forgotPasswordResponse = await forgotPassword(email);
        this.setState({
          emailWasSubmitted: true,
          emailFormProcess: { status: 'success', data: forgotPasswordResponse },
        });
      } catch (forgotPasswordError) {
        this.setState({
          emailFormProcess: { status: 'error', data: forgotPasswordError },
        });
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not perform action',
        });
      }
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Form is not complete',
      });
    }
  };

  handleEmailAndNewPasswordFieldSubmit = async (event) => {
    event.preventDefault();
    if (
      this.state.code !== '' &&
      this.state.newPassword !== '' &&
      this.state.confirmNewPassword !== ''
    ) {
      if (this.state.newPassword === this.state.confirmNewPassword) {
        try {
          this.setState({ verifyFormProcess: { status: 'loading' } });
          const { email, code, newPassword } = this.state;
          const forgotPasswordSubmitResponse = await forgotPasswordSubmit(email, code, newPassword);
          this.setState({
            verifyFormProcess: { status: 'success', data: forgotPasswordSubmitResponse },
            newPassword: '',
            confirmNewPassword: '',
            code: '',
          });
          this.props.history.push('/login');
        } catch (forgotPasswordSubmitError) {
          this.setState({
            verifyFormProcess: { status: 'error', data: forgotPasswordSubmitError },
            newPassword: '',
            confirmNewPassword: '',
            code: '',
          });
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not perform action',
          });
        }
      } else {
        this.setState({
          newPassword: '',
          confirmNewPassword: '',
          code: '',
        });
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: 'Given passwords do not match',
        });
      }
    } else {
      this.setState({
        newPassword: '',
        confirmNewPassword: '',
        code: '',
      });
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Form is not complete',
      });
    }
  };

  render() {
    let emailFormButtonStyles = this.state.emailFormProcess.status;
    if (this.state.email.length > 0 && this.state.emailFormProcess.status === 'inactive') {
      emailFormButtonStyles = 'active';
    }
    let verifyFormButtonStyle = this.state.verifyFormProcess.status;
    if (
      this.state.newPassword.length > 0 &&
      this.state.confirmNewPassword.length > 0 &&
      this.state.code.length > 0 &&
      this.state.verifyFormProcess.status === 'inactive'
    ) {
      verifyFormButtonStyle = 'active';
    }

    return (
      <div className="forgotPassword">
        <div className="card">
          {!this.state.emailWasSubmitted && (
            <>
              <div className="header">
                <h5>Forgot password</h5>
                <p>We will send verification code to your email</p>
              </div>
              <div className="contentWrapper">
                <form onSubmit={this.handleEmailFormSubmit}>
                  <div className="inputsWrapper">
                    <div className="inputWrapper">
                      <TextInput
                        type="text"
                        value={this.state.email}
                        onChange={this.handleEmailFieldChange}
                        placeholder=" "
                        id="email"
                        autoFocus
                      />
                      <label htmlFor="email">Email</label>
                    </div>
                  </div>
                  <div className="buttonWrapper">
                    <ExperimentalButton
                      isDisabled={emailFormButtonStyles === 'inactive'}
                      loading={this.state.emailFormProcess.status === 'loading'}
                      htmlType="submit"
                    >
                      {emailFormButtonStyles === 'error' ? 'Try again' : 'Send'}
                    </ExperimentalButton>
                  </div>
                </form>
              </div>
            </>
          )}
          {this.state.emailWasSubmitted && (
            <>
              <div className="header">
                <h5>Verify</h5>
                <p>Pass in the code from email we sent to you and set your new password</p>
              </div>
              <div className="contentWrapper">
                <form onSubmit={this.handleEmailAndNewPasswordFieldSubmit}>
                  <div className="inputsWrapper">
                    <div className="inputWrapper">
                      <TextInput
                        type="text"
                        value={this.state.code}
                        onChange={this.handleCodeFieldChange}
                        placeholder=" "
                        id="code"
                        autoFocus
                      />
                      <label htmlFor="code">Code</label>
                    </div>
                    <div className="inputWrapper">
                      <TextInput
                        type="password"
                        value={this.state.newPassword}
                        onChange={this.handleNewPasswordFieldChange}
                        placeholder=" "
                        id="password1"
                      />
                      <label htmlFor="password1">New password</label>
                    </div>
                    <div className="inputWrapper">
                      <TextInput
                        type="password"
                        value={this.state.confirmNewPassword}
                        onChange={this.handleConfirmNewPasswordFieldChange}
                        placeholder=" "
                        id="password2"
                      />
                      <label htmlFor="password2">Confirm password</label>
                    </div>
                  </div>
                  <div className="buttonWrapper">
                    <ExperimentalButton
                      isDisabled={verifyFormButtonStyle === 'inactive'}
                      loading={this.state.verifyFormProcess.status === 'loading'}
                      htmlType="submit"
                    >
                      {emailFormButtonStyles === 'error' ? 'Try again' : 'Send'}
                    </ExperimentalButton>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;

ForgotPassword.propTypes = {
  // router props
  history: PropTypes.shape(historyShape).isRequired,
};
