import React from 'react';
import PropTypes from 'prop-types';
import ArrowUpIcon from '../../../../../components/icons/ArrowUpIcon';
import { locationShape } from '../../../../../routerPropTypes';
import { showTypeItemShape } from '../FolderPropTypes';
import './folderViewHeader.scss';
import ShowTypeSection from './showTypeSection/ShowTypeSection';

const sortByCriteria = ['Date', 'Name', 'Type'];

class FolderViewHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTypeDropdownOpen: false,
    };
    this.showTypeWrapperNode = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    if (this.state.showTypeDropdownOpen) {
      if (!this.showTypeWrapperNode.current.contains(event.target)) {
        this.setState({ showTypeDropdownOpen: false });
      }
    }
  };

  handleShowTypeButtonClick = () => {
    if (!this.props.disabled) {
      const { showTypeDropdownOpen } = this.state;
      this.setState({ showTypeDropdownOpen: !showTypeDropdownOpen });
    }
  };

  render() {
    const isAscending = this.props.sortByDirectionIsAscending;
    return (
      <div className="folderViewHeader">
        <div className="sortBySection">
          <h5 className="secondaryText">Sort by:</h5>
          <h4 onClick={this.props.handleSortByCriteriaClicked}>
            {this.props.sortByCriteriaIndex === undefined && sortByCriteria[1]}
            {this.props.sortByCriteriaIndex !== undefined
              && sortByCriteria[this.props.sortByCriteriaIndex]}
          </h4>
          <div className="divider" />
          <div
            onClick={this.props.handleArrowClick}
            title={isAscending ? 'ascending' : 'descending'}
            className={`arrow ${isAscending ? 'up' : 'down'}`}
          >
            <ArrowUpIcon height="12px" fill="black" />
          </div>

        </div>
        <div />

        {this.props.location.pathname !== '/in/folder/root' && this.props.showTypes && (
          <ShowTypeSection
            handleShowTypesChange={this.props.handleShowTypesChange}
            showTypes={this.props.showTypes}
          />
        )}
      </div>
    );
  }
}

export default FolderViewHeader;

FolderViewHeader.propTypes = {
  // router props
  location: PropTypes.shape(locationShape).isRequired,

  // Folder.jsx props
  sortByCriteriaIndex: PropTypes.number.isRequired,
  showTypes: PropTypes.arrayOf(PropTypes.shape(showTypeItemShape)),
  handleShowTypesChange: PropTypes.func,
  handleSortByCriteriaClicked: PropTypes.func.isRequired,
  sortByDirectionIsAscending: PropTypes.bool.isRequired,
  handleArrowClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

FolderViewHeader.defaultProps = {
  showTypes: undefined,
  handleShowTypesChange: undefined,
};
