const getMsgCount = (messagesList, id) => {
  let msgCount = 0;
  messagesList.forEach((message) => {
    if (id === message.project.S) {
      if (message.statustype && message.statustype.S === 'msg') {
        if (!(message.read && message.read.N === '0')) {
          msgCount = msgCount + 1;
        }
      }
    }
  });
  return msgCount;
};

const getUnreadMessagesCount = (messagesList, id) => {
  let unreadMessagesCount = 0;
  messagesList.forEach((message) => {
    if (id === message.project.S) {
      if (message.statustype && message.statustype.S === 'msg') {
        if (message.read && message.read.N === '0') {
          unreadMessagesCount = unreadMessagesCount + 1;
        }
      }
    }
  });
  return unreadMessagesCount;
};

const getTaskMsgCount = (messagesList, id) => {
  let taskMsgCount = 0;
  messagesList.forEach((message) => {
    if (id === message.project.S) {
      if (message.statustype && message.statustype.S === 'task') {
        if (!(message.read && message.read.N === '0')) {
          taskMsgCount = taskMsgCount + 1;
        }
      }
    }
  });
  return taskMsgCount;
};

const getUnreadTaskMsgsCount = (messagesList, id) => {
  let unreadTaskMsgsCount = 0;
  messagesList.forEach((message) => {
    if (id === message.project.S) {
      if (message.statustype && message.statustype.S === 'task') {
        if (message.read && message.read.N === '0') {
          unreadTaskMsgsCount = unreadTaskMsgsCount + 1;
        }
      }
    }
  });
  return unreadTaskMsgsCount;
};

const getUniqProjectObjList = (messagesList) => {
  const messagesProjectIdList = messagesList.map((message) => message.project.S);
  const uniqProjectList = [...new Set(messagesProjectIdList)];
  return uniqProjectList.map((id) => {
    const tempObj = messagesList.find((message) => message.project.S === id);
    const msgCount = getMsgCount(messagesList, id);
    const unreadMessagesCount = getUnreadMessagesCount(messagesList, id);
    const taskMsgCount = getTaskMsgCount(messagesList, id);
    const unreadTaskMsgsCount = getUnreadTaskMsgsCount(messagesList, id);
    return {
      title: tempObj.project_title.S,
      projectId: id,
      taskMsgCount,
      unreadTaskMsgsCount,
      msgCount,
      unreadMessagesCount,
    };
  });
};

const getProcessedUniqProjectObjList = (uniqProjectObjList, messagesList) => {
  const copiedList = [...uniqProjectObjList];
  copiedList.forEach((obj, index) => {
    let latestMessage = '0';
    messagesList.forEach((message) => {
      if (message.project.S === obj.projectId) {
        if (message.created.S > latestMessage) {
          latestMessage = message.created.S;
        }
      }
    });
    const copiedObj = {
      ...obj,
      latestMessage,
    };
    copiedList[index] = copiedObj;
  });
  return copiedList;
};

const getSortedUniqProjectObjList = (processedUniqProjectObjList) => {
  const copiedList = [...processedUniqProjectObjList];
  return copiedList.sort((a, b) => {
    if (a.latestMessage > b.latestMessage) return -1;
    else if (a.latestMessage > b.latestMessage) return 1;
    return 0;
  });
};

export const processMessagesListData = (messagesList) => {
  const uniqProjectObjList = getUniqProjectObjList(messagesList);
  const processedUniqProjectObjList = getProcessedUniqProjectObjList(
    [...uniqProjectObjList],
    messagesList,
  );
  return getSortedUniqProjectObjList([...processedUniqProjectObjList]);
};
