import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DocumentsIcon } from '../../../../../assets/nitro/documents.svg';
import { ReactComponent as VideoIcon } from '../../../../../assets/nitro/videos.svg';
import { ReactComponent as SoundsIcon } from '../../../../../assets/soundwave.svg';
import { validateLocalVideoFileRendability } from '../../../../../util/FileTypeSupportCheck';
import './listItemThumbnail.scss';
import { getExtFromFileName } from '../../../../../util/UtilFunctions';
import AllfilesIcon from '../../../../icons/AllfilesIcon';

class ListItemThumbnail extends React.Component {
  getVideoThumbnailUrl = (snapshotBlob) => {
    if (snapshotBlob?.thumbnailBlob) {
      return URL.createObjectURL(this.props.snapshotBlob.thumbnailBlob);
    } else {
      return undefined;
    }
  };

  getPdfThumbnailUrl = (snapshotBlob) => {
    if (snapshotBlob?.thumbnail) {
      return URL.createObjectURL(snapshotBlob.thumbnail);
    } else {
      return undefined;
    }
  };

  getVideoThumbnail = (videoThumbnailUrl) => {
    if (videoThumbnailUrl !== undefined) {
      return <img src={videoThumbnailUrl} alt="video thumbnail" />;
    } else {
      return <VideoIcon className="video" />;
    }
  };

  getThumbnail = (type) => {
    switch (type) {
      case 'image':
        return <img src={URL.createObjectURL(this.props.file)} alt="thumbnail" />;
      case 'audio': {
        return <SoundsIcon className="audio" />;
      }
      case 'application': {
        const isPdf = getExtFromFileName(this.props.file.name) === 'pdf';
        if (!isPdf) {
          return <DocumentsIcon className="document" />;
        } else {
          const pdfThumbnailUrl = this.getPdfThumbnailUrl(this.props.snapshotBlob);
          if (pdfThumbnailUrl !== undefined) {
            return <img src={pdfThumbnailUrl} alt="pdf thumbnail" />;
          } else {
            return <DocumentsIcon className="document" />;
          }
        }
      }
      case 'video': {
        const videoIsRendable = validateLocalVideoFileRendability(this.props.file);
        if (!this.props.videoPlayerError && videoIsRendable) {
          return (
            <>
              <div id={this.props.videoWrapperId} className="videoWrapper" />
              {this.getVideoThumbnail(this.getVideoThumbnailUrl(this.props.snapshotBlob))}
            </>
          );
        } else {
          return this.getVideoThumbnail(this.getVideoThumbnailUrl(this.props.snapshotBlob));
        }
      }
      case 'folder': {
        return <AllfilesIcon className="folder" />;
      }
      default: {
        return <img src={this.props.file} alt="thumbnail" />;
      }
    }
  };

  render = () => {
    const type = this.props.file.type.split('/')[0];
    const thumbnail = this.getThumbnail(type);
    return <div className="listItemThumbnail">{thumbnail}</div>;
  };
}

export default ListItemThumbnail;

ListItemThumbnail.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  snapshotBlob: PropTypes.object,
  videoPlayerError: PropTypes.object,
  videoWrapperId: PropTypes.string.isRequired,
};

ListItemThumbnail.defaultProps = {
  snapshotBlob: undefined,
  videoPlayerError: undefined,
};
