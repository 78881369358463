import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import NotificationContext from '../../../context/notification';
import { convertFile } from '../../../apiServices/resourceApi';
import { borderRadiusDefault } from '../../../styles/variables';
import { folderListResourceItemShape } from '../../../app/views/folderView/FolderViewPropTypes';

export const ConvertModal = (props) => {
  const [convertingFileProcess, setConvertingFileProcess] = React.useState({ status: 'initial' });
  const { add } = React.useContext(NotificationContext);

  const convert = async () => {
    try {
      setConvertingFileProcess({ status: 'loading' });
      props.updateIsQuerying(true);
      const convertFileResponse = await convertFile(props.item.id);

      props.updateIsQuerying(false);
      props.refreshList();
      setConvertingFileProcess({ status: 'success', data: convertFileResponse });
      add({
        id: Date.now(),
        type: 'success',
        message: 'Conversion process started, file will appear on refresh',
      });
    } catch (convertFileError) {
      props.refreshList();
      add({
        id: Date.now(),
        type: 'error',
        message: 'Could not convert file',
      });
      setConvertingFileProcess({ status: 'error', error: convertFileError });
    }
  };

  return (
    <Wrapper>
      <Headline>{`Convert file "${props.item.name}" to an mp4?`}</Headline>
      <ContentWrapper>
        <ExperimentalButton onClick={convert} loading={convertingFileProcess.status === 'loading'}>
          Convert
        </ExperimentalButton>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  border-radius: ${borderRadiusDefault};
  width: 100%;
  height: 200px;
`;

const Headline = styled.h4`
  text-align: center;
  font-size: 1.3em;
  margin: 0 0 1rem 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    padding: 0.5rem 0;
    width: 7rem;
    margin: 2rem auto;
  }
`;

ConvertModal.propTypes = {
  updateIsQuerying: PropTypes.func.isRequired,
  item: PropTypes.shape(folderListResourceItemShape).isRequired,
  refreshList: PropTypes.func,
};

ConvertModal.defaultProps = {
  refreshList: undefined,
};
