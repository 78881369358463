export const getCurrentUserIsAdminOrPro = (currentUserInfo) => {
  if (currentUserInfo) {
    return currentUserInfo.role.S === 'admin' || currentUserInfo.role.S === 'pro';
  } else {
    return false;
  }
};

export const getWorkspaceParticipantsNotYetParticipatingInProjectList = (
  workspacePeopleList,
  usersInProjectList,
) => {
  return workspacePeopleList.filter((person) => {
    return !usersInProjectList.find((i) => i.uid === person.uid);
  });
};
