import React from 'react';
import PropTypes from 'prop-types';
import classes from './Folder.module.css';
import ItemList from '../../../../components/itemList/ItemList';
import FolderViewHeader from './folderViewHeader/FolderViewHeader';
import ItemListLoading from '../../../../components/itemListLoading/ItemListLoading';
import ItemNotFound from '../../../../components/itemNotFound/ItemNotFound';
import ExperimentalButton from '../../../../components/buttons/experimentalButton/experimentalButton';
import { locationShape, matchShape, historyShape } from '../../../../routerPropTypes';
import { currentUserInfoShape } from '../../../../apiServices/userApiTypes';
import { folderListItemShape } from '../FolderViewPropTypes';
import { filterListByFileType } from './FolderUtilityFunctions';
import { statusOptions } from '../../../AppPropTypes';

const initialState = {
  sortByCriteriaIndex: 0,
  sortByDirectionIsAscending: true,
  showTypes: [
    { label: 'video', filterStatus: false },
    { label: 'audio', filterStatus: false },
    { label: 'image', filterStatus: false },
    { label: 'doc', filterStatus: false },
    { label: 'folder', filterStatus: false },
    { label: 'project', filterStatus: false },
  ],
};

class Folder extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.cancelled = false;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.currentItem.id !== this.props.currentItem.id) {
      this.setState(initialState);
    }
  };

  componentWillUnmount() {
    this.cancelled = true;
  }

  handleArrowClick = () => {
    const { sortByDirectionIsAscending } = this.state;
    this.setState({ sortByDirectionIsAscending: !sortByDirectionIsAscending });
  };

  handleSortByCriteriaClicked = () => {
    let newIndex;
    if (this.state.sortByCriteriaIndex === 2) {
      newIndex = 0;
    } else {
      // eslint-disable-next-line
      newIndex = this.state.sortByCriteriaIndex + 1;
    }
    this.setState({ sortByCriteriaIndex: newIndex });
  };

  handleShowTypesChange = (changeTypeObj) => {
    this.setState({ showTypes: changeTypeObj });
  };

  render() {
    let content = <div />;
    switch (this.props.queryProcessObj.status) {
      case 'loading':
        content = <ItemListLoading amount={15} />;
        break;
      case 'success': {
        const typeFilteredList = filterListByFileType(
          this.props.queryProcessObj.data,
          this.state.showTypes,
        );

        content = (
          <ItemList
            showTypes={this.state.showTypes}
            sortByCriteriaIndex={this.state.sortByCriteriaIndex}
            sortByDirectionIsAscending={this.state.sortByDirectionIsAscending}
            content={typeFilteredList}
            match={this.props.match}
            currentUserGroup={this.props.currentUserGroup}
            refreshList={this.props.refreshList}
            currentItem={this.props.currentItem}
            inProjectInitialState={false}
            history={this.props.history}
            updateIsQuerying={this.props.updateIsQuerying}
            currentUserInfo={this.props.currentUserInfo}
            useCase="folder"
            location={this.props.location}
          />
        );
        break;
      }
      case 'error': {
        if (this.props.match.params.id !== 'root') {
          content = <ItemNotFound history={this.props.history} />;
        } else {
          content = (
            <div className={classes.errorWrapper}>
              <h5>Something went wrong</h5>
              <ExperimentalButton
                onClick={() => this.props.initateMainView(false, undefined, true)}
                title="try again"
                type="primary"
              >
                Try again
              </ExperimentalButton>
            </div>
          );
        }
        break;
      }
      default:
        content = <div />;
    }
    return (
      <div className={classes.wrapper}>
        <FolderViewHeader
          sortByCriteriaIndex={this.state.sortByCriteriaIndex}
          sortByDirectionIsAscending={this.state.sortByDirectionIsAscending}
          showTypes={this.state.showTypes}
          handleShowTypesChange={this.handleShowTypesChange}
          handleArrowClick={this.handleArrowClick}
          handleSortByCriteriaClicked={this.handleSortByCriteriaClicked}
          location={this.props.location}
          disabled={this.props.queryProcessObj.status !== 'success'}
        />
        {content}
      </div>
    );
  }
}

export default Folder;

Folder.propTypes = {
  // router props
  location: PropTypes.shape(locationShape).isRequired,
  match: PropTypes.shape(matchShape).isRequired,
  history: PropTypes.shape(historyShape).isRequired,

  // app.jsx props
  currentUserInfo: PropTypes.shape(currentUserInfoShape),
  currentUserGroup: PropTypes.string.isRequired,
  currentItem: PropTypes.object.isRequired,

  // folderView.jsx props
  queryProcessObj: PropTypes.shape({
    status: PropTypes.oneOf(statusOptions).isRequired,
    siteLoaded: PropTypes.bool,

    // shape of folderListNonResourceItemShape or folderListResourceItemShape,
    data: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)),
    prevData: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)),
  }).isRequired,
  refreshList: PropTypes.func.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  initateMainView: PropTypes.func.isRequired,
};

Folder.defaultProps = {
  currentUserInfo: undefined,
};
