import React from 'react';
import styles from './packageCard.module.scss';

const PackageCard = ({ title, price, disclaimer, buttonText, onButtonClick, features }) => {
  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      <h3>{`${price} € per month`}</h3>
      <ul>
        {features.map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
      <p className={styles.disclaimer}>{disclaimer}</p>

      <button onClick={onButtonClick} type="button" className={styles.link}>
        {buttonText}
      </button>
    </div>
  );
};

export default PackageCard;
