import { API } from 'aws-amplify';
import store from '../configureStore';
import { config } from '../config/Config';

const searchItems = (searchString) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const searchItemsInit = {
    headers: header,
  };

  return API.get(
    config.apiGateway.NAME,
    `/search?gid=${currentUserGroup}&search=${searchString}`,
    searchItemsInit,
  );
};

export default searchItems;
