export const roleDefinitions = {
  rootWorkspaceAdmin: [
    'Handles billing',
    'Is in control of the main workspace under which other workspaces can be created.',
    'Can delete and invite admins.',
  ],
  admin: [
    'Can participate in a root workspace',
    'Can create sub workspaces',
    'Can delete pro users.',
  ],
  pro: [
    'Can participate in sub workspaces.',
    'Can invite user level users to workspaces.',
    'Can add user level users to specific projects.',
    'Is automatically part of every project.',
  ],
  user: [
    'Can participate in the workspace normally',
    'can upload files',
    'can work in a project in which the user has access.',
  ],
  viewer: [
    'Can see content in the workspace, view projects you have access to.',
    'Can download',
    'Can share content.',
  ],
};
