import React, { useState, useEffect } from 'react';
import './ProjectMainViewModals.scss';
import classes from './ProjectMainView.module.scss';
import ProjectChat from './projectChat/ProjectChat';
import FileUploadingModal from '../../../../components/modals/fileUploadingModal/FileUploadingModal';
import ProjectMainViewWorkSection from './projectMainViewWorkSection/ProjectMainViewWorkSection';
import { getPreviewItemIdFromLocalStorage } from './ProjectMainViewUtilityFunctions';
import { ReactComponent as CrossIcon } from '../../../../assets/nitro/cross.svg';
import DeleteItemModal from '../../../../components/modals/deleteItemModal/DeleteItemModal';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem, setItems } from '../../../features/project/projectSlice';
import { getFilesForProject } from '../../../../apiServices/projectApi';

const ProjectMainView = (props) => {
  const [messagesTabOpen, setMessagesTabOpen] = useState(true);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(undefined);
  const [modalParams, setModalParams] = useState(undefined);
  const { project } = useSelector((state) => state);
  const { items, users, selectedItem, projectId, userRole } = project;
  const dispatch = useDispatch();
  // const getUpdatedSelectedTask = (getTasksResponse, workspacePeopleList) => {
  //   const modifiedTasksList = getTasksList(
  //     getTasksResponse.response.resources,
  //     workspacePeopleList,
  //   );
  //   return modifiedTasksList.find((task) => task.id.S === selectedTask.id.S);
  // };

  // getTasks = async (updating) => {
  //   if (!updating) {
  //     setState({ getTasksProcess: { status: 'loading' } });
  //   }
  //   try {
  //     props.updateIsQuerying(true);
  //     const getTasksResponse = await getTasks(props.match.params.id);
  //     console.log('tasks response', getTasksResponse);
  //     props.updateIsQuerying(false);
  //     setState({
  //       getTasksProcess: { status: 'success', data: getTasksResponse.response.resources },
  //     });
  //     if (selectedTask) {
  //       const updatedSelectedTask = getUpdatedSelectedTask(getTasksResponse, props.users);
  //       if (updatedSelectedTask) {
  //         updateSelectedTask(updatedSelectedTask);
  //       }
  //     }
  //   } catch (getTasksError) {
  //     props.updateIsQuerying(false);
  //     setState({ getTasksProcess: { status: 'error', data: getTasksError } });
  //   }
  // };

  useEffect(() => {
    if (!selectedItem && items && projectId) {
      const prevId = getPreviewItemIdFromLocalStorage(projectId);
      const prevItems = items?.filter((i) => i.preview);
      if (prevItems.length) {
        dispatch(setSelectedItem(prevItems.find((i) => i.id === prevId) || prevItems[0]));
      }
    }
  }, [selectedItem, items, projectId]);

  useEffect(() => {
    if (messagesTabOpen) {
      setSelectedTask(undefined);
    }
  }, [messagesTabOpen]);

  const updateModalOpen = (type, params) => {
    if (type) {
      setModalParams(params);
      setModalOpen(type);
    } else {
      setModalOpen(false);
    }
  };

  const updateAllProjectItems = () => {
    // thumbnail takes some time apperently

    return new Promise((resolve) =>
      setTimeout(
        getFilesForProject(project.projectId).then((res) => {
          // console.log('Update all items');
          dispatch(setItems(res.response.Items));
          resolve();
        }),
        1000,
      ),
    );
  };

  return (
    <>
      <h5>{selectedItem?.title}</h5>
      <div className={classes.wrapper}>
        <ProjectMainViewWorkSection
          messagesTabOpen={messagesTabOpen}
          selectedTask={selectedTask}
          updateSelectedTask={setSelectedTask}
          updateModalOpen={updateModalOpen}
          match={props.match}
        />
        {!!users && (
          <div className={classes.chatSection}>
            <ProjectChat
              match={props.match}
              users={users}
              currentUserGroup={props.currentUserGroup}
              updateSelectedTask={setSelectedTask}
              messagesTabOpen={messagesTabOpen}
              updateMessagesTabOpen={(value) => setMessagesTabOpen(value)}
              previewItemsList={items?.filter((i) => i.preview)}
              previewItemOnShow={selectedItem}
              updateIsQuerying={props.updateIsQuerying}
              getProjectProcess={props.getProjectProcess}
              updateModalOpen={updateModalOpen}
            />
          </div>
        )}
      </div>
      <div
        className={`projectMainViewModals ${modalOpen ? modalOpen : 'closed'}`}
        onClick={() => {}}
      >
        <div className="modalContent">
          <div className="header">
            <button onClick={() => updateModalOpen()} title="close modal" type="button">
              <CrossIcon />
            </button>
          </div>
          {modalOpen === 'fileUpload' && (
            <FileUploadingModal
              closeModal={updateModalOpen}
              currentUserGroup={props.currentUserGroup}
              currentItem={project.projectId}
              inProjectInitialState
              useCase="project"
              history={props.history}
              updateIsQuerying={() => {
                console.log('outdated');
              }}
              refreshList={async () => {
                await updateAllProjectItems().then(() => {
                  props.history.push(`/in/project/${projectId}/allfiles`);
                });
              }}
              content={items}
            />
          )}
          {modalOpen === 'deleteItem' && (
            <DeleteItemModal
              closeModal={updateModalOpen}
              action={modalParams.action}
              selectedItems={[]}
              userIsAdmin={false}
              updateIsQuerying={props.updateIsQuerying}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectMainView;
