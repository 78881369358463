import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorDenimPrimary, colorGreyPrimary, colorRedSecondary } from '../../styles/variables';
import { folderListItemShape } from '../../app/views/folderView/FolderViewPropTypes';
import { ItemTypeIcon } from '../itemTypeIcon/ItemTypeIcon';
import { ReactComponent as CrossIcon } from '../../assets/nitro/cross.svg';
import NotificationContext from '../../context/notification';

class SelectedItemsList extends React.Component {
  static contextType = NotificationContext;

  handleRemoveItemClick = (item) => {
    if (this.props.selectedItems.length > 1) {
      this.props.removeFromSelectedItems([item]);
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        messag: "Can't remove only item",
      });
    }
  };

  render() {
    const listItems = this.props.selectedItems.map((item) => {
      const title = item.title || item.name;
      return (
        <ListItem key={title}>
          <ItemTypeIcon
            itemType={item.itemtype}
            fileGenre={item.type && item.type}
            size={12}
            color="#233c4d"
          />
          <Title>{title}</Title>
          <Button
            className="removeButton"
            onClick={() => this.handleRemoveItemClick(item)}
            type="button"
          >
            <CrossIcon />
          </Button>
        </ListItem>
      );
    });
    return <List>{listItems}</List>;
  }
}

export default SelectedItemsList;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: 100px;
  overflow-y: auto;
`;

const ListItem = styled.li`
  max-width: 200px;
  height: 30px;
  padding: 5px 5px;
  border: 1px solid ${colorDenimPrimary};
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
`;

const Title = styled.h5`
  margin: 0 5px;
  max-height: 20px;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
`;

const Button = styled.button`
  border: 1px solid ${colorGreyPrimary};
  outline: none;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;

  svg {
    width: 1rem;
    fill: lighten(${colorRedSecondary}, 15);
    transition: all 0.2s linear;
  }
  &:hover {
    background-color: lighten(${colorRedSecondary}, 15);
    svg {
      fill: #fff;
    }
  }
`;

SelectedItemsList.propTypes = {
  // ItemList.jsx props
  selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  removeFromSelectedItems: PropTypes.func.isRequired,
};
