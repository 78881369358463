import { getValueFromUserAttributes } from '../../../util/UtilFunctions';

const getReceiverFirstName = (email) => {
  if (email.split('.').length > 1) {
    return email.split('.')[0];
  }
  return undefined;
};

const getReceiverLastName = (email) => {
  if (email.length > 0) {
    const receiverDotSplitArr = email.split('.');
    if (receiverDotSplitArr.length > 1) {
      const receiverStrBottomPart = receiverDotSplitArr[1];
      if (receiverStrBottomPart.split('@').length > 1) {
        const receiverLastName = receiverStrBottomPart.split('@')[0];
        return receiverLastName;
      }
    }
  }
  return undefined;
};

const capitalize = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return undefined;
};

export const getEmailSenderInfo = (userInfo) => {
  const senderFirstNameUnCapitalized = getValueFromUserAttributes(
    userInfo.cognitoAttributes.UserAttributes,
    'given_name',
  );
  const firstName = capitalize(senderFirstNameUnCapitalized);
  const senderLastNameUnCapitalized = getValueFromUserAttributes(
    userInfo.cognitoAttributes.UserAttributes,
    'family_name',
  );
  const lastName = capitalize(senderLastNameUnCapitalized);
  const email = getValueFromUserAttributes(userInfo.cognitoAttributes.UserAttributes, 'email');
  return {
    firstName,
    lastName,
    email,
  };
};

export const getEmailReceiverInfo = (email) => {
  const receiverFirstNameUnCapitalized = getReceiverFirstName(email);
  const firstName = capitalize(receiverFirstNameUnCapitalized);
  const receiverLastNameUnCapitalized = getReceiverLastName(email);
  const lastName = capitalize(receiverLastNameUnCapitalized);
  return {
    firstName,
    lastName,
  };
};

export const getEmailSubjectLine = (emailSenderInfo, selectedFiles) => {
  if (selectedFiles.length === 1) {
    return `${emailSenderInfo.firstName} ${emailSenderInfo.lastName} shared '${selectedFiles[0].name}' with you`;
  } else if (selectedFiles.length > 1) {
    return `${emailSenderInfo.firstName} ${emailSenderInfo.lastName} shared multiple files with you`;
  }
  return '';
};

export const getEmailMsg = (emailSenderInfo, emailReceiverInfo, selectedFiles, link) => {
  if (selectedFiles.length === 1) {
    return `Hello ${emailReceiverInfo.firstName} ${emailReceiverInfo.lastName} --- ${emailSenderInfo.firstName} ${emailSenderInfo.lastName} (${emailSenderInfo.email}) invited you to view the file '${selectedFiles[0].name}' on Yemma. --- ${link}`;
  } else if (selectedFiles.length > 1) {
    return `Hello ${emailReceiverInfo.firstName} ${emailReceiverInfo.lastName} --- ${emailSenderInfo.firstName} ${emailSenderInfo.lastName} (${emailSenderInfo.email}) invited you to view multiple files on Yemma. --- ${link}`;
  }
  return '';
};

export const getIdsList = (selectedFiles, modalParams) => {
  if (selectedFiles.length > 0) {
    return selectedFiles.map((file) => file.id);
  } else {
    if (modalParams[0]) {
      return [modalParams[0].selectedFiles[0].id];
    } else {
      return undefined;
    }
  }
};
