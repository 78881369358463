import React from 'react';
import styled from 'styled-components';
import TaskMessage from '../projectMainViewComponents/taskMessage/TaskMessage';
import NewTask from '../projectMainViewComponents/newTask/NewTask';
import Dot from '../projectMainViewComponents/dot/Dot';
import { getXYPercent } from './ProjectMainViewImageUtilityFunctions';
import { ReactComponent as FullScreenIcon } from '../../../../../../assets/nitro/fullscreen.svg';
import { ReactComponent as Cross } from '../../../../../../assets/nitro/cross.svg';
import { lineTypes } from '../projectMainViewVideoPlayer/arrowsOverlay/utils';
import ClickArea from './clickArea';
import Line from './line';
import LazyLoadPicture from '../../../../../../components/common/lazyloadImage';

class ProjectMainViewImage extends React.Component {
  constructor() {
    super();
    this.state = {
      clickEvent: undefined,
      imageErrored: false,
      fullScreen: false,
      newTaskData: undefined,
      newLineType: lineTypes.freeLine,
      loading: true,
    };
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.listenToScroll);
    window.addEventListener('resize', this.updateDimensions);
    this.setState({
      dimensions: {
        width: this.imageWrapperRef.offsetWidth,
        height: this.imageWrapperRef.offsetHeight,
        offsetLeft: this.imageWrapperRef.offsetLeft,
        offsetTop: this.imageWrapperRef.offsetTop,
      },
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.listenToScroll);
    window.removeEventListener('resize', this.updateDimensions);
  };

  updateDimensions = () => {
    if (this.imageWrapperRef) {
      this.setState({
        dimensions: {
          width: this.imageWrapperRef.offsetWidth,
          height: this.imageWrapperRef.offsetHeight,
          offsetLeft: this.imageWrapperRef.offsetLeft,
          offsetTop: this.imageWrapperRef.offsetTop,
        },
      });
    } else {
      this.setState({
        dimensions: undefined,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.fullScreen !== prevState.fullScreen) {
      this.updateDimensions();
      window.scroll(0, 0);
    }
    if (this.props.resource.id !== prevProps.resource.id) {
      this.setState({ loading: true });
    }
  };

  listenToScroll = () => {
    if (window.scrollY > 0 && this.state.fullScreen) {
      this.setState({ fullScreen: false });
    }
  };

  updateNewTaskData = (newTaskData) => {
    if (!newTaskData) {
      this.setState({ newTaskData: undefined, newLineType: lineTypes.freeLine });
    } else {
      this.setState({
        newTaskData,
      });
    }
  };

  // updateNaturalImageClickSpot = (newX, newY) => {
  //   if (!(newX && newY)) {
  //     this.setState({ naturalImageClickSpot: undefined });
  //   } else {
  //     this.setState({
  //       naturalImageClickSpot: {
  //         x: newX,
  //         y: newY,
  //       },
  //     });
  //   }
  // };

  // handleImageWrapperClick = (event) => {
  //   event.stopPropagation();
  //   if (!this.props.messagesTabOpen) {
  //     const wrapperElement = document.getElementById(
  //       this.state.fullScreen ? 'fullscreenImageWrapper' : 'imageWrapper',
  //     );
  //     if (wrapperElement) {
  //       const relativeCoordinates = getRelativeCoordinates(event, wrapperElement);

  //       const widthRatio = event.target.naturalWidth / event.target.width;
  //       const heightRatio = event.target.naturalHeight / event.target.height;

  //       const clickOnResizedImageXCoordPosition =
  // relativeCoordinates.x - event.target.offsetLeft;
  //       const clickOnResizedImageYCoordPosition = relativeCoordinates.y - event.target.offsetTop;

  //       const clickOnNaturalImageXCoordPosition = Math.round(
  //         clickOnResizedImageXCoordPosition * widthRatio,
  //       );

  //       const clickOnNaturalImageYCoordPosition = Math.round(
  //         clickOnResizedImageYCoordPosition * heightRatio,
  //       );

  //       this.updateClickEvent(clickOnNaturalImageXCoordPosition,
  // clickOnNaturalImageYCoordPosition);
  //       this.updateNaturalImageClickSpot(
  //         clickOnNaturalImageXCoordPosition,
  //         clickOnNaturalImageYCoordPosition,
  //       );
  //     }
  //   }
  // };

  handleImageLoaded = () => {
    // window.requestAnimationFrame(() => {
    //   this.props.updateCurrentPreviewItemIsLoaded(true);
    // });
    this.setState({ loading: false });
    this.updateDimensions();
  };

  // handleImageErrored = () => {
  //   this.props.getProjectFileList();
  //   this.setState({ imageErrored: true });
  // };

  renderTaskDots = () =>
    this.props.tasks.map((task) => (
      // get coordinates as percent
      <>
        <Dot
          type="image"
          updateSelectedTask={this.props.updateSelectedTask}
          index={task.index}
          task={task}
          coord_x={parseInt(task.coord_x, 10)}
          coord_y={parseInt(task.coord_y, 10)}
          getXYPercent={getXYPercent}
          opacity={0.7}
          key={task.coord_x + task.coord_y}
          newTask={false}
          appearance={task.taskType}
        />
        {Object.values(lineTypes).includes(task.taskType) && !!this.state.dimensions?.width && (
          <Line lineType={task.taskType} coords={task.coords} dimensions={this.state.dimensions} />
        )}
      </>
    ));

  render() {
    return (
      <>
        {this.state.fullScreen && (
          <ProjectMainViewImageFullscreenWrapper>
            {!this.state.imageErrored && this.props.resource && (
              <ImageWrapper id="fullscreenImageWrapper">
                <FullScreenImage
                  src={this.props.resource.signedUrl}
                  alt="preview"
                  id="project-mainview-fullscreen-img"
                  onLoad={this.handleImageLoaded}
                  onError={this.handleImageErrored}
                  allowFullScreen={this.props.messagesTabOpen}
                  imgLoading={this.state.loading}
                />
                <>
                  {this.props.selectedTask && !this.state.loading && (
                    <TaskMessage
                      task={this.props.selectedTask}
                      updateSelectedTask={this.props.updateSelectedTask}
                      users={this.props.users}
                      videoFullscreen={false}
                    />
                  )}
                  {/* {this.state.clickEvent && (
                    <NewTask
                      clickEvent={this.state.clickEvent}
                      updateClickEvent={this.updateNewTaskData}
                      resource={this.props.resource}
                      newTaskIndex={this.props.highestIndex}
                      useCase="image"
                      toggleLineType={(value) => this.setState({ newLineType: value })}
                    />
                  )} */}
                  {!this.props.messagesTabOpen &&
                    this.props.tasks.length > 0 &&
                    !this.state.loading &&
                    !!this.state.dimensions?.width &&
                    this.renderTaskDots()}
                </>
                <CrossIconWrapper
                  onClick={() => this.setState({ fullScreen: !this.state.fullScreen })}
                  fullscreen={this.state.fullscreen}
                >
                  <Cross />
                </CrossIconWrapper>
              </ImageWrapper>
            )}
          </ProjectMainViewImageFullscreenWrapper>
        )}

        <ProjectMainViewImageRegularWrapper
          ref={(el) => (this.imageWrapperRef = el)}
          fullScreen={this.state.fullScreen}
          id="imageWrapper"
        >
          {!this.state.imageErrored && this.props.resource && (
            <>
              {!!this.state.dimensions?.width &&
                !this.props.messagesTabOpen &&
                this.props.userRole !== 'guest' && (
                  <ClickArea
                    updateNewTaskData={this.updateNewTaskData}
                    dimensions={this.state.dimensions}
                    isNewTaskActive={!!this.state.newTaskData}
                    lineType={this.state.newLineType}
                  />
                )}
              <LazyLoadPicture
                src={this.props.resource.signedUrl}
                // onClick={this.handleImageWrapperClick}
                thumbUrl={this.props.resource.thumbnailUrl}
                alt="preview"
                id="project-mainview-fullscreen-img"
                onLoad={this.handleImageLoaded}
                onError={this.handleImageErrored}
              />
            </>
          )}
          <>
            {this.props.selectedTask && (
              <TaskMessage
                task={this.props.selectedTask}
                updateSelectedTask={this.props.updateSelectedTask}
                users={this.props.users}
                updateTasks={this.props.updateTasks}
                videoFullscreen={false}
              />
            )}
            {this.state.newTaskData && (
              <NewTask
                clickEvent={this.state.newTaskData}
                updateClickEvent={this.updateNewTaskData}
                match={this.props.match}
                resource={this.props.resource}
                newTaskIndex={this.props.highestIndex}
                useCase="image"
                toggleLineType={(value) => this.setState({ newLineType: value })}
              />
            )}
            {!this.props.messagesTabOpen &&
              this.props.tasks.length > 0 &&
              !!this.state.dimensions?.width &&
              !this.state.loading &&
              this.renderTaskDots()}
            <FullScreenIconWrapper
              onClick={() => this.setState({ fullScreen: !this.state.fullScreen })}
              fullscreen={this.state.fullscreen}
            >
              <FullScreenIcon />
            </FullScreenIconWrapper>
          </>
        </ProjectMainViewImageRegularWrapper>
      </>
    );
  }
}

const ProjectMainViewImageFullscreenWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 105;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProjectMainViewImageRegularWrapper = styled.div`
  margin: auto;
  max-width: 100%;
  max-height: calc(35rem - 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ImageWrapper = styled.div`
  max-width: 1400px;
  max-height: 800px;
  position: relative;
`;

const Image = styled.img`
  max-height: ${(props) => (props.fullscreen ? '90%' : 'calc(35rem - 2px)')};
  max-width: 100%;
  object-fit: scale-down;
`;

const FullScreenImage = styled.img`
  max-height: 80vh;
  max-width: 1400px;
  object-fit: scale-down;
  @media (max-width: 1500px) {
    max-width: 1000px;
  }
  @media (max-width: 1100px) {
    max-width: 700px;
  }
  @media (max-width: 800px) {
    max-width: 400px;
  }
  @media (max-width: 500px) {
    max-width: 200px;
  }
`;

const CrossIconWrapper = styled.div`
  position: fixed;
  top: 30px;
  right: 30px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  border-radius: 2px;
  &:hover svg {
    transform: scale(1.05);
  }
  svg {
    transition: all 0.2s linear;
    fill: #fff;
    width: 30px;
    height: 30px;
    margin: auto;
  }
`;

const FullScreenIconWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  display: flex;
  border-radius: 2px;
  z-index: 3;
  &:hover svg {
    transform: scale(1.05);
  }
  svg {
    transition: all 0.2s linear;
    fill: #fff;
    width: 1rem;
    height: 1rem;
    margin: auto;
  }
`;

export default ProjectMainViewImage;
