import React from 'react';
import PropTypes from 'prop-types';
import classes from './ItemListButtons.module.scss';
import AddNewIcon from '../../icons/AddNewIcon';
import UploadIcon from '../../icons/UploadIcon';
import { locationShape } from '../../../routerPropTypes';
import { currentUserInfoShape } from '../../../apiServices/userApiTypes';
import { useCaseType } from '../ItemListPropTypes';
import {
  getShouldRenderAddFileButton,
  getShouldRenderAddItemButton,
} from './ItemListButtonsUtilityFunctions';

export const getAddItemsWrapperStyles = (location) => {
  const pathnameSplitArr = location.pathname.split('/');
  if (pathnameSplitArr[2] === 'welcome' || pathnameSplitArr[2] === 'project') {
    return classes.addItemsWrapperProject;
  } else {
    return classes.addItemsWrapper;
  }
};

export const ItemListButtons = (props) => {
  const addItemsWrapperStyles = getAddItemsWrapperStyles(props.location);
  const shouldRenderAddFileButton = getShouldRenderAddFileButton(
    props.location,
    props.currentUserInfo,
  );
  const shouldRenderAddItemButton = getShouldRenderAddItemButton(
    props.location,
    props.currentUserInfo,
  );
  return (
    <div className={addItemsWrapperStyles}>
      {props.currentUserInfo && props.currentUserInfo.role && props.useCase !== 'link' && (
        <>
          {shouldRenderAddFileButton && (
            <div
              onClick={() => props.updateModalOpen('fileUpload')}
              title="add file"
              className={classes.addFileButton}
            >
              <UploadIcon height="50px" fill="white" />
            </div>
          )}
          {shouldRenderAddItemButton && (
            <div
              onClick={() => props.updateModalOpen('newItem')}
              title="add item"
              className={classes.newItemButton}
            >
              <AddNewIcon height="50px" fill="white" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

ItemListButtons.propTypes = {
  location: PropTypes.shape(locationShape).isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  updateModalOpen: PropTypes.func.isRequired,
  useCase: useCaseType.isRequired,
};
