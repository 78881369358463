import { createSlice } from '@reduxjs/toolkit';
import { transformAuthUser, transformUserDetails } from './utils';

const initialState = {
  uid: null,
  given_name: null,
  family_name: null,
  email: null,
  jwtToken: null,
  identityId: null,
  status: 'initial',
  image: null,
  oldUserDetails: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthUser(state, action) {
      const authUser = transformAuthUser(action.payload);
      if (!authUser) {
        return initialState;
      }
      const { uid, jwtToken } = authUser;
      state.uid = uid;
      // state.given_name = given_name;
      // state.family_name = family_name;
      // state.email = email;
      state.jwtToken = jwtToken;
    },
    setGuestUser: (state, action) => {
      const { email, firstname, lastname, uid } = action.payload;
      state.uid = uid;
      state.given_name = firstname;
      state.family_name = lastname;
      state.email = email;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setDetails: (state, action) => {
      const userDetails = transformUserDetails(action.payload);
      state.image = userDetails?.image;
      state.given_name = userDetails?.firstname;
      state.family_name = userDetails?.lastname;
      state.email = userDetails?.email;
    },
    updateCurrentCredentials: (state, action) => {
      state.identityId = action.payload;
    },
    updateAuthorization: (state, action) => {
      state.authorization = action.payload;
    },
    updateHeader: (state, action) => {
      state.header = action.payload;
    },
    setOldUserDetails: (state, action) => {
      state.oldUserDetails = action.payload;
    },
  },
});
// example of acync reducer (thunk)
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

export const {
  updateCurrentCredentials,
  setStatus,
  updateAuthorization,
  updateHeader,
  setAuthUser,
  setDetails,
  setGuestUser,
  setOldUserDetails,
} = userSlice.actions;

export default userSlice.reducer;
