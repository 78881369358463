import { API } from 'aws-amplify';
import store from '../configureStore';
import { config } from '../config/Config';

const sendGuestMessage = (hash, header, message) => {
  const init = {
    headers: header,
    body: { msgbody: message },
  };
  return API.post(config.apiGateway.NAME, `/guest/message/send/${hash}`, init);
};

export const sendMessage = (projectId, message) => {
  const {
    project: { header, userRole, guestHash },
    workspace: { currentUserGroup },
  } = store.getState();
  if (userRole === 'guest') return sendGuestMessage(guestHash, header, message);
  const sendMessageInit = {
    headers: header,
    body: { project: projectId, msgbody: message },
  };

  return API.post(config.apiGateway.NAME, `/message/send?gid=${currentUserGroup}`, sendMessageInit);
};

export const notifyProjectParticipants = (projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const sendMessageInit = {
    headers: header,
    body: `project=${projectId}&cmd=notify`,
  };
  return API.post(config.apiGateway.NAME, `/message/send?gid=${currentUserGroup}`, sendMessageInit);
};

export const getMessagesByProject = (projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const getMessagesByProjectInit = {
    headers: header,
  };
  return API.get(
    config.apiGateway.NAME,
    `/message/project/${projectId}?gid=${currentUserGroup}`,
    getMessagesByProjectInit,
  );
};

export const getMessages = () => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const getMessagesInit = {
    headers: header,
  };
  return API.get(config.apiGateway.NAME, `/message?gid=${currentUserGroup}`, getMessagesInit);
};

export const deleteMessage = (messageId, projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const deleteMessageInit = {
    headers: header,
    body: `id=${messageId}&project=${projectId}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/message/delete?gid=${currentUserGroup}`,
    deleteMessageInit,
  );
};

export const getWorkspacesMessagesAmount = () => {
  const {
    project: { header },
  } = store.getState();
  const getWorkspacesMessagesAmountInit = {
    headers: header,
  };
  return API.get(config.apiGateway.NAME, '/message?type=groups', getWorkspacesMessagesAmountInit);
};
