import React from 'react';
import PropTypes from 'prop-types';
import './AccountSettingsWorkspaceAdministrationList.scss';
import { groupShape } from '../../../../../AppPropTypes';
import { AccountSettingsWorkspaceAdministrationListItem } from './accountSettingsWorkspaceAdministrationListItem/AccountSettingsWorkspaceAdministrationListItem';
import { historyShape } from '../../../../../../routerPropTypes';

export const AccountSettingsWorkspaceAdministrationList = (props) => {
  const listItems = props.rootGroupsList.map((rootGroup) => (
    <AccountSettingsWorkspaceAdministrationListItem
      key={rootGroup.id.S}
      group={rootGroup}
      currentUserGroup={props.currentUserGroup}
      initiateSession={props.initiateSession}
      updateModalParams={props.updateModalParams}
      updateModalOpen={props.updateModalOpen}
      history={props.history}
    />
  ));
  return <ul className="account-settings-workspace-administration-list">{listItems}</ul>;
};

AccountSettingsWorkspaceAdministrationList.propTypes = {
  rootGroupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
  currentUserGroup: PropTypes.string.isRequired,
  initiateSession: PropTypes.func.isRequired,
  updateModalParams: PropTypes.func.isRequired,
  updateModalOpen: PropTypes.func.isRequired,
  history: PropTypes.shape(historyShape).isRequired,
};
