import React from 'react';
import PropTypes from 'prop-types';
import classes from './AccountSettingsWorkspaceListItemActionables.module.css';
import { groupShape, userInfoGroupsListItemShape } from '../../../../../../AppPropTypes';

class AccountSettingsWorkspaceListItemActionables extends React.Component {
  handleDropdownListItemClick = (listItem) => {
    let modalParams;
    this.props.updateDropdownOpenedItemId();
    switch (listItem) {
      case 'Select': {
        this.props.selectGroup();
        break;
      }
      case 'Rename':
        modalParams = [
          { workspace: this.props.group, title: `Rename workspace ${this.props.group.title.S}` },
        ];
        this.props.updateModalParams(modalParams);
        this.props.updateModalOpen('renameWorkspace');
        break;
      case 'Cover icon': {
        modalParams = [{ useCase: 'workspace', workspace: this.props.group }];
        this.props.updateModalParams(modalParams);
        this.props.updateModalOpen('uploadProfile');
        break;
      }
      case 'Participants': {
        modalParams = [{ workspace: this.props.group.id.S }];
        this.props.updateModalParams(modalParams);
        this.props.updateModalOpen('workspacePeopleList');
        break;
      }
      case 'Exit workspace': {
        modalParams = [{ workspace: this.props.group }];
        this.props.updateModalParams(modalParams);
        this.props.updateModalOpen('exitWorkspace');
        break;
      }
      case 'Delete': {
        modalParams = [{ workspace: this.props.group }];
        this.props.updateModalParams(modalParams);
        this.props.updateModalOpen('deleteWorkspace');
        break;
      }
      default:
        break;
    }
  };

  handleDropdownTriggerClick = () => {
    if (this.props.dropdownOpenedItemId === this.props.group.id.S) {
      this.props.updateDropdownOpenedItemId();
    } else {
      this.props.updateDropdownOpenedItemId(this.props.group.id.S);
    }
  };

  getDropdownListItems = (currentGroupUserInfoObj) => {
    if (currentGroupUserInfoObj) {
      if (currentGroupUserInfoObj.role) {
        if (currentGroupUserInfoObj.role.S === 'admin') {
          return ['Select', 'Participants', 'Rename', 'Cover icon', 'Delete'];
        } else if (currentGroupUserInfoObj.role.S === 'pro') {
          return ['Select', 'Participants', 'Rename', 'Cover icon', 'Exit workspace'];
        } else if (currentGroupUserInfoObj.role.S === 'user') {
          return ['Select', 'Participants', 'Exit workspace'];
        } else if (currentGroupUserInfoObj.role.S === 'viewer') {
          return ['Select', 'Exit workspace'];
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  render() {
    const hoveringOrDropdownOpen =
      this.props.isHoveringOver || this.props.dropdownOpenedItemId === this.props.group.id.S;
    const actionablesDropdownTriggerClass =
      this.props.dropdownOpenedItemId === this.props.group.id.S
        ? classes.actionablesDropdownTriggerActive
        : classes.actionablesDropdownTrigger;

    const actionablesDropdownTriggerDotsStyles = {
      margin: 'var(--space-minus-3) 0 0 0',
      fontSize: '1.7em',
      fontWeight: 900,
      color: this.props.dropdownOpenedItemId === this.props.group.id.S ? '#00ce5c' : 'black',
    };

    const dropdownListItems = this.getDropdownListItems(this.props.currentGroupUserInfoObj);
    const listItems = dropdownListItems.map((listItem) => (
      <li
        key={listItem}
        className={classes.actionablesDropdownListItem}
        onClick={() => this.handleDropdownListItemClick(listItem)}
      >
        <h5 className={classes.actionablesDropdownListItemLabel}>{listItem}</h5>
      </li>
    ));
    return (
      <>
        {hoveringOrDropdownOpen && (
          <>
            <button
              className={actionablesDropdownTriggerClass}
              onClick={this.handleDropdownTriggerClick}
              type="button"
              title="options"
            >
              <h6 style={actionablesDropdownTriggerDotsStyles}>...</h6>
            </button>
            {this.props.dropdownOpenedItemId === this.props.group.id.S && (
              <ul className={classes.actionablesDropdownList} onBlur={this.handleDropdownBlur}>
                {listItems}
              </ul>
            )}
          </>
        )}
      </>
    );
  }
}

export default AccountSettingsWorkspaceListItemActionables;

AccountSettingsWorkspaceListItemActionables.propTypes = {
  // app.jsx props
  isHoveringOver: PropTypes.bool.isRequired,
  // updateIsQuerying: PropTypes.func.isRequired,
  // currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  currentGroupUserInfoObj: PropTypes.shape(userInfoGroupsListItemShape),

  // AccountSettingsWorkspace.jsx props
  updateModalParams: PropTypes.func.isRequired,
  updateModalOpen: PropTypes.func.isRequired,
  selectGroup: PropTypes.func.isRequired,

  // accountSettingsWorkspaceList.jsx props
  group: PropTypes.shape(groupShape).isRequired,
  dropdownOpenedItemId: PropTypes.string,
  updateDropdownOpenedItemId: PropTypes.func.isRequired,
};

AccountSettingsWorkspaceListItemActionables.defaultProps = {
  currentGroupUserInfoObj: undefined,
  dropdownOpenedItemId: undefined,
};
