import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './ChooseFilesButton.scss';
import '../experimentalButton/button.scss';
import Loader from '../../loader/loader';

const ChooseFilesButton = (props) => (
  <div
    className={`button ${props.type} ${props.isDisabled ? 'disabled' : ''} ${
      props.loading ? 'loading' : ''
    }`}
    title={props.loading ? 'loading' : props.title}
  >
    <InputField
      type="file"
      onChange={props.handleChange}
      multiple={props.multiple}
      onClick={props.handleClick}
      accept={props.accept}
    />
    {props.animated && props.loading ? <Loader /> : props.children}
  </div>
);

const InputField = styled.input`
  margin: auto;
  width: 120px;
  height: 35px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
`;

export default ChooseFilesButton;

ChooseFilesButton.propTypes = {
  // html title-attribute
  title: PropTypes.string,

  // whether to display loader-animation on loading, defaults to true
  animated: PropTypes.bool,

  /* boolean to determine loading-state. Will give button 'loading'-class to set some styling,
   will disable button-events, if animated-prop is true will display loader-animation */
  loading: PropTypes.bool,

  // content of the button
  children: PropTypes.node.isRequired,

  /* boolean to determine disabled-state.
   Will give button 'disabled'-class to set some styling, will disable button-events */
  isDisabled: PropTypes.bool,

  /* type to get some default styling for commonly used buttons.
   At the moment there is: 'primary', 'secondary' and 'green'. Defaults to 'primary'. */
  type: PropTypes.string,

  handleClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
};

ChooseFilesButton.defaultProps = {
  type: 'primary',
  isDisabled: false,
  loading: false,
  animated: true,
  title: '',
  multiple: true,
  accept: '*',
};
