import React, { useState } from 'react';
import classes from './MessageMainSection.module.scss';
import { getRelativeTime } from '../../../../../../../../util/UtilFunctions';
import { deleteMessage } from '../../../../../../../../apiServices/messageApi';
import { deleteTask } from '../../../../../../../../apiServices/taskApi';
import LoadingDots from '../../../../../../../../components/loadingDots/LoadingDots';
import { ReactComponent as RemoveIcon } from '../../../../../../../../assets/trash.svg';
import { useDispatch } from 'react-redux';
import { removeMessage, removeTask } from '../../../../../../../features/project/projectSlice';

const MessageMainSection = (props) => {
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();

  const parseContent = () => {
    if (props.content) {
      const rowSplitContent = props.content.split('\\n');

      return rowSplitContent.map((item, index) => {
        const returnableItem =
          item === '' ? (
            <br key={item + index} />
          ) : (
            <p className={classes.text} key={`${item}${index}`}>
              {item.replace(/\\"/g, '"')}
            </p>
          );

        return returnableItem;
      });
    }
    return '';
  };

  const handleDeleteMessage = () => {
    const deleteFunc = () =>
      new Promise(async (resolve, reject) => {
        setDeleting(true);
        try {
          if (props.task) {
            await deleteTask(props.task.id, props.projectId);
            dispatch(removeTask(props.task.id));
          } else {
            await deleteMessage(props.messageId, props.projectId);
            dispatch(removeMessage(props.messageId));
          }

          resolve();
        } catch (deleteMessageError) {
          console.error(deleteMessageError, 'deleteMessageError');
          setDeleting(false);
          reject(deleteMessageError);
        }
      });
    props.updateModalOpen('deleteItem', { action: deleteFunc });
  };

  const relativeTime = getRelativeTime(new Date(), new Date(props.created));
  const name =
    props.firstName && props.lastName ? `${props.firstName} ${props.lastName}` : '[Removed user]';
  return (
    <div className="messageMainSection">
      <div className="infoSection">
        <h4>{name}</h4>
        <h5>{relativeTime}</h5>
        {props.isRemovable && (
          <button
            type="button"
            onClick={handleDeleteMessage}
            className={`removeButton ${deleting ? 'disabled' : ''}`}
            title="remove"
            disabled={deleting}
          >
            {deleting ? <LoadingDots fontSize={1} width={5} color="black" /> : <RemoveIcon />}
          </button>
        )}
      </div>
      <div className={classes.messageSection}>{parseContent()}</div>
    </div>
  );
};

export default MessageMainSection;
