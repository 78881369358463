import React from 'react';
import PropTypes from 'prop-types';
import { getAllUsers } from '../../../../apiServices/userApi';
import WorkspaceParticipiantsModalAddExistingPeopleSectionList from './workspaceParticipiantsModalAddExistingPeopleList/WorkspaceParticipiantsModalAddExistingPeopleList';
import { determineCombinedProcessesStatus } from './WorkspaceParticipiantsModalAddExistingPeopleSectionUtilFunctions';
import { groupShape } from '../../../../app/AppPropTypes';
import { currentUserInfoShape } from '../../../../apiServices/userApiTypes';
import Loader from '../../../loader/loader';
import './WorkspaceParticipiantsModalAddExistingPeopleSection.scss';
import ExperimentalButton from '../../../buttons/experimentalButton/experimentalButton';

class WorkspaceParticipiantsModalAddExistingPeopleSection extends React.Component {
  state = {
    peopleListFromAllWorkspacesProcess: { status: 'initial' },
    currentWorkspacePeopleListProcess: { status: 'initial' },
  };

  async componentDidMount() {
    this.initiateSection();
  }

  initiateSection = async () => {
    this.getPeopleFromAllWorkspacesOfCurrentUser();
    this.getPeopleListForWorkspace(this.props.currentWorkspace);
  };

  getPeopleFromAllWorkspacesOfCurrentUser = async () => {
    this.setState({ peopleListFromAllWorkspacesProcess: { status: 'loading' } });
    let groupsPeopleListPromisesList = [];
    this.props.groupsList.forEach((group) => {
      const newPromise = new Promise((resolve, reject) => {
        this.props.updateIsQuerying(true);
        getAllUsers(group)
          .then((getAllUsersResponse) => {
            this.props.updateIsQuerying(false);
            resolve(getAllUsersResponse.response.Items);
          })
          .catch((getAllUsersError) => {
            this.props.updateIsQuerying(false);
            reject(getAllUsersError);
          });
      });
      groupsPeopleListPromisesList.push(newPromise);
    });
    Promise.all(groupsPeopleListPromisesList)
      .then((values) => {
        const flattedPeopleArr = values.flat();
        let newArr = [];
        flattedPeopleArr.forEach((person) => {
          if (person) {
            if (newArr.length > 0) {
              const personExistInNewArrAlready = newArr.find(
                (entry) => entry.cognitoAttributes.Username === person.cognitoAttributes.Username,
              );
              if (!personExistInNewArrAlready) {
                newArr.push(person);
              }
            } else {
              newArr.push(person);
            }
          }
        });
        this.setState({
          peopleListFromAllWorkspacesProcess: { status: 'success', data: newArr },
        });
      })
      .catch((peopleListFromAllWorkspacesError) => {
        this.setState({
          peopleListFromAllWorkspacesProcess: {
            status: 'error',
            data: peopleListFromAllWorkspacesError,
          },
        });
        this.props.updateIsQuerying(false);
      });
  };

  getPeopleListForWorkspace = async () => {
    this.setState({ currentWorkspacePeopleListProcess: { status: 'loading' } });
    try {
      this.props.updateIsQuerying(true);
      const getAllUsersResponse = await getAllUsers(this.props.chosenGroup);
      this.props.updateIsQuerying(false);
      this.setState({
        currentWorkspacePeopleListProcess: {
          status: 'success',
          data: getAllUsersResponse.response.Items,
        },
      });
    } catch (getPeopleListError) {
      this.setState({
        currentWorkspacePeopleListProcess: { status: 'error', data: getPeopleListError },
      });
      this.props.updateIsQuerying(false);
    }
  };

  render() {
    const combinedProcessesStatus = determineCombinedProcessesStatus(
      this.state.peopleListFromAllWorkspacesProcess.status,
      this.state.currentWorkspacePeopleListProcess.status,
    );
    let view;
    switch (combinedProcessesStatus) {
      case 'loading': {
        const loadingView = (
          <div className="spinnerWrapper">
            <Loader />
          </div>
        );
        view = loadingView;
        break;
      }
      case 'success': {
        const list = this.state.peopleListFromAllWorkspacesProcess.data.filter((person) => {
          const personIsCheckedAlready =
            this.state.currentWorkspacePeopleListProcess.data.find(
              (userInWorkspace) =>
                person.cognitoAttributes.Username === userInWorkspace.cognitoAttributes.Username,
            ) !== undefined;
          return !personIsCheckedAlready;
        });
        const successView = (
          <>
            {list.length > 0 && (
              <div className="peopleListWrapper">
                <WorkspaceParticipiantsModalAddExistingPeopleSectionList
                  peopleListFromOutsideCurrentlyChosenWorkspace={list}
                  listType="workspace"
                  currentWorkspace={this.props.currentWorkspace}
                  updateIsQuerying={this.props.updateIsQuerying}
                  currentUserInfoInThisWorkspace={this.props.currentUserInfoInThisWorkspace}
                  chosenGroup={this.props.chosenGroup}
                />
              </div>
            )}
            {list.length === 0 && <h5>No one to add from other workspaces</h5>}
          </>
        );
        view = successView;
        break;
      }
      case 'error': {
        const errorCaseView = (
          <div className="errorWrapper">
            <h5 className="errorText">Something went wrong</h5>
            <ExperimentalButton type="primary" onClick={this.initiateSection} title="Try again">
              Try again
            </ExperimentalButton>
          </div>
        );
        view = errorCaseView;
        break;
      }
      default:
        view = <div />;
        break;
    }
    return <div className="workspaceParticipiantsModalAddExistingPeopleSection">{view}</div>;
  }
}

export default WorkspaceParticipiantsModalAddExistingPeopleSection;

WorkspaceParticipiantsModalAddExistingPeopleSection.propTypes = {
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
  currentWorkspace: PropTypes.string.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfoInThisWorkspace: PropTypes.shape(currentUserInfoShape).isRequired,
  chosenGroup: PropTypes.shape(groupShape).isRequired,
};
