import React from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import '../../../../../video-js-global.css';
import '../../videojs.markers.css';
import 'videojs-markers';
import TaskMessage from '../projectMainViewComponents/taskMessage/TaskMessage';
import NewTask from '../projectMainViewComponents/newTask/NewTask';

import { ReactComponent as Soundwave } from '../../../../../../assets/soundwave.svg';
// prop types
import { matchShape } from '../../../../../../routerPropTypes';
import { workspaceParticipantShape } from '../../../../../AppPropTypes';
import { taskShape } from '../../ProjectMainViewPropTypes';
import { folderListItemShape } from '../../../../folderView/FolderViewPropTypes';
import './audioPlayerOverWrites.scss';
import './projectMainViewAudioPlayer.scss';
import NotificationContext from '../../../../../../context/notification';

class ProjectMainViewAudioPlayer extends React.Component {
  static contextType = NotificationContext;

  constructor() {
    super();
    this.state = {
      videoHasStarted: false,
      markerClicked: undefined,
      clickEvent: undefined,
      isPlaying: false,
    };
  }

  componentDidMount() {
    if (!this.props.messagesTabOpen) {
      this.initializePlayerWithTasks();
    } else {
      this.initializePlayerWithoutTasks();
    }
  }

  // adapt the audio player for different updates caused by the user
  componentDidUpdate(prevProps) {
    if (this.props.messagesTabOpen !== prevProps.messagesTabOpen) {
      this.handleChatTabsChange();
    } else if (this.props.selectedTask && this.props.selectedTask !== prevProps.selectedTask) {
      this.handleTaskSelectionChange(prevProps);
    } else if (this.props.resource.id !== prevProps.resource.id) {
      this.handleresourceChange(prevProps);
    } else if (
      prevProps.tasks &&
      this.props.tasks &&
      this.props.tasks.length > prevProps.tasks.length &&
      !this.props.messagesTabOpen
    ) {
      this.handleNewTaskAppearance();
    }
  }

  componentWillUnmount() {
    this.removePlayerEventListeners();
    videojs(`project-main-view-player-${this.props.resource.id}`).dispose();
  }

  handleNewTaskAppearance = () => {
    const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);
    playerObj.markers?.removeAll();
    if (playerObj.markers.add) {
      this.addMarkersToPlayer(playerObj);
    }
  };

  handleresourceChange = (prevProps) => {
    this.removePlayerEventListeners();
    const previousPlayerObj = videojs(`project-main-view-player-${prevProps.resource.id}`);
    previousPlayerObj.dispose();
    if (!this.props.messagesTabOpen) {
      this.initializePlayerWithTasks();
    } else {
      this.initializePlayerWithoutTasks();
    }
  };

  handleChatTabsChange = () => {
    const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);

    if (this.props.messagesTabOpen) {
      !!playerObj?.markers?.removeAll && playerObj.markers.removeAll();
      playerObj.pause();
    } else {
      // start video to show controls when task tab is clicked open
      // (controls are hidden when the video hasn't been started)
      playerObj.play();
      playerObj.pause();

      this.addMarkersToPlayer(playerObj);
    }
  };

  handleTaskSelectionChange = (prevProps) => {
    if (this.props.selectedTask.resource !== prevProps.resource.id) {
      this.removePlayerEventListeners();
      const previousPlayerObj = videojs(`project-main-view-player-${prevProps.resource.id}`);
      previousPlayerObj.dispose();
      this.initializePlayerWithSelectedTask();
    } else {
      const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);

      if (this.state.videoHasStarted) {
        if (!this.state.markerClicked) {
          playerObj.currentTime(this.props.selectedTask.timestamp / 1000);
        } else {
          // if user previously clicked on a marker and now clicked on a
          // different task in the "chat"
          if (this.state.markerClicked.task.id !== this.props.selectedTask.id) {
            playerObj.currentTime(this.props.selectedTask.timestamp / 1000);
          }
        }
      } else {
        const time = this.props.selectedTask.timestamp / 1000;
        this.updateVideoPositionToTime(playerObj, time);
      }
    }
  };

  updateClickEvent = (newEvent) => {
    this.setState({
      clickEvent: newEvent,
      markerClicked: undefined,
    });
  };

  initializePlayerWithSelectedTask = () => {
    const videoJsAudioPlayerOptions = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: this.props.resource.signedUrl,
          type: this.props.resource.mimeType,
        },
      ],
      controlBar: {
        fullscreenToggle: false,
        liveDisplay: false,
        pictureInPictureToggle: false,
      },
    };
    const videoPlayerWrapperElement = document.getElementById('project-main-view-audio-wrapper');
    const videoDomElement = this.createNewAudioElement(this.props.resource);
    videoPlayerWrapperElement.appendChild(videoDomElement);
    const playerObj = videojs(videoDomElement, videoJsAudioPlayerOptions);

    playerObj.ready(() => {
      this.initVideoPlugins(playerObj);
      setTimeout(() => {
        const time = this.props.selectedTask.timestamp / 1000;
        this.updateVideoPositionToTime(playerObj, time);
      }, 300);
    });

    // playerObj.controlBar.progressControl.seekBar.on('mouseup', this.handleSeekBarMouseUp);
  };

  initializePlayerWithoutSelectedTask = () => {
    const videoJsAudioPlayerOptions = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: this.props.resource.signedUrl,
          type: this.props.resource.mimeType,
        },
      ],
      controlBar: {
        fullscreenToggle: false,
        liveDisplay: false,
        pictureInPictureToggle: false,
      },
    };
    const videoPlayerWrapperElement = document.getElementById('project-main-view-audio-wrapper');
    const videoDomElement = this.createNewAudioElement(this.props.resource);
    videoPlayerWrapperElement.appendChild(videoDomElement);
    const playerObj = videojs(videoDomElement, videoJsAudioPlayerOptions);

    playerObj.ready(() => {
      this.initVideoPlugins(playerObj);
      playerObj.currentTime(0);
    });

    // playerObj.controlBar.progressControl.seekBar.on('mouseup', this.handleSeekBarMouseUp);
  };

  handleSeekBarMouseUp = () => {
    if (!this.props.messagesTabOpen && this.state.clickEvent) {
      this.handleContentClick();
    }
  };

  initializePlayerWithTasks = () => {
    if (this.props.selectedTask) {
      if (this.props.selectedTask.resource === this.props.resource.id) {
        this.initializePlayerWithSelectedTask();
      } else {
        this.initializePlayerWithoutSelectedTask();
      }
    } else {
      this.initializePlayerWithoutSelectedTask();
    }
  };

  initializePlayerWithoutTasks = () => {
    const videoJsAudioPlayerOptions = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: this.props.resource.signedUrl,
          type: this.props.resource.mimeType,
        },
      ],
      controlBar: {
        fullscreenToggle: false,
        liveDisplay: false,
        pictureInPictureToggle: false,
      },
    };
    const videoPlayerWrapperElement = document.getElementById('project-main-view-audio-wrapper');
    const videoDomElement = this.createNewAudioElement(this.props.resource);
    videoPlayerWrapperElement.appendChild(videoDomElement);
    const playerObj = videojs(videoDomElement, videoJsAudioPlayerOptions);

    playerObj.ready(() => {
      this.initVideoPlugins(playerObj);
    });
  };

  initVideoPlugins = (playerObj) => {
    const markersObj = {
      markers: [],
      markerStyle: {
        width: '6px',
        height: '22px',
        'background-color': 'lightgreen',
        'margin-bottom': '-4px',
      },
      onMarkerClick: (marker) => {
        playerObj.pause();
        this.setState(
          {
            markerClicked: marker,
            clickEvent: undefined,
          },
          () => {
            this.props.updateSelectedTask(marker.task);
          },
        );
      },
    };

    // playerObj.overlay({ overlays: arrows, dimensions: this.state.dimensions });
    playerObj.markers(markersObj);

    !this.props.messagesTabOpen && setTimeout(() => this.addMarkersToPlayer(playerObj), 500);
  };

  addMarkersToPlayer = (playerObj) => {
    if (playerObj.markers) {
      let markersList;
      if (this.props.tasks.length > 0) {
        markersList = this.props.tasks.map((task) => {
          const marker = {
            task,
            time: task.timestamp / 1000,
            text: task.messages[0]?.content || '',
          };
          return marker;
        });
      } else {
        markersList = [];
      }

      playerObj.markers.add(markersList);
    }
  };

  updateVideoPositionToTime = (playerObj, time) => {
    playerObj.play();
    playerObj.currentTime(time);
    playerObj.pause();
  };

  createNewAudioElement = (video) => {
    const videoDomElement = document.createElement('audio');
    videoDomElement.setAttribute('id', `project-main-view-player-${video.id}`);
    videoDomElement.setAttribute('class', 'video-js');
    videoDomElement.setAttribute('controls', 'true');
    videoDomElement.setAttribute('preload', 'auto');
    videoDomElement.setAttribute('data-setup', '{}');
    videoDomElement.setAttribute('width', '500px');
    videoDomElement.setAttribute('crossorigin', 'anonymous');
    const videoDomElementDisplayValue = `${video.id !== 1 ? 'inherit' : 'none'}`;
    videoDomElement.setAttribute('display', videoDomElementDisplayValue);
    videoDomElement.addEventListener('playing', this.videoDomElementPlayingListeningFunction);
    videoDomElement.addEventListener('pause', this.videoDomElementIsPaused);
    const videoDomSourceElement = document.createElement('source');
    videoDomSourceElement.setAttribute('src', video.signedUrl);
    videoDomSourceElement.setAttribute('type', video.mimeType);

    videoDomElement.appendChild(videoDomSourceElement);

    return videoDomElement;
  };

  videoDomElementIsPaused = () => {
    this.setState({ isPlaying: false });
  };

  videoDomElementPlayingListeningFunction = () => {
    this.setState({
      videoHasStarted: true,
      markerClicked: undefined,
      isPlaying: true,
    });
    if (this.props.selectedTask) {
      const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);
      const currentTimeInDeciSeconds = playerObj.currentTime() * 10;
      const selectedTaskTimeInDeciSeconds = this.props.selectedTask.timestamp / 100;
      if (Math.round(currentTimeInDeciSeconds) !== Math.round(selectedTaskTimeInDeciSeconds)) {
        this.props.updateSelectedTask();
      }
    }
  };

  videoDomElementSeekedListeningFunction = () => {
    if (!this.props.messagesTabOpen) {
      if (this.props.selectedTask) {
        // during seek operation, only delete selected task and markerClicked if
        // progressbar is not near a marker
        const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);
        const currentTimeInDeciSeconds = playerObj.currentTime() * 10;
        const selectedTaskTimeInDeciSeconds = this.props.selectedTask.timestamp / 100;
        if (Math.round(currentTimeInDeciSeconds) !== Math.round(selectedTaskTimeInDeciSeconds)) {
          this.props.updateSelectedTask();
          this.setState({ markerClicked: undefined });
        }
      }
    }
  };

  removePlayerEventListeners = () => {
    const videojsElement = document.getElementById(
      `project-main-view-player-${this.props.resource.id}`,
    );
    if (videojsElement) {
      const videoDomElement = videojsElement.getElementsByTagName('audio')[0];
      if (videoDomElement) {
        videoDomElement.removeEventListener(
          'playing',
          this.videoDomElementPlayingListeningFunction,
        );
        videoDomElement.removeEventListener('pause', this.videoDomElementIsPaused);
        videoDomElement.addEventListener('seeked', this.videoDomElementSeekedListeningFunction);
      }
    }
  };

  handleContentClick = () => {
    const playerObj = videojs(`project-main-view-player-${this.props.resource.id}`);
    if (!this.props.messagesTabOpen && this.props.userRole !== 'guest') {
      playerObj.pause();
      this.setState({ clickEvent: { time: playerObj.currentTime() * 1000 } });
    } else {
      if (this.state.isPlaying) {
        playerObj.pause();
      } else {
        playerObj.play();
      }
    }
  };

  render() {
    return (
      <div className="projectMainViewAudioPlayer" id="project-main-view-audio-wrapper">
        <div
          className={`content ${this.state.isPlaying ? 'animate' : ''}`}
          onClick={this.handleContentClick}
        >
          <Soundwave />
        </div>
        <>
          {this.props.selectedTask && (
            <TaskMessage
              task={this.props.selectedTask}
              updateSelectedTask={this.props.updateSelectedTask}
              users={this.props.users}
              videoFullscreen={false}
            />
          )}
          {this.state.clickEvent && (
            <NewTask
              tasks={this.props.tasks}
              match={this.props.match}
              resource={this.props.resource}
              updateClickEvent={this.updateClickEvent}
              clickEvent={this.state.clickEvent}
              newTaskIndex={this.props.highestIndex}
              useCase="audio"
            />
          )}
        </>
      </div>
    );
  }
}

export default ProjectMainViewAudioPlayer;

ProjectMainViewAudioPlayer.propTypes = {
  // router props
  match: PropTypes.shape(matchShape).isRequired,

  // app.jsx props
  users: PropTypes.arrayOf(PropTypes.shape(workspaceParticipantShape)).isRequired,

  // projectView.jsx props
  currentPreviewItemIsLoaded: PropTypes.bool.isRequired,
  updateCurrentPreviewItemIsLoaded: PropTypes.func.isRequired,
  getProjectFileList: PropTypes.func.isRequired,

  // projectMainView.jsx props
  selectedTask: PropTypes.shape(taskShape),
  resource: PropTypes.shape(folderListItemShape).isRequired,
  messagesTabOpen: PropTypes.bool.isRequired,
  updateTasks: PropTypes.func.isRequired,
  updateSelectedTask: PropTypes.func.isRequired,

  // projecMainViewWorkSection.jsx props
  tasks: PropTypes.arrayOf(PropTypes.shape(taskShape)).isRequired,
  highestIndex: PropTypes.number.isRequired,
};

ProjectMainViewAudioPlayer.defaultProps = {
  tasksAmountByResourceList: undefined,
  selectedTask: undefined,
};
