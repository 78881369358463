import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { formatMilliSeconds } from '../../../../../../../util/UtilFunctions';
import ExperimentalButton from '../../../../../../../components/buttons/experimentalButton/experimentalButton';
import { ReactComponent as ArrowIcon } from '../../../../../../../assets/arrow-head.svg';
import { ReactComponent as StraightLineIcon } from '../../../../../../../assets/straight-line.svg';
import { ReactComponent as FreeLineIcon } from '../../../../../../../assets/freehand.svg';
import { ReactComponent as FreeArrowIcon } from '../../../../../../../assets/freehand_arrow.svg';
import { Select, Option } from '../../../../../../../components/inputs/select';
import NumberInput from '../../../../../../../components/inputs/numberInput';
import './videoTaskForm.scss';
import { lineTypes } from '../../projectMainViewVideoPlayer/arrowsOverlay/utils';

const VideoTaskForm = ({
  handleSubmit,
  time,
  isSubmitting,
  type,
  toggleLineType,
  modifyPlayerCurrentTime,
  useCase,
}) => {
  const [message, setMessage] = useState('');
  const [duration, setDuration] = useState(0.5);
  const [taskType, setTaskType] = useState(type);

  const onSubmit = () => {
    handleSubmit(message, duration, taskType);
    setDuration(duration);
  };

  const modifyDuration = (amount) => {
    const diff = amount - duration;
    modifyPlayerCurrentTime(diff);
    setDuration(amount);
  };

  return (
    <>
      <h3>
        Assign a task
        {useCase === 'video' && (
          <span>
            {' '}
            {`${formatMilliSeconds(time)}-${formatMilliSeconds(time + duration * 1000)}`}
          </span>
        )}
      </h3>
      <div className="modalScrollWrapper videoTaskForm">
        <div className="contentSection">
          <form>
            {Object.values(lineTypes).includes(taskType) && (
              <div className="additionalFields">
                <Select
                  onSelect={(selected) => {
                    setTaskType(selected);
                    toggleLineType(selected);
                  }}
                  label="Type:"
                  selected={taskType}
                >
                  <Option value={lineTypes.freeLine}>
                    <FreeLineIcon />
                  </Option>
                  <Option value={lineTypes.straightLine}>
                    <StraightLineIcon style={{ transform: 'rotate(45deg)' }} />
                  </Option>
                  <Option value={lineTypes.freeArrow}>
                    <FreeArrowIcon />
                  </Option>
                  <Option value={lineTypes.straightArrow}>
                    <ArrowIcon style={{ transform: 'rotate(45deg)' }} />
                  </Option>
                </Select>
                {useCase === 'video' && (
                  <NumberInput
                    value={duration}
                    min={0.5}
                    max={100}
                    step={0.5}
                    onChange={(value) => modifyDuration(value)}
                    label="Duration:"
                    suffix="s"
                  />
                )}
              </div>
            )}
            <textarea
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="taskPopUpTextarea"
              placeholder="Write something"
              autoFocus
            />
            <div className="submitSectionWrapper">
              <ExperimentalButton loading={isSubmitting} htmlType="button" onClick={onSubmit}>
                Submit
              </ExperimentalButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default memo(VideoTaskForm);

VideoTaskForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  time: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  type: PropTypes.string,
  toggleLineType: PropTypes.func.isRequired,
  modifyPlayerCurrentTime: PropTypes.func.isRequired,
  useCase: PropTypes.string.isRequired,
};

VideoTaskForm.defaultProps = {
  type: 'default',
};
