import { createSlice } from '@reduxjs/toolkit';
import { transformProject } from '../workspace/utils';
import {
  transformInvitedUsers,
  transformItems,
  transformMessages,
  transformProjectUsers,
  transformTask,
  transformTasks,
} from './utils';

export const projectSlice = createSlice({
  name: 'project',
  initialState: {
    users: [],
    invitedUsers: [],
    error: false,
    loading: false,
    modalOpen: false,
    projectId: null,
    title: null,
    currentCredentials: {
      identityId: null,
    },
    authorization: null,
    header: null,
    userRole: null,
    guestHash: null,
    items: null,
    tasks: null,
    messages: null,
    selectedItem: null,
    details: null,
  },
  reducers: {
    updateProjectUsers: (state, action) => {
      const users = action.payload ? transformProjectUsers(action.payload) : [];
      // console.log('projectUsers', users);
      state.users = users;
    },
    updateInvitedUsers: (state, action) => {
      const invitedUsers = action.payload ? transformInvitedUsers(action.payload) : [];
      state.invitedUsers = invitedUsers;
    },
    updateProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    updateProjectError: (state, action) => {
      state.projectError = action.payload;
    },
    updateProjectLoading: (state, action) => {
      state.projectLoading = action.payload;
    },
    updateProjectModal: (state, action) => {
      state.modalOpen = action.payload;
    },

    updateAuthorization: (state, action) => {
      state.authorization = action.payload;
    },
    updateHeader: (state, action) => {
      state.header = action.payload;
    },
    updateUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setItems: (state, action) => {
      const items = action.payload && transformItems(action.payload || []);
      state.items = items;
    },
    setTasks: (state, action) => {
      const tasks = action.payload && transformTasks(action.payload, state.users);

      state.tasks = tasks;
    },
    setMessages: (state, action) => {
      const messages = action.payload && transformMessages(action.payload, state.users);
      state.messages = messages;
    },
    addMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    removeMessage: (state, action) => {
      const newArr = [...state.messages];
      const index = newArr.findIndex((i) => i.id === action.payload);
      newArr.splice(index, 1);
      state.messages = newArr;
    },
    removeTask: (state, action) => {
      const newArr = [...state.tasks];
      const index = newArr.findIndex((i) => i.id === action.payload);
      newArr.splice(index, 1);
      state.tasks = newArr;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setProjectMetadata: (state, action) => {
      const projectData = transformProject(action.payload);
      state.projectId = action.payload.id?.S;
      state.title = action.payload.title?.S;
      state.guestHash = action.payload.guestHash;
      state.details = projectData;
    },
    updateItemPreview: (state, action) => {
      const item = action.payload;
      const newArr = [...state.items];
      const index = newArr.findIndex((i) => i.id === item.id);
      newArr[index] = { ...item, preview: !item.preview };
      state.items = newArr;
    },
    setItemsPreview: (state, action) => {
      const newItems = state.items.map((i) => ({ ...i, preview: true }));
      state.items = newItems;
    },
    addTask: (state, action) => {
      const newTask = transformTask(action.payload, state.users);
      state.tasks = [...state.tasks, newTask];
    },
    addMessageToTask: (state, action) => {
      const { task, message } = action.payload;

      const newTasks = [...state.tasks];
      const index = newTasks.findIndex((i) => i.id === task.id);
      newTasks[index] = { ...task, messages: [...task.messages, message] };

      state.tasks = newTasks;
    },
    updateTaskDuration: (state, action) => {
      const task = action.payload;
      const newTasks = [...state.tasks];
      const index = newTasks.findIndex((i) => i.id === task.id);

      newTasks[index] = task;

      state.tasks = newTasks;
    },
    addInvitedUser: (state, action) => {
      state.invitedUsers = [...state.invitedUsers, action.payload];
    },
    removeInvitedUser: (state, action) => {
      const newArr = [...state.invitedUsers];
      const index = newArr.findIndex((i) => i.uid === action.payload);
      newArr.splice(index, 1);
      state.invitedUsers = newArr;
    },
    removeUser: (state, action) => {
      const newArr = [...state.users];
      const index = newArr.findIndex((i) => i.uid === action.payload);
      newArr.splice(index, 1);
      state.users = newArr;
    },
    addUser: (state, action) => {
      const newArr = [...state.users, action.payload];
      state.users = newArr;
    },
  },
});
// example of acync reducer (thunk)
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

export const {
  updateProjectUsers,
  updateInvitedUsers,
  updateProjectId,
  updateProjectError,
  updateProjectLoading,
  updateProjectModal,
  updateAuthorization, // updateHeader
  updateHeader,
  updateUserRole,
  setItems,
  setTasks,
  setMessages,
  setSelectedItem,
  setProjectMetadata,
  updateItemPreview,
  setItemsPreview,
  addTask,
  addMessageToTask,
  updateTaskDuration,
  addMessage,
  addInvitedUser,
  removeInvitedUser,
  removeUser,
  addUser,
  removeMessage,
  removeTask,
} = projectSlice.actions;

export default projectSlice.reducer;
