import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { addProject, updateProjectImage } from '../../../apiServices/projectApi';
import { putToStorageDefault } from '../../../apiServices/storageApiService';
import { locationShape, historyShape } from '../../../routerPropTypes';
import { currentUserInfoShape } from '../../../apiServices/userApiTypes';
import {
  getParentId,
  getIsInWelcomeScreen,
  getMode,
  getHeadline,
  validateTitleField,
} from './NewItemModalUtilityFunctions';
import { RadioButtons } from './radioButtons/RadioButtons';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import TextInput from '../../inputs/textInput';
import NotificationContext from '../../../context/notification';
import ImageSection from '../setPictureModal/imageSection/ImageSection';
import { getLowerCaseFileExtensionInFile } from '../../../util/UtilFunctions';
import { pdfToPng } from '../setPictureModal/SetPictureModalUtilFunctions';
import { colorRedSecondary } from '../../../styles/variables';

class NewItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRadio: 'folder',
      titleFieldValue: '',
      pictureUploadingProcess: { status: 'initial' },
      newItemCreatingProcess: { status: 'initial' },
      errorMessage: false,
      croppedImageUrl: undefined,
    };
  }

  handletitleFieldValueChange = (event) => {
    this.setState({ titleFieldValue: event.target.value, errorMessage: false });
  };

  handleCloseButtonClick = () => {
    this.props.closeModal();
  };

  handleRadioChange = (type) => {
    this.setState({ activeRadio: type });
  };

  updateItemCover = async () => {
    try {
      this.props.updateIsQuerying(true);
      const storageResponse = await putToStorageDefault(
        this.state.pictureUploadingProcess.data.picture,
      );
      this.props.updateIsQuerying(false);
      try {
        this.props.updateIsQuerying(true);
        await updateProjectImage(
          this.state.newItemCreatingProcess.data.response,
          this.state.titleFieldValue,
          storageResponse.key,
          this.state.pictureUploadingProcess.data.name,
        );
        this.props.updateIsQuerying(false);
      } catch (updateProjectImageError) {
        this.props.updateIsQuerying(false);
        this.props.refreshList(false, undefined, true);
      }
    } catch (putToStorageError) {
      this.props.updateIsQuerying(false);
    }
  };

  handleItemSubmit = async (event) => {
    event.preventDefault();
    const mode = getMode(this.props.currentUserInfo.role.S, this.props.welcomeScreenSelection);
    const uploadingItem = mode === undefined ? this.state.activeRadio : mode;
    const errorMessage = validateTitleField(this.state.titleFieldValue);
    if (!errorMessage) {
      this.setState({ errorMessage: false });
      try {
        this.setState({ newItemCreatingProcess: { status: 'loading' } });
        this.props.updateIsQuerying(true);
        const parentId = getParentId(this.props.location.pathname);
        const addProjectResponse = await addProject({
          title: this.state.titleFieldValue,
          type: uploadingItem,
          parent: parentId,
        });
        this.setState({ newItemCreatingProcess: { status: 'success', data: addProjectResponse } });
        this.props.updateIsQuerying(false);
      } catch (addProjectError) {
        this.setState({ newItemCreatingProcess: { status: 'error' } });
        this.props.updateIsQuerying(false);
      }
      if (
        this.state.pictureUploadingProcess.status === 'pictureCropped' ||
        this.state.pictureUploadingProcess.status === 'pdfSelected'
      ) {
        await this.updateItemCover(this.state.newItemCreatingProcess.data.response);
        this.props.refreshList(
          { type: 'item', id: this.state.newItemCreatingProcess.data.response },
          0,
          true,
        );
      } else {
        this.props.refreshList(false, undefined, true);
      }
      this.showNotification(uploadingItem, this.state.newItemCreatingProcess);
      this.props.closeModal();
      if (getIsInWelcomeScreen(this.props.location.pathname)) {
        this.props.history.push('/in/folder/root');
      }
    } else {
      this.setState({ errorMessage });
    }
  };

  showNotification = (uploadingItem, itemCoverUpdatingProcess) => {
    const message =
      itemCoverUpdatingProcess.status === 'success'
        ? `Added ${uploadingItem}`
        : `Could not add ${uploadingItem}`;
    this.context.add({
      id: Date.now(),
      type: itemCoverUpdatingProcess.status,
      message,
    });
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();
    if (this.state.workspaceFieldValue.length > 0) {
      if (this.state.workspaceFieldValue.length < 50) {
        if (
          this.state.pictureUploadingProcess.status === 'pictureCropped' ||
          this.state.pictureUploadingProcess.status === 'pdfSelected'
        ) {
          this.addWorkspaceWithImage();
        } else {
          this.addWorkspace();
        }
      } else {
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: "Name can't be over 50 characters",
        });
      }
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Form is not complete',
      });
    }
  };

  handlePictureChange = async (event) => {
    const file = getLowerCaseFileExtensionInFile(event.target.files[0]);
    const mimeTypeSplitArr = file.type.split('/');
    if (mimeTypeSplitArr[1] === 'gif') {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: ".gif files can't be set as cover images",
      });
    } else if (mimeTypeSplitArr[0] === 'image') {
      this.handleImageSelection(file);
    } else if (mimeTypeSplitArr[1] === 'pdf') {
      this.handlePdfSelection(file);
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Given file is not an image or pdf',
      });
    }
  };

  updateCroppedImage = (croppedImage) => {
    const { pictureUploadingProcess } = this.state;
    this.setState({
      pictureUploadingProcess: {
        status: 'pictureCropped',
        data: { picture: pictureUploadingProcess.data.picture, croppedImage },
      },
    });
  };

  handleImageSelection = (file) => {
    this.setState({
      pictureUploadingProcess: {
        status: 'pictureSelected',
        data: { picture: file, croppedImage: undefined },
      },
    });
  };

  handlePdfSelection = async (file) => {
    this.setState(
      {
        pictureUploadingProcess: {
          status: 'pdfSelected',
          data: {
            pdfFile: file,
            picture: undefined,
          },
        },
      },
      async () => {
        try {
          const newFile = await pdfToPng(file);
          this.setState({
            pictureUploadingProcess: {
              status: 'pdfSelected',
              data: {
                picture: newFile,
              },
            },
            croppedImageUrl: URL.createObjectURL(file),
          });
        } catch (pdfCreationError) {
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not produce pdf thumbnail',
          });
        }
      },
    );
  };

  static contextType = NotificationContext;

  render() {
    const mode = getMode(this.props.currentUserInfo.role.S, this.props.welcomeScreenSelection);
    const headlineText = getHeadline(
      this.props.currentUserInfo.role.S,
      this.props.welcomeScreenSelection,
    );
    return (
      <Wrapper>
        <Headline>{headlineText}</Headline>
        <form onSubmit={this.handleItemSubmit}>
          <InputWrapper>
            {!mode && (
              <RadioButtons
                activeRadio={this.state.activeRadio}
                handleRadioChange={this.handleRadioChange}
              />
            )}
            <TextInput
              type="text"
              placeholder="Title"
              value={this.state.titleFieldValue}
              onChange={this.handletitleFieldValueChange}
              styleType="underline"
            />
            <ErrorMessage>{this.state.errorMessage && this.state.errorMessage}</ErrorMessage>
          </InputWrapper>
          <UploadPictureWrapper>
            <ImageSection
              useCase="createNew"
              pictureUploadingProcess={this.state.pictureUploadingProcess}
              currentUserInfo={this.props.currentUserInfo.S}
              updateCroppedImage={this.updateCroppedImage}
              croppedImageUrl={this.state.croppedImageUrl}
              handlePictureChange={this.handlePictureChange}
            />
          </UploadPictureWrapper>
        </form>
        <ExperimentalButton
          title="create"
          onClick={this.handleItemSubmit}
          isDisabled={this.state.errorMessage}
          loading={this.state.newItemCreatingProcess.status === 'loading'}
          type="primary"
        >
          Create
        </ExperimentalButton>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  form {
    width: 100%;
  }

  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin: 0 auto 1rem auto;
  }
`;

const Headline = styled.h4`
  text-align: center;
  font-size: 1.3em;
  margin: 0 0 1rem 0;
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .textInput {
    margin: 1rem 0 0.2rem 0;
    width: 90%;
    max-width: 14rem;
  }
`;

const ErrorMessage = styled.div`
  height: 1rem;
  font-size: 0.8rem;
  color: ${colorRedSecondary};
  opacity: 0.8;
  width: 90%;
  max-width: 14rem;
`;

const UploadPictureWrapper = styled.div`
  height: 17rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export default NewItemModal;

NewItemModal.propTypes = {
  // router props
  location: PropTypes.shape(locationShape).isRequired,
  history: PropTypes.shape(historyShape).isRequired,

  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,

  // other props
  closeModal: PropTypes.func.isRequired,
  refreshList: PropTypes.func,
  welcomeScreenSelection: PropTypes.oneOf(['project', 'folder']),
};

NewItemModal.defaultProps = {
  welcomeScreenSelection: undefined,
  refreshList: undefined,
};
