import React from 'react';
import PropTypes from 'prop-types';
import { dimensionsShape } from '../../ProjectMainViewPropTypes';
import {
  lineTypes,
  getArrowHeadPoints,
  getLinePoints,
} from '../projectMainViewVideoPlayer/arrowsOverlay/utils';

const Line = ({ dimensions, coords, lineType }) => (
  <svg
    style={{
      color: '#fff',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
    preserveAspectRatio="none"
    width="100%"
    height="100%"
  >
    <path d={`${getLinePoints(coords, lineType)}`} fill="none" stroke="#e32d4b" strokeWidth="4" />

    <polygon
      points={getArrowHeadPoints(coords, lineType)}
      stroke="#e32d4b"
      strokeWidth="4"
      fill="#e32d4b"
    />
  </svg>
);

export default Line;

Line.propTypes = {
  dimensions: PropTypes.shape(dimensionsShape).isRequired,
  coords: PropTypes.bool.isRequired,
  lineType: PropTypes.oneOf(Object.values(lineTypes)).isRequired,
};
