import PropTypes from 'prop-types';
import { workspaceParticipantShape, statusOptions } from '../../../app/AppPropTypes';

export const getCurrentWorkspacePeopleListProcessShape = {
  status: PropTypes.oneOf(statusOptions).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(workspaceParticipantShape)),
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
};
