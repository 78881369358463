import React from 'react';

const AddNewIcon = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 90.4 87.8"
    enableBackground="new 0 0 90.4 87.8"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M52.6,36.7h30.2c2.1,0,3.9,0.7,5.4,2.1c1.5,1.4,2.2,3.1,2.2,5.2c0,2-0.7,3.7-2.2,5.1c-1.5,1.4-3.3,2.2-5.4,2.2H52.6v29.2
            c0,1-0.2,1.9-0.6,2.8c-0.4,0.9-0.9,1.7-1.6,2.3c-0.7,0.7-1.5,1.2-2.4,1.6c-0.9,0.4-1.9,0.6-2.9,0.6c-2.1,0-3.9-0.7-5.3-2.1
            c-1.4-1.4-2.2-3.1-2.2-5.2V51.3H7.5c-1.1,0-2-0.2-2.9-0.6c-0.9-0.4-1.7-0.9-2.4-1.6c-0.7-0.7-1.2-1.5-1.6-2.3C0.2,46,0,45,0,44
            c0-2.1,0.7-3.8,2.2-5.2c1.4-1.4,3.2-2.1,5.3-2.1h30.1V7.4c0-2.1,0.7-3.8,2.2-5.2C41.2,0.7,43,0,45.1,0c2.1,0,3.8,0.7,5.3,2.2
            c1.5,1.4,2.2,3.2,2.2,5.2V36.7z"
      />
    </g>
  </svg>
);

export default AddNewIcon;
