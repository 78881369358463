import { Auth } from 'aws-amplify';

export const getCurrentSession = () => Auth.currentSession();
export const getCurrentAuthenticatedUser = () => Auth.currentAuthenticatedUser();
export const getCurrentCredentials = () => Auth.currentCredentials();
export const signOut = () => Auth.signOut();
export const signIn = (email, password) => Auth.signIn(email, password);
export const signUp = (params) => Auth.signUp(params);
export const confirmSignUp = (email, confirmationCode) => (
  Auth.confirmSignUp(email, confirmationCode));
export const changePassword = (user, oldPasswordFieldValue, newPasswordFieldValue) => (
  Auth.changePassword(user, oldPasswordFieldValue, newPasswordFieldValue));
export const updateUserAttributes = (user, params) => Auth.updateUserAttributes(user, params);
export const verifyCurrentUserAttributeSubmit = (type, confirmationCode) => (
  Auth.verifyCurrentUserAttributeSubmit(type, confirmationCode));
export const forgotPassword = (email) => Auth.forgotPassword(email);
export const forgotPasswordSubmit = (email, code, newPassword) => (
  Auth.forgotPasswordSubmit(email, code, newPassword));
export const completeNewPassword = (user, newPassword) => (
  Auth.completeNewPassword(user, newPassword));
export const resendVerificationCode = (userName) => Auth.resendSignUp(userName);
