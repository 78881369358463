import React from 'react';
import PropTypes from 'prop-types';
import classes from './ProjectChatMessagesLoading.module.css';

const ProjectChatMessagesLoading = (props) => {
  const messages = [];
  for (let i = 0; i < props.amount; i++) {
    const messageElement = (
      <li className={classes.loadingListItemWrapper} key={i}>
        <div className={classes.loadingListItemAvatarWrapper}>
          <div className={classes.loadingListItemAvatar} />
        </div>
        <div className={classes.loadingListItemInfoSectionWrapper}>
          <div className={classes.loadingListItemInfoSectionTitle} />
          <div className={classes.loadingListItemInfoSectionContent} />
        </div>
      </li>
    );
    messages.push(messageElement);
  }
  return <>{messages}</>;
};

export default ProjectChatMessagesLoading;

ProjectChatMessagesLoading.propTypes = {
  amount: PropTypes.number.isRequired,
};
