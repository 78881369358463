import React from 'react';
import PropTypes from 'prop-types';

const TasksIcon = (props) => {
  const { height } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 31.2 25"
      enableBackground="new 0 0 31.2 25"
      xmlSpace="preserve"
      height={height}
    >
      <path
        d="M22.5,15.5V22c0,0.6-0.4,1-1,1H3c-0.6,0-1-0.4-1-1V3.5c0-0.6,0.4-1,1-1h18.5c0.6,0,1,0.4,1,1v3.3c0,0.9,1.1,1.3,1.7,0.7l0,0
          c0.2-0.2,0.3-0.4,0.3-0.7V1.5c0-0.6-0.4-1-1-1H1c-0.6,0-1,0.4-1,1V24c0,0.6,0.4,1,1,1h22.5c0.6,0,1-0.4,1-1v-8.5
          c0-0.9-1.1-1.3-1.7-0.7l0,0C22.6,15,22.5,15.2,22.5,15.5z"
      />
      <path
        d="M5.3,11.1C5.1,10.9,5,10.6,5,10.4c0-0.2,0.1-0.5,0.3-0.7l1.4-1.4c0.4-0.4,1-0.4,1.4,0l0.1,0.1l5.5,5.9
          c0.2,0.2,0.5,0.2,0.7,0L28,0.3h0.1l0,0c0.4-0.4,1-0.4,1.4,0l1.4,1.4c0.4,0.4,0.4,1,0,1.4l0,0L14.8,19.8c-0.2,0.2-0.4,0.3-0.7,0.3
          s-0.5-0.1-0.7-0.3l-7.9-8.5L5.3,11.1z"
      />
    </svg>
  );
};

export default TasksIcon;

TasksIcon.propTypes = {
  height: PropTypes.string,
};

TasksIcon.defaultProps = {
  height: '20px',
};
