import React from 'react';
import PropTypes from 'prop-types';
import classes from './MainViewLoading.module.css';
import PreviewListLoading from './previewListLoading/PreviewListLoading';

const MainViewLoading = (props) => (
  <div className={classes.mainViewLoadingWrapper}>
    <div className={classes.mainContentLoadingWrapper}>
      <div className={classes.mainContentPreviewItemLoadingWrapper}>
        <div />
      </div>
      <PreviewListLoading amount={props.previewListItemsAmount} />
    </div>
    <div className={classes.chatLoadingWrapper} />
  </div>
);

export default MainViewLoading;

MainViewLoading.propTypes = {
  previewListItemsAmount: PropTypes.number.isRequired,
};
