export const getIsRoleEditable = (
  chosenGroup,
  currentUserWorkspacePeopleListItem,
  currentUserInfoInThisWorkspace,
  role,
  person,
) => {
  let roleIsEditable = false;
  if (chosenGroup.parent.S !== 'root') {
    if (currentUserInfoInThisWorkspace.role.S === 'admin') {
      if (role === 'pro' || role === 'user' || role === 'viewer') {
        roleIsEditable = true;
      } else if (role === 'admin') {
        if (chosenGroup.parent.S === 'root') {
          if (
            currentUserWorkspacePeopleListItem.creator &&
            currentUserWorkspacePeopleListItem.creator.N === '1'
          ) {
            if (person.user.S !== currentUserInfoInThisWorkspace.user.S) {
              roleIsEditable = true;
            }
          }
        } else if (chosenGroup.parent.S && chosenGroup.parent.S !== 'root') {
          if (
            currentUserWorkspacePeopleListItem.creator &&
            currentUserWorkspacePeopleListItem.creator.N === '1'
          ) {
            if (person.user.S !== currentUserInfoInThisWorkspace.user.S) {
              roleIsEditable = true;
            }
          }
        }
      }
    } else if (currentUserInfoInThisWorkspace.role.S === 'pro') {
      if (role === 'user' || role === 'viewer') {
        roleIsEditable = true;
      }
    }
  }
  return roleIsEditable;
};

export const getIsUserRemovable = (
  currentUserInfoInThisWorkspace,
  itemIsCurrentUser,
  person,
  role,
) => {
  if (!itemIsCurrentUser) {
    if (
      currentUserInfoInThisWorkspace.role.S === 'admin' &&
      currentUserInfoInThisWorkspace.creator &&
      currentUserInfoInThisWorkspace.creator.N === '1'
    ) {
      if (person.id.S !== currentUserInfoInThisWorkspace.user.S) {
        return true;
      }
    }
    if (
      currentUserInfoInThisWorkspace.role.S === 'admin' &&
      (role === 'pro' || role === 'user' || role === 'viewer')
    ) {
      return true;
    } else if (
      currentUserInfoInThisWorkspace.role.S === 'pro' &&
      (role === 'user' || role === 'viewer')
    ) {
      return true;
    }
  }
  return false;
};
