import React from 'react';
import PropTypes from 'prop-types';

import FolderIcon from '../icons/AllfilesIcon';
import ProjectIcon from '../icons/ProjectIcon';
import ImagesIcon from '../icons/ImagesIcon';
import VideosIcon from '../icons/VideosIcon';
import DocumentsIcon from '../icons/DocumentsIcon';
import SoundsIcon from '../icons/SoundsIcon';

const defaultColors = {
  video: '#29BAF8',
  image: '#EBC300',
  document: 'gray',
  folder: '#E35000',
  audio: '#00E1A1',
  project: '#45cdaa',
};

export const ItemTypeIcon = (props) => {
  switch (props.itemType) {
    case 'folder':
      return <FolderIcon height={`${props.size}px`} fill={props.color || defaultColors.folder} />;
    case 'project':
      return <ProjectIcon height={`${props.size}px`} fill={props.color || defaultColors.project} />;
    case 'resource': {
      switch (props.fileGenre) {
        case 'image':
          return (
            <ImagesIcon height={`${props.size}px`} fill={props.color || defaultColors.image} />
          );
        case 'video':
          return (
            <VideosIcon height={`${props.size}px`} fill={props.color || defaultColors.video} />
          );
        case 'audio':
          return (
            <SoundsIcon height={`${props.size}px`} fill={props.color || defaultColors.audio} />
          );
        case 'doc':
          return (
            <DocumentsIcon
              height={`${props.size}px`}
              fill={props.color || defaultColors.document}
            />
          );
        default:
          return <div />;
      }
    }
    default:
      return <div />;
  }
};

ItemTypeIcon.propTypes = {
  itemType: PropTypes.string.isRequired,
  fileGenre: PropTypes.string,
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
};

ItemTypeIcon.defaultProps = {
  fileGenre: undefined,
  color: undefined,
};
