import styles from './radioGroup.module.scss';

// Section for RadioGroup, its states, error-handling etc (file includes radioButton as well)

// type RadioButtonProps = {
//   id: string;
//   label?: string;
//   isSelected: boolean;
//   onChange: (p: string) => void;
//   disabled?: boolean;
// };

const RadioButton = ({ id, label, isSelected, onChange, disabled }) => {
  return (
    <div className={`${styles.radioButton} ${disabled ? styles.disabled : ''}`}>
      <label>
        <input
          type="radio"
          className={styles.inputElement}
          onChange={() => {
            onChange(id);
          }}
          value={id}
          checked={isSelected}
          disabled={disabled}
        />
        <span className={`${styles.box} ${isSelected ? styles.selected : ''}`} aria-hidden="true">
          <span />
        </span>
        {label}
      </label>
    </div>
  );
};

// type RadioGroupElement = {
//   id: string;
//   label: string;
// };

// type RadioGroupProps = {
//   title?: string;
//   selected?: string;
//   items: RadioGroupElement[];
//   errorMessage?: string;
//   onSelect: (p: string) => void;
//   disabled?: boolean;
//   layout?: 'inline' | 'default'
// };

const RadioGroup = ({ title, selected, items, onSelect, disabled }) => {
  return (
    <div className={styles.radioGroup}>
      {title && <span>{title}</span>}
      <div className={`${styles.radioButtonContainer} `}>
        {items.map(({ id, label }) => (
          <RadioButton
            key={id}
            id={id}
            label={label}
            isSelected={id === selected}
            onChange={onSelect}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
