import { hasExpired } from '../util/subscriptionDetails';
import { userIsCurrentRootGroupCreator } from '../util/UtilFunctions';

export const getActiveWorkspace = (groupsList, currentUserGroup) => {
  if (groupsList) {
    return groupsList.find((group) => currentUserGroup === (group.id.S || group.id));
  } else {
    return undefined;
  }
};

export const getIsRootWorkspace = (activeWorkspace) => {
  if (activeWorkspace) {
    return (activeWorkspace.parent?.S || activeWorkspace.parent) === 'root';
  } else {
    return false;
  }
};

export const getPathSecondItem = (location) => {
  const pathnameSplitArr = location.pathname.split('/');
  return pathnameSplitArr[2];
};

export const getCurrentUserGroup = (groupsList) => {
  const localStorageCurrentUserGroup = window.localStorage.getItem('currentUserGroup');
  const localStorageCurrentUserGroupIsValid =
    groupsList.find((group) => group.id.S === localStorageCurrentUserGroup) !== undefined;
  if (localStorageCurrentUserGroupIsValid) {
    return localStorageCurrentUserGroup;
  } else {
    if (groupsList && groupsList[0]) {
      window.localStorage.setItem('currentUserGroup', groupsList[0].id.S);
      return groupsList[0].id.S;
    } else {
      return undefined;
    }
  }
};

export const handleLoginRedirect = (
  getGroupsListProcess,
  currentUserGroup,
  loginProcessOngoing,
  history,
) => {
  const activeWorkspace = getActiveWorkspace(getGroupsListProcess.data, currentUserGroup);
  const isRootWorkspace = getIsRootWorkspace(activeWorkspace);
  if (isRootWorkspace && loginProcessOngoing) {
    history.push('/in/account/workspace');
  } else if (!isRootWorkspace && loginProcessOngoing) {
    history.push('/in/folder/root');
  }
};

export const getIsWorkspaceExpired = (getGroupsListProcess, currentUserInfo, currentUserGroup) => {
  const isCurrentRootGroupCreator = userIsCurrentRootGroupCreator(
    getGroupsListProcess.data,
    currentUserInfo,
    currentUserGroup,
  );
  if (isCurrentRootGroupCreator) {
    const currentRootGroup = getGroupsListProcess.data.find(
      (group) => group.id.S === currentUserGroup,
    );
    return hasExpired(currentRootGroup);
  } else {
    return false;
  }
};
