import React, { useRef } from 'react';
import CopyItemModal from '../../modals/copyItemModal/CopyItemModal';
import MoveItemModal from '../../modals/moveItemModal/MoveItemModal';
import FileUploadingModal from '../../modals/fileUploadingModal/FileUploadingModal';
import NewItemModal from '../../modals/newItemModal/NewItemModal';
import ShareFileModal from '../../modals/shareFileModal/ShareFileModal';
import SetPictureModal from '../../modals/setPictureModal/SetPictureModal';
import { ReactComponent as CrossIcon } from '../../../assets/nitro/cross.svg';
import './itemListModals.scss';
import DeleteItemModal from '../../modals/deleteItemModal/DeleteItemModal';
import RenameModal from '../../modals/renameModal/renameModal';
import { ConvertModal } from '../../modals/convertModal/ConvertModal';

// TODO: add keyboard events
const ItemListModals = (props) => {
  const modalContent = useRef(null);
  const handleClick = (e) => {
    if (modalContent.current) {
      if (!modalContent.current.contains(e.target)) {
        props.updateModalOpen();
      }
    }
  };

  const handleModalClose = (selectedItems, removeFromSelectedItems, updateModalOpen) => {
    if (selectedItems.length === 1) {
      removeFromSelectedItems(selectedItems);
      updateModalOpen();
    } else {
      updateModalOpen();
    }
  };

  return (
    <div
      className={`itemListModals ${props.modalOpen ? '' : 'closed'} ${
        props.modalOpen ? props.modalOpen : ''
      }`}
      onClick={handleClick}
    >
      <div className="modalContent" ref={modalContent}>
        <div className="header">
          <button
            onClick={() =>
              handleModalClose(
                props.selectedItems,
                props.removeFromSelectedItems,
                props.updateModalOpen,
              )
            }
            title="close modal"
            type="button"
          >
            <CrossIcon />
          </button>
        </div>
        {props.modalOpen === 'copy' && (
          <CopyItemModal
            selectedItems={props.selectedItems}
            closeModal={props.updateModalOpen}
            workspaceName="root"
            removeFromSelectedItems={props.removeFromSelectedItems}
            refreshList={props.refreshList}
            match={props.match}
            pathname={props.location.pathname}
            updateIsQuerying={props.updateIsQuerying}
            history={props.history}
          />
        )}
        {props.modalOpen === 'move' && (
          <MoveItemModal
            selectedItems={props.selectedItems}
            closeModal={props.updateModalOpen}
            workspaceName="root"
            removeFromSelectedItems={props.removeFromSelectedItems}
            refreshList={props.refreshList}
            match={props.match}
            pathname={props.location.pathname}
            updateIsQuerying={props.updateIsQuerying}
            history={props.history}
          />
        )}
        {props.modalOpen === 'fileUpload' && (
          <FileUploadingModal
            closeModal={props.updateModalOpen}
            currentItem={props.currentItem}
            inProjectInitialState={props.inProjectInitialState}
            history={props.history}
            updateIsQuerying={props.updateIsQuerying}
            refreshList={props.refreshList}
            content={props.content}
            useCase={props.useCase}
          />
        )}
        {props.modalOpen === 'newItem' && (
          <NewItemModal
            closeModal={props.updateModalOpen}
            history={props.history}
            updateIsQuerying={props.updateIsQuerying}
            refreshList={props.refreshList}
            currentUserInfo={props.currentUserInfo}
            match={props.match}
            location={props.location}
          />
        )}
        {props.modalOpen === 'shareFile' && (
          <ShareFileModal
            closeModal={props.updateModalOpen}
            selectedFiles={props.selectedItems}
            currentUserInfo={props.currentUserInfo}
            updateIsQuerying={props.updateIsQuerying}
            modalParams={props.modalParams}
          />
        )}
        {props.modalOpen === 'delete' && (
          <DeleteItemModal
            selectedItems={props.selectedItems}
            closeModal={props.updateModalOpen}
            updateIsQuerying={props.updateIsQuerying}
            refreshList={props.refreshList}
            userIsAdmin={false}
          />
        )}
        {props.modalOpen === 'coverImage' && (
          <SetPictureModal
            closeModal={props.updateModalOpen}
            item={props.modalParams[0].item}
            useCase="coverImage"
            refreshList={props.refreshList}
            updateIsQuerying={props.updateIsQuerying}
            currentUserInfo={props.currentUserInfo}
            hasImage={props.modalParams[0].item.image}
          />
        )}
        {props.modalOpen === 'rename' && (
          <RenameModal
            closeModal={props.updateModalOpen}
            item={props.modalParams[0].item}
            title={props.modalParams[0].title}
            refreshList={props.refreshList}
            updateIsQuerying={props.updateIsQuerying}
            currentUserInfo={props.currentUserInfo}
            placeholder="New name"
          />
        )}
        {props.modalOpen === 'convert' && (
          <ConvertModal
            closeModal={props.updateModalOpen}
            item={props.modalParams[0].item}
            refreshList={props.refreshList}
            updateIsQuerying={props.updateIsQuerying}
          />
        )}
      </div>
    </div>
  );
};

export default ItemListModals;

// ItemListModals.propTypes = {
//   // router props
//   history: PropTypes.shape(historyShape).isRequired,
//   match: PropTypes.shape(matchShape).isRequired,
//   location: PropTypes.shape(locationShape).isRequired,

//   // app.jsx props
//   currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
//   updateIsQuerying: PropTypes.func.isRequired,
//   currentItem: PropTypes.object.isRequired,

//   // Folder.jsx or ProjectView.jsx props
//   inProjectInitialState: PropTypes.bool.isRequired,
//   refreshList: PropTypes.func.isRequired,
//   content: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,

//   // itemList.jsx props
//   selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
//   modalOpen: modalOpenType,
//   modalParams: PropTypes.arrayOf(PropTypes.object),
//   updateModalOpen: PropTypes.func.isRequired,
//   removeFromSelectedItems: PropTypes.func.isRequired,
// };

// ItemListModals.defaultProps = {
//   modalOpen: undefined,
//   modalParams: undefined,
// };
