import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Cross } from '../../../assets/nitro/cross.svg';
import { ReactComponent as Check } from '../../../assets/nitro/checked.svg';
import './toggleSwitch.scss';

const ToggleSwitch = ({ checked, name, onChange, disabled }) => (
  <div className={`toggle-switch ${disabled ? 'disabled' : ''}`}>
    <input
      type="checkbox"
      className="toggle-switch-checkbox"
      name={name}
      id={name}
      checked={checked}
      disabled={disabled}
      onChange={(e) => (!disabled ? onChange(e) : '')}
    />
    <label className="toggle-switch-label" htmlFor={name}>
      <span className="toggle-switch-inner" />
      <span className="toggle-switch-switch">
        {!checked ? <Cross className={`crossIcon`} /> : <Check className={`checkIcon`} />}
      </span>
    </label>
  </div>
);

export default ToggleSwitch;

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
