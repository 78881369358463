export const getUserSubFromLocalStorageByEmail = (email) => {
  const userSubArr = window.localStorage.getItem('userSub');
  if (userSubArr) {
    const parsedArr = JSON.parse(userSubArr);
    const obj = parsedArr.find((item) => item.email === email);
    if (obj?.userSub) {
      return obj.userSub;
    } else {
      return '';
    }
  }
  return '';
};

export const getInvitationIdFromQueryParams = (location) => {
  if (location.search !== '') {
    if (location.search.charAt('?') !== -1) {
      const queryParam = location.search.split('?')[1];
      return queryParam.split('=').length === 2 ? queryParam.split('=')[1] : undefined;
    }
    return undefined;
  } else {
    return undefined;
  }
};
