import React from 'react';
import PropTypes from 'prop-types';

const VideosIcon = (props) => {
  const { height, fill } = props;
  return (
    <svg
      version="1.1"
      id="Icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48 37"
      enableBackground="new 0 0 48 37"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={fill}
        d="M0,0v37h48V0H0z M8,34H4v-4h4V34z M8,25H4v-4h4V25z M8,16H4v-4h4V16z M8,7H4V3h4V7z M18,28.5v-20l16.5,10
      L18,28.5z M44,34h-4v-4h4V34z M44,25h-4v-4h4V25z M44,16h-4v-4h4V16z M44,7h-4V3h4V7z"
      />
    </svg>
  );
};

export default VideosIcon;

VideosIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

VideosIcon.defaultProps = {
  height: '0',
};
