import React from 'react';
import PropTypes from 'prop-types';
import classes from './Buttons.module.css';
import ExperimentalButton from '../../../buttons/experimentalButton/experimentalButton';
import ChooseFilesButton from '../../../buttons/chooseFilesButton/ChooseFilesButton';
import {
  imageRemovingProcessShape,
  pictureUploadingProcessShape,
  useCaseOptions,
} from '../SetPictureModalPropTypes';

const Buttons = (props) => (
  <>
    {props.pictureUploadingProcess.status === 'initial' && (
      <div className={classes.buttonsWrapper}>
        <div className={classes.buttonWrapper}>
          <ChooseFilesButton
            handleChange={props.handlePictureChange}
            multiple={false}
            accept="image/png, image/jpg, image/jpeg, application/pdf"
            title="choose new files"
            animated={false}
            isDisabled={false}
          >
            Choose new
          </ChooseFilesButton>
        </div>
        {props.hasImage && (
          <div className={classes.buttonWrapper}>
            <ExperimentalButton
              onClick={props.removeImage}
              title="remove image"
              type="red"
              loading={props.imageRemovingProcess.status === 'loading'}
            >
              Remove
            </ExperimentalButton>
          </div>
        )}
      </div>
    )}
    {(props.pictureUploadingProcess.status === 'pictureSelected' ||
      props.pictureUploadingProcess.status === 'pictureCropped' ||
      props.pictureUploadingProcess.status === 'uploading' ||
      props.pictureUploadingProcess.status === 'pdfSelected') && (
      <div className={classes.buttonsWrapper}>
        <div className={classes.buttonWrapper}>
          <ChooseFilesButton
            handleChange={props.handlePictureChange}
            multiple={false}
            accept="image/png, image/jpg, image/jpeg, application/pdf"
            title="choose new files"
            animated={false}
            isDisabled={false}
          >
            Choose another
          </ChooseFilesButton>
        </div>
        <div className={classes.buttonWrapper}>
          <ExperimentalButton
            onClick={props.getOnClick(props.useCase)}
            title="upload image"
            type="primary"
            loading={props.pictureUploadingProcess.status === 'uploading'}
          >
            Upload
          </ExperimentalButton>
        </div>
      </div>
    )}
  </>
);

export default Buttons;

Buttons.propTypes = {
  pictureUploadingProcess: PropTypes.shape(pictureUploadingProcessShape).isRequired,
  imageRemovingProcess: PropTypes.shape(imageRemovingProcessShape).isRequired,
  getOnClick: PropTypes.func.isRequired,
  handlePictureChange: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  useCase: PropTypes.oneOf(useCaseOptions).isRequired,
  hasImage: PropTypes.bool.isRequired,
};
