import { API } from 'aws-amplify';
import store from '../configureStore';
import { config } from '../config/Config';

export const getFolders = () => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const headersObj = { headers: header };
  return API.get(config.apiGateway.NAME, `/folder?gid=${currentUserGroup}`, headersObj);
};

export const getFolderById = (subfolderId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const headersObj = { headers: header };
  return API.get(
    config.apiGateway.NAME,
    `/folder/${subfolderId}?gid=${currentUserGroup}`,
    headersObj,
  );
};

export const getFolderContentIdsList = (subfolderId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const getFolderContentIdsListInit = { headers: header };
  return API.get(
    config.apiGateway.NAME,
    `/folder/${subfolderId}?gid=${currentUserGroup}&min=1`,
    getFolderContentIdsListInit,
  );
};

export const deleteFolder = (folderId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const deleteFolderInit = {
    headers: header,
    body: `id=${folderId}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/resource/delete?gid=${currentUserGroup}&id=${folderId}`,
    deleteFolderInit,
  );
};
