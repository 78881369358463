import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import { borderRadiusDefault } from '../../../styles/variables';

const DataTransferLimitModal = (props) => {
  const handleClick = () => {
    window.localStorage.setItem('data-transfer-limit-notification-accepted', 'true');
    props.closeModal();
  };
  return (
    <Wrapper>
      <Title>Data transfer limit exceeded</Title>
      <ContentWrapper>
        <p>additional traffic costs 0.2€ per gigabyte</p>
        <ExperimentalButton onClick={handleClick} title="delete files" type="primary">
          ok
        </ExperimentalButton>
      </ContentWrapper>
    </Wrapper>
  );
};

export default DataTransferLimitModal;

DataTransferLimitModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: ${borderRadiusDefault};
  height: 100%;
  margin: auto;
  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`;

const Title = styled.h4`
  text-align: center;
  font-size: 1.3em;
  margin: 0 0 1rem 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
