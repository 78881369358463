import React from 'react';
import PropTypes from 'prop-types';
import './textInput.scss';

const TextInput = ({ styleType, className, ...props }) => (
  <input className={`textInput ${className || ''} ${styleType}`} type="text" {...props} />
);

export default TextInput;

TextInput.propTypes = {
  // set some commonly used styling. At the moment: "underline", defaults to underline.
  styleType: PropTypes.string,
  // plus all native input-attributes
  className: PropTypes.string,
};

TextInput.defaultProps = {
  styleType: 'underline',
  className: undefined,
};
