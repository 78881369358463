import React from 'react';
import './AccountSettingsSupport.scss';
import { supportEmail } from '../../../../constantValues/ConstantValues';

export const AccountSettingsSupport = () => (
  <div className="account-settings-support-wrapper">
    <h5>
      Please take a look at the
      <a
        className="terms-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://yemma-eu-central-1-dev-static-files.s3.eu-central-1.amazonaws.com/Yemma+user+guide.pdf"
        title="https://yemma-eu-central-1-dev-static-files.s3.eu-central-1.amazonaws.com/Yemma+user+guide.pdf"
      >
        user manual
      </a>
    </h5>
    <h5>
      If you need help, contact support at
      <a
        className="terms-link"
        target="_blank"
        rel="noopener noreferrer"
        href={`mailto:${supportEmail}`}
        title={supportEmail}
      >
        {supportEmail}
      </a>
    </h5>
  </div>
);
