import React from 'react';
import styled from 'styled-components';
import { addNewUser, addGuestUser, addInvitedUser } from '../../../../apiServices/userApi';
import { getValueFromUserAttributes, validateEmailFormat } from '../../../../util/UtilFunctions';
import ExperimentalButton from '../../../buttons/experimentalButton/experimentalButton';
import { Checkbox } from '../../../inputs/checkbox/Checkbox';
import './ProjectParticipiantsModalAddNewPeopleSection.scss';
import NotificationContext from '../../../../context/notification';
import * as stylesVariables from '../../../../styles/variables';
import RoleSelector from '../../workspaceParticipiantsModal/roleSelector';
import RadioGroup from '../../../inputs/radioGroup';
import { addInvitedUser as addInvitedUserToContext } from '../../../../app/features/project/projectSlice';
import { connect } from 'react-redux';

const roles = {
  pro: {
    id: 'pro',
    label: 'pro',
  },
  user: {
    id: 'user',
    label: 'user',
  },
  viewer: {
    id: 'viewer',
    label: 'viewer',
  },
  guest: {
    id: 'guest',
    label: 'guest',
  },
};
class ProjectParticipiantsModalAddNewPeopleSection extends React.Component {
  state = {
    showInfo: undefined,
    emailFieldValue: '',
    selectedRole: 'viewer',
    personAddingProcess: { status: 'initial' },
  };

  updateShowInfo = (showInfoProps) => {
    if (!this.state.showInfo) {
      this.setState({ showInfo: showInfoProps });
    } else {
      this.setState({ showInfo: undefined });
    }
  };

  handleEmailFieldValueChange = (event) => {
    this.setState({ emailFieldValue: event.target.value.toLowerCase() });
  };

  capitalize = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return undefined;
  };

  validateFormEmpty = () => this.state.emailFieldValue !== '';

  validateFormTooLong = () => this.state.emailFieldValue.length < 50;

  handleFormSubmit = async (event) => {
    event.preventDefault();
    if (this.validateFormEmpty()) {
      if (this.validateFormTooLong()) {
        if (validateEmailFormat(this.state.emailFieldValue)) {
          this.setState({
            personAddingProcess: { status: 'loading' },
          });
          try {
            const addNewUserResponse =
              this.state.selectedRole === 'guest'
                ? await addGuestUser(this.state.emailFieldValue.toLowerCase(), this.props.projectId)
                : await addNewUser(
                    this.state.emailFieldValue.toLowerCase(),
                    this.state.selectedRole,
                    this.props.currentUserGroup,
                    this.props.projectId,
                  );
            if (addNewUserResponse.response.includes('ERROR')) {
              // no further explanation for the error
              // TODO: specify restrictions and modify UI according to it
              throw new Error({});
            }
            this.props.addInvitedUserToContext({
              uid: undefined,
              created: new Date().toString(),
              role: this.state.selectedRole,
              group: this.props.currentUserGroup,
              inviter: '',
              expires: '',
              email: this.state.emailFieldValue.toLowerCase(),
            });

            this.context.add({
              id: Date.now(),
              type: 'success',
              message: `Invited ${this.state.selectedRole} '${this.state.emailFieldValue}' participant`,
            });
            this.restoreDefaultState();
          } catch (addUserError) {
            this.restoreDefaultState();
            this.context.add({
              id: Date.now(),
              type: 'error',
              message: 'Could not invite user',
            });
          }
        } else {
          this.context.add({
            id: Date.now(),
            type: 'info',
            message: 'Given text is not an email address',
          });
          this.setState({ emailFieldValue: '' });
        }
      } else {
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: "Form entries can't be over 50 characters",
        });
      }
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Form not complete',
      });
    }
  };

  restoreDefaultState = () => {
    this.setState({
      emailFieldValue: '',
      personAddingProcess: { status: 'initial' },
    });
  };

  getAvailableRoles = () => {
    if (this.props.currentUserInfo.role.S === 'admin') {
      return [roles.pro, roles.user, roles.viewer, roles.guest];
    } else if (this.props.currentUserInfo.role.S === 'pro') {
      return [roles.user, roles.viewer, roles.guest];
    } else if (this.props.currentUserInfo.role.S === 'user') {
      return [roles.viewer];
    } else {
      return [];
    }
  };

  static contextType = NotificationContext;

  render() {
    return (
      <div className="project-participants-modal-add-new-people-section-content-wrapper">
        <div className="form">
          <div className="formInfoFieldsWrapper">
            <input
              type="text"
              placeholder="email"
              value={this.state.emailFieldValue}
              onChange={this.handleEmailFieldValueChange}
              className="inputField"
            />
            <ListWrapper>
              <RadioGroup
                title="Select role"
                selected={this.state.selectedRole}
                items={this.getAvailableRoles()}
                onSelect={(id) => this.setState({ selectedRole: id })}
              />
              {this.state.showInfo && (
                <Box
                  maxHeight={this.state.showInfo.maxHeight}
                  stylesVariables={stylesVariables}
                  left={this.state.showInfo.left}
                  top={this.state.showInfo.top}
                >
                  <BoxContentWrapper>
                    {this.state.showInfo.children}
                    <Button onClick={this.updateShowInfo} title="close info">
                      X
                    </Button>
                  </BoxContentWrapper>
                </Box>
              )}
            </ListWrapper>
          </div>
          <div className="buttonWrapper">
            <ExperimentalButton
              loading={this.state.personAddingProcess.status === 'loading'}
              onClick={this.handleFormSubmit}
            >
              Add
            </ExperimentalButton>
          </div>
        </div>
      </div>
    );
  }
}

const BoxContentWrapper = styled.div`
  position: relative;
`;

const ListWrapper = styled.div`
  position: relative;
`;

const Box = styled.div`
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 7.5rem);
  background-color: ${(props) => props.stylesVariables.colorGreySecondary};
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 15rem;
  padding-bottom: 1rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  max-height: ${(props) => props.maxHeight};
  z-index: 300;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.stylesVariables.colorGreySecondary};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.stylesVariables.colorGreyPrimary};
    border-radius: 5px;
    height: 50px;
  }
  h4 {
    margin: 0.5rem;
    text-transform: capitalize;
    font-size: 0.95rem;
  }
  ul {
    padding: 0 0 0 1.5rem;
    li {
      text-align: left;
      font-size: 0.85rem;
      line-height: 1rem;
      padding-bottom: 0.5rem;
    }
  }
  .secondaryText {
    font-size: 0.85rem;
    text-align: left;
    color: darken(${stylesVariables.colorDenimSecondary}, 15);
    margin: 0;
  }
`;

const Button = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  addInvitedUserToContext,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectParticipiantsModalAddNewPeopleSection);
