import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import '../login/login.scss';
import { historyShape, locationShape } from '../../../routerPropTypes';
import InformationForm from './informationForm/InformationForm';
import { getInvitedUserEmail } from '../../../apiServices/userApi';
import { getInvitationIdFromQueryParams } from './SignupUtilFunctions';
import ErrorBoundary from '../../errorBoundary/ErrorBoundary';
import { ReactComponent as DividerShape } from '../../../assets/nitro/divider-shape.svg';
import { SpeechBubble } from '../../../components/speechBubble/SpeechBubble';
import { breakpointSmall } from '../../../styles/variables';

const signUpInitialState = {
  getInvitedUserEmailProcess: { status: 'inactive' },
};

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = signUpInitialState;
  }

  componentDidMount = async () => {
    const invitationId = getInvitationIdFromQueryParams(this.props.location);
    if (invitationId) {
      this.getEmailFromInvitationId(invitationId);
    }
  };

  // user registering with an invitation id, (received from an email)
  getEmailFromInvitationId = async (invitationId) => {
    try {
      this.setState({ getInvitedUserEmailProcess: { status: 'loading' } });
      const getInvitedUserEmailResponse = await getInvitedUserEmail(invitationId);
      this.setState({
        getInvitedUserEmailProcess: {
          status: 'success',
          data: getInvitedUserEmailResponse.response.Item,
        },
      });
    } catch (getInvitedUserEmailError) {
      this.setState({
        getInvitedUserEmailProcess: { status: 'error', error: getInvitedUserEmailError },
      });
    }
  };

  render = () => (
    <ErrorBoundary>
      <div className="signup">
        <div className="whiteBackground" />
        <div className="dividerShape">
          <DividerShape />
        </div>
        <div className="greenBackground" />
        {this.state.getInvitedUserEmailProcess.status === 'inactive' && (
          <SpeechBubbleWrapper>
            <SpeechBubble />
          </SpeechBubbleWrapper>
        )}
        <CardWrapperWrapper>
          <CardWrapper>
            <div className="card">
              <div className="header">
                <h5>Sign up</h5>
              </div>
              <InformationForm
                infoFormProcess={this.state.infoFormProcess}
                updateInfoFormProcess={this.updateInfoFormProcess}
                getInvitedUserEmailProcess={this.state.getInvitedUserEmailProcess}
                history={this.props.history}
              />
            </div>
          </CardWrapper>
        </CardWrapperWrapper>
      </div>
    </ErrorBoundary>
  );
}

export default Signup;

Signup.propTypes = {
  // router props
  location: PropTypes.shape(locationShape).isRequired,
  history: PropTypes.shape(historyShape).isRequired,
};

export const CardWrapperWrapper = styled.div`
  position: absolute;
  top: 200px;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const CardWrapper = styled.div`
  position: relative;
`;

export const SpeechBubbleWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: calc(50% - 200px);
  @media (max-width: ${breakpointSmall}) {
    left: calc(50% - 100px);
  }
`;
