import React, { useEffect } from 'react';
import ProfileBall from '../../profileBall/ProfileBall';
import Subnavigation, { SubnavigationItem } from '../Subnavigation';
import {
  getValueFromUserAttributes,
  currentUserIsAdminOfRootGroup,
  getCurrentUserCreatorGroup,
  getClosestRootGroup,
} from '../../../util/UtilFunctions';
import './userDetails.scss';
import StorageSection from './storageSection';
import { useSelector } from 'react-redux';

const UserDetails = ({
  handleLogout,
  currentUserInfo,
  updateCurrentUserInfo,
  updateRootWorkspaceDetails,
  rootWorkspaceDetails,
  groupsList,
  history,
  updateSubnav,
  isSubnavOpen,
  updateModalOpen,
}) => {
  const { email, given_name, family_name } = useSelector((state) => state.user);
  const { createdGroup, rootGroup } = useSelector((state) => state.workspace);

  // update subnav on userTrigger-click
  // prevent subnav's outsideClick from closing it again
  const handleClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    updateSubnav();
  };

  const navigate = (url) => {
    history.push(url);
    updateSubnav();
  };

  // const renderPackageInfo = (currentUserCreatorGroup) => {
  //   if (!currentUserCreatorGroup) return null;
  //   const closestRoot = getClosestRootGroup(groupsList, currentUserGroup);
  //   let content;
  //   if (closestRoot?.status.N === '0') {
  //     content = <h5 className="expired-subscription-text">Expired subscription</h5>;
  //   } else if (closestRoot?.status.N === '2') {
  //     content = <h5 className="free-trial-text">Free trial</h5>;
  //   } else {
  //     return null;
  //   }
  //   return (
  //     <SubnavigationItem
  //       onClick={
  //         currentUserCreatorGroup?.id.S === currentUserGroup
  //           ? () => navigate('/in/account/billing')
  //           : () => {}
  //       }
  //     >
  //       {content}
  //     </SubnavigationItem>
  //   );
  // };

  return (
    <div className="userDetails">
      <div
        className={`triggerWrapper ${isSubnavOpen ? 'active' : ''}`}
        title="user details"
        onClick={handleClick}
      >
        <ProfileBall
          imageUrl={currentUserInfo.image?.S}
          refreshPicture={updateCurrentUserInfo}
          firstName={given_name}
          lastName={family_name}
        />
        <h4 className="userLabel" title="user details">
          {given_name}
        </h4>
      </div>
      <Subnavigation close={updateSubnav} isOpen={isSubnavOpen}>
        <SubnavigationItem className="userWrapper">
          <>
            <ProfileBall
              imageUrl={currentUserInfo.image?.S}
              refreshPicture={updateCurrentUserInfo}
              firstName={given_name}
              lastName={family_name}
            />
            <div className="infoWrapper">
              <h4>{`${given_name} ${family_name}`}</h4>
              <h5>{email}</h5>
            </div>
          </>
        </SubnavigationItem>
        {rootGroup?.role === 'admin' &&
          (rootGroup?.status === '2' || rootGroup?.status === '1') && (
            <SubnavigationItem className="storageWrapper">
              <span className="workspace-name">{rootGroup.title}</span>
              <StorageSection
                rootGroup={rootGroup}
                rootWorkspaceDetails={rootWorkspaceDetails}
                groupsList={groupsList}
                updateRootWorkspaceDetails={updateRootWorkspaceDetails}
                updateModalOpen={updateModalOpen}
                currentUserInfo={currentUserInfo}
              />
            </SubnavigationItem>
          )}
        <SubnavigationItem
          disabled={createdGroup?.status === '3' || createdGroup?.status === '4'}
          onClick={() => navigate('/in/account/profile')}
        >
          Account Settings
        </SubnavigationItem>
        {/**renderPackageInfo(currentUserCreatorGroup)*/}
        <SubnavigationItem
          onClick={() => {
            handleLogout();
            updateSubnav();
          }}
        >
          Sign out
        </SubnavigationItem>
      </Subnavigation>
    </div>
  );
};

export default UserDetails;

// UserDetails.propTypes = {
//   handleLogout: PropTypes.func.isRequired,
//   currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
//   updateCurrentUserInfo: PropTypes.func.isRequired,
//   updateRootWorkspaceDetails: PropTypes.func.isRequired,
//   rootWorkspaceDetails: PropTypes.shape(workspaceDetailsShape),
//   groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
//   history: PropTypes.shape(historyShape).isRequired,
//   updateSubnav: PropTypes.func.isRequired,
//   isSubnavOpen: PropTypes.bool.isRequired,
//   currentUserGroup: PropTypes.string.isRequired,
//   updateModalOpen: PropTypes.func.isRequired,
// };

// UserDetails.defaultProps = {
//   rootWorkspaceDetails: undefined,
// };
