import * as data from './supported-file-types.json';
import { getExtFromFileName } from './UtilFunctions';

const getSupportedFilesList = (genre) => {
  switch (genre) {
    case 'image':
      return data.default.images;
    case 'video':
      return data.default.videos;
    case 'audio':
      return data.default.audios;
    case 'doc':
      return data.default.documents;
    default:
      return [];
  }
};

const isExtensionSupported = (genre, ext) => {
  const supportedList = getSupportedFilesList(genre);
  const supportedImagesObjects = supportedList.filter((item) => item.supported.chrome === 1);
  const supportedImageExtensions = supportedImagesObjects.map((item) => item.extension);

  return supportedImageExtensions.some((supportedExt) => supportedExt === ext);
};

export const validateFileTypeRendability = (fileGenre, fileExt) => {
  if (fileGenre === 'image') {
    return isExtensionSupported(fileGenre, fileExt);
  } else if (fileGenre === 'video') {
    return isExtensionSupported(fileGenre, fileExt);
  } else if (fileGenre === 'audio') {
    return isExtensionSupported(fileGenre, fileExt);
  } else if (fileGenre === 'doc') {
    return isExtensionSupported(fileGenre, fileExt);
  }
  return false;
};

export const validateLocalVideoFileRendability = (file) => {
  const genre = file.type.split('/')[0];
  const ext = getExtFromFileName(file.name);
  return validateFileTypeRendability(genre, ext);
};
