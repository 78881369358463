export const filterListByFileType = (list, showTypes) => {
  let modifiedList = list;
  showTypes.forEach((typeObj) => {
    let tempList = [];
    if (typeObj.filterStatus) {
      if (typeObj.label === 'folder' || typeObj.label === 'project') {
        tempList = modifiedList.filter((item) => {
          if (item.itemtype) {
            if (typeObj.label !== item.itemtype) {
              return true;
            }
            return false;
          }
          return true;
        });
      } else {
        tempList = modifiedList.filter((item) => {
          if (item.type) {
            if (typeObj.label !== item.type) {
              return true;
            }
            return false;
          }
          return true;
        });
      }
      modifiedList = [...tempList];
    }
  });
  return modifiedList;
};
