import React from 'react';
import PropTypes from 'prop-types';
import classes from './NoMatchView.module.scss';
import ExperimentalButton from '../../../components/buttons/experimentalButton/experimentalButton';
import { historyShape } from '../../../routerPropTypes';
import { getActiveWorkspace, getIsRootWorkspace } from '../../AppUtilityFunctions';
import { groupShape } from '../../AppPropTypes';

class NoMatchView extends React.Component {
  handleButtonClick = () => {
    const activeWorkspace = getActiveWorkspace(this.props.groupsList, this.props.currentUserGroup);
    const isRootWorkspace = getIsRootWorkspace(activeWorkspace);
    if (isRootWorkspace) {
      this.props.history.push('/in/account/workspace');
    } else {
      this.props.history.push('/in/folder/root');
    }
  };

  render = () => (
    <div className={classes.wrapper}>
      <h2 className={classes.headline}>Could not find page!</h2>
      <ExperimentalButton title="return home" type="primary" onClick={this.handleButtonClick}>
        Return home
      </ExperimentalButton>
    </div>
  );
}

export default NoMatchView;

NoMatchView.propTypes = {
  // router props
  history: PropTypes.shape(historyShape).isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
  currentUserGroup: PropTypes.string.isRequired,
};
