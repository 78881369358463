import React from 'react';
import PropTypes from 'prop-types';
import classes from './NoAccessView.module.scss';
import { historyShape } from '../../../routerPropTypes';
import ExperimentalButton from '../../../components/buttons/experimentalButton/experimentalButton';

class NoAccessView extends React.Component {
  handleButtonClick = () => {
    this.props.history.push('/in/account/profile');
  };

  render = () => (
    <div className={classes.wrapper}>
      <h2 className={classes.headline}>Can&apos;t access page!</h2>
      <ExperimentalButton
        onClick={this.handleButtonClick}
        title="Go to settings"
        isDisabled={false}
        type="primary"
      >
        Go to settings
      </ExperimentalButton>
    </div>
  );
}

export default NoAccessView;

NoAccessView.propTypes = {
  // router props
  history: PropTypes.shape(historyShape).isRequired,
};
