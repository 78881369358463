import React from 'react';
import styles from './ProjectChatMessages.module.scss';
import Message from '../../projectMainViewWorkSection/projectMainViewComponents/message/Message';
import ProjectChatMessagesLoading from './projectChatMessagesLoading/ProjectChatMessagesLoading';

const ProjectChatMessages = ({
  messages,
  isVisible,
  updateModalOpen,
  projectId,
  uid,
  userRole,
}) => {
  return (
    <ul className={`${styles.container} ${isVisible ? '' : styles.hidden}`}>
      {!!messages ? (
        messages.map((i) => (
          <Message
            key={i.created}
            firstName={i.userData?.given_name}
            lastName={i.userData?.family_name}
            created={i.created}
            content={i.content}
            image={{ task: false, imageUrl: i.userData?.img }}
            isAReply={false}
            messageId={i.id}
            projectId={projectId}
            isRemovable={
              (userRole === 'admin' || uid === i.sender) && userRole !== 'guest' && !!i.id
            }
            updateModalOpen={updateModalOpen}
          />
        ))
      ) : (
        <ProjectChatMessagesLoading amount={5} />
      )}
    </ul>
  );
  // render = () => {
  //   switch (this.props.getProjectMessagesProcess.status) {
  //     case 'initial':
  //       return <ProjectChatMessagesLoading amount={5} />;
  //     case 'loading': {
  //       if (this.props.getProjectMessagesProcess.siteLoaded) {
  //         return this.renderList(this.props.getProjectMessagesProcess.prevData);
  //       } else {
  //         return <ProjectChatMessagesLoading amount={5} />;
  //       }
  //     }
  //     case 'success': {
  //       return this.renderList(this.props.getProjectMessagesProcess.data);
  //     }
  //     // case 'error': {
  //     //   const content = (
  //     //     <div className={classes.errorWrapper}>
  //     //       <h5>Could not get messages</h5>
  //     //       <ExperimentalButton
  //     //         type="secondary"
  //     //         title="try again"
  //     //         onClick={() => this.props.updateMessages(false, true)}
  //     //       >
  //     //         Try again
  //     //       </ExperimentalButton>
  //     //     </div>
  //     //   );
  //     //   return content;
  //     // }
  //     default:
  //       return <div />;
};

export default ProjectChatMessages;
