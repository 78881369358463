import React from 'react';
import PropTypes from 'prop-types';
import ExperimentalButton from '../../components/buttons/experimentalButton/experimentalButton';
import './ErrorBoundary.scss';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary-wrapper">
          <h5 style={{ fontSize: '5rem', fontWeight: '900' }}>!</h5>
          <div className="headlines-wrapper">
            <h1 className="headline">Ooops!</h1>
            <h3 className="secondary-headline">Something went wrong. Please refresh.</h3>
          </div>
          <ExperimentalButton
            onClick={() => window.location.reload()}
            title="reload"
            animated={false}
            loading={false}
            isDisabled={false}
          >
            Refresh
          </ExperimentalButton>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
