import React from 'react';
import PropTypes from 'prop-types';

const ReplyIcon = (props) => {
  const { height } = props;
  return (
    <svg
      height={height}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 511.6 511.6"
      enableBackground="new 0 0 511.6 511.6"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M15.1,277.8C46,354.5,129.2,392.8,265,392.8h64v73.1c0,5,1.8,9.2,5.4,12.9c3.6,3.6,7.9,5.4,12.8,5.4
		c5,0,9.2-1.8,12.9-5.4l146.2-146.2c3.6-3.6,5.4-7.9,5.4-12.9c0-4.9-1.8-9.2-5.4-12.8L360,160.7c-3.6-3.6-7.9-5.4-12.9-5.4
		c-4.9,0-9.2,1.8-12.8,5.4c-3.6,3.6-5.4,7.9-5.4,12.8v73.1h-64c-18.6,0-35.3-0.6-50.1-1.7c-14.7-1.1-29.4-3.2-44-6.1
		c-14.6-3-27.2-7-38-12.1c-10.8-5.1-20.8-11.8-30.1-19.8c-9.3-8.1-16.9-17.7-22.8-28.8c-5.9-11.1-10.5-24.3-13.8-39.5
		c-3.3-15.2-5-32.5-5-51.7c0-10.5,0.5-22.2,1.4-35.1c0-1.1,0.2-3.4,0.7-6.7c0.5-3.3,0.7-5.9,0.7-7.6c0-2.9-0.8-5.2-2.4-7.1
		c-1.6-1.9-3.9-2.9-6.7-2.9c-3,0-5.7,1.6-8,4.9c-1.3,1.7-2.6,3.8-3.7,6.3c-1.1,2.5-2.4,5.3-3.9,8.6c-1.4,3.2-2.4,5.5-3,6.8
    C12.1,108.2,0,151.1,0,182.7C0,220.6,5,252.3,15.1,277.8z"
        />
      </g>
    </svg>
  );
};

export default ReplyIcon;

ReplyIcon.propTypes = {
  height: PropTypes.string,
};

ReplyIcon.defaultProps = {
  height: '0',
};
