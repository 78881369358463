import PropTypes from 'prop-types';
import { statusOptions } from '../../AppPropTypes';

const invitationInfoShape = {
  created: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  email: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  expires: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  group: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  id: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  resource: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
  role: PropTypes.shape({
    S: PropTypes.string.isRequired,
  }),
};

export const getInvitedUserEmailProcessShape = {
  status: PropTypes.oneOf(statusOptions).isRequired,
  data: PropTypes.shape(invitationInfoShape).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
};
