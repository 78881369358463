export const colorGreenPrimary = '#45cdaa';
export const colorGreenPrimaryDark = '#277561';
export const colorGreenSecondary = '#57bc72';
export const colorGreenMedium = '#1eba92';
export const colorTurquoisePrimary = '#00e1a1';
export const colorYellowPrimary = '#ffb300';
export const colorYellowSecondary = '#fbf85a';

export const colorBluePrimary = '#52c8da';
export const colorBlueSecondary = '#3c8cde';

export const colorRedPrimary = '#dc304d';
export const colorRedSecondary = '#e32d4b';

export const colorPinkPrimary = '#fa5d89';
export const colorPinkSecondary = '#e383a8';

export const colorDenimPrimary = '#233c4d';
export const colorDenimSecondary = '#87949c';

export const colorWhitePrimary = '#fff';
export const colorWhiteSecondary = '#fcfcfc';

export const colorBlackPrimary = '#000';

export const colorGreyPrimary = '#dfe1e2';
export const colorGreySecondary = '#f2f2f2';
export const colorGreyMedium = '#f2f5f4';
export const colorGreyDark = '#808080';

export const colorBackgroundPrimary = '#fff';
export const colorBackgroundSecondary = '#f8fbfa';
export const colorBackgroundMedium = '#f1f4f3';

/* breakpoints */

export const breakpointLarge = '1200px';
export const breakpointMedium = '900px';
export const breakpointSmall = '750px';
export const breakpointMin = '600px';

/* spacing */

export const spacingFixedButtons = '3rem';
export const spacingSectionHorizontal = '1.5rem';
export const spacingSectionVertical = '1rem';

/* Border-radius */

export const borderRadiusButton = '1.875rem';
export const borderRadiusTextInput = '1rem';
export const borderRadiusDefault = '0.5rem';

/* max-widths */

export const mainContentMaxWidth = '1400px';
