import React from 'react';
import PropTypes from 'prop-types';
import classes from './MiniatureFolderSystemFolderList.module.css';
import MiniatureFolderSystemFolderListItem from './miniatureFolderSystemFolderListItem/MiniatureFolderSystemFolderListItem';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';

const MiniatureFolderSystemFolderList = (props) => {
  const listItems = props.currentFolderNonResourceContent.map((item) => {
    const isUnavailable = props.selectedItemsContainsNonFileItem && item.itemtype === 'project';
    const containsProjectsOrFolders =
      (item.resources && item.resources.folder > 0) ||
      (item.resources && item.resources.project > 0);
    const selected = props.selectedItem && props.selectedItem.id === item.id;
    return (
      <MiniatureFolderSystemFolderListItem
        key={item.id}
        item={item}
        isUnavailable={isUnavailable}
        containsProjectsOrFolders={containsProjectsOrFolders}
        updateFolderById={props.updateFolderById}
        updateSelectedItem={props.updateSelectedItem}
        selected={selected}
      />
    );
  });
  const listStyles = listItems.length >= 6 ? classes.overFlowAdjustedList : classes.list;
  return <ul className={listStyles}>{listItems}</ul>;
};

export default MiniatureFolderSystemFolderList;

MiniatureFolderSystemFolderList.propTypes = {
  // CopyItemModal.jsx props
  selectedItemsContainsNonFileItem: PropTypes.bool.isRequired,
  selectedItem: PropTypes.shape(folderListItemShape),

  // MiniatureFolderSystem.jsx props
  updateSelectedItem: PropTypes.func.isRequired,
  currentFolderNonResourceContent: PropTypes.arrayOf(PropTypes.shape(folderListItemShape))
    .isRequired,
  updateFolderById: PropTypes.func.isRequired,
};

MiniatureFolderSystemFolderList.defaultProps = {
  selectedItem: undefined,
};
