import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { validateEmailFormat } from '../../../../util/UtilFunctions';
import { signUp } from '../../../../apiServices/authApi';
import { getInvitedUserEmailProcessShape } from '../SignupPropTypes';
import NotificationContext from '../../../../context/notification';
import TextInput from '../../../../components/inputs/textInput';
import ExperimentalButton from '../../../../components/buttons/experimentalButton/experimentalButton';
import './InformationForm.scss';
import { historyShape } from '../../../../routerPropTypes';

class InformationForm extends React.Component {
  state = {
    // some of the fields might be received from an endpoint,
    // a user might be invited to join a particular workspace
    email: { prefilled: false, value: '' },
    confirmEmail: { prefilled: false, value: '' },
    companyName: { prefilled: false, value: '' },
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    termsAgreed: false,
    privacyAgreed: false,
    infoFormProcess: { status: 'inactive' },
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.getInvitedUserEmailProcess.status === 'success' &&
      prevProps.getInvitedUserEmailProcess.status === 'loading'
    ) {
      this.setState({
        email: { prefilled: true, value: this.props.getInvitedUserEmailProcess.data.email.S },
        confirmEmail: {
          prefilled: true,
          value: this.props.getInvitedUserEmailProcess.data.email.S,
        },
        companyName: {
          prefilled: true,
          value: this.props.getInvitedUserEmailProcess.data.groupTitle.S,
        },
      });
    }
  };

  toggleTermsAgreed = () => {
    const { termsAgreed } = this.state;
    this.setState({ termsAgreed: !termsAgreed });
  };

  togglePrivacyAgreed = () => {
    const { privacyAgreed } = this.state;
    this.setState({ privacyAgreed: !privacyAgreed });
  };

  handleEmailFieldValueChange = (event) => {
    this.setState({ email: { prefilled: false, value: event.target.value } });
  };

  handleConfirmEmailFieldValueChange = (event) => {
    this.setState({ confirmEmail: { prefilled: false, value: event.target.value } });
  };

  handlePasswordFieldValueChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleFirstNameFieldValueChange = (event) => {
    this.setState({ firstName: event.target.value });
  };

  handleLastNameFieldValueChange = (event) => {
    this.setState({ lastName: event.target.value });
  };

  handleCompanyNameFieldValueChange = (event) => {
    this.setState({ companyName: { prefilled: false, value: event.target.value } });
  };

  handleConfirmPasswordFieldValueChange = (event) => {
    this.setState({ confirmPassword: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.validateFormLength()) {
      if (this.state.termsAgreed && this.state.privacyAgreed) {
        if (this.validatePasswords()) {
          if (this.validateEmails()) {
            const signUpParams = {
              username: this.state.email.value.toLowerCase(),
              password: this.state.password,
              attributes: {
                given_name: this.state.firstName,
                family_name: this.state.lastName,
              },
            };
            if (validateEmailFormat(signUpParams.username)) {
              this.setState({
                infoFormProcess: { status: 'loading', data: undefined, error: undefined },
              });
              try {
                const signUpResponse = await signUp(signUpParams);
                this.putUserSubIntoLocalStorage(signUpResponse.userSub);
                window.localStorage.setItem(
                  'confirmFormProcess',
                  JSON.stringify({
                    status: 'initial',
                    email: signUpParams.username,
                    password: this.state.password,
                    companyName: this.state.companyName.value,
                    userSub: signUpResponse.userSub,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    invitationId:
                      this.props.getInvitedUserEmailProcess.status === 'success'
                        ? this.props.getInvitedUserEmailProcess.data.id.S
                        : '',
                  }),
                );
                this.props.history.push('/signup/confirm');
                this.setState({ infoFormProcess: { status: 'success', data: signUpResponse } });
                this.context.add({
                  id: Date.now(),
                  type: 'success',
                  message: `Code sent to ${this.state.email.value.toLowerCase()}`,
                });
              } catch (signUpError) {
                this.context.add({
                  id: Date.now(),
                  type: 'error',
                  message: 'Could not register user',
                });
                this.setState({
                  infoFormProcess: { status: 'error', data: undefined, error: signUpError },
                });
              }
            } else {
              this.context.add({
                id: Date.now(),
                type: 'info',
                message: 'Given text is not an email address',
              });
            }
          } else {
            this.context.add({
              id: Date.now(),
              type: 'info',
              message: 'Emails do not match',
            });
          }
        } else {
          this.context.add({
            id: Date.now(),
            type: 'info',
            message: 'Passwords do not match',
          });
        }
      } else {
        this.context.add({
          id: Date.now(),
          type: 'info',
          message:
            'Terms of service and privacy statement has to be accepted in order to use yemma',
        });
      }
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Form is not complete',
      });
    }
  };

  validateFormLength = () => {
    if (
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.companyName.value.length > 0 &&
      this.state.email.value.length > 0 &&
      this.state.confirmEmail.value.length > 0 &&
      this.state.password.length > 0 &&
      this.state.confirmPassword.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  validatePasswords = () => this.state.password === this.state.confirmPassword;

  validateEmails = () =>
    this.state.email.value.toLowerCase() === this.state.confirmEmail.value.toLowerCase();

  putUserSubIntoLocalStorage = (userSub) => {
    const userSubArr = window.localStorage.getItem('userSub');
    const parsedArr = JSON.parse(userSubArr);
    const newItem = { email: this.state.email.value.toLowerCase(), sub: userSub };
    if (parsedArr) {
      const newArr = [...parsedArr, newItem];
      window.localStorage.setItem('userSub', JSON.stringify(newArr));
    } else {
      window.localStorage.setItem('userSub', JSON.stringify([newItem]));
    }
  };

  getinfoFormButtonStyles = (infoFormProcessStatus) => {
    if (infoFormProcessStatus === 'inactive' && this.validateFormLength()) {
      return 'active';
    } else {
      return infoFormProcessStatus;
    }
  };

  static contextType = NotificationContext;

  render = () => {
    const infoFormButtonStyles = this.getinfoFormButtonStyles();
    return (
      <div className="contentWrapper">
        <form onSubmit={this.handleSubmit}>
          <div className="inputsWrapper">
            <div className="inputWrapper">
              <TextInput
                type="text"
                value={this.state.email.value}
                onChange={this.handleEmailFieldValueChange}
                placeholder=" "
                id="email"
                autoFocus={!this.state.email.prefilled}
                disabled={this.state.email.prefilled}
              />
              <label htmlFor="email">Email</label>
            </div>
            {!this.state.email.prefilled && (
              <div className="inputWrapper">
                <TextInput
                  type="text"
                  value={this.state.confirmEmail.value}
                  onChange={this.handleConfirmEmailFieldValueChange}
                  placeholder=" "
                  id="emailConfirm"
                />
                <label htmlFor="confirmEmail">Confirm email</label>
              </div>
            )}
            <div className="inputWrapper">
              <TextInput
                type="text"
                value={this.state.companyName.value}
                onChange={this.handleCompanyNameFieldValueChange}
                placeholder=" "
                id="company"
                autoFocus
                disabled={this.state.companyName.prefilled}
              />
              <label htmlFor="company">Company</label>
            </div>
            <div className="inputWrapper">
              <TextInput
                type="text"
                value={this.state.firstName}
                onChange={this.handleFirstNameFieldValueChange}
                placeholder=" "
                id="fname"
                autoFocus
              />
              <label htmlFor="fname">First name</label>
            </div>
            <div className="inputWrapper">
              <TextInput
                type="text"
                value={this.state.lastName}
                onChange={this.handleLastNameFieldValueChange}
                placeholder=" "
                id="lname"
                autoFocus
              />
              <label htmlFor="lname">Surname</label>
            </div>
            <div className="inputWrapper">
              <TextInput
                type="password"
                value={this.state.password}
                onChange={this.handlePasswordFieldValueChange}
                placeholder=" "
                id="password1"
                autoComplete="new-password"
              />
              <label htmlFor="password1">Password</label>
            </div>
            <div className="inputWrapper">
              <TextInput
                type="password"
                value={this.state.confirmPassword}
                onChange={this.handleConfirmPasswordFieldValueChange}
                placeholder=" "
                id="password2"
                autoComplete="new-password"
              />
              <label htmlFor="password2">Confirm password</label>
            </div>
            <div className="termsWrapper">
              <input
                type="checkbox"
                checked={this.state.termsAgreed}
                onChange={this.toggleTermsAgreed}
              />
              <h5 className="text">
                I accept the
                <a
                  className="terms-link"
                  target="_blank"
                  href="https://theyemma.com/termsOfService/index.html"
                  title="https://theyemma.com/termsOfService/index.html"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
              </h5>
            </div>
            <div className="termsWrapper">
              <input
                type="checkbox"
                checked={this.state.privacyAgreed}
                onChange={this.togglePrivacyAgreed}
              />
              <h5 className="text">
                I accept the
                <a
                  className="terms-link"
                  target="_blank"
                  href="https://theyemma.com/privacyStatement/index.html"
                  title="https://theyemma.com/privacyStatement/index.html"
                  rel="noreferrer"
                >
                  Privacy Statement
                </a>
              </h5>
            </div>
            <div className="buttonWrapper">
              <ExperimentalButton
                htmlType="submit"
                loading={this.state.infoFormProcess.status === 'loading'}
                isDisabled={infoFormButtonStyles === 'inactive'}
              >
                {infoFormButtonStyles === 'error' ? 'Try again' : 'Register'}
              </ExperimentalButton>
            </div>
          </div>
        </form>
        <Link to="/login" className="link">
          Already have an account? Login here!
        </Link>
      </div>
    );
  };
}

export default InformationForm;

InformationForm.propTypes = {
  // router props
  history: PropTypes.shape(historyShape).isRequired,

  // Signup.jsx props
  getInvitedUserEmailProcess: PropTypes.shape(getInvitedUserEmailProcessShape).isRequired,
};
