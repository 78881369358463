export const getSnapshotBlob = (file, videoDetailsList) => {
  if (!file.item.type !== 'folder') {
    if (file.item.type.split('/')[0] === 'video') {
      return videoDetailsList.find((videoDetailItem) => videoDetailItem.name === file.item.name);
    } else if (file.item.type === 'application/pdf') {
      return { thumbnail: file.details };
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getIsFinished = (file, finishedUploadsList) => {
  if (file.item.type !== 'folder') {
    return finishedUploadsList.find((item) => item.name === file.item.name) !== undefined;
  } else {
    return finishedUploadsList.find((item) => item.id === file.item.id) !== undefined;
  }
};
