import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import { historyShape } from '../../../routerPropTypes';
import { borderRadiusDefault } from '../../../styles/variables';

const PackageExpiredModal = (props) => {
  const handleRelocate = () => {
    props.history.push('/in/account/billing');
  };
  return (
    <Wrapper>
      <h4>Subscription package has expired</h4>
      <ContentWrapper>
        <ExperimentalButton onClick={handleRelocate} title="delete files" type="primary">
          Go to billing
        </ExperimentalButton>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PackageExpiredModal;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: ${borderRadiusDefault};
  height: 100%;
  margin: auto;
  h4 {
    text-align: center;
    font-size: 1.3em;
    margin: 0 0 1rem 0;
  }

  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

PackageExpiredModal.propTypes = {
  history: PropTypes.shape(historyShape).isRequired,
};
