import PropTypes from 'prop-types';
import { statusOptions } from '../../../app/AppPropTypes';

export const outsideResourceIdsProcessShape = {
  status: PropTypes.oneOf(statusOptions).isRequired,
  data: PropTypes.string,
  error: PropTypes.object,
};

export const modalParamsItemShape = {
  selectedFiles: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, url: PropTypes.string }),
  ),
};
