import React from 'react';
import PropTypes from 'prop-types';
import classes from './PreviewListLoading.module.css';

const PreviewListLoading = (props) => {
  const listItems = [];
  for (let i = 0; i < props.amount; i++) {
    const loadingItem = <li className={classes.listItemWrapper} key={i} />;
    listItems.push(loadingItem);
  }
  return <ul className={classes.list}>{listItems}</ul>;
};

export default PreviewListLoading;

PreviewListLoading.propTypes = {
  amount: PropTypes.number.isRequired,
};
