import React from 'react';
import './ProjectParticipiantsModalPeopleListItem.scss';
import ProfileBall from '../../../../profileBall/ProfileBall';
import CrossIcon from '../../../../icons/CrossIcon';
import RoleTablet from '../../../../common/roleTablet/roleTablet';
import ExperimentalButton from '../../../../buttons/experimentalButton/experimentalButton';

const ProjectParticipiantsModalPeopleListItem = ({
  person,
  currentUserIsAdminOrPro,
  removeUserFromProject,
  deleting,
}) => {
  const userIsRemovableFromProject = !(person.role === 'admin' || person.role === 'pro');

  return (
    <li className="projectParticipiantsModalPeopleListItem">
      <div className="avatarWrapper">
        <ProfileBall
          imageUrl={person.img}
          firstName={person.given_name}
          lastName={person.family_name}
        />
      </div>
      <div className="userInfoWrapper">
        <div className="userTitleField">
          <h5 className="userInfoName">
            {`${person.given_name} ${person.family_name}`}
            <RoleTablet role={person.role} />
          </h5>
        </div>
        <h5 className="userInfoEmail">{person.email}</h5>
      </div>
      {currentUserIsAdminOrPro && userIsRemovableFromProject && (
        <>
          <ExperimentalButton
            type="grey"
            isDisabled={deleting}
            onClick={() => removeUserFromProject(person)}
            title="Remove person from project"
          >
            <CrossIcon height="10px" fill="lightgray" />
          </ExperimentalButton>
          {/* {!deleting && (
                <div
                  className={classes.deleteButtonWrapper}
                  onClick={() => removeUserFromProject(person)}
                >
                  <CrossIcon height="15px" fill="lightgray" />
                </div>
              )}
              {deleting && (
                <div className={classes.deleteButtonWrapper}>
                  <LoadingDots fontSize={1} width={20} color="gray" />
                </div>
              )} */}
        </>
      )}
    </li>
  );
};

export default ProjectParticipiantsModalPeopleListItem;
