import { API } from 'aws-amplify';
import store from '../configureStore';
import { convertObjectBodyString } from './apiUtil';
import { config } from '../config/Config';

export const addVideoTask = ({
  parent,
  type,
  index,
  resource,
  message,
  coordX,
  coordY,
  timestamp,
  snapshotThumbnailStorage,
  taskType,
  duration,
  coords,
}) => {
  const {
    project: { header },
    user: { identityId },
    workspace: { currentUserGroup },
  } = store.getState();
  const snapshotResourceS3Path = `private/${identityId}/${snapshotThumbnailStorage}`;
  const addVideoTaskInit = {
    headers: header,
    body: `parent=${parent}&type=${type}&index=${index}&resource=${resource}&message=${message}&coord_x=${coordX}&coord_y=${coordY}&timestamp=${timestamp}&preview=${snapshotResourceS3Path}&taskType=${taskType}&duration=${
      duration || null
    }&coords=${coords ? JSON.stringify(coords) : null}`,
  };

  return API.post(config.apiGateway.NAME, `/task/add?gid=${currentUserGroup}`, addVideoTaskInit);
};

export const editVideoTask = ({ id, duration, status, tasktype }) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  let body = `id=${id}`;
  if (duration) body += `&duration=${duration}`;
  if (status) body += `&status=${status}`;
  if (tasktype) body += `&taskType=${tasktype}`;

  const updateVideoTaskInit = {
    headers: header,
    body,
  };

  return API.post(
    config.apiGateway.NAME,
    `/task/update?gid=${currentUserGroup}`,
    updateVideoTaskInit,
  );
};

export const addTask = (data) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const { coords, ...rest } = data;

  let body = convertObjectBodyString(rest);
  body = `${body}&coords=${coords ? JSON.stringify(coords) : null}`;

  const addTaskInit = {
    headers: header,
    body,
  };
  return API.post(config.apiGateway.NAME, `/task/add?gid=${currentUserGroup}`, addTaskInit);
};

export const addAudioTask = (parent, type, index, resource, message, timestamp) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const addAudioTaskInit = {
    headers: header,
    body: `parent=${parent}&type=${type}&index=${index}&resource=${resource}&message=${message}&timestamp=${timestamp}`,
  };
  return API.post(config.apiGateway.NAME, `/task/add?gid=${currentUserGroup}`, addAudioTaskInit);
};

export const getTasks = (projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const addTaskInit = {
    headers: header,
  };
  return API.get(
    config.apiGateway.NAME,
    `/task?gid=${currentUserGroup}&parent=${projectId}`,
    addTaskInit,
  );
};

export const getProjectTasks = (projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const addTaskInit = {
    headers: header,
  };
  return API.get(
    config.apiGateway.NAME,
    `/task/all/${projectId}?gid=${currentUserGroup}`,
    addTaskInit,
  );
};

export const addMessageToTask = (taskId, message) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const addTaskInit = {
    headers: header,
    body: `id=${taskId}&message=${message}`,
  };
  return API.post(config.apiGateway.NAME, `/task/message?gid=${currentUserGroup}`, addTaskInit);
};

export const deleteTask = (taskId, projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const deleteTaskInit = {
    headers: header,
    body: `id=${taskId}&project=${projectId}`,
  };
  return API.post(config.apiGateway.NAME, `/task/delete?gid=${currentUserGroup}`, deleteTaskInit);
};
