import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import NotificationContext from '../../../context/notification';
import { borderRadiusDefault } from '../../../styles/variables';

export const ConfirmModal = (props) => {
  const [executeActionProcess, setExecuteActionProcess] = useState({
    status: 'initial',
    data: undefined,
    error: undefined,
  });

  const { add } = React.useContext(NotificationContext);

  const handleConfirmedAction = async () => {
    try {
      setExecuteActionProcess({ status: 'loading', data: undefined, error: undefined });
      props.updateIsQuerying(true);
      const confirmedActionResponse = await props.executeAction();
      props.updateIsQuerying(false);
      setExecuteActionProcess({
        status: 'success',
        data: confirmedActionResponse,
        error: undefined,
      });
      add({
        id: Date.now(),
        message: props.successText,
        type: 'success',
      });
      props.closeModal();
      if (props.refreshList) {
        props.refreshList();
      }
    } catch (confirmedActionError) {
      props.updateIsQuerying(false);
      setExecuteActionProcess({ status: 'error', data: undefined, error: confirmedActionError });
      add({
        id: Date.now(),
        message: props.errorText,
        type: 'error',
      });
    }
  };

  return (
    <Wrapper>
      <h4>{props.headerText}</h4>
      <ContentWrapper>
        <ExperimentalButton
          onClick={handleConfirmedAction}
          title="confirm action"
          type="primary"
          loading={executeActionProcess.status === 'loading'}
        >
          Confirm
        </ExperimentalButton>
      </ContentWrapper>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: ${borderRadiusDefault};
  height: 100%;
  margin: auto;

  h4 {
    text-align: center;
    font-size: 1.3em;
    margin: 0 0 1rem 0;
  }
  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

ConfirmModal.propTypes = {
  refreshList: PropTypes.func,
  updateIsQuerying: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  executeAction: PropTypes.func,
  headerText: PropTypes.string.isRequired,
  successText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
};

ConfirmModal.defaultProps = {
  executeAction: undefined,
  refreshList: undefined,
};
