import React from 'react';
import PropTypes from 'prop-types';
import './dot.scss';
import { taskShape } from '../../../ProjectMainViewPropTypes';
import { getXYPercent } from '../../projectMainViewImage/ProjectMainViewImageUtilityFunctions';

class Dot extends React.Component {
  state = {
    dotStyles: {
      top: 0,
      left: 0,
      opacity: this.props.opacity,
    },
  };

  componentDidMount = () => {
    this.setState({ dotStyles: this.getDotStyles() });
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.fullscreen && this.props.fullscreen) {
      this.setState({ dotStyles: this.getDotStyles() });
    }
  };

  getDotStyles = () => {
    const dotStyles = {
      top: 0,
      left: 0,
      opacity: this.props.opacity,
    };
    if (this.props.type === 'image') {
      const relativeCoord = getXYPercent(
        this.props.coord_x,
        this.props.coord_y,
        'project-mainview-fullscreen-img',
      );
      if (relativeCoord) {
        return {
          ...dotStyles,
          top: `${relativeCoord.y}%`,
          left: `${relativeCoord.x}%`,
        };
      } else {
        return {
          ...dotStyles,
          top: 0,
          left: 0,
        };
      }
    } else if (this.props.type === 'video' || this.props.type === 'audio') {
      const changeDate = new Date('2021-01-13T09:59:55.671Z');
      const creationDate = new Date(this.props.created);
      if (this.props.newTask || creationDate < changeDate) {
        return {
          ...dotStyles,
          top: `${this.props.coord_y}px`,
          left: `${this.props.coord_x}px`,
        };
      } else {
        return {
          top: `${this.props.coord_y}%`,
          left: `${this.props.coord_x}%`,
        };
      }
    } else {
      return dotStyles;
    }
  };

  render = () => (
    <div className={`dot ${this.props.appearance}`} style={this.state.dotStyles}>
      <div
        className="taskDot"
        onClick={
          this.props.closeNewTask
            ? this.props.closeNewTask
            : () => this.props.updateSelectedTask(this.props.task ? this.props.task : undefined)
        }
        id="taskDot"
      >
        <h4>{this.props.index}</h4>
      </div>
    </div>
  );
}

export default Dot;

Dot.propTypes = {
  fullscreen: PropTypes.bool,

  // projectMainView.jsx
  updateSelectedTask: PropTypes.func,

  // TaskMessage.jsx props
  type: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  coord_x: PropTypes.number.isRequired,
  coord_y: PropTypes.number.isRequired,
  closeNewTask: PropTypes.func,
  opacity: PropTypes.number,
  task: PropTypes.shape(taskShape),
  newTask: PropTypes.bool.isRequired,
  created: PropTypes.string,
  appearance: PropTypes.string,
};

Dot.defaultProps = {
  opacity: 1,
  updateSelectedTask: undefined,
  closeNewTask: undefined,
  task: undefined,
  fullscreen: undefined,
  created: undefined,
  appearance: 'default',
};
