import React from 'react';
import styled from 'styled-components';
import FileUploadingModalList from './fileUploadingModalList/FileUploadingModalList';
import Dropzone from '../../buttons/dropzone/dropzone';
import { addResource, addResourceWithSnapshot } from '../../../apiServices/resourceApi';
import {
  putToStorageResource,
  putToStorageVideoSnapShot,
  putToStorageDefault,
} from '../../../apiServices/storageApiService';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import {
  processSelectedFiles,
  checkIfNoFilesToUpload,
  getMaxDepth,
  processFolderFiles,
  getUpdatedFilesList,
} from './FileUploadingModalUtilFunctions';
import NotificationContext from '../../../context/notification';
import { FinishedUploadsCounter } from './finishedUploadsCounter/FinishedUploadsCounter';
import { addProject } from '../../../apiServices/projectApi';
import { colorGreenPrimary } from '../../../styles/variables';

class FileUploadingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filesList: [],
      uploadingItemsList: [],
      finishedUploadsList: [],
      failedUploadsList: [],
      videoDetailsList: [],
      thumbnailGeneratingProcessOnGoing: false,
      pdfThumbnailGeneratingProcessOnGoing: false,
      fileUploadingProcess: { status: 'inactive' },
      progress: {},
    };
    this.modalWrapperNode = React.createRef();
    this.handleProgress = this.handleProgress.bind(this);
  }

  componentDidUpdate = (prevProps, prevState) => {
    this.checkPdfThumbnailGeneratingProcess(prevState.pdfThumbnailGeneratingProcessOnGoing);
  };

  checkPdfThumbnailGeneratingProcess = (previousPdfThumbnailGeneratingProcessOnGoing) => {
    const pdfs = this.state.filesList.filter(
      (fileItem) => fileItem.item.type.split('/')[1] === 'pdf',
    );
    const pdfDoesntHaveThumbnail = pdfs.find((pdf) => pdf.details === undefined) !== undefined;
    if (pdfDoesntHaveThumbnail && !previousPdfThumbnailGeneratingProcessOnGoing) {
      this.setState({ pdfThumbnailGeneratingProcessOnGoing: true });
    } else if (!pdfDoesntHaveThumbnail && previousPdfThumbnailGeneratingProcessOnGoing) {
      this.setState({ pdfThumbnailGeneratingProcessOnGoing: false });
    }
  };

  updateThumbnailGeneratingProcessOnGoing = (value) => {
    this.setState({ thumbnailGeneratingProcessOnGoing: value });
  };

  addToVideoDetailsList = (videoDetailsItem) => {
    const { videoDetailsList } = this.state;
    const newVideoDetailsList = [...videoDetailsList, videoDetailsItem];
    this.setState({ videoDetailsList: newVideoDetailsList });
  };

  updateFilesListItemDetails = (listItemDetails) => {
    const { filesList } = this.state;
    const updatedFilesList = getUpdatedFilesList(filesList, listItemDetails);
    this.setState({ filesList: updatedFilesList });
  };

  removeFromVideoDetailsList = (videoDetailsItem) => {
    const { videoDetailsList } = this.state;
    const newVideoDetailsList = videoDetailsList.filter(
      (item) => item.name !== videoDetailsItem.name,
    );
    this.setState({ videoDetailsList: newVideoDetailsList });
  };

  removeItemFromFilesList = (file) => {
    const { filesList } = this.state;
    const copiedFilesList = [...filesList];
    if (copiedFilesList.find((copiedItem) => copiedItem.item.name === file.name)) {
      const filteredList = copiedFilesList.filter((item) => item.item.name !== file.name);
      this.setState({ filesList: filteredList });
    }
  };

  addFileToUploadingItemsList = (file) => {
    this.setState((prevState) => ({ uploadingItemsList: [...prevState.uploadingItemsList, file] }));
  };

  removeFileFromUploadingItemsList = (file) => {
    const { uploadingItemsList } = this.state;
    const newUploadingItemsList = uploadingItemsList.filter((item) => item.name !== file.name);
    this.setState({ uploadingItemsList: newUploadingItemsList });
  };

  addFileToFinishedUploadsList = (file) => {
    const { finishedUploadsList } = this.state;
    const copiedFinishedUploadsList = [...finishedUploadsList];
    const newList = copiedFinishedUploadsList.concat(file);
    this.setState({ finishedUploadsList: newList });
  };

  addFileToFailedUploadsList = (file) => {
    const { failedUploadsList } = this.state;
    const copiedFailedUploadsList = [...failedUploadsList];
    const newList = copiedFailedUploadsList.concat(file);
    this.setState({ failedUploadsList: newList });
  };

  handleFileSelect = (event) => {
    const { filesList, finishedUploadsList } = this.state;
    const someFileHasPath = [...event.target.files].some((file) => file.webkitRelativePath !== '');
    if (!someFileHasPath) {
      const processedList = processSelectedFiles(
        event.target.files,
        filesList,
        finishedUploadsList,
        this.props.content,
        this.context.add,
      );
      this.setState({
        filesList: processedList,
        fileUploadingProcess: { status: 'active' },
      });
    } else {
      const depth = getMaxDepth(event.target.files);
      if (depth > 2) {
        this.context.add({
          id: Date.now(),
          message: 'Folder structure is too deep',
          type: 'info',
        });
      } else {
        const processedList = processFolderFiles(event.target.files, this.state.filesList);
        this.setState({
          filesList: processedList,
          fileUploadingProcess: { status: 'active' },
        });
      }
    }
  };

  fileInputOnclick = (event) => {
    // eslint-disable-next-line
    event.target.value = null;
  };

  uploadFiles = (list, parent) => {
    const uploadingFilesPromisesList = [];
    list.forEach(async (file) => {
      if (file.item.type !== 'folder') {
        const fileExistsInFinishedUploadsList =
          this.state.finishedUploadsList.find((item) => item.name === file.item.name) !== undefined;
        if (!fileExistsInFinishedUploadsList) {
          const addResourcePromise = new Promise((resolve, reject) => {
            //this.setState({ fileUploadingProcess: { status: 'loading' } });
            this.addFileToUploadingItemsList(file.item);
            this.props.updateIsQuerying(true);

            // pass cb to listen progress, use file.item.name to identify correct file
            const progressCallback = (progress) => this.handleProgress(progress, file.item.name);
            putToStorageResource(file.item, progressCallback)
              .then((storageResponse) => {
                if (file.item.type.split('/')[0] === 'video') {
                  this.generateVideoFileUploadingPromise(
                    file.item,
                    parent,
                    storageResponse.key,
                    resolve,
                    reject,
                  );
                } else if (file.item.type === 'application/pdf') {
                  this.generatePdfFileUploadingPromise(
                    file,
                    parent,
                    storageResponse.key,
                    resolve,
                    reject,
                  );
                } else {
                  const addResourceParams = [storageResponse.key, parent, file.item.name];
                  this.generateAddResourcePromise(addResourceParams, file.item, resolve, reject);
                }
                this.props.updateIsQuerying(false);
              })
              .catch(() => {
                this.removeFileFromUploadingItemsList(file.item);
                this.addFileToFailedUploadsList(file.item);
                this.props.updateIsQuerying(false);
              });
          });
          uploadingFilesPromisesList.push(addResourcePromise);
        }
      } else {
        const addFolderPromise = new Promise((resolve, reject) => {
          this.setState({ fileUploadingProcess: { status: 'loading' } });
          this.addFileToUploadingItemsList(file.item);
          this.generateUploadFolderPromise(file, parent, resolve, reject);
        });
        uploadingFilesPromisesList.push(addFolderPromise);
      }
    });

    Promise.all(uploadingFilesPromisesList)
      .then((res) => {
        if (this.props.useCase === 'project') {
          this.props.history.push(`/in/project/${parent}/allfiles`);
        }
        if (parent !== (this.props.currentItem?.id || this.props.currentItem)) {
          const folderItem = this.state.filesList.find((file) => file.item.id === parent);
          const someContentIsUnfinished = folderItem.item.content.some((file) => {
            const isFinished = this.state.finishedUploadsList.some(
              (finishedFile) => file.item.name === finishedFile.name,
            );
            return !isFinished;
          });
          if (!someContentIsUnfinished) {
            this.addFileToFinishedUploadsList(folderItem.item);
            this.removeFileFromUploadingItemsList(folderItem.item);

            this.context.add({
              id: Date.now(),
              type: 'success',
              message: 'Folder uploaded',
            });
          }
          this.props.refreshList && this.props.refreshList(undefined, 0, true);
          this.props.closeModal();
        } else {
          this.context.add({
            id: Date.now(),
            type: 'success',
            message: list > 0 ? 'Files uploaded' : 'File uploaded',
          });
          const performUntilThumbnailsAreShowing = {
            type: 'resource',
          };
          this.props.refreshList &&
            this.props.refreshList(performUntilThumbnailsAreShowing, 0, true);
          this.setState({ fileUploadingProcess: { status: 'success' } });
          this.props.closeModal();
        }
      })
      .catch((err) => {
        console.error(err);
        this.context.add({
          id: Date.now(),
          type: 'error',
          message:
            this.state.filesList > 0 ? 'Could not upload files' : 'Could not upload the file',
        });
        this.props.updateIsQuerying(false);
      });
  };

  handleProgress = (progress, id) => {
    const obj = { ...this.state.progress };
    obj[id] = Math.round((progress.loaded / progress.total) * 100);
    this.setState({ progress: obj });
  };

  updateFolderItemId = (folderName, id) => {
    const { filesList } = this.state;
    const folderItem = filesList.find((file) => file.item.name === folderName);
    const newFolderItem = {
      ...folderItem.item,
      id,
    };
    const filteredList = filesList.filter((file) => file.item.name !== folderName);
    const updatedList = [...filteredList, { item: newFolderItem }];
    this.setState({ filesList: updatedList });
  };

  generateUploadFolderPromise = async (folder, parent, resolve, reject) => {
    try {
      this.props.updateIsQuerying(true);
      const addProjectResponse = await addProject({
        title: folder.item.name,
        type: 'folder',
        parent,
      });
      this.updateFolderItemId(folder.item.name, addProjectResponse.response);
      this.props.updateIsQuerying(false);
      this.uploadFiles(folder.item.content, addProjectResponse.response);
      resolve(addProjectResponse);
    } catch (addFolderError) {
      this.props.updateIsQuerying(false);
      this.removeFileFromUploadingItemsList(folder.item);
      this.addFileToFailedUploadsList(folder.item);
      this.context.add({
        id: Date.now(),
        message: 'Could not add folder',
        type: 'error',
      });
      reject(addFolderError);
    }
  };

  generatePdfFileUploadingPromise = async (file, parent, storageResponseKey, resolve, reject) => {
    if (file.details) {
      try {
        this.props.updateIsQuerying(true);
        const progressCallback = (progress) => this.handleProgress(progress, file.item.name);
        const thumbnailAddingStorageResponse = await putToStorageDefault(
          file.details,
          progressCallback,
        );
        this.props.updateIsQuerying(false);
        const addResourceParams = [
          storageResponseKey,
          parent,
          file.item.name,
          thumbnailAddingStorageResponse.key,
        ];
        this.generateAddResourceWithSnapshotPromise(addResourceParams, file.item, resolve, reject);
      } catch (thumbnailAddingStorageResponseError) {
        this.props.updateIsQuerying(false);
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not perform action',
        });
      }
    } else {
      const addResourceParams = [storageResponseKey, parent, file.item.name];
      this.generateAddResourcePromise(addResourceParams, file, resolve, reject);
    }
  };

  generateVideoFileUploadingPromise = async (file, parent, storageResponseKey, resolve, reject) => {
    const videoDetailsItem = this.state.videoDetailsList.find(
      (videoDetail) => videoDetail.name === file.name,
    );
    if (videoDetailsItem && videoDetailsItem.thumbnailBlob) {
      try {
        this.props.updateIsQuerying(true);
        const thumbnailAddingStorageResponse = await putToStorageVideoSnapShot(
          videoDetailsItem.thumbnailBlob,
        );
        this.props.updateIsQuerying(false);
        const addResourceParams = [
          storageResponseKey,
          parent,
          file.name,
          thumbnailAddingStorageResponse.key,
        ];
        return this.generateAddResourceWithSnapshotPromise(
          addResourceParams,
          file,
          resolve,
          reject,
        );
      } catch (thumbnailAddingStorageResponseError) {
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not perform action',
        });
        this.props.updateIsQuerying(false);
      }
    } else {
      const addResourceParams = [storageResponseKey, parent, file.name];
      return this.generateAddResourcePromise(addResourceParams, file, resolve, reject);
    }
  };

  generateAddResourceWithSnapshotPromise = (addResourceParams, file, resolve, reject) => {
    this.props.updateIsQuerying(true);
    const addResourcePromise = addResourceWithSnapshot(...addResourceParams)
      .then((result) => {
        this.addFileToFinishedUploadsList(file);
        this.removeFileFromUploadingItemsList(file);
        this.props.updateIsQuerying(false);
        // Resolve with file info
        resolve(result.response.Item);
      })
      .catch((addResourceError) => {
        this.setState({
          fileUploadingProcess: { status: 'error', data: addResourceError },
        });
        this.removeFileFromUploadingItemsList(file);
        this.props.updateIsQuerying(false);
        reject(addResourceError);
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not add resource',
        });
      });
    return addResourcePromise;
  };

  generateAddResourcePromise = (addResourceParams, file, resolve, reject) => {
    this.props.updateIsQuerying(true);
    const addResourcePromise = addResource(...addResourceParams)
      .then((result) => {
        this.addFileToFinishedUploadsList(file);
        this.removeFileFromUploadingItemsList(file);
        this.props.updateIsQuerying(false);
        // Resolve with file information
        resolve(result.response.Item);
      })
      .catch((addResourceError) => {
        this.setState({
          fileUploadingProcess: { status: 'error', data: addResourceError },
        });
        this.removeFileFromUploadingItemsList(file);
        this.props.updateIsQuerying(false);
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: 'Could not add resource',
        });
        reject(addResourceError);
      });
    return addResourcePromise;
  };

  handleFileUpload = async () => {
    if (this.state.filesList.length > 0) {
      const noFilesToUpload = checkIfNoFilesToUpload(
        this.state.filesList,
        this.state.uploadingItemsList,
        this.state.finishedUploadsList,
      );
      if (!noFilesToUpload) {
        this.uploadFiles(
          this.state.filesList,
          this.props.currentItem?.id || this.props.currentItem, // ProjectView already refactored
        );
      } else {
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: 'Nothing to upload',
        });
      }
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Form is not complete',
      });
    }
  };

  static contextType = NotificationContext;

  render() {
    return (
      <Wrapper ref={this.modalWrapperNode}>
        <Headline>Upload files</Headline>
        <ContentWrapper>
          <InputsWrapper>
            <Dropzone
              handleChange={this.handleFileSelect}
              handleClick={this.fileInputOnclick}
              multiple
              useCase="file"
            />
            {this.props.useCase !== 'project' && (
              <InputWrapper>
                <input
                  type="file"
                  onChange={this.handleFileSelect}
                  onClick={this.fileInputOnclick}
                  multiple
                  style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    padding: 0,
                    cursor: 'pointer',
                  }}
                  webkitdirectory=""
                  directory=""
                />
                <h5 style={{ margin: 0, color: colorGreenPrimary }}>Upload folders</h5>
              </InputWrapper>
            )}
          </InputsWrapper>
          <FileUploadingModalList
            failedUploadsList={this.state.failedUploadsList}
            finishedUploadsList={this.state.finishedUploadsList}
            filesList={this.state.filesList}
            removeItemFromFilesList={this.removeItemFromFilesList}
            uploadingItemsList={this.state.uploadingItemsList}
            addToVideoDetailsList={this.addToVideoDetailsList}
            removeFromVideoDetailsList={this.removeFromVideoDetailsList}
            videoDetailsList={this.state.videoDetailsList}
            updateFilesListItemDetails={this.updateFilesListItemDetails}
            progress={this.state.progress}
            updateThumbnailGeneratingProcessOnGoing={this.updateThumbnailGeneratingProcessOnGoing}
          />
          <ExperimentalButton
            onClick={this.handleFileUpload}
            title="upload files"
            isDisabled={
              this.state.fileUploadingProcess.status !== 'active' ||
              this.state.thumbnailGeneratingProcessOnGoing ||
              this.state.pdfThumbnailGeneratingProcessOnGoing
            }
            type="primary"
          >
            {this.state.fileUploadingProcess.status === 'error' ? 'Try again' : 'Upload'}
          </ExperimentalButton>
          {this.state.filesList.length > 1 && (
            <FinishedUploadsCounter
              filesListLength={this.state.filesList.length}
              fileUploadingProcessStatus={this.state.fileUploadingProcess.status}
              finishedUploadsListLength={this.state.finishedUploadsList.length}
              failedUploadsListLength={this.state.failedUploadsList.length}
            />
          )}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  max-height: 85vh;
  overflow-y: auto;
  button.button {
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Headline = styled.h4`
  text-align: center;
  font-size: 1.3em;
  margin: 0 0 1rem 0;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputWrapper = styled.div`
  border: none;
  display: flex;
  justify-content: center;
  position: relative;
  input {
    margin: auto;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }
`;

export default FileUploadingModal;
