export const getVideoSnapshotFromStart = async (videoWrapperDomId) => {
  const videojsElement = document.getElementById(videoWrapperDomId);
  const videoDomElement = videojsElement.getElementsByTagName('video')[0];
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = videoDomElement.videoWidth;
  canvas.height = videoDomElement.videoHeight;

  context.drawImage(videoDomElement, 0, 0, canvas.width, canvas.height);
  const dataURL = canvas.toDataURL();
  return dataURL;
};

export const getPercent = (isFinished, progressPercent) => {
  if (isFinished) {
    return 100;
  } else {
    if (progressPercent) {
      return progressPercent;
    } else {
      return 0;
    }
  }
};
