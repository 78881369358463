import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classes from './ChangePasswordSection.module.scss';
import { changePassword, getCurrentAuthenticatedUser } from '../../../../../apiServices/authApi';
import ExperimentalButton from '../../../../../components/buttons/experimentalButton/experimentalButton';
import TextInput from '../../../../../components/inputs/textInput';
import NotificationContext from '../../../../../context/notification';
import { Header } from '../generalInfoSection/GeneralInfoSection';

class ChangePasswordSection extends React.Component {
  static contextType = NotificationContext;

  constructor() {
    super();
    this.state = {
      oldPasswordFieldValue: '',
      newPasswordFieldValue: '',
      confirmPasswordFieldValue: '',
      passWordChangingProcess: { status: 'initial' },
    };
  }

  handlePasswordSubmit = async (event) => {
    event.preventDefault();
    if (this.validateNewPasswordForm()) {
      this.setState({
        passWordChangingProcess: { status: 'loading' },
      });
      try {
        this.props.updateIsQuerying(true);
        const currentUserCognitoObject = await getCurrentAuthenticatedUser();
        this.props.updateIsQuerying(false);
        try {
          const changePasswordParams = [
            currentUserCognitoObject,
            this.state.oldPasswordFieldValue,
            this.state.newPasswordFieldValue,
          ];
          this.props.updateIsQuerying(true);
          await changePassword(...changePasswordParams);
          this.props.updateIsQuerying(false);
          this.setState({
            oldPasswordFieldValue: '',
            newPasswordFieldValue: '',
            confirmPasswordFieldValue: '',
            passWordChangingProcess: { status: 'success' },
          });
          this.context.add({
            id: Date.now(),
            type: 'success',
            message: 'Password changed',
          });
        } catch (changePasswordError) {
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not change password',
          });
          this.setState({
            passWordChangingProcess: { status: 'error', data: changePasswordError },
          });
          this.props.updateIsQuerying(false);
        }
      } catch (getCurrentAuthenticatedUserError) {
        this.props.updateIsQuerying(false);
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: 'Could not change password',
        });
      }
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Form is not complete',
      });
    }
  };

  handleOldPasswordFieldValueChange = (event) => {
    this.setState({ oldPasswordFieldValue: event.target.value });
  };

  handleNewPasswordFieldValueChange = (event) => {
    this.setState({ newPasswordFieldValue: event.target.value });
  };

  handleConfirmPasswordFieldValueChange = (event) => {
    this.setState({ confirmPasswordFieldValue: event.target.value });
  };

  validateNewPasswordForm = () =>
    this.state.oldPasswordFieldValue !== '' &&
    this.state.newPasswordFieldValue !== '' &&
    this.state.confirmPasswordFieldValue !== '';

  render = () => {
    const inputFieldStyles =
      this.state.passWordChangingProcess.status === 'success'
        ? classes.inputFieldSuccess
        : classes.inputField;

    return (
      <Wrapper>
        <Header>
          <h4>Change password</h4>
        </Header>
        <div className={classes.generalContentWrapper}>
          <form onSubmit={this.handlePasswordSubmit}>
            <Password>
              <TextInput
                type="password"
                value={this.state.oldPasswordFieldValue}
                onChange={this.handleOldPasswordFieldValueChange}
                className={inputFieldStyles}
                placeholder="Old password"
              />
            </Password>
            <Password>
              <TextInput
                type="password"
                value={this.state.newPasswordFieldValue}
                onChange={this.handleNewPasswordFieldValueChange}
                className={inputFieldStyles}
                placeholder="New password"
              />
            </Password>
            <Password>
              <TextInput
                type="password"
                value={this.state.confirmPasswordFieldValue}
                onChange={this.handleConfirmPasswordFieldValueChange}
                className={inputFieldStyles}
                placeholder="Confirm password"
              />
            </Password>
            <ButtonWrapper>
              <ExperimentalButton
                onClick={this.handlePasswordSubmit}
                htmlType="submit"
                loading={this.state.passWordChangingProcess.status === 'loading'}
                type="primary submit"
              >
                Change
              </ExperimentalButton>
            </ButtonWrapper>
          </form>
        </div>
      </Wrapper>
    );
  };
}

const Wrapper = styled.div`
  margin: 1rem 0 3rem 0;
`;

const Password = styled.div`
  height: 75px;
  border-bottom: none;
  display: grid;
  grid-template-columns: 30% auto;
  padding: 0 0 0 var(--space-2);
`;

const ButtonWrapper = styled.div`
  margin-top: var(--space-6);
`;

export default ChangePasswordSection;

ChangePasswordSection.propTypes = {
  // App.jsx props
  updateIsQuerying: PropTypes.func.isRequired,
};
