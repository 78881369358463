import { API } from 'aws-amplify';
import store from '../configureStore';
import { convertObjectBodyString } from './apiUtil';
import { config } from '../config/Config';

export const getProjectById = (projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const getProjectByIdInit = {
    headers: header,
  };

  return API.get(
    config.apiGateway.NAME,
    `/project/${projectId}?gid=${currentUserGroup}`,
    getProjectByIdInit,
  );
};

export const getFilesForProject = (projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const getFilesForProjectInit = {
    headers: header,
  };
  return API.get(
    config.apiGateway.NAME,
    `/resource-by-parent/${projectId}?gid=${currentUserGroup}`,
    getFilesForProjectInit,
  );
};

export const getFilesForProjectByType = (projectId, fileType) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const getFilesForProjectInit = {
    headers: header,
  };
  if (fileType !== 'allfiles') {
    return API.get(
      config.apiGateway.NAME,
      `/resource-by-parent/${projectId}?gid=${currentUserGroup}&type=${fileType}`,
      getFilesForProjectInit,
    );
  }

  return API.get(
    config.apiGateway.NAME,
    `/resource-by-parent/${projectId}?gid=${currentUserGroup}`,
    getFilesForProjectInit,
  );
};

export const addProject = (data) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const addProjectInit = {
    headers: header,
    body: convertObjectBodyString(data),
  };
  return API.post(config.apiGateway.NAME, `/project/add?gid=${currentUserGroup}`, addProjectInit);
};

export const renameItem = (id, title) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();

  const espacedTitle = encodeURIComponent(title);

  const updateProjectInit = {
    headers: header,
    body: `id=${id}&title=${espacedTitle}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/project/update?gid=${currentUserGroup}`,
    updateProjectInit,
  );
};

export const updateItemCover = (id, storageResponse) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
    user: { identityId },
  } = store.getState();
  const resourceS3Path = `private/${identityId}/${storageResponse.key}`;
  const updateProjectInit = {
    headers: header,
    body: `id=${id}&image=${resourceS3Path}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/project/update?gid=${currentUserGroup}`,
    updateProjectInit,
  );
};

export const updateProjectImage = (id, title, storagedImageKey, name) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
    user: { identityId },
  } = store.getState();
  const imageS3Path = `private/${identityId}/${storagedImageKey}`;
  const updateProjectInitBody = `id=${id}&title=${title}&image=${imageS3Path}&name=${name}`;
  const updateProjectInit = {
    headers: header,
    body: updateProjectInitBody,
  };
  return API.post(
    config.apiGateway.NAME,
    `/project/update?gid=${currentUserGroup}`,
    updateProjectInit,
  );
};

export const removeCoverImage = (id) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const updateProjectInitBody = `id=${id}&image=no-image`;
  const updateProjectInit = {
    headers: header,
    body: updateProjectInitBody,
  };
  return API.post(
    config.apiGateway.NAME,
    `/project/update?gid=${currentUserGroup}`,
    updateProjectInit,
  );
};

export const getGuestUser = (hash) => {
  const {
    project: { header },
  } = store.getState();
  const init = {
    headers: header,
  };
  return Promise.allSettled([
    API.get(config.apiGateway.NAME, `/guest/user/${hash}`, init),
    API.get(config.apiGateway.NAME, `/guest/user/all/${hash}`, init),
    API.get(config.apiGateway.NAME, `/guest/project/${hash}`, init),
    API.get(config.apiGateway.NAME, `/guest/message/${hash}`, init),
    API.get(config.apiGateway.NAME, `/guest/task/${hash}`, init),
    API.get(config.apiGateway.NAME, `/guest/resource/${hash}`, init),
  ]);
};

export const setGuestUsername = (hash, firstname, lastname) => {
  const {
    project: { header },
  } = store.getState();
  const init = {
    headers: header,
  };
  return API.get(
    config.apiGateway.NAME,
    `/guest/user/${hash}?firstname=${firstname}&lastname=${lastname}`,
    init,
  );
};
