import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classes from './WorkspaceParticipiantsModalAddExistingPeopleList.module.css';
import WorkspaceParticipiantsModalAddExistingPeopleListItem from './workspaceParticipiantsModalAddExistingPeopleListItem/WorkspaceParticipiantsModalAddExistingPeopleListItem';
import ProfileBall from '../../../../profileBall/ProfileBall';
import { currentUserInfoShape } from '../../../../../apiServices/userApiTypes';
import { groupShape, workspaceParticipantShape } from '../../../../../app/AppPropTypes';
import { sortParticipantsListByFullName } from '../../../../../util/UtilFunctions';
import * as stylesVariables from '../../../../../styles/variables';

const WorkspaceParticipiantsModalAddExistingPeopleList = (props) => {
  const [showInfo, setShowInfo] = React.useState();

  const updateShowInfo = (showInfoProps) => {
    if (!showInfo) {
      setShowInfo(showInfoProps);
    } else {
      setShowInfo();
    }
  };
  const sortedList = sortParticipantsListByFullName(
    props.peopleListFromOutsideCurrentlyChosenWorkspace,
  );
  const listItems = sortedList.map((person) => {
    const userIsPending = person.cognitoAttributes.UserStatus === 'FORCE_CHANGE_PASSWORD';

    const firstName = person.cognitoAttributes.UserAttributes.find(
      (attribute) => attribute.Name === 'given_name',
    ).Value;
    const lastName = person.cognitoAttributes.UserAttributes.find(
      (attribute) => attribute.Name === 'family_name',
    ).Value;
    const email = person.cognitoAttributes.UserAttributes.find(
      (attribute) => attribute.Name === 'email',
    ).Value;
    return (
      <WorkspaceParticipiantsModalAddExistingPeopleListItem
        key={person.cognitoAttributes.Username}
        firstName={firstName}
        lastName={lastName}
        email={email}
        userIsPending={userIsPending}
        currentUserRoleInThisWorkspace={props.currentUserInfoInThisWorkspace.role.S}
        updateIsQuerying={props.updateIsQuerying}
        currentWorkspace={props.currentWorkspace}
        profileImage={
          // eslint-disable-next-line
          <ProfileBall
            imageUrl={person.image && person.image.S}
            firstName={firstName}
            lastName={lastName}
          />
        }
        chosenGroup={props.chosenGroup}
        updateShowInfo={updateShowInfo}
      />
    );
  });
  return (
    <ListWrapper>
      <ul className={classes.list}>{listItems}</ul>{' '}
      {showInfo && (
        <Box
          maxHeight={showInfo.maxHeight}
          stylesVariables={stylesVariables}
          left={showInfo.left}
          top={showInfo.top}
        >
          <BoxContentWrapper>
            {showInfo.children}
            <Button onClick={() => setShowInfo()} title="close info">
              X
            </Button>
          </BoxContentWrapper>
        </Box>
      )}
    </ListWrapper>
  );
};

const ListWrapper = styled.div`
  position: relative;
`;

const Button = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
`;

const BoxContentWrapper = styled.div`
  position: relative;
`;

const Box = styled.div`
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 7.5rem);
  background-color: ${(props) => props.stylesVariables.colorGreySecondary};
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 15rem;
  padding-bottom: 1rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  max-height: ${(props) => props.maxHeight};
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.stylesVariables.colorGreySecondary};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.stylesVariables.colorGreyPrimary};
    border-radius: 5px;
    height: 50px;
  }
  h4 {
    margin: 0.5rem;
    text-transform: capitalize;
    font-size: 0.95rem;
  }
  ul {
    padding: 0 0 0 1.5rem;
    li {
      text-align: left;
      font-size: 0.85rem;
      line-height: 1rem;
      padding-bottom: 0.5rem;
    }
  }
  .secondaryText {
    font-size: 0.85rem;
    text-align: left;
    color: darken(${stylesVariables.colorDenimSecondary}, 15);
    margin: 0;
  }
`;

export default WorkspaceParticipiantsModalAddExistingPeopleList;

WorkspaceParticipiantsModalAddExistingPeopleList.propTypes = {
  peopleListFromOutsideCurrentlyChosenWorkspace: PropTypes.arrayOf(
    PropTypes.shape(workspaceParticipantShape),
  ).isRequired,
  currentWorkspace: PropTypes.string.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfoInThisWorkspace: PropTypes.shape(currentUserInfoShape).isRequired,
  chosenGroup: PropTypes.shape(groupShape).isRequired,
};
