const commandTypes = {
  free: 'free',
  straight: 'straight',
};

const lineTypes = {
  freeLine: 'freeLine',
  freeArrow: 'freeArrow',
  straightLine: 'straightLine',
  straightArrow: 'straightArrow',
};

const svgPath = (points, command) => {
  // build the d attributes by looping over the points
  const d = points.reduce(
    // eslint-disable-next-line no-confusing-arrow
    (acc, point, i, a) =>
      i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${command(point, i, a)}`,
    '',
  );
  return d;
};

const line = (pointA, pointB) => {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];
  return {
    length: Math.sqrt(lengthX ** 2 + lengthY ** 2),
    angle: Math.atan2(lengthY, lengthX),
  };
};

const controlPoint = (current, previous, next, reverse) => {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current;
  const n = next || current;
  // The smoothing ratio
  const smoothing = 0.1;
  // Properties of the opposed-line
  const o = line(p, n);
  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothing;
  // The control point position is relative to the current point
  const x = current[0] + Math.cos(angle) * length;
  const y = current[1] + Math.sin(angle) * length;
  return [x, y];
};

const bezierCommand = (point, i, a) => {
  // start control point
  const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point);
  // end control point
  const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true);
  return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`;
};

const lineCommand = (point) => `L ${point[0]} ${point[1]}`;

const getSvgPath = (points, type) => {
  const command = type === commandTypes.free ? bezierCommand : lineCommand;
  return svgPath(points, command);
};

//= == arrowHeadPoints ===

const getArrowHeadPoints = (points, lineType) => {
  if (lineType === lineTypes.freeArrow || lineType === lineTypes.straightArrow) {
    let startPoint;
    const endPoint = points[points.length - 1];
    if (lineType === lineTypes.freeArrow) {
      startPoint = points[parseInt(points.length * 0.8, 10)];
    } else {
      [startPoint] = points;
    }
    const headlen = 20;
    const dx = endPoint[0] - startPoint[0];
    const dy = endPoint[1] - startPoint[1];
    const angle = Math.atan2(dy, dx);
    const v1 = endPoint[0] - headlen * Math.cos(angle - Math.PI / 6);
    const v2 = endPoint[1] - headlen * Math.sin(angle - Math.PI / 6);
    const a1 = endPoint[0] - headlen * Math.cos(angle + Math.PI / 6);
    const a2 = endPoint[1] - headlen * Math.sin(angle + Math.PI / 6);

    return `${endPoint[0]},${endPoint[1]} ${v1},${v2} ${a1},${a2}`;
  }
  return null;
};

const getLinePoints = (points, lineType) => {
  const straightLineCoords = [points[0], points[points.length - 1]];
  switch (lineType) {
    case lineTypes.freeLine: {
      return getSvgPath(points, commandTypes.free);
    }
    case lineTypes.straightLine: {
      return getSvgPath(straightLineCoords, commandTypes.straight);
    }
    case lineTypes.freeArrow:
      return getSvgPath(points, commandTypes.free);
    case lineTypes.straightArrow:
      return getSvgPath(straightLineCoords, commandTypes.straight);
    default:
      return points.join(' ');
  }
};

export { commandTypes, lineTypes, getSvgPath, getArrowHeadPoints, getLinePoints };
