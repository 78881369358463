import { API } from 'aws-amplify';
import store from '../configureStore';
import { config } from '../config/Config';

export const addResource = (storagedResourceS3Key, parentId, name) => {
  const {
    project: { header },
    user: { identityId },
    workspace: { currentUserGroup },
  } = store.getState();
  const fileS3Path = `private/${identityId}/${storagedResourceS3Key}`;

  const espacedName = encodeURIComponent(name);

  const addResourceInit = {
    headers: header,
    body: `key=${fileS3Path}&parent=${parentId}&name=${espacedName}`,
  };
  return API.post(config.apiGateway.NAME, `/resource/add?gid=${currentUserGroup}`, addResourceInit);
};

export const addResourceWithSnapshot = (
  storagedResourceS3Key,
  parentId,
  name,
  thumbnailAddingStorageResponseKey,
) => {
  const {
    project: { header },
    user: { identityId },
    workspace: { currentUserGroup },
  } = store.getState();
  const fileS3Path = `private/${identityId}/${storagedResourceS3Key}`;
  const thumbnailResourceS3Path = `private/${identityId}/${thumbnailAddingStorageResponseKey}`;

  const espacedName = encodeURIComponent(name);

  const addResourceInit = {
    headers: header,
    body: `key=${fileS3Path}&parent=${parentId}&name=${espacedName}&snapshot=${thumbnailResourceS3Path}`,
  };
  return API.post(config.apiGateway.NAME, `/resource/add?gid=${currentUserGroup}`, addResourceInit);
};

export const updateResourceName = (resourceId, resourceName) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();

  const espacedName = encodeURIComponent(resourceName);

  const updateResourceInit = {
    headers: header,
    body: `id=${resourceId}&name=${espacedName}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/resource/update?gid=${currentUserGroup}`,
    updateResourceInit,
  );
};

export const updateResourceProjectPreviewStatus = (resourceId, resourceName, newPreviewStatus) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const updateResourceInit = {
    headers: header,
    body: `id=${resourceId}&name=${resourceName}&preview=${newPreviewStatus ? 1 : 0}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/resource/update?gid=${currentUserGroup}`,
    updateResourceInit,
  );
};

export const updateResourceParent = (resourceId, resourceName, newParentId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();

  const espacedName = encodeURIComponent(resourceName);

  const updateResourceInit = {
    headers: header,
    body: `id=${resourceId}&name=${espacedName}&parent=${newParentId}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/resource/update?gid=${currentUserGroup}`,
    updateResourceInit,
  );
};

export const getResourceDetails = (resourceId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const getResourceDetailsInit = {
    headers: header,
  };
  return API.get(
    config.apiGateway.NAME,
    `/resource/${resourceId}?gid=${currentUserGroup}`,
    getResourceDetailsInit,
  );
};

export const deleteResource = (resourceId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const deleteResourceInit = {
    headers: header,
    body: `id=${resourceId}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/resource/delete?gid=${currentUserGroup}&id=${resourceId}`,
    deleteResourceInit,
  );
};

export const copyResource = (resourceId, copyDestinationFolderParent) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const copyResourceInit = {
    headers: header,
    body: `id=${resourceId}&parent=${copyDestinationFolderParent}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/resource/copy?gid=${currentUserGroup}`,
    copyResourceInit,
  );
};

export const convertFile = (resourceId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const updateResourceInit = {
    headers: header,
  };
  return API.get(
    config.apiGateway.NAME,
    `/convert/${resourceId}?gid=${currentUserGroup}`,
    updateResourceInit,
  );
};
