import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { useCaseType } from '../../../ItemListPropTypes';

export const ThumbnailLink = (props) => {
  const { params } = useRouteMatch();

  return (
    <>
      {props.useCase !== 'link' && (
        <Link
          to={
            props.useCase === 'folder'
              ? `/in/folder/${props.parentId}/resource/${props.itemId}/${params.viewType}?sortBy=${props.sortByCriteriaIndex}&ascending=${props.sortByDirectionIsAscending}`
              : `/in/project/${props.parentId}/resource/${props.itemId}/${params.viewType}?sortBy=${props.sortByCriteriaIndex}&ascending=${props.sortByDirectionIsAscending}`
          }
        >
          {props.children}
        </Link>
      )}
      {props.useCase === 'link' && (
        <div onClick={() => props.updateResourceId(props.itemId)}>{props.children}</div>
      )}
    </>
  );
};

ThumbnailLink.propTypes = {
  useCase: useCaseType.isRequired,
  children: PropTypes.array.isRequired,
  itemId: PropTypes.string.isRequired,
  projectFileListView: PropTypes.string,
  updateResourceId: PropTypes.func,
  parentId: PropTypes.string.isRequired,
  sortByCriteriaIndex: PropTypes.number,
  sortByDirectionIsAscending: PropTypes.bool,
};

ThumbnailLink.defaultProps = {
  projectFileListView: undefined,
  updateResourceId: undefined,
  sortByCriteriaIndex: undefined,
  sortByDirectionIsAscending: undefined,
};
