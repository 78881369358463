import React, { useEffect } from 'react';
import videojs from 'video.js';
import PropTypes from 'prop-types';
import { ReactComponent as Soundwave } from '../../../../../assets/soundwave.svg';
import './AudioPlayer.scss';
import { resourceShape } from '../../ResourceViewPropTypes';

const AudioPlayer = (props) => {
  const createNewAudioElement = () => {
    const mediaDomElement = document.createElement('audio');
    mediaDomElement.setAttribute('id', `audio-wrapper-${props.content.id.S}-resource-view`);
    mediaDomElement.setAttribute('class', 'video-js');
    mediaDomElement.setAttribute('controls', 'true');
    mediaDomElement.setAttribute('data-setup', '{}');
    mediaDomElement.setAttribute('width', '800px');
    mediaDomElement.setAttribute('height', '0px');
    mediaDomElement.setAttribute('crossorigin', 'anonymous');

    const mediaDomSourceElement = document.createElement('source');
    mediaDomSourceElement.setAttribute('src', props.content.signedUrl.S);
    mediaDomSourceElement.setAttribute('type', props.content.mimeType.S);

    mediaDomElement.appendChild(mediaDomSourceElement);
    return mediaDomElement;
  };

  useEffect(() => {
    const videoJsAudioPlayerOptions = {
      autoplay: false,
      controls: true,
      height: '500px',
      sources: [
        {
          src: props.content.signedUrl.S,
          type: props.content.mimeType.S,
        },
      ],
      controlBar: {
        fullscreenToggle: false,
        liveDisplay: false,
        pictureInPictureToggle: false,
      },
    };
    const audioPlayerWrapperElement = document.getElementById('audio-wrapper-resource');
    const mediaDomElement = createNewAudioElement();
    audioPlayerWrapperElement.appendChild(mediaDomElement);
    const playerObj = videojs(mediaDomElement, videoJsAudioPlayerOptions);
    playerObj.ready(() => {
      props.ready();
    });
    return () => {
      videojs(`audio-wrapper-${props.content.id.S}-resource-view`).dispose();
    };
  }, [props.content]);

  return (
    <div className="soundwave">
      <Soundwave />
    </div>
  );
};

export default AudioPlayer;

AudioPlayer.propTypes = {
  content: PropTypes.shape(resourceShape).isRequired,
  ready: PropTypes.func.isRequired,
};
