import React, { useMemo, useEffect, useState } from 'react';
import classes from './Workspaces.module.scss';
import { ReactComponent as WorkspaceIcon } from '../../../assets/workspace.svg';
import Subnavigation, { SubnavigationItem } from '../Subnavigation';
import './workspaces.scss';
import { cutItemTitle } from '../../../util/FitTextIntoWidth';
import RoleTablet from '../../common/roleTablet/roleTablet';
import SearchIcon from '../../icons/SearchIcon';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUserGroup as updateCurrentUserGroupForRedux } from '../../../app/features/workspace/workspaceSlice';

const WorkSpaces = ({ isSubnavOpen, updateSubnav, history, notifications }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const {
    workspace: { currentUserGroup, allWorkspaces },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  // update subnav on workspaceTrigger-click
  // prevent subnav's outsideClick from closing it again
  const handleClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    updateSubnav();
  };

  const currentUserGroupObj = useMemo(() => {
    return allWorkspaces.find((i) => i.id === currentUserGroup);
  }, [currentUserGroup, allWorkspaces]);
  //console.log('allworspaces', allWorkspaces, currentUserGroup);
  // Trying to fix this shit with some ducktape
  const sortedList = useMemo(() => {
    const list = allWorkspaces.map((i) => {
      // Some weird data-modeling, just tries to map notifications for workspaces
      const note = notifications.find((j) => j[i.id]);
      let notifAmount = 0;
      if (note && note[i.id]) {
        notifAmount = note[i.id];
      }

      return {
        id: i.id,
        title: cutItemTitle(i.title, 130),
        status: i.status, // No idea what this represents
        notifications: notifAmount,
        image: i.image,
        active: currentUserGroup === i.id,
        parent: i.parent,
        expired: i.expired,
      };
    });
    return list.sort((a, b) => {
      if (a.notifications > b.notifications) return -1;
      if (a.notifications < b.notifications) return 1;
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      return 1;
    });
  }, [notifications, allWorkspaces]);

  useEffect(() => {
    if (!searchTerm.length) {
      setFilteredList(sortedList);
    } else {
      const newList = sortedList.filter((i) =>
        i.title.toLowerCase().includes(searchTerm.trim().toLowerCase()),
      );
      setFilteredList(newList);
    }
  }, [sortedList, searchTerm]);

  const updateCurrentUserGroup = (id) => {
    window.localStorage.setItem('currentUserGroup', id);

    //Redux-fix
    dispatch(updateCurrentUserGroupForRedux(id));
    //---

    if (currentUserGroupObj.parent === 'root') {
      history.push('/in/account/workspace');
    } else {
      history.push('/in/folder/root');
    }

    updateSubnav();
    setSearchTerm('');
  };

  const navigateTo = (url) => {
    history.push(url);
    updateSubnav();
    setSearchTerm('');
  };

  const getNotificationSection = () => {
    if (notifications) {
      const unseenNotifications = sortedList.reduce(
        (acc, current) => acc + current.notifications,
        0,
      );
      if (unseenNotifications > 0) {
        return (
          <div className="unreadMessagesCounter">
            <h5>{unseenNotifications > 99 ? '99' : unseenNotifications}</h5>
          </div>
        );
      }
    }
    return undefined;
  };

  const handleExpiredClick = () => {
    history.push('/in/account/billing');
  };

  return (
    <div className="workspaces">
      <div
        className={`triggerWrapper ${isSubnavOpen ? 'active' : ''}`}
        title="workspaces"
        onClick={handleClick}
      >
        {getNotificationSection()}
        <div className="imageWrapper">
          {currentUserGroupObj?.image ? (
            <img
              src={currentUserGroupObj.image}
              alt="group icon"
              className={classes.workspaceIcon}
            />
          ) : (
            <WorkspaceIcon />
          )}
        </div>
        <h4 className="workspaceLabel">{currentUserGroupObj?.title}</h4>
      </div>
      <Subnavigation close={updateSubnav} isOpen={isSubnavOpen}>
        <SubnavigationItem
          className="editWorkspace"
          onClick={() => navigateTo('/in/account/workspace')}
        >
          Edit workspaces
        </SubnavigationItem>
        <SubnavigationItem className="searchGroups">
          <div className="searchField">
            <SearchIcon height="20px" fill="gray" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
              className="searchInput"
            />
          </div>
        </SubnavigationItem>
        {filteredList &&
          filteredList.map((group) => {
            return (
              <SubnavigationItem
                className={`workspaceLink ${group.active ? 'active' : ''}`}
                key={group.id}
              >
                {group.active && <div className="activityIndicator" />}
                <div className="imageWrapper">
                  {group.image ? <img src={group.image} alt="workspace logo" /> : <WorkspaceIcon />}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                  <h4 className="groupTitle" onClick={() => updateCurrentUserGroup(group.id)}>
                    {group.title}
                  </h4>
                  <div className="iconsWrapper">
                    {group.parent === 'root' && (
                      <h4 style={{ color: 'gray' }}>
                        <RoleTablet role="admin" />
                      </h4>
                    )}
                  </div>
                  <h5 className="unseenNotificationsAmount">
                    {group.notifications > 99 ? '99' : group.notifications || ''}
                  </h5>
                </div>
              </SubnavigationItem>
            );
          })}
        {filteredList && filteredList.length > 6 && (
          <SubnavigationItem
            className="editWorkspace"
            onClick={() => navigateTo('/in/account/workspace')}
          >
            Edit workspaces
          </SubnavigationItem>
        )}
      </Subnavigation>
    </div>
  );
};

export default WorkSpaces;

// WorkSpaces.propTypes = {
//   groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)),
//   isSubnavOpen: PropTypes.bool.isRequired,
//   updateSubnav: PropTypes.func.isRequired,
//   currentUserGroup: PropTypes.string.isRequired,
//   initiateSession: PropTypes.func.isRequired,
//   history: PropTypes.shape(historyShape).isRequired,
//   notifications: PropTypes.array.isRequired,
// };

// WorkSpaces.defaultProps = {
//   groupsList: undefined,
// };
