import React, { useContext, useState, useEffect } from 'react';
import { updateUserAccess } from '../../../../apiServices/userApi';
import NotificationContext from '../../../../context/notification';
import ProfileBall from '../../../profileBall/ProfileBall';
import ExperimentalButton from '../../../buttons/experimentalButton/experimentalButton';
import RoleTablet from '../../../common/roleTablet/roleTablet';

import './workspaceUsersSection.scss';
import { useDispatch } from 'react-redux';
import { addUser } from '../../../../app/features/project/projectSlice';

const WorkspaceUsersSection = (props) => {
  const [addingUserId, setAddingUserId] = useState(undefined);
  const { add } = useContext(NotificationContext);
  const dispatch = useDispatch();

  const addUserToProject = async (person) => {
    try {
      setAddingUserId(person.uid);

      await updateUserAccess(person.uid, props.projectId, 1);

      dispatch(addUser(person));
      add({
        type: 'success',
        message: `${person.given_name} ${person.family_name} added to project.`,
      });
    } catch (updateUserAccessError) {
      setAddingUserId(undefined);

      add({
        type: 'error',
        message: `Error while adding ${person.given_name} ${person.family_name} to project.`,
      });
    }
  };

  const sortedList = props.users.sort((i, j) => i.given_name < j.given_name);

  return (
    <ul className="addWorkspacePeople">
      {sortedList.map((person) => (
        <li key={person.uid} className="person">
          <div className="avatarWrapper">
            <ProfileBall
              imageUrl={person.img}
              firstName={person.given_name}
              lastName={person.family_name}
            />
          </div>
          <div className="userInfoWrapper">
            <div className="userTitleField">
              <h5 className="userInfoName">
                {`${person.given_name} ${person.family_name}`}
                <RoleTablet role={person.role} />
              </h5>
            </div>
            <h5 className="userInfoEmail">{person.email}</h5>
          </div>
          <ExperimentalButton
            onClick={() => addUserToProject(person)}
            loading={addingUserId === person.uid}
            type="primary"
            title="Add person to project"
          >
            Add
          </ExperimentalButton>
        </li>
      ))}
    </ul>
  );
};

export default WorkspaceUsersSection;
