import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import classes from './App.module.scss';
import { Hub } from 'aws-amplify';
import './App.scss';
import Routes from '../routes/Routes';
import NavigationBar from '../components/navigationBar/NavigationBar';
import {
  getCurrentSession,
  getCurrentCredentials,
  signOut,
  getCurrentAuthenticatedUser,
} from '../apiServices/authApi';
import { getAllUsers, getUsersGroups, getCurrentUserInfo } from '../apiServices/userApi';

import { locationShape, historyShape } from '../routerPropTypes';
import { imageExists } from '../util/UtilFunctions';
import '../styles/global.scss';
import Subheader from '../components/subHeader/subHeader';
import { NotificationProvider } from '../context/notification';
import { Notifications } from '../components/notifications/Notifications';
import { updateHeader } from './features/project/projectSlice';
import {
  getActiveWorkspace,
  getIsRootWorkspace,
  getPathSecondItem,
  getCurrentUserGroup,
  handleLoginRedirect,
  getIsWorkspaceExpired,
} from './AppUtilityFunctions';
import { updateCurrentUserGroup, updateWorkspaceUsers } from './features/workspace/workspaceSlice';
import {
  setAuthUser,
  updateCurrentCredentials,
  setDetails,
  setStatus,
  setOldUserDetails,
} from './features/user/userSlice';
import PackageExpiredModal from '../components/modals/packageExpiredModal/PackageExpiredModal';
import { ReactComponent as CrossIcon } from '../assets/nitro/cross.svg';
import DataTransferLimitModal from '../components/modals/dataTransferLimitModal/DataTransferLimitModal';
import StorageSpaceLimitModal from '../components/modals/storageSpaceLimitModal/StorageSpaceLimitModal';

const initialAppState = {
  getCurrentSessionProcess: { status: 'initial', data: undefined, error: undefined },
  getCurrentCredentialsProcess: { status: 'initial', data: undefined, error: undefined },
  getGroupsListProcess: { status: 'initial', data: undefined, error: undefined },
  getAllUsersProcess: { status: 'initial', data: undefined, error: undefined },
  init: undefined,
  currentUserGroup: undefined,
  isAuthenticated: false,
  isAuthenticating: true,
  currentUserInfo: undefined,
  // currentItem is a hotfix, refer to ./README.md
  currentItem: undefined,
  isQuerying: false,
  rootWorkspaceDetails: undefined,
  loginProcessOngoing: false,
  modalOpen: undefined,
};

class App extends Component {
  constructor(props) {
    super(props);

    // // Redux-fix
    Hub.listen(
      'auth',
      (data) => {
        // console.log('A new auth event has happened: ', `${data} has ${data.payload.event}`);
        const { payload } = data;
        // console.log(JSON.stringify(payload));
        if (payload.event === 'signOut') {
          //  this.props.setAuthUser(null);
        } else if (payload.event === 'signIn') {
          // Set authuser to user-slice

          this.props.setAuthUser(payload.data);

          //this.initiateSession();
        } else if (payload.event === 'signIn_failure') {
          // Set authuser to user-slice
        }
      },
      'authListener',
    );

    this.state = initialAppState;
    this.currentSessionInterval = undefined;

    //---
  }

  componentDidMount = async () => {
    this.addEventListeners();
    await this.initiateSession();
    this.currentSessionInterval = setInterval(() => this.updateCurrentSession(), 300000);
    // Redux-fix

    if (!this.props.user.uid) {
      const cognitoUser = await getCurrentAuthenticatedUser();

      this.props.setAuthUser(cognitoUser);
    }
    // ---
  };

  componentWillUnmount = () => {
    clearInterval(this.currentSessionInterval);
  };

  initiateSession = async () => {
    const containsSignout = this.props.location.pathname.search('signout');
    if (containsSignout !== -1) {
      await this.handleLogout();
    } else {
      this.setState({ ...initialAppState });
      this.updateIsQuerying(true);
      await this.updateCurrentSession();
      await this.updateCurrentCredentials();

      this.updateGroupsList().then(() => {
        if (
          this.state.getGroupsListProcess.data &&
          this.state.getGroupsListProcess.data.length > 0
        ) {
          this.updateAllUsers(this.props.currentUserGroup, this.state.init);
        }
        this.updateCurrentUserInfo(false, 0).then(() => {
          const workspaceExpired = getIsWorkspaceExpired(
            this.state.getGroupsListProcess,
            this.props.currentUserInfo,
            this.props.currentUserGroup,
          );
          if (workspaceExpired) {
            this.setState({ modalOpen: true });
          }
          handleLoginRedirect(
            this.state.getGroupsListProcess,
            this.props.currentUserGroup,
            this.state.loginProcessOngoing,
            this.props.history,
          );
          this.setState({ isAuthenticating: false, loginProcessOngoing: false });
          this.updateIsQuerying(false);
        });
      });
    }
    // if all went well, we now have user-data and workspace-data set to store
    // lets inform somehow that all is done
    this.props.setStatus('ready');
  };

  updateCurrentSession = () =>
    new Promise(async (resolve, reject) => {
      try {
        const getCurrentSessionResponse = await getCurrentSession();

        const apigInit = {
          headers: {
            Authorization: getCurrentSessionResponse.idToken.jwtToken,
          },
        };
        // Redux-fix
        this.props.updateHeader({ Authorization: getCurrentSessionResponse.idToken.jwtToken });
        // ---
        this.setState(
          {
            getCurrentSessionProcess: {
              status: 'success',
              data: getCurrentSessionResponse,
              error: undefined,
            },
            init: apigInit,
          },
          () => resolve(getCurrentSessionResponse),
        );
      } catch (getCurrentSessionError) {
        this.updateIsQuerying(false);
        this.setState({
          getCurrentSessionProcess: {
            status: 'error',
            data: undefined,
            error: getCurrentSessionError,
          },
          isAuthenticating: false,
        });
        clearInterval(this.currentSessionInterval);
        reject(getCurrentSessionError);
      }
    });

  updateCurrentCredentials = () =>
    new Promise(async (resolve, reject) => {
      try {
        const getCurrentCredentialsResponse = await getCurrentCredentials();
        this.userHasAuthenticated(true);
        // redux-fix
        this.props.updateCurrentCredentials(getCurrentCredentialsResponse.identityId);
        // ---

        this.setState(
          {
            getCurrentCredentialsProcess: {
              status: 'success',
              data: getCurrentCredentialsResponse,
              error: undefined,
            },
          },
          () => resolve(getCurrentCredentialsResponse),
        );
      } catch (getCurrentCredentialsError) {
        this.setState({
          getCurrentCredentialsProcess: {
            status: 'error',
            data: undefined,
            error: getCurrentCredentialsError,
          },
        });
        // Redux-fix
        this.props.updateCurrentCredentials(undefined);
        // ---
        this.updateIsQuerying(false);
        reject(getCurrentCredentialsError);
      }
    });

  updateGroupsList = async (repeatUntilImageIsAvailableGroupId, counter) => {
    try {
      const getUsersGroupsResponse = await getUsersGroups();

      this.handleCurrentUserGroup(getUsersGroupsResponse);

      if (getUsersGroupsResponse.response.Items.length === 0) {
        this.setState({ getGroupsListProcess: { status: 'success', data: [], error: undefined } });
      } else {
        if (repeatUntilImageIsAvailableGroupId) {
          this.setState({
            getGroupsListProcess: {
              status: 'success',
              data: getUsersGroupsResponse.response.Items,
              error: undefined,
            },
          });
          await this.handleGroupWithUpdatedImageRecursiveCheck(
            repeatUntilImageIsAvailableGroupId,
            counter,
            getUsersGroupsResponse,
          );
        } else {
          this.setState({
            getGroupsListProcess: {
              status: 'success',
              data: getUsersGroupsResponse.response.Items,
              error: undefined,
            },
          });
        }
      }
    } catch (getUsersGroupsError) {
      this.setState({
        isAuthenticating: false,
        getGroupsListProcess: { status: 'error', data: undefined, error: getUsersGroupsError },
      });
      this.updateIsQuerying(false);
    }
  };

  updateAllUsers = () =>
    getAllUsers()
      .then(({ response }) => {
        this.setState({
          getAllUsersProcess: {
            status: 'success',
            data: response.Items,
            error: undefined,
          },
        });
        // Redux fixes
        // this.props.updateWorkspaceUsers(response);
        // ----
        return response.Items;
      })
      .catch((err) => {
        this.setState({
          getAllUsersProcess: {
            status: 'error',
            data: undefined,
            error: err,
          },
          isAuthenticating: false,
        });
        this.updateIsQuerying(false);
      });

  updateCurrentUserInfo = async (shouldRepeatUntilProfilePictureIsShowing, counter) => {
    try {
      const getCurrentUserInfoResponse = await getCurrentUserInfo(this.props.currentUserGroup);
      if (shouldRepeatUntilProfilePictureIsShowing) {
        this.handleCurrentUserInfoImageRecursiveCheck(
          getCurrentUserInfoResponse,
          shouldRepeatUntilProfilePictureIsShowing,
          counter,
        );
      } else {
        this.props.setOldUserDetails(getCurrentUserInfoResponse.response.Item);
        // this.setState({
        //   currentUserInfo: getCurrentUserInfoResponse.response.Item,
        // });
      }
    } catch (getCurrentUserInfoError) {
      this.setState({ isAuthenticating: false });
      this.updateIsQuerying(false);
    }
  };

  handleCurrentUserInfoImageRecursiveCheck = async (
    getCurrentUserInfoResponse,
    shouldRepeatUntilProfilePictureIsShowing,
    counter,
  ) => {
    if (getCurrentUserInfoResponse.response.Item.image) {
      try {
        await imageExists(getCurrentUserInfoResponse.response.Item.image.S);
        // this.setState({
        //   currentUserInfo: getCurrentUserInfoResponse.response.Item,
        // });
        this.props.setOldUserDetails(getCurrentUserInfoResponse.response.Item);
      } catch (imageExistsError) {
        if (counter < 5) {
          setTimeout(
            this.updateCurrentUserInfo(shouldRepeatUntilProfilePictureIsShowing, counter + 1),
            1000,
          );
        } else {
          // this.setState({
          //   currentUserInfo: getCurrentUserInfoResponse.response.Item,
          // });
          this.props.setOldUserDetails(getCurrentUserInfoResponse.response.Item);
        }
      }
    } else {
      if (counter < 5) {
        setTimeout(
          this.updateCurrentUserInfo(shouldRepeatUntilProfilePictureIsShowing, counter + 1),
          1000,
        );
      } else {
        // this.setState({
        //   currentUserInfo: getCurrentUserInfoResponse.response.Item,
        // });
        this.props.setOldUserDetails(getCurrentUserInfoResponse.response.Item);
      }
    }
  };

  handleGroupWithUpdatedImageRecursiveCheck = async (
    repeatUntilImageIsAvailableGroupId,
    counter,
    getUsersGroupsResponse,
  ) => {
    const updatedGroup = getUsersGroupsResponse.response.Items.find(
      (group) =>
        group.id.S === (repeatUntilImageIsAvailableGroupId.S || repeatUntilImageIsAvailableGroupId),
    );
    if (updatedGroup?.image) {
      try {
        await imageExists(updatedGroup?.image?.S);
        this.setState({
          getGroupsListProcess: {
            status: 'success',
            data: getUsersGroupsResponse.response.Items,
            error: undefined,
          },
        });
      } catch (imageCheckingError) {
        if (counter < 5) {
          setTimeout(
            () => this.updateGroupsList(repeatUntilImageIsAvailableGroupId, counter + 1),
            3000,
          );
        } else {
          this.setState({
            getGroupsListProcess: {
              status: 'success',
              data: getUsersGroupsResponse.response.Items,
              error: undefined,
            },
          });
        }
      }
    } else {
      if (counter < 5) {
        setTimeout(
          () => this.updateGroupsList(repeatUntilImageIsAvailableGroupId, counter + 1),
          3000,
        );
      }
      this.setState({
        getGroupsListProcess: {
          status: 'success',
          data: getUsersGroupsResponse.response.Items,
          error: undefined,
        },
      });
    }
  };

  handleCurrentUserGroup = (getUsersGroupsResponse) => {
    const currentUserGroup = getCurrentUserGroup(getUsersGroupsResponse.response.Items);

    //redux-fix
    // const localStorageCurrentUserGroup = window.localStorage.getItem('currentUserGroup');

    //this.props.updateCurrentUserGroup(currentUserGroup);
    // ---
    this.setState({ currentUserGroup });
  };

  resetState = () => {
    const resetState = { ...initialAppState };
    resetState.isAuthenticating = false;
    this.setState(resetState);
  };

  getWorkspacePeopleList = async () => {
    try {
      this.updateIsQuerying(true);
      const getAllUsersResponse = await getAllUsers();
      this.updateIsQuerying(false);
      this.setState({
        getAllUsersProcess: {
          status: 'success',
          data: getAllUsersResponse.response.Items,
          error: undefined,
        },
      });
      // Redux-fixes
      this.props.updateWorkspaceUsers(getAllUsersResponse.response);
      // ---
    } catch (getAllUsersError) {
      this.setState({
        getAllUsersProcess: {
          status: 'error',
          data: undefined,
          error: getAllUsersError,
        },
      });
      this.updateIsQuerying(false);
    }
  };

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  updateIsQuerying = (newQueryingState) => {
    if (newQueryingState) {
      if (this.state.isQuerying) {
        this.setState({ isQuerying: false }, () => this.setState({ isQuerying: true }));
      } else {
        this.setState({ isQuerying: true });
      }
    } else {
      this.setState({ isQuerying: false });
    }
  };

  updateCurrentItem = (item) => {
    this.setState({ currentItem: item });
  };

  updateLoginProcessOngoing = (value) => {
    this.setState({ loginProcessOngoing: value });
  };

  handleLogout = async (noRedirect) => {
    try {
      this.updateIsQuerying(true);
      await signOut();
      !noRedirect && this.props.history.push('/');
      this.updateIsQuerying(false);
      this.resetState();
      //this.userHasAuthenticated(false);
      this.props.setAuthUser(null);
      this.props.setStatus('initial');
    } catch (signOutError) {
      this.updateIsQuerying(false);
    }
  };

  updateRootWorkspaceDetails = (details) => {
    this.setState({ rootWorkspaceDetails: details });
  };

  getWrapperClass = (location, pathSecondItem) => {
    const pathnameSplitArr = location.pathname.split('/');
    if (pathSecondItem === 'welcome' || pathSecondItem === 'project') {
      return classes.projectViewWrapper;
    } else if (pathnameSplitArr.length < 3 || location.pathname.search('confirm') !== -1) {
      // we are not logged in
      return classes.authView;
    } else {
      return classes.folderViewWrapper;
    }
  };

  addEventListeners = () => {
    window.addEventListener('focus', this.handleFocus);
    window.addEventListener('dragover', this.preventUnwantedDragNDropEffect, false);
    window.addEventListener('drop', this.preventUnwantedDragNDropEffect, false);
  };

  preventUnwantedDragNDropEffect = (event) => {
    if (event.target.id !== 'dropzone') {
      event.preventDefault();
    }
  };

  handleFocus = () => {
    this.updateCurrentSession();
  };

  updateModalOpen = (useCase) => {
    this.setState({ modalOpen: useCase });
  };

  render() {
    const pathSecondItem = getPathSecondItem(this.props.location);
    const wrapperClass = this.getWrapperClass(this.props.location, pathSecondItem);
    const activeWorkspace = getActiveWorkspace(
      this.props.allWorkspaces,
      this.props.currentUserGroup,
    );

    const isRootWorkspace = getIsRootWorkspace(activeWorkspace);
    const childProps = {
      //currentSession: this.state.getCurrentSessionProcess.data,
      //currentCredentials: this.state.getCurrentCredentialsProcess.data,
      init: this.state.init,
      currentUserGroup: this.props.currentUserGroup,
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      updateIsQuerying: this.updateIsQuerying,
      initiateSession: this.initiateSession,
      updateGroupsList: this.updateGroupsList,
      groupsList: this.state.getGroupsListProcess.data,
      updateCurrentItem: this.updateCurrentItem,
      updateCurrentSession: this.updateCurrentSession,
      history: this.props.history,
      location: this.props.location,
      currentUserInfo: this.props.oldUserDetails,
      workspacePeopleList: this.state.getAllUsersProcess.data,
      getWorkspacePeopleList: this.getWorkspacePeopleList,
      updateCurrentUserInfo: this.updateCurrentUserInfo,
      updateRootWorkspaceDetails: this.updateRootWorkspaceDetails,
      rootWorkspaceDetails: this.state.rootWorkspaceDetails,
      updateLoginProcessOngoing: this.updateLoginProcessOngoing,
      handleLogout: this.handleLogout,
    };
    return (
      <NotificationProvider>
        <div className={wrapperClass} onWheel={this.handleScroll}>
          <NavigationBar
            isAuthenticated={this.state.isAuthenticated}
            handleLogout={this.handleLogout}
            groupsList={this.state.getGroupsListProcess.data}
            currentUserGroup={this.props.currentUserGroup}
            isQuerying={this.state.isQuerying}
            currentUserInfo={this.props.oldUserDetails}
            pathname={this.props.location.pathname}
            initiateSession={this.initiateSession}
            updateIsQuerying={this.updateIsQuerying}
            updateCurrentUserInfo={this.updateCurrentUserInfo}
            history={this.props.history}
            updateRootWorkspaceDetails={this.updateRootWorkspaceDetails}
            rootWorkspaceDetails={this.state.rootWorkspaceDetails}
            updateModalOpen={this.updateModalOpen}
          />

          {!this.state.isAuthenticating && (
            <>
              {this.props.user?.status === 'ready' &&
                pathSecondItem !== 'welcome' &&
                !isRootWorkspace &&
                this.state.getGroupsListProcess.data &&
                this.state.getGroupsListProcess.data.length > 0 && (
                  <Subheader currentItem={this.state.currentItem} location={this.props.location} />
                )}
              <div className={classes.contentWrapper}>
                <Routes childProps={childProps} />
              </div>
            </>
          )}
        </div>
        {this.state.modalOpen && (
          <div className={`appModal ${this.state.modalOpen ? '' : 'closed'}`}>
            <div className="modalContent">
              <div className="header">
                <button
                  onClick={() => this.updateModalOpen(undefined)}
                  title="close modal"
                  type="button"
                >
                  <CrossIcon />
                </button>
              </div>
              {this.state.modalOpen === 'expired' && (
                <PackageExpiredModal history={this.props.history} />
              )}
              {this.state.modalOpen === 'dataTransfer' && (
                <DataTransferLimitModal closeModal={this.updateModalOpen} />
              )}
              {this.state.modalOpen === 'storageLimit' && (
                <StorageSpaceLimitModal closeModal={this.updateModalOpen} />
              )}
            </div>
          </div>
        )}
        {this.state.isQuerying && (
          <div className={classes.progressBar}>
            <div className={classes.bar} />
          </div>
        )}
        <Notifications />
      </NotificationProvider>
    );
  }
}

const mapStateToProps = (state, router) => {
  const { currentCredentials } = state.project;
  const { oldUserDetails, ...user } = state.user;
  const { allWorkspaces, currentUserGroup, workspaceUsers } = state.workspace;
  const { history, location } = router;
  // console.log(state);
  return {
    currentUserGroup,
    currentCredentials,
    allWorkspaces,
    workspaceUsers,
    user,
    oldUserDetails,
    history,
    location,
  };
};

const mapDispatchToProps = {
  updateCurrentUserGroup,
  updateCurrentCredentials,
  updateHeader,
  setAuthUser,
  setDetails,
  updateWorkspaceUsers,
  setOldUserDetails,
  setStatus,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App);

App.propTypes = {
  history: PropTypes.shape(historyShape).isRequired,
  location: PropTypes.shape(locationShape).isRequired,
};
