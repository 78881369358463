import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { deleteResource } from '../../../apiServices/resourceApi';
import { deleteFolder } from '../../../apiServices/folderApi';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';
import NotificationContext from '../../../context/notification';
import { deleteWorkspace } from '../../../apiServices/groupApi';
import { borderRadiusDefault } from '../../../styles/variables';

const DeleteItemModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deleteWorkspaceProcess, setDeleteWorkspaceProcess] = useState({
    status: 'initial',
    data: undefined,
    error: undefined,
  });
  const { add } = React.useContext(NotificationContext);
  console.log(props.selectedItems);
  const deleteResourceWrapper = async (item) => {
    try {
      props.updateIsQuerying(true);
      await deleteResource(item.id || item.id);
      props.updateIsQuerying(false);
      add({
        id: Date.now(),
        type: 'success',
        message: 'File deleted',
      });
    } catch (deleteResourceError) {
      add({
        id: Date.now(),
        type: 'error',
        message: 'Could not delete file',
      });
      props.updateIsQuerying(false);
    }
  };

  const getFolderHasContent = (item) => {
    const folderContentAmountValues = Object.values(item.resources);
    return folderContentAmountValues.some((value) => value > 0);
  };

  const deleteItem = async (item) => {
    const folderHasContent = getFolderHasContent(item);
    if (!folderHasContent) {
      try {
        props.updateIsQuerying(true);
        const deleteFolderResponse = await deleteFolder(item.id);
        if (deleteFolderResponse.response.search('ERROR') === -1) {
          props.updateIsQuerying(false);
          add({
            id: Date.now(),
            type: 'success',
            message: 'Item deleted',
          });
        } else {
          add({
            id: Date.now(),
            type: 'error',
            message: 'Could not delete item',
          });
        }
      } catch (deleteItemError) {
        props.updateIsQuerying(false);
        add({
          id: Date.now(),
          type: 'error',
          message: 'Could not delete item',
        });
      }
    } else {
      add({
        id: Date.now(),
        type: 'info',
        message: "Can't delete a folder with contents, (delete items inside first)",
      });
    }
  };

  const handleWorkspaceDelete = async (item) => {
    if (props.userIsAdmin) {
      try {
        setDeleteWorkspaceProcess({ status: 'loading', data: undefined, error: undefined });
        props.updateIsQuerying(true);
        const deleteWorkspaceResponse = await deleteWorkspace(item.id);
        setDeleteWorkspaceProcess({
          status: 'success',
          data: deleteWorkspaceResponse,
          error: undefined,
        });
        if (deleteWorkspaceResponse.response.search('ERROR') === -1) {
          props.updateIsQuerying(false);
          add({
            id: Date.now(),
            type: 'success',
            message: 'Workspace deleted',
          });
        } else {
          if (deleteWorkspaceResponse.response.search('group not empty')) {
            add({
              id: Date.now(),
              type: 'info',
              message: "Can't delete workspace with contents, (delete items inside first)",
            });
          } else {
            add({
              id: Date.now(),
              type: 'error',
              message: 'Could not delete workspace',
            });
          }
        }
      } catch (deleteWorkspaceError) {
        props.updateIsQuerying(false);
        setDeleteWorkspaceProcess({
          status: 'error',
          data: undefined,
          error: deleteWorkspaceError,
        });
        add({
          id: Date.now(),
          type: 'error',
          message: 'Could not delete workspace',
        });
      }
    } else {
      add({
        id: Date.now(),
        type: 'info',
        message: 'Only admins can delete workspaces',
      });
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);

    if (!props.action) {
      await Promise.all(
        props.selectedItems.map(async (item) => {
          const itemtype = item.itemtype || item.itemtype; // projectview is refactored where folders aren't
          if (itemtype === 'resource') {
            await deleteResourceWrapper(item);
          } else if (itemtype === 'folder' || itemtype === 'project') {
            await deleteItem(item);
          } else {
            await handleWorkspaceDelete(item);
          }
        }),
      );

      setIsLoading(false);
      props.refreshList(false, undefined, true);
      props.closeModal();
    } else {
      props
        .action()
        .then(() => {
          setIsLoading(false);
          if (props.refreshList) props.refreshList(false, undefined, true);
          props.closeModal();
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <Wrapper>
      <Title>{`Delete selected item${props.selectedItems.length > 1 ? 's' : ''}?`}</Title>
      <ContentWrapper>
        <ExperimentalButton
          onClick={handleDelete}
          title="delete files"
          type="red"
          loading={isLoading || deleteWorkspaceProcess.status === 'loading'}
        >
          Delete
        </ExperimentalButton>
      </ContentWrapper>
    </Wrapper>
  );
};

export default DeleteItemModal;

DeleteItemModal.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  refreshList: PropTypes.func,
  updateIsQuerying: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.func,
  userIsAdmin: PropTypes.bool.isRequired,
};

DeleteItemModal.defaultProps = {
  // if provided, this function will take care of deleting the item
  action: undefined,
  refreshList: undefined,
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: ${borderRadiusDefault};
  height: 100%;
  margin: auto;

  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`;

const Title = styled.h4`
  text-align: center;
  font-size: 1.3em;
  margin: 0 0 1rem 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
