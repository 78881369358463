import { dataURItoBlob, getNameFromFileName } from '../../../util/UtilFunctions';

const pdfjs = require('pdfjs-dist/es5/build/pdf');
const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.entry');

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const getHeadline = (useCase) => {
  switch (useCase) {
    case 'profile':
      return 'Add profile picture';
    case 'workspace':
      return 'Add workspace icon';
    case 'coverImage':
      return 'Add cover image';
    default:
      return '';
  }
};

const getPngFromPaintedCanvas = (file, canvasRef) =>
  // eslint-disable-next-line
  new Promise((resolve, _) => {
    setTimeout(() => {
      const canvasDataURl = canvasRef.toDataURL('image/png');
      const blob = dataURItoBlob(canvasDataURl);
      const fileNameBody = getNameFromFileName(file.name);
      const newFileName = `${fileNameBody}.png`;
      const newFile = new File([blob], newFileName);
      resolve(newFile);
    }, 100);
  });

const paintPageToCanvas = async (page) => {
  const viewport = page.getViewport({ scale: 1.0 });
  const canvasElement = document.createElement('canvas');
  const context = canvasElement.getContext('2d');
  canvasElement.width = viewport.width;
  canvasElement.height = viewport.height;
  // eslint-disable-next-line
  const newPromise = new Promise((resolve, _) => {
    page
      .render({
        canvasContext: context,
        viewport,
      })
      .promise.then(() => resolve(canvasElement));
  });
  return newPromise;
};

const readPdf = (file) => {
  const url = URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    pdfjs
      .getDocument({ url })
      .promise.then((result) => resolve(result))
      .catch((readDocumentError) => reject(readDocumentError));
  });
};

const paintPdfFirstPageToCanvas = async (file) => {
  try {
    const pdfFile = await readPdf(file);
    // if pdf is a massive one page picture, do not generate thumbnail for performance reasons
    const shouldNotGenerateThumb = pdfFile._pdfInfo.numPages === 1 && file.size > 10000000;
    if (!shouldNotGenerateThumb) {
      try {
        const page = await pdfFile.getPage(1);
        try {
          const paintedCanvas = await paintPageToCanvas(page);
          return paintedCanvas;
        } catch (paintPageToCanvasError) {
          return undefined;
        }
      } catch (getPageError) {
        return undefined;
      }
    } else {
      return undefined;
    }
  } catch (pdfReadError) {
    return undefined;
  }
};

export const pdfToPng = async (file) =>
  new Promise((resolve, reject) => {
    paintPdfFirstPageToCanvas(file).then(async (paintedCanvas) => {
      if (paintedCanvas) {
        try {
          const pngPromise = getPngFromPaintedCanvas(file, paintedCanvas);
          const newFile = await pngPromise;

          resolve(newFile);
        } catch (timeOutError) {
          reject(timeOutError);
        }
      } else {
        reject();
      }
    });
  });
