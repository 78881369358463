import React, { useState, useRef, useEffect } from 'react';
import styles from './lazyloadImage.module.scss';

const LazyLoadPicture = ({ src, thumbUrl, className, onLoad, ...restProps }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleOnLoad = () => {
    setIsLoaded(true);
    if (onLoad) onLoad();
  };

  useEffect(() => {
    setIsLoaded(false);
  }, [src]);

  return (
    <>
      <img
        className={`${styles.thumbnail} ${className ? className : ''}`}
        style={!isLoaded ? { display: 'block' } : { display: 'none' }}
        alt="thumb"
        src={thumbUrl}
      />
      <img
        className={`${styles.img} ${className ? className : ''}`}
        style={isLoaded ? { display: 'block' } : { display: 'none' }}
        alt="img"
        src={src}
        onLoad={handleOnLoad}
        {...restProps}
      />
    </>
  );
};

export default LazyLoadPicture;
