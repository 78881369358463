import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/**
 * Displays round sections with background-color and user initials
 * If picture is present, will display it.
 */

const ProfileBall = (props) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
    if (props.refreshPicture) props.refreshPicture();
  };

  /**
   * Returns some rgba-value according to passed char. Defaults to 'lightgray'
   * @param {string} char
   * @returns {string}
   */
  const getBackgroundColor = (char) => {
    switch (true) {
      case char <= 'e':
        return 'rgba(118, 168, 177, 1)';
      case char <= 'j':
        return 'rgba(235, 195, 0, 1)';
      case char <= 'o':
        return 'rgba(227, 80, 0, 1)';
      case char <= 't':
        return 'rgba(0, 225, 161, 1)';
      default:
        return 'lightgray';
    }
  };

  /**
   * Return initials from props. Defaults to 'U'
   * @returns {string}
   */
  const getInitials = () => {
    if (props.firstName && props.firstName[0] && props.lastName && props.lastName[0]) {
      return `${props.firstName[0]}${props.lastName[0]}`;
    } else {
      return 'U';
    }
  };

  const backgroundColor = getBackgroundColor(props.lastName?.[1]);
  return (
    <Wrapper
      style={{ backgroundColor, border: props.hasBorder ? '2px solid #04c98e' : 'none' }}
      className="profileBall"
      title={props.title}
    >
      <InitialsText>{getInitials()}</InitialsText>
      {props.imageUrl && !imageError && (
        <BallImage src={props.imageUrl} onError={handleImageError} alt="user" />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const InitialsText = styled.p`
  text-transform: uppercase;
  color: #fff;
  font-size: 100%;
  width: 100%;
  text-align: center;
  font-weight: bold;
`;

const BallImage = styled.img`
  object-fit: cover;
  object-position: center;
  max-width: 100%;
  position: absolute;
`;

export default ProfileBall;

ProfileBall.propTypes = {
  imageUrl: PropTypes.string,
  hasBorder: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  refreshPicture: PropTypes.func,
  title: PropTypes.string,
};

ProfileBall.defaultProps = {
  hasBorder: false,
  firstName: undefined,
  lastName: undefined,
  refreshPicture: undefined,
  imageUrl: undefined,
  title: undefined,
};
