import React from 'react';
import styled from 'styled-components';
import CheckedIcon from '../../../../../../../components/icons/CheckedIcon';
import './AccountSettingsWorkspaceAdministrationListItem.scss';
import ExperimentalButton from '../../../../../../../components/buttons/experimentalButton/experimentalButton';
import { cutItemTitle } from '../../../../../../../util/FitTextIntoWidth';
import { hasExpired } from '../../../../../../../util/subscriptionDetails';
import { historyShape } from '../../../../../../../routerPropTypes';
import { Infobox } from '../../../../../../../components/common/infobox/Infobox';
import * as stylesVariables from '../../../../../../../styles/variables';

export const AccountSettingsWorkspaceAdministrationListItem = (props) => {
  const [showInfo, setShowInfo] = React.useState();

  const updateShowInfo = (showInfoProps) => {
    if (!showInfo) {
      setShowInfo(showInfoProps);
    } else {
      setShowInfo();
    }
  };
  const isActive = props.group.id.S === props.currentUserGroup;
  const handleActivateClick = () => {
    if (!isActive) {
      window.localStorage.setItem('currentUserGroup', props.group.id.S);
      props.initiateSession();
    }
  };

  const handleTitleClick = () => {
    if (isActive) {
      const modalParams = [
        { workspace: props.group, title: `Rename workspace ${props.group.title.S}` },
      ];
      props.updateModalParams(modalParams);
      props.updateModalOpen('renameWorkspace');
    }
  };

  const handlePeopleClick = () => {
    const modalParams = [{ workspace: props.group.id.S }];
    props.updateModalParams(modalParams);
    props.updateModalOpen('workspacePeopleList');
  };

  const handleExpiredClick = () => {
    props.history.push('/in/account/billing');
  };

  return (
    <ItemWrapper>
      <li
        className={`${
          isActive
            ? 'account-settings-workspace-administration-list-item-active'
            : 'account-settings-workspace-administration-list-item'
        }`}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="check-button-wrapper">
            <button
              className={isActive ? 'actionables-select-active' : 'actionables-select'}
              onClick={handleActivateClick}
              type="button"
              title="select workspace"
            >
              {isActive && <CheckedIcon height="15px" fill="#fff" />}
            </button>
          </div>
          <div className="title-wrapper">
            <h5 className="text">{cutItemTitle(props.group.title.S, 200)}</h5>
            {isActive && (
              <h5 onClick={handleTitleClick} className="edit-text">
                edit
              </h5>
            )}
          </div>
          {hasExpired(props.group) && (
            <button
              onClick={() => handleExpiredClick()}
              className="expiredButton"
              type="button"
              title="expired"
            >
              <h5 className="expiredButtonText">!</h5>
            </button>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="participants-button-wrapper">
            <ExperimentalButton type="primary" onClick={handlePeopleClick}>
              participants
            </ExperimentalButton>
          </div>
          <div className="info-box-wrapper">
            <Infobox maxHeight="11rem" updateShowInfo={updateShowInfo}>
              <p>This is your account administration, create workspaces under this</p>
            </Infobox>
          </div>
        </div>
      </li>
      {showInfo && (
        <Box
          maxHeight={showInfo.maxHeight}
          stylesVariables={stylesVariables}
          left={showInfo.left}
          top={showInfo.top}
        >
          <BoxContentWrapper>
            {showInfo.children}
            <Button onClick={() => setShowInfo()} title="close info">
              X
            </Button>
          </BoxContentWrapper>
        </Box>
      )}
    </ItemWrapper>
  );
};

const BoxContentWrapper = styled.div`
  position: relative;
`;

const ItemWrapper = styled.div`
  position: relative;
`;

const Box = styled.div`
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 7.5rem);
  background-color: ${(props) => props.stylesVariables.colorGreySecondary};
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 15rem;
  padding-bottom: 1rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  z-index: 100;
  max-height: ${(props) => props.maxHeight};
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.stylesVariables.colorGreySecondary};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.stylesVariables.colorGreyPrimary};
    border-radius: 5px;
    height: 50px;
  }
  h4 {
    margin: 0.5rem;
    text-transform: capitalize;
    font-size: 0.95rem;
  }
  ul {
    padding: 0 0 0 1.5rem;
    li {
      text-align: left;
      font-size: 0.85rem;
      line-height: 1rem;
      padding-bottom: 0.5rem;
    }
  }
  .secondaryText {
    font-size: 0.85rem;
    text-align: left;
    color: darken(${stylesVariables.colorDenimSecondary}, 15);
    margin: 0;
  }
`;

const Button = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
`;
