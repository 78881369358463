import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ItemListItemFile from '../itemListItemFile/ItemListItemFile';
import { useCaseType } from '../ItemListPropTypes';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';
import { breakpointLarge } from '../../../styles/variables';

const LinkViewItemList = (props) => {
  const rendableList = props.content.map((item) => {
    const isSelected =
      props.selectedItems.find((selectedItem) => selectedItem.id === item.id) !== undefined;
    return (
      <ItemListItemFile
        item={item}
        updateModalOpen={props.updateModalOpen}
        refreshList={props.refreshList}
        viewAsLargeGrid={false}
        updateIsQuerying={props.updateIsQuerying}
        useCase={props.useCase}
        updateResourceId={props.updateResourceId}
        selectedItems={props.selectedItems}
        addToSelectedItems={props.addToSelectedItems}
        removeFromSelectedItems={props.removeFromSelectedItems}
        isSelected={isSelected}
      />
    );
  });
  return <ItemsList>{rendableList}</ItemsList>;
};

const ItemsList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  @media screen and (max-width: ${breakpointLarge}) {
    justify-content: center;
  }
`;

export default LinkViewItemList;

LinkViewItemList.propTypes = {
  // App.jsx props
  updateIsQuerying: PropTypes.func.isRequired,

  // FolderView.jsx props
  refreshList: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  useCase: useCaseType.isRequired,
  updateResourceId: PropTypes.func,

  // ItemList.jsx props
  updateModalOpen: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  addToSelectedItems: PropTypes.func.isRequired,
  removeFromSelectedItems: PropTypes.func.isRequired,
};

LinkViewItemList.defaultProps = {
  updateResourceId: undefined,
};
