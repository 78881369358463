import React from 'react';
import PropTypes from 'prop-types';

const SoundsIcon = (props) => {
  const { height, fill } = props;
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 25.7 25.7"
      enableBackground="new 0 0 25.7 25.7"
      xmlSpace="preserve"
      height={height}
    >
      <g>
        <path
          fill={fill}
          d="M22.1,0.1L11,3.5C9.6,3.9,8.5,5.3,8.5,6.6v11.6c0,0,0,0.2,0,0.2c0,0-0.8-0.5-2.6-0.3
            c-2.6,0.4-4.8,2.4-4.8,4.5s2.1,3.4,4.8,3c2.6-0.4,4.6-2.3,4.6-4.5c0,0,0-9.1,0-10s1.1-1.3,1.1-1.3l9.8-3.1c0,0,1.1-0.4,1.1,0.6
            s0,8,0,8s0,0,0,0c0,0-1-0.6-2.8-0.4c-2.6,0.3-4.8,2.3-4.8,4.4c0,2.1,2.1,3.5,4.8,3.1c2.6-0.3,4.8-2.3,4.8-4.4V1.8
            C24.5,0.5,23.4-0.3,22.1,0.1z"
        />
      </g>
    </svg>
  );
};

export default SoundsIcon;

SoundsIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

SoundsIcon.defaultProps = {
  height: '0',
};
