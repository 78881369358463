import React from 'react';
import styles from './spinner.module.scss';

const divs = []; // collector of rendered divs
const count = 12; // how many divs we want

for (let i = 0; i < count; i++) {
  divs.push(
    <div key={i} className={`${styles[`spinnerCircle${i + 1}`]} ${styles.spinnerChild}`} />,
  );
}

const Loading = ({ className, size = 50 }) => {
  return (
    <div
      className={`${styles.spinner} ${className ? className : ''}`}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      {divs}
    </div>
  );
};
export default React.memo(Loading);
