import React from 'react';
import PropTypes from 'prop-types';
import MiniatureFolderSystemFolderList from './miniatureFolderSystemFolderList/MiniatureFolderSystemFolderList';
import MiniatureFolderSystemBreadCrumbs from './miniatureFolderSystemBreadCrumbs/MiniatureFolderSystemBreadCrumbs';
import { getFolders, getFolderById } from '../../apiServices/folderApi';
import { folderListItemShape } from '../../app/views/folderView/FolderViewPropTypes';
import Loader from '../loader/loader';
import './miniatureFolderSystem.scss';
import NotificationContext from '../../context/notification';
import { transformFolder, transformFolderContent } from '../../app/features/workspace/utils';

class MiniatureFolderSystem extends React.Component {
  state = {
    isLoading: false,
    currentFolderNonResourceContent: undefined,
    currentFolder: undefined,
    selectedItem: undefined,
  };

  componentDidMount = async () => {
    this.updateRootFolder();
  };

  updateRootFolder = async () => {
    try {
      this.setState({ isLoading: true });
      this.props.updateIsQuerying(true);
      const getFolderResponse = await getFolders();
      const folders = transformFolderContent(getFolderResponse.response.Items);

      this.props.updateIsQuerying(false);
      const currentFolder = getFolderResponse.response.Item
        ? transformFolder(getFolderResponse.response.Item)
        : 'root';
      const currentFolderNonResourceContent = folders.filter(
        (item) => item.itemtype !== 'resource',
      );
      this.setState({
        isLoading: false,
        currentFolderNonResourceContent,
        currentFolder,
      });
      this.props.updateSelectedDestination(currentFolder);
    } catch (getFolderResponseError) {
      this.setState({ isLoading: false });
      this.props.updateIsQuerying(false);
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not get folder',
      });
    }
  };

  updateSelectedItem = (item) => {
    if (item) {
      if (this.props.useCase === 'move') {
        const selectedItemsIncludesDestination =
          this.props.selectedItems.find((selectedItem) => {
            if (selectedItem.id === item.id) {
              return selectedItem;
            }
            return undefined;
          }) !== undefined;
        if (!selectedItemsIncludesDestination) {
          this.setState({ selectedItem: item }, () => this.props.updateSelectedDestination(item));
        } else {
          this.context.add({
            id: Date.now(),
            type: 'info',
            message: "Can't move folder inside itself",
          });
        }
      } else if (this.props.useCase === 'copy') {
        this.setState({ selectedItem: item }, () => this.props.updateSelectedDestination(item));
      }
    } else {
      this.setState({ selectedItem: undefined }, () => this.props.updateSelectedDestination());
    }
  };

  updateFolderById = async (folderId) => {
    try {
      this.setState({ isLoading: true });
      this.props.updateIsQuerying(true);
      const getFolderByIdResponse = await getFolderById(folderId);

      this.props.updateIsQuerying(false);
      const currentFolder = getFolderByIdResponse.response.Item
        ? transformFolder(getFolderByIdResponse.response.Item)
        : 'root';
      const folderContent = transformFolderContent(getFolderByIdResponse.response.Items);
      const currentFolderNonResourceContent = folderContent.filter(
        (item) => item.itemtype !== 'resource',
      );
      this.setState({
        isLoading: false,
        currentFolderNonResourceContent,
        currentFolder,
      });
      this.props.updateSelectedDestination(currentFolder);
    } catch (getFolderResponseError) {
      this.setState({ isLoading: false });
      this.props.updateIsQuerying(false);
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not get folder',
      });
    }
  };

  static contextType = NotificationContext;

  render = () => (
    <div className="miniatureFolderSystem">
      {this.state.currentFolder && (
        <MiniatureFolderSystemBreadCrumbs
          currentFolder={this.state.currentFolder}
          workspaceName={this.props.workspaceName}
          updateRootFolder={this.updateRootFolder}
          updateFolderById={this.updateFolderById}
        />
      )}
      <div className="wrapper" style={this.state.currentFolder ? {} : { border: 'none' }}>
        {!this.state.isLoading && this.state.currentFolderNonResourceContent && (
          <MiniatureFolderSystemFolderList
            currentFolderNonResourceContent={this.state.currentFolderNonResourceContent}
            selectedItemsContainsNonFileItem={this.props.selectedItemsContainsNonFileItem}
            updateFolderById={this.updateFolderById}
            updateSelectedItem={this.updateSelectedItem}
            selectedItem={this.state.selectedItem}
          />
        )}
        {this.state.isLoading && <Loader />}
      </div>
    </div>
  );
}

export default MiniatureFolderSystem;

MiniatureFolderSystem.propTypes = {
  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,

  // ItemList.jsx props
  selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,

  // ItemListModals.jsx props
  workspaceName: PropTypes.string.isRequired,

  // CopyItemModal.jsx props
  updateSelectedDestination: PropTypes.func.isRequired,
  selectedItemsContainsNonFileItem: PropTypes.bool.isRequired,
  useCase: PropTypes.oneOf(['copy', 'move']).isRequired,
};
