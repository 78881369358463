import React from 'react';
import PropTypes from 'prop-types';
import classes from './OutsideLinkView.module.css';
import './OutsideLinkView.scss';
import { getLink } from '../../../apiServices/linkApi';
import { downloadFile, getExtFromFileName } from '../../../util/UtilFunctions';
import { validateFileTypeRendability } from '../../../util/FileTypeSupportCheck';
import { locationShape, matchShape, historyShape } from '../../../routerPropTypes';
import ItemList from '../../../components/itemList/ItemList';
import { currentUserInfoShape } from '../../../apiServices/userApiTypes';
import Resource from '../resourceView/resource/Resource';
import ItemListLoading from '../../../components/itemListLoading/ItemListLoading';
import ErrorBoundary from '../../errorBoundary/ErrorBoundary';
import NotificationContext from '../../../context/notification';
import ExperimentalButton from '../../../components/buttons/experimentalButton/experimentalButton';
import { transformFolderContent } from '../../features/workspace/utils';

class OutsideLinkView extends React.Component {
  static contextType = NotificationContext;

  constructor() {
    super();
    this.state = {
      resourceLinkProcess: { status: 'initial' },
      resourceOpenedId: undefined,
      fullscreen: false,
    };
  }

  componentDidMount = async () => {
    await this.getLinks();
  };

  getLinks = async () => {
    try {
      this.setState({ resourceLinkProcess: { status: 'loading' } });
      const idsStr = this.props.location.pathname.substring(
        this.props.location.pathname.lastIndexOf('/') + 1,
        this.props.location.pathname.length,
      );
      const getLinkResponse = await getLink(idsStr);
      this.setState({
        resourceLinkProcess: { status: 'success', data: getLinkResponse.response.Items },
      });
    } catch (getLinkError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Could not find link',
      });
      this.setState({ resourceLinkProcess: { status: 'error', data: getLinkError } });
    }
  };

  updateResourceId = (id) => {
    this.setState({ resourceOpenedId: id });
  };

  handleFileDownload = async (resource) => {
    downloadFile(resource.name.S, resource.signedUrl.S);
  };

  handleExpiredButtonClick = () => {
    if (this.props.isAuthenticated) {
      this.props.history.push('/in/folder/root');
    } else {
      this.props.history.push('/');
    }
  };

  getList = () => {
    switch (this.state.resourceLinkProcess.status) {
      case 'initial': {
        return <div />;
      }
      case 'loading': {
        return <ItemListLoading amount={7} />;
      }
      case 'success': {
        if (this.state.resourceLinkProcess.data.length > 0) {
          return (
            <ItemList
              useCase="link"
              content={transformFolderContent(this.state.resourceLinkProcess.data || [])}
              sortByCriteriaIndex={0}
              sortByDirectionIsAscending
              match={this.props.match}
              currentUserGroup={this.props.currentUserGroup}
              refreshList={this.getLinks}
              currentItem={undefined}
              inProjectInitialState={false}
              history={this.props.history}
              updateIsQuerying={this.props.updateIsQuerying}
              currentUserInfo={this.props.currentUserInfo}
              location={this.props.location}
              updateResourceId={this.updateResourceId}
            />
          );
        } else {
          return (
            <div className={classes.expiredWrapper}>
              <h2 className={classes.expiredHeadline}>Link has expired</h2>
              <ExperimentalButton
                type="button"
                title="return home"
                onClick={this.handleExpiredButtonClick}
              >
                Return home
              </ExperimentalButton>
            </div>
          );
        }
      }
      case 'error': {
        return (
          <>
            <h5>Try again</h5>
            <ExperimentalButton
              onClick={() => this.getLinks()}
              title="try again"
              buttonType="button"
            >
              Try again
            </ExperimentalButton>
          </>
        );
      }
      default: {
        return <div />;
      }
    }
  };

  getOpenedResource = (resourceLinkProcess, resourceOpenedId) => {
    if (resourceLinkProcess.status === 'success') {
      return resourceLinkProcess.data.find((resource) => resource.id.S === resourceOpenedId);
    } else {
      return undefined;
    }
  };

  getOpenedResourceIsRendable = (openedResource) => {
    if (openedResource) {
      const fileExt = getExtFromFileName(openedResource.name.S || '');
      return validateFileTypeRendability(openedResource.type.S, fileExt);
    } else {
      return undefined;
    }
  };

  render() {
    const openedResource = this.getOpenedResource(
      this.state.resourceLinkProcess,
      this.state.resourceOpenedId,
    );
    const openedResourceIsRendable = this.getOpenedResourceIsRendable(openedResource);
    let fileExt;
    if (openedResource) {
      fileExt = getExtFromFileName(openedResource.name.S);
    }
    return (
      <ErrorBoundary>
        {this.state.resourceOpenedId && (
          <>
            <div
              className={`linkResourceWrapper ${
                openedResource === undefined ? openedResource.type.S : ''
              }`}
            >
              <ExperimentalButton
                onClick={() => this.updateResourceId()}
                title="Back"
                buttonType="button"
              >
                Back
              </ExperimentalButton>
              <Resource
                content={openedResource}
                location={this.props.location}
                updateIsQuerying={this.props.updateIsQuerying}
                viewType="allfiles"
                history={this.props.history}
                useCase="link"
                isRendable={openedResourceIsRendable}
                fileExt={fileExt}
                fullscreen={this.state.fullscreen}
                closeFullscreen={() => this.setState({ fullscreen: false })}
                openFullscreen={() => this.setState({ fullscreen: true })}
              />
            </div>
            <p className="resourceName">{openedResource.name.S}</p>
          </>
        )}
        {!this.state.resourceOpenedId && this.getList()}
      </ErrorBoundary>
    );
  }
}

export default OutsideLinkView;

OutsideLinkView.propTypes = {
  // router
  location: PropTypes.shape(locationShape).isRequired,
  match: PropTypes.shape(matchShape).isRequired,
  history: PropTypes.shape(historyShape).isRequired,

  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape),
  currentUserGroup: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
};

OutsideLinkView.defaultProps = {
  currentUserInfo: undefined,
  currentUserGroup: undefined,
};
