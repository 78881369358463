import React from 'react';
import PropTypes from 'prop-types';
import ProfileBall from '../../../../../../../../components/profileBall/ProfileBall';
import ReplyIcon from '../../../../../../../../components/icons/ReplyIcon';
import MessageImageSectionTaskItem from './messageImageSectionTaskItem/MessageImageSectionTaskItem';

// prop types
import { folderListItemShape } from '../../../../../../folderView/FolderViewPropTypes';
import { taskShape } from '../../../../ProjectMainViewPropTypes';
import { imageShape } from '../../../../projectChat/projectChatMessages/ProjectChatMessagesPropTypes';

const MessageImageSection = ({
  isAReply,
  image,
  taskResource,
  task,
  handleImageClick,
  firstName,
  lastName,
}) => (
  <div className="messageImageSection">
    {isAReply && <ReplyIcon height="15px" fill="green" />}
    {image && (
      <>
        {image.task ? (
          <MessageImageSectionTaskItem
            image={image}
            taskResource={taskResource}
            task={task}
            handleImageClick={handleImageClick}
          />
        ) : (
          <ProfileBall imageUrl={image.imageUrl} firstName={firstName} lastName={lastName} />
        )}
      </>
    )}
  </div>
);

export default MessageImageSection;

MessageImageSection.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  image: PropTypes.shape(imageShape),
  isAReply: PropTypes.bool.isRequired,
  taskResource: PropTypes.shape(folderListItemShape),
  task: PropTypes.shape(taskShape),
  handleImageClick: PropTypes.func.isRequired,
};

MessageImageSection.defaultProps = {
  firstName: undefined,
  lastName: undefined,
  image: undefined,
  taskResource: undefined,
  task: undefined,
};
