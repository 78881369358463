import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { groupShape } from '../../../../../../app/AppPropTypes';
import { addNewUser } from '../../../../../../apiServices/userApi';
import NotificationContext from '../../../../../../context/notification';
import RoleSelector from '../../../roleSelector';
import { getAvailableRoles } from './WorkspaceParticipiantsModalAddExistingPeopleListItemUtilityFunctions';
import './WorkspaceParticipiantsModalAddExistingPeopleListItem.scss';

const WorkspaceParticipiantsModalAddExistingPeopleSectionListItem = ({
  firstName,
  lastName,
  email,
  profileImage,
  currentUserRoleInThisWorkspace,
  currentWorkspace,
  updateIsQuerying,
  chosenGroup,
  updateShowInfo,
}) => {
  const [addUserStatus, setAddUserStatus] = useState('initial');
  const notificationContext = useContext(NotificationContext);

  const addUserToWorkspace = async (selectedRole) => {
    setAddUserStatus('loading');
    try {
      updateIsQuerying(true);
      const addNewUserResponse = await addNewUser(email, selectedRole, currentWorkspace);
      if (addNewUserResponse.response.search('ERROR') === -1) {
        updateIsQuerying(false);
        setAddUserStatus('success');
        notificationContext.add({
          id: Date.now(),
          type: 'success',
          message: `Added ${firstName} ${lastName} as a ${selectedRole} participant`,
        });
      } else {
        setAddUserStatus('initial');
        if (addNewUserResponse.response.search('Too many admins') !== -1) {
          notificationContext.add({
            id: Date.now(),
            type: 'error',
            message: 'Could not invite the user, too many admins already',
          });
        } else {
          notificationContext.add({
            id: Date.now(),
            type: 'error',
            message: `Could not invite the user '${email}'`,
          });
        }
      }
    } catch (addUserError) {
      setAddUserStatus('initial');
      updateIsQuerying(false);
      notificationContext.add({
        id: Date.now(),
        type: 'error',
        message: `Could not invite the user '${email}'`,
      });
    }
  };

  return (
    <li className="workspaceParticipiantsModalAddExistingPeopleSectionListItem">
      <div className="avatarWrapper">{profileImage}</div>
      <div className="userInfoWrapper">
        <div className="userTitleField">
          <h5 className="userInfoName">{`${firstName} ${lastName}`}</h5>
        </div>
        <h5 className="userInfoEmail" title={email}>
          {email}
        </h5>
      </div>
      <RoleSelector
        availableValues={getAvailableRoles(currentUserRoleInThisWorkspace, chosenGroup.parent.S)}
        value={chosenGroup.parent.S === 'root' ? 'admin' : 'viewer'}
        setValue={addUserToWorkspace}
        isEditable
        status={addUserStatus}
        useCase="addUser"
        updateShowInfo={updateShowInfo}
      />
    </li>
  );
};

export default WorkspaceParticipiantsModalAddExistingPeopleSectionListItem;

WorkspaceParticipiantsModalAddExistingPeopleSectionListItem.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  profileImage: PropTypes.node.isRequired,
  // userIsPending: PropTypes.bool.isRequired,
  currentUserRoleInThisWorkspace: PropTypes.string.isRequired,
  currentWorkspace: PropTypes.string.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  chosenGroup: PropTypes.shape(groupShape).isRequired,
  updateShowInfo: PropTypes.func.isRequired,
};
