import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { subscripePlan } from '../../../apiServices/groupApi';
import PackageCard from './packageCard';
import styles from './packagesView.module.scss';
import Spinner from '../../../components/common/spinner';

export const PackagesView = () => {
  const { createdGroup, packages } = useSelector((state) => state.workspace);
  const [loading, setLoading] = useState(false);
  const onCardButtonClick = (plan) => {
    if (loading) return;
    setLoading(true);
    subscripePlan(createdGroup.id, plan)
      .then((res) => {
        if (res.response) {
          window.location.href = res.response;
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      })
      .finally(() => {});
  };

  return (
    <div className={styles.container}>
      <h1>You're almost there</h1>
      <div className={styles.ingress}>
        <p>
          Choose the most suitable plan for your business and fill out your payment card information
          on Stripe, our partner service.
        </p>
        <p>
          Yemma is free for the first 14 days and your payment card will only be charged after the
          free period. During the free period, you can easily stop using the service and deactivate
          your account.
        </p>
      </div>
      <h2>Select a plan</h2>
      <div className={styles.cardsList}>
        {packages?.map((i) => {
          const storage = i.storage < 1000 ? `${i.storage} GB` : `${i.storage / 1000} TB`;
          return (
            <PackageCard
              key={i.name}
              title={i.name}
              price={i.price}
              onButtonClick={() => {
                onCardButtonClick(i.name);
              }}
              buttonText={'Purchase'}
              features={[
                `all functionalities`,
                `${i.admins} admin users`,
                `unlimited other users`,
                `${storage} of storage space`,
                `data transfer ${i.data_transfer} GB per month`,
              ]}
            />
          );
        })}
      </div>

      <p className={styles.info}>
        The cost of additional storage space for the part exceeding the contract is € 0.10 per
        GB/month and the data transfer cost for the part exceeding the contract is € 0.20 per
        GB/month. VAT is not included in the prices.
      </p>

      {loading && (
        <div className={styles.loadingContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default PackagesView;
