import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './AccountSettingsWorkspace.scss';
import { groupShape } from '../../../AppPropTypes';
import { currentUserInfoShape } from '../../../../apiServices/userApiTypes';
import WorkspaceModals from './workspaceModals/WorkspaceModals';
import AddNewIcon from '../../../../components/icons/AddNewIcon';
import AccountSettingsWorkspaceList from './accountSettingsWorkspaceList/AccountSettingsWorkspaceList';
import { AccountSettingsWorkspaceAdministration } from './accountSettingsWorkspaceAdministration/AccountSettingsWorkspaceAdministration';
import {
  sortGroupsListByName,
  capitalize,
  currentWorkspaceHasExpired,
  userIsRootGroupCreator,
} from '../../../../util/UtilFunctions';
import { cutItemTitle } from '../../../../util/FitTextIntoWidth';
import NotificationContext from '../../../../context/notification';
import { historyShape } from '../../../../routerPropTypes';
import { getPackageType } from '../../../../util/subscriptionDetails';
import ExperimentalButton from '../../../../components/buttons/experimentalButton/experimentalButton';
import { colorDenimPrimary } from '../../../../styles/variables';
import { ReactComponent as ArrowIcon } from '../../../../assets/arrow.svg';

class AccountSettingsWorkspace extends React.Component {
  static contextType = NotificationContext;

  constructor() {
    super();
    this.state = {
      dropdownOpenedItemId: undefined,
      sectionsOpen: [1, 2, 3, 4, 5],
      modalOpen: undefined,
      modalParams: undefined,
    };
  }

  componentDidMount = () => {
    const currentSelectedGroup = this.props.groupsList.find(
      (group) => group.id.S === this.props.currentUserGroup,
    );
    const currentSelectedGroupIsSubGroup = currentSelectedGroup.parent.S !== 'root';
    const isRootsSubGroup = this.props.groupsList.some(
      (group) => group.id.S === currentSelectedGroup.parent.S,
    );
    if (currentSelectedGroupIsSubGroup && isRootsSubGroup) {
      this.setState({ sectionsOpen: [1, 2] });
    } else if (currentSelectedGroupIsSubGroup && !isRootsSubGroup) {
      this.setState({ sectionsOpen: [1, 3] });
    }
  };

  updateSectionsOpen = (sectionOpen) => {
    if (!currentWorkspaceHasExpired(this.props.groupsList) || sectionOpen !== 2) {
      const { sectionsOpen } = this.state;
      if (!this.state.sectionsOpen.some((item) => item === sectionOpen)) {
        const newSectionsOpen = [...sectionsOpen, sectionOpen];
        this.setState({ sectionsOpen: newSectionsOpen });
      } else {
        const newSectionsOpen = sectionsOpen.filter((item) => item !== sectionOpen);
        this.setState({ sectionsOpen: newSectionsOpen });
      }
    } else {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Free trial period has ended, please upgrade your account',
      });
    }
  };

  updateDropdownOpenedItemId = (id) => {
    this.setState({ dropdownOpenedItemId: id });
  };

  extractRootSubGroupsList = (groupsList, currentUserGroup) => {
    const currentWorkspace = groupsList.find((group) => group.id.S === currentUserGroup);
    if (currentWorkspace) {
      const hasRootWorkspaces = groupsList.some((group) => group.parent.S === 'root');
      if (hasRootWorkspaces) {
        const rootGroupIsSelected = currentWorkspace.parent.S === 'root';
        if (rootGroupIsSelected) {
          return groupsList.filter((group) => group.parent.S === currentWorkspace.id.S);
        } else {
          const currentSelectedSubGroupParentGroupIsKnowable = groupsList.some(
            (group) => group.id.S === currentWorkspace.parent.S,
          );
          if (currentSelectedSubGroupParentGroupIsKnowable) {
            const parentGroup = groupsList.find(
              (group) => group.id.S === currentWorkspace.parent.S,
            );
            return groupsList.filter((group) => group.parent.S === parentGroup.id.S);
          }
        }
      }
    }
    return [];
  };

  extractOtherGroupsList = (groupsList) => {
    const copiedGroupsList = [...groupsList];
    const hasRootWorkspaces = copiedGroupsList.some((group) => group.parent.S === 'root');
    if (!hasRootWorkspaces) {
      return copiedGroupsList;
    } else {
      const rootGroups = copiedGroupsList.filter((group) => group.parent.S === 'root');
      const nonRootGroups = copiedGroupsList.filter((group) => group.parent.S !== 'root');
      const nonRootGroupsWithUnknownParents = nonRootGroups.filter((group) => {
        const hasKnownParent = rootGroups.some((rootGroup) => rootGroup.id.S === group.parent.S);
        return !hasKnownParent;
      });
      return nonRootGroupsWithUnknownParents;
    }
  };

  extractRootsSubGroupsListTitle = (groupsList, currentUserGroup) => {
    const currentWorkspace = groupsList.find((group) => group.id.S === currentUserGroup);
    if (currentWorkspace) {
      const hasRootWorkspaces = groupsList.some((group) => group.parent.S === 'root');
      if (hasRootWorkspaces) {
        const rootGroupIsSelected = currentWorkspace.parent.S === 'root';
        const rootsSubGroupsList = groupsList.filter(
          (group) => group.parent.S === currentWorkspace.id.S,
        );
        if (rootGroupIsSelected) {
          return `${cutItemTitle(capitalize(currentWorkspace.title.S), 200)} workspaces (${
            rootsSubGroupsList.length
          })`;
        } else {
          const rootGroups = groupsList.filter((group) => group.parent.S === 'root');
          const parentRootGroup = rootGroups.find(
            (group) => group.id.S === currentWorkspace.parent.S,
          );
          if (parentRootGroup) {
            return `${cutItemTitle(capitalize(parentRootGroup.title.S), 200)} workspaces (${
              rootsSubGroupsList.length
            })`;
          }
        }
      }
    }
    return 'not found';
  };

  updateModalParams = (modalParams) => {
    this.setState({ modalParams });
  };

  updateModalOpen = (value) => {
    this.setState({ modalOpen: value });
  };

  getAbleToAddNewWorkspaces = (groupsList, currentUserGroup, currentUserInfo) => {
    const currentWorkspace = groupsList.find((group) => group.id.S === currentUserGroup);
    if (currentWorkspace) {
      const userCurrentWorkspaceObj = currentUserInfo.groups.find(
        (group) => group.id.S === currentWorkspace.id.S,
      );
      if (userCurrentWorkspaceObj) {
        const currentUserIsAdminOrProInCurrentWorkspace =
          userCurrentWorkspaceObj.role.S === 'admin' || userCurrentWorkspaceObj.role.S === 'pro';
        return currentWorkspace.parent.S === 'root' && currentUserIsAdminOrProInCurrentWorkspace;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  handleCreateWorkspaceClick = (groupsList, useCase) => {
    if (currentWorkspaceHasExpired(groupsList)) {
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Free trial period has ended, please upgrade your account',
      });
    } else {
      const modalParams = [{ useCase }];
      this.setState({ modalParams });
      this.updateModalOpen('createWorkspace');
    }
  };

  getRoleInCurrentWorkspace = () => {
    const userCurrentWorkspace = this.props.currentUserInfo.groups.find(
      (group) => group.id.S === this.props.currentUserGroup,
    );
    return userCurrentWorkspace.role.S;
  };

  render = () => {
    const packageType = getPackageType(this.props.currentUserInfo, this.props.groupsList);
    const sortedList = sortGroupsListByName([...this.props.groupsList]);
    const rootsSubGroupsList = this.extractRootSubGroupsList(
      sortedList,
      this.props.currentUserGroup,
    );
    const otherSubGroupsList = this.extractOtherGroupsList([...this.props.groupsList]);
    const rootGroupsList = sortedList.filter((group) => group.parent.S === 'root');
    const rootsSubGroupsListTitle = this.extractRootsSubGroupsListTitle(
      this.props.groupsList,
      this.props.currentUserGroup,
    );

    const userIsCreator = userIsRootGroupCreator(this.props.currentUserInfo, this.props.groupsList);

    // console.log('accountSettingsWorkspace',
    // this.props.groupsList, this.props.currentUserInfo, this.props.currentUserGroup);
    return (
      <div className="accountSettingsWorkspace">
        <>
          {!userIsCreator && (
            <Wrapper>
              <Header>
                <h4>Create your own yemma</h4>
                <ArrowIcon />
              </Header>
              <ContentWrapper>
                <ExperimentalButton
                  title="create"
                  onClick={() => this.handleCreateWorkspaceClick(this.props.groupsList, 'root')}
                >
                  Create
                </ExperimentalButton>
              </ContentWrapper>
            </Wrapper>
          )}
          {rootGroupsList.length > 0 && (
            <AccountSettingsWorkspaceAdministration
              rootGroupsList={rootGroupsList}
              currentUserGroup={this.props.currentUserGroup}
              refreshList={this.props.refreshList}
              initiateSession={this.props.initiateSession}
              updateModalParams={this.updateModalParams}
              updateModalOpen={this.updateModalOpen}
              updateSectionsOpen={this.updateSectionsOpen}
              sectionsOpen={this.state.sectionsOpen}
              sectionIndex={1}
              title="Workspace control"
              history={this.props.history}
            />
          )}
          {rootsSubGroupsList.length > 0 && (
            <AccountSettingsWorkspaceList
              groupsList={rootsSubGroupsList}
              title={rootsSubGroupsListTitle}
              currentUserGroup={this.props.currentUserGroup}
              updateIsQuerying={this.props.updateIsQuerying}
              currentUserInfo={this.props.currentUserInfo}
              refreshList={this.props.refreshList}
              initiateSession={this.props.initiateSession}
              updateModalParams={this.updateModalParams}
              updateModalOpen={this.updateModalOpen}
              updateSectionsOpen={this.updateSectionsOpen}
              dropdownOpenedItemId={this.state.dropdownOpenedItemId}
              updateDropdownOpenedItemId={this.updateDropdownOpenedItemId}
              sectionsOpen={this.state.sectionsOpen}
              sectionIndex={2}
              rootWorkspaceHasExpired={currentWorkspaceHasExpired(this.props.groupsList)}
            />
          )}
          {otherSubGroupsList.length > 0 && (
            <AccountSettingsWorkspaceList
              groupsList={otherSubGroupsList}
              title={`Other workspaces (${otherSubGroupsList.length})`}
              currentUserGroup={this.props.currentUserGroup}
              updateIsQuerying={this.props.updateIsQuerying}
              currentUserInfo={this.props.currentUserInfo}
              refreshList={this.props.refreshList}
              initiateSession={this.props.initiateSession}
              updateModalParams={this.updateModalParams}
              updateModalOpen={this.updateModalOpen}
              updateSectionsOpen={this.updateSectionsOpen}
              dropdownOpenedItemId={this.state.dropdownOpenedItemId}
              updateDropdownOpenedItemId={this.updateDropdownOpenedItemId}
              sectionsOpen={this.state.sectionsOpen}
              sectionIndex={3}
              rootWorkspaceHasExpired={false}
            />
          )}
        </>
        {this.state.modalOpen && (
          <WorkspaceModals
            modalOpen={this.state.modalOpen}
            modalParams={this.state.modalParams}
            updateModalOpen={this.updateModalOpen}
            updateIsQuerying={this.props.updateIsQuerying}
            currentUserInfo={this.props.currentUserInfo}
            refreshList={this.props.refreshList}
            currentUserGroup={this.props.currentUserGroup}
            groupsList={this.props.groupsList}
            getWorkspacePeopleList={this.props.getWorkspacePeopleList}
            updateCurrentUserInfo={this.props.updateCurrentUserInfo}
            roleInCurrentWorkspace={this.getRoleInCurrentWorkspace(this.props.groupsList)}
            packageType={packageType}
          />
        )}
        {this.getAbleToAddNewWorkspaces(
          this.props.groupsList,
          this.props.currentUserGroup,
          this.props.currentUserInfo,
        ) && (
          <div className="addItemWrapper">
            <div
              onClick={() =>
                this.handleCreateWorkspaceClick(this.props.groupsList, this.props.currentUserGroup)
              }
              title="add item"
              className={
                currentWorkspaceHasExpired(this.props.groupsList)
                  ? 'addWorkspaceButtonDisabled'
                  : 'addWorkspaceButton'
              }
            >
              <AddNewIcon />
            </div>
          </div>
        )}
      </div>
    );
  };
}

const Wrapper = styled.div`
  margin-top: 50px;
`;

const Header = styled.div`
  max-width: 45rem;
  height: 2.5rem;
  color: ${colorDenimPrimary};
  display: flex;
  align-items: center;
  padding: 0 0 0 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  border: 2px solid ${colorDenimPrimary};
  transition: all 0.2s linear;
  svg {
    width: 1rem;
    fill: #233c4d;
    transform: rotate(0deg);
    margin-left: 0.5rem;
    transition: all 0.2s linear;
    outline: none;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 20px;
`;

export default AccountSettingsWorkspace;

AccountSettingsWorkspace.propTypes = {
  // app.jsx props
  groupsList: PropTypes.arrayOf(PropTypes.shape(groupShape)).isRequired,
  currentUserGroup: PropTypes.string.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  getWorkspacePeopleList: PropTypes.func.isRequired,
  initiateSession: PropTypes.func.isRequired,
  updateCurrentUserInfo: PropTypes.func.isRequired,
  history: PropTypes.shape(historyShape).isRequired,
};
