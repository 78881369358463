import React from 'react';
import PropTypes from 'prop-types';
import ExperimentalButton from '../buttons/experimentalButton/experimentalButton';
import './numberInput.scss';

const NumberInput = ({
  value, onChange, max, min, step, label, suffix,
}) => (

  <div className="numberInput">
    <label>{label}</label>
    <span>
      {value}
      {suffix}
    </span>

    {' '}
    <ExperimentalButton
      title="minus"
      isDisabled={value - step < min}
      onClick={() => onChange(value - step)}
      type="primary"
    >
      -
    </ExperimentalButton>
    <ExperimentalButton
      title="plus"
      isDisabled={value + step > max}
      onClick={() => onChange(value + step)}
      type="primary"
    >
      +
    </ExperimentalButton>
  </div>
);

export default NumberInput;

NumberInput.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  suffix: PropTypes.string,
};

NumberInput.defaultProps = {
  value: 1,
  min: 1,
  step: 1,
  max: 10,
  label: '',
  suffix: '',
};
