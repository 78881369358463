import React from 'react';
import PropTypes from 'prop-types';
import './Checkbox.scss';

export const Checkbox = (props) => (
  <div className="checkbox">
    <input
      type="checkbox"
      value="None"
      id={`${props.type}Checkbox`}
      name="check"
      onChange={() => props.handleRadioChange(props.type)}
      checked={props.checked}
    />
    <label htmlFor={`${props.type}Checkbox`} />
  </div>
);

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
