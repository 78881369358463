import React from 'react';
import MailIcon from '../../icons/MailIcon';
import Subnavigation from '../Subnavigation';
import MessagesSubnavItems from './MessagesSubnavItems';
import './messages.scss';

const Messages = ({ messages, isSubnavOpen, updateSubnav, history }) => {
  // update subnav on messagesTrigger-click
  // prevent subnav's outsideClick from closing it again

  const handleClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    updateSubnav();
  };

  const unreadMessagesListLength = React.useMemo(
    () => messages && messages.filter((m) => m.read.N === '0').length,
    [messages],
  );
  return (
    <div className="messages">
      <div
        className={`triggerWrapper ${isSubnavOpen ? 'active' : ''}`}
        title="messages"
        onClick={handleClick}
      >
        <MailIcon fill={!messages ? 'salmon' : 'black'} />
        {unreadMessagesListLength > 0 && messages && (
          <div className="unreadMessagesCounter">
            <h5>{unreadMessagesListLength > 99 ? '99' : unreadMessagesListLength}</h5>
          </div>
        )}
      </div>
      <Subnavigation close={updateSubnav} isOpen={isSubnavOpen}>
        <MessagesSubnavItems
          messagesList={messages}
          fetcherFailed={!messages}
          history={history}
          updateSubnav={updateSubnav}
        />
      </Subnavigation>
    </div>
  );
};

export default Messages;
