import React from 'react';
import PropTypes from 'prop-types';

const UploadIcon = (props) => {
  const { height, fill } = props;
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 489.4 356.1"
      enableBackground="new 0 0 489.4 356.1"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={fill}
        d="M382.4,356.1h-79.1H282h-4.6V250h34.7c8.8,0,14-10,8.8-17.2l-67.5-93.4c-4.3-6-13.2-6-17.5,0l-67.5,93.4
              c-5.2,7.2-0.1,17.2,8.8,17.2h34.7v106.1h-4.6H186H94.3C41.8,353.2,0,304.1,0,250.9c0-36.7,19.9-68.7,49.4-86
              c-2.7-7.3-4.1-15.1-4.1-23.3c0-37.5,30.3-67.8,67.8-67.8c8.1,0,15.9,1.4,23.2,4.1C158,31.9,204.8,0,259.2,0
              c70.4,0.1,128.4,54,135,122.7c54.1,9.3,95.2,59.4,95.2,116.1C489.4,299.4,442.2,351.9,382.4,356.1z"
      />
    </svg>
  );
};

export default UploadIcon;

UploadIcon.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string.isRequired,
};

UploadIcon.defaultProps = {
  height: '0',
};
