import { API } from 'aws-amplify';
import store from '../configureStore';
import { config } from '../config/Config';

export const getAllUsers = (fromGroup) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  let groupId;
  let parentId;
  if (fromGroup) {
    groupId = fromGroup.id.S;
    parentId = fromGroup.parent.S;
  } else {
    groupId = currentUserGroup;
    parentId = 'root';
  }
  const getAllUsersInit = {
    headers: header,
  };

  return API.get(
    config.apiGateway.NAME,
    `/user/all?gid=${groupId}&parent=${parentId}`,
    getAllUsersInit,
  );
};

export const getWorkspaceUsers = (gid) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();

  const getAllUsersInit = {
    headers: header,
  };

  return API.get(
    config.apiGateway.NAME,
    `/user/all?gid=${gid || currentUserGroup}`,
    getAllUsersInit,
  );
};

export const addUser = (uid, userRole, addToworkspaceId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  let workspaceId;
  if (addToworkspaceId) {
    workspaceId = addToworkspaceId;
  } else {
    workspaceId = currentUserGroup;
  }
  const addUserInit = {
    headers: header,
    body: `useruid=${uid}&userrole=${userRole}`,
  };
  return API.post(config.apiGateway.NAME, `/user/add?gid=${workspaceId}`, addUserInit);
};

export const addInvitedUser = (invitationId, firstName, lastName, header) => {
  const addInvitedUserInit = {
    headers: header,
    body: `invid=${invitationId}&firstname=${firstName}&lastname=${lastName}`,
  };
  return API.post(config.apiGateway.NAME, '/user/add', addInvitedUserInit);
};

export const removeInvitedUser = (id) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const init = {
    headers: header,
  };
  return API.get(config.apiGateway.NAME, `/link/delete/${id}?gid=${currentUserGroup}`, init);
};

export const addNewUser = (email, userRole, addToworkspaceId, projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  let workspace;
  if (addToworkspaceId) {
    workspace = addToworkspaceId;
  } else {
    workspace = currentUserGroup;
  }
  let body = `userrole=${userRole}&email=${email}`;
  // if projectId is present, we will add user to project as well
  if (projectId) {
    body += `&project=${projectId}`;
  }

  const addUserInit = {
    headers: header,
    body,
  };

  return API.post(config.apiGateway.NAME, `/user/add?gid=${workspace}`, addUserInit);
};

export const addGuestUser = (email, projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();

  let body = `email=${email}&project=${projectId}`;

  const addUserInit = {
    headers: header,
    body,
  };

  return API.post(config.apiGateway.NAME, `/user/add-guest?gid=${currentUserGroup}`, addUserInit);
};

export const getUsersGroups = () => {
  const {
    project: { header },
  } = store.getState();
  const getUsersGroupsInit = {
    headers: header,
  };
  return API.get(config.apiGateway.NAME, '/user/groups', getUsersGroupsInit);
};

export const usersByProject = (projectId) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const usersByProjectInit = {
    headers: header,
  };
  return API.get(
    config.apiGateway.NAME,
    `/user/by-project?gid=${currentUserGroup}&project=${projectId}`,
    usersByProjectInit,
  );
};

export const updateUserAccess = (uid, projectId, access) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const updateUserAccessInit = {
    headers: header,
    body: `useruid=${uid}&project=${projectId}&access=${access}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/user/update-access?gid=${currentUserGroup}`,
    updateUserAccessInit,
  );
};

export const updateUserRole = (uid, role, groupId) => {
  const {
    project: { header },
  } = store.getState();
  const updateUserAccessInit = {
    headers: header,
    body: `useruid=${uid}&userrole=${role}`,
  };
  return API.post(config.apiGateway.NAME, `/user/update?gid=${groupId}`, updateUserAccessInit);
};

export const updateUserImage = (userUid, storageResponseKey, name) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
    user: { identityId },
  } = store.getState();
  const resourceS3Path = `private/${identityId}/${storageResponseKey}`;
  const updateUserImageInit = {
    headers: header,
    body: `useruid=${userUid}&image=${resourceS3Path}&name=${name}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/user/update-image?gid=${currentUserGroup}`,
    updateUserImageInit,
  );
};

export const removeUserImage = (userUid) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const updateUserImageInit = {
    headers: header,
    body: `useruid=${userUid}&image=no-image`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/user/update-image?gid=${currentUserGroup}`,
    updateUserImageInit,
  );
};

export const getCurrentUserInfo = (gid) => {
  const {
    project: { header },
  } = store.getState();
  const getCurrentUserInfoInit = {
    headers: header,
  };
  const endpoint = gid ? `/user/myself?gid=${gid}` : '/user/myself';
  return API.get(config.apiGateway.NAME, endpoint, getCurrentUserInfoInit);
};

export const getCurrentUserInfoInWorkspace = (workspaceId) => {
  const {
    project: { header },
  } = store.getState();
  const getCurrentUserInfoInit = {
    headers: header,
  };
  return API.get(config.apiGateway.NAME, `/user/myself?gid=${workspaceId}`, getCurrentUserInfoInit);
};

export const removeUserFromWorkspace = (uid, workspaceId) => {
  const {
    project: { header },
  } = store.getState();
  const removeUserInit = {
    headers: header,
    body: `useruid=${uid}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/user/remove-from-group?gid=${workspaceId}`,
    removeUserInit,
  );
};

export const getInvitedUserEmail = (invitationId) =>
  API.get(config.apiGateway.NAME, `/user/invitation/${invitationId}`);

export const updateUserName = (firstName, lastname) => {
  const {
    project: { header },
    workspace: { currentUserGroup },
  } = store.getState();
  const updateUserFirstNameInit = {
    headers: header,
    body: `firstname=${firstName}&lastname=${lastname}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/user/update-details?gid=${currentUserGroup}`,
    updateUserFirstNameInit,
  );
};

export const updateUserEmail = (email, workspaceId) => {
  const {
    project: { header },
  } = store.getState();
  const updateUserEmailInit = {
    headers: header,
    body: `email=${email}`,
  };
  return API.post(
    config.apiGateway.NAME,
    `/user/update-details?gid=${workspaceId}`,
    updateUserEmailInit,
  );
};
