import PropTypes from 'prop-types';

export const filesListShape = PropTypes.arrayOf(
  PropTypes.shape({ item: PropTypes.instanceOf(File) }),
);

export const videoDetailsListItemShape = {
  name: PropTypes.string,
  thumbnailBlob: PropTypes.instanceOf(Blob),
};

export const videoDetailsListShape = PropTypes.arrayOf(PropTypes.shape(videoDetailsListItemShape));
