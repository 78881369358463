import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Canvas from '../projectMainViewVideoPlayer/arrowsOverlay/canvas';
import { dimensionsShape } from '../../ProjectMainViewPropTypes';
import {
  lineTypes,
  getArrowHeadPoints,
  getLinePoints,
} from '../projectMainViewVideoPlayer/arrowsOverlay/utils';
import './clickArea.scss';

const ClickArea = ({ updateNewTaskData, dimensions, isNewTaskActive, lineType }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [savedCoords, setSavedCoords] = useState([]);

  useEffect(() => {
    if (!isNewTaskActive) {
      setSavedCoords([]);
    }
  }, [isNewTaskActive]);

  const getCoordsForOriginalImage = (x, y) => {
    // this is due to poor choices made earlier in this project
    const image = document.getElementById('project-mainview-fullscreen-img');

    const widthRatio = image.naturalWidth / dimensions.width;
    const heightRatio = image.naturalHeight / dimensions.height;

    //console.log('getCoordsForOriginalImage', x, y, image.naturalWidth, dimensions.width);
    // result was => getCoordsForOriginalImage 197 99 640 63
    // 26.10 Some bug introduced itself here. Dimensions was really small. It indicates that at ProjectMainViewImage the render was not complited when dimension were saved
    // If this bug continues start investigations from ProjectMainViewImage
    return {
      left: Math.round(x * widthRatio),
      top: Math.round(y * heightRatio),
    };
  };

  const handleVideoClickEvent = (event) => {
    event.stopPropagation();
    if (!isDragging) {
      const x = event.pageX - dimensions.offsetLeft;
      const y = event.pageY - dimensions.offsetTop;

      const newTaskCoord = getCoordsForOriginalImage(x, y);

      createNewTask({ coord: newTaskCoord });
    }
  };

  const createNewTask = (newTaskData) => {
    updateNewTaskData(newTaskData);
  };

  const afterDraw = (coords) => {
    if (coords.length > 0) {
      const startX = coords[0][0];

      const startY = coords[0][1];

      let newTaskData;
      // check minimum delta x or y to create drawed task

      if (coords.length > 10) {
        // remove some points from the middle for smoother line
        const filteredCoords = coords.filter(
          (d, i) => i === 0 || i === savedCoords.length - 1 || i % 6 === 0,
        );

        setSavedCoords(filteredCoords);
        const startCoords = getCoordsForOriginalImage(startX, startY);
        newTaskData = {
          coord: startCoords,
          coords: filteredCoords,
          taskType: lineTypes.freeLine,
        };
      } else {
        const newTaskCoord = getCoordsForOriginalImage(startX, startY);
        newTaskData = { coord: newTaskCoord };
      }

      updateNewTaskData(newTaskData);
    }
  };

  return (
    <div
      className="clickArea"
      onClick={handleVideoClickEvent}
      onMouseDown={() => setIsDragging(false)}
      onMouseMove={() => setIsDragging(true)}
    >
      {isNewTaskActive && (
        <svg
          style={{
            color: '#fff',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          preserveAspectRatio="none"
          width="100%"
          height="100%"
        >
          <path
            d={`${getLinePoints(savedCoords, lineType)}`}
            fill="none"
            stroke="#e32d4b"
            strokeWidth="4"
          />

          <polygon
            points={getArrowHeadPoints(savedCoords, lineType)}
            stroke="#e32d4b"
            strokeWidth="4"
            fill="#e32d4b"
          />
        </svg>
      )}
      <Canvas dimensions={dimensions} afterDraw={afterDraw} />
    </div>
  );
};

export default ClickArea;

ClickArea.propTypes = {
  updateNewTaskData: PropTypes.func.isRequired,
  dimensions: PropTypes.shape(dimensionsShape).isRequired,
  isNewTaskActive: PropTypes.bool.isRequired,
  lineType: PropTypes.oneOf(Object.values(lineTypes)).isRequired,
};
