import React from 'react';
import PropTypes from 'prop-types';
import './UpdateEmailModal.scss';
import {
  verifyCurrentUserAttributeSubmit,
  updateUserAttributes,
  getCurrentAuthenticatedUser,
} from '../../../apiServices/authApi';
import { validateEmailFormat } from '../../../util/UtilFunctions';
import { updateUserEmail } from '../../../apiServices/userApi';
import NotificationContext from '../../../context/notification';
import TextInput from '../../inputs/textInput';
import ExperimentalButton from '../../buttons/experimentalButton/experimentalButton';

class UpdateEmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailInputFieldValue: '',
      emailSent: false,
      emailConfirmationInputFieldValue: '',
      loading: false,
    };
  }

  handleEmailInputFieldValueChange = (event) => {
    this.setState({ emailInputFieldValue: event.target.value });
  };

  handleEmailConfirmationInputFieldValueChange = (event) => {
    this.setState({ emailConfirmationInputFieldValue: event.target.value });
  };

  handleEmailConfirmationSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    if (this.state.emailConfirmationInputFieldValue !== '') {
      try {
        const verifyCurrentUserAttributeSubmitParams = [
          'email',
          this.state.emailConfirmationInputFieldValue,
        ];
        this.props.updateIsQuerying(true);
        await verifyCurrentUserAttributeSubmit(...verifyCurrentUserAttributeSubmitParams);
        await updateUserEmail(this.state.emailInputFieldValue);
        this.props.updateIsQuerying(false);
        this.props.updateCurrentUserInfo();
        this.setState({ loading: false });
        this.context.add({
          id: Date.now(),
          type: 'success',
          message: 'Email changed',
        });
        this.props.closeModal();
      } catch (error) {
        this.setState({ loading: false });
        this.context.add({
          id: Date.now(),
          type: 'error',
          message: error.message,
        });
        this.props.updateIsQuerying(false);
      }
    } else {
      this.setState({ loading: false });
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: 'Form not complete',
      });
    }
  };

  handleUpdateEmailSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    if (this.state.emailInputFieldValue !== '') {
      if (validateEmailFormat(this.state.emailInputFieldValue)) {
        try {
          this.props.updateIsQuerying(true);
          const getCurrentAuthenticatedUserResponse = await getCurrentAuthenticatedUser();
          this.props.updateIsQuerying(false);
          try {
            const updateUserAttributesParams = [
              getCurrentAuthenticatedUserResponse,
              { email: this.state.emailInputFieldValue },
            ];
            this.props.updateIsQuerying(true);
            await updateUserAttributes(...updateUserAttributesParams);
            this.props.updateIsQuerying(false);
            this.setState({ emailSent: true });
            this.setState({ loading: false });
          } catch (updateUserAttributesError) {
            this.setState({ loading: false });
            this.props.updateIsQuerying(false);
            this.context.add({
              id: Date.now(),
              type: 'error',
              message: updateUserAttributesError.message,
            });
          }
        } catch (getCurrentAuthenticatedUserError) {
          this.setState({ loading: false });
          this.context.add({
            id: Date.now(),
            type: 'error',
            message: getCurrentAuthenticatedUserError.message,
          });
          this.props.updateIsQuerying(false);
        }
      } else {
        this.setState({ loading: false });
        this.context.add({
          id: Date.now(),
          type: 'info',
          message: 'Given text is not an email address',
        });
        this.setState({ emailInputFieldValue: '' });
      }
    } else {
      this.setState({ loading: false });
      this.context.add({
        id: Date.now(),
        type: 'info',
        message: 'Form is not complete',
      });
    }
  };

  static contextType = NotificationContext;

  render() {
    return (
      <div className="updateEmailModal">
        <h4>Update email</h4>
        <div className="contentWrapper">
          {!this.state.emailSent && (
            <>
              <div className="inputWrapper">
                <TextInput
                  type="text"
                  placeholder="New email"
                  value={this.state.emailInputFieldValue}
                  onChange={this.handleEmailInputFieldValueChange}
                />
              </div>
              <ExperimentalButton
                onClick={this.handleUpdateEmailSubmit}
                isDisabled={this.state.emailInputFieldValue.length < 1}
                loading={this.state.loading}
              >
                Update
              </ExperimentalButton>
            </>
          )}
          {this.state.emailSent && (
            <>
              <div className="inputWrapper">
                <h5>Confirmation code is being sent to your email address</h5>

                <TextInput
                  type="text"
                  placeholder="Confirmation code"
                  value={this.state.emailConfirmationInputFieldValue}
                  onChange={this.handleEmailConfirmationInputFieldValueChange}
                />
              </div>
              <ExperimentalButton
                onClick={this.handleEmailConfirmationSubmit}
                isDisabled={this.state.emailConfirmationInputFieldValue < 1}
                loading={this.state.loading}
              >
                Confirm
              </ExperimentalButton>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default UpdateEmailModal;

UpdateEmailModal.propTypes = {
  // app.jsx props
  closeModal: PropTypes.func.isRequired,
  updateIsQuerying: PropTypes.func.isRequired,
  updateCurrentUserInfo: PropTypes.func.isRequired,
};
