import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ItemListItemFile from '../itemListItemFile/ItemListItemFile';
import classes from './ProjectViewItemList.module.scss';
import { matchShape } from '../../../routerPropTypes';
import { currentUserInfoShape } from '../../../apiServices/userApiTypes';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';
import { useCaseType } from '../ItemListPropTypes';
import { sortProjectItemsList } from '../../../util/UtilFunctions';

const ProjectViewItemList = (props) => {
  const sortedList = useMemo(() => {
    return props.content
      ? sortProjectItemsList(
          props.content,
          props.sortByCriteriaIndex,
          props.sortByDirectionIsAscending,
        )
      : [];
  }, [props.content, props.sortByCriteriaIndex, props.sortByDirectionIsAscending]);

  const rendableList = sortedList.map((item) => {
    const isSelected = !!props.selectedItems.find(
      (selectedItem) => (selectedItem.id.S || selectedItem.id) === item.id,
    );
    return (
      <ItemListItemFile
        key={item.id}
        item={item}
        isSelected={isSelected}
        selectedItems={props.selectedItems}
        match={props.match}
        addToSelectedItems={props.addToSelectedItems}
        removeFromSelectedItems={props.removeFromSelectedItems}
        updateModalOpen={props.updateModalOpen}
        updateDropdownOpenedItemId={props.updateDropdownOpenedItemId}
        dropdownOpenedItemId={props.dropdownOpenedItemId}
        updateRenamingItemId={props.updateRenamingItemId}
        renamingItemId={props.renamingItemId}
        refreshList={props.refreshList}
        viewAsLargeGrid
        updateIsQuerying={props.updateIsQuerying}
        currentUserIsAViewer={props.userRole === 'viewer' || props.userRole === 'guest'}
        useCase={props.useCase}
        sortByCriteriaIndex={props.sortByCriteriaIndex}
        sortByDirectionIsAscending={props.sortByDirectionIsAscending}
        projectFileListView={props.projectFileListView}
      />
    );
  });
  return <ul className={classes.largeGridList}>{rendableList}</ul>;
};

export default ProjectViewItemList;

ProjectViewItemList.propTypes = {
  // router props
  match: PropTypes.shape(matchShape).isRequired,

  // app.jsx props
  updateIsQuerying: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,

  // FolderView.jsx props
  refreshList: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  useCase: useCaseType.isRequired,

  // ItemList.jsx props
  selectedItems: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  addToSelectedItems: PropTypes.func.isRequired,
  removeFromSelectedItems: PropTypes.func.isRequired,
  updateModalOpen: PropTypes.func.isRequired,
  updateDropdownOpenedItemId: PropTypes.func.isRequired,
  dropdownOpenedItemId: PropTypes.string,
  renamingItemId: PropTypes.string,
  updateRenamingItemId: PropTypes.func.isRequired,
  sortByCriteriaIndex: PropTypes.number.isRequired,
  sortByDirectionIsAscending: PropTypes.bool.isRequired,
};

ProjectViewItemList.defaultProps = {
  renamingItemId: undefined,
  dropdownOpenedItemId: undefined,
};
