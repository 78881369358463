import { validateFileTypeRendability } from '../../../util/FileTypeSupportCheck';
import { getUserAttributes } from '../workspace/utils';

const transformInvitedUsers = (items) => {
  return items.map((u) => {
    const { created, group, role, id, inviter, expires, email, project, status } = u;
    return {
      uid: id?.S,
      created: created?.S,
      role: role?.S,
      group: group?.S,
      inviter: inviter?.S,
      expires: expires?.S,
      email: email.S,
      status: status.S,
      project: project?.S,
    };
  });
};

const transformProjectUsers = (items) => {
  // if (!items?.[0]?.cognitoAttributes) {
  //   // Quest user's users have different schema for some reason
  //   return items.map((u) => ({
  //     uid: u.user?.S,
  //     active: '1',
  //     role: 'user',
  //     given_name: u.firstname?.S,
  //     family_name: u.lastname?.S,
  //     email: u.email?.S,
  //   }));
  // }
  // For normal users
  return items.map((u) => {
    const { active, cognitoAttributes, firstname, lastname, email, role, user, image } = u;
    // const userAttributes = getUserAttributes(cognitoAttributes?.UserAttributes);
    return {
      uid: user?.S,
      active: active?.N,
      role: role?.S,
      img: image?.S,
      given_name: firstname?.S,
      family_name: lastname?.S,
      email: email?.S,
    };
  });
};

const transformItems = (items) => {
  return items.map(transformResource);
};

const transformResource = (item) => {
  const {
    breadcrumbs,
    created,
    group,
    id,
    itemtype,
    mimeType,
    name,
    owner,
    parent,
    parentType,
    preview,
    searchField,
    signedUrl,
    size,
    status,
    thumbnailUrl,
    snapshotUrl,
    type,
    key,
    title,
    previewImages, // here until own transform for folders
    resources, // same here
  } = item;
  const ext = key?.S.substring(key?.S.lastIndexOf('.') + 1);

  // folder views not yet refactored so we use this same function to transform
  // them for now, if already transformed, move on
  if (!id.S) return item;
  return {
    breadcrumbs,
    created: created?.S,
    groupId: group?.S,
    title: title?.S || title?.N || name?.S,
    name: name?.S || title?.S, // still in use on some places
    id: id.S,
    itemtype: itemtype?.S,
    mimeType: mimeType?.S,
    owner: owner?.S,
    parent: parent?.S,
    parentType: parentType?.S,
    preview: preview?.N === '1',
    searchField: searchField?.S,
    signedUrl: signedUrl?.S,
    size: size?.N,
    status: status?.N,
    thumbnailUrl: thumbnailUrl?.S || snapshotUrl?.S,
    snapshotUrl: thumbnailUrl?.S || snapshotUrl?.S,
    type: type?.S,
    key: key?.S,
    ext,
    isRendable: validateFileTypeRendability(type?.S, ext),
    previewImages: previewImages || [], // old format for this one
    resources: resources || [], // same here
  };
};

const transformTasks = (items, users) =>
  items
    .map((task) => transformTask(task, users))
    .sort((i, j) => new Date(i.created) - new Date(j.created));

const transformTask = (task, users) => {
  const userData = users.find((i) => i.uid === task.owner?.S);
  return {
    resource: task.resource.S,
    created: task.created.S,
    index: parseInt(task.index?.N || 1),
    id: task.id.S,
    type: task.type.S,
    coord_x: task.coord_x?.N,
    coord_y: task.coord_y?.N,
    coords: !!task.coords?.S && JSON.parse(task.coords.S),
    timestamp: task.timestamp?.N,
    duration: task.duration?.N,
    owner: task.owner.S,
    taskType: task.tasktype?.S || 'default',
    message: task.message?.S,
    messages: transformTaskMessages(task.messages?.L, users),
    userData,
  };
};

const transformTaskMessages = (messages = [], users) => {
  return messages.map(({ M }) => {
    const userData = users.find((i) => i.uid === M.sender?.S);
    return {
      created: M.created?.S,
      content: M.msg?.S,
      sender: M.sender?.S,
      userData,
    };
  });
};

const transformMessages = (messages = [], users) => {
  return messages
    .map((m) => {
      const userData = users.find((i) => i.uid === m.sender?.S);
      return {
        created: m.created?.S,
        content: m.msgbody?.S,
        sender: m.sender?.S,
        id: m.msgid?.S,
        userData,
      };
    })
    .sort((i, j) => new Date(i.created) - new Date(j.created));
};

export {
  transformProjectUsers,
  transformItems,
  transformTasks,
  transformTask,
  transformMessages,
  transformResource,
  transformInvitedUsers,
};
