import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RadioButtonsItem } from './radioButtonsItem/RadioButtonsItem';

export const RadioButtons = (props) => (
  <List>
    <RadioButtonsItem
      type="folder"
      handleRadioChange={props.handleRadioChange}
      activeRadio={props.activeRadio}
    />
    <RadioButtonsItem
      type="project"
      handleRadioChange={props.handleRadioChange}
      activeRadio={props.activeRadio}
    />
  </List>
);

const List = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0 auto;
`;

RadioButtons.propTypes = {
  handleRadioChange: PropTypes.func.isRequired,
  activeRadio: PropTypes.string.isRequired,
};
