import React from 'react';
import PropTypes from 'prop-types';
import { addLinks } from '../../../apiServices/linkApi';
import ShareFileModalInputField from './shareFileModalInputField/ShareFileModalInputField';
import { currentUserInfoShape } from '../../../apiServices/userApiTypes';
import SendEmailSection from './sendEmailSection/SendEmailSection';
import { folderListItemShape } from '../../../app/views/folderView/FolderViewPropTypes';
import './shareFileModal.scss';
import { modalParamsItemShape } from './ShareFileModalPropTypes';
import NotificationContext from '../../../context/notification';
import { getIdsList } from './ShareFileModalUtilFunctions';

class ShareFileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outsideResourceIdsProcess: { status: 'loading' },
    };
    this.modalWrapperNode = React.createRef();
  }

  async componentDidMount() {
    document.addEventListener('keydown', this.handleButtonPress, false);
    document.addEventListener('click', this.handleClickOutside, true);
    await this.addLinks();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleButtonPress, false);
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  addLinks = async () => {
    try {
      const idsList = getIdsList(this.props.selectedFiles, this.props.modalParams);
      const addLinksResponse = await addLinks(idsList);

      const copyLink = `${window.location.origin}/in/link/${addLinksResponse.response}`;
      this.setState({ outsideResourceIdsProcess: { status: 'success', data: copyLink } });
    } catch (addLinksError) {
      this.context.add({
        id: Date.now(),
        type: 'error',
        message: addLinksError.message,
      });
    }
  };

  handleButtonPress = (event) => {
    if (event.keyCode === 27) {
      this.props.closeModal();
    }
  };

  handleClickOutside = (event) => {
    if (!this.modalWrapperNode.current.contains(event.target)) {
      this.props.closeModal();
    }
  };

  handleCloseButtonClick = () => {
    this.props.closeModal();
  };

  static contextType = NotificationContext;

  render() {
    return (
      <div className="shareFileModal" ref={this.modalWrapperNode}>
        <h4 className="headline">Share selected file</h4>
        <div className="section">
          <h5 className="title">Copy this link to share file</h5>
          <ShareFileModalInputField
            outsideResourceIdsProcess={this.state.outsideResourceIdsProcess}
            useCase="link"
          />
        </div>
        <div className="section">
          <h5 className="title">Send link to email address</h5>
          <SendEmailSection
            currentUserInfo={this.props.currentUserInfo}
            updateIsQuerying={this.props.updateIsQuerying}
            closeModal={this.props.closeModal}
            outsideResourceIdsProcess={this.state.outsideResourceIdsProcess}
          />
        </div>
      </div>
    );
  }
}

export default ShareFileModal;

ShareFileModal.propTypes = {
  // app.jsx props
  currentUserInfo: PropTypes.shape(currentUserInfoShape).isRequired,
  updateIsQuerying: PropTypes.func.isRequired,

  // Itemlist.jsx props
  closeModal: PropTypes.func.isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape(folderListItemShape)).isRequired,
  modalParams: PropTypes.arrayOf(modalParamsItemShape).isRequired,
};
